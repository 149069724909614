import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import InputMask from "react-input-mask";
import { Switch, Pagination } from "antd";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import ResourcePartial from "./partials/component";
import { CloseIcon } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { resourceSchema } from "../../utils/validations";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import EditResourceModal from "./partials/edit-resource-modal";
import "./styles.scss";
import NotFound from "../NoRecords/component";
import NumberFormat from "react-number-format";

export default class ResourcesComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    let activeTab = 1;
    let resourceType = "facility";
    let tabName = "Facility";

    if (props.location.pathname === "/third-party-haulers") {
      activeTab = 2;
      resourceType = "thirdPartyHauler";
      tabName = "Third Party Hauler";
    }
    if (props.location.pathname === "/in-house-haulers") {
      activeTab = 3;
      resourceType = "inHouseHauler";
      tabName = "In House Hauler";
    }
    this.state = {
      spinning: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      modalIsOpen: false,
      toggleMenu: false,
      initalValues: {
        companyName: "",
        phone: "",
        email: "",
        address: ""
      },
      resources: [],
      facilities: [],
      selectedFacilities: [],
      facilityDetails: [
        {
          facility: "",
          cost_per_ton_for_facility: "",
          cost_per_load_for_facility: "",
        },
      ],
      showFacilityDetails: false,      
      activeTab,
      resourceType,
      tabName,
      status: 1,
      editModal: false,
      invalidFacilityIndex: null,
      resource: null,
      searchText: "",
      address: "",
      typingTimeout: 0,
      cost_per_yard: "",
      cost_per_ton: "",
      facilityCount: 0,
      cost_per_load: "",
      is_first_render: true
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.props.location.pathname === "/facilities") {
      this.setPageTitle("facilities");
    } else if (this.props.location.pathname === "/third-party-haulers") {
      this.setPageTitle("thirdPartyHauler");
    } else if (this.props.location.pathname === "/in-house-haulers") {
      this.setPageTitle("inHouseHauler");
    }
    this.props.getMe();
    this.fetchResources();
    this.fetchFacilities("facility");
  };

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchResources();
    });
  };

  setPageTitle = tab => {
    if (tab === "facility") {
      document.title = "Facility | Weighworks";
    } else if (tab === "thirdPartyHauler") {
      document.title = "Third Party Haulers | Weighworks";
    } else {
      document.title = "In House Haulers | Weighworks";
    }
  };

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchResources();
      }, 1000)
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  fetchResources = async () => {
    const { resourceType, searchText, skip, limit } = this.state;
    const {
      value: {
        data: { resources, resourcesCount }
      }
    } = await this.props.fetchResources({ resourceType, searchText, skip, limit });
    this.setState({ resources, totalItems: resourcesCount });
  };

  fetchFacilities = async resourceType => {
    const {
      value: { data }
    } = await this.props.fetchResources({ resourceType, searchText: "", skip: 0, limit: 0 });
    this.setState({ facilities: data.resources });
  };

 
  areAllFacilitiesAdded = () => {
    const { facilityDetails, facilities } = this.state;
    return facilityDetails.length === facilities.length;
  };
  
  handleAddFacility = (is_first_render) => {
    const { facilityDetails, facilities, facilityCount } = this.state;
    if (facilityDetails.length === facilities.length) {
      return;
    }
  
    const newFacility = {
      facility: '',
      cost_per_ton_for_facility: '',
      cost_per_load_for_facility: '',
    }; 
    this.setState(() => ({
      facilityDetails: is_first_render ? [newFacility] : [...facilityDetails, newFacility],
      showFacilityDetails: true,
      is_first_render: false,
      facilityCount:facilityCount+1
    }));
  };
  
  handleFacilityChange = (selectedFacility, index) => {
    const { facilityDetails } = this.state;
    const updatedFacilityDetails = [...facilityDetails];
    updatedFacilityDetails[index].facility = selectedFacility;
    this.setState({ facilityDetails: updatedFacilityDetails, invalidFacilityIndex: null });
  };
  
  isFacilityAdded = (facilityOption, currentIndex) => {
    const { facilityDetails } = this.state;
    return facilityDetails.some((facility, index) => index !== currentIndex && facility.facility === facilityOption.companyName);
  };
  

  handleRemoveFacility = () => {
    const { facilityDetails ,facilityCount} = this.state;
    const updatedFacilityDetails = facilityDetails.slice(0, -1);
    this.setState({ facilityDetails: updatedFacilityDetails ,facilityCount:facilityCount-1});
  };
  

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      editModal: false,
      initalValues: {
        companyName: "",
        phone: "",
        email: "",
        address: ""
      },
      facilityDetails: [
        {
          facility: "",
          cost_per_ton_for_facility: "",
          cost_per_load_for_facility: "",
        },
      ],
      showFacilityDetails: false,  
      is_first_render: true,
      cost_per_ton: "",
      cost_per_yard: "",
      cost_per_load: "",
      facilityCount: 0,
      status: 1,
      errorMessage: "",
      emailerrorMessage:"",
      facilityerrorMessage:""
    });
  }

  onTabChange(activeTab, resourceType, tabName, url) {
    const { history } = this.props;
    this.setState(
      {
        activeTab,
        resourceType,
        tabName,
        cost_per_ton: "",
        cost_per_yard: "",
        cost_per_load: "",
        address: "",
        skip: 0,
        currentPage: 1
      },
      () => {
        this.setPageTitle(resourceType);
        this.fetchResources(activeTab);
        this.fetchFacilities("facility");
        history.push(url);
      }
    );
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  onChangeInput = (e, values, setValues) => {
    setValues({ ...values, cost_per_yard: e.target.value.toLocaleString() });
  };

  handleSubmit = async ({ email, phone, ...values }, { setSubmitting, setErrors }) => {
    const {
      resourceType,
      activeTab,
      cost_per_yard,
      cost_per_ton,
      cost_per_load,
      facilityCount,
      facilityDetails,
      user: { companyId }
    } = this.state;
    this.setState({ errorMessage: "" });
    setSubmitting(true);
 
    let isFacilityDetailsValid = true;
    let formattedFacilityDetails = [];
    if (facilityCount >0){
    if (activeTab === 2 || activeTab === 3) {
      formattedFacilityDetails = facilityDetails.map((facility) => ({
        facility: facility.facility,
        cost_per_ton_for_facility: facility.cost_per_ton_for_facility ? facility.cost_per_ton_for_facility.replace(/,/g, "") : "",
        cost_per_load_for_facility: facility.cost_per_load_for_facility ? facility.cost_per_load_for_facility.replace(/,/g, "") : "",
      }));
    
      const invalidFacilityIndex = facilityDetails.findIndex(facility => facility.facility === '' || facility.facility === 'Select Facility');
      if (invalidFacilityIndex !== -1) {
        setSubmitting(false);
        this.setState({ facilityerror: "Please select a facility", invalidFacilityIndex });
        return;
      }
      
      isFacilityDetailsValid = formattedFacilityDetails.every((item) => (
        item.facility.trim() !== "" &&
        (item.cost_per_ton_for_facility !== "" || item.cost_per_load_for_facility !== "")
      ));
    }
    
    if (!isFacilityDetailsValid) {
      this.setState({
        facilityerrorMessage: "Please input either Cost per Ton or Cost Per Load to save"
      });
        return;
      }
    }
    const payload = {
      ...values,
      cost_per_ton: cost_per_ton ? String(cost_per_ton).replace(/,/g, "") : "",
      cost_per_load: cost_per_load ? String(cost_per_load).replace(/,/g, "") : "",
      cost_per_yard: cost_per_yard ? String(cost_per_yard).replace(/,/g, "") : "",
      phone: phone.replace(/[^\d]/g, ""),
      status: this.state.status,
      companyId,
      facilityDetails: formattedFacilityDetails,
      type: resourceType
    };
    if (email !== "") {
      payload.email = email;
    }
    const {
      value: { status, message }
    } = await this.props.createResource(payload);
    if (status) {
      this.setState({ address: "" }, () => {
        this.fetchResources();
        this.closeModal();
        setSubmitting(false);
      });
    } else {
      if (message==="Email already exists."){
        this.setState({ emailerrorMessage: message });
      }
      else{
      this.setState({ errorMessage: message });
    }
    }
  };
  render() {
    const {
      initalValues,
      resources,
      activeTab,
      tabName,
      status,
      editModal,
      searchText,
      facilityerrorMessage,
      errorMessage,
      emailerrorMessage,
      invalidFacilityIndex,
      showFacilityDetails,
      facilities,
      facilityDetails,
      cost_per_ton,
      cost_per_yard,
      cost_per_load
    } = this.state;
    return (
      <div>
        <div className="layout-has-sidebar">
          <TopNavigation
            {...this.props}
            searchText={searchText}
            handleSearchChange={this.handleSearch}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
         
          <main className="dashboard-layout-content resources__wrapper">
            <div className="loader__section">
              <div className="loading-circle"></div>
            </div>
            <div className="page-header">
              <h2 className="heading-title">Resources</h2>
              <div className="page-header-btn-grp">
                <button className="btn btn-dark btn-sm" onClick={this.openModal}>
                  Add New {tabName}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="custome__tabs">
                  <ul className="custome__tabs-list">
                    <li>
                      <span
                        className={activeTab === 1 ? "active" : ""}
                        onClick={() => this.onTabChange(1, "facility", "Facility", "/facilities")}
                      >
                        Facilities
                      </span>
                    </li>
                    <li>
                      <span
                        className={activeTab === 2 ? "active" : ""}
                        onClick={() =>
                          this.onTabChange(2, "thirdPartyHauler", "Third Party Hauler", "/third-party-haulers")
                        }
                      >
                        Third Party Haulers
                      </span>
                    </li>
                    <li>
                      <span
                        className={activeTab === 3 ? "active" : ""}
                        onClick={() => this.onTabChange(3, "inHouseHauler", "In House Hauler", "/in-house-haulers")}
                      >
                        In House Haulers
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {resources.length !== 0 ? (
              <ResourcePartial
                {...this.props}
                resources={resources}
                fetchResources={() => this.fetchResources()}
                editResourceModal={resource => this.setState({ editModal: true, resource })}
              />
            ) : (
              <NotFound title={`No ${tabName}`} />
            )}
          </main>
        </div>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered edit-third-party-modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Create {tabName}</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initalValues}
              validationSchema={resourceSchema}
              onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
            >
              {({ values, handleChange, handleSubmit, setValues, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values.companyName}
                            name="companyName"
                            required
                          />
                          <ErrorMessage component="span" name="companyName" className="invalid-feedback d-block" />
                          <span className="invalid-feedback d-block m-10">{errorMessage}</span>
                          <label className="material-textfield-label">
                            Company Name<span>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <InputMask
                            mask="(999) 999-9999"
                            className="form-control material-textfield-input"
                            value={values.phone}
                            name="phone"
                            maskChar=""
                            onChange={handleChange}
                            required
                          />
                          <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">Phone Number</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values.email}
                            name="email"
                            required
                          />
                          <ErrorMessage component="span" name="email" className="invalid-feedback d-block" />
                          <span className="invalid-feedback d-block m-10">{emailerrorMessage}</span>
                          <label className="material-textfield-label">Email</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <PlacesAutocomplete
                            type="text"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            className="form-control material-textfield-input"
                            handleAddressChange={({ address }) => setValues({ ...values, address })}
                            setValue={address => this.setState({ address })}
                            address={this.state.address}
                            isSetToState={true}
                            isRequired={false}
                          />
                          <ErrorMessage component="span" name="address" className="invalid-feedback d-block" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                {activeTab === 2 || activeTab === 3 ? ( 
                  <div className="material__label-section add__another">
                    <h3 className={`material__label-title underline ${facilityDetails.length === facilities.length ? 'disabled' : ''}`}  onClick={() => this.handleAddFacility(this.state?.is_first_render)}>
                      + Add Facility
                    </h3>
                  </div>
                ) : null}
              </div>

              {showFacilityDetails && (    
  <div className="row">
    {facilityDetails.map((facility, index) => (
      <React.Fragment key={index}>
        <div className="col-md-4">
          <div className="form-group material-textfield">
          <select
           className="form-control custom-select w-150 material-textfield-input pr-22"
          value={facility.facility}
          onChange={(e) => this.handleFacilityChange(e.target.value, index)}
          required
        >
          <option>Select Facility</option>
          {facilities
            .filter(facilityOption => !this.isFacilityAdded(facilityOption, index))
            .sort((a, b) => a.companyName.localeCompare(b.companyName))
            .map((facilityOption) => (
              <option key={facilityOption.index} value={facilityOption.companyName}>
                {facilityOption.companyName}
              </option>
            ))}
        </select>
        <label className="material-textfield-label">
          Facility<span>*</span>
        </label>
        <div className=""><span className="invalid-feedback d-block m-10">{invalidFacilityIndex === index ? 'Please select a facility' : ''}</span></div>
      </div>
        </div>
        <div className="col-md-3">
          <div className="form-group material-textfield">
            <NumberFormat
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"input"}
              type="text"
              className="form-control material-textfield-input"
              onValueChange={(values) => {
                const { formattedValue } = values;
                const updatedFacilityDetails = [...facilityDetails];
                updatedFacilityDetails[index].cost_per_ton_for_facility = formattedValue;
                this.setState({ facilityDetails: updatedFacilityDetails });
              }}
              value={facility.cost_per_ton_for_facility}
            />
            <label className="material-textfield-label">Cost Per Ton</label>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group material-textfield">
            <NumberFormat
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"input"}
              type="text"
              className="form-control material-textfield-input"
              onValueChange={(values) => {
                const { formattedValue } = values;
                const updatedFacilityDetails = [...facilityDetails];
                updatedFacilityDetails[index].cost_per_load_for_facility = formattedValue;
                this.setState({ facilityDetails: updatedFacilityDetails });
              }}
              value={facility.cost_per_load_for_facility}
            />
            <label className="material-textfield-label">Cost Per Load</label>
          </div>
        </div>
        {index !== facilityDetails.length + 1 && ( 
          <div className="col-md-2">
            <div className="material__label-section">
              <div className="material__label-title">
                <button className="material__label-remove" type="button" onClick={this.handleRemoveFacility}>
                  Remove
                </button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    ))}
  </div>
)}

                    {activeTab === 1 ? (
                      <React.Fragment>
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={values => {
                              const { formattedValue } = values;
                              this.setState({ cost_per_ton: formattedValue });
                            }}
                            value={cost_per_ton}
                            required
                          />
                          <label className="material-textfield-label">Cost Per Ton</label>
                        </div>
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={values => {
                              const { formattedValue } = values;
                              this.setState({ cost_per_yard: formattedValue });
                            }}
                            value={cost_per_yard}
                            required
                          />
                          <label className="material-textfield-label">Cost Per Yard</label>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={values => {
                              const { formattedValue } = values;
                              this.setState({ cost_per_ton: formattedValue });
                            }}
                            value={cost_per_ton}
                            required
                          />
                          <label className="material-textfield-label">Cost Per Ton</label>
                        </div>
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={values => {
                              const { formattedValue } = values;
                              this.setState({ cost_per_load: formattedValue });
                            }}
                            value={cost_per_load}
                            required
                          />
                          <label className="material-textfield-label">Cost Per Load</label>
                        </div>
                        <span className="invalid-feedback d-block m-10">{facilityerrorMessage}</span>
                      </React.Fragment>
                    )}
                    <div className="display-team-status">
                      <span className="display-team-text">Status</span>
                      <Switch
                        name="status"
                        checked={status === 3 ? false : true}
                        onChange={() => this.setState({ status: status === 3 ? 1 : 3 })}
                      />
                      <p className="on-of-text text-green">{status === 3 ? "Not Active" : "Active"}</p>
                    </div>
                    <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-lg w-100">
                      {isSubmitting ? "Please wait..." : "Save"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
        <EditResourceModal
          closeModal={this.closeModal}
          editModal={editModal}
          resource={this.state.resource}
          facilityData={this.state.facilities}
          tabName={tabName}
          fetchResources={this.fetchResources}
          updateResource={this.props.updateResource}
        />
        <Pagination
          className="text-center"
          onChange={this.onChangePage}
          hideOnSinglePage
          current={this.state.currentPage}
          total={this.state.totalItems}
          pageSize={this.state.limit}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
