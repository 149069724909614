import React, { PureComponent } from 'react'
import { Formik, ErrorMessage } from "formik";
import { CloseIcon } from '../../components/icons'
import { profileSchema } from "../../utils/validations";
import './styles.scss'

export default class CardknoxInfoComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  }

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        email: ""
      },
      user: null
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.getMe();
    document.title = "Profile | Weigh Works"
  };

  static getDerivedStateFromProps({ user }, prevState) {
    if (user && user !== prevState.user) {
      const {        
        firstName,
        lastName,
        phone,
        email
      } = user;
      const initialValues = {
        firstName,
        lastName,
        phone,
        email
      };
      return { initialValues, user };
    }
    return null;
  }

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone
    };
    const {
      value: { status, message }
    } = await this.props.updateProfile(payload);
    console.log('status', status)
    console.log('message', message)
    if (status) {
      this.props.history.push('/dashboard')
    } else {
      setErrors({ email: message });
    }
  };

  render() {
    const {
      initialValues
    } = this.state;

    return (
      <div>
        <div className="cardknox__info-container">
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Cardknox Information</div>
            <div className="head-section-cta">
              <span
                className="btn btn-link btn-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
          <div className="cardknox__info-wrapper">
            <div className="card">
              <div className="card-header d-flex align-items-center">
                <h5 className="card-title">Cardknox Keys</h5>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={profileSchema}
                onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
              >
                {({ values, handleChange, handleSubmit, setValues }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="firstName"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values && values.firstName}
                              required
                            />
                            <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Server Key<span>*</span>
                            </label>                          
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="lastName"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values && values.lastName}
                              required
                            />
                            <ErrorMessage component="span" name="lastName" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Public Key<span>*</span> 
                            </label>
                          </div>
                        </div>
                      </div> 
                      <button type="submit" className="btn btn-dark btn-lg w-100">
                        Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
