import { connect } from "react-redux";
import { getMe } from "../../store/user/duck";
import { createScale ,updateScale,getOneScale} from "../../store/scale/duck";
// import UpdateProfileComponent from "./component";
import CreateScaleComponent from "./component";

const CreateScaleContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getMe,
    updateScale,
    createScale,
    getOneScale
  }
  // )(UpdateProfileComponent);
)(CreateScaleComponent);

// export default UpdateProfileContainer;
export default CreateScaleContainer;
