import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import moment from "moment";
import { Formik, ErrorMessage } from "formik";
import { depositSchema } from "../../../utils/validations";
import { CloseIcon, BackArrowIcon } from "../../../components/icons";
import _ from "lodash";
import { numberFormat } from "../../../constants/common";
import NumberFormat from "react-number-format";

export default class CloseBalance extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      closeBalanceModal: false,
      closingBalance: 0,
      depositInitialValues: {
        amount: ""
      }
    };
  }

  async componentDidMount() {
    const startDate = moment()
      .startOf("day")
      .toISOString();
    const endDate = moment()
      .endOf("day")
      .toISOString();
    const {
      value: { data }
    } = await this.props.fetchClosingBalance({ startDate, endDate, timezone: moment().format("Z") });
    const depositInitialValues = {
      amount: _.get(data, "[0].closingBalance", 0)
    };
    this.setState({
      closingBalance: _.get(data, "[0].closingBalance", 0),
      closeBalanceModal: this.props.closeBalanceModal,
      depositInitialValues
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.closeBalanceModal !== prevProps.closeBalanceModal) {
      this.setState({ closeBalanceModal: this.props.closeBalanceModal });
    }
  }

  render() {
    const { closingBalanceText } = this.props;
    return (
      <div>
        <ReactModal
          isOpen={this.state.closeBalanceModal}
         onRequestClose={this.props.showCloseApproveModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered common__confirmtion-popu">
            <div className="react-modal-header">
              {!closingBalanceText.oBScreen && (
                <div className="btn-back-header">
                  <button className="btn btn-link btn-back">
                    <span onClick={this.props.showCloseApproveModal}>
                      <BackArrowIcon />
                      Back
                    </span>
                  </button>
                </div>
              )}
              <button type="button" className="btn react-modal-close" onClick={this.props.showCloseApproveModal}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <h5 className="react-modal-title">{closingBalanceText.header}</h5>
              <p>{closingBalanceText.para}</p>
              <Formik
                enableReinitialize
                initialValues={this.state.depositInitialValues}
                validationSchema={depositSchema}
                onSubmit={(values, formikProps) => {
                  this.props.handleSubmit(values, "closeBalance", formikProps);
                }}
              >
                {({ values, handleChange, handleSubmit, setValues, isSubmitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    {!closingBalanceText.oBScreen ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <NumberFormat 
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType={"input"}
                              type="text"
                              className="form-control material-textfield-input"
                              onValueChange={(_values) => {
                                const {formattedValue} = _values;
                                const amount = formattedValue
                                setValues({
                                  ...values,
                                  amount
                                })                               
                              }}
                              value={values.amount}
                              required
                            />
                            <ErrorMessage component="span" name="amount" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Enter Amount<span>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h3 className="amout__title">${numberFormat(this.state.closingBalance)}</h3>
                    )}
                    <button type="submit" disabled={isSubmitting} className="btn btn-dark btn-lg w-100">
                      {closingBalanceText.btnText}
                    </button>
                    {closingBalanceText.oBScreen && (
                      <button
                        onClick={this.props.showCloseAmountModal}
                        disabled={isSubmitting}
                        className="btn btn-dark btn-lg w-100 bg-red"
                      >
                        No, this is NOT Correct
                      </button>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
