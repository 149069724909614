import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const createResource = data => {
  return fetch(`${HOSTNAME}/resource`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchResources = ({ resourceType, searchText, skip, limit }) => {
  return fetch(`${HOSTNAME}/resources?type=${resourceType}&searchText=${searchText}&skip=${skip}&limit=${limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchResourcesList = ({ resourceType, searchText }) => {
  return fetch(`${HOSTNAME}/resources-list?type=${resourceType}&searchText=${searchText}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const deleteResource = (id) => {
  return fetch(`${HOSTNAME}/resource/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const updateResource = ({ _id, ...rest }) => {
  return fetch(`${HOSTNAME}/resource/${_id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(rest)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}