import React, { PureComponent } from "react";
import { Table } from "antd";
import moment from "moment";
// import _ from "lodash";
import { numberFormat } from "../../../constants/common";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  {
    title: "Date",
    width: 15,
    dataIndex: "_id",
    key: "_id",
    align: "center",
    fixed: "left",
    render: _id => moment(_id, "DD-MM-YYYY").format("M-D-YYYY")
  },
  {
    title: "Starting Balance",
    width: 15,
    dataIndex: "startingBalance",
    key: "startingBalance",
    align: "center",
    render: startingBalance => numberFormat(startingBalance)
  },
  {
    title: "Deposit Amount",
    width: 15,
    dataIndex: "cashDeposited",
    key: "cashDeposited",
    align: "center",
    render: cashDeposited => numberFormat(cashDeposited)
  },
  {
    title: "Withdrawl Amount",
    width: 15,
    dataIndex: "withDrawlAmount",
    key: "withDrawlAmount",
    align: "center",
    render: withDrawlAmount => numberFormat(withDrawlAmount)
  },
  {
    title: "Collection Cash",
    width: 15,
    dataIndex: "cashCollection",
    key: "cashCollection",
    align: "center",
    render: cashCollection => numberFormat(cashCollection)
  },
  {
    title: "Collection Card",
    width: 15,
    dataIndex: "collectionCard",
    key: "collectionCard",
    align: "center",
    render: collectionCard => numberFormat(collectionCard)
  },
  {
    title: "Closing Balance",
    width: 15,
    dataIndex: "closingBalance",
    key: "closingBalance",
    align: "center",
    render: closingBalance => numberFormat(closingBalance)
  },
  {
    title: "Discrepancy",
    width: 15,
    dataIndex: "discrepancy",
    key: "discrepancy",
    align: "center"
  },
  {
    title: "Counted Amount",
    width: 15,
    dataIndex: "countedAmount",
    key: "countedAmount",
    align: "center",
    render: countedAmount => numberFormat(countedAmount)
  },
  {
    title: "Difference",
    width: 15,
    dataIndex: "difference",
    key: "difference",
    align: "center",
    render: difference => numberFormat(difference)
  }
];
export default class JobReportComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isExportData: false
    };
  }

  async componentDidMount() {
    const { startDate, endDate } = this.props;
    const {
      value: { data }
    } = await this.props.getReport({
      url: "/cash-register-report",
      startDate,
      endDate,
      timezone: moment().format("Z")
    });
    this.setState({ data });
  }

  render() {
    const { data } = this.state;
    const { isExportData, pageSize } = this.props;
    return (
      <>
        <div className="work__repot-body">
          <div className="card">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: 1600, y: 300 }}
            />
          </div>
        </div>
        {isExportData && data.length !== 0 && (
          <ExcelFile hideElement filename="Reports">
            <ExcelSheet data={data} name="Orders">
              <ExcelColumn
                label="Date"
                value={col => (moment(col.createdAt).isValid() ? moment(col.createdAt).format("M-D-YYYY") : "Summary")}
              />
              <ExcelColumn label="Starting Balance" value={col => numberFormat(col.startingBalance)} />
              <ExcelColumn label="Deposit Amount" value={col => numberFormat(col.cashDeposited)} />
              <ExcelColumn label="Withdrawl Amount" value={col => numberFormat(col.withDrawlAmount)} />
              <ExcelColumn label="Collection Cash" value={col => numberFormat(col.cashCollection)} />
              <ExcelColumn label="Collection Card" value={col => numberFormat(col.collectionCard)} />
              <ExcelColumn label="Closing Balance" value={col => numberFormat(col.closingBalance)} />
              <ExcelColumn label="Discrepancy" value={col => (col.discrepancy)} />
              <ExcelColumn label="Counted Amount" value={col => numberFormat(col.countedAmount)} />
              <ExcelColumn label="Difference" value={col => numberFormat(col.difference)} />
            </ExcelSheet>
          </ExcelFile>
        )}
      </>
    );
  }
}
