import { connect } from "react-redux";
import { getMe, fetchMe } from "../../store/user/duck";
import ReceiptComponent from "./component";
import { fetchOrder } from "../../store/order/duck";

const ReceiptContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchOrder,
    fetchMe
  }
)(ReceiptComponent);

export default ReceiptContainer;
