import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken } from '../../store/user/duck'
import { fetchSetting } from "../../store/company/duck";
import { createCustomer, addCard,createProject } from "../../store/customer/duck";
import EditCompanyInfoComponent from './component';
import {getPricingTier} from "../../store/Pricing_tier/duck"
import { getPayabliCustomerData ,getPaybliIntegration} from '../../store/paybli_integrations/duck';
import {fetchInvoiceDeliveryData} from "../../store/invoiceDelivery/duck"

const EditCompanyInfoContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateUserToken,
    fetchMe,
    createCustomer,
    fetchSetting,
    addCard,
    getPricingTier,
    getPayabliCustomerData,
    fetchInvoiceDeliveryData,
    getPaybliIntegration,
    createProject
  }
)(EditCompanyInfoComponent);

export default EditCompanyInfoContainer
