import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { DownarrowIcon, StateUpArrowIcon } from "../../../components/icons";
import _ from "lodash";
import moment from "moment-timezone";
import NotFound from "../../NoRecords/component";
import InvoiceDetailComponent from "../../Billing/partials/invoiceDetail";
import {customStartCase} from "../../../constants/common";
export default class DriverSignature extends Component {

  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      isModelOpen: false,
      isPrintRecipt: false,
      modelData: {},
      tz,
    }
  }

  getinvoiceDetailData = async (invoiceId) => {
    const payload = {
      invoiceId: invoiceId
    }
    const { value: { data: { invoiceDetail } } } = await this.props.getInvoiceDetalByInvoiceId(payload);
    if (invoiceDetail && invoiceDetail.length > 0) {
    let modelData = invoiceDetail[0]
    modelData['customAttachments'] = []
    modelData['showAttachments'] = []
    this.setState({ isModelOpen: true, modelData: modelData })
    }
  }

  navigate = async(orderId, currentPage) => {
    this.props.history.push({ pathname: `/order-details/${orderId}` });
    await sessionStorage.setItem("current-page-complete-orders", currentPage)
  };

  truncateText(text, maxLength) {
    if (!text || text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }

  
  reArrangOrdersData = (orderData) => {
    let order = orderData
    let reArrangOrders = []
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if ((item?.orderBreakDownItems?.isminimumPrice)) {
            let obj = {
              fixedItems: [{
                fixedItem: "Dumping services",
                unitPrice: item?.orderBreakDownItems?.minimumPrice,
                units: 1,
                totalamout: item?.orderBreakDownItems?.totalAmountDue,
              }],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType
            }
            reArrangOrders.push(obj)
            return null
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {

              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems?.fixedItem)
                  if (fixedItemName.includes("Yard")) {
                    if (Number(fixedItems?.unitPrice) > Number(item?.capoutPrice)) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout: item?.orderBreakDownItems?.capoutPrice * fixedItems.units,
                      }
                      obj.fixedItems.push(fixedItemObject)
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    }
                    obj.fixedItems.push(fixedItemObject)
                  }
                  return null
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightFixedItems) => {
                  let chargeByWeightItemObject = {
                    fixedItem: chargeByWeightFixedItems?.fixedItem,
                    unitPrice: chargeByWeightFixedItems?.unitPrice,
                    units: chargeByWeightFixedItems?.units,
                    totalamout: chargeByWeightFixedItems?.unitPrice * chargeByWeightFixedItems?.units,
                  }

                  obj.fixedItems.push(chargeByWeightItemObject)
                  return null
                })
              }
              reArrangOrders.push(obj)
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  }
                  obj.fixedItems.push(fixedItemObject)
                  return null
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightItems) => {
                  let chargeByWeightItemsObject = {
                    fixedItem: chargeByWeightItems?.fixedItem,
                    unitPrice: chargeByWeightItems?.unitPrice,
                    units: chargeByWeightItems?.units,
                    totalamout: chargeByWeightItems?.unitPrice * chargeByWeightItems?.units,
                  }
                  obj.fixedItems.push(chargeByWeightItemsObject)
                  return null
                })
              }
              reArrangOrders.push(obj)
            }
          }
        } else {
          let obj = {
            inboundWeight: item?.inboundWeight,
            outboundWeight: item?.outboundWeight,
            dumpRateType: item?.dumpRateType,
            dumpRate: item?.dumpRate,
            ticketNumber: item?.ticketNumber,
            netWeight: item?.netWeight,
            yardage: item?.yardage,
            tonnage: item?.tonnage,
            totalAmount: item?.totalAmount
          }
          reArrangOrders.push(obj)
        }
        return null
      })
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders
  }

  invoiceDetailCloseModel = () => {
    this.setState({ isModelOpen: false });
  };
  
  showPaymentTypes=(type,payment)=>{
    let paymentType = payment !== "" ? `- ${_.startCase(payment)}` : "";
  let btnClass = "btn btn-for-account";
  if (paymentType === "") {
    paymentType = "ACCOUNT";
  } else {
    paymentType = `COD ${paymentType}`;
  }
  if (paymentType === "COD - Credit Card") {
    btnClass = "btn btn-for-cc";
  } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
    btnClass = "btn btn-for-cash";
  } else if (paymentType === "COD - Terminal") {
    btnClass = "btn btn-for-terminal";
  }
  if(type === "css"){
    return btnClass
  }else{
    return paymentType
  }
  }

  render() {
    const { orders, sortOrder, setOrder, currentPage} = this.props;
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    return (
      <>
        {orders.length === 0 ? (
          <NotFound title="No Orders Found" />
        ) : (
          <div className="table-responsive">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th onClick={() => setOrder("ticketNumber")}>
                    Ticket # {sortOrder["ticketNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("invoiceId")}>
                    Invoice Id {sortOrder["invoiceId"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("completedDate")}>
                    {`Date & Time`} {sortOrder["completedDate"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("customer.displayName")}>
                    Company Name {sortOrder["customer.displayName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("customerRefrence")}>
                    Cust Ref # {sortOrder["customerRefrence"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("netWeight")}>
                    Net Weight(lbs) {sortOrder["netWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("tonnage")}>
                    Tonnage {sortOrder["tonnage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("yardage")}>
                    Yardage {sortOrder["yardage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("totalAmount")}>
                    Total Order Amount {sortOrder["totalAmount"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("paymentType")}>
                    Payment Type {sortOrder["paymentType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => {
                 
                  return (
                    <tr className="cursor-pointer" key={order._id}>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>{order.ticketNumber}</td>
                      <td className={`${order?.invoiceId ? 'underline' : ''}`} onClick={() => { this.getinvoiceDetailData(order?.invoiceId) }} >{order?.invoiceId ? order?.invoiceId : '-'}</td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>{order?.isDeleted ? `${moment(order?.updatedAt)?.tz(tz)?.format("MM/DD/YY")} ${moment(order?.updatedAt)?.tz(tz)?.format(
                        "hh:mm a"
                      )}` :`${moment(order.completedDate)?.tz(tz)?.format("MM/DD/YY")} ${moment(order.completedDate)?.tz(tz)?.format(
                        "hh:mm a"
                      )}`}</td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>{order.customer.displayName}</td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)} ><div> {this.truncateText(order.customerRefrence, 20) || '-'}</div></td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>
                        { order.netWeight > 0 ? <>
                       <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} />  lbs </> : "-" }
                      </td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={Number(order.tonnage)?.toFixed(2)} />
                      </td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.yardage} />
                      </td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.totalAmount}
                        />
                      </td>
                      <td onClick={() => this.navigate(order.ticketNumber, currentPage)}>
                        {order?.paymentTerms === "account"?
                        <button className={this.showPaymentTypes("css",'')}>{this.showPaymentTypes("paymentType",'')}</button>
                        : order.paymentType === "" ? order.splitPayment.length > 0 && order.splitPayment.map((payment,index)=>{
                          return(
                          <button className={(this.showPaymentTypes("css",payment.paymentType)) + ( index < order.splitPayment.length-1 ? " mr-2" : "")}>{this.showPaymentTypes("paymentType",payment.paymentType)}</button>)
                        }) 
                        :
                        <button className={this.showPaymentTypes("css",order.paymentType)}>{this.showPaymentTypes("paymentType",order.paymentType)}</button>
                       }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {this.state.isModelOpen && (
          <InvoiceDetailComponent
            updateinvoiceDetailData={this.updateinvoiceDetailData}
            isModelOpen={this.state.isModelOpen}
            closeModel={this.invoiceDetailCloseModel}
            modelData={this.state.modelData}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            reArrangOrdersData={this.reArrangOrdersData}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            fetchOrder={this.props.fetchOrder}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            {...this.props}
          />
        )}
          </div>
        )}
      </>
    );
  }
}
