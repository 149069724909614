import { connect } from 'react-redux'
import { getMe ,updateUserToken,fetchMe} from '../../store/user/duck'
import { fetchTransaction } from "../../store/transaction/duck";
import CashLogDetailsComponent from './component'

const CashLogDetailsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    updateUserToken,
    fetchTransaction
  }
)(CashLogDetailsComponent);

export default CashLogDetailsContainer
