import React, { PureComponent } from "react";
import "../styles.scss";
import { loadPayengine } from "payengine";
import moment from "moment-timezone";
import { getEndDate, getStartDate, numberFormat } from "../../../constants/common";
import NotFound from "../../NoRecords/component";
import { ArrowIcon } from "../../../components/icons";
import DateRangePicker from "../../../components/forms/DateRangePicker";
import { Pagination } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class CreditCardTransactionsComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);

    this.state = {
      toggleMenu: false,
      activeUrl: "",
      startDate: props.startDate,
      endDate: props.endDate,
      dateType: "today",
      transactions: [],
      selectedDate: "",
      datePickerModal: false,
      payabliPayouts: {
        Records: []
      },
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      isPayoutModelOpen: false,
      payoutDate: "",
      spinning:false,
      payengine: null,
      user: {
        company: {}
      },
      users: [],
      userId: "none",
      paybliEnable:false,
      tz
    };
  }
  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));
  getFromLocalStorage = async ({ dateType, startDate, endDate }) => {
    await this.setAsyncState({
      startDate: getStartDate(dateType, startDate)?.tz(this.state?.tz)?.toDate(),
      endDate: getEndDate(dateType, endDate)?.tz(this.state?.tz)?.toDate(),
      dateType,
      selectedDate: `${getStartDate(dateType, startDate)?.tz(this.state?.tz)?.format(
        "MMM DD, YYYY"
      )} - ${getEndDate(dateType, endDate)?.tz(this.state?.tz)?.format("MMM DD, YYYY")}`,
    });
  };
  componentDidMount = async () => {
    document.title = "Credit Card Transactions | Weighworks";
    const { fetchMe } = this.props;
    const {
      value: { user }
    } = await fetchMe();
    loadPayengine({
      publicKey: process.env.REACT_APP_PAY_ENGINE_PUBLIC_KEY
    }).then((pe) => {
      this.setState({ user, payengine: new pe() })
    });
    this.fetchTransactions()
    this.getPaybliIntegration()
    this.fetchCompanyUsers()
   
  }

  componentDidUpdate = async () => {
    let startDate = this.props.date.startDate
    let endDate = this.props.date.endDate
    if (this.state.startDate !== startDate || this.state.endDate !== endDate) {
      this.setState({ startDate: startDate, endDate: endDate },()=>{
        this.fetchPayabliPayouts();
      })
      this.fetchTransactions(startDate, endDate, this.state.userId);
    }
  }
  getPaybliIntegration = async () => {
    const {
      value: { status },
    } = await this.props.getPaybliIntegration();
    if (status) {
      this.fetchPayabliPayouts()
      this.setState({ paybliEnable: true });
    }
  };

  fetchPayabliPayouts = async () => {
    const payload = { limit: this.state.limit, skip: this.state.skip, sDate: moment(this.state.startDate).format("YYYY-MM-DD") , eDate: moment(this.state.endDate).format("YYYY-MM-DD") }
    this.setState({spinning:true})
    const {
      value: { data }
    } = await this.props.getPayabliTransactions(payload)
    this.setState({ payabliPayouts: data, spinning:false,totalItems: data?.Summary.totalRecords })
  }

  fetchTransactions = async (startDate = this.state.startDate, endDate = this.state.endDate, userId = this.state.userId ) => {
    const { dateType } = this.state;
    const localStorageData = {
      startDate,
      endDate,
      dateType,
    };
    localStorage.setItem("date", JSON.stringify(localStorageData));
    startDate = moment(startDate)?.tz(this.state.tz).toISOString()
    endDate = moment(endDate)?.tz(this.state.tz).toISOString()
    // startDate = startDate + " 00:00:00"
    // endDate = endDate + " 23:59:00"
    const {
      value: { data }
    } = await this.props.fetchTransactions({
      type: "card",
      startDate: startDate,
      endDate: endDate,
      timezone: moment().format("Z"),
      userId: userId
    });
    this.setState({ transactions: data });
  };

  setDate(e) {
    const val = e.target.value;
    if (val === "today") {
      this.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
          endDate: moment()?.tz(this.state.tz)?.endOf('day')?.toDate(),
        },
        () => {
          this.fetchPayabliPayouts()
          this.fetchTransactions();
        }
      );
    } else if (val === "this week") {
      this.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate: moment().clone()?.tz(this.state?.tz)?.startOf("isoWeek").toDate(),
          endDate: moment().clone()?.tz(this.state?.tz)?.endOf("isoWeek")?.toDate(),
        },
        () => {
          this.fetchPayabliPayouts()
          this.fetchTransactions();
        }
      );
    } else if (val === "this month") {
      this.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate: moment()?.tz(this.state?.tz)?.startOf("month")?.toDate(),
          endDate: moment()?.tz(this.state?.tz)?.endOf("month")?.toDate(),
        },
        () => {
          this.fetchPayabliPayouts()
          this.fetchTransactions();
        }
      );
    } else if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  }

  activePayOutTab = () => {
    this.setState({ activeUrl: "/billing/credit-card-transactions/payouts" })
    this.props.navigateFunction("/billing/credit-card-transactions/payouts")
    //  this.props.history.push("/billing/credit-card-transactions/payouts")
  }
  activeTransactionTab = () => {
    this.setState({ activeUrl: "/billing/credit-card-transactions/tranasactions" })
    this.props.navigateFunction("/billing/credit-card-transactions/tranasactions")
    // this.props.history.push("/billing/credit-card-transactions/tranasactions")
  }

  closeModal = () => {
    this.setState({ datePickerModal: false })
  }
  closeModel = () => {
    this.setState({ isPayoutModelOpen: false, })
    this.props.navigateFunction("/billing/credit-card-transactions/payouts")
  }
  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchPayabliPayouts();
    });
  };
  fetchCompanyUsers = async () => {
    const { fetchCompanyUsers } = this.props;
    const {
      value: { data: { users } }
    } = await fetchCompanyUsers({searchText:"",limit:100,skip:0, getInactiveUsers: false});
    this.setState({ users });
  };
  handleUserChange = (e) => {
    const {value} = e.target;
    this.setState({ userId: value },()=>{
      this.fetchTransactions(this.state.startDate, this.state.endDate, value);
    })
  }
  render() {
    const numberWithCommas = (x) => {
      var parts = x.toFixed(2).split(".");
      var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
      return num;
    };
    const { transactions,user, startDate, endDate,users } = this.state
    const self = this;
    return (
      <div className="credit-card-transactions-wrapper">
        <div className="custome__tabs">
          <ul className="custome__tabs-list">
            <li>
              <span className={this.props.pathname === "/billing/credit-card-transactions/tranasactions" ? "active" : ""}
                onClick={() => { this.activeTransactionTab(); this.fetchTransactions(startDate, endDate,this.state.userId) }}>Transactions</span>
            </li>
            <li>
              <span className={this.props.pathname === "/billing/credit-card-transactions/payouts" ? "active" : ""}
                onClick={() => {this.activePayOutTab();this.fetchPayabliPayouts();}}>Payouts</span>
            </li>
            <li className="ml-auto mt-4">
              
              <div className="cursor-pointer " >
                <div className="form-group material-textfield">
                  <select
                    className="form-control custom-select  material-textfield-input user-select-all"
                    name="userId"
                    onChange={(e)=>this.handleUserChange(e)}
                    value={this.state.userId}
                  >
                    <option value="none">None</option>
                    {users && users.length > 0 ? users?.sort((a, b) => (a.firstName + a.lastName)?.localeCompare(b.firstName + b.lastName))?.map((user) => {
                      return (
                        <option key={user._id} value={user._id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    }):null
                    }
                  </select>
                  <label className="material-textfield-label">
                  User
                  </label>
                </div>
                </div>
            </li>
          </ul>

        </div>
        {this.props.pathname === "/billing/credit-card-transactions/payouts" &&
         <Spin indicator={antIcon} spinning={this.state.spinning}>
          <section className="table__section">
            {this.state.paybliEnable ?
            <div className="card">
              <div className="table-responsive">
                <table className="table custom-table fixTableHead">
                  <thead>
                    <tr>
                      <th className="first-child">Payment Date</th>
                      <th>Charges</th>
                      <th>Payabli Fees</th>
                      <th>Total</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.payabliPayouts?.Records.length > 0 && this.state.payabliPayouts?.Records.map(function (data) {
                      const date = new Date(data?.TransactionTime);
                      const formattedDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });
                      const parts = formattedDate.split('/');
                      const ddmmYYYY = parts[1] + '-' + parts[0] + '-' + parts[2];
                      
                      return (
                        <tr>
                          <td onClick={() => {
                            self.setState({ isPayoutModelOpen: true, payoutDate: data?.TransactionTime, }, () => {
                              self.props.navigateFunction({
                                pathname: "/billing/credit-card-transactions/payouts/details",
                                state: {
                                  date: data?.TransactionTime
                                }
                              })
                            })
                          }} className="first-child  underline ">{ddmmYYYY}</td>
                          <td>${data?.NetAmount && numberWithCommas(Number(data?.NetAmount))}</td>
                          <td>${data?.FeeAmount}</td>
                          <td>${data?.TotalAmount && numberWithCommas(Number(data?.TotalAmount))}</td>
                          <td>
                            {data?.SettlementStatus === 2 ?
                              <button className="btn status-btn btn-green">Paid</button> :
                              <button className="btn status-btn btn-blue">
                                In Transit
                              </button>}
                          </td>
                        </tr>
                      )
                    })}
                    {this.state.payabliPayouts?.Records.length  === 0 && 
                      <div className="empty__section">
                        <div className="empty__section-inner">
                          <p className="empty__text">
                            No Payouts
                          </p>
                        </div>
                      </div>
                    }
                  </tbody>
                </table>
                <Pagination
                  className="text-center"
                  onChange={this.onChangePage}
                  hideOnSinglePage
                  current={this.state.currentPage}
                  total={this.state.totalItems}
                  pageSize={this.state.limit}
                  showSizeChanger={false}
                />
              </div>
            </div>:
            <>
            <div className="page-header-inner">
            <h4 className="heading-title">Payouts List</h4>
          </div>
          <div className="card">
            <pay-engine id="pf-payouts" 
              type="payouts" 
              merchant-id={user.company.payEngineMerchantId}
              hash={user.company.merchantHash}></pay-engine>
          </div>
          </>}
          </section>
          <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format(
                  "MMM DD, YYYY"
                )} - ${moment(this.state.endDate).format("MMM DD, YYYY")}`,
              },
              () => {
                this.closeModal();
                this.fetchPayabliPayouts()
                this.fetchTransactions();
              }
            );
          }}
          handleFromChange={(date) =>
            this.setState({ startDate: moment(date)?.tz(this.state?.tz)?.startOf('day').toDate() })
          }
          handleToChange={(date) =>
            this.setState({ endDate: moment(date)?.tz(this.state?.tz)?.endOf('day').toDate() })
          }
          from={this.state.startDate}
          to={this.state.endDate}
        />
          </Spin>
          
        }
        {
          this.props.pathname === "/billing/credit-card-transactions/tranasactions" &&
          <>
            {transactions.length === 0 ? (
              <NotFound title="No Transactions" />
            ) : (
              transactions.map(transaction => {
                return (
                  <div className="row" key={transaction._id}>
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header d-flex align-items-start justify-content-between">
                          <h5 className="card-title">
                            {moment(transaction._id)?.tz(this.state?.tz)?.format("MMM DD, YYYY")} Balance{" "}
                          </h5>
                          <p className="card-title-sm">
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                // this.props.history.push({
                                //   pathname: `/itemized-credit-card-transactions/${transaction._id}`
                                // })
                                this.props.navigateFunction(`/itemized-credit-card-transactions/${transaction._id}?userId=${this.state.userId}`)
                              }
                            >
                              View Itemized List
                              <ArrowIcon />
                            </span>
                          </p>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="transactions__list">
                                <li>
                                  <div>
                                    <h5 className="transactions__title">Credit Card Transations</h5>
                                    <p className="transactions__sub-title">
                                      {transaction.totalTransactions === 1
                                        ? `${transaction.totalTransactions} transaction`
                                        : `${transaction.totalTransactions} transactions`}
                                    </p>
                                  </div>
                                  <div className="text-amount text-green">${numberFormat(transaction.totalAmount)}</div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          {(!this.state.paybliEnable && user.company.payEngineMerchantId) && 
            <>
            <div className="page-header-inner">
              <h4 className="heading-title">Transaction List</h4>
            </div>
            <div className="card">
              <pay-engine id="pf-transactions" 
                type="transactions" 
                merchant-id={user.company.payEngineMerchantId}
                print-receipt="true"
                hash={user.company.merchantHash}></pay-engine>
            </div>
            </>
          }              
          </>
        }
        <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format(
                  "MMM DD, YYYY"
                )} - ${moment(this.state.endDate).format("MMM DD, YYYY")}`,
              },
              () => {
                this.closeModal();
                this.fetchPayabliPayouts()
                this.fetchTransactions();
              }
            );
          }}
          handleFromChange={(date) =>
            this.setState({ startDate: moment(date)?.tz(this.state?.tz)?.startOf('day').toDate() })
          }
          handleToChange={(date) =>
            this.setState({ endDate: moment(date)?.tz(this.state?.tz)?.endOf('day').toDate() })
          }
          from={this.state.startDate}
          to={this.state.endDate}
        />
      
      </div>
    );
  }
}
