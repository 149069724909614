import React, { PureComponent } from 'react';
import { Formik, ErrorMessage } from 'formik';
// import InputMask from "react-input-mask";
import { CloseIcon } from '../../components/icons';
import { scaleSchema } from '../../utils/validations';
import ReactModal from 'react-modal';
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';
export default class ScaleSetupComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        scaleName: '',
        scaleIp: '',
        scalePort: undefined,
        scaleLocation: '',
      },
      isDisable:false,
      scale: null,
      isEdit: false,
      PleaseWait:false,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    // this.props.getMe();
    document.title = 'Scale Setup | Weighworks';
    let ScaleIdArray = window.location.pathname.split('/');
    if (ScaleIdArray[2]) {
      const payload = { id: String(ScaleIdArray[2]) };
      const {
        value: { data },
      } = await this.props.getOneScale(payload);
      this.setState({
        isEdit: true,
        initialValues: data.scale,
      });
    }
  };

  static getDerivedStateFromProps({ scale }, prevState) {
    if (scale && scale !== prevState.scale) {
      const { scaleName, scaleIp, scalePort, scaleLocation } = scale;
      const initialValues = {
        scaleName,
        scaleIp,
        scalePort,
        scaleLocation,
      };
      return { initialValues, scale };
    }
    return null;
  }

  handleSubmit = async (values, {setErrors }) => {
    this.setState({isDisable:true,PleaseWait:true})
    let payload = {
      scaleName: values.scaleName,
      scaleIp: values.scaleIp,
      scalePort: values.scalePort,
      scaleLocation: values.scaleLocation,
    };
    if (values.scaleLocation === '') {
      // delete payload.scaleLocation;
      delete payload['scaleLocation'];
    }

    if (this.state.isEdit === false) {
      const {
        value: { status, message },
      } = await this.props.createScale(payload);
      if (status) {
        setTimeout(()=>{
          this.setState({isDisable:false})
          this.props.onCloseClick();
        }, 800);
      } else {
        setErrors({ scaleIp: message });
      }
    } else {
      this.updateScale(values);
    }
  };

  updateScale = async (values) => {
    this.setState({isDisable:true})
    const payload = {
      scaleName: values.scaleName,
      scaleIp: values.scaleIp,
      scalePort: values.scalePort,
      scaleLocation: values?.scaleLocation,
    };
    const {
      value: { status },
    } = await this.props.updateScale(payload, this.state.initialValues._id);
    if (status) {
       setTimeout(()=>{
        this.setState({isDisable:false})
         this.props.onCloseClick();
       }, 500);
      
    } 
  };

  render() {
    const { initialValues } = this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.isAddNewScaleModelOpen}
          onRequestClose={this.props.onCloseClick}
          // className='scale__setting-modal'
          >
          <div className='react-modal-dialog react-modal-dialog-centered HeightAutoModal'>
            <div className='react-modal-header'>
              <h5 className='react-modal-title'>Scale Setup</h5>
              <button
                type='button'
                className='btn react-modal-close'
                onClick={this.props.onCloseClick}>
                <CloseIcon />
              </button>
            </div>
            <div className='react-modal-body'>
              <div className='scale-setting-container'>
                <div className='scale-setting-wrapper'>
                  {/* <div className='card'> */}
                    {/* <div className='card-header d-flex align-items-center'>
                      <h5 className='card-title'>Scale Information</h5>
                    </div> */}
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={scaleSchema}
                      onSubmit={(values, formikProps) =>
                        this.handleSubmit(values, formikProps)
                      }>
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setValues,
                      }) => (
                        <form onSubmit={handleSubmit} noValidate>
                          <div className='card-body'>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='form-group material-textfield'>
                                  <input
                                    type='text'
                                    name='scaleName'
                                    className='form-control material-textfield-input'
                                    onChange={handleChange}
                                    value={values && values.scaleName}
                                    required
                                  />
                                  <ErrorMessage
                                    component='span'
                                    name='scaleName'
                                    className='invalid-feedback d-block'
                                  />
                                  <label className='material-textfield-label'>
                                    Scale Name<span>*</span>
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-12'>
                                <div className='form-group material-textfield'>
                                  <input
                                    type='text'
                                    name='scaleIp'
                                    className='form-control material-textfield-input'
                                    onChange={handleChange}
                                    value={values && values.scaleIp}
                                    required
                                  />
                                  <ErrorMessage
                                    component='span'
                                    name='scaleIp'
                                    className='invalid-feedback d-block'
                                  />
                                  <label className='material-textfield-label'>
                                    Scale IP<span>*</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='form-group material-textfield'>
                                  <input
                                    type='text'
                                    name='scalePort'
                                    className='form-control material-textfield-input'
                                    onChange={handleChange}
                                    value={values && values.scalePort}
                                    required
                                  />
                                  <ErrorMessage
                                    component='span'
                                    name='scalePort'
                                    className='invalid-feedback d-block'
                                  />
                                  <label className='material-textfield-label'>
                                    Scale Port<span>*</span>
                                  </label>
                                </div>
                              </div>
                              <div className='col-md-12'>
                                <div className='form-group material-textfield'>
                                  <input
                                    type='text'
                                    name='scaleLocation'
                                    className='form-control material-textfield-input'
                                    onChange={handleChange}
                                    value={values && values.scaleLocation}
                                    // required={false}
                                  />
                                  <ErrorMessage
                                    component='span'
                                    name='scaleLocation'
                                    className='invalid-feedback d-block'
                                  />
                                  <label className='material-textfield-label'>
                                    Scale Location (optional)
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button
                              type='submit'
                              disabled={this.state.isDisable}
                              className='btn btn-dark btn-lg w-100'
                              // onClick={notify}
                            >
                              {this.state.PleaseWait? "Please wait..." : "Save"} 
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
