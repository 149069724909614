import React, { PureComponent } from "react";
import { Popover, message } from "antd";
import { get } from "lodash";
import Moment from "moment-timezone";
import ReactHtmlParser from 'react-html-parser';
import io from "socket.io-client";
import { CloseIcon, BellIcon } from "../../../components/icons";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME
let socket = null;
message.config({ maxCount: 1 });
export default class Notifications extends PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      open: false,
      isSocketConnected: false,
      unreadCount: 0,
      notifications: [],
      unreadNotificationIds: []
    };
  }
  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const userId = get(this.props, 'user._id', false);
    const { isSocketConnected } = this.state;
    if (!isSocketConnected) {
      socket = io.connect(`${HOSTNAME}`);
    }
    this.getAllUnreadNotificationCount();
    const onReceiveNotificationEvent = (value) => {
      // message.info(ReactHtmlParser(value.message));
      const ticketNumber = value?.ticketNumber;
      const _id = value?.notificationId;
      console.log(value.message,"notifications")
      const notificationMessage = value.message;
      message.info(notificationMessage);
      message.info(<span>Unable to process payment for Order <span className="toast-anchor" onClick={() => this.handleOpenOrderDetail({ticketNumber, _id})}>#{ticketNumber}</span></span>);
    }
    const IncreaseUnreadCount = () => {
      this.getAllUnreadNotificationCount();
    }
    socket.on(`notifications:toast:${userId}`, onReceiveNotificationEvent);
    socket.on(`notifications:count`, IncreaseUnreadCount);
    return () => {
      socket.off(`notifications:toast:${userId}`, onReceiveNotificationEvent);
      socket.off(`notifications:count`, IncreaseUnreadCount);
      // socket.close();
    };
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.setState({ open: false });
    }
  }
  componentWillUnmount() {
    socket.close();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  async getAllNotifications() {
    const { getNotifications } = this.props;
    const {
      value: { notifications, unreadNotificationCount }
    } = await getNotifications();
    this.setState({ notifications, unreadCount: unreadNotificationCount })
  };
  async getAllUnreadNotificationCount() {
    const { getUnreadNotificationCount } = this.props;
    const {
      value: { unreadNotificationCount, unreadNotificationIds }
    } = await getUnreadNotificationCount();
    this.setState({ unreadCount: unreadNotificationCount, unreadNotificationIds })
  };
  handleOpenChange() {
    this.setState({ open: true });
  };
  handleClose() {
    this.getAllUnreadNotificationCount();
    this.setState({ open: false });
  };
  formatDate(date) {
    const isToday = Moment(date).isSame(Moment(), 'day');
    if (isToday) {
      return `Today | ${Moment(date).format('h:mm a')}`
    }
    return `${Moment(date).format('MM/DD/YYYY')} | ${Moment(date).format('h:mm a')}`
  }
  async handleOpenOrderDetail(payload) {
    const { readNotifications } = this.props;
    const { _id, ticketNumber } = payload;
    if (_id) {
      await readNotifications({ notificationId: _id });
    }
    this.props.history.push({
      pathname: `/order-details/${ticketNumber}`,
      param: 'notification',
      currentPath: window?.location?.pathname
    })
  }
  async clearAllNotifications() {
    const { notifications } = this.state;
    const { clearNotifications } = this.props;
    await clearNotifications({ notificationIds: notifications });
    this.setState({ notifications: [], unreadCount: 0 });
  }
  render() {
    const { unreadCount, notifications, open } = this.state;
    const content = (
      <div ref={this.wrapperRef}>
        <div className="notification-modal">
          <div className="notification-header">
            <h2 className="title">Notifications</h2>
            <button className="close-btn" onClick={() => this.handleClose()}><CloseIcon className="icon" /></button>
            <button className="clear-btn" onClick={() => this.clearAllNotifications()}>Clear All</button>
          </div>
          <div className="notification-body">
            <ul className="notification-list">
              {
                notifications.map((n, i) => (<li key={i} className="notification-items" onClick={() => this.handleOpenOrderDetail(n)}>
                  <div className="notification-content">
                    <h2 className="notification-title">{n.title}</h2>
                    <p className="notification-sub-title">{ReactHtmlParser(n.message)}</p>
                    <p className="notification-sub-title">{this.formatDate(n.createdAt)}</p>
                  </div>
                  {!n.read && <div className="dot"></div>}
                </li>))
              }
              {
                notifications.length === 0 && <li className="notification-items">
                  <div>
                    <p className="notification-sub-title">You have no notifications</p>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    );
    return (
      <div className="notification__dropdown ml-auto mr-4">
        <Popover
          content={content}
          open={open}
          onOpenChange={() => this.handleOpenChange()}
          trigger="click"
          overlayClassName="notification__popover"
        >
          <button className="notification__dropdown-inner" onClick={() => this.getAllNotifications()}>
            <BellIcon />
            {unreadCount > 0 && <div className="notification__count">{unreadCount}</div>}
          </button>
        </Popover>
      </div>
    )
  }
}