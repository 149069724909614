import React, { PureComponent } from "react";
import moment from "moment-timezone";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import MaterialReport from "./partials/material-report";
import ClientReport from "./partials/client-report";
import CashDiscrepancy from "./partials/cash-discrepancy-report";
import DeletedOrderReport from "./partials/deleted-order-report";
import _ from "lodash";
import { BackArrowIcon, ExportIcon, CloseIcon, FilterIcon,DateIcon } from "../../components/icons";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
} from "date-fns";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";

import { Drawer } from "antd";
import "./styles.scss";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export default class WorkReportComponent extends PureComponent {
  constructor(props) {
    const tz =  localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);

    this.state = {
      data: [],
      openDatePicker: false,
      startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
      endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
      dashboardData: [],
      selectedDate: "",
      dateType: "today",
      datePickerModal: false,
      randomNumber: 0,
      customers: [],
      filterCustomers: [],
      visible: false,
      customerSearchText: "",
      status: 1,
      sortOrder: { companyName: 1 },
      locations: [],
      filterLocations: [],
      locationSearchText: "",
      locationFilter: false,
      customerFilter: false,
      dateFilter: false,
      typingTimeout: 0,
      isExportData: false,
      toggleMenu: false,
      searchText: "",
      pageSize: 100,
      tz,
      DateRangePickerDroDown: false,
      date: {
        startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
        endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
        key: 'selection'
      },
      InboundMaterial:"",
      OutboundMaterial:"",
      OutboundSaleMaterial:""
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  closeModal = () => {
    this.setState({ datePickerModal: false });
  };

  clearFilter = () => {
    this.setState({
      dateFilter: false,
      customerFilter: false,
      from: "",
      to: "",
      filterCustomers: [],
      filterLocations: [],
      locationFilter: false,
      // visible: false,
      randomNumber: Math.random()
    });
  };

  setDate(e) {
    const val = e.target.value;
    if (val === "today") {
      this.setState({
        dateType: "today",
        selectedDate: "",
        startDate: moment()?.clone()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
        endDate: moment()?.clone()?.tz(this.state?.tz)?.endOf('day')?.toDate(),
        randomNumber: Math.random()
      });
    } else if (val === "this week") {
      this.setState({
        dateType: "this week",
        selectedDate: "",
        startDate: moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.startOf("isoWeek")
          ?.toDate(),
        endDate: moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.endOf("isoWeek")
          ?.toDate(),
        randomNumber: Math.random()
      });
    } else if (val === "this month") {
      this.setState({
        dateType: "this month",
        selectedDate: "",
        startDate: moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.startOf("month")
          ?.toDate(),
        endDate: moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.endOf("month")
          ?.toDate(),
        randomNumber: Math.random()
      });
    } else if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  }

  openDatePicker = e => {
    const val = e.target.value;
    if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  };

  handleSearch = event => {
    const eventName = event.target.name;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      [event.target.name]: event.target.value,
      typingTimeout: setTimeout(() => {
        if (eventName === "locationSearchText") {
          this.fetchLocationsList();
        }
        if (eventName === "customerSearchText") {
          this.fetchCustomersList();
        }
      }, 800)
    });
  };

  openFilterList = e => {
    this.setState({
      locationFilter: false,
      customerFilter: false,
      [e.target.name]: e.target.checked
    });
  };

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
    this.fetchCustomersList();
    this.fetchLocationsList();
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  }

  selectLocation(location) {
    const locations = [...this.state.filterLocations];
    const index = _.findIndex(locations, function(c) {
      return String(c) === String(location);
    });
    if (index === -1) {
      locations.push(location);
    } else {
      locations.splice(index, 1);
    }
    this.setState({ filterLocations: locations });
  }

  fetchCustomersList = async () => {
    const { fetchCustomers } = this.props;
    const { customerSearchText, status, sortOrder } = this.state;
    const {
      value: {
        data: { customers }
      }
    } = await fetchCustomers({ searchText: customerSearchText, status, sortOrder, limit: 5000 });
    this.setState({ customers });
  };

  fetchLocationsList = async () => {
    const { fetchLocationsList } = this.props;
    const { locationSearchText } = this.state;
    const {
      value: { data }
    } = await fetchLocationsList({ searchText: locationSearchText });
    this.setState({ locations: _.get(data, "[0].data", []) });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  applyFilter = () => {
    this.setState({ visible: false, randomNumber: Math.random() });
  };

  getTitle(pathname) {
    let title = "Client & Job Report";
    switch (pathname) {
      case "/job-report":
        title = "Job Report";
        break;
      case "/material-report":
        title = "Inbound vs Outbound Report";
        break;
      case "/cash-register-report":
        title = "Cash Register Report";
        break;
      case "/cash-descrepancy-report":
        title = "Cash Discrepancy Form/Log Report";
        break;
      case "/deleted-tickets-report":
        title = "Voided Report";
        break;
      default:
        title = "Client & Job Report";
    }
    return title;
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  selectCustomer(customer) {
    const customers = [...this.state.filterCustomers];
    const index = _.findIndex(customers, function(c) {
      return String(c._id) === String(customer._id);
    });
    if (index === -1) {
      customers.push({
        _id: String(customer._id),
        companyName: escape(customer.displayName)
      });
    } else {
      customers.splice(index, 1);
    }
    this.setState({ filterCustomers: customers });
  }
  handleSelect = (ranges) => {
    ranges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: moment(ranges.selection.startDate)?.startOf("day").toDate(),
        endDate: moment(ranges.selection.endDate)?.endOf("day")?.toDate()
      }
    }
    this.setState({
      date: ranges.selection,
      // isPaymentsDateRangePickerOpen: false
    });
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate,randomNumber: Math.random(),selectedDate: "", })
  }
  handleClickOutside = (event) => {
    if (this.dateRangePickerRef && !this.dateRangePickerRef.contains(event.target)) {
      this.setState({ DateRangePickerDroDown: false });
    }
  };

  handleValueChange = (e) => {
    if (e.target.name === "InboundMaterial") {
      this.setState({ InboundMaterial: e.target.value});
      
    }else if (e.target.name === "OutboundMaterial") {
      this.setState({ OutboundMaterial: e.target.value});
    }
    else if (e.target.name === "OutboundSaleMaterial") {
      this.setState({ OutboundSaleMaterial: e.target.value});
    }
  }


  render() {
    const {
      startDate,
      endDate,
      randomNumber,
      customers,
      filterCustomers,
      locations,
      filterLocations,
      isExportData,
      searchText,
      pageSize
    } = this.state;
    const {
      location: { pathname },
      user
    } = this.props;
    return (
      <div>
        <div className="layout-has-sidebar">
          <TopNavigation
            {...this.props}
            searchText={searchText}
            handleSearchChange={this.handleSearch}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
         
          <main className="dashboard-layout-content work__repot-wrapper">
            <div className="btn-back-header">
              <button className="btn btn-link btn-back">
                <Link to={"/reports"}>
                  <BackArrowIcon />
                  Back to Reports
                </Link>
              </button>
            </div>
            <div className="page-header" ref={ref => this.dateRangePickerRef = ref}>
              <h2 className="heading-title">{this.getTitle(pathname)} </h2>
              <div className="page-header-inner">
                {pathname === "/client-report" && (
                  <div>
                    <button
                      onClick={this.showDrawer}
                      // onClick={() => this.setState({ isCreateDrawerOpen: true })}
                      className="btn btn-dark btn-sm btn-exportorders"
                    >
                      <FilterIcon />
                      Filters
                    </button>
                  </div>
                )}
                {<button
              className="btn btn-dark btn-sm btn-exportorders" onClick={() => { this.setState({ DateRangePickerDroDown: !this.state.DateRangePickerDroDown }) }}>
                <DateIcon />
              {this.state.date.startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} - {this.state.date.endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
            </button>}
            {this.state.DateRangePickerDroDown &&
              <DateRangePicker
              className="datepicker-orders"
                //onChange={(e)=>{this.handleSelect(e.dateRange) ;this.deshboardPayments(e.target.value); this.paymentDatePicker()}}
                onChange={this.handleSelect}
                ranges={[this.state.date]}
                editableDateInputs={true}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                calendarFocus="forwards"
                staticRanges={[
                  ...defaultStaticRanges,
                  {
                    label: "Last Year",
                    range: () => ({
                      startDate: startOfYear(addYears(new Date(), -1)),
                      endDate: endOfYear(addYears(new Date(), -1))
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  },
                  {
                    label: "This Year",
                    range: () => ({
                      startDate: startOfYear(new Date()),
                      endDate: endOfDay(new Date())
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  }
                ]}

              />}
                <div className="form-group material-textfield ml-3">
                  <select
                    onChange={e => this.setState({ pageSize: e.target.value })}
                    className="form-control custom-select w-150 material-textfield-input pr-22"
                    required
                  >
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={300}>300</option>
                  </select>
                  <label className="material-textfield-label">Results</label>
                </div>
              </div>
            </div>
            {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.exportOrdersAndReports === true) ?
            <div>
              {pathname !== "/material-report" ? (
              <div
                className="export__section"                
              >
                <div className="cursor-pointer d-flex" onClick={() => this.setState({ isExportData: true }, () => this.setState({ isExportData: false }))}>
                  <ExportIcon />
                  <h3>Export</h3>
                </div>
              </div>
            ) : (
              <a
                className="export__section cursor-pointer w-25"
                href={`${HOSTNAME}/download-excel?startDate=${moment(startDate)
                  .clone()
                  .startOf("day")
                  .toISOString()}&endDate=${moment(endDate)
                  .clone()
                  .endOf("day")
                  .toISOString()}&token=${localStorage.getItem("Authorization")}
                  ${this.state?.InboundMaterial?.length > 0 && this.state?.InboundMaterial !== "All" ?`&InboundMaterial=${encodeURIComponent(this.state.InboundMaterial)}`:""}
                  ${this.state?.OutboundSaleMaterial?.length > 0 && this.state?.OutboundSaleMaterial !== "All" ?`&OutboundSaleMaterial=${encodeURIComponent(this.state.OutboundSaleMaterial)}`:""}
                  ${this.state?.OutboundMaterial?.length > 0 && this.state?.OutboundMaterial !== "All" ? `&OutboundMaterial=${encodeURIComponent(this.state.OutboundMaterial)}`:""}`}
              >
                <ExportIcon />
                <h3>Export</h3>
              </a>
            )}
            </div> :null}
            {pathname === "/material-report" && (
              <MaterialReport
                {...this.props}
                key={randomNumber}
                startDate={moment(startDate)
                  .clone()
                  ?.tz(this.state?.tz)
                  .toISOString()}
                endDate={moment(endDate)
                  .clone()
                  ?.tz(this.state?.tz)
                  .toISOString()}
                isExportData={isExportData}
                pageSize={pageSize}
                setPageSize={size => this.setState({ pageSize: size })}
                handleValueChange={this.handleValueChange}
                inboundMaterial={this.state.InboundMaterial}
                outboundMaterial={this.state.OutboundMaterial}
                outboundSaleMaterial={this.state.OutboundSaleMaterial}
                getInboundOutboundMaterials={this.props.getInboundOutboundMaterials}
              />
            )}
            {pathname === "/client-report" && (
              <ClientReport
                {...this.props}
                key={randomNumber}
                filterCustomers={filterCustomers}
                filterLocations={filterLocations}
                startDate={moment(startDate)
                  ?.clone()
                  ?.tz(this.state?.tz)
                  ?.startOf("day")
                  ?.toISOString()}
                endDate={moment(endDate)
                  ?.clone()
                  ?.tz(this.state?.tz)
                  ?.endOf("day")
                  ?.toISOString()}
                isExportData={isExportData}
                pageSize={pageSize}
              />
            )}
            {pathname === "/cash-descrepancy-report" && (
              <CashDiscrepancy
                {...this.props}
                key={randomNumber}
                startDate={moment(startDate)
                  ?.clone()
                  ?.tz(this.state?.tz)
                  ?.startOf("day")
                  ?.toISOString()}
                endDate={moment(endDate)
                  ?.clone()
                  ?.tz(this.state?.tz)
                  ?.endOf("day")
                  ?.toISOString()}
                isExportData={isExportData}
                pageSize={pageSize}
              />
            )}
            {pathname === "/deleted-tickets-report" && (
              <DeletedOrderReport
                {...this.props}
                key={randomNumber}
                startDate={moment(startDate)
                  ?.startOf("day")
                  ?.toISOString()}
                endDate={moment(endDate)
                  ?.endOf("day")
                  ?.toISOString()}
                timeZone={this.state?.tz}
                isExportData={isExportData}
                pageSize={pageSize}
              />
            )}
          </main>
        </div>
        {/* <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).clone().format("MMM DD, YYYY")} - ${moment(
                  this.state.endDate
                ).clone().format("MMM DD, YYYY")}`,
                randomNumber: Math.random()
              },
              () => {
                this.closeModal();
              }
            );
          }}
          handleFromChange={date => this.setState({ startDate: moment(date)?.startOf("day")?.clone()?.tz(this.state?.tz)?.toDate() })}
          handleToChange={date => this.setState({ endDate: moment(date)?.endOf("day")?.clone()?.tz(this.state?.tz)?.toDate()})}
          from={this.state.startDate}
          to={this.state.endDate}
        /> */}
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="select__driver-drawer work__repot-filter"
        >
          <div className="head-section-header d-flex align-items-center justify-content-between">
            <button className="btn btn-dark btn-sm clear-btn" onClick={this.clearFilter.bind(this)}>
              Clear
            </button>
            <div className="head-section-title">Filters</div>
            <div className="head-section-cta">
              <div onClick={this.onClose} className="btn btn-link btn-back">
                <CloseIcon />
              </div>
            </div>
          </div>
          <ul className="filter__list">
            {/* <li className="filter__list-item">
              <span className="d-flex -align-items-center filter__check">
                <label className="custom-checkbox custom-checkbox-line">
                  <input
                    onChange={this.openFilterList}
                    name="dateFilter"
                    type="checkbox"
                    className="custom-checkbox--input"
                    checked={this.state.dateFilter}
                  />
                  <span className="checkmark"></span>
                  <p>Date</p>
                </label>
              </span>
              <div className={`filter__list-item-inner ${this.state.dateFilter ? "show-list-detail" : ""}`}>
                <div className="calendar__form">
                  <div className="input-from">
                    <div className="form-group">
                      <DatePicker
                        name="from"
                        selected={this.state.from}
                        onChange={this.handleFromChange}
                        className="form-control material-textfield-input"
                        placeholder="10/01/20"
                        required
                        autoComplete="off"
                      />
                      <label className="calendar-label">From</label>
                    </div>
                  </div>
                  <div className="input-to">
                    <div className="form-group">
                      <DatePicker
                        name="to"
                        selected={this.state.to}
                        onChange={this.handleToChange}
                        className="form-control material-textfield-input"
                        placeholder="10/01/20"
                        required
                        autoComplete="off"
                      />
                      <label className="calendar-label">To</label>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}
            <li className="filter__list-item">
              <span className="d-flex -align-items-center filter__check">
                <label className="custom-checkbox custom-checkbox-line">
                  <input
                    onChange={this.openFilterList}
                    name="customerFilter"
                    type="checkbox"
                    className="custom-checkbox--input"
                    checked={this.state.customerFilter}
                  />
                  <span className="checkmark"></span>
                  <p>Customers</p>
                </label>
              </span>
              <div className={`filter__list-item-inner ${this.state.customerFilter ? "show-list-detail" : ""}`}>
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    name="customerSearchText"
                    onChange={this.handleSearch}
                    value={this.state.customerSearchText}
                    className="form-control material-textfield-input"
                    required
                  />
                  <label className="material-textfield-label">Search customers...</label>
                </div>
                <div className="select_driver-wrapper">
                  <div>
                    <ul className="select_driver-list box-shadow">
                      {customers.map(customer => {
                        const fcIndex = _.findIndex(filterCustomers, function(fc) {
                          return String(fc._id) === String(customer._id);
                        });
                        return (
                          <li className={fcIndex !== -1 ? "active" : ""} key={customer._id}>
                            <div className="d-flex align-items-center">
                              <p className="driver__name">{customer.displayName}</p>
                            </div>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                type="checkbox"
                                className="custom-checkbox--input"
                                onChange={() => this.selectCustomer(customer)}
                                checked={fcIndex !== -1 ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li className="filter__list-item">
              <span className="d-flex -align-items-center filter__check">
                <label className="custom-checkbox custom-checkbox-line">
                  <input
                    onChange={this.openFilterList}
                    name="locationFilter"
                    type="checkbox"
                    className="custom-checkbox--input"
                    checked={this.state.locationFilter}
                  />
                  <span className="checkmark"></span>
                  <p>Location</p>
                </label>
              </span>
              <div className={`filter__list-item-inner ${this.state.locationFilter ? "show-list-detail" : ""}`}>
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    name="locationSearchText"
                    onChange={this.handleSearch}
                    value={this.state.locationSearchText}
                    className="form-control material-textfield-input"
                    required
                  />
                  <label className="material-textfield-label">Search locations...</label>
                </div>
                <div className="select_driver-wrapper">
                  <div>
                    <ul className="select_driver-list box-shadow">
                      {locations.map((location, i) => {
                        return (
                          <li className={filterLocations.indexOf(location) !== -1 ? "active" : ""} key={i}>
                            <div className="filter_list-inner d-flex align-items-center">
                              <p className="driver__name">{location}</p>
                            </div>
                            <label className="custom-checkbox custom-checkbox-line">
                              <input
                                type="checkbox"
                                className="custom-checkbox--input"
                                onChange={() => this.selectLocation(location)}
                                checked={filterLocations.indexOf(location) !== -1 ? true : false}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="apply_filter-btn">
            <button
              className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
              onClick={this.applyFilter}
            >
              Apply Filter
            </button>
          </div>
        </Drawer>
      </div>
    );
  }
}
