class ListenerManager {
  constructor() {
    this.listeners = new Set();
    this.globalListener = this.handleEvent.bind(this);
  }

  handleEvent(event) {
    for (const listener of this.listeners) {
      listener(event);
    }
  }

  addListener(listener) {
    this.listeners.add(listener);
    if (this.listeners.size === 1) {
      window.addEventListener("message", this.globalListener);
    }
  }

  removeListener(listener) {
    this.listeners.delete(listener);
    if (this.listeners.size === 0) {
      window.removeEventListener("message", this.globalListener);
    }
  }
}

var listenerManager = new ListenerManager();
let globalConfig = {};

export default class PayabliComponent {
  constructor(payabliConfig, cardModalOpen) {
    let objThis = this;
    this.payabliConfig = payabliConfig;
    this.payabliIframe = document.createElement("iframe");
    this.payabliButton = document.createElement("button");
    this.payabliIframeContainer = document.createElement("div");
    this.payabliIframeContainer.onclick = function () { objThis.closeModal(cardModalOpen) };
    this.payabliIframe.style.opacity = "0";
    this.payabliIframe.style.width = "100%";
    this.payabliPayMethodSelected = null;
    this.payabliMainContainer = document.getElementById(payabliConfig.rootContainer);
    this.randomId = '';
    listenerManager.addListener(this.handleMessage.bind(this));
    this.initUI(payabliConfig);
  }

  initUI(payabliConfig) {
    this.randomId = '_' + Math.random().toString(36).substr(2, 9);
    globalConfig[this.randomId] = payabliConfig;
    try {
      if (typeof payabliConfig === 'undefined') {
        throw new Error("Payabli component configuration is not defined. Try something like this:\n\nvar payabliConfig = {}")
      }
      if (typeof payabliConfig.rootContainer === 'undefined') {
        throw  new Error("Payabli component 'rootContainer' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n rootContainer: 'pay-component' \n}")
      }
      if (typeof payabliConfig.defaultOpen === 'undefined' || (payabliConfig.defaultOpen !== 'card' && payabliConfig.defaultOpen !== 'ach' && payabliConfig.defaultOpen !== 'device')) {
        throw  new Error("Payabli component 'defaultOpen' configuration is not defined or is incorrect. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'card' \n}")
      }
      if (payabliConfig.defaultOpen === 'card' && typeof payabliConfig.card !== 'undefined' && payabliConfig.card.enabled === false) {
        throw  new Error("Payabli component 'defaultOpen' is 'card' and 'card' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'card',\n card: { enabled: true } \n}")
      }
      if (payabliConfig.defaultOpen === 'ach' && typeof payabliConfig.ach !== 'undefined' && payabliConfig.ach.enabled === false) {
        throw  new Error("Payabli component 'defaultOpen' is 'ach' and 'ach' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'ach',\n ach: { enabled: true } \n}")
      }
      if (payabliConfig.defaultOpen === 'device' && ((typeof payabliConfig.device !== 'undefined' && payabliConfig.device.enabled === false) || (typeof payabliConfig.device === 'undefined'))) {
        throw  new Error("Payabli component 'defaultOpen' is 'device' and 'device' is disable. Try something like this:\n\nvar payabliConfig = {\n defaultOpen: 'device',\n device: { enabled: true } \n}")
      }
      if ((typeof payabliConfig.card === 'undefined' || typeof payabliConfig.ach === 'undefined')) {
        throw  new Error("Payabli component 'card' or 'ach' configuration is not defined.")
      }
      if ((typeof payabliConfig.card !== 'undefined' && typeof payabliConfig.ach !== 'undefined') && (payabliConfig.card.enabled === false && payabliConfig.ach.enabled === false)) {
        throw  new Error("Payabli component 'card' or 'ach' should be enable.")
      }
      if (typeof payabliConfig.type !== 'undefined' && payabliConfig.type.toLowerCase() !== 'methodembedded' && typeof payabliConfig.buttonLabelInModal === 'undefined') {
        throw  new Error("Payabli component 'buttonLabelInModal' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n buttonLabelInModal: 'Pay' \n}")
      }
      if (typeof payabliConfig.token === 'undefined') {
        throw  new Error("Payabli component 'token' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n token: 'xyz' \n}")
      }

      if (typeof payabliConfig.type === 'undefined') {
        throw  new Error("Payabli component 'type' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n type: 'methodEmbedded' \n}")
      }

      if (payabliConfig.type.toLowerCase() === 'vterminal' && (typeof payabliConfig.oneTimePayment === 'undefined' || typeof payabliConfig.recurringPayment === 'undefined')) {
        throw  new Error("Payabli component 'oneTimePayment' or 'recurringPayment' configuration is not defined. Try something like this:\n\nvar payabliConfig = {\n oneTimePayment: true,\n recurringPayment: false, \n}")
      }

      if (payabliConfig.type.toLowerCase() === 'vterminal'
        && (typeof payabliConfig.oneTimePayment !== 'undefined' && typeof payabliConfig.recurringPayment !== 'undefined')
        && (payabliConfig.oneTimePayment === false && payabliConfig.recurringPayment === false)
      ) {
        throw  new Error("Payabli component 'oneTimePayment' and 'recurringPayment' configurations are 'false', you need at least one of them set to 'true'. Try something like this:\n\nvar payabliConfig = {\n oneTimePayment: true,\n recurringPayment: false, \n}")
      }

      if (payabliConfig.card && payabliConfig.card.inputs && payabliConfig.card.inputs.cardZipcode && payabliConfig.card.inputs.cardZipcode.country) {
        let payabliConfigZipcodeCountry = payabliConfig.card.inputs.cardZipcode.country;
        if(!Array.isArray(payabliConfigZipcodeCountry)){
          throw  new Error("Payabli component 'cardZipcode.country' configuration should be an Array of Strings. Try something like this:\ncountry: ['ca', 'us']")

        }

        if(Array.isArray(payabliConfigZipcodeCountry)){
          payabliConfigZipcodeCountry.forEach(function (item) {
            if(typeof item !== 'string'){
              throw  new Error("Payabli component 'cardZipcode.country' configuration should be an Array of Strings. Try something like this:\ncountry: ['ca', 'us']");
            }
          });
        }
      }

      if (payabliConfig.type.toLowerCase() === 'vterminal' && payabliConfig.lineItems && typeof payabliConfig.lineItems === 'object') {
        for (var item in payabliConfig.lineItems) {
          if (
            typeof payabliConfig.lineItems[item].name === 'undefined' ||
            typeof payabliConfig.lineItems[item].type === 'undefined' ||
            typeof payabliConfig.lineItems[item].label === 'undefined' ||
            typeof payabliConfig.lineItems[item].value === 'undefined' ||
            typeof payabliConfig.lineItems[item].description === 'undefined' ||
            typeof payabliConfig.lineItems[item].quantity === 'undefined' ||
            typeof payabliConfig.lineItems[item].showDescription === 'undefined'
          ) {
            throw  new Error("Payabli component 'lineItems' configuration is not correct. Try something like this:\n\nvar payabliConfig = {\n lineItems: [{\n   name: 'name',\n   type: 'customer',\n   label: 'label',\n   value: '20.00',\n   quantity: '',\n   value: '20.00',\n   showDescription: '20.00'\n }] \n}")
          }
        }
      }

      if (payabliConfig.defaultOpen === 'card' && payabliConfig.card.amex === false && payabliConfig.card.discover === false && payabliConfig.card.visa === false && payabliConfig.card.mastercard === false && payabliConfig.card.diners === false && payabliConfig.card.jcb === false) {
        throw  new Error("Payabli component. All cards are disabled, you must activate at least one card.")
      }

      if (payabliConfig.defaultOpen === 'ach' && payabliConfig.ach.checking === false && payabliConfig.ach.savings === false) {
        throw  new Error("Payabli component. All account types are disabled, you must activate at least one account type.")
      }

      if (payabliConfig.type.toLowerCase() === 'vterminal' && (!payabliConfig.entryPoint || payabliConfig.entryPoint === "")) {
        throw  new Error("Payabli component 'entryPoint' configuration is not defined or empty. Try something like this:\n\nvar payabliConfig = {\n entryPoint: 'entrypoint' \n}")
      }

      if  (payabliConfig.defaultOpen === 'card' && (payabliConfig.card.enabled === undefined || payabliConfig.card.enabled === null)) {
        payabliConfig.card.enabled = true;
      }

      if (payabliConfig.defaultOpen === 'ach' && (payabliConfig.ach.enabled === undefined || payabliConfig.ach.enabled === null)) {
        payabliConfig.ach.enabled = true;
      }

      if (payabliConfig.card.amex === undefined) {
        payabliConfig.card.amex = true;
      }
      if (payabliConfig.card.discover === undefined) {
        payabliConfig.card.discover = true;
      }
      if (payabliConfig.card.visa === undefined) {
        payabliConfig.card.visa = true;
      }
      if (payabliConfig.card.mastercard === undefined) {
        payabliConfig.card.mastercard = true;
      }
      if (payabliConfig.card.diners === undefined) {
        payabliConfig.card.diners = true;
      }
      if (payabliConfig.card.jcb === undefined) {
        payabliConfig.card.jcb = true;
      }

      if (payabliConfig.ach.checking === undefined) {
        payabliConfig.ach.checking = true;
      }
      if (payabliConfig.ach.savings === undefined) {
        payabliConfig.ach.savings = true;
      }

      payabliConfig.randomId = this.randomId;
      let payabliConfigJson = JSON.stringify(payabliConfig);
      let payabliEncoded = window.btoa(payabliConfigJson);

      if (!this.payabliMainContainer) {
        throw new Error("Payabli component 'rootContainer' not found.")
      }

      var thisScriptTag = document.querySelector('script[src*="component.js"]');
      var jsversion = '';
      var urlParser = document.createElement('a');
      urlParser.href = thisScriptTag.getAttribute('src');
      var hostjs = urlParser.protocol + '//' + urlParser.host + jsversion;

      let payabliHead = document.getElementsByTagName('HEAD')[0];
      let payabliLink = document.createElement('link');

      switch (payabliConfig.type.toLowerCase()) {
        case 'methodlightbox':
          this.payabliIframe.setAttribute("src", hostjs + "/addpaymethod/" + payabliEncoded);
          this.payabliIframe.setAttribute("scrolling", "no");
          this.payabliIframeContainer.id = "payabliComponentsIframeContainerMethod";
          this.payabliIframeContainer.style.opacity = "1"
          if (payabliConfig.hasOwnProperty('hideComponent')) {
            if (payabliConfig.hideComponent === true) {
              this.payabliIframeContainer.style.display = "none"
            }
          }
          payabliLink.rel = 'stylesheet';
          payabliLink.type = 'text/css';
          payabliLink.href = hostjs + '/lightbox.css';
          payabliHead.appendChild(payabliLink);
          break;
        case 'methodembedded':
          this.payabliIframe.setAttribute("src", hostjs + "/addpaymethodembeded/" + payabliEncoded);
          this.payabliIframe.setAttribute("scrolling", "no");
          this.payabliIframeContainer.id = "payabliComponentsIframeContainerMethodEmbedded";
          this.payabliIframeContainer.style.opacity = "1"
          if (payabliConfig.hasOwnProperty('hideComponent')) {
            if (payabliConfig.hideComponent === true) {
              this.payabliIframeContainer.style.display = "none"
            }
          }
          break;
        case 'vterminal':
          this.payabliIframe.setAttribute("src", hostjs + "/vterminal/" + payabliEncoded);
          this.payabliIframe.setAttribute("scrolling", "yes");
          this.payabliIframeContainer.id = "payabliComponentsIframeContainerEterminal";
          this.payabliIframeContainer.style.opacity = "1"
          if (payabliConfig.hasOwnProperty('hideComponent')) {
            if (payabliConfig.hideComponent === false) {
              this.payabliIframeContainer.style.display = "flex"
            }
          }
          payabliLink.rel = 'stylesheet';
          payabliLink.type = 'text/css';
          payabliLink.href = hostjs + '/components.css';
          payabliHead.appendChild(payabliLink);
          break;
        default:
          throw new Error("Payabli component 'type' has an incorrect value. Try something like this:\n\nvar payabliConfig = {\n type: 'methodlightbox' \n}\n\nOR\n\nvar payabliConfig = {\n type: 'methodembedded' \n}\n\nOR\n\nvar payabliConfig = {\n type: 'vterminal' \n}")
      }


      if (this.payabliMainContainer) {

        while (this.payabliMainContainer.firstChild) {
          this.payabliMainContainer.removeChild(this.payabliMainContainer.firstChild);
        }

        this.payabliMainContainer.appendChild(this.payabliIframeContainer);
        this.payabliIframeContainer.appendChild(this.payabliIframe);
      }

    } catch (error) {
      console.log(error);
      this.payabliMainContainer.innerHTML = error;
    }
  }

  handleMessage(event) {
    let payabliConfig = globalConfig[this.randomId];
    if (event.data === "close-payabli-modal" && this.payabliConfig.type.toLowerCase() === 'methodlightbox') {
      this.closeModal();
    }
    if (event.data === "close-payabli-modal-vt" && this.payabliConfig.type.toLowerCase() === 'vterminal') {
      this.closeModal();
    }

    if (event.data.event === "callback-payabli-function-done" + payabliConfig.randomId) {
      if (typeof payabliConfig.functionCallBackSuccess !== 'undefined') {
        payabliConfig.functionCallBackSuccess(event.data.data);
      }
    }
    if (event.data.event === "callback-payabli-function-ready" + payabliConfig.randomId) {
      if (typeof payabliConfig.functionCallBackReady !== 'undefined') {
        payabliConfig.functionCallBackReady(event.data.data);
      }
    }
    if (event.data.event === "callback-payabli-function-error" + payabliConfig.randomId) {
      if (typeof payabliConfig.functionCallBackError !== 'undefined') {
        payabliConfig.functionCallBackError(event.data.data);
      }
    }

    if (event.data.event === "callback-payabli-function-mounted-component" + payabliConfig.randomId) {
      this.payabliIframe.style.opacity = "1";
    }

    if (event.data.event === "callback-payabli-function-resize" + payabliConfig.randomId) {
      if (event.data.data && Number.isInteger(event.data.data) && payabliConfig.type.toLowerCase() !== 'vterminal') {
        this.payabliIframe.style.height = event.data.data;
        this.payabliIframe.style.height = event.data.data.toString() + "px";
      }
    }
    if (event.data.event === "callback-payabli-function-pay-method-selected" + payabliConfig.randomId) {
      if (event.data.data) {
        this.payabliPayMethodSelected = event.data.data;
      }
    }

  }

  showModal() {
    this.payabliIframeContainer.style.display = "flex";
    this.payabliIframeContainer.style.opacity = "1";
  }

  closeModal() {
    this.payabliIframeContainer.style.opacity = "0";
    this.payabliIframeContainer.style.display = "none";
  }

  payabliExec(action, parameter) {
    let data = {};
    if (parameter) {
      data = parameter;
    }

    if (action === 'method') {
      this.payabliIframe.contentWindow.postMessage({
        event: "callback-payabli-function-exec" + this.randomId,
        data: data
      }, '*');
    }
    else if (action === 'pay') {
      this.payabliIframe.contentWindow.postMessage({
        event: "callback-payabli-function-pay" + this.randomId,
        data: data
      }, '*');
    }
    else if (action === 'reinit') {
      this.payabliIframe.contentWindow.postMessage({
        event: "callback-payabli-function-reinit" + this.randomId,
        data: data
      }, '*');
    }
    else if (action === 'auth') {
      this.payabliIframe.contentWindow.postMessage({
        event: "callback-payabli-function-auth" + this.randomId,
        data: data
      }, '*');
    }
  }

  updateConfig(newConfig) {
    listenerManager.removeListener(this.handleMessage.bind(this));
    this.initUI(newConfig);
    this.payabliExec('reinit');
  }
}
