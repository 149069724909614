import { connect } from 'react-redux'
import { getMe, uploadImage, fetchMe,updateUserToken } from '../../store/user/duck'
import { createCompany, fetchCompanies, updateCompany } from "../../store/company/duck";
import CompanyListingComponent from './component'

const CompanyListingContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  // Map actions to dispatch and props
  {
    getMe,
    updateUserToken,
    fetchMe,
    createCompany,
    fetchCompanies,
    uploadImage,
    updateCompany
  }
)(CompanyListingComponent);

export default CompanyListingContainer
