import { connect } from 'react-redux'
import SignupComponent from './component'

const SignupContainer = connect(
  // Map state to props
  state => ({
  	
  }),
  {
  	
  }
)(SignupComponent)

export default SignupContainer
