// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'


import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs'
import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const LOGIN_USER = 'empire/user/LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'empire/user/LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'empire/user/LOGIN_USER_ERROR'

export const SEND_VERIFICATION_CODE = 'empire/user/SEND_VERIFICATION_CODE'
export const SEND_VERIFICATION_CODE_SUCCESS = 'empire/user/SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_ERROR = 'empire/user/SEND_VERIFICATION_CODE_ERROR'

export const GET_ME = 'empire/user/GET_ME'
export const GET_ME_SUCCESS = 'empire/user/GET_ME_SUCCESS'
export const GET_ME_ERROR = 'empire/user/GET_ME_ERROR'

export const UPDATE_USER_BY_ID = "empire/user/UPDATE_USER_BY_ID";

export const FETCH_ME = 'empire/user/FETCH_ME'
export const GET_USER_DETAIL = 'empire/user/GET_USER_DETAIL'
export const FETCH_COMPANY_USERS = 'empire/user/FETCH_COMPANY_USERS'
export const UPLOAD_IMAGE = 'empire/user/UPLOAD_IMAGE'
export const CLEAR_PHASE = 'empire/user/CLEAR_PHASE'
export const CLEAR_VERIFICATION_CODE = 'empire/user/CLEAR_VERIFICATION_CODE'
export const UPDATE_PROFILE = 'empire/user/UPDATE_PROFILE'
export const LOGOUT_USER = 'empire/user/LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'empire/user/LOGOUT_USER_SUCCESS'
export const DELETE_USER = "empire/user/DELETE_USER";
export const CREATE_USER = 'empire/user/CREATE_USER'
export const FETCH_USERS_BY_COMPANY_ID = 'empire/user/FETCH_USERS_BY_COMPANY_ID'
export const GET_USER_TOKEN = "empire/user/GET_USER_TOKEN";
export const UPDATE_USER_TOKEN =  "empire/user/UPDATE_USER_TOKEN"
export const GET_USER_PERMISSIONS="empire/user/GET_USER_PERMISSIONS"
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {},
  loginError: ''
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SEND_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodePhase', LOADING)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_SUCCESS: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', SUCCESS)
        .set('sendVerificationCodeData', payload)
        .set('message', payload.message)
        .set('error', null)
    }

    case SEND_VERIFICATION_CODE_ERROR: {
      const { payload } = action
      return state
        .set('sendVerificationCodePhase', ERROR)
        .set('message', payload.error.message)
        .set('error', null)
    }

    case LOGIN_USER: {
      return state
        .set('loginPhase', LOADING)
        .set('loginError', null)
    }

    case LOGIN_USER_SUCCESS: {
      const { payload } = action
      localStorage.setItem('Authorization', payload.token)
      return state
        .set('loginPhase', SUCCESS)
        .set('sendVerificationCodeData', {})
        .set('user', payload.user)
        .set('loginError', null)
    }

    case LOGIN_USER_ERROR: {
      const { payload } = action
      return state
        .set('loginError', payload.error && payload.error.message)
        .set('loginPhase', ERROR)
    }

    case GET_ME: {
      return state
        .set('userPhase', LOADING)
    }

    case GET_ME_SUCCESS: {
      const { payload } = action
      return state
        .set('userPhase', SUCCESS)
        .set('user', payload.user)
    }

    case GET_ME_ERROR: {
      return state
        .set('userPhase', ERROR)
    }

    case CLEAR_PHASE: {
      return state
        .set('sendVerificationCodePhase', INIT)
        .set('loginPhase', INIT)
        .set('message', '')
    }

    case CLEAR_VERIFICATION_CODE: {
      return state
        .set('sendVerificationCodeData', {})
    }

    case LOGOUT_USER: {
      // cookies.remove('Authorization')
      localStorage.removeItem('Authorization') //remove only user
      localStorage.clear() // for remove Authorization token
      return state
        .set('phase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', true)
    }

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const sendVerificationCode = credentials => {
  return {
    type: SEND_VERIFICATION_CODE,
    payload: credentials
  }
}

export const loginUser = credentials => {
  return {
    type: LOGIN_USER,
    payload: credentials
  }
}

export const getMe = credentials => {
  return {
    type: GET_ME,
    payload: credentials
  }
}

export const fetchMe = credentials => {
  return {
    type: FETCH_ME,
    payload: api.me(credentials)
  }
}

export const userClearPhase = credentials => {
  return {
    type: CLEAR_PHASE,
    payload: credentials
  }
}

export const codeClearPhase = credentials => {
  return {
    type: CLEAR_VERIFICATION_CODE,
    payload: credentials
  }
}

export const fetchCompanyUsers = payload => {
  return {
    type: FETCH_COMPANY_USERS,
    payload: api.fetchCompanyUsers(payload)
  }
}

export const createUser = payload => {
  return {
    type: CREATE_USER,
    payload: api.createUser(payload)
  }
}

export const uploadImage = payload => {
  return {
    type: UPLOAD_IMAGE,
    payload: api.uploadImage(payload)
  }
}

export const updateProfile = payload => {
  return {
    type: UPDATE_PROFILE,
    payload: api.updateProfile(payload)
  }
}

export const deleteUser = payload => {
  return {
    type: DELETE_USER,
    payload: api.deleteUser(payload)
  };
}

export const updateUserById = payload => {
  return {
    type: UPDATE_USER_BY_ID,
    payload: api.updateUserById(payload)
  };
}

export const getUserDetail = id => {
  return {
    type: GET_USER_DETAIL,
    payload: api.getUserDetail(id)
  }
}

export const fetchUsersByCompanyId = payload => {
  return {
    type: FETCH_USERS_BY_COMPANY_ID,
    payload: api.fetchUsersByCompanyId(payload)
  }
}

export const logout = payload => {
  return {
    type: LOGOUT_USER,
    payload: api.logout(payload)
  };
}

export const getUserToken = payload => {
  return {
    type: GET_USER_TOKEN,
    payload: api.getUserToken(payload)
  };
}
export const getUserPermissions = payload => {
  return {
    type: GET_USER_PERMISSIONS,
    payload: api.getUserPermissions(payload)
  };
}

export const updateUserToken = payload => {
  return {
    type: UPDATE_USER_TOKEN,
    payload: api.updateUserToken(payload)
  };
}
export const handleSignOut = () => ({
  type: LOGOUT_USER
})

/***********************************
 * Epics
 ***********************************/

const sendVerificationCodeEpic = action$ =>
  action$.pipe(
    ofType(SEND_VERIFICATION_CODE),
    mergeMap(action => {
      return fromPromise(api.sendVerificationCode(action.payload)).pipe(
        flatMap(payload => [
          {
            type: SEND_VERIFICATION_CODE_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: SEND_VERIFICATION_CODE_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const loginUserEpic = action$ =>
  action$.pipe(
    ofType(LOGIN_USER),
    mergeMap(action => {
      return fromPromise(api.loginUser(action.payload)).pipe(
        flatMap(payload => [
          {
            type: LOGIN_USER_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: LOGIN_USER_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

const meEpic = action$ =>
  action$.pipe(
    ofType(GET_ME),
    mergeMap(action => {
      return fromPromise(api.me(action.payload)).pipe(
        flatMap(payload => [
          {
            type: GET_ME_SUCCESS,
            payload
          }
        ]),
        catchError(error =>
          of({
            type: GET_ME_ERROR,
            payload: { error }
          })
        )
      )
    })
  )

export const userEpic = combineEpics(
  sendVerificationCodeEpic,
  loginUserEpic,
  meEpic
)
