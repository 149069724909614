// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
// import Cookies from 'universal-cookie'
import { INIT } from "../../constants/phase";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const CREATE_SCALE = "empire/scale/CREATE_SCALE";
export const GET_SCALE = "empire/scale/GET_SCALE";
export const UPDATE_SCALE = "empire/scale/UPDATE_SCALE";
export const DELETE_SCALE="empire/scale/DELETE_SCALE";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const createScale = (payload) => {
  return {
    type: CREATE_SCALE,
    payload: api.createScale(payload),
  };
};

export const getScale = (payload) => {
  return {
    type: GET_SCALE,
    payload: api.getScale(payload),
  };
};

export const getOneScale = (payload) => {
  return {
    type: GET_SCALE,
    payload: api.getOneScale(payload),
  };
};

export const deleteScale = (payload) => {
  return {
    type: DELETE_SCALE,
    payload: api.deleteScale(payload),
  };
};

export const updateScale = (payload,id) => {
  return {
    type: UPDATE_SCALE,
    payload: api.updateScale(payload,id),
    

  };
};
/***********************************
 * Epics
 ***********************************/

export const scaleEpic = combineEpics();
