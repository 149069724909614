import { connect } from 'react-redux'
import { getMe , fetchMe} from '../../store/user/duck'
import { fetchSetting } from "../../store/company/duck";
import { createCustomer, addCard } from "../../store/customer/duck";
import { createPricingTier, editPricingTier, addFixedItems, getFixedItems} from "../../store/Pricing_tier/duck"
import { getPricingTierById } from '../../store/Pricing_tier/duck';
import PricingTierComponent from './component'

const CreateEditPricingContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    fetchMe,
    getMe,
    createCustomer,
    fetchSetting,
    createPricingTier,
    editPricingTier,
    getPricingTierById,
    addCard,
    addFixedItems,
    getFixedItems
  }
)(PricingTierComponent);

export default CreateEditPricingContainer
