import { connect } from 'react-redux'
import LoginComponent from './component'
import { sendVerificationCode, loginUser, userClearPhase, codeClearPhase } from '../../store/user/duck'
import { fetchCompanyLogo } from '../../store/company/duck'
const LoginContainer = connect(
  // Map state to props
  state => ({
    sendVerificationCodePhase: state.user.sendVerificationCodePhase,
    sendVerificationCodeData: state.user.sendVerificationCodeData,
    message: state.user.message,
    loginError: state.user.loginError,
    user: state.user.user,
    loginPhase: state.user.loginPhase
  }),
  {
    fetchCompanyLogo,
    sendVerificationCode,
    loginUser,
    userClearPhase,
    codeClearPhase
  }
  // Map actions to dispatch and props
)(LoginComponent);

export default LoginContainer
