import React, { PureComponent } from "react";
import "./styles.scss";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import NeedReviewComponent from "./partials/NeedsReview";
import CustomersComponent from "./partials/Customers";
import CashLogBalanceContainer from "../CashLogBalance/container";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from "moment-timezone";
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  startOfDay,
} from "date-fns";
import PostedComponent from './partials/posted'
import FinalizedComponent from "./partials/finalized";
import CreditCardTransactionsComponent from "./partials/CreditCardTransactions";
import { DateIcon } from "../../components/icons";
export default class BillingComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    this.state = {
      toggleMenu: false,
      // activeUrl: "",
      user: {},
      startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
      endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
      DateRangePickerDroDown: false,
      date: {
        startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
        endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
        key: 'selection'
      },
      tz,
      searchText: ''
      // date:{
      //   startDate: moment().format('ll'),
      // endDate: moment().format('ll'),
      // }
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount = async () => {
    // const {
    //   value: { user },
    // } = await this.props.fetchMe();
    const billingData = JSON.parse(localStorage.getItem("BillingDate"))
    const startDate = startOfDay(new Date(JSON.parse(localStorage.getItem("BillingDate"))?.startDate ?? new Date()))
    const endDate = endOfDay(new Date(JSON.parse(localStorage.getItem("BillingDate"))?.endDate ?? new Date()))
    const date = { startDate: startDate, endDate: endDate, key: 'selection' }
    billingData && this.setState({ startDate: startDate, endDate: endDate, date: date })
    window.addEventListener('click', this.handleClickOutside);
  };
  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dateRangePickerRef && !this.dateRangePickerRef.contains(event.target)) {
      this.setState({ DateRangePickerDroDown: false });
    }
  };

  handleSelect = (ranges) => {
    ranges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: moment(ranges.selection.startDate)?.startOf("day").toDate(),
        endDate: moment(ranges.selection.endDate)?.endOf("day")?.toDate()
      }
    }
    const obj = {
      startDate: ranges.selection.startDate, endDate: ranges.selection.endDate
    }
    this.setState({
      date: ranges.selection,
      // isPaymentsDateRangePickerOpen: false
    });
    localStorage.setItem("BillingDate", JSON.stringify(obj));
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate })
  }

  OnClickTab = async (activeTabUrl) => {
    this.props.history.push(activeTabUrl);
  };
  navigate = async (activeTabUrl) => {
    const state = { from: this.props.location.pathname };
    this.props.history.push(activeTabUrl,state);
  };
  closeBalanceModalOpen = () => {
    this.setState({ closeBalanceModal: true });
  };

  closeBalanceModalClose = () => {
    this.setState({ closeBalanceModal: false });
  };
  navigateFunction = (routePath) => {
    this.props.history.push(routePath);
  }
  render() {
    // const { activeUrl } = this.state;
   /*  const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    } */
    const {
      location: { pathname },
    } = this.props;
    const urlArray = pathname.split("/")
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
          closeBalanceModal={this.state.closeBalanceModal}
          closeBalanceModalFunc={this.closeBalanceModalClose}
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />

        <main className="dashboard-layout-content billing__wrapper" >
          <div className="page-header" ref={ref => this.dateRangePickerRef = ref} >
            <div className="page-header-inner">
              <h2 className="heading-title">Billing</h2>
            </div>
            {/* <div className="form-group material-textfield custom-select-50">
              <select
                className="form-control custom-select w-150 material-textfield-input pr-22"
                name="dateType" 
                required
              >
                <option value="today">Today</option>
                <option value="this week">
                  This Week
                </option>
                <option value="this month">
                  This Month
                </option>
                <option value="custom">Custom </option>
              </select>
              <label className="material-textfield-label">Date </label>
            </div> */}
            {<button
              className="btn btn-dark btn-sm btn-exportorders" onClick={() => { this.setState({ DateRangePickerDroDown: !this.state.DateRangePickerDroDown }) }}>
                <DateIcon />
              {this.state.date.startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} - {this.state.date.endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
            </button>}
            {this.state.DateRangePickerDroDown &&
              <DateRangePicker
                //onChange={(e)=>{this.handleSelect(e.dateRange) ;this.deshboardPayments(e.target.value); this.paymentDatePicker()}}
                onChange={this.handleSelect}
                ranges={[this.state.date]}
                editableDateInputs={true}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                calendarFocus="forwards"
                staticRanges={[
                  ...defaultStaticRanges,
                  {
                    label: "Last Year",
                    range: () => ({
                      startDate: startOfYear(addYears(new Date(), -1)),
                      endDate: endOfYear(addYears(new Date(), -1))
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  },
                  {
                    label: "This Year",
                    range: () => ({
                      startDate: startOfYear(new Date()),
                      endDate: endOfDay(new Date())
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  }
                ]}

              />}
          </div>
          <div className="billing__main-tabs">
            <div className="custome__tabs">
              <ul className="custome__tabs-list">
                <li>
                  <span
                    onClick={() => {
                      sessionStorage.removeItem('posted_filtersData')
                      sessionStorage.removeItem('finalized_filtersData')
                      this.OnClickTab("/billing/needs-review");
                    }}
                    className={
                      pathname === "/billing/needs-review" || (urlArray[1] === "billing" && urlArray[2] === "customer-detail") ? "active" : ""
                    }
                  >
                    Needs Review
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      sessionStorage.removeItem('need_review_filtersData')
                      sessionStorage.removeItem('finalized_filtersData')
                      this.OnClickTab("/billing/posted-transactions");
                    }}
                    className={
                      pathname === "/billing/posted-transactions" || (urlArray[1] === "posted" && urlArray[2] === "customer-detail")
                        ? "active"
                        : ""
                    }
                  >
                    Posted
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      sessionStorage.removeItem('need_review_filtersData')
                      sessionStorage.removeItem('posted_filtersData')
                      this.OnClickTab("/billing/finalized-transactions");
                    }}
                    className={
                      pathname === "/billing/finalized-transactions" || (urlArray[1] === "finalized" && urlArray[2] === "customer-detail")
                        ? "active"
                        : ""
                    }
                  >
                    Finalized
                  </span>
                </li>
              </ul>
            </div>
            <div className="custome__tabs">
              <ul className="custome__tabs-list">
                <li>
                  <span
                    onClick={() => {
                      this.OnClickTab("/billing/cash-transactions");
                    }}
                    className={
                      pathname === "/billing/cash-transactions"
                        ? "active"
                        : ""
                    }
                  >
                    Cash Transactions
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      this.OnClickTab("/billing/credit-card-transactions/tranasactions");
                    }}
                    className={
                      pathname === "/billing/credit-card-transactions"
                        || pathname === "/billing/credit-card-transactions/payouts" ||
                        pathname === "/billing/credit-card-transactions/tranasactions"
                        ? "active"
                        : ""
                    }
                  >
                    CC Transactions
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      this.OnClickTab("/billing/customers");
                    }}
                    className={
                      pathname === "/billing/customers" ? "active" : ""
                    }
                  >
                    Customers
                  </span>
                </li>
              </ul>
            </div>
          </div>
          {(pathname === "/billing/needs-review" || (urlArray[1] === "needReview" && urlArray[2] === "customer-detail")) && <NeedReviewComponent
            date={this.state.date}
            fetchNeedReview={this.props.fetchNeedReview}
            fetchInvoiceDeliveryData={this.props.fetchInvoiceDeliveryData}
            getPricingTierById={this.props.getPricingTierById}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            uploadImage={this.props.uploadImage}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            sendReminderInvoiceEmail={this.props.sendReminderInvoiceEmail}
            getMe={this.props.getMe}
            navigate={this.navigate}
            fetchCustomer={this.props.fetchCustomer}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            batchPrintInvoice={this.props.batchPrintInvoice}
            getProjectByProjectId={this.props.getProjectByProjectId}
            deletePaymentMethod={this.props.deletePaymentMethod}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            updateInvoicDetail={this.props.updateInvoicDetail}
            downloadBulkInvoice={this.props.downloadBulkInvoice}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            handleSearchText={(searchText, searchStartsWith) => this.props.setSearchText(searchText, searchStartsWith)}
            {...this.props}
          />}
          {(pathname === "/billing/posted-transactions" || (urlArray[1] === "posted" && urlArray[2] === "customer-detail")) && <PostedComponent
            date={this.state.date}
            fetchPostedOrder={this.props.fetchPostedOrder}
            fetchPostedInvoicesCount={this.props.fetchPostedInvoicesCount}
            fetchInvoiceDeliveryData={this.props.fetchInvoiceDeliveryData}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            syncPostedOrder={this.props.syncPostedOrder}
            navigate={this.navigate}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            sendReminderInvoiceEmail={this.props.sendReminderInvoiceEmail}
            fetchMe={this.props.fetchMe}
            batchPrintInvoice={this.props.batchPrintInvoice}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            updateInvoicDetail={this.props.updateInvoicDetail}
            uploadImage={this.props.uploadImage}
            getMe={this.props.getMe}
            sendInvoiceEmail2={this.props.sendInvoiceEmail2}
            fetchCustomer={this.props.fetchCustomer}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            getProjectByProjectId={this.props.getProjectByProjectId}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            downloadBulkInvoice={this.props.downloadBulkInvoice}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            printPaymentReciept={this.props.printPaymentReciept}
            handleSearchText={(searchText, searchStartsWith) => this.props.setSearchText(searchText, searchStartsWith)}
            {...this.props}
          />
          }
          {(pathname === "/billing/finalized-transactions" || (urlArray[1] === "finalized" && urlArray[2] === "customer-detail")) && <FinalizedComponent
            date={this.state.date}
            fetchMe={this.props.fetchMe}
            fetchFinalizedInvoice={this.props.fetchFinalizedInvoice}
            fetchInvoiceDeliveryData={this.props.fetchInvoiceDeliveryData}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            sendReminderInvoiceEmail={this.props.sendReminderInvoiceEmail}
            batchPrintInvoice={this.props.batchPrintInvoice}
            // syncPostedOrder={this.props.syncPostedOrder}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            sendInvoiceEmail2={this.props.sendInvoiceEmail2}
            navigate={this.navigate}
            updateInvoicDetail={this.props.updateInvoicDetail}
            uploadImage={this.props.uploadImage}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            getProjectByProjectId={this.props.getProjectByProjectId}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            downloadBulkInvoice={this.props.downloadBulkInvoice}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            printPaymentReciept={this.props.printPaymentReciept}
            handleSearchText={(searchText, searchStartsWith) => this.props.setSearchText(searchText, searchStartsWith)}
            {...this.props}
          />
          }


          {(pathname === "/billing/customers" || (urlArray.length > 3 && urlArray[3] === "customer-detail")) && <CustomersComponent
            fetchCustomers={this.props.fetchCustomers}
            getMe={this.props.getMe}
            navigateFunction={this.navigateFunction}
            updateCustomer={this.props.updateCustomer}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            fetchCustomer={this.props.fetchCustomer}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            getCompanyById={this.props.getCompanyById}
            generateStatementPdf={this.props.generateStatementPdf}
            statementEmail={this.props.statementEmail}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            printPaymentReciept={this.props.printPaymentReciept}
            {...this.props}
          />
          }
          {pathname === "/billing/credit-card-transactions" || pathname === "/billing/credit-card-transactions/payouts" ||
            pathname === "/billing/credit-card-transactions/tranasactions" ? (
            <CreditCardTransactionsComponent
              date={this.state.date}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              pathname={pathname}
              navigateFunction={this.navigateFunction}
              user={this.state.user}
              fetchTransactions={this.props.fetchTransactions}
              getPayabliTransactions={this.props.getPayabliTransactions}
              fetchMe={this.props.fetchMe}
              getPaybliIntegration={this.props.getPaybliIntegration}
              {...this.props}
            />
          ) : null}
          {pathname === "/billing/cash-transactions" && (
            <CashLogBalanceContainer navigate={this.navigate}
              date={this.state.date}
              closeBalanceModalClose={this.closeBalanceModalClose}
              closeBalanceModalOpen={this.closeBalanceModalOpen}
            />
          )}
        </main>         
      </div>
    );
  }
}
