import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
// import PropTypes from 'prop-types'
import Logo from "../../images/logo.png";
// import dollerIconGray from "../../images/doller_icon_gray.svg";
// import dollerIcon from "../../images/doller_icon.svg";
import sidebarClose from "../../images/sidebar_close.svg";
import sidebarOpen from "../../images/sidebar_open.svg";


import "./styles.scss";

import {
  DashboardGreenIcon,
  DashboardIcon,
  ScaleIcon,
  ScaleGreenIcon,
  CompletedOrdersIcon,
  CompletedOrdersGreenIcon,
  CustomersIcon,
  CustomersGreenIcon,
  UserManagementIcon,
  UserManagementGreenIcon,
  ReportsIcon,
  ReportsGreenIcon,
  CashLogIcon,
  CashLogGreenIcon,
  ResourcesIcon,
  ResourcesGreenIcon,
  OutboundActivityIcon,
  OutboundActivityGreenIcon,
  BusinessAdminGreenIcon,
  BusinessAdminIcon,
  DollerIconGray,
  DollerIcon
} from "../../components/icons";

export default class SidebarNavigationComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      companiesList: [],
      selectedCompany: '',
      userData: {},
      permissions: {},
      isSideBarOpen:false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getUserCompanies();
    let item = localStorage.getItem("sideBarOpenClose");
    if (item) {
      this.setState({ isSideBarOpen: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getUserCompanies = async () => {
    const {
      value: { user, userCompanies }
    } = await this.props.fetchMe()
    if (user?.permissions) {
      this.setState({ permissions: user?.permissions })
    }
    await this.checkPermissions(); 
    this.setState({ userData: user, companiesList: userCompanies, selectedCompany: user?.companyId })

    // .log("scales", user)
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickToggleMenu();
    }
  }

  handleChangeCompany = async (e) => {
    const targetValue = e.target.value
    this.setState({ selectedCompany: e.target.value })
    const payload = {
      companyId: targetValue,
      userId: this.state.userData._id
    }
    const {
      value: { data }
    } = await this.props.updateUserToken(payload)
    localStorage.setItem("Authorization", data.token)
    window.location.reload()
  }

  handleVersionChangeRefresh = () => {
    if(localStorage.getItem('x-version-update-needed') === "true"){
      localStorage.removeItem('x-version-update-needed')
      window.location.reload()
    }
  }

  checkPermissions = () => {
    const { permissions } = this.state;
    const path = window.location.pathname;
      if (path.startsWith("/create-charge") && !permissions?.permissionOptions?.createManualCharge) {
        this.props.history.push("/dashboard");
      }
      if (path.startsWith("/billing") && ! permissions?.permissionOptions?.viewBillingPage ) {
        this.props.history.push("/dashboard");
      }
    }
  render() {
    const path = window.location.pathname;
    const { user, toggleMenu } = this.props;
    const permissions  = this.props.user?.permissions
    return (
      <aside
        ref={this.setWrapperRef}
        className={!toggleMenu ? "sidebar-layout-sider" : "sidebar-layout-sider sidebar-layout-sider-open"}
      >
        <div className="sidebar-layout-sider__children">
          <div className="sidebar-layout-sider__logo">
            <img
              className="img-fluid cursor-pointer"
              // style={{height:'75px',width:'100px',objectFit:'contain'}}
              src={user && user.company && user.company.logo ? user.company.logo : Logo}
              alt=""
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
            />
          </div>
          <div className="sidebar__open-close-items">
            <img onClick={()=>{  this.setState({isSideBarOpen:true}) ;var element = document.getElementsByClassName("application-container");
            element[0].classList.add("sidebar__toggle-wrapper");
            localStorage.setItem("sideBarOpenClose", "sidebar__toggle-wrapper");
            }} className="sidebar__close-icon" src={sidebarClose} alt=""/>
            <img onClick={ ()=>{  this.setState({isSideBarOpen:false}); var element = document.getElementsByClassName("application-container");
           element[0].classList.remove("sidebar__toggle-wrapper");
           localStorage.removeItem("sideBarOpenClose");
           }} className="sidebar__open-icon" src={sidebarOpen} alt=""/>
          </div>
          {this.state?.companiesList?.length > 1 &&
            <div className="sidebar__slect">

              <div className="form-group material-textfield custom-select-50">
                <select
                  className="form-control custom-select w-150 material-textfield-input pr-22"
                  name="dateType"
                  value={this.state.selectedCompany}
                  required
                  onChange={(e) => this.handleChangeCompany(e)}
                >
                  {this.state.companiesList?.length > 0 && this.state.companiesList?.map(item => {
                    return (
                      <option key={item._id} value={item._id}>{item.name}</option>
                    )
                  })}
                </select>
                <label className="material-textfield-label">Account</label>
              </div>
            </div>
          }
          <ul className="sidebar-layout__menu sidebar-layout__menu__dark">
          
            <li className={path === "/dashboard" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Tooltip 
                overlayClassName="navigation-tooltip" 
                placement="right" 
                title= { this.state.isSideBarOpen ? "Dashboard" :""}
                >
                <Link className="sidebar-item__link info-tooltip" to={"/dashboard"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon" tooltip="Dashboard">
                    {path === "/dashboard" ? <DashboardGreenIcon /> : <DashboardIcon />}
                    <DashboardGreenIcon />
                  </span>{" "}
                  <text>Dashboard</text>
                </Link>
              </Tooltip>            
            </li>
            <li className={path === "/scale" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Tooltip 
                overlayClassName="navigation-tooltip" 
                placement="right"
                title= {this.state.isSideBarOpen?"Scale":""} >
                <Link className="sidebar-item__link" to={"/scale"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/scale" ? <ScaleGreenIcon /> : <ScaleIcon />}
                    <ScaleGreenIcon />
                  </span>{" "}
                  <text>Scale</text>
                </Link>
              </Tooltip>
            </li>
            {(permissions?.permissionOptions && permissions?.permissionOptions?.createManualCharge === true)  ?
            <li className={path === "/create-charge" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title={this.state.isSideBarOpen?"Create Charge":""} >
                <Link className="sidebar-item__link" to={"/create-charge"} onClick={this.handleVersionChangeRefresh}>              
                  <span className="sidebar-item__icon">
                    {path === "/create-charge" ? <DollerIcon /> : <DollerIconGray />}
                    <DollerIcon />
                  </span>{" "}
                  <text> Create Charge </text>
                </Link>
              </Tooltip>
            </li>:null
            }
            {/* <li className={path === "/create-charge" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Link className="sidebar-item__link" to={"/create-charge"}>
                <span className="sidebar-item__icon">
                  {path === "/create-charge" ? <img className="gray_icon" src={dollerIconGray} alt=""/> : 
                  <img  className="gray_icon" src={dollerIconGray} alt=""/>}
                  <img className="green_icon" src={dollerIcon} alt=""/>
                </span>{" "}            
                Create Charge     
              </Link>
            </li> */}
            <li
              className={
                path === "/queued-for-drivers-signature" || path === "/completed-orders"
                  ? "sidebar-item sidebar-item-is-selected"
                  : "sidebar-item"
              }
            >
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title={this.state.isSideBarOpen?"Orders":""}>
                <Link className="sidebar-item__link" to={"/completed-orders"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/queued-for-drivers-signature" || path === "/completed-orders" ? (
                      <CompletedOrdersGreenIcon />
                    ) : (
                      <CompletedOrdersIcon />
                    )}
                    <CompletedOrdersGreenIcon />
                  </span>{" "}
                  <text>Orders</text>
                </Link>
              </Tooltip>
            </li>
            <li className={path === "/customers" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title= {this.state.isSideBarOpen?"Customers":""}>
                <Link className="sidebar-item__link" to={"/customers"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/customers" ? <CustomersGreenIcon /> : <CustomersIcon />}
                    <CustomersGreenIcon />
                  </span>{" "}
                  <text>Customers</text>
                </Link>
              </Tooltip>
            </li>
            {(permissions?.permissionOptions && permissions?.permissionOptions?.viewBillingPage === true) ?
            <li className={path === "/billing/needs-review" || path === "/billing/posted-transactions" || path === "/billing/finalized-transactions" || path === "/billing/cash-transactions"|| path === "/billing/credit-card-transactions/tranasactions" ||  path === "/billing/credit-card-transactions/payouts" || path === "/billing/customers"  ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title={this.state.isSideBarOpen?"Billing":""}>
                <Link className="sidebar-item__link" to={"/billing/needs-review"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/billing/needs-review" || path === "/billing/posted-transactions" || path === "/billing/finalized-transactions" || path === "/billing/cash-transactions"|| path === "/billing/credit-card-transactions/tranasactions" ||  path === "/billing/credit-card-transactions/payouts" || path === "/billing/customers" ? <CashLogGreenIcon /> : <CashLogIcon />}
                    <CashLogGreenIcon />
                  </span>{" "}
                  <text>Billing</text>
                </Link>
              </Tooltip>
            </li>:null}
            {permissions?.userManagement ? (
              <li className={path === "/user-management" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                <Tooltip overlayClassName="navigation-tooltip"  placement="right" title= {this.state.isSideBarOpen?"User Management":""}>
                  <Link className="sidebar-item__link" to={"/user-management"} onClick={this.handleVersionChangeRefresh}>
                    <span className="sidebar-item__icon">
                      {path === "/user-management" ? <UserManagementGreenIcon /> : <UserManagementIcon />}
                      <UserManagementGreenIcon />
                    </span>{" "}
                  <text>User Management</text> 
                  </Link>
                </Tooltip>
              </li>
            ) : (
              ""
            )}

            {permissions && permissions?.reports && (permissions?.reports.cashDiscrepancy || permissions?.reports.inboundVsOutbound || permissions?.reports.clintAndJob || permissions?.reports.orders) &&
              <li className={path === "/reports" ? "sidebar-item sidebar-item-is-selected" : "sidebar-item"}>
                <Tooltip overlayClassName="navigation-tooltip"  placement="right" title= {this.state.isSideBarOpen?"Reports":""}>
                  <Link className="sidebar-item__link" to={"/reports"} onClick={this.handleVersionChangeRefresh}>
                    <span className="sidebar-item__icon">
                      {path === "/reports" ? <ReportsGreenIcon /> : <ReportsIcon />}
                      <ReportsGreenIcon />
                    </span>{" "}
                    <text>Reports</text>
                  </Link>
                </Tooltip>
              </li>
            }

            {/* {user && _.toLower(user.role) !== "user" ? (
              <li
                className={
                  path === "/cash-transactions" || path === "/credit-card-transactions"
                    ? "sidebar-item sidebar-item-is-selected"
                    : "sidebar-item"
                }
              >
                <Link className="sidebar-item__link" to={"/cash-transactions"}>
                  <span className="sidebar-item__icon">
                    {path === "/cash-transactions" || path === "/credit-card-transactions" ? (
                      <CashLogGreenIcon />
                    ) : (
                      <CashLogIcon />
                    )}
                    <CashLogGreenIcon />
                  </span>
                  <text>Transactions</text>
                </Link>
              </li>
            ) : (
              ""
            )} */}
            <li
              className={
                path === "/facilities" || path === "/third-party-haulers" || path === "/in-house-haulers"
                  ? "sidebar-item sidebar-item-is-selected"
                  : "sidebar-item"
              }
            >
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title= {this.state.isSideBarOpen?"Resources":""}>
                <Link className="sidebar-item__link" to={"/facilities"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/facilities" || path === "/third-party-haulers" || path === "/in-house-haulers" ? (
                      <ResourcesGreenIcon />
                    ) : (
                      <ResourcesIcon />
                    )}
                    <ResourcesGreenIcon />
                  </span>{" "}
                  <text>Resources</text>
                </Link>
              </Tooltip>
            </li>
            <li
              className={
                path === "/outbound-activity-active" || path === "/outbound-activity-completed"
                  ? "sidebar-item sidebar-item-is-selected"
                  : "sidebar-item"
              }
            >
              <Tooltip overlayClassName="navigation-tooltip"  placement="right" title= {this.state.isSideBarOpen?"Outbound Activity":""}>
                <Link className="sidebar-item__link" to={"/outbound-activity-active"} onClick={this.handleVersionChangeRefresh}>
                  <span className="sidebar-item__icon">
                    {path === "/outbound-activity-active" || path === "/outbound-activity-completed" ? (
                      <OutboundActivityGreenIcon />
                    ) : (
                      <OutboundActivityIcon />
                    )}
                    <OutboundActivityGreenIcon />
                  </span>{" "}
                  <text>Outbound Activity</text>
                </Link>
              </Tooltip>
            </li>
            {(permissions?.businessAdmin?.businessSetup || permissions?.businessAdmin?.intergrations || permissions?.businessAdmin?.pricingSetup || permissions?.businessAdmin?.scaleSetup) &&
              <li
                className={
                  path === "/business-admin/scales" ||
                    path === "/business-admin/pricing-tier" || path === "/business-admin/business-settings"
                    ? "sidebar-item sidebar-item-is-selected"
                    : "sidebar-item"
                }
              >
                <Tooltip overlayClassName="navigation-tooltip"  placement="right" title=  {this.state.isSideBarOpen?"Business Admin":""}>
                  <Link className="sidebar-item__link" to={{ pathname: permissions?.businessAdmin?.businessSetup ? "/business-admin/business-settings" : permissions?.businessAdmin?.pricingSetup ? "/business-admin/pricing-tier" : "/business-admin/scales", state: { isActive: true } }} onClick={this.handleVersionChangeRefresh}>
                    <span className="sidebar-item__icon">
                      {path === "/business-admin/scales" || path === "/business-admin/integrations" ||
                        path === "/business-admin/pricing-tier" || path === "/business-admin/business-settings" ? (
                        <BusinessAdminGreenIcon />
                      ) : (
                        <BusinessAdminIcon />
                      )}
                      <BusinessAdminGreenIcon />
                    </span>{" "}
                    <text>Business Admin</text>
                  </Link>
                </Tooltip>
              </li>}

            {/* <li
              className={
                path === "/charge-by-yards" ||
                  path === "/charge-by-yards-completed"
                  ? "sidebar-item sidebar-item-is-selected"
                  : "sidebar-item"
              }
            >
              <Link className="sidebar-item__link" to={"/business-admin"}>
                <span className="sidebar-item__icon">
                  {path === "/charge-by-yards" ||
                    path === "/charge-by-yards-completed" ? (
                    <ChargeByYardsGreenIcon />
                  ) : (
                    <ChargeByYardsIcon />
                  )}
                  <ChargeByYardsGreenIcon />
                </span>{" "}
                Charge By Yards
              </Link>
            </li> */}
          </ul>
        </div>
      </aside>
    );
  }
}
