// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
// import Cookies from 'universal-cookie'
import { INIT } from "../../constants/phase";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const UPLOAD_SIGNATURE = "empire/signaturepad/UPLOAD_SIGNATURE"
export const UPDATE_SIGNATURE_PAD_SETTING = "empire/signaturepad/UPDATE_SIGNATURE_PAD_SETTING"
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const uploadSignature = payload => {
  return {
    type: UPLOAD_SIGNATURE,
    payload: api.uploadSignature(payload)
  };
};
export const updateSignaturePadSetting = payload => {
  return {
    type: UPDATE_SIGNATURE_PAD_SETTING,
    payload: api.updateSignaturePadSetting(payload)
  };
};

/***********************************
 * Epics
 ***********************************/

export const signaturepadEpic = combineEpics();
