// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const CREATE_RESOURCE = 'empire/resource/CREATE_RESOURCE'
export const FETCH_RESOURCES = 'empire/resource/FETCH_RESOURCES'
export const FETCH_RESOURCES_LIST = 'empire/resource/FETCH_RESOURCES_LIST'
export const DELETE_RESOURCE = 'empire/resource/DELETE_RESOURCE'
export const UPDATE_RESOURCE = 'empire/resource/UPDATE_RESOURCE'

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const createResource = payload => {
  return {
    type: CREATE_RESOURCE,
    payload: api.createResource(payload)
  }
}

export const fetchResources = payload => {
  return {
    type: FETCH_RESOURCES,
    payload: api.fetchResources(payload)
  }
}

export const fetchResourcesList = payload => {
  return {
    type: FETCH_RESOURCES_LIST,
    payload: api.fetchResourcesList(payload)
  }
}

export const deleteResource = payload => {
  return {
    type: DELETE_RESOURCE,
    payload: api.deleteResource(payload)
  }
}

export const updateResource = payload => {
  return {
    type: UPDATE_RESOURCE,
    payload: api.updateResource(payload)
  }
}

/***********************************
 * Epics
 ***********************************/

export const resourceEpic = combineEpics()