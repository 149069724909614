import React, { useEffect, useState } from "react";
// import DriverImage from './../../../images/driver_image.png'
// import { ArrowIcon } from "../../../components/icons";
import { Pagination } from "antd";
import NumberFormat from "react-number-format";
import NotFound from "../../NoRecords/component";

const PendingOrder = ({ fetchOrders, navigate, startDate, endDate, isPendingOutboundShow=false, pendingData=null, setPendingPaymentData=null,weightType}) => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit] = useState(20);
  const [skip, setSkip] = useState(0);
  // const [searchText] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const {
        value: {
          data: { orders, ordersCount }
        }
      } = await fetchOrders({ startDate, endDate, skip, limit });
      setOrders(orders);
      setTotalItems(ordersCount);
    };
    fetchData();
  }, [fetchOrders, startDate, endDate, skip, limit]);

  const onClickOrder = (isWithOutweight,orderId, defaultOutboundWeight,usingDefault) => {
  let scale=[]
    navigate({ pathname: `/pending-order-details/${orderId}`, state: { dumpRate: "", ScalePage: true } },scale, defaultOutboundWeight,usingDefault,isWithOutweight);

  };

  const onChangePage = page => {
    setSkip((page - 1) * limit);
    setCurrentPage(page);
  };

  return (
        <>
          <section className="pending__orders-section">
            <div className="row ali">
              <div className="col-md-12">
              <div className="custome__tabs">
                  <ul className="custome__tabs-list">
                    <li onClick={()=> setPendingPaymentData && setPendingPaymentData({pending_payment_orders: []})}>
                      <span className="active">Pending Outbound</span>
                    </li>
                    {
                      isPendingOutboundShow && (<li onClick={()=> pendingData()}>
                          <span className="">Pending Payments</span>
                        </li>
                     )}
                  </ul>
                </div>
            {isPendingOutboundShow && orders.length === 0 ? (
              <NotFound title="No Orders Found" />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th>Ticket #</th>
                        <th>Inbound Weight</th>
                        <th>Company Name</th>
                        <th>Driver Name</th>
                        <th>Driver Image</th>
                        <th>Truck Load Picture</th>
                        <th>Licence Plate #</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                     {orders.map((order) => {
                        return (
                          <tr className="cursor-pointer" key={order._id}>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>{order.ticketNumber}</td>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}><NumberFormat thousandSeparator={true} displayType={"text"} value={order?.inboundWeight} />  lbs </td>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>{order.customer.displayName}</td>
                            <td onClick={() => 
                            onClickOrder( true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>
                              {order?.driver?.firstName} {order?.driver?.lastName}
                            </td>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>
                              {order?.driver && order?.driver?.licenseImageUrl !== "" ?
                                (<div className="thumb-preview">
                                  <img
                                    style={{ transform: `rotate(${order?.driver?.rotation}deg)` }}
                                    src={order?.driver?.licenseImageUrl}
                                    alt=""
                                  />
                                </div>) : null
                          } 
                            </td>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>
                              {order.loadImageUrl && order.loadImageUrl !== "" ? (
                                <div className="thumb-preview">
                                  <img src={order.loadImageUrl} alt="" />
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td onClick={() => 
                            onClickOrder(true,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>{order?.truck?.plateNumber}</td>
                            <td className="text-bold">              
                              {order?.truck?.defaultOutboundWeight !== 0 || order?.defaultOutboundWeight !== 0  ? (
                                  <button className="btn btn-outline-black"  onClick={() => 
                                    onClickOrder(false,order?.ticketNumber, order?.defaultOutboundWeight ?? order?.truck?.defaultOutboundWeight, true)}>
                                  Use Default
                                  </button>
                              ):null}
                                <button  className={weightType==="Outbound"?"btn btn-yellow btn-sm ml-3":"btn btn-yellow btn-sm outbound-disabled ml-3"} 
                                onClick={() =>{weightType==="Outbound" && onClickOrder(false,order?.ticketNumber)}}>
                                Weight Out
                                </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/*<br/>*/}
                </div>
                             
          <Pagination
            className="text-center"
            onChange={onChangePage}
            hideOnSinglePage
            current={currentPage}
            total={totalItems}
            pageSize={limit}
            showSizeChanger={false}
          />
        </>
      )}
      </div>
      </div>
      </section>
    </>
  );
};
export default PendingOrder;
