import React, { PureComponent } from "react";
import { Table } from "antd";
import moment from "moment-timezone";
import ReactExport from "react-data-export";
import _ from "lodash";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class JobReportComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isExportData: false,
      user: {},
    };
  }

  async componentDidMount() {
    const { startDate, endDate } = this.props;
    const {
      value: { data },
    } = await this.props.getReport({
      url: "/deleted-tickets-report",
      startDate,
      endDate,
      timezone: moment().format("Z"),
    });
    this.fetchMe();
    this.setState({ data });
  }
  fetchMe = async () => {
    const {
      value: { user },
    } = await this.props.fetchMe();
    this.setState({ user: user });
  };

  render() {
    const columns = [
      {
        title: "Ticket Number",
        width: 15,
        dataIndex: "ticketNumber",
        key: "ticketNumber",
        align: "center",
      },
      {
        title: "Date Deleted",
        width: 15,
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "center",
        render: (updatedAt) => moment(updatedAt).tz(this.props?.timeZone).format("M-D-YYYY"),
      },
      {
        title: "Deleted By",
        width: 15,
        dataIndex: "deletedBy",
        key: "deletedBy",
        align: "center",
      },
      {
        title: "Time of deletion",
        width: 15,
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "center",
        render: (updatedAt) =>
          moment(updatedAt).tz(this.props?.timeZone).format("LTS"),
      },
      {
        title: "Reason",
        width: 15,
        dataIndex: "deleteReason",
        key: "deleteReason",
        align: "center",
        render: (deleteReason) =>
        _.capitalize(deleteReason),
      },
      {
        title: "Time of order created",
        width: 15,
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (createdAt) =>
          moment(createdAt).tz(this.props?.timeZone).format("LTS"),
      },
      {
        title: "Customer",
        width: 15,
        dataIndex: "companyName",
        key: "companyName",
        align: "center",
      },
      {
        title: "Weights  (gross)",
        width: 15,
        dataIndex: "inboundWeight",
        key: "inboundWeight",
        align: "center",
        render: (inboundWeight,records) => records?.orderCreationType === undefined ?inboundWeight + " Ibs" : '-'
      },
    ];
    const { data } = this.state;
    const { isExportData, pageSize } = this.props;
    return (
      <>
        <div className="work__repot-body">
          <div className="card">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: 1600, y: 300 }}
            />
          </div>
        </div>
        {isExportData && data.length !== 0 && (
          <ExcelFile hideElement filename="Voided Reports">
            <ExcelSheet data={data} name="Orders">
              <ExcelColumn
                label="Ticket Number"
                value={(col) => col.ticketNumber}
              />
              <ExcelColumn
                label="Date Deleted"
                value={(col) => moment(col.updatedAt).format("M-D-YYYY")}
              />
              <ExcelColumn label="Deleted By" value={(col) => col.deletedBy} />
              <ExcelColumn
                label="Time of deletion"
                value={(col) =>
                  moment(col.updatedAt).tz(this.props?.timeZone).format("LTS")
                }
              />
              <ExcelColumn
                label="Reason"
                value={(col) => col.deleteReason}
              />
              <ExcelColumn
                label="Time of order created"
                value={(col) =>
                  moment(col.createdAt).tz(this.props?.timeZone).format("LTS")
                }
              />
              <ExcelColumn label="Customer" value={(col) => col.companyName} />
              <ExcelColumn
                label="Weights  (gross)"
                value={(col) => col?.orderCreationType === undefined ?col?.inboundWeight + " Ibs" : '-'}
              />
            </ExcelSheet>
          </ExcelFile>
        )}
      </>
    );
  }
}
