// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
// import Cookies from 'universal-cookie'
import { INIT } from "../../constants/phase";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const GET_PAYBLI = "empire/paybli/GET_PAYBLI";
export const GET_PAYBLI_CUSTOMER_DATA = 'empire/paybli/GET_PAYBLI_CUSTOMER_DATA'
export const MAKE_TRANSACTION = 'empire/paybli/MAKE_TRANSACTION'
export const MAKE_TERMINAL_TRANSACTION = "empire/paybli/MAKE_TERMINAL_TRANSACTION"
export const GET_PAYBLI_TRANSACTIONS = "empire/paybli/GET_PAYBLI";
export const GET_PAYBLI_TERMINAL_DEVICES = "empire/paybli/GET_PAYBLI_TERMINAL_DEVICES"
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getPaybliIntegration = (payload) => {
  return {
    type: GET_PAYBLI,
    payload: api.getPaymentIntegration(payload),
  };
};

export const getPayabliCustomerData = (payload) => {
  return {
    type: GET_PAYBLI_CUSTOMER_DATA,
    payload: api.getPayabliCustomerData(payload),
  };
};

export const makeTransaction = (payload) => {
  return {
    type: MAKE_TRANSACTION,
    payload: api.makeTransaction(payload),
  };
};
export const makeTerminalTransaction = (payload) => {
  return {
    type: MAKE_TERMINAL_TRANSACTION,
    payload: api.makeTerminalTransaction(payload),
  };
};
export const getPayabliTransactions = (payload) => {
  return {
    type: GET_PAYBLI_TRANSACTIONS,
    payload: api.getPayabliTransactions(payload),
  };
};

export const getPaybliTerminalDevices = (payload) => {
  return {
    type: GET_PAYBLI_TERMINAL_DEVICES,
    payload: api.getPaybliTerminalDevices(payload),
  };
};


/***********************************
 * Epics
 ***********************************/

export const paybliEpic = combineEpics();
