import { connect } from "react-redux";
import {  uploadImage,fetchMe,updateUserToken } from "../../store/user/duck";
import { fetchOrder, updateOrder, sendEmailReceipt, deleteOrder, updateTotalAmount } from "../../store/order/duck";
import InvoiceEachTransactionComponent from "./component";
import {getPricingTierById} from "../../store/Pricing_tier/duck"

const InvoiceEachTransactionContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    fetchOrder,
    updateUserToken,
    updateOrder,
    fetchMe,
    sendEmailReceipt,
    uploadImage,
    deleteOrder,
    updateTotalAmount,
    getPricingTierById
  }
)(InvoiceEachTransactionComponent);

export default InvoiceEachTransactionContainer;

