import { connect } from 'react-redux'
import { fetchMe, uploadImage } from "../../store/user/duck";
import { chargeCustomer, addCard , fetchCustomer,updateCustomer, fetchCustomers, fetchProjects, fetchDriversByCustomerId, fetchTrucksByCustomerId, createProject, fetchPaymentMethodCards} from "../../store/customer/duck";
import {customerInvoicesByCustomerId} from "../../store/Billing/duck"
import { fetchSetting, updateCompany } from "../../store/company/duck";
import { fetchOrder, updateOrder, deleteOrder, sendEmailReceipt, fetchDevices, chargeOnDevice, getOrderInvoicePdf ,updateOrderData } from "../../store/order/duck";
import PendingOrderDetailsComponent from './component'
import { getPaybliIntegration , getPayabliCustomerData,makeTransaction,getPaybliTerminalDevices, makeTerminalTransaction} from '../../store/paybli_integrations/duck';
import {getPricingTierById} from "../../store/Pricing_tier/duck"
import {createDriver,driverDetails} from "../../store/driver/duck"
import { fetchContainers } from '../../store/container/duck';
import {getCustomercredits} from '../../store/Billing/duck'

const PendingOrderDetailsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    fetchMe,
    fetchOrder,
    fetchSetting,
    chargeCustomer,
    updateCustomer,
    addCard,
    updateOrderData,
    deleteOrder,
    updateOrder,
    sendEmailReceipt,
    fetchDevices,
    chargeOnDevice,
    getPaybliIntegration,
    customerInvoicesByCustomerId,
    getPayabliCustomerData,
    fetchCustomer,
    makeTransaction,
    getPricingTierById,
    getPaybliTerminalDevices,
    makeTerminalTransaction,
    getOrderInvoicePdf,
    fetchCustomers,
    fetchTrucksByCustomerId,
    fetchDriversByCustomerId ,
    fetchPaymentMethodCards,
    fetchProjects,
    createProject,
    createDriver,
    uploadImage,
    fetchContainers,
    driverDetails,   
    getCustomercredits,
    updateCompany
  }
)(PendingOrderDetailsComponent);

export default PendingOrderDetailsContainer
