import React, { PureComponent } from "react";
import _ from "lodash";
import { CloseIcon } from "../../components/icons";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class DisableQuickbooks extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      customers: [],
      qbCustomers: [],
      spinning: false,
      qbStatus: false,
      companyId: "",
      qbCompany: null
    };
  }

  componentDidMount = async () => {
    document.title = "QuickBooks | Weighworks";
    this.setState({ spinning: true });
    const [customers, qbCustomers, user, qbUser] = await Promise.all([
      this.props.fetchCustomers({ searchText: "", status: 1, sortOrder: { createdAt: 1 } }),
      this.props.fetchQBCustomers({ skip: 0, limit: 10, search:"" }),
      this.props.fetchMe(),
      this.props.getQuickBooksUser({})
    ]);
    this.setState({
      customers: customers.value.data,
      qbCustomers: qbCustomers.value.data,
      spinning: false,
      qbStatus: user.value.user.company.qbStatus,
      companyId: user.value.user.company._id,
      qbCompany: qbUser.value.data
    });
  };

  updateQuickBooks = async () => {
    const { customers } = this.state;
    await this.props.updateQuickBooks({ customers });
  };

  disableQuickbooks = async () => {
    const {
      value: { status }
    } = await this.props.disableQuickbooks({});
    if (status) {
      this.props.history.push("/dashboard");
    }
  };

  render() {
    const { qbStatus, spinning, qbCompany } = this.state;
    return (
      <div className="quickbooks__quickbooks invoice__mapping">
        <Spin indicator={antIcon} spinning={spinning}>
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Quickbooks Setup</div>
            <div className="head-section-cta">
              <Link to={"/dashboard"} className="btn btn-link btn-back">
                <CloseIcon />
              </Link>
            </div>
          </div>
          <div className="custome__container">
            <div className="row">
              <div className="col-md-12">
                <div className="card quickBooks__right-side mdwidth">
                  <div className="card-header quickbook d-flex align-items-center justify-content-between">
                    <h5 className="card-title">QuickBooks</h5>
                    <button
                      onClick={this.disableQuickbooks}
                      className="btn btn-dark btn-md disable__quickbooks-btn mt-15-mobile"
                    >
                      Disable Quickbooks Integration
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="quickbooks__status">
                      <h3 className="status__title">Company</h3>
                      <h4 className={`status__sub-title`}>{_.get(qbCompany, "CompanyName", "")}</h4>
                    </div>
                    <div className="quickbooks__status">
                      <h3 className="status__title">Quickbooks Connected With</h3>
                      <h4 className={`status__sub-title`}>{_.get(qbCompany, "Email.Address", "")}</h4>
                    </div>
                    <div className="quickbooks__status">
                      <h3 className="status__title">Status</h3>
                      <h4 className={`status__sub-title active`}>{qbStatus ? "Active" : "Active"}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
