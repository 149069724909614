import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import ReactModal from "react-modal";
import { ArrowIcon, CloseIcon } from "../../components/icons";
import { numberFormat, getStartDate, getEndDate } from "../../constants/common";
import moment from "moment";
import "./styles.scss";
import DateRangePicker from "../../components/forms/DateRangePicker";
import NotFound from "../NoRecords/component";
import { loadPayengine } from "payengine";

export default class CreditCardTransactionsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      isCreateDrawerOpen: false,
      modalIsOpen: false,
      WithdrawlOpen: false,
      toggleMenu: false,
      transactions: [],
      openDatePicker: false,
      startDate: moment().format("MMM DD, YYYY"),
      endDate: moment().format("MMM DD, YYYY"),
      selectedDate: "",
      dateType: "today",
      datePickerModal: false,
      payengine: null,
      user: {
        company: {}
      }
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  getFromLocalStorage = async ({ dateType, startDate, endDate }) => {
    await this.setAsyncState({
      startDate: getStartDate(dateType, startDate).format("MMM DD, YYYY"),
      endDate: getEndDate(dateType, endDate).format("MMM DD, YYYY"),
      dateType,
      selectedDate: `${getStartDate(dateType, startDate).format("MMM DD, YYYY")} - ${getEndDate(
        dateType,
        endDate
      ).format("MMM DD, YYYY")}`
    });
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    document.title = "Credit Card Transactions | Weighworks";
    const localStorageData = JSON.parse(localStorage.getItem("date"));
    if (localStorageData) {
      await this.getFromLocalStorage(localStorageData);
    }
    const { fetchMe } = this.props;
    const {
      value: { user }
    } = await fetchMe();
    loadPayengine({
      publicKey: process.env.REACT_APP_PAY_ENGINE_PUBLIC_KEY
    }).then((pe) => {
        console.log(pe)
        this.setState({ user, payengine: new pe() })
    });
    this.fetchTransactions();
  };

  fetchTransactions = async () => {
    const { startDate, endDate, dateType } = this.state;
    const localStorageData = {
      startDate,
      endDate,
      dateType
    };
    localStorage.setItem("date", JSON.stringify(localStorageData));
    const {
      value: { data }
    } = await this.props.fetchTransactions({
      type: "card",
      startDate: moment(startDate)
        .startOf("day")
        .toISOString(),
      endDate: moment(endDate)
        .endOf("day")
        .toISOString(),
      timezone: moment().format("Z")
    });
    this.setState({ transactions: data });
  };

  openModal() {
    this.setState({ openDatePicker: !this.state.openDatePicker });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, datePickerModal: false });
  }

  openDatePicker = (e) => {
    const val = e.target.value
    if (val === "custom") {
      this.setState({ datePickerModal: true })
    }
  }

  setDate(e) {
    const self = this;
    const val = e.target.value;
    if (val === "today") {
      self.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment().format("MMM DD, YYYY"),
          endDate: moment().format("MMM DD, YYYY")
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "this week") {
      self.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate: moment()
            .clone()
            .startOf("isoWeek")
            .format("MMM DD, YYYY"),
          endDate: moment()
            .clone()
            .endOf("isoWeek")
            .format("MMM DD, YYYY")
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "this month") {
      self.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate: moment()
            .startOf("month")
            .format("MMM DD, YYYY"),
          endDate: moment()
            .endOf("month")
            .format("MMM DD, YYYY")
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "custom") {
      self.setState({ datePickerModal: true });
    }
  }

  onSelect = ({ start, end }) => {
    this.setState(
      {
        dateType: "custom",
        selectedDate: `${moment(start).format("MMM DD, YYYY")} - ${moment(end).format("MMM DD, YYYY")}`,
        startDate: moment(start).format("MMM DD, YYYY"),
        endDate: moment(end).format("MMM DD, YYYY"),
        datePickerModal: false
      },
      () => {
        this.fetchTransactions();
      }
    );
  };

  WithdrawlopenModal = () => {
    this.setState({ WithdrawlOpen: !this.state.WithdrawlOpen });
  };

  render() {
    const { transactions, selectedDate, dateType } = this.state;
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
      
        <main className="dashboard-layout-content cashlogbalance__wrapper">
          <div className="page-header">
            <div className="page-header-inner">
              <h2 className="heading-title">Transactions </h2>
            </div>
            <div className="form-group material-textfield custom-select-50">
              <select
                className="form-control custom-select w-150 material-textfield-input pr-22"
                name="dateType"
                onChange={this.setDate.bind(this)}
                // onClick={this.openDatePicker}
                value={dateType}
                required
              >
                <option value="today">Today ({getStartDate("today").format("MMM DD, YYYY")})</option>
                <option value="this week">
                  This Week ({getStartDate("week").format("MMM DD, YYYY")} - {getEndDate("week").format("MMM DD, YYYY")}
                  )
                </option>
                <option value="this month">
                  This Month ({getStartDate("month").format("MMM DD, YYYY")} -{" "}
                  {getEndDate("month").format("MMM DD, YYYY")})
                </option>
                <option value="custom">Custom {selectedDate !== "" ? `(${selectedDate})` : ""}</option>
              </select>
              <label className="material-textfield-label">Date </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="custome__tabs">
                <ul className="custome__tabs-list">
                  <li>
                    <Link to={"/cash-transactions"}>Cash Transactions</Link>
                  </li>
                  <li>
                    <Link className="active" to={"/credit-card-transactions"}>
                      Credit Card Transactions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {transactions.length === 0 ? (
            <NotFound title="No Transactions" />
          ) : (
            transactions.map(transaction => {
              return (
                <div className="row" key={transaction._id}>
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header d-flex align-items-start justify-content-between">
                        <h5 className="card-title">
                          {moment(transaction._id).format("MMM DD, YYYY")} Balance{" "}
                        </h5>
                        <p className="card-title-sm">
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              this.props.history.push({
                                pathname: `/itemized-credit-card-transactions/${transaction._id}`
                              })
                            }
                          >
                            View Itemized List
                            <ArrowIcon />
                          </span>
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="transactions__list">
                              <li>
                                <div>
                                  <h5 className="transactions__title">Credit Card Transations</h5>
                                  <p className="transactions__sub-title">
                                    {transaction.totalTransactions === 1
                                      ? `${transaction.totalTransactions} transaction`
                                      : `${transaction.totalTransactions} transactions`}
                                  </p>
                                </div>
                                <div className="text-amount text-green">${numberFormat(transaction.totalAmount)}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {/* <div className="page-header-inner">
            <h4 className="heading-title">Transaction List</h4>
          </div>
          <div className="card">
            <pay-engine id="pf-transactions" 
              type="transactions" 
              merchant-id={user.company.payEngineMerchantId}
              hash={user.company.merchantHash}></pay-engine>
          </div>
          <div className="page-header-inner">
            <h4 className="heading-title">Payouts List</h4>
          </div>
          <div className="card">
            <pay-engine id="pf-payouts" 
              type="payouts" 
              merchant-id={user.company.payEngineMerchantId}
              hash={user.company.merchantHash}></pay-engine>
          </div> */}
        </main>
        <ReactModal
          isOpen={this.state.WithdrawlOpen}
          onRequestClose={this.WithdrawlopenModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Deposit</h5>
              <button type="button" className="btn react-modal-close" onClick={this.WithdrawlopenModal}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group material-textfield">
                    <input type="text" className="form-control material-textfield-input" required />
                    <label className="material-textfield-label">
                      Enter Amount<span>*</span>
                    </label>
                  </div>
                </div>
              </div>
              <button className="btn btn-dark btn-lg w-100">Submit</button>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Withdrawl</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group material-textfield">
                    <input type="text" className="form-control material-textfield-input" required />
                    <label className="material-textfield-label">
                      Enter Amount<span>*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group material-textfield">
                <select className="form-control custom-select material-textfield-input" required>
                  <option></option>
                </select>
                <label className="material-textfield-label">
                  Withdrawl Reason<span>*</span>
                </label>
              </div>
              <button className="btn btn-dark btn-lg w-100">Submit</button>
            </div>
          </div>
        </ReactModal>
        <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format("MMM DD, YYYY")} - ${moment(
                  this.state.endDate
                ).format("MMM DD, YYYY")}`
              },
              () => {
                this.closeModal();
                this.fetchTransactions();
              }
            );
          }}
          handleFromChange={date => this.setState({ startDate: moment(date).format("MMM DD, YYYY") })}
          handleToChange={date => this.setState({ endDate: moment(date).format("MMM DD, YYYY") })}
          from={this.state.startDate}
          to={this.state.endDate}
        />
      </div>
    );
  }
}
