import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken, fetchCompanyUsers } from '../../store/user/duck'
import { fetchTransactions, saveAmount , fetchTransaction} from "../../store/transaction/duck";
import CashLogBalanceComponent from './component'

const CashLogBalanceContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    updateUserToken,
    fetchTransactions,
    fetchTransaction,
    saveAmount,
    fetchCompanyUsers

  }
)(CashLogBalanceComponent);

export default CashLogBalanceContainer
