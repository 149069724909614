import React, { PureComponent } from "react";
import { message } from "antd";
import BlackLogo from "../../images/logo.png";
// import { Link } from 'react-router-dom'
import "./styles.scss";

export default class LoginComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      err: {},
      code: "",
      message: "",
      disable: true,
      signInButtonDisable: true,
      loginMessage: true,
      logo: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.attemptLogin = this.attemptLogin.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }

  handleChange(e) {
    let disable = false;
    const self = this;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (self.state.email === "" || self.state.email === undefined) {
        disable = true;
      }
      self.setState({ disable });
    });
  }

  componentDidMount = async () => {
    document.title = "Login | Weighworks";
    const { fetchCompanyLogo } = this.props;
    const {
      value: {
        logo
      }
    } = await fetchCompanyLogo();
    if(logo && logo !== "") {
      this.setState({ logo })
    } else {
      this.setState({ logo: BlackLogo })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sendVerificationCodePhase === "success") {
      message.success(this.props.message);
    } else if (this.props.sendVerificationCodePhase === "error") {
      message.error(this.props.message);
    } else if (this.props.loginPhase === "error") {
      message.error(this.props.loginError);
    } else if (this.props.loginPhase === "success") {
      if (this.props.user) {
        if (this.props.user && this.props.user.role === "superadmin") {
          this.props.history.push("/companylisting");
        } else {
            this.props.history.push("/dashboard");
          }
        }
      }
    this.props.userClearPhase();
  }

  handleChangeForCode(e) {
    this.setState({ error: "" });
    const codeVal = 4;
    const self = this;
    let signInButtonDisable = true;
    this.setState({ code: e.target.value }, () => {
      if (this.state.code.length === codeVal) {
        signInButtonDisable = false;
      }
      self.setState({ signInButtonDisable });
    });
  }

  loginUser() {
    const codeVal = 4;
    if (this.state.code.length === codeVal) {
      const loginData = {
        code: this.state.code,
        email: this.state.email
      };
      this.props.loginUser(loginData);
    } else {
      message.error("Please insert valid code");
    }
  }

  removeCode() {
    this.props.codeClearPhase();
  }

  attemptLogin() {
    const { email } = this.state;
    /* eslint-disable */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const err = {};

    if (email === "" || email.trim() === "") {
      err.email = "Email is required.";
    }

    if (email && email !== "") {
      const testR = emailRegex.test(String(this.state.email).toLowerCase());
      if (!testR) {
        err.email = "Email is invalid.";
      }
    }

    this.setState({ err });
    if (!Object.keys(err).length) {
      const { sendVerificationCode } = this.props;
      this.setState({ isLoading: true });
      const data = {
        email
      };
      sendVerificationCode(data);
    }
  }

  handleEnterCode() {
    var code = event.keyCode || event.charCode;
    if (code === 13) {
      this.loginUser();
    }
  }

  handleEnter(event) {
    var code = event.keyCode || event.charCode;
    if (code === 13) {
      this.attemptLogin();
    }
  }

  render() {
    const { sendVerificationCodeData } = this.props;
    const { logo } = this.state
    return (
      <div>
        <main className="login-container">
          {!Object.keys(sendVerificationCodeData).length ||
          !sendVerificationCodeData.status ? (
            <div className="card login-container-inner">
              <div className="logo-container">
              {logo !== "" ?
                <img 
                  className="img-fluid logo" 
                  src={logo !== "" ? logo : BlackLogo} 
                  alt=""
                />
                  :
                  ""
                }
              </div>
              <p>
                Please enter your email address below and we will send you a
                login code
              </p>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  name="email"
                  onKeyPress={this.handleEnter.bind(this)}
                  value={this.state.email}
                  onChange={this.handleChange}
                  className="form-control material-textfield-input"
                  required
                />
                {this.state.err.email ? (
                  <span className="invalid-feedback">
                    {this.state.err.email}
                  </span>
                ) : (
                  ""
                )}
                <label className="material-textfield-label">
                  Login with Email
                </label>
              </div>
              <button
                type="submit"
                className="btn-block btn-login btn-dark"
                onClick={this.attemptLogin}
                onKeyDown={this.attemptLogin}
              >
                Login
              </button>
            </div>
          ) : (
            ""
          )}
          {sendVerificationCodeData &&
          sendVerificationCodeData.status === true ? (
            <div className="card login-container-inner">
              {logo !== "" ?
                <img 
                  className="img-fluid logo" 
                  src={logo !== "" ? logo : BlackLogo} 
                  alt=""
                />
                :
                ""
              }
              <p>
                Please check your email address and we will send you a login
                code.
              </p>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  name="code"
                  className="form-control material-textfield-input"
                  value={this.state.code}
                  onKeyPress={this.handleEnterCode.bind(this)}
                  onChange={this.handleChangeForCode.bind(this)}
                  required
                />
                {this.state.error ? (
                  <span className="invalid-feedback">{this.state.error}</span>
                ) : (
                  ""
                )}
                <label className="material-textfield-label">
                  Enter login code
                </label>
              </div>
              <button
                type="submit"
                className="btn-block btn-login btn-dark"
                disabled={this.state.disable}
                onClick={this.loginUser}
                onKeyDown={this.loginUser}
              >
                Login
              </button>
            </div>
          ) : (
            ""
          )}
        </main>
      </div>
    );
  }
}
