import { connect } from "react-redux";
import {
  getMe,
  fetchCompanyUsers,
  updateUserToken,
  fetchMe,
} from "../../store/user/duck";
import { fetchCustomers } from "../../store/customer/duck";
import { fetchCompanyDrivers } from "../../store/company/duck";
import {
  fetchReports,
  exportOrder,
  generatePdf,
  generateMultiplePdf,
} from "../../store/order/duck";
import CreditCardPayoutDetailsComponent from "./component";
import { getPayabliTransactions } from "../../store/paybli_integrations/duck";

const CreditCardPayoutDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getMe,
    fetchReports,
    fetchCustomers,
    fetchMe,
    fetchCompanyUsers,
    fetchCompanyDrivers,
    updateUserToken,
    exportOrder,
    generatePdf,
    generateMultiplePdf,
    getPayabliTransactions
  }
)(CreditCardPayoutDetailsComponent);

export default CreditCardPayoutDetailsContainer;
