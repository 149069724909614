import { connect } from 'react-redux'
import SignPadComponent from './component'
import { 
  uploadSignature
} from '../../store/signature_pad/duck'
const SignPadContainer = connect(
  // Map state to props
  state => ({
  	
  }),
  {
  	uploadSignature
  }
)(SignPadComponent)
export default SignPadContainer;