import { connect } from 'react-redux'
import { getMe ,updateUserToken,fetchMe} from '../../store/user/duck'
import { fetchDashboardData } from "../../store/dashboard/duck";
import {fetchCompaniesByID} from '../../store/company/duck'
import DashboardComponent from './component'

const DashboardContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  // Map actions to dispatch and props
  {
    getMe,
    fetchMe,
    fetchDashboardData,
    fetchCompaniesByID,
    updateUserToken
    
  }
)(DashboardComponent);

export default DashboardContainer
