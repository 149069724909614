import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const fetchTransactions = ({ type, startDate, endDate, timezone,userId }) => {
  return fetch(`${HOSTNAME}/transactions?type=${type}&startDate=${startDate}&endDate=${endDate}&timezone=${timezone}&userId=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchTransaction = ({ type, startDate, endDate,userId }) => {
  return fetch(`${HOSTNAME}/transaction?type=${type}&startDate=${startDate}&endDate=${endDate}&userId=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const saveAmount = data => {
  return fetch(`${HOSTNAME}/save-amount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchClosingBalance = ({ startDate, endDate, timezone }) => {
  return fetch(`${HOSTNAME}/closing-balance?startDate=${startDate}&endDate=${endDate}&timezone=${timezone}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
