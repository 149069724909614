import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { CloseIcon } from "../components/icons";

class PermissionDialog extends PureComponent {
  render() {
    return (
      <ReactModal
        isOpen={this.props?.open}
        onRequestClose={this.props?.onClose}
      >
        <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Edit Synced Orders</h5>
            <button
              className="btn react-modal-close"
              onClick={this.props?.onClose}
            >
              <CloseIcon />
            </button>
          </div>

          <div className="react-modal-body">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>
                    Orders already synced to Quickbooks will not be resynced
                    after editing in WeighWorks. Edited orders in WeighWorks
                    should be manually updated in Quickbooks to avoid
                    reconciliation issues.
                  </b>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-right">
                <button
                  className="btn btn-dark btn-sm delete-btn"
                  onClick={this.props?.onOkChange}
                >
                  OK
                </button>
                <button
                  className="btn btn-yellow btn-sm delete-btn mx-2"
                  onClick={this.props?.onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default PermissionDialog;
