import { connect } from 'react-redux'
import { getMe } from '../../store/user/duck'
import { fetchSetting, updateStripeInfo } from '../../store/company/duck'
import StripeSettingsComponent from './component'

const StripeSettingsContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchSetting,
    updateStripeInfo
  }
)(StripeSettingsComponent)

export default StripeSettingsContainer
