import { datadogRum } from '@datadog/browser-rum';

if(process.env.REACT_APP_DATADOG_IS_ACTION){

    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: process.env.REACT_APP_DATADOG_SITE,
        service:'weighworks_dashboard',
        env: process.env.REACT_APP_DATADOG_ENV,
        sessionSampleRate:100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input'
    });
    
    datadogRum.startSessionReplayRecording();
}