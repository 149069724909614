import React from "react";
import _ from "lodash";
import { connect } from "formik";

class ErrorFocus extends React.Component {
  isObject(value) {
    return value && typeof value === "object" && value.constructor === Object;
  }

  getKeysRecursively = (val, keys = []) => {
    const result = _.isObject(val) // if it's an object or array
      ? _.flatMap(val, (v, k) => this.getKeysRecursively(v, [...keys, k])) // iterate it and call fn with the value and the collected keys
      : keys.join(".");
    return result;
	};

  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const keys = this.getKeysRecursively(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selectorKey = keys[0];
      const selector = `[id="${selectorKey}"], [name="${selectorKey}"] `;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}
export default connect(ErrorFocus);
