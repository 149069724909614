import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import Logo from '../../images/logo.png'
import './styles.scss'

export default class InactivityLogoutComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  render() {
    return (
      <div>
        <div className="inactivitylogout__container">
          <div className="inactivitylogout__body">
            <img className="img-fluid" src={Logo} alt="Empire"/>
            <h1 className="title">Busy with other things?</h1>
            <p>We thought you left, so for your security we logged you out.</p>
            <button onClick={() => this.props.history.push("/login")} className="btn logbackin-btn">Log Back In</button>
          </div>
        </div>
      </div>
    )
  }
}
