// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
// import Cookies from 'universal-cookie'
import { INIT } from "../../constants/phase";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const FETCH_ORDERS = "empire/order/FETCH_ORDERS";
export const FETCH_ORDER = "empire/order/FETCH_ORDER";
export const FETCH_PROJECT_ORDER = "empire/order/FETCH_PROJECT_ORDER";
export const UPDATE_ORDER = "empire/order/UPDATE_ORDER";
export const FETCH_ORDER_LIST = "empire/order/FETCH_ORDER_LIST";
export const FETCH_REPORTS = "empire/order/FETCH_REPORTS";
export const UPDATE_ORDER_SUSTAINABILITY_INFO = "empire/order/UPDATE_ORDER_SUSTAINABILITY_INFO";
export const EMAIL_RECEIPT = "empire/order/EMAIL_RECEIPT";
export const EXPORT_ORDER = "empire/order/EXPORT_ORDER";
export const DELETE_ORDER = "empire/order/DELETE_ORDER";
export const GENERATE_PDF = "empire/order/GENERATE_PDF";
export const GENERATE_MULTIPLE_PDF = "empire/order/GENERATE_MULTIPLE_PDF";
export const UPDATE_TOTAL_AMOUNT = "empire/order/UPDATE_TOTAL_AMOUNT";
export const FETCH_DEVICES = "empire/order/FETCH_DEVICES";
export const CHARGE_ON_DEVICE = "empire/order/CHARGE_ON_DEVICE";
export const GET_ORDER_INVOICE_PDF = "empire/order/GET_ORDER_INVOICE_PDF"
export const PRINT_PERFORATED_ORDER = "empire/order/PRINT_PERFORATED_ORDER"
export const PRINT_ORDER_DETAILS = "empire/order/PRINT_ORDER_DETAILS"
export const UPDATE_ORDER_DATA="empire/order/PRINT_ORDER_DETAILS/UPDATE_ORDER_DATA"
export const FETCH_DEVICES_STATUS = "empire/order/FETCH_DEVICES_STATUS";
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const fetchOrders = payload => {
  return {
    type: FETCH_ORDERS,
    payload: api.fetchOrders(payload)
  };
};

export const fetchOrder = payload => {
	return {
		type: FETCH_ORDER,
		payload: api.fetchOrder(payload)
	};
};
export const fetchProjectOrderList = payload => {
	return {
		type: FETCH_PROJECT_ORDER,
		payload: api.fetchProjectOrderList(payload)
	};
};

export const updateOrder = payload => {
  return {
    type: UPDATE_ORDER,
    payload: api.updateOrder(payload)
  };
};

export const updateOrderData = payload => {
  return {
    type: UPDATE_ORDER_DATA,
    payload: api.updateOrderData(payload)
  };
};

export const fetchOrderList = payload => {
  return {
    type: FETCH_ORDER_LIST,
    payload: api.fetchOrderList(payload)
  };
};

export const fetchReports = payload => {
  return {
    type: FETCH_REPORTS,
    payload: api.fetchReports(payload)
  };
};
export const printPerforatedOrder = payload => {
  return {
    type: PRINT_PERFORATED_ORDER,
    payload: api.printPerforatedOrder(payload)
  };
};

export const updateOrderSustainabilityInfo = payload => {
  return {
    type: UPDATE_ORDER_SUSTAINABILITY_INFO,
    payload: api.updateOrderSustainabilityInfo(payload)
  };
};

export const exportOrder = payload => {
  return {
    type: EXPORT_ORDER,
    payload: api.exportOrder(payload)
  };
};

export const sendEmailReceipt = payload => {
  return {
    type: EMAIL_RECEIPT,
    payload: api.sendEmailReceipt(payload)
  };
};

export const deleteOrder = (payload,reason) => {
  return {
    type: DELETE_ORDER,
    payload: api.deleteOrder(payload,reason)
  };
};

export const generatePdf = payload => {
  return {
    type: GENERATE_PDF,
    payload: api.generatePdf(payload)
  };
};

export const generateMultiplePdf = payload => {
  return {
    type: GENERATE_MULTIPLE_PDF,
    payload: api.generateMultiplePdf(payload)
  };
};

export const updateTotalAmount = payload => {
  return {
    type: UPDATE_TOTAL_AMOUNT,
    payload: api.updateTotalAmount(payload)
  };
};

export const fetchDevices = () => {
  return {
    type: FETCH_DEVICES,
    payload: api.fetchDevices()
  };
};

export const getOrderInvoicePdf = (payload) => {
  return {
    type: GET_ORDER_INVOICE_PDF,
    payload: api.getOrderInvoicePdf(payload)
  };
}
export const printOrderDetails = (payload) => {
  return {
    type: PRINT_ORDER_DETAILS,
    payload: api.printOrderDetails(payload)
  };
}

export const chargeOnDevice = payload => {
  return {
    type: CHARGE_ON_DEVICE,
    payload: api.chargeOnDevice(payload)
  };
};

export const fetchDeviceStatus = (payload) => {
  return {
    type: FETCH_DEVICES_STATUS,
    payload: api.fetchDeviceStatus(payload)
  };
};

/***********************************
 * Epics
 ***********************************/

export const orderEpic = combineEpics();
