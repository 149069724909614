import { connect } from 'react-redux'
import LicenseAgreementComponent from './component'

const LicenseAgreementContainer = connect(
  // Map state to props
  state => ({
  }),
  {
  }
)(LicenseAgreementComponent)

export default LicenseAgreementContainer
