import { connect } from 'react-redux'
import { getMe } from '../../store/user/duck'
import { fetchOrder, updateOrderSustainabilityInfo } from "../../store/order/duck";
import SustainabilityInformationComponent from './component'

const SustainabilityInformationContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
  	fetchOrder,
  	updateOrderSustainabilityInfo
  }
)(SustainabilityInformationComponent)

export default SustainabilityInformationContainer
