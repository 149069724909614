import React, { PureComponent } from "react";
import "../styles.scss";
import moment from "moment-timezone";
import { RightCaretIcon, } from "../../../components/icons";
class CusromerCreditsComponent extends PureComponent {
    constructor(props) {
        const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
        super(props);
        this.state = {
            customercredits: [],
            tz
        };
    }

    componentDidMount = () => {
        this.setState({ customercredits: this.props.customercredits })
    };

    componentWillReceiveProps(props) {
        // return the new state as object, do not call .setState()
        this.setState({ customercredits: props.customercredits })
    }
    numberWithCommas = (x) => {
        var parts = x.toFixed(2).split(".");
        var num =
            parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
            (parts[1] ? "." + parts[1] : "");
        return num;
    };

    render() {
        return (
            <>
                <div className="show-data active">
                    <div className="table-responsive">
                        <table className="table custom-table">
                            <thead>
                                <tr>
                                    <th className="first-child">Credit Memo #</th>
                                    {/* <th className="text-center">Quickbooks Sync</th> */}
                                    <th>Status</th>
                                    {/* <th>Quickbooks Sync</th> */}
                                    <th>Payment Date</th>
                                    <th>Payment Receipt #</th>
                                    <th>Invoice #</th>
                                    <th>Amount</th>
                                    <th className="last-child">Credit Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.customercredits.length > 0 ? this.state.customercredits.map((item) =>
                                    <tr>
                                        <td className="first-child">{item?.creditMemoNumber}</td>
                                        <td>{item?.status==="created" ? "CM Created" :"CM Used" }</td>
                                        {/* <td><SyncIcon className="icon" /></td> */}
                                        <td>{moment(item?.paymentDate)?.tz(this.state?.tz)?.format('l') + " " + moment(item?.paymentDate)?.tz(this.state?.tz)?.format('LT')}</td>
                                        <td>{item?.paymentReciptInvoiceId}</td>
                                        <td className="white-space-normal">{item.invoices.length > 0 && item.invoices.map((items,index) =>{
                                            return(
                                                <> {index === item?.invoices?.length - 1 ?<span className="underline" onClick={() => {!item?.is_deleted_invoice_credit && this.props.getinvoiceDetailData(items) }}>  {items} </span> : <span className="underline" onClick={() => {!item?.is_deleted_invoice_credit && this.props.getinvoiceDetailData(items) }}>  {items + ", "} </span>}</>
                                            )
                                        }
                                        )}</td>
                                        <td>${this.numberWithCommas(Number(item?.amount))}</td>
                                        <td>${ item?.cumulativeCreditAmount ? this.numberWithCommas(Number(item?.cumulativeCreditAmount)):"0"}</td>
                                    </tr>
                                )
                                    :
                                    <tr>
                                        <td colSpan={10}>
                                            <div className="empty__section">
                                                <div className="empty__section-inner">
                                                    <p className="empty__text">No Credits</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                            <div className="d-flex align-items-center justify-content-center text-center">
                      <button className="btn btn-dark btn-xs mr-2 btn-previous" onClick={() => this.props.onCreditChangePage('prev')} disabled={this.props.currentCreditPage <= 1 || this.props.customercredits.length === 0}><RightCaretIcon/>  Previous</button>
                      <button className="btn btn-dark btn-xs btn-next" onClick={() => this.props.onCreditChangePage('next')} disabled={!(this.props.customercredits.length > 10 )}>Next  <RightCaretIcon/></button>
                    </div>
                </div>

            </>
        );
    }
}

export default CusromerCreditsComponent;
