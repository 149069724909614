import { connect } from "react-redux";
import { getMe, fetchCompanyUsers, fetchMe ,updateUserToken} from "../../store/user/duck";
import { fetchOrderList, exportOrder } from "../../store/order/duck";
import { fetchCustomers } from "../../store/customer/duck";
import { fetchCompanyDrivers } from "../../store/company/duck";
import OrdersComponent from "./component";
import { fetchSetting } from "../../store/company/duck";
import { getInvoiceDetalByInvoiceId } from "../../store/Billing/duck";
import { getReceivePaymentForCustomers, batchPrintInvoice, getProjectByProjectId, getPaymentOfInvoice } from "../../store/Billing/duck";
import { sendInvoiceEmail } from "../../store/Billing/duck";
import { updateInvoicDetail } from "../../store/Billing/duck";
import { getOrderInvoicePdf } from "../../store/order/duck";
import { uploadImage } from "../../store/user/duck";
import { updateInvoiceOrder } from "../../store/Billing/duck";
import { fetchOrder } from "../../store/order/duck";
import { getReceivePaymentForPdf } from "../../store/Billing/duck";

const OrdersContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
	fetchMe,
	updateUserToken,
  	fetchOrderList,
  	fetchCustomers,
	fetchSetting,
  	fetchCompanyUsers,
	getProjectByProjectId,
	sendInvoiceEmail,
	updateInvoicDetail,
	getOrderInvoicePdf,
	getReceivePaymentForCustomers,
	getInvoiceDetalByInvoiceId,
	uploadImage,
	updateInvoiceOrder,
	fetchOrder,
	getReceivePaymentForPdf,
    exportOrder,
  	fetchCompanyDrivers,
	batchPrintInvoice,
	getPaymentOfInvoice
  }
)(OrdersComponent);

export default OrdersContainer;
