import React, { PureComponent } from 'react';
import TopNavigation from './../TopNavigation/container';
import SidebarNavigation from './../SidebarNavigation/component';
import moment from 'moment';
import ReactModal from 'react-modal';
import 'react-datetime/css/react-datetime.css';
import './styles.scss';
import NotFound from '../NoRecords/component';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Switch, message } from 'antd';
import SettingsContainer from '../Settings/container';
import CreateScaleContainer from '../ScaleSetup/container';
import DeleteScale from './partials/deleteScale'
import CreateEditPricingContainer from '../PricingTierCreateEdit/container';
import CustomerPricing from './partials/CustomerPricing';
import payabliIcon from "./../../images/payabli_icon.svg";
import email_svg from "../../images/email.svg"
import signature_pad from "../../images/signature_pad.jpeg";
import quickbooksIcon from "./../../images/quickbooks_icon.svg";
import { CloseIcon } from "../../components/icons";
import EditEmailPopup from './partials/EditEmailPopup';
import SignaturePadModal from "./partials/SignaturePadModal";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class BusinessAdminComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    const activeTab =
      props.location.pathname === '/business-admin/business-settings'
        ? 'active'
        : 'completed';
    this.state = {
      scales: [],
      businessSetting: {},
      scaleIdForModel: '',
      isAddNewScaleModelOpen: false,
      isBusinessSettingModelOpen: false,
      isDeleteScaleModelOpen: false,
      scaleName: "",
      isCreateEditPricingModelOpen: false,
      isCustomerPricingModelOpen: false,
      companyIds: '',
      modalIsOpen: false,
      toggleMenu: false,
      url: '',
      initialValues: {
        date: new Date(),
        manifestNumber: '',
        manifestNumberOther: '',
        facilityId: '',
        haulerId: '',
        truckType: '',
        actualGross: 0,
        tare: 0,
        materials: [
          {
            material: '',
            grossWeight: '',
            yardage: '',
          },
        ],
      },
      companyId: '',
      activities: [],
      facilities: [],
      inHouseHaulers: [],
      thirdPartyHaulers: [],
      pricingList: [],
      activeTab,
      completeModal: false,
      editModal: false,
      showModal: false,
      activity: {},
      searchText: '',
      typingTimeout: 0,
      dateType: '',
      selectedDate: '',
      datePickerModal: false,
      startDate: moment().format('MMM DD, YYYY'),
      endDate: moment().format('MMM DD, YYYY'),
      spinning: false,
      isDeleteModal: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      progressBar: false,
      paybliEnable: false,
      isPaybliModel:false,
      isQuickbooksConnected:false,
      is_custom_domain_email: false,
      is_custom_domain_modal_open: false,
      is_custom_email_edit:false,
      customersByPricing: [],
      isCustomersByPricingOpen: false,
      showActivePricingTiers: false,
      openSignaturePadModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidUpdate = (nextProps) => {
    const {
      location: { pathname },
    } = this.props;
    if(pathname!==nextProps?.location?.pathname) {
      this.onClickTab('active', pathname, true)
    }
  }
  componentDidMount = async () => {
   this.fetchIntegrations()
   this.getPaybliIntegration();
    window.scrollTo(0, 0);
    const {
      value: { user },
    } = await this.props.fetchMe();
    if (user?.permissions) {
      this.setState({ permissions: user?.permissions })
    }
    this.setPageTitle(window.location.pathname);
    this.setState({ url: window.location.pathname });
    const urlArray = window.location.pathname.split('/');
    if (
      window.location.pathname === '/add-scale-setting' ||
      urlArray[1] === `edit-scale-setting`
    ) {
      this.setState({ isAddNewScaleModelOpen: true });
    }
    if (
      window.location.pathname === '/add-pricing-tier' ||
      urlArray[1] === `edit-pricing-tier`
    ) {
      this.setState({ isCreateEditPricingModelOpen: true });
    }
    if (urlArray[1] === `customer-pricing-tier`
    ) {
      this.setState({ isCustomerPricingModelOpen: true });
    }
    if (window.location.pathname === '/business-admin/edit-business-settings') {
      this.setState({ isBusinessSettingModelOpen: true });
    }

    if (window.location.pathname === '/business-admin/business-settings' || window.location.pathname === '/business-admin/integrations') {
      this.fetchSetting(user.companyId);
    }
    const {value: {data}}
     = await this.props.getCompanyById({companyId: user.companyId});
    this.setState({ companyIds: user.companyId, is_custom_domain_email: data.fromEmail });
  };

  getPricingTier = async () => {
    const {
      value: {
        data: { pricingList },
      },
    } = await this.props.getPricingTier();
    this.setState({ pricingList: pricingList ?? [] });
    this.setState({ url: "/business-admin/pricing-tier" })
  };

  async fetchIntegrations() {
    const 
      { value: { status }}
     = await 
      this.props.verifyQBIntegration() 
    this.setState({
      isQuickbooksConnected: status,
    })
  }

  fetchSetting = async (companyId) => {
    this.setState({ spinning: true })
    const {
      value: {
        data: {
          businessInfo,
          logo,
          scaleip,
          scaleport,
          autoLogoutDuration,
          microblinkEnabled,
          chargeStripeCardFees,
          signatureRequired,
          timezone,
          domain,
          cities,
          isEnvironmentalLoadFee,
          isSalesTax,
          environmentalLoadFeeAmount,
          is_open_pdf_when_an_order_complete,
          isFuelSurchardeFee,
          fuelSurchardeFeeAmount,
          orderPrefix,
          invoicePrefix,
          billingEmail,
          serviceAddress,
          isYardageRequired,
          isRoundOffAmount,
          emailForPaymentsOnOrders,
          isSignaturePadIntegration
        },
      },
    } = await this.props.fetchSetting(companyId);
    const businessSetting = {
      businessInfo,
      logo,
      scaleip,
      scaleport,
      autoLogoutDuration,
      microblinkEnabled,
      chargeStripeCardFees,
      signatureRequired,
      timezone,
      domain,
      cities,
      isEnvironmentalLoadFee,
      isSalesTax,
      environmentalLoadFeeAmount,
      is_open_pdf_when_an_order_complete,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      orderPrefix,
      invoicePrefix,
      billingEmail,
      serviceAddress,
      isYardageRequired,
      isRoundOffAmount,
      emailForPaymentsOnOrders,
      isSignaturePadIntegration
    };
    this.setState({ businessSetting: businessSetting });
    if (window.location.pathname !== '/business-admin/integrations') {
      this.setState({ url: '/business-admin/business-settings' });
    }
    this.setState({ spinning: false })
  };

  getScales = async () => {
    this.setState({ spinning: true });
    const {
      value: {
        data: { scales },
      },
    } = await this.props.getScale();
    this.setState({ scales, spinning: false, url: '/business-admin/scales' });
  };

  closeBusinessModel() {
    this.props.history.push('/business-admin/business-settings');
    this.fetchSetting(this.state.companyIds);
    this.setState({ isBusinessSettingModelOpen: false });
  }
  closeDeleteScaleModel() {
    this.props.history.push('/business-admin/scales');
    this.getScales();
    this.setState({ isDeleteScaleModelOpen: false });
  }

  closePricingModel() {
    this.props.history.push('/business-admin/pricing-tier');
    this.getPricingTier();
    this.setState({ isCreateEditPricingModelOpen: false });
  }

  closeCustomerPricingModel() {
    this.props.history.push('/business-admin/pricing-tier');
    this.getPricingTier();
    this.setState({ isCustomerPricingModelOpen: false });
  }

  closeAddNewScaleModel() {
    this.props.history.push('/business-admin/scales');
    this.getScales();
    this.setState({ isAddNewScaleModelOpen: false });
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    document.removeEventListener("click", this.handleOutsideClick, false);
    this.setState({
      modalIsOpen: false,
      editModal: false,
      completeModal: false,
      showModal: false,
      isPaybliModel:false,
      datePickerModal: false,
      isCustomersByPricingOpen: false,
    });
  }

  deleteOutboundActivity = async () => {
    const { deleteOutboundActivity } = this.props;
    const { _id } = this.state;
    const {
      value: { status },
    } = await deleteOutboundActivity(_id);
    if (status) {
      this.setState({ isDeleteModal: false });
      this.fetchOutboundActivities();
    }
  };

  setPageTitle = (url) => {
    if (url === '/business-admin/business-settings') {
      document.title = 'Business Settings | Weighworks';
    } else if (url === '/business-admin/scales') {
      this.getScales();
      document.title = 'Scale Setup | Weighworks';
    } else if (url === '/business-admin/pricing-tier') {
      this.getPricingTier();
      document.title = 'Pricing Tier | Weighworks';
    }
    else if (url === '/business-admin/integrations') {
      this.getPaybliIntegration();
      document.title = 'Integrations | Weighworks';
    }
  };
  getPaybliIntegration = async () => {
    const {
      value: { status }
    } = await this.props.getPaybliIntegration();
    if (status) {
      this.setState({ paybliEnable: true })
    }
  }
  onClickTab(activeTab, url, isRedirect) {
    const { history } = this.props;
    const { companyIds } = this.state;
    this.setPageTitle(url);
    const self = this;
    this.setState({ activeTab, url: url, currentPage: 1, skip: 0 }, () => {
      if (url === '/business-admin/business-settings') {
        self.fetchSetting(companyIds);
      }
      if(url === '/business-admin/integrations'){
        this.fetchIntegrations()
      }
      history.push(url);
    });
  }

  onSelect = ({ start, end }) => {
    this.setState(
      {
        dateType: 'custom',
        selectedDate: `${moment(start).format('MMM DD, YYYY')} - ${moment(
          end
        ).format('MMM DD, YYYY')}`,
        startDate: moment(start).format('MMM DD, YYYY'),
        endDate: moment(end).format('MMM DD, YYYY'),
        datePickerModal: false,
      },
      () => {
        this.fetchOutboundActivities();
      }
    );
  };

  handleSubmit = async (
    { grossWeight, ...values },
    { setSubmitting, setErrors }
  ) => {
    const { companyId } = this.state;
    setSubmitting(true);
    const payload = {
      ...values,
      status: 1,
      companyId,
    };
    const {
      value: { status, message },
    } = await this.props.createOutboundActivity(payload);
    if (status) {
      this.fetchOutboundActivities();
      this.closeModal();
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setErrors({ email: message });
    }
  };

  onToggleChange = async(item) => {
    const { value: { data: { Customers }}} = await this.props.getCustomerByPricing({ id: item._id })
    if (Customers.length > 0 && item.isActive) {
      document.addEventListener("click", this.handleOutsideClick, false);
      this.setState({ isCustomersByPricingOpen: true, customersByPricing: Customers })
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
      await this.props.editPricingTier({
        pricingTier: item.pricingTier,
        id: item._id,
        isActive: !item.isActive
      })
      message.success(`Pricing tier ${item.isActive ? 'deactivated' : 'activated'}`)
      this.getPricingTier()
    }
  }

  handleOutsideClick = e => {
    if (!this.node.contains(e.target)) this.closeModal();
  };

  render() {
    const { scales, pricingList} = this.state;
    let filteredPricingList = pricingList;
    if (!this.state.showActivePricingTiers) {
      filteredPricingList = pricingList.filter(obj => obj.isActive !== this.state.showActivePricingTiers)
    }
    const {
      location: { pathname },
    } = this.props;
    const { activeTab, businessSetting, searchText, openSignaturePadModal } = this.state;

    let permissions={}
      if(this.props.user?.permissions){
        permissions=this.props.user.permissions
      }else{
        permissions=this.state.permissions
      }
    return (
      <div className='layout-has-sidebar' ref={node => { this.node = node }}>
        <TopNavigation
          {...this.props}
          searchText={searchText}
          // handleSearchChange={this.handleSearch}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
        <main className='dashboard-layout-content businessadmin__wrapper'>
          <Spin indicator={antIcon} spinning={this.state.spinning}>
            <div className='page-header'>
              <h2 className='heading-title'>Business Admin</h2>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='custome__tabs'>
                  <ul className='custome__tabs-list'>
                    {permissions?.businessAdmin?.businessSetup &&
                      <li>
                        <span
                          className={
                            pathname === '/business-admin/business-settings'
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            this.onClickTab(
                              'active',
                              '/business-admin/business-settings'
                            )
                          }>
                          Business Settings
                        </span>
                      </li>
                    }
                    {permissions?.businessAdmin?.pricingSetup &&
                      <li>
                        <span
                          className={
                            pathname === '/business-admin/pricing-tier' ? 'active' : ''
                          }
                          onClick={() =>
                            this.onClickTab('active', '/business-admin/pricing-tier')
                          }>
                          Pricing Setup
                        </span>
                      </li>
                    }
                    {permissions?.businessAdmin?.scaleSetup &&
                      <li>
                        <span
                          className={
                            pathname === '/business-admin/scales' ? 'active' : ''
                          }
                          onClick={() =>
                            this.onClickTab('active', '/business-admin/scales')
                          }>
                          Scale Setup
                        </span>
                      </li>
                    }
                    {permissions?.businessAdmin?.intergrations &&
                      <li>
                      <span
                        className={
                          pathname === '/business-admin/integrations' ? 'active' : ''
                        }
                        onClick={() =>
                          this.onClickTab('active', '/business-admin/integrations')
                        }>
                        Integrations
                      </span>
                    </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
            {this.state.url === '/business-admin/scales' && (
              <>
                <div className='row'>
                  <div className='col-md-12'>
                    <button
                      className='btn btn-dark btn-sm'
                      onClick={() => {
                        this.setState({ isAddNewScaleModelOpen: true });
                        this.props.history.push('/add-scale-setting');
                      }}>
                      Add New Scale
                    </button>
                  </div>
                </div>
                <br />

                {scales && scales.length > 0 ? (
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='table-responsive'>
                        <table className='table custom-table'>
                          <thead>
                            <tr>
                              <th>Scale Name</th>
                              <th>Scale IP</th>
                              <th>Scale Port</th>
                              <th>Scale Location</th>
                              {activeTab === 'active' && <th>&nbsp;</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {scales.length > 0 &&
                              scales.map((scale) => {
                                return (
                                  <tr
                                    className='cursor-pointer'
                                    key={scale._id}>
                                    <td onClick={() => {
                                      this.setState({
                                        isAddNewScaleModelOpen: true,
                                        scaleIdForModel: scale.id,
                                      });
                                      this.props.history.push(
                                        `/edit-scale-setting/${scale._id}`
                                      );
                                    }}>{scale.scaleName}</td>
                                    <td onClick={() => {
                                      this.setState({
                                        isAddNewScaleModelOpen: true,
                                        scaleIdForModel: scale.id,
                                      });
                                      this.props.history.push(
                                        `/edit-scale-setting/${scale._id}`
                                      );
                                    }}>{scale.scaleIp}</td>
                                    <td onClick={() => {
                                      this.setState({
                                        isAddNewScaleModelOpen: true,
                                        scaleIdForModel: scale.id,
                                      });
                                      this.props.history.push(
                                        `/edit-scale-setting/${scale._id}`
                                      );
                                    }}>{scale.scalePort}</td>
                                    <td onClick={() => {
                                      this.setState({
                                        isAddNewScaleModelOpen: true,
                                        scaleIdForModel: scale.id,
                                      });
                                      this.props.history.push(
                                        `/edit-scale-setting/${scale._id}`
                                      );
                                    }}>{scale.scaleLocation}</td>

                                    <td className='cursor-pointer underline blackText'>
                                      <button
                                        className='underline blackText TransparentBtn'
                                        onClick={() => {
                                          this.setState({
                                            isAddNewScaleModelOpen: true,
                                            scaleIdForModel: scale._id,
                                          });
                                          this.props.history.push(
                                            `/edit-scale-setting/${scale._id}`
                                          );
                                        }}>
                                        Edit
                                      </button>
                                    </td>
                                    <td className='cursor-pointer underline blackText'>
                                      <button
                                        className='underline blackText text-red TransparentBtn'
                                        onClick={() => {
                                          this.setState({
                                            isDeleteScaleModelOpen: true,
                                            scaleIdForModel: scale._id,
                                            scaleName: scale.scaleName,
                                          });
                                        }}
                                      >
                                        {/* <span style={{color: "red"}}>Delete</span>  */}
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <NotFound
                    title='No Scales Found'
                  />
                )}

              </>
            )}
            {this.state.url === '/business-admin/pricing-tier' && (
              <>
                <div>
                  <div className='row'>
                    <div className='col-md-12'>
                      {(permissions?.permissionOptions?.pricingTier) && <button
                        className='btn btn-dark btn-sm'
                        onClick={() => {
                          this.setState({ isCreateEditPricingModelOpen: true });
                          this.props.history.push('/add-pricing-tier');
                        }}>
                        Add New Pricing Tier
                      </button>}
                      <span className='ml-3'>
                        Show inactive pricing tiers
                        <Switch
                          className="ml-3 ant-switch-gray-bg"
                          checked={this.state.showActivePricingTiers}
                          onChange={() => this.setState({ showActivePricingTiers: !this.state.showActivePricingTiers })}
                        />
                      </span>
                      {/* <Link to="/pricing-tier" className="btn btn-dark btn-sm">
                      Add Pricing Tier
                    </Link> */}
                    </div>
                  </div>
                  <br />
                  {filteredPricingList && filteredPricingList.length !== 0 ? (
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='table-responsive'>
                          <table className='table custom-table pricing __tier-table'>
                            <thead>
                              <tr>
                                { filteredPricingList.length !== 0 && <th className='text-left'>Pricing Tier Name</th>}
                                {activeTab === 'active' && <th>&nbsp;</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filteredPricingList.length === 0 &&
                                <NotFound
                                  title={`No Pricing Tiers`}
                                />
                              }
                              {filteredPricingList.length > 0 &&
                                filteredPricingList.map((item) => {
                                    return (
                                    <tr className={(permissions?.permissionOptions?.pricingTier === true) ? 'cursor-pointer':""} key={item._id}>
                                      <td className='col-1' onClick={(permissions?.permissionOptions?.pricingTier === true) ?() =>  {
                                      this.setState({
                                        isCreateEditPricingModelOpen: true,
                                        // scaleIdForModel: scale.id,
                                      });
                                      this.props.history.push(
                                        `/edit-pricing-tier/${item._id}`
                                      );
                                  }:undefined}>{item.pricingTier}</td>
                                        <td  className='col-2 text-right'>{ <button
                                          className='underline blackText TransparentBtn text_font mr-5'
                                          onClick={() => {
                                            this.setState({
                                              isCustomerPricingModelOpen: true,
                                              // scaleIdForModel: scale.id,
                                            });
                                            this.props.history.push(
                                              `/customer-pricing-tier/${item._id}`
                                            );
                                          }}>
                                          View Customers
                                        </button>}
                                          {(permissions?.permissionOptions?.pricingTier) &&<button
                                            className='underline blackText TransparentBtn text_font mr-1'
                                            onClick={() => {
                                              this.setState({
                                                isCreateEditPricingModelOpen: true,
                                                // scaleIdForModel: scale.id,
                                              });
                                              this.props.history.push(
                                                `/edit-pricing-tier/${item._id}`
                                              );
                                            }}>
                                            Edit
                                          </button>}</td>
                                          <td className={item.isActive ? "text-green" : "text-red"}>
                                          { item.isActive ? `Active`: "Not Active" }
                                          <Switch
                                            className="ml-3"
                                            checked={item.isActive}
                                            onChange={() => this.onToggleChange(item)}
                                          />
                                        </td>
                                      </tr>
                                    );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NotFound
                      title='No Pricing tier'
                    // title={
                    //   activeTab === "active"
                    //     ? "No Active Outbound Activity"
                    //     : "No Completed Outbound Activity"
                    // }
                    />
                  )}
                </div>
              </>
            )}
            {this.state.url === '/business-admin/business-settings' && (
              <>
                <div className='settings__container'>
                  <div className='settings__container-wrapper1'>
                    <div className='card CardBusinessSettings'>
                      <div className='card-body'>
                        <div className='container'>
                          <div className='row edit__business-row'>
                            <div className='col-lg-6'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Business Name </h6>
                                    <p className='sub__title'>
                                      {
                                        businessSetting?.businessInfo
                                          ?.businessName
                                      }
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                           
                            <div className='col-lg-6 edit__business-btn'>
                              {/* <Link
                              className='btn btn-dark btn-sm'
                              to={'/business-settings'}>
                              Edit Business Settings
                            </Link> */}
                              {(permissions?.permissionOptions && permissions?.permissionOptions?.editBusinessSettings === true)? 
                              (<button
                                className='btn btn-dark btn-sm'
                                onClick={() => {
                                  this.setState({
                                    isBusinessSettingModelOpen: true,
                                  });
                                  this.props.history.push(
                                    '/business-admin/edit-business-settings'
                                  );
                                }}>
                                Edit Business Settings
                              </button>
                              ):null}
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Invoice Prefix</h6>
                                    <p className='sub__title'>
                                      {businessSetting?.invoicePrefix ? businessSetting?.invoicePrefix  : "N/A" }
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Company Address </h6>
                                    {businessSetting?.businessInfo?.address ||
                                      businessSetting?.businessInfo?.city ||
                                      businessSetting?.businessInfo?.state ||
                                      businessSetting?.businessInfo?.zip ? (
                                      <>
                                        <p className='sub__title'>
                                          {businessSetting?.businessInfo?.address}
                                        </p>
                                        <p className='sub__title'>
                                          {businessSetting?.businessInfo?.city +
                                            ', ' +
                                            businessSetting?.businessInfo?.state +
                                            ' ' +
                                            businessSetting?.businessInfo?.zip}
                                        </p>
                                      </>
                                    ) : (
                                      <p className='sub__title'>N/A</p>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Cities of Origin </h6>
                                    {businessSetting?.cities?.length > 0
                                      ? businessSetting?.cities.map((items) => {
                                        return (
                                          <p key={items} className='sub__title'>{items}</p>
                                        );
                                      })
                                      : 'None'}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-5'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Phone Number </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.businessInfo?.phone}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className='col-lg-7'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Fax Number</h6>
                                    <p className='sub__title'>
                                      {businessSetting?.businessInfo?.fax ??
                                        'None'}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Billing Email
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.billingEmail && 
                                      businessSetting?.billingEmail !== "" ? 
                                      businessSetting?.billingEmail 
                                      : "N/A"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      DSNY Transfer Station{' '}
                                    </h6>
                                    <p className='sub__title'>
                                      {
                                        businessSetting?.businessInfo
                                          ?.dsnyTransferStation
                                      }
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>NYCDEC Permit</h6>
                                    <p className='sub__title'>
                                      {
                                        businessSetting?.businessInfo
                                          ?.nycdecPermit
                                      }
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Auto logout duration Permit
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.autoLogoutDuration}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Order Prefix</h6>
                                    <p className='sub__title'>
                                      {businessSetting?.orderPrefix}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Timezone</h6>
                                    <p className='sub__title'>
                                      {businessSetting?.timezone}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Enable Microblink</h6>
                                    <p className='sub__title'>
                                      {businessSetting?.microblinkEnabled ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {
                            businessSetting?.isSignaturePadIntegration &&
                            <div className='row'>
                              <div className='col-lg-12'>
                                <ul className='card__list'>
                                  <li>
                                    <div className='company__driver-title'>
                                      <h6 className='title'>Signature Required</h6>
                                      <p className='sub__title'>
                                        {businessSetting?.signatureRequired ? "On" : "Off"}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          }

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Charge Handling Fee
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.chargeStripeCardFees ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                    Open PDF when an order completes
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.is_open_pdf_when_an_order_complete ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                    Display service address on orders
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.serviceAddress ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Required yardage for Outbound Activities
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.isYardageRequired ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Round up order totals for cash transactions
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.isRoundOffAmount ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                    Receive email notifications for payments on orders
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.emailForPaymentsOnOrders ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                    Sales Tax
                                    </h6>
                                    <p className='sub__title'>
                                      {
                                        businessSetting?.isSalesTax ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Environmental Load Fee
                                    </h6>
                                    <p className='sub__title'>
                                      {
                                        businessSetting?.isEnvironmentalLoadFee ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>
                                      Add Fuel Surcharge?
                                    </h6>
                                    <p className='sub__title'>
                                      {businessSetting?.isFuelSurchardeFee ? "On" : "Off"}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        

                          <div className='row'>
                            <div className='col-lg-12'>
                              <ul className='card__list'>
                                <li>
                                  <div className='company__driver-title'>
                                    <h6 className='title'>Logo</h6>
                                    {businessSetting?.logo ? (
                                      <div className='business-setting-logo'>
                                        <img src={businessSetting?.logo} alt='' />
                                        {/* <img src={businessSetting?.logo} alt='' /> */}
                                      </div>
                                    ) : (
                                      <p className='sub__title'>{'None'}</p>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </>
            )}
             {this.state.url === "/business-admin/integrations" && (
              <section className='integrations__section'>
                <div className="row">
                  <div className="col-lg-6">
                    <div className='card d-card cursor-pointer'>
                      <div className='d-flex'>
                        <div className='icon'>
                          <img src={quickbooksIcon} alt="" />
                        </div>
                        <div>
                          <h2 className='title'>Quickbooks</h2>
                          <p>Sync and important all you WeighWorks transactions
                            seamlessly to your Quickbooks account.</p>
                          <button onClick={() => this.props.history.push('/quickbooks')} 
                          className= { this.state.isQuickbooksConnected ? "btn btn-sm bg-yellow" : 'btn btn-dark btn-sm '} 
                          > { this.state.isQuickbooksConnected ? "Connected" :"Enable"} 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  className="col-lg-6">
                    <div className='card d-card cursor-pointer'>
                      <div className='d-flex'>
                        <div className='icon'>
                          <img src={payabliIcon} alt="" />
                        </div>
                        <div>
                          <h2 className='title'>Payabli</h2>
                          <p>Accept  customer payments through WeighWorks by Credit
                            Card and Point of Sale (Terminal).</p>
                          <button onClick={() => !this.state.paybliEnable ? this.setState({ isPaybliModel: true }) : ""} className={this.state.paybliEnable ? 'btn btn-sm bg-yellow' : 'btn btn-dark btn-sm'}>{this.state.paybliEnable ? "Connected" : "Enable"}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div  className="col-lg-6">
                    <div className='card d-card cursor-pointer'>
                      <div className='d-flex'>
                        <div className='icon'>
                          <img src={email_svg} alt="Email" />
                        </div>
                        <div>
                          <h2 className='title'>Setup Custom Domain to Send Emails</h2>
                          <p>{this.state?.is_custom_domain_email ? `You’re connected, emails are being sent out using: ${this.state?.is_custom_domain_email}` : 'Send your WeighWorks email using your domain.'}</p>
                          {this.state?.is_custom_domain_email ? 
                          <button onClick={()=> this.setState({is_custom_email_edit: true})} 
                          className="btn btn-sm bg-yellow">
                            Edit Email
                            </button>:<button onClick={()=> this.setState({is_custom_domain_modal_open: true})} 
                            className="btn btn-dark btn-sm">
                            Enable
                            </button> }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  className="col-lg-6">
                    <div className='card d-card cursor-pointer'>
                      <div className='d-flex'>
                        <div className='icon'>
                          <img src={signature_pad} alt="Signature Pad" />
                        </div>
                        <div>
                          <h2 className='title'>Signature Pad - Topaz (model: T-LBK460-HSX-R)</h2>
                          <p>Collect customer signatures directly through an integrated signature pad.</p>
                          <button
                            // onClick={()=> !businessSetting?.isSignaturePadIntegration ? this.setState({ openSignaturePadModal: true }) : this.updateSignaturePadSetting()} 
                            onClick={()=> this.setState({ openSignaturePadModal: true })} 
                            className={businessSetting?.isSignaturePadIntegration ? "btn btn-sm bg-yellow": "btn btn-dark btn-sm"}>
                            {businessSetting?.isSignaturePadIntegration ? "Disable" : "Enable"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
                {this.state.isBusinessSettingModelOpen && (
                  <SettingsContainer
                    isBusinessSettingModelOpen={this.state.isBusinessSettingModelOpen}
                    onCloseClick={() => this.closeBusinessModel()}
                  />
                )}
                {this.state.isAddNewScaleModelOpen && (
                  <CreateScaleContainer
                    isAddNewScaleModelOpen={this.state.isAddNewScaleModelOpen}
                    onCloseClick={() => this.closeAddNewScaleModel()}
                  />
                )}
                {console.log(this.state.scaleIdForModel)}
                {this.state.isDeleteScaleModelOpen && (
                  <DeleteScale
                    isDeleteScaleModelOpen={this.state.isDeleteScaleModelOpen}
                    DeleteScale={this.props.deleteScale}
                    ScaleId={this.state.scaleIdForModel}
                    scaleName={this.state.scaleName}
                    onCloseClick={() => this.closeDeleteScaleModel()}
                  />
                )}
                {this.state.isCreateEditPricingModelOpen && (
                  <CreateEditPricingContainer
                    isCreateEditPricingModelOpen={
                      this.state.isCreateEditPricingModelOpen
                    }
                    onCloseClick={() => this.closePricingModel()}
                  />
                )}
                {this.state.isCustomerPricingModelOpen && (
                  <CustomerPricing
                    isCustomerPricingModelOpen={
                      this.state.isCustomerPricingModelOpen
                    }
                    getCustomersPricing={this.props.getCustomerByPricing}
                    getPricingTierById={this.props.getPricingTierById}
                    onCloseClick={() => this.closeCustomerPricingModel()}
                  />
                )}
       </Spin>  
    
        </main>
        {
          this.state.isCustomersByPricingOpen &&
            <ReactModal
              isOpen={this.state.isCustomersByPricingOpen} 
            >
              <div className="react-modal-dialog react-modal-dialog-centered HeightAutoModal CstmModalWidth">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Pricing tier must be unassigned from the following customers<br/> before it can be deactivated:</h5>
                  <button
                    type="button"
                    className="btn react-modal-close"
                    onClick={this.closeModal}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className='react-modal-body'>
                  <div className='settings__container'>
                    <div className='card-body'>
                      <div className='customer__ricing-wrapper'>
                        <div className='custome__container'>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='table-responsive'>
                                { this.state.customersByPricing?.length > 0 ? (
                                  <table className='table custom-table drivers__trucks-table'>
                                    <thead>
                                      <th>Customer Name</th>
                                      <th>Address</th>
                                      <th>Phone Number</th>
                                      <th>Email</th>
                                      <th>Contact Name</th>
                                    </thead>
                                    <tbody>
                                      {this.state.customersByPricing.map((item) => (
                                        <tr key={item?.companyName}>
                                          <td>{item?.companyName}</td>
                                          <td>{item?.address}</td>
                                          <td>{item?.phone}</td>
                                          <td>{item?.email}</td>
                                          <td>{item?.displayName}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <NotFound
                                    title={`No Customers`}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="react-modal-footer text-center">
                    <button className='btn btn-dark w-100' onClick={this.closeModal}>Ok</button>
                  </div>
                </div>
              </div>
            </ReactModal>
          }
        <ReactModal
         isOpen={this.state.isPaybliModel} 
        >
          <div className="react-modal-dialog react-modal-dialog-centered payabli__integration-modal m-auto">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Payabli Integration</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <p>Want your customers to be able to pay your by credit card  directly in WeighWorks.</p>
              <p>Reach out to <a href='mailto:hello@weighworks.com'>hello@weighworks.com</a> for more information on how to setup this integration.</p>
            </div>
          </div>
        </ReactModal>
        <ReactModal
         isOpen={this.state?.is_custom_domain_modal_open} 
        >
          <div className="react-modal-dialog react-modal-dialog-centered payabli__integration-modal m-auto">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Send Email from your domain</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={()=> this.setState({is_custom_domain_modal_open: false})}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <p>Want your customers to receive emails from your domain.</p>
              <p>Reach out to <a href='mailto:hello@weighworks.com'>hello@weighworks.com</a> for more information on how to setup this integration.</p>
            </div>
          </div>
        </ReactModal>
        {this.state.is_custom_email_edit ? 
        <EditEmailPopup 
        is_custom_email_edit={this.state.is_custom_email_edit} 
        closeModal={()=> this.setState({is_custom_email_edit:false})}
        customEmail={this.state.is_custom_domain_email}
        updateCustomEmail={this.props.updateCustomEmail}
        companyId={this.state.companyIds}
        emailUpdate={(email)=> this.setState({is_custom_domain_email:email})}
        />
        :""}
        <SignaturePadModal 
          isOpen={openSignaturePadModal} 
          isSignaturePadIntegration={businessSetting?.isSignaturePadIntegration}
          closeModal={()=> this.setState({ openSignaturePadModal: false })}
          // signaturePadEnabled={this.state.is_signature_pad_enabled}
          fetchSetting={(companyId) => this.fetchSetting(companyId)}
          updateSignaturePadSetting={this.props.updateSignaturePadSetting}
          companyId={this.state.companyIds}
        />
        {/* {/* <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Record Outbound Activity</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={activeOutboundActivitySchema}
              onSubmit={(values, formikProps) =>
                this.handleSubmit(values, formikProps)
              }
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setValues,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Datetime
                            inputProps={{
                              name: "date",
                              required: true,
                              autoComplete: "off",
                              className:
                                "form-control material-textfield-input",
                            }}
                            onChange={(date) => setValues({ ...values, date })}
                            // isValidDate={this.valid}
                            value={values.date}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                            // Placeholder=""
                          />
                          <ErrorMessage
                            component="span"
                            name="date"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label label__for-date">
                            Date<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="manifestNumber"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values && values.manifestNumber}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="manifestNumber"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Manifest #
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="manifestNumberOther"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values && values.manifestNumberOther}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="manifestNumberOther"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Manifest # (other)
                          </label>
                        </div>
                      </div>
                    </div>
                    <FieldArray
                      name="materials"
                      render={(arrayHelpers) => (
                        <div>
                          {values.materials.map((material, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Material ${
                                      index + 1
                                    }`}</h3>
                                    {values.materials.length !== 1 && (
                                      <button
                                        className="material__label-remove"
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name={`materials[${index}].material`}
                                      value={material.material}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option>Select Material</option>
                                      <option value="appliances">
                                        Appliances
                                      </option>
                                      <option value="concrete">Concrete</option>
                                      <option value="brick">Brick</option>
                                      <option value="metal">Metal</option>
                                      <option value="wood">Wood</option>
                                      <option value="cardboard">
                                        Cardboard
                                      </option>
                                      <option value="asphalt">Asphalt</option>
                                      <option value="dirt/sand">
                                        Dirt/Sand
                                      </option>
                                      <option value="furniture">
                                        Furniture
                                      </option>
                                      <option value="glass">Glass</option>
                                      <option value="mixed c&d">
                                        Mixed C&D
                                      </option>
                                      <option value="mixed putrescible">
                                        Mixed Putrescible
                                      </option>
                                      <option value="plastic">Plastic</option>
                                      <option value="roofing">Roofing</option>
                                      <option value="screens">Screens</option>
                                      <option value="sheetrock">
                                        Sheetrock
                                      </option>
                                      <option value="stone">Stone</option>
                                      <option value="tile">
                                        Fill Material
                                      </option>
                                      <option value="residual">Residual</option>
                                      <option value="other">Other</option>
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].material`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Material<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`materials[${index}].grossWeight`}
                                      value={material.grossWeight}
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      onValueChange={(values) => {
                                        const { value } = values;
                                        setFieldValue(
                                          `materials[${index}].grossWeight`,
                                          value
                                        );
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Gross Weight at Facility (lbs)
                                      <span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="number"
                                      name={`materials[${index}].yardage`}
                                      value={material.yardage}
                                      className="form-control material-textfield-input"
                                      onChange={handleChange}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].yardage`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Yardage<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                {values.materials.length - 1 === index && (
                                  <div className="col-md-12">
                                    <div className="material__label-section add__another">
                                      <h3
                                        className="material__label-title underline"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            material: "",
                                            grossWeight: "",
                                            yardage: "",
                                          })
                                        }
                                      >
                                        Add Another Material
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="facilityId"
                        onChange={handleChange}
                        value={values && values.facilityId}
                        required
                      >
                        <option>Select Facility</option>
                        {facilities.map((facility) => {
                          return (
                            <option value={facility._id} key={facility._id}>
                              {facility.companyName}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        component="span"
                        name="facilityId"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        Facility<span>*</span>
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="haulerId"
                        onChange={handleChange}
                        value={values && values.haulerId}
                        required
                      >
                        <option>Select Hauler</option>
                        {inHouseHaulers.map((inHouseHauler) => {
                          return (
                            <option
                              value={inHouseHauler._id}
                              key={inHouseHauler._id}
                            >
                              {inHouseHauler.companyName} (In House)
                            </option>
                          );
                        })}
                        {thirdPartyHaulers.map((thirdPartyHauler) => {
                          return (
                            <option
                              value={thirdPartyHauler._id}
                              key={thirdPartyHauler._id}
                            >
                              {thirdPartyHauler.companyName} (Third Party)
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage
                        component="span"
                        name="haulerId"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        Hauler<span>*</span>
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="truckType"
                        onChange={handleChange}
                        value={values && values.truckType}
                        required
                      >
                        <option>Select Truck Type</option>
                        <option value="Car">Car</option>
                        <option value="Van">Van</option>
                        <option value="Roll Off">Roll Off</option>
                        <option value="Box Truck">Box Truck</option>
                        <option value="Pick Up">Pick Up</option>
                        <option value="Dump Truck">Dump Truck</option>
                        <option value="Dump Trailer">Dump Trailer</option>
                        <option value="Garbage Truck">Garbage Truck</option>
                      </select>
                      <ErrorMessage
                        component="span"
                        name="truckType"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        Type of Truck<span>*</span>
                      </label>
                    </div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-dark btn-lg w-100"
                    >
                      {isSubmitting ? "Please wait..." : "Save Outbound Record"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal> */}
        {/* <CompleteOutBoundActivity
          activity={this.state.activity}
          editModal={this.state.completeModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={true}
        /> */}
        {/* <EditOutBoundActivity
          activity={this.state.activity}
          editModal={this.state.editModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={true}
        /> */}
        {/* <ShowOutBoundActivity
          activity={this.state.activity}
          showModal={this.state.showModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={false}
        /> */}
        {/* <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format(
                  "MMM DD, YYYY"
                )} - ${moment(this.state.endDate).format("MMM DD, YYYY")}`,
                currentPage: 1,
                skip: 0,
              },
              () => {
                this.closeModal();
                this.fetchOutboundActivities();
              }
            );
          }}
          handleFromChange={(date) =>
            this.setState({ startDate: moment(date).format("MMM DD, YYYY") })
          }
          handleToChange={(date) =>
            this.setState({ endDate: moment(date).format("MMM DD, YYYY") })
          }
          from={this.state.startDate}
          to={this.state.endDate}
        /> */}
        {/* <Pagination
          className="text-center"
          onChange={this.onChangePage}
          hideOnSinglePage
          current={this.state.currentPage}
          total={this.state.totalItems}
          pageSize={this.state.limit}
          showSizeChanger={false}
        /> */}
        {/* <DeleteOrderModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => this.setState({ isDeleteModal: false })}
          deleteOrder={this.deleteOutboundActivity}
          progressBar={progressBar}
        /> */}
      </div>
    );
  }
}
