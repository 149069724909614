import React, { useEffect, useState } from "react"
import ReactModal from 'react-modal';
import { CloseIcon } from "../../../components/icons";
import { loadPayengine } from "payengine";

const PECardModal = ({ isCardModalOpen, closeModal, addCard, fetchOrder, customerId, fetchCustomerDetail ,serviceLocation , customerRefrence}) => {
  const [secureForm, setSecureForm] = useState(null)
  const [ isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [resultMessage, setResultMessage] = useState("")
  
  useEffect(() => {
    loadPayengine({
      publicKey: process.env.REACT_APP_PAY_ENGINE_PUBLIC_KEY
    }).then((pe) => {
      createSecureFields(pe);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const css = {};
  
  function createSecureFields(pe) {
    console.log("loading SecureFields Obj");
    pe.SecureFields.create().then((form) => {
      form.field("#cc-name", {
        type: "text",
        name: "card_holder",
        placeholder: "Card holder",
        validations: ["required"],
        css,
      });
      
      form.field("#cc-number", {
        type: "card-number",
        name: "card_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Card number",
        showCardIcon: false,
        validations: ["required", "validCardNumber"],
        css,
      });
      form.field("#cc-cvc", {
        type: "card-security-code",
        name: "card_cvc",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "CVC",
        maxLength: 4,
        validations: ["required", "validCardSecurityCode"],
        css,
      });
      form.field("#cc-expiration-date", {
        type: "card-expiration-date",
        name: "card_exp",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "MM / YY",
        validations: ["required", "validCardExpirationDate"],
        css,
      });
      
      setSecureForm(form)
    })
  }
  
  async function addCardToPE(e) {
    e.preventDefault();
    try {
      setIsBtnDisabled(true);
      const cardObj = await secureForm.createCard();
      const {
        value: { status, message }
      } = await addCard({ card: cardObj, customerId });
      if (status) {
        fetchCustomerDetail()
        closeModal();
        fetchOrder(serviceLocation , customerRefrence);
      }
      setResultMessage(message);
    } catch (e) {
      setIsBtnDisabled(false);
      setResultMessage(JSON.stringify(JSON.stringify(e)));
    }
  }
  
  return (
    <ReactModal isOpen={isCardModalOpen} onRequestClose={closeModal} contentLabel="Please Confirm" ariaHideApp={false}>
      <div className="react-modal-dialog react-modal-dialog-centered add__card-modal">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Add Card</h5>
          <button type="button" className="btn react-modal-close" onClick={closeModal}>
            <CloseIcon />
          </button>
        </div>
        <div className="react-modal-body">
          <form id="card-form">
            <div className="row">
              <div className="col-md-12">
                <div className="form-field payengine-field" id="cc-name"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-field payengine-field" id="cc-number"></div>
                <div className="form-field payengine-field" id="cc-expiration-date"></div>
                <div className="form-field payengine-field" id="cc-cvc"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <button onClick={addCardToPE} disabled={isBtnDisabled} className="btn btn-dark btn-lg w-100 font-600">{isBtnDisabled ? "Please wait..." : "Save"}</button>
              </div>
              <div className="col-md-12">
                  <pre id="result">{resultMessage}</pre>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ReactModal>
  )
}
  
export default PECardModal;
