import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { DownarrowIcon, StateUpArrowIcon } from "../../../components/icons";
import _ from "lodash";
import NotFound from "../../NoRecords/component";

export default class DriverSignature extends Component {
  navigate = async (orderId, currentPage) => {
    this.props.history.push({ pathname: `/order-details/${orderId}` });
    await sessionStorage.setItem("current-page-complete-orders", currentPage)
	};
  truncateText(text, maxLength) {
    if (!text || text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }

  render() {
    const { orders, sortOrder, setOrder, currentPage } = this.props;
    return (
      <>
        {orders.length === 0 ? (
          <NotFound title="No Orders Found" />
        ) : (
          <div className="table-responsive">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th onClick={() => setOrder("ticketNumber")}>
                    Ticket # {sortOrder["ticketNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("customer.companyName")}>
                    Company Name {sortOrder["customer.companyName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("customerRefrence")}>
                    Cust Ref # {sortOrder["customerRefrence"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("inboundWeight")}>
                    Inbound {sortOrder["inboundWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("outboundWeight")}>
                    Outbound {sortOrder["outboundWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("netWeight")}>
                    Net Weight {sortOrder["netWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("totalAmount")}>
                    Total Order Amount {sortOrder["totalAmount"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => setOrder("paymentType")}>
                    Payment Type {sortOrder["paymentType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => {
                  let paymentType =
                    order.paymentType && order.paymentType !== "" ? `- ${_.startCase(order.paymentType)}` : "";
                  let btnClass = "btn btn-for-account";
                  if (paymentType === "") {
                    paymentType = "ACCOUNT";
                  } else {
                    paymentType = `COD ${paymentType}`;
                  }
                  if (paymentType === "COD - Credit Card") {
                    btnClass = "btn btn-for-cc";
                  } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
                    btnClass = "btn btn-for-cash";
                  } else if (paymentType === "COD - Terminal") {
                    btnClass = "btn btn-for-terminal";
                  }
                  return  (
                    <tr className="cursor-pointer" key={order._id} onClick={() => this.navigate(order.ticketNumber,currentPage)}>
                      <td>{order.ticketNumber}</td>
                      <td>{order.customer?.displayName}</td>
                      <td><div>{this.truncateText(order.customerRefrence, 20) || '-'}</div></td> 
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.inboundWeight} /> lbs
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.outboundWeight} /> lbs
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} /> lbs
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.totalAmount}
                        />
                      </td>
                      <td>
                        <button className={btnClass}>{paymentType}</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}
