import React, { useState, useEffect } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { BackArrowIcon } from "../../../components/icons";
import InputMask from "react-input-mask";
import { ErrorMessage } from "formik";
import ReactImageCrop from "./react-crop";
import "react-image-crop/lib/ReactCrop.scss";
import CropIcon from "../../../images/crop_icon.svg";
import RotateIcon from "../../../images/rotate_icon.svg";
import Edit from "../../../images/edit.svg";

const CreateDriver = ({
  values,
  handleChange,
  isSubmitting,
  onTakePhoto,
  isLicenseCamera,
  licenseImageUrl,
  onClose,
  rotation,
  setRotation,
  changeLicenseCameraState,
  isForEdit,
  onCrop,
  fromPopUp
}) => {
  const [isCrop, setIsCrop] = useState(false);
  const [imageToCrop, setImageToCrop] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (licenseImageUrl && count === 0) {
      setImageToCrop(licenseImageUrl);
      setCount(count + 1);
    }
  }, [licenseImageUrl, count]);

  return (
    <main className="create__driver-wrapper">
      <div className="header__container m-10">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {!fromPopUp && (
              <div className="back__button">
                <button className="btn btn-link btn-back" onClick={onClose}>
                  <BackArrowIcon /> Back
                </button>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="page_container">
        <div className="create__driver-inner-scroll">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    className="form-control material-textfield-input"
                    name="firstName"
                    required
                  />
                  <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" />
                  <label className="material-textfield-label">
                    First Name<span>*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    className="form-control material-textfield-input"
                    name="lastName"
                    required
                  />
                  <ErrorMessage component="span" name="lastName" className="invalid-feedback d-block" />
                  <label className="material-textfield-label">
                    Last Name<span>*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    value={values.licenseNumber}
                    onChange={handleChange}
                    className="form-control material-textfield-input"
                    name="licenseNumber"
                    required
                  />
                  <ErrorMessage component="span" name="licenseNumber" className="invalid-feedback d-block" />
                  <label className="material-textfield-label">License Number </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group material-textfield">
                  <InputMask
                    mask="(999) 999-9999"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                    className="form-control material-textfield-input"
                    required
                  />
                  <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                  <label className="material-textfield-label">Phone Number (optional)</label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group material-textfield">
                  <select
                    value={values.state}
                    className="form-control custom-select material-textfield-input"
                    name="state"
                    onChange={handleChange}
                  >
                    <option value="">Select State</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                  </select>
                  <label className="material-textfield-label">
                  State<span>*</span>
                  </label>
                  <ErrorMessage component="span" className="invalid-feedback d-block" name="state" />
                  {/* <label className="material-textfield-label">
                    State<span>*</span>
                  </label> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group material-textfield">
                  <h3 className="license__image-title">License Image</h3>
                  <div className="crop__rotate-wrapper">
                    {!isLicenseCamera ? (
                      <div className="truck__img crop__rotate-section">
                        {licenseImageUrl !== "" && !isForEdit ? (
                          <>
                            {isCrop ? (
                              <ReactImageCrop
                              licenseImageUrl={imageToCrop}
                                setIsCrop={blob => {
                                  onCrop(blob);
                                  setIsCrop(false);
                                }}
                              />
                            ) : (
                              <img style={{ transform: `rotate(${rotation}deg)` }} src={licenseImageUrl} alt="" />
                            )}
                            <div className="crop__rotate-icon">
                              {!isCrop && (
                                <span className="crop__icon" title="Crop" onClick={() => {
                                  setImageToCrop(licenseImageUrl)
                                  setIsCrop(true)
                                  }}>
                                  <img className="img-fluid" src={CropIcon} alt="" />
                                </span>
                              )}
                              {!isCrop && (
                                <span className="rotate__icon" title="Rotate" onClick={setRotation}>
                                  <img className="img-fluid" src={RotateIcon} alt="" />
                                </span>
                              )}
                            </div>
                          </>
                        ) : licenseImageUrl !== "" && isForEdit ? (
                          <>
                          {isCrop ? (
                              <ReactImageCrop
                              licenseImageUrl={imageToCrop}
                                setIsCrop={blob => {
                                  onCrop(blob);
                                  setIsCrop(false);
                                }}
                              />
                            ) : (
                              <img style={{ transform: `rotate(${rotation}deg)` }} src={licenseImageUrl} alt="" />
                              )}
                              <div className="crop__rotate-icon crop-rotate-edit-icon">
                              {!isCrop && (
                                <span className="crop__icon edit__icon" title="edit" onClick={() => changeLicenseCameraState()}>
                                  <img className="img-fluid" src={Edit} alt="" />
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <div className="truck__img">
                        <Camera
                          onTakePhoto={dataUri => onTakePhoto(dataUri,"licenseImageUrl","isLicenseCamera")}
                          imageType={IMAGE_TYPES.PNG}
                          idealFacingMode={FACING_MODES.ENVIRONMENT}
                          isMaxResolution={true}
                          isImageMirror={false}
                          isSilentMode={false}
                          isDisplayStartCameraError={true}
                          isFullscreen={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <button
                 className="btn btn-dark btn-lg btn-block w-100 btn-done"
                 type="submit"
                 disabled={isSubmitting}
                 >
                  {isSubmitting ? "Please wait..." : "Done"}
                </button>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateDriver;
