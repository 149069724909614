import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken } from '../../store/user/duck'
import { fetchTransaction } from "../../store/transaction/duck";
import CreditCardItemizedComponent from './component';
import {getInvoiceDetailsById,getInvoiceDetailsByPrId} from '../../store/Billing/duck'

const CreditCardItemizedContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateUserToken,
    fetchMe,
    fetchTransaction,
    getInvoiceDetailsById,
    getInvoiceDetailsByPrId
  }
)(CreditCardItemizedComponent);

export default CreditCardItemizedContainer
