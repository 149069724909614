import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const getPricingTier = (data) => {
    return fetch(`${HOSTNAME}/pricing-tier?isFilteredData=${data?.isFilteredData ? data?.isFilteredData : false}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };

  export const getPricingTierById = (data) => {
    return fetch(`${HOSTNAME}/get_pricing-tier/${data}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };

  export const getPricingTierDetails = (data) => {
    return fetch(`${HOSTNAME}/pricing-tier/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };

  export const createPricingTier = (data) => {
    return fetch(`${HOSTNAME}/add-pricing-tier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
  export const editPricingTier = (data) => {
    return fetch(`${HOSTNAME}/edit-pricing-tier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
  export const getCustomerByPricing = (data) => {
    return fetch(`${HOSTNAME}/customer_by_pricing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
  export const addFixedItems = (data) => {
    return fetch(`${HOSTNAME}/custom_fixed_Items`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
  export const getFixedItems = (data) => {
    return fetch(`${HOSTNAME}/get_fixed_items/${data.companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((payload) => {
        return payload;
      })
      .catch((error) => {
        throw error;
      });
  };
