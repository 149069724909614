import React, { PureComponent } from "react";
import { Table } from "antd";
import moment from "moment";
// import _ from "lodash";
import { numberFormatLbs, numberFormat } from "../../../constants/common";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  {
    title: "Date",
    width: 5,
    dataIndex: "createdAt",
    render: createdAt => (moment(createdAt).isValid() ? moment(createdAt).format("M-D-YYYY") : "Summary"),
    key: "createdAt",
    fixed: "left",
    align: "center",
    children: [
      {
        title: "Summary",
        dataIndex: "createdAt",
        key: "createdAt",
        fixed: "left",
        align: "center",
        render: createdAt => (moment(createdAt).isValid() ? moment(createdAt).format("M-D-YYYY") : "Summary")
      }
    ]
  },
  {
    title: "Customer",
    width: 20,
    dataIndex: "customerName",
    key: "customerName",
    align: "center",
    children: [
      {
        title: "",
        dataIndex: "customerName",
        key: "customerName",
        align: "center"
      }
    ]
  },
  {
    title: "Location",
    dataIndex: "address",
    key: "address",
    align: "center",
    width: 500,
    children: [
      {
        title: "",
        width: 500,
        dataIndex: "address",
        align: "center",
        key: "address"
      }
    ]
  },
  {
    title: "Waste (tons)",
    width: 5,
    dataIndex: "waste",
    key: "waste",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "waste",
        key: "waste",
        align: "center",
        render: totalWaste => numberFormatLbs(totalWaste)
      }
    ]
  },
  {
    title: "Brick",
    width: 5,
    dataIndex: "brick",
    key: "brick",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "brick",
        key: "brick",
        align: "center",
        render: totalBricks => numberFormatLbs(totalBricks)
      }
    ]
  },
  {
    title: "Dirt",
    width: 5,
    dataIndex: "dirt",
    key: "dirt",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "dirt",
        key: "dirt",
        align: "center",
        render: totalDirt => numberFormatLbs(totalDirt)
      }
    ]
  },
  {
    title: "Concrete",
    width: 5,
    dataIndex: "concrete",
    key: "concrete",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "concrete",
        key: "concrete",
        align: "center",
        render: totalConcrete => numberFormatLbs(totalConcrete)
      }
    ]
  },
  {
    title: "Wood",
    width: 5,
    dataIndex: "wood",
    key: "wood",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "wood",
        key: "wood",
        align: "center",
        render: totalWood => numberFormatLbs(totalWood)
      }
    ]
  },
  {
    title: "Metal",
    width: 5,
    dataIndex: "metal",
    key: "metal",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "metal",
        key: "metal",
        align: "center",
        render: totalMetal => numberFormatLbs(totalMetal)
      }
    ]
  },
  {
    title: "Paper/CB",
    width: 5,
    dataIndex: "paper_cardboard",
    key: "paper_cardboard",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "paper_cardboard",
        key: "paper_cardboard",
        align: "center",
        render: totalPaperCb => numberFormatLbs(totalPaperCb)
      }
    ]
  },
  {
    title: "Plastic",
    width: 5,
    dataIndex: "plastic",
    key: "plastic",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "plastic",
        key: "plastic",
        align: "center",
        render: totalPlastic => numberFormatLbs(totalPlastic)
      }
    ]
  },
  {
    title: "Drywall",
    width: 5,
    dataIndex: "drywall",
    key: "drywall",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "drywall",
        key: "drywall",
        align: "center",
        render: totalDrywall => numberFormatLbs(totalDrywall)
      }
    ]
  },
  {
    title: "Glass",
    width: 5,
    dataIndex: "glass",
    key: "glass",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "glass",
        key: "glass",
        align: "center",
        render: totalGlass => numberFormatLbs(totalGlass)
      }
    ]
  },
  {
    title: "Asphalt",
    width: 5,
    dataIndex: "asphalt",
    key: "asphalt",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "asphalt",
        key: "asphalt",
        align: "center",
        render: totalAsphalt => numberFormatLbs(totalAsphalt)
      }
    ]
  },
  {
    title: "Total Tons",
    width: 5,
    dataIndex: "totalTons",
    key: "totalTons",
    align: "center",
    children: [
      {
        title: numberFormatLbs(0),
        dataIndex: "totalTons",
        key: "totalTons",
        align: "center",
        render: totalTons => numberFormatLbs(totalTons)
      }
    ]
  },
  {
    title: "Total Revenue",
    width: 5,
    dataIndex: "totalAmount",
    key: "totalAmount",
    align: "center",
    render: totalAmount => `$${numberFormat(totalAmount)}`,
    children: [
      {
        title: `$${numberFormat(0)}`,
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "center",
        render: totalAmount => `$${numberFormat(totalAmount)}`
      }
    ]
  }
];

export default class JobReportComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchText: "",
      typingTimeout: 0,
      columns: columns,
      headerData: []
    };
  }

  async componentDidMount() {
    const { startDate, endDate, filterCustomers, filterLocations } = this.props;
    const { searchText } = this.state;
    const {
      value: { data }
    } = await this.props.getReport({
      url: "/client-report",
      startDate,
      endDate,
      searchText,
      filterCustomers,
      filterLocations
    });
    this.setState({ data }, () => {
      this.setColumns();
    });
  }

  setColumns() {
    const { data } = this.state;
    let totalWaste = 0;
    let totalBricks = 0;
    let totalDirt = 0;
    let totalConcrete = 0;
    let totalWood = 0;
    let totalMetal = 0;
    let totalPaperCb = 0;
    let totalPlastic = 0;
    let totalDrywall = 0;
    let totalGlass = 0;
    let totalAsphalt = 0;
    let totalRevenue = 0;
    let totalTons = 0;
    let salesTaxFeeAmount=0
    data.map(d => {
      totalWaste += d.waste;
      totalBricks += d.brick;
      totalDirt += d.dirt;
      totalConcrete += d.concrete;
      totalWood += d.wood;
      totalMetal += d.metal;
      totalPaperCb += d.paper_cardboard;
      totalPlastic += d.plastic;
      totalDrywall += d.drywall;
      totalGlass += d.glass;
      totalAsphalt += d.asphalt;
      totalTons += d.totalTons;
      totalRevenue += d.totalAmount;
      salesTaxFeeAmount +=d.salesTaxFeeAmount
      return null;
    });
    const headerData = [
      {
        createdAt: "Summary",
        customerName: "",
        address: "",
        waste: totalWaste,
        brick: totalBricks,
        dirt: totalDirt,
        concrete: totalConcrete,
        wood: totalWood,
        metal: totalMetal,
        paper_cardboard: totalPaperCb,
        plastic: totalPlastic,
        drywall: totalDrywall,
        glass: totalGlass,
        asphalt: totalAsphalt,
        totalTons: totalTons,
        totalAmount: totalRevenue
      }
    ];
    const columns = [
      {
        title: "Date",
        width: 5,
        dataIndex: "createdAt",
        render: createdAt => (moment(createdAt).isValid() ? moment(createdAt).format("M-D-YYYY") : "Summary"),
        key: "createdAt",
        fixed: "left",
        align: "center",
        children: [
          {
            title: "Summary",
            dataIndex: "createdAt",
            key: "createdAt",
            fixed: "left",
            align: "center",
            render: createdAt => (moment(createdAt).isValid() ? moment(createdAt).format("M-D-YYYY") : "Summary")
          }
        ]
      },
      {
        title: "Customer",
        width: 20,
        dataIndex: "customerName",
        key: "customerName",
        align: "center",
        children: [
          {
            title: "",
            dataIndex: "customerName",
            key: "customerName",
            align: "center"
          }
        ]
      },
      {
        title: "Location",
        width: 500,
        dataIndex: "address",
        key: "address",
        align: "center",
        children: [
          {
            title: "",
            width: 500,
            dataIndex: "address",
            align: "center",
            key: "address"
          }
        ]
      },
      {
        title: "Ticket #",
        width: 5,
        dataIndex: "ticketNumber",
        key: "ticketNumber",
        align: "center",
        children: [
          {
            title: "",
            dataIndex: "ticketNumber",
            key: "ticketNumber",
            align: "center"
          }
        ]
      },
      {
        title: "Truck",
        width: 5,
        dataIndex: "truckNumber",
        key: "truckNumber",
        align: "center",
        children: [
          {
            title: "",
            dataIndex: "truckNumber",
            key: "truckNumber",
            align: "center"
          }
        ]
      },
      {
        title: "Truck License Plate",
        width: 180,
        dataIndex: "plateNumber",
        key: "plateNumber",
        align: "center",
        children: [
          {
            title: "",
            dataIndex: "plateNumber",
            key: "plateNumber",
            align: "center",
            width: 180,
          }
        ]
      },
      {
        title: "Waste (tons)",
        width: 5,
        dataIndex: "waste",
        key: "waste",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalWaste),
            dataIndex: "waste",
            key: "waste",
            align: "center",
            render: totalWaste => numberFormatLbs(totalWaste)
          }
        ]
      },
      {
        title: "Brick",
        width: 5,
        dataIndex: "brick",
        key: "brick",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalBricks),
            dataIndex: "brick",
            key: "brick",
            align: "center",
            render: totalBricks => numberFormatLbs(totalBricks)
          }
        ]
      },
      {
        title: "Dirt",
        width: 5,
        dataIndex: "dirt",
        key: "dirt",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalDirt),
            dataIndex: "dirt",
            key: "dirt",
            align: "center",
            render: totalDirt => numberFormatLbs(totalDirt)
          }
        ]
      },
      {
        title: "Concrete",
        width: 5,
        dataIndex: "concrete",
        key: "concrete",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalConcrete),
            dataIndex: "concrete",
            key: "concrete",
            align: "center",
            render: totalConcrete => numberFormatLbs(totalConcrete)
          }
        ]
      },
      {
        title: "Wood",
        width: 5,
        dataIndex: "wood",
        key: "wood",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalWood),
            dataIndex: "wood",
            key: "wood",
            align: "center",
            render: totalWood => numberFormatLbs(totalWood)
          }
        ]
      },
      {
        title: "Metal",
        width: 5,
        dataIndex: "metal",
        key: "metal",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalMetal),
            dataIndex: "metal",
            key: "metal",
            align: "center",
            render: totalMetal => numberFormatLbs(totalMetal)
          }
        ]
      },
      {
        title: "Paper/CB",
        width: 5,
        dataIndex: "paper_cardboard",
        key: "paper_cardboard",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalPaperCb),
            dataIndex: "paper_cardboard",
            key: "paper_cardboard",
            align: "center",
            render: totalPaperCb => numberFormatLbs(totalPaperCb)
          }
        ]
      },
      {
        title: "Plastic",
        width: 5,
        dataIndex: "plastic",
        key: "plastic",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalPlastic),
            dataIndex: "plastic",
            key: "plastic",
            align: "center",
            render: totalPlastic => numberFormatLbs(totalPlastic)
          }
        ]
      },
      {
        title: "Drywall",
        width: 5,
        dataIndex: "drywall",
        key: "drywall",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalDrywall),
            dataIndex: "drywall",
            key: "drywall",
            align: "center",
            render: totalDrywall => numberFormatLbs(totalDrywall)
          }
        ]
      },
      {
        title: "Glass",
        width: 5,
        dataIndex: "glass",
        key: "glass",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalGlass),
            dataIndex: "glass",
            key: "glass",
            align: "center",
            render: totalGlass => numberFormatLbs(totalGlass)
          }
        ]
      },
      {
        title: "Asphalt",
        width: 5,
        dataIndex: "asphalt",
        key: "asphalt",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalAsphalt),
            dataIndex: "asphalt",
            key: "asphalt",
            align: "center",
            render: totalAsphalt => numberFormatLbs(totalAsphalt)
          }
        ]
      },
      {
        title: "Total Tons",
        width: 5,
        dataIndex: "totalTons",
        key: "totalTons",
        align: "center",
        children: [
          {
            title: numberFormatLbs(totalTons),
            dataIndex: "totalTons",
            key: "totalTons",
            align: "center",
            render: totalTons => numberFormatLbs(totalTons)
          }
        ]
      },
      {
        title: "Sales Tax",
        width: 5,
        dataIndex: "salesTaxFeeAmount",
        key: "salesTaxFeeAmount",
        align: "center",
        children: [
          {
            title: numberFormatLbs(salesTaxFeeAmount),
            dataIndex: "salesTaxFeeAmount",
            key: "salesTaxFeeAmount",
            align: "center",
            render: salesTaxFeeAmount => numberFormatLbs(salesTaxFeeAmount)
          }
        ]
      },
      {
        title: "Total Revenue",
        width: 5,
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "center",
        render: totalAmount => `$${numberFormat(totalAmount)}`,
        children: [
          {
            title: `$${numberFormat(totalRevenue)}`,
            dataIndex: "totalAmount",
            key: "totalAmount",
            align: "center",
            render: totalAmount => `$${numberFormat(totalAmount)}`
          }
        ]
      }
    ];
    this.setState({ columns, headerData });
  }

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.componentDidMount();
      }, 1000)
    });
  };

  render() {
    const { data, searchText, columns, headerData } = this.state;
    const { isExportData, pageSize } = this.props;
    return (
      <>
        <div className="work__repot-body">
          <input
            className="form-control mb-3"
            placeholder="Search by location address or company name..."
            value={searchText}
            onChange={this.handleSearch}
          />
          <div className="card">
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ pageSize, hideOnSinglePage: true }}
              scroll={{ x: 2700, y: 300 }}
            />
          </div>
        </div>
        {isExportData && data.length !== 0 && (
          <ExcelFile hideElement filename="Client & Job Report">
            <ExcelSheet data={[...headerData, ...data]} name="Orders">
              <ExcelColumn
                label="Date"
                value={col => (moment(col.createdAt).isValid() ? moment(col.createdAt).format("M-D-YYYY") : "Summary")}
              />
              <ExcelColumn label="Customer" value="customerName" />
              <ExcelColumn label="Location" value="address" />
              <ExcelColumn label="Ticket #" value="ticketNumber" />
              <ExcelColumn label="Truck" value="truckNumber" />
              <ExcelColumn label="Truck License Plate" value="plateNumber" />
              <ExcelColumn label="Waste (tons)" value={col => numberFormatLbs(col.waste)} />
              <ExcelColumn label="Brick" value={col => numberFormatLbs(col.brick)} />
              <ExcelColumn label="Dirt" value={col => numberFormatLbs(col.dirt)} />
              <ExcelColumn label="Concrete" value={col => numberFormatLbs(col.concrete)} />
              <ExcelColumn label="Wood" value={col => numberFormatLbs(col.wood)} />
              <ExcelColumn label="Metal" value={col => numberFormatLbs(col.metal)} />
              <ExcelColumn label="Paper/CB" value={col => numberFormatLbs(col.paper_cardboard)} />
              <ExcelColumn label="Plastic" value={col => numberFormatLbs(col.plastic)} />
              <ExcelColumn label="Drywall" value={col => numberFormatLbs(col.drywall)} />
              <ExcelColumn label="Glass" value={col => numberFormatLbs(col.glass)} />
              <ExcelColumn label="Asphalt" value={col => numberFormatLbs(col.asphalt)} />
              <ExcelColumn label="Total Tons" value={col => numberFormatLbs(col.totalTons)} />
              <ExcelColumn label="Total Revenue" value={col => `$${numberFormat(col.totalAmount)}`} />
            </ExcelSheet>
          </ExcelFile>
        )}
      </>
    );
  }
}
