import { connect } from "react-redux";
import { getMe } from "../../store/user/duck";
import { updateOrder } from "../../store/order/duck";
import Terminal from "./component";

const TerminalContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateOrder
  }
)(Terminal);

export default TerminalContainer;
