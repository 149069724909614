import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const getReport = ({ url, startDate, endDate, searchText, filterCustomers, filterLocations, timezone,InboundMaterial,OutboundMaterial,OutboundSaleMaterial }) => {
  return fetch(
    `${HOSTNAME}${url}?searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterCustomers=${JSON.stringify(
      filterCustomers
    )}&filterLocations=${JSON.stringify(filterLocations)}&timezone=${timezone}&InboundMaterial=${InboundMaterial}&OutboundMaterial=${OutboundMaterial}&OutboundSaleMaterial=${OutboundSaleMaterial}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const getInboundOutboundMaterials = ({ startDate, endDate}) => {
  return fetch(
    `${HOSTNAME}/get-inbound-outbound-materials`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
