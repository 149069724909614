import React from 'react';
import { Switch } from 'antd';

const DumpRateSalesTaxSwitch = ({ value, onChange ,isSalesTax}) => {
  return (
    isSalesTax ? 
    <div className='form-group'>
      <label className='display-team-text'>
        Sale Tax 
      </label>
      &nbsp;  &nbsp;
      <Switch
        name="selectedReadingType"
        checked={value}
        onChange={onChange}
      />
    
    </div>
    : ""
  );
};

export default DumpRateSalesTaxSwitch;
