import React, { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import PageviewIcon from "./../../images/pageview-icon.svg";
import ArrowRight from "./../../images/arrow_right.svg";
import LeadsIcon from "./../../images/leads-icon.svg";
import { getStartDate, getEndDate } from "../../constants/common";
import DateRangePicker from "../../components/forms/DateRangePicker";
import { DownarrowIcon } from "../../components/icons";
import NumberFormat from "react-number-format";
import NotFound from "../NoRecords/component";
import moment from "moment";
import _ from "lodash";
import "./styles.scss";

export default class DashboardComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      openDatePicker: false,
      startDate: moment().format("MMM DD, YYYY"),
      endDate: moment().format("MMM DD, YYYY"),
      dashboardData: [],
      selectedDate: "",
      dateType: "today",
      datePickerModal: false
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.getMe();
    this.fetchDashboardStats();
    document.title = "Dashboard | Weighworks";
  };

  fetchDashboardStats = async () => {
    const { fetchDashboardData } = this.props;
    const { startDate, endDate } = this.state;
    const {
      value: { data }
    } = await fetchDashboardData({
      startDate: moment(startDate, "MMM DD, YYYY")
        .startOf("day")
        .toISOString(),
      endDate: moment(endDate, "MMM DD, YYYY")
        .endOf("day")
        .toISOString()
    });
    this.setState({ dashboardData: data });
  };

  onSelect = dates => {
    const self = this;
    self.setState(
      {
        dateType: "custom",
        selectedDate: `${moment(dates.start).format("MMM DD, YYYY")} - ${moment(dates.end).format("MMM DD, YYYY")}`,
        startDate: moment(dates.start).format("MMM DD, YYYY"),
        endDate: moment(dates.end).format("MMM DD, YYYY"),
        datePickerModal: false
      },
      () => {
        self.fetchDashboardStats({ startDate: self.state.startDate, endDate: self.state.endDate });
      }
    );
  };

  openModal() {
    this.setState({ openDatePicker: !this.state.openDatePicker });
  }

  setDate(e) {
    const self = this;
    const val = e.target.value;
    if (val === "today") {
      self.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment().format("MMM DD, YYYY"),
          endDate: moment().format("MMM DD, YYYY")
        },
        () => {
          self.fetchDashboardStats({ startDate: self.state.startDate, endDate: self.state.endDate });
        }
      );
    } else if (val === "this week") {
      self.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate: moment()
            .clone()
            .startOf("isoWeek")
            .format("MMM DD, YYYY"),
          endDate: moment()
            .clone()
            .endOf("isoWeek")
            .format("MMM DD, YYYY")
        },
        () => {
          self.fetchDashboardStats({ startDate: self.state.startDate, endDate: self.state.endDate });
        }
      );
    } else if (val === "this month") {
      self.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate: moment()
            .startOf("month")
            .format("MMM DD, YYYY"),
          endDate: moment()
            .endOf("month")
            .format("MMM DD, YYYY")
        },
        () => {
          self.fetchDashboardStats({ startDate: self.state.startDate, endDate: self.state.endDate });
        }
      );
    } else if (val === "custom") {
      self.setState({ datePickerModal: true });
    }
  }

  openDatePicker = e => {
    const val = e.target.value;
    if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  };

  closeModal = () => {
    this.setState({ datePickerModal: false });
  };

  changeRoute = event => {
    const { startDate, endDate } = this.state;
    this.props.history.push({
      pathname: event.currentTarget.dataset.id,
      state: { from: startDate, to: endDate }
    });
  };

  showPaymentTypes=(type,payment)=>{
    let paymentType = payment !== "" ? `- ${_.startCase(payment)}` : "";
  let btnClass = "btn btn-for-account";
  if (paymentType === "") {
    paymentType = "ACCOUNT";
  } else {
    paymentType = `COD ${paymentType}`;
  }
  if (paymentType === "COD - Credit Card") {
    btnClass = "btn btn-for-cc";
  } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
    btnClass = "btn btn-for-cash";
  } else if (paymentType === "COD - Terminal") {
    btnClass = "btn btn-for-terminal";
  }
  if(type === "css"){
    return btnClass
  }else{
    return paymentType
  }
  }
  render() {
    const { user } = this.props;
    const { startDate, endDate, dashboardData, selectedDate, dateType } = this.state;
    return (
      <div className="layout-has-sidebar">
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          user={user}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
        />
        <main className="dashboard-layout-content dashboard__wrapper">
          <div className="page-header"> 
            <h2 className="heading-title">Welcome {user && user.company && user.company.name}!</h2>
            <div className="form-group material-textfield custom-select-50">
              <select
                className="form-control custom-select w-150 material-textfield-input pr-22"
                name="dateType"
                onChange={this.setDate.bind(this)}
                // onClick={this.openDatePicker}
                value={dateType}
                required
              >
                <option value="today">Today ({getStartDate("today").format("MMM DD, YYYY")})</option>
                <option value="this week">
                  This Week ({getStartDate("week").format("MMM DD, YYYY")} - {getEndDate("week").format("MMM DD, YYYY")}
                  )
                </option>
                <option value="this month">
                  This Month ({getStartDate("month").format("MMM DD, YYYY")} -{" "}
                  {getEndDate("month").format("MMM DD, YYYY")})
                </option>
                <option value="custom">Custom {selectedDate !== "" ? `(${selectedDate})` : ""}</option>
              </select>
              <label className="material-textfield-label">Date </label>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-33">
              <div className="card d-card cursor-pointer" data-id="/scale" onClick={this.changeRoute}>
                <div className="row">
                  <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                    <h4 className="title">Pending weigh-ins</h4>
                    <p className="subtitle">
                      Number of weight-ins that aren't assigned to In or <span>Out Readings </span>
                    </p>
                    <div className="pageviews-count">{dashboardData && dashboardData.pengindWeighIns}</div>
                    <p className="pending-date">{dateType === "today" ? startDate : `${startDate} - ${endDate}`}</p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 text-sm-right">
                    <div className="d-card-icon">
                      <img className="img-fluid" src={PageviewIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-33">
              <div className="card d-card cursor-pointer" data-id="/completed-orders" onClick={this.changeRoute}>
                <div className="row">
                  <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                    <h4 className="title">Completed weigh-ins</h4>
                    <p className="subtitle">
                      Number of weight-ins that were assigned In or <span>Out Readings </span>
                    </p>
                    <div className="leads-count">{dashboardData && dashboardData.completedWeighIns}</div>
                    <p className="pending-date">{dateType === "today" ? startDate : `${startDate} - ${endDate}`}</p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 text-sm-right">
                    <div className="d-card-icon">
                      <img className="img-fluid" src={LeadsIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(_.get(user, "permissions.reports.inboundVsOutbound", false) || _.get(user, "permissions.reports.cashDiscrepancy", false) || _.get(user, "permissions.reports.clintAndJob", false))  && <div className="order__section">
            <div className="row">
              <div className="col-md-12">
                <h3 className="report__title">Reports</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="order__list">
                  {_.get(user, "permissions.reports.inboundVsOutbound", false) &&
                  <li className="card order__item" onClick={() => this.props.history.push("/material-report")}>
                    <div className="d-flex align-items-center">
                      <div>
                        <h3 className="order__title">Inbound vs Outbound Report </h3>
                      </div>
                    </div>
                    <div className="order__count">
                      <img className="img-fluid" src={ArrowRight} alt="" />
                    </div>
                  </li>
                   }{_.get(user, "permissions.reports.cashDiscrepancy", false) &&
                  <li className="card order__item" onClick={() => this.props.history.push("/cash-descrepancy-report")}>
                    <div className="d-flex align-items-center">
                      <div>
                        <h3 className="order__title">Cash Discrepancy Form/Log Report</h3>
                      </div>
                    </div>
                    <div className="order__count text-red">
                      {" "}
                      <img className="img-fluid" src={ArrowRight} alt="" />
                    </div>
                  </li>}
                  {_.get(user, "permissions.reports.clintAndJob", false) &&
                  <li className="card order__item" onClick={() => this.props.history.push("/client-report")}>
                    <div className="d-flex align-items-center">
                      <div>
                        <h3 className="order__title">{`Client & Job Report`}</h3>
                      </div>
                    </div>
                    <div className="order__count">
                      <img className="img-fluid" src={ArrowRight} alt="" />
                    </div>
                  </li>
                   }
                </ul>
              </div>
            </div>
          </div>}
          <div className="row">
            <div className="col-md-12">
              <div className="table-caption">
                Most Recent Weigh-ins {/*<Link className="btn-view-all" to="/">View All Listings <ArrowIcon/></Link>*/}
              </div>
              <div className="table-responsive">
                {dashboardData 
                  && dashboardData.mostRecentWeighIns 
                  && dashboardData.mostRecentWeighIns.length === 0 ? (
                  <NotFound title="No Recent Weigh-ins Found" />
                ) : (
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>Ticket #</th>
                      <th>Company Name</th>
                      <th>Inbound</th>
                      <th>Outbound</th>
                      <th>Net Weight</th>
                      <th>
                        Total Order Amount <DownarrowIcon />
                      </th>
                      <th>Payment Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData &&
                      dashboardData.mostRecentWeighIns &&
                      dashboardData.mostRecentWeighIns.map(order => {
                        return (
                          <tr
                            onClick={() =>
                              this.props.history.push({ pathname: `/order-details/${order.ticketNumber}` })
                            }
                            className="cursor-pointer"
                            key={order._id}
                          >
                            <td>{order.ticketNumber}</td>
                            <td>{order && order.customer && order.customer.companyName}</td>
                            <td>
                              <NumberFormat thousandSeparator={true} displayType={"text"} value={order.inboundWeight} />{" "}
                              lbs
                            </td>
                            <td>
                              <>
                                <NumberFormat
                                  thousandSeparator={true}
                                  displayType={"text"}
                                  value={order.outboundWeight}
                                />
                                lbs
                              </>
                            </td>
                            <td>
                              <>
                                <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} />
                                lbs
                              </>
                            </td>
                            <td>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                value={order.totalAmount}
                              />
                            </td>
                            <td> {order?.paymentTerms === "account"?
                        <button className={this.showPaymentTypes("css",'')}>{this.showPaymentTypes("paymentType",'')}</button>
                        : order.paymentType === "" ? order.splitPayment && order.splitPayment.length > 0 && order.splitPayment.map((payment,index)=>{
                          return(
                          <button className={(this.showPaymentTypes("css",payment.paymentType)) + ( index < order.splitPayment.length-1 ? " mr-2" : "")}>{this.showPaymentTypes("paymentType",payment.paymentType)}</button>)
                        }) 
                        :
                        <button className={this.showPaymentTypes("css",order.paymentType)}>{this.showPaymentTypes("paymentType",order.paymentType)}</button>
                       }</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                )}
              </div>
            </div>
          </div>
        </main>
        <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format("MMM DD, YYYY")} - ${moment(
                  this.state.endDate
                ).format("MMM DD, YYYY")}`
              },
              () => {
                this.closeModal();
                this.fetchDashboardStats();
              }
            );
          }}
          handleFromChange={date => this.setState({ startDate: moment(date).format("MMM DD, YYYY") })}
          handleToChange={date => this.setState({ endDate: moment(date).format("MMM DD, YYYY") })}
          from={this.state.startDate}
          to={this.state.endDate}
        />
      </div>
    );
  }
}
