import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import InputMask from "react-input-mask";
import { Formik, ErrorMessage } from "formik";
import { userSchema } from "../../../utils/validations";
import { CloseIcon } from "../../../components/icons";

class EditUser extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      initialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "admin",
        status: "1"
      },
      bussinessAdminCheck: false,
      pricingSetupAdminCheck: false,
      scaleSetupAdminCheck: false,
      intergrationsCheck: false,
      userManagementCheck: false,
      reportsCheck: false,
      ordersCheck: false,
      inboundVsOutboundCheck: false,
      clientAndJobReportCheck: false,
      cashDiscrepancyCheck: false,
      projectOrdersCheck: false,
      deletedTicketsCheck:false,
      bussinessSetupCheck: false,
    };
  }

  static getDerivedStateFromProps({ user }, prevState) {
    if (user && user !== prevState.user) {
      const { firstName, lastName, phone, email, role, status } = user;
      const initialValues = {
        firstName,
        lastName,
        phone,
        email,
        role,
        status
      };
      return { initialValues, user,};
    }
    return null;
  }



  handleSubmit = async ({ phone,role, ...rest }, { setErrors }) => {
    let payload={};
    const {
      user: { companyId, _id }
    } = this.state;
    
    payload = {
      ...rest,
      companyId,
      role,
      id: _id,
      phone: phone.replace(/[^\d]/g, ""),
    };
    const {
      value: { status, message }
    } = await this.props.updateUserById(payload);
    if (status) {
      this.props.closeModal();
      this.props.fetchCompanyUsers();
    } else {
      setErrors({ email: message });
    }
  };

  render() {
    const { editUserModal, closeModal } = this.props;
    const { initialValues } = this.state;
    return (
      <ReactModal isOpen={editUserModal} onRequestClose={closeModal} contentLabel="Please Confirm" ariaHideApp={false}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={userSchema}
          onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <div className="react-modal-dialog react-modal-dialog-centered edit__user-modal">
              <div className="react-modal-header">
                <h5 className="react-modal-title">Edit User</h5>
                <button type="button" className="btn react-modal-close" onClick={closeModal}>
                  <CloseIcon />
                </button>
              </div>
              <form onSubmit={handleSubmit} noValidate>
                <div className="react-modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <input
                          name="firstName"
                          value={values.firstName}
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          required
                        />
                        <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          First Name<span>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <input
                          name="lastName"
                          value={values.lastName}
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          required
                        />
                        <ErrorMessage component="span" name="lastName" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          Last Name<span>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <InputMask
                          value={values.phone}
                          mask="(999) 999-9999"
                          name="phone"
                          maskChar=""
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          required
                        />
                        <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          Phone Number<span>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <input
                          name="email"
                          type="text"
                          value={values.email}
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          required
                        />
                        <ErrorMessage component="span" name="email" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          Email<span>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <select
                      value={values.role}
                      className="form-control custom-select material-textfield-input"
                      name="role"
                      onChange={handleChange}
                      required
                    >
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                      <option value="supervisor">Supervisor</option>
                    </select>
                    <ErrorMessage component="span" className="invalid-feedback d-block" name="role" />
                    <label className="material-textfield-label">
                      Role<span>*</span>
                    </label>
                  </div>
                  <div className="form-group material-textfield">
                    <select
                      value={values.status}
                      className="form-control custom-select material-textfield-input"
                      name="status"
                      onChange={handleChange}
                      required
                    >
                      <option value="1">Active</option>
                      <option value="3">Inactive</option>
                    </select>
                    <ErrorMessage component="span" className="invalid-feedback d-block" name="status" />
                    <label className="material-textfield-label">
                      Status<span>*</span>
                    </label>
                  </div>
                  <button disabled={isSubmitting} className="btn btn-dark btn-lg w-100" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </ReactModal>
    );
  }
}

export default EditUser;
