import React from "react";
import { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import ReactModal from "react-modal";
import { CloseIcon} from "../../components/icons";
import {Switch } from "antd";
import { BackArrowIcon } from "../../components/icons";
import { Link } from "react-router-dom";

    export default class OutboundActivityMaterialsComponent extends PureComponent {
        static propTypes = {
          // PropTypes go here
        };
        constructor(props) {
          super(props);
          this.state = {
            materials: [],
            searchText: "",
            modalIsOpen : false,
            addMaterialsErrorMsg : "",
            fieldName : "",
            companyId: ""
          };
          this.openModal = this.openModal.bind(this);
          this.closeModal = this.closeModal.bind(this);
        }

      
        async componentDidMount() {
          this.getMaterial();
          this.fetchMe()
        }

        getMaterial = async() =>{
          const { value : {data} } = await this.props.getOutboundMaterials();
          this.setState({ materials: data });
        }

      toggleStatus = async (materialId) => {
        const { materials } = this.state;
        const index = materials.findIndex(material => material._id === materialId);
    
        if (index !== -1) {
          const updatedMaterial = { ...materials[index] };
          updatedMaterial.status = !updatedMaterial.status;
          const updatedMaterials = [...materials];
          updatedMaterials[index] = updatedMaterial;
          this.setState({ materials: updatedMaterials });
    
          const payload = {
            materials: updatedMaterial.materials,
            status: updatedMaterial.status,
          };
          try {
            await this.props.postOutboundMaterials(payload);
          } catch (error) {
            this.setState(prevState => ({
              materials: prevState?.materials?.map(material => {
                if (material?._id === materialId) {
                  return { ...material, status: !material.status };
                }
                return material;
              }),
            }));
          }
        }
      };

      handleInputValue = (e) =>{
        let fieldValue = e.target.value;
        let errorMessage = "";
        if (fieldValue.length > 50) {
          fieldValue = fieldValue.slice(0, 50);
          errorMessage = "Maximum character limit reached";
        }
        this.setState({ 
          fieldName: fieldValue,
          addMaterialsErrorMsg: errorMessage
        });
      }      
        openModal() {
          this.setState({ modalIsOpen: true});
        }

        closeModal() {
          this.setState({
            modalIsOpen: false,
          });
        }

        fetchMe = async () => {
          const {
            value:{user}
          } = await this.props.fetchMe();
          this.setState({companyId:user?.company?._id})
        }

        createMaterialField = async () => {
          if (this.state.fieldName === "") {
            this.setState({ addMaterialsErrorMsg: "Material field is required" })
          } else {
            let fieldName = this.state.fieldName;
            const { materials } = this.state;
            const newMaterialName = fieldName.trim();
            const isDuplicate = materials.some(material => material.materials.toLowerCase() === newMaterialName.toLowerCase());
            if (isDuplicate) {
              this.setState({ addMaterialsErrorMsg: "Material name already exists" });
            }
            else {
            const payload = {
              materials: fieldName.trim(),
              status : true
            };
            const {
              value: { status },
            } = await this.props.postOutboundMaterials(payload);
            if (status) {
              this.setState({ modalIsOpen: false , fieldName: "", addMaterialsErrorMsg: "" })
              this.getMaterial()
            }else{
            }
          }
          }
        }

      render() {
        const {
          searchText,materials
        } = this.state;
        const sortedMaterials = [...materials].sort((a, b) => {
          const materialA = a?.materials?.toUpperCase();
          const materialB = b?.materials?.toUpperCase();
          if (materialA < materialB) {
            return -1;
          }
          if (materialA > materialB) {
            return 1;
          }
          return 0;
        });
      
        return (
          <div className="layout-has-sidebar">
            <TopNavigation
              {...this.props}
              searchText={searchText}
              handleSearchChange={this.handleSearch}
              onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
            />
            <SidebarNavigation
              {...this.props}
              toggleMenu={this.state.toggleMenu}
              onClickToggleMenu={() => this.setState({ toggleMenu: false })}
            />                    
            <main className="dashboard-layout-content">
              <div className="btn-back-header">
                <button className="btn btn-link btn-back">
                  <Link to={"/outbound-activity-active"}>
                    <BackArrowIcon /> Back
                  </Link>
                </button> 
              </div>    
              <div className="page-header">
                <h2 className="heading-title">Outbound Materials</h2>
                <div className="page-header-btn-grp">
                  <button className="btn btn-dark btn-sm" onClick={this.openModal}>
                    Add New Material
                  </button>
                </div>
              </div>
              <div className="dashboard-main-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr>
                            <th className="first-child">Material Name</th>
                            <th className="last-child text-right">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedMaterials.map((material, index) => (
                            <tr key={material._id}>
                              <td className="first-child">{material.materials}</td>
                              <td className="last-child text-right">
                                <Switch
                                    checked={material.status}
                                    onChange={() => this.toggleStatus(material._id)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </main>
           
            <ReactModal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Add material"
              ariaHideApp={false}
            >
              <div className='react-modal-dialog react-modal-dialog-centered HeightAutoModal-'>
                <div className='react-modal-header'>
                  <h5 className='react-modal-title'>Add Material</h5>
                  <button
                    type='button'
                    className='btn react-modal-close'
                    onClick={() => this.setState({ modalIsOpen: false , fieldName: "" , addMaterialsErrorMsg: ""})}>
                    <CloseIcon />
                  </button>
                </div>
                <div className='react-modal-body'>
                  <div className="row">
                    <div className='col-md-12'>
                      <div className='form-group material-textfield'>
                        <input
                          type='text'
                          name='fieldName'
                          className='form-control material-textfield-input'
                          value = {this.state.fieldName}
                          onChange={(e) => {
                           this.handleInputValue(e)
                          }}
                          required
                        />    
                         <p className='invalid-feedback d-block'>{this.state.addMaterialsErrorMsg === "" ? "" : this.state.addMaterialsErrorMsg}</p>      
                        <label className='material-textfield-label'>
                          Material Name<span>*</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <button className="btn btn-dark btn-lg w-100" type="button" disabled={this.state.addMaterialsErrorMsg === "" ? false : true} onClick={() => this.createMaterialField()}>
                          Create
                        </button>
                      </div>                              
                    </div>                           
                  </div>
                </div>
              </div>
            </ReactModal>
          </div>
        );
      }
    }