import React, { PureComponent } from "react";
// import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { ArrowIcon, CloseIcon, ExportIcon } from "../../components/icons";
import { numberFormat, getStartDate, getEndDate , numberFormatLbs, round2decimal, numberWithCommas} from "../../constants/common";
import moment from "moment-timezone";
import "./styles.scss";
import { Formik, ErrorMessage } from "formik";
import { withDrawlSchema, depositSchema } from "../../utils/validations";
import DateRangePicker from "../../components/forms/DateRangePicker";
import NotFound from "../NoRecords/component";
import NumberFormat from "react-number-format";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export default class CashLogBalanceComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);

    this.state = {
      isCreateDrawerOpen: false,
      modalIsOpen: false,
      depositModal: false,
      toggleMenu: false,
      transactions: [],
      openDatePicker: false,
      startDate: moment()?.tz(tz)?.toDate(),
      endDate: moment()?.tz(tz)?.toDate(),
      selectedDate: "",
      closeBalanceModal: false,
      dateType: "today",
      datePickerModal: false,
      withDrawlInitialValues: {
        amount: "",
        withDrawlReason: "bankDeposit",
      },
      depositInitialValues: {
        amount: "",
      },
      exportData:[],
      users:[],
      userId: "none",
      tz,
      isExport:false
    };
    this.openModal = this.openModal.bind(this);
    this.openWithDrawlModal = this.openWithDrawlModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  
  setAsyncState = (newState) =>
    new Promise((resolve) => this.setState(newState, resolve));

  getFromLocalStorage = async ({ dateType, startDate, endDate }) => {
    await this.setAsyncState({
      startDate: getStartDate(dateType, startDate)?.tz(this.state?.tz)?.toDate(),
      endDate: getEndDate(dateType, endDate)?.tz(this.state?.tz)?.toDate(),
      dateType,
      selectedDate: `${getStartDate(dateType, startDate)?.tz(this.state?.tz)?.format(
        "MMM DD, YYYY"
      )} - ${getEndDate(dateType, endDate)?.tz(this.state?.tz)?.format("MMM DD, YYYY")}`,
    });
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    document.title = "Cash Transactions | WeighWorks";
    const localStorageData = JSON.parse(localStorage.getItem("date"));
    if (localStorageData) {
      await this.getFromLocalStorage(localStorageData);
    }
    let startDate= moment(this.props.date?.startDate)?.tz(this.state?.tz)?.toDate()
    let endDate= moment(this.props.date?.endDate)?.tz(this.state?.tz)?.toDate()
   this.props.date ? this.fetchTransactions(startDate,endDate,this.state.userId):this.fetchTransactions();
   this.fetchCompanyUsers();

  };

  fetchCompanyUsers = async () => {
    const { fetchCompanyUsers } = this.props;
    const {
      value: { data: { users } }
    } = await fetchCompanyUsers({searchText:"",limit:100,skip:0, getInactiveUsers: false});
    this.setState({ users });
  };

  componentDidUpdate =async ()=>{
     let startDate= this.props.date.startDate
     let endDate=this.props.date.endDate
    if(this.state.startDate !== startDate || this.state.endDate !== endDate){
   this.setState({startDate:startDate,endDate:endDate})
    this.fetchTransactions(startDate,endDate,this.state.userId);
    }
  }

  fetchTransactions = async (startDate,endDate,userId) => {
    const { dateType } = this.state;
    const localStorageData = {
      startDate,
      endDate,
      dateType,
    };
    localStorage.setItem("date", JSON.stringify(localStorageData));
    const {
      value: { data },
    } = await this.props.fetchTransactions({
      type: "cash",
      startDate: moment(startDate)?.tz(this.state?.tz)?.toISOString(),
      endDate: moment(endDate)?.tz(this.state?.tz)?.toISOString(),
      timezone: moment().format("Z"),
      userId: userId
    });
    this.setState({ transactions: data });
  };

  openWithDrawlModal() {
    this.setState({ modalIsOpen: true });
  }

  openModal() {
    this.setState({ openDatePicker: !this.state.openDatePicker });
  }


  closeModal() {
    this.setState({
      modalIsOpen: false,
      depositModal: false,
      datePickerModal: false,
    });
  }

  openDatePicker = (e) => {
    const val = e.target.value;
    if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  };

  depositModal = () => {
    this.setState({ depositModal: !this.state.depositModal });
  };

  setDate(e) {
    const self = this;
    const val = e.target.value;
    if (val === "today") {
      self.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment().format("MMM DD, YYYY"),
          endDate: moment().format("MMM DD, YYYY"),
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "this week") {
      self.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate: moment().clone().startOf("isoWeek").format("MMM DD, YYYY"),
          endDate: moment().clone().endOf("isoWeek").format("MMM DD, YYYY"),
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "this month") {
      self.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate: moment().startOf("month").format("MMM DD, YYYY"),
          endDate: moment().endOf("month").format("MMM DD, YYYY"),
        },
        () => {
          this.fetchTransactions();
        }
      );
    } else if (val === "custom") {
      self.setState({ datePickerModal: true });
    }
  }

  onSelect = ({ start, end }) => {
    this.setState(
      {
        dateType: "custom",
        selectedDate: `${moment(start).format("MMM DD, YYYY")} - ${moment(
          end
        ).format("MMM DD, YYYY")}`,
        startDate: moment(start).format("MMM DD, YYYY"),
        endDate: moment(end).format("MMM DD, YYYY"),
        datePickerModal: false,
      },
      () => {
        this.fetchTransactions();
      }
    );
  };

  closeBalanceModalOpen = () => {
    // this.setState({ closeBalanceModal: true });
    this.props.closeBalanceModalOpen()
  };

  closeBalanceModalClose = () => {
    // this.setState({ closeBalanceModal: false });
    this.props.closeBalanceModalClose()
  };

  async handleSubmit({ amount, withDrawlReason , note}, type) {
    const { saveAmount } = this.props;
    amount = parseFloat(String(amount).replace(/,/g, ""));
    const {
      value: { status },
    } = await saveAmount({ amount, type, withDrawlReason , note});
    if (status) {
      this.closeModal();
      
      this.fetchTransactions(this.state?.startDate, this.state?.endDate,this.state.userId);
    }
  }

 async exportTransaction(){
  const startDate = moment(this.state.startDate)
  .startOf("day")
  .toISOString();
 const endDate = moment(this.state.endDate)
  .endOf("day")
  .toISOString();
    const {
      value: {
        data: {inFlowDetails}
      }
    } = await this.props.fetchTransaction({ startDate: startDate, endDate: endDate, type: "cash", timezone: moment().format("Z"), userId: this.state.userId});
    const exportData = inFlowDetails[0]?.data?.map(item => ({
      ...item,
      totalAmount: item.amount,
      timezone: localStorage.getItem("timezone") ?? moment?.tz?.guess(),
      displayName: item.order?.customer?.displayName ? item.order?.customer?.displayName : "N/A",
      date: item.createdAt,
      orderCreationType: item.order.orderCreationType,
      outboundWeightDate: item.order.outboundWeightDate,
      inboundWeightDate: item.order.inboundWeightDate,
      ticketNumber: item.order.ticketNumber ?? item?.receivePayments?.invoices?.map(invoice => invoice?.invoiceId)?.join(", "),
      tonnage: item.order.tonnage,
      yardage: item.order.yardage,
      dumpRate: item.order.dumpRate,
    }));
    this.setState({ exportData,isExport:true });
    setTimeout(() => {
      this.setState({ isExport: false });
    }, 2000);
  }

  handleUserChange = (e) => {
    const {value} = e.target;
    this.setState({ userId: value,isExport:false },()=>{
      this.fetchTransactions(this.state.startDate, this.state.endDate, value);
    })
  }

  render() {
    const { transactions,users,isExport } = this.state;
    return (
      <div className="layout-has-sidebar--">
        {/* <TopNavigation
          {...this.props}
          onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          closeBalanceModal={this.state.closeBalanceModal}
          closeBalanceModalFunc={this.closeBalanceModalClose}
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        /> */}

        <main className="cashlogbalance__wrapper">
          <div className=" d-flex justify-content-between">
          <div className="cashlogbalance-page-header">
            <ul className="enterwithdrawl__list">
              <li onClick={this.depositModal}>+ Enter Deposit</li>
              <li onClick={this.openWithDrawlModal}>+ Enter Withdrawl</li>
              <li onClick={this.closeBalanceModalOpen}>
                Enter Closing Balance
              </li>
            </ul>
            {/* <div className="form-group material-textfield custom-select-50">
              <select
                className="form-control custom-select w-150 material-textfield-input pr-22"
                name="dateType"
                onChange={this.setDate.bind(this)}
                // onClick={this.openDatePicker}
                value={dateType}
                required
              >
                <option value="today">
                  Today ({getStartDate("today").format("MMM DD, YYYY")})
                </option>
                <option value="this week">
                  This Week ({getStartDate("week").format("MMM DD, YYYY")} -{" "}
                  {getEndDate("week").format("MMM DD, YYYY")})
                </option>
                <option value="this month">
                  This Month ({getStartDate("month").format("MMM DD, YYYY")} -{" "}
                  {getEndDate("month").format("MMM DD, YYYY")})
                </option>
                <option value="custom">
                  Custom {selectedDate !== "" ? `(${selectedDate})` : ""}
                </option>
              </select>
              <label className="material-textfield-label">Date </label>
            </div> */}
          </div>
          <div className="cashlogbalance-page-header ml-auto mr-3">
                <div className="cursor-pointer " >
                <div className="form-group material-textfield">
                  <select
                    className="form-control custom-select  material-textfield-input user-select-all "
                    name="userId"
                    onChange={(e)=>this.handleUserChange(e)}
                    value={this.state.userId}
                  >
                    <option value="none">None</option>
                    {users && users.length > 0 ? users?.sort((a, b) => (a.firstName + a.lastName)?.localeCompare(b.firstName + b.lastName))?.map((user) => {
                      return (
                        <option key={user._id} value={user._id}>
                          {user.firstName} {user.lastName}
                        </option>
                      );
                    }):null
                    }
                  </select>
                  <label className="material-textfield-label">
                  User
                  </label>
                </div>
                </div>
              </div>
                <div className="export__section">
                <div className="cursor-pointer d-flex" onClick={() => this.exportTransaction()}>
                  <ExportIcon />
                  <h3>Export Cash Register</h3>
                </div>
              </div>
          </div>
    

          {/* <div className="row">
              <div className="col-md-12">
                <div className="custome__tabs">
                  <ul className="custome__tabs-list">
                    <li>
                      <Link className="active" to={"/cash-transactions"}>
                        Cash Transactions
                      </Link>
                    </li>
                    <li>
                      <Link to={"/credit-card-transactions"}>Credit Card Transactions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          {transactions.length === 0 ? (
            <NotFound title="No Transactions" />
          ) : (
            transactions.map((transaction) => {
              return (
                <div className="row" key={transaction._id}>
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header d-flex align-items-start justify-content-between flex-xs-column">
                        <h5 className="card-title">
                          {moment(transaction._id)?.tz(this.state?.tz)?.format("MMM DD, YYYY")}{" "}
                          Balance{" "}
                        </h5>
                        <p className="card-title-sm">
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              let url = `/itemized-cash-transactions/${transaction._id}?userId=${this.state.userId}`;
                              this.props.navigate(url);
                              // this.props.history.push({
                              //   pathname: `/itemized-cash-transactions/${transaction._id}`
                              // })
                            }}
                          >
                            View Itemized List
                            <ArrowIcon />
                          </span>
                        </p>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <ul className="transactions__list">
                              <li>
                                <div>
                                  <h5 className="transactions__title m-0">
                                    Opening Balance
                                  </h5>
                                </div>
                                <div className="text-amount text-green">
                                  ${numberFormat(transaction.openingBalance)}
                                </div>
                              </li>
                              <li>
                                <div>
                                  <h5 className="transactions__title">
                                    Cash Debit (inflow)
                                  </h5>
                                  <p className="transactions__sub-title">
                                    {transaction.inFlowCount === 1
                                      ? `${transaction.inFlowCount} transaction`
                                      : `${transaction.inFlowCount} transactions`}
                                  </p>
                                </div>
                                <div className="text-amount text-green">
                                  +${numberFormat(transaction.inFlow)}
                                </div>
                              </li>
                              <li>
                                <div>
                                  <h5 className="transactions__title">
                                    Cash Credit (outflow)
                                  </h5>
                                  <p className="transactions__sub-title">
                                    {transaction.outFlowCount === 1
                                      ? `${transaction.outFlowCount} transaction`
                                      : `${transaction.outFlowCount} transactions`}
                                  </p>
                                </div>
                                <div className="text-amount text-red">
                                  -${numberFormat(transaction.outFlow)}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between">
                        <h5 className="transactions__title">Closing Balance</h5>
                        <p className="text-amount">
                          {" "}
                          ${numberFormat(transaction.closingBalance)}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </main>
        <ReactModal
          isOpen={this.state.depositModal}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Deposit</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <Formik
                enableReinitialize
                initialValues={this.state.depositInitialValues}
                validationSchema={depositSchema}
                onSubmit={(values, formikProps) => {
                  this.handleSubmit(values, "deposit");
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setValues,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={(_values) => {
                              const { formattedValue } = _values;
                              const amount = formattedValue;
                              setValues({
                                ...values,
                                amount,
                              });
                            }}
                            value={values.amount}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="amount"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Enter Amount<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              const note = e.target.value;
                              setValues({
                                ...values,
                                note,
                              });
                            }}
                            value={values.note}
                          />
                          <label className="material-textfield-label">
                            Note
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-dark btn-lg w-100"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </ReactModal>
        <ReactModal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Withdrawl</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <Formik
                enableReinitialize
                initialValues={this.state.withDrawlInitialValues}
                validationSchema={withDrawlSchema}
                onSubmit={(values, formikProps) => {
                  this.handleSubmit(values, "withDrawl");
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  setValues,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            displayType={"input"}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={(_values) => {
                              const { formattedValue } = _values;
                              const amount = formattedValue;
                              setValues({
                                ...values,
                                amount,
                              });
                            }}
                            value={values.amount}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="amount"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Enter Amount<span>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        name="withDrawlReason"
                        className="form-control custom-select material-textfield-input"
                        required
                        value={values.withDrawlReason}
                        onChange={handleChange}
                      >
                        <option value="bankDeposit">Bank Deposit</option>
                        <option value="pettyCash">Petty Cash</option>
                      </select>
                      <ErrorMessage
                        component="span"
                        name="withDrawlReason"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">
                        Withdrawl Reason<span>*</span>
                      </label>
                    </div>
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={(e) => {
                              const note = e.target.value;
                              setValues({
                                ...values,
                                note,
                              });
                            }}
                            value={values.note}
                          />
                          <label className="material-textfield-label">
                            Note
                          </label>
                      </div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-dark btn-lg w-100"
                    >
                      Submit
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </ReactModal>
        <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format(
                  "MMM DD, YYYY"
                )} - ${moment(this.state.endDate).format("MMM DD, YYYY")}`,
              },
              () => {
                this.closeModal();
                this.fetchTransactions();
              }
            );
          }}
          handleFromChange={(date) =>
            this.setState({ startDate: moment(date).format("MMM DD, YYYY") })
          }
          handleToChange={(date) =>
            this.setState({ endDate: moment(date).format("MMM DD, YYYY") })
          }
          from={this.state.startDate}
          to={this.state.endDate}
        />

        {
          
          this.state.exportData.length > 0 && isExport &&  (  
          <ExcelFile key={Number(this.state.exportData.length) + Number(Math.random())} hideElement filename={"Cash Register " + moment().format("MM/DD/YYYY hh:mm:ss a")}>
          <ExcelSheet data={this.state.exportData} name="Orders">
            <ExcelColumn label="Date"   value={col =>
                `${moment.tz(col.date, col.timezone).format("MM/DD/YY")}`
              } />
            <ExcelColumn label="Order Number" value="ticketNumber" />
            <ExcelColumn label="Inbound Time"   value={col =>
                `${col.orderCreationType === "createCharge" ? "N/A" : moment(col.inboundWeightDate)?.tz(col.timezone)?.format("hh:mm a")}`
              } />
            <ExcelColumn label="Outbound Time"   value={col =>
                `${col.orderCreationType === "createCharge" ? "N/A" : moment(col.outboundWeightDate)?.tz(col.timezone)?.format("hh:mm a")}`
              } />
            <ExcelColumn label="Customer" value={col => col.displayName}  />
            <ExcelColumn label="Tons" value={col => isNaN(numberFormatLbs(col.tonnage)) ? '-' : numberFormatLbs(col.tonnage)} />
            <ExcelColumn label="Yardage" value={col => isNaN(numberFormatLbs(col.yardage)) ? '-' : numberFormatLbs(col.yardage)} />
            <ExcelColumn label="Dump Rate" value={col => 
             col.orderCreationType === "createCharge" ? "-" :
             isNaN(numberWithCommas(round2decimal(Number(col.dumpRate))))
             ? "-"
             : `$${numberWithCommas(round2decimal(Number(col.dumpRate)))}`
            } />
            <ExcelColumn label="Total Revenue" value={col => `$${numberWithCommas(
                                                                 round2decimal(
                                                                 Number(col.totalAmount)))}`} />
          </ExcelSheet>
        </ExcelFile>
        )
        }
      </div>
    );
  }
}
