import React from "react";
// import DateRangePicker from 'react-daterange-picker'
// import 'react-daterange-picker/dist/css/react-calendar.css'
import { CloseIcon } from "../icons";
import ReactModal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";

// const ModalComponent = ({ datePickerModal, closeModal, handleSelect }) => (
//   <ReactModal isOpen={datePickerModal} onRequestClose={closeModal} contentLabel="Please Confirm" ariaHideApp={false}>
//     <div className="react-modal-dialog react-modal-dialog-centered select__date-modal">
//       <div className="react-modal-body text-center">
//         <div className="react-modal-header">
//           <h5 className="react-modal-title text-left">Select Date</h5>
//           <button type="button" className="btn react-modal-close" onClick={closeModal}>
//             <CloseIcon />
//           </button>
//         </div>
//         <DateRangePicker
//           onSelect={handleSelect}
//           numberOfCalendars={2}
//           singleDateRange
//           selectionType="range"
//         />
//       </div>
//     </div>
//   </ReactModal>
// );
const ModalComponent = ({ datePickerModal, closeModal, handleFromChange, handleToChange, from, to, setDate }) => (
  <ReactModal isOpen={datePickerModal} onRequestClose={closeModal} contentLabel="Please Confirm" ariaHideApp={false}>
    {/* <li className="filter__list-item"> */}
    <div className="react-modal-dialog react-modal-dialog-centered custom__select-date-modal">
      <div className="react-modal-header">
        <h5 className="react-modal-title text-left">Select Date</h5>
        <button type="button" className="btn react-modal-close" onClick={closeModal}>
          <CloseIcon />
        </button>
      </div>
      <div className="react-modal-body text-center">
        <div className="custom__date-inner">          
          <div className="filter__list-item-inner show-list-detail">
            <div className="calendar__form">
              <div className="input-from">
                <div className="form-group">
                  <DatePicker
                    name="from"
                    selected={moment(from, "MMM DD, YYYY").toDate()}
                    onChange={handleFromChange}
                    className="form-control material-textfield-input"
                    placeholder="10/01/20"
                    required
                    autoComplete="off"
                  />
                  <label className="material-textfield-label label__for-date">From</label>
                </div>
              </div>
              <div className="input-to">
                <div className="form-group">
                  <DatePicker
                    name="to"
                    selected={moment(to, "MMM DD, YYYY").toDate()}
                    onChange={handleToChange}
                    className="form-control material-textfield-input"
                    placeholder="10/01/20"
                    required
                    autoComplete="off"
                  />
                  <label className="material-textfield-label label__for-date">To</label>
                </div>
              </div>
            </div>
            <button className="btn btn-dark btn-lg w-100 save__btn" onClick={setDate}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* </li> */}
  </ReactModal>
);

export default ModalComponent;
