import React, { PureComponent } from "react";
import _ from "lodash";
import "../styles.scss";
import moment from "moment";
import { sort_by_descending,customStartCase } from "../../../constants/common";


// const timezone = moment().format("Z");
class PrintReciptComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      printReciptData: {},
      reRender:false,
      reArrangOrders:[]
    };
  }
  
  componentDidMount = () => {
    this.reArrangOrdersData()
    setTimeout(() => {
    this.setState({ printReciptData: this.props.printRecipt }, () => {
      this.printRecipt();
            });
    }, 20);
  };
  printRecipt = () => {
    window.print();
    this.props.closePrintRecipt()
  }
  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  reArrangOrdersData = () => {
    const { order } = this.props.printRecipt
    const { reArrangOrders } = this.state
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if ((item?.orderBreakDownItems?.isminimumPrice)) {
            let obj = {
              fixedItems: [{
                fixedItem: "Dumping services",
                unitPrice: item?.orderBreakDownItems?.minimumPrice,
                units: 1,
                totalamout: item?.orderBreakDownItems?.totalAmountDue,
              }],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType
            }
            reArrangOrders.push(obj)
            return null 
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {
         
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems?.fixedItem)
                  if (fixedItemName.includes("Yard")) {
                    if ( Number(fixedItems?.unitPrice) > Number(item?.capoutPrice)) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout: item?.orderBreakDownItems?.capoutPrice * fixedItems.units,
                      }
                      obj.fixedItems.push(fixedItemObject)
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    }
                    obj.fixedItems.push(fixedItemObject)
                  }
                  return null 
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightFixedItems) => {
                  let chargeByWeightItemObject = {
                    fixedItem: chargeByWeightFixedItems?.fixedItem,
                    unitPrice: chargeByWeightFixedItems?.unitPrice,
                    units: chargeByWeightFixedItems?.units,
                    totalamout: chargeByWeightFixedItems?.unitPrice * chargeByWeightFixedItems?.units,
                  }
                  
                  obj.fixedItems.push(chargeByWeightItemObject)
                  return null 
                })
              }
              reArrangOrders.push(obj)
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  }
                  obj.fixedItems.push(fixedItemObject)
                  return null 
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightItems) => {
                  let chargeByWeightItemsObject = {
                    fixedItem: chargeByWeightItems?.fixedItem,
                    unitPrice: chargeByWeightItems?.unitPrice,
                    units: chargeByWeightItems?.units,
                    totalamout: chargeByWeightItems?.unitPrice * chargeByWeightItems?.units,
                  }
                  obj.fixedItems.push(chargeByWeightItemsObject)
                  return null 
                })
              }
              reArrangOrders.push(obj)
            }
          }
        }else{
       let obj={
        inboundWeight: item?.inboundWeight,
        outboundWeight: item?.outboundWeight,
        // dumpRateType: item?.dumpRateType,
        // dumpRate: item?.dumpRate,
        // ticketNumber: item?.ticketNumber,
        netWeight: item?.netWeight,
        // yardage: item?.yardage,
        // tonnage: item?.tonnage,
        totalAmount: item?.totalAmount,
        //  totalAmount :item?.totalAmount,
         units : 1,
         dumpRateType: "Dumping services",
         ticketNumber: item?.ticketNumber
          }
           reArrangOrders.push(obj)
        }
        return null 
      })
    }
    this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    console.log(reArrangOrders,"reArrangOrders")
  }


  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  render() {
    console.log("data", this.state.printReciptData?.balacnce);
    // const { order } = this.state.printReciptData;
    const Company =
      this.state.printReciptData.company &&
      this.state.printReciptData.company[0];
    // let total = (this.state.printReciptData?.totalOrdersAmount) + (Company?.environmentalLoadFeeAmount) +
    //   (Company?.fuelSurchardeFeeAmount);

    return (
      <>
      <div className="print-block-two">
        <div className="invoice__each-transaction invoice__print-table-main" style={{width: '100%', padding: '15px 30px'}}>
          <table className="invoice__print-table" align="left">
            <thead>
              <tr>
                <th colSpan={3} style={{paddingTop: "25px",}}>
                  <h3 
                    style={{
                    fontFamily: "Arial",
                    fontWeight: "700",
                    fontSize: '18px',
                    lineHeight: "23px",
                    color: "#000000",
                    marginBottom: "12px",
                    textAlign: 'center',
                    }}
                  > {Company?.businessInfo?.businessName}</h3>
                  <p 
                    style={{
                    fontFamily: "Arial",
                    fontWeight: "400",
                    fontSize: '15px',
                    lineHeight: '23px',
                    textAlign: "center",
                    color: "#000000",
                    marginBottom: "41px",
                    }}
                  >
                    {Company?.businessInfo?.address}
                    <br />
                    {Company?.businessInfo?.state +
                      " " +
                      Company?.businessInfo?.city +
                      "," +
                      Company?.businessInfo?.zip}
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{verticalAlign:"top", paddingRight:"30px", maxWidth:"210px"}}>
                    {/* <h3
                    style={{
                    fontFamily: "Arial",
                    fontWeight: "700",
                    fontSize: '15px',
                    lineHeight: "23px",
                    color: "#000000",
                    textAlign: 'left',
                    margin: "0px",
                    }}
                    >{Company?.businessInfo?.address}</h3> */}
                    <p
                      style={{
                      fontFamily: 'Arial',
                      fontWeight: '400',
                      fontSize: '15px',
                      lineHeight: '23px',
                      textAlign: "left",
                      color: '#000000',
                      margin: '0px',
                    }}
                    >
                      {Company?.businessInfo?.state +
                          " " +
                        Company?.businessInfo?.city +
                        "," +
                        Company?.businessInfo?.zip}
                      <br />
                      {Company?.businessInfo?.phone}
                      <br />
                      {Company?.email}
                    </p>
                </td>
                <td style={{verticalAlign: 'top', paddingRight: '30px'}}>
                  <table className="invoice-number-date-table">
                    <tbody>
                      <tr>
                        <td>
                          <h3
                            style={{
                            fontFamily: 'Arial',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '18px',
                            color: '#000000',
                            minWidth: '127px',
                            margin: '0px',
                            verticalAlign: 'top',
                            }}
                          >Invoice Number: </h3>
                        </td>
                        <td>
                          <p> {this.state.printReciptData?.invoiceId}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3
                          style={{
                            fontFamily: 'Arial',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '18px',
                            color: '#000000',
                            display: 'inline',
                            minWidth: '127px',
                            margin: '0px',
                            verticalAlign: 'top',
                          }}
                          >Due Date: </h3>
                        </td>
                        <td>
                          <p
                            style={{
                            fontFamily: 'Arial',
                            fontWeight: '400',
                            fontSize: '15px',
                            lineHeight: '18px',
                            color: '#000000',
                            verticalAlign: 'top',
                            display: 'inline',
                            minWidth: '127px',
                            margin: '0px',
                            }}
                          > {moment(this.state.printReciptData?.dueDate).format("l")}            
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td 
                  style={{verticalAlign: 'top'}}
                  >
                  <h3
                  style={{
                  fontFamily: 'Arial',
                  fontWeight: '700',
                  fontSize: '15px',
                  lineHeight: 'normal',
                  color: '#000000',
                  display: 'inline-block',
                  minWidth: '127px',
                  margin: '0px',
                  }}
                >Balance</h3>
                  <h2 className="amount-number"   
                    >$
                    {this.numberWithCommas(
                      this.round2decimal(
                        Number(this.state.printReciptData?.total- this.state.printReciptData?.amountPaid)
                      )
                    )}</h2>
                </td>
              </tr>        
              <tr>
                <td style={{height: '32px'}}></td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <h3 style={{
                    fontFamily: 'Arial',
                    fontWeight: 700,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#000000',
                    margin: '0px 0px 4px 0px',
                  }}>Notes:</h3>
                  <p style={{
                    fontFamily: 'Arial',
                    fontWeight: 400,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#000000',
                    margin: '0px',
                  }}>
                    {this.state.printReciptData?.notes}
                  </p>
                </td>         
              </tr>    
              <tr>
                <td style={{height: "32px"}}></td>
              </tr> 
              <tr>
                <td colSpan={3}>
                  <table className="bill-description-table" width="100%" style={{borderCollapse: "collapse"}}>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>SKU</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Rate </th>
                        <th>Amount</th>
                      </tr>
                    </thead> 
                    {this.state.reArrangOrders?.length > 0 &&
                    sort_by_descending(this.state.reArrangOrders, "createdAt").map((item, index) => (
                      <>
                        {item.orderCreationType === "createCharge" ? (
                          <>
                            <tbody>
                              {item?.fixedItems?.length > 0 &&
                                item?.fixedItems.map(
                                  (orderItem, index) => (
                                    <>
                                      <tr>
                                        <td>{moment(item.createdAt).format(
                                          "l"
                                        )}</td>
                                        <td style={{whiteSpace: 'nowrap'}}>
                                          {orderItem?.fixedItem}
                                        </td>                                    
                                        <td>                      
                                          <div className="desc-list-items">
                                          <p>Ticket: <span>#{item?.ticketNumber}</span></p>
                                        </div> 
                                        </td>
                                        <td>{orderItem?.units}</td>
                                        <td>${this.numberWithCommas(
                                            Number(orderItem?.unitPrice) 
                                          )}</td>
                                        <td style={{whiteSpace: "nowrap"}}>
                                          $
                                          {this.numberWithCommas(
                                            Number(orderItem?.units) *
                                            Number(orderItem?.unitPrice)
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )}                            
                            </tbody>
                          </>
                        ) : (
                          <tbody>
                            <tr>
                              <td>
                                {moment(item.createdAt).format(
                                  "l"
                                )}
                              </td>
                              <td style={{whiteSpace: 'nowrap'}}>{customStartCase(item?.dumpRateType)}</td>
                              <td>                      
                                <div className="desc-list-items">
                                  <p>Ticket: <span>#{item?.ticketNumber}</span></p>
                                  <p>Inbound Weight = <span>{item?.inboundWeight}</span></p>
                                  <p>Outbound Weight = <span>{item?.outboundWeight}</span></p>
                                  <p>Net weight = <span>{item?.netWeight}</span></p>
                                </div> 
                              </td>                        
                              <td>
                                {item?.units}
                              </td>
                              <td>${item?.totalAmount}</td>
                              <td style={{whiteSpace: 'nowrap'}}>
                                $
                                {item?.totalAmount}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </>
                    ))}
                    <tfoot className="bill-description-footer-table" style={{borderTop: '1px solid #000'}}>
                      <tr>
                        <td style={{height: '20px'}}></td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{verticalAlign: 'top'}}>
                          <div style={{marginBottom: '4px'}}>
                            <span style={{ 
                            fontFamily: 'Arial',
                            fontWeight: '700',
                            fontSize: '15px',
                            lineHeight: '18px',
                            color: '#000000',
                            margin: '0px',
                            display: 'inline',
                            minWidth: '120px',
                            }}>Account Type:</span>
                            <span 
                            style={{ 
                              fontFamily: 'Arial',
                              fontWeight: '400',
                              fontSize: '15px',
                              lineHeight: '18px',
                              color: '#000000',
                              margin: '0px',
                              display: 'inline-block',
                            }}
                            > {_.startCase(this.state.printReciptData?.customer?.paymentTerms.toUpperCase())}</span>
                          </div>
                        </td>
                        <td colSpan={4} style={{verticalAlign: "top",}}>
                          <table style={{ marginLeft: 'auto',width:'auto'}}>
                            <tbody>
                              <tr>
                                <td style={{padding: '4px 0 4px 0'}}>
                                  <h3 
                                  style={{
                                    fontFamily: "Arial",
                                    fontWeight: "700",
                                    fontSize: '15px',
                                    lineHeight: '18px',
                                    color: "#000000",
                                    margin: "0px",
                                    display: "inline-block",
                                  }}>Total</h3>
                                </td>
                                <td style={{textAlign: 'right', padding: '4px 0px'}}>
                                  $
                                    {this.numberWithCommas(
                                      Number(this.state.printReciptData?.totalOrdersAmount)
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
     
      </>
    );
  }
}

export default PrintReciptComponent;
