// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'
// import { Empty } from 'antd'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()
export const FETCH_ORDER_Need_REVIEW = 'empire/WeighworkInvoice/FETCH_ORDER_Need_REVIEW'
export const FETCH_POSTED_ORDER="empire/WeighworkInvoice/FETCH_POSTED_ORDER"
export const FETCH_POSTED_INVOICES_COUNT="empire/WeighworkInvoice/FETCH_POSTED_INVOICES_COUNT"
export const SYNC_POSTED_ORDER = "empire/WeighworkInvoice/SYNC_POSTED_ORDER"
export const BATCH_PRINT_INVOICE = "empire/WeighworkInvoice/BATCH_PRINT_INVOICE"
export const UPDATE_NEEDREVIEW_ORDER="empire/WeighworkInvoice/UPDATE_NEEDREVIEW_ORDER"
export const SEND_INVOICE_EMAIL='empire/WeighworkInvoice/SEND_INVOICE_EMAIL'
export const FETCH_FINALIZED_INVOICE ='empire/WeighworkInvoice/FETCH_FINALIZED_INVOICE'
export const FETCH_RECIVING_PAYMENT_INVOICES='empire/WeighworkInvoice/FETCH_RECIVING_PAYMENT_INVOICES'
export const UPDATE_RECEIVE_PAYMENTS='empire/WeighworkInvoice/UPDATE_RECEIVE_PAYMENTS'
export const SEND_REMINDER_INVOICE_EMAIL='empire/WeighworkInvoice/SEND_REMINDER_INVOICE_EMAIL'
export const UPDATE_INVOICE_DETAIL='empire/WeighworkInvoice/UPDATE_INVOICE_DETAIL'
export const DOWNLOAD_BULK_INVOICE = 'empire/WeighworkInvoice/DOWNLOAD_BULK_INVOICE'
export const PAY_INVOICE='empire/WeighworkInvoice/PAY_INVOICE'
export const GET_INVOICE_FOR_REVIEW_AND_PAY_EMAIL='empire/WeighworkInvoice/GET_INVOICE_FOR_REVIEW_AND_PAY_EMAIL'
export const GET_INVOICE_DETAIL_BY_INVOICE_ID='empire/WeighworkInvoice/GET_INVOICE_DETAIL_BY_INVOICE_ID'
export const GET_CUSTOMER_CREDITS='empire/WeighworkInvoice/GET_CUSTOMER_CREDITS'
export const CHECK_PAYBLI_INTEGRATION='empire/WeighworkInvoice/CHECK_PAYBLI_INTEGRATION'
export const UPDATE_PAYMENT_INFO='empire/WeighworkInvoice/UPDATE_PAYMENT_INFO'
export const GET_PAYMENT_INVOICES_FOR_CUSTOMER='empire/WeighworkInvoice/GET_PAYMENT_INVOICES_FOR_CUSTOMER'
export const GET_INVOICE_DETAILS_BY_ID='empire/WeighworkInvoice/GET_INVOICE_DETAILS_BY_ID'
export const GET_INVOICE_DETAILS_BY_PR_ID='empire/WeighworkInvoice/GET_INVOICE_DETAILS_BY_PR_ID'
export const GET_PAYMENT_INVOICES_FOR_PDF='empire/WeighworkInvoice/GET_PAYMENT_INVOICES_FOR_PDF'
export const CUSTOMER_INVOICES_BY_CUSTOMER_ID='empire/WeighworkInvoice/CUSTOMER_INVOICES_BY_CUSTOMER_ID'
export const CUSTOMER_INVOICES_BY_PROJECT_ID='empire/WeighworkInvoice/CUSTOMER_INVOICES_BY_PROJECT_ID'
export const GET_QB_DETAILS='empire/WeighworkInvoice/GET_QB_DETAILS'
export const PRINT_PAYMENT_RECIEPT='empire/WeighworkInvoice/PRINT_PAYMENT_RECIEPT'
export const SET_SEARCH_TEXT='empire/WeighworkInvoice/SET_SEARCH_TEXT'
export const GET_PAYMENTS_BY_INVOICEID='empire/WeighworkInvoice/GET_PAYMENTS_BY_INVOICEID'
export const GET_PAYMENTS_BY_CUSTOMER='empire/WeighworkInvoice/GET_PAYMENTS_BY_CUSTOMER'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {},
  searchText: "",
  searchStartsWith: true
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SET_SEARCH_TEXT: {
      const { payload } = action
      return state
        .set('searchText', payload?.searchText)
        .set('searchStartsWith', payload?.searchStartsWith)
    }
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const setSearchText = (searchText, searchStartsWith) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: { searchText, searchStartsWith }
  }
}

export const fetchNeedReview = payload => {
  return {
    type: FETCH_ORDER_Need_REVIEW,
    payload: api.fetchNeedReview(payload)
  }
}

export const fetchPostedOrder= payload =>{
  return {
    type: FETCH_POSTED_ORDER,
    payload: api.fetchPostedOrder(payload)
  }
}

export const fetchPostedInvoicesCount= payload =>{
  return {
    type: FETCH_POSTED_ORDER,
    payload: api.fetchPostedInvoicesCount(payload)
  }
}

export const getInvoiceDetailsById = payload =>{
  return {
    type: GET_INVOICE_DETAILS_BY_ID,
    payload: api.getInvoiceDetailsById(payload)
  }
}

export const getInvoiceDetailsByPrId = payload =>{
  return {
    type: GET_INVOICE_DETAILS_BY_PR_ID,
    payload: api.getInvoiceDetailsByPrId(payload)
  }
}

export const fetchFinalizedInvoice= payload =>{
  return {
    type: FETCH_FINALIZED_INVOICE,
    payload: api.fetchFinalizedInvoice(payload)
  }
}

export const getRecivingPaymentInvoices= payload =>{
  return {
    type: FETCH_RECIVING_PAYMENT_INVOICES,
    payload: api.getRecivingPaymentInvoices(payload)
  }
}

export const movedInvoiceBulkAction= payload =>{
  return {
    type: FETCH_RECIVING_PAYMENT_INVOICES,
    payload: api.movedInvoiceBulkAction(payload)
  }
}

export const syncPostedOrder= payload =>{
  return {
    type: SYNC_POSTED_ORDER,
    payload: api.syncPostedOrder(payload)
  }
}
export const batchPrintInvoice = payload =>{
  return {
    type: BATCH_PRINT_INVOICE,
    payload: api.batchPrintInvoice(payload)
  }
}
export const syncBulkPostedOrder= payload =>{
  return {
    type: SYNC_POSTED_ORDER,
    payload: api.syncBulkPostedOrder(payload)
  }
}
export const updateInvoiceOrder= payload =>{
  return {
    type: UPDATE_NEEDREVIEW_ORDER,
    payload: api.updateInvoiceOrder(payload)
  }
}
export const updateInvoicDetail= payload =>{
  return {
    type: UPDATE_INVOICE_DETAIL,
    payload: api.updateInvoicDetail(payload)
  }
}
export const sendInvoiceEmail= payload =>{
  return {
    type: SEND_INVOICE_EMAIL,
    payload: api.sendInvoiceEmail(payload)
  }
}

export const sendReminderInvoiceEmail= payload =>{
  return {
    type: SEND_REMINDER_INVOICE_EMAIL,
    payload: api.sendReminderInvoiceEmail(payload)
  }
}

export const updateReceievePayment= payload =>{
  return {
    type: UPDATE_RECEIVE_PAYMENTS,
    payload: api.updateReceievePayment(payload)
  }
}

export const getQbDetails = payload =>{
  return {
    type: GET_QB_DETAILS,
    payload: api.getQbDetails(payload)
  }
}

export const getInvoiceDataForEmail= payload =>{
  return {
    type: GET_INVOICE_FOR_REVIEW_AND_PAY_EMAIL,
    payload: api.getInvoiceDataForEmail(payload)
  }
}

export const downloadBulkInvoice= payload =>{
  return {
    type:DOWNLOAD_BULK_INVOICE,
    payload: api.downloadBulkInvoice(payload)
  }
}
export const fetchCustomerOpenBalance= payload =>{
  return {
    type:DOWNLOAD_BULK_INVOICE,
    payload: api.fetchCustomerOpenBalance(payload)
  }
}

export const checkPabliIntegration= payload =>{
  return {
    type: CHECK_PAYBLI_INTEGRATION,
    payload: api.checkPabliIntegration(payload)
  }
}

export const updatePaymentInfo= payload =>{
  return {
    type: UPDATE_PAYMENT_INFO,
    payload: api.updatePaymentInfo(payload)
  }
}

export const payInvoice= payload =>{
  return {
    type: PAY_INVOICE,
    payload: api.payInvoice(payload)
  }
}

export const getCustomercredits= payload =>{
  return {
    type: GET_CUSTOMER_CREDITS,
    payload: api.getCustomercredits(payload)
  }
}

export const getInvoiceDetalByInvoiceId= payload =>{
  return {
    type: GET_INVOICE_DETAIL_BY_INVOICE_ID,
    payload: api.getInvoiceDetalByInvoiceId(payload)
  }
}

export const getCustomercreditsForCustomers= payload=>{
  return {
    type: GET_INVOICE_DETAIL_BY_INVOICE_ID,
    payload: api.getCustomercreditsForCustomers(payload)
  }
}

export const getReceivePaymentForCustomers= payload=>{
  return {
    type: GET_PAYMENT_INVOICES_FOR_CUSTOMER,
    payload: api.getReceivePaymentForCustomers(payload)
  }
}

export const getReceivePaymentForPdf= payload=>{
  return {
    type: GET_PAYMENT_INVOICES_FOR_PDF,
    payload: api.getReceivePaymentForPDF(payload)
  }
}

export const sendInvoiceEmail2= payload =>{
  return {
    type: SEND_INVOICE_EMAIL,
    payload: api.sendInvoiceEmail_2(payload)
  }
}

export const customerInvoicesByCustomerId= payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_CUSTOMER_ID,
    payload: api.customerInvoicesByCustomerId(payload)
  }
}

export const customerOpenBalanceInvoicesByCustomerId= payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_CUSTOMER_ID,
    payload: api.customerOpenBalanceInvoicesByCustomerId(payload)
  }
}

export const getCompanyById = payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_CUSTOMER_ID,
    payload: api.getCompanyById(payload)
  }
}

export const getProjectByProjectId = payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_PROJECT_ID,
    payload: api.getProjectByProjectId(payload)
  }
}

export const generateStatementPdf = payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_CUSTOMER_ID,
    payload: api.generateStatementPdf(payload)
  }
}

export const printPaymentReciept = payload =>{
  return {
    type: PRINT_PAYMENT_RECIEPT,
    payload: api.printPaymentReciept(payload)
  }
}

export const statementEmail = payload =>{
  return {
    type: CUSTOMER_INVOICES_BY_CUSTOMER_ID,
    payload: api.statementEmail(payload)
  }
}

export const getPaymentOfInvoice= payload=>{
  return {
    type: GET_PAYMENTS_BY_INVOICEID,
    payload: api.getPaymentsByInvoiceId(payload)
  }
}

export const getPaymentByCustomer= payload=>{
  return {
    type: GET_PAYMENTS_BY_CUSTOMER,
    payload: api.getPaymentByCustomer(payload)
  }
}
/***********************************
 * Epics
 ***********************************/


export const billingEpic = combineEpics()
