import React, { Component } from "react";
import NumberFormat from "react-number-format";
// import { DownarrowIcon, StateUpArrowIcon } from "../../../components/icons";
// import _ from "lodash";
import moment from "moment-timezone";
import NotFound from "../../NoRecords/component";

export default class PendingPayments extends Component {
  navigate = (orderId, is_create_charge_order) => {
    this.props.history.push({ pathname: is_create_charge_order ? `/create-charge/${orderId}` : `/pending-order-details/${orderId}` });
  };

  truncateText(text, maxLength) {
    if (!text || text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }

  render() {
    const { orders, 
      // sortOrder,
   setOrder } = this.props;
    // const areArrowsRemove = this.props.areArrowsRemove
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    return (
      <>
        {orders.length === 0 ? (
          <NotFound title="No Orders Found" />
        ) : (
          <div className="table-responsive">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th 
                  // onClick={() => setOrder("ticketNumber")}
                  >
                    Ticket # 
                    {/* {!areArrowsRemove && (sortOrder["ticketNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th
                  //  onClick={() => setOrder("plateNumber")}
                  >
                   License Plate Number # 
                   {/* {!areArrowsRemove && (sortOrder["plateNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th 
                  // onClick={() => setOrder("completedDate")}
                  >
                    {`Date & Time`} 
                    {/* {!areArrowsRemove && (sortOrder["completedDate"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th 
                  // onClick={() => setOrder("customer.companyName")}
                  >
                    Company Name 
                    {/* {!areArrowsRemove && (sortOrder["customer.companyName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th 
                  >
                    Cust Ref #
                  </th>
                  <th 
                  // onClick={() => setOrder("netWeight")}
                  >
                    Net Weight(lbs) 
                    {/* {!areArrowsRemove && (sortOrder["netWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th onClick={() => setOrder("tonnage")}>
                    Tonnage 
                    {/* {!areArrowsRemove && (sortOrder["tonnage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th 
                  // onClick={() => setOrder("yardage")}
                  >
                    Yardage 
                    {/* {!areArrowsRemove && (sortOrder["yardage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  <th 
                  // onClick={() => setOrder("totalAmount")}
                  >
                    Total Order Amount 
                    {/* {!areArrowsRemove && (sortOrder["totalAmount"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />)} */}
                  </th>
                  {/* <th onClick={() => setOrder("paymentType")}>
                    Payment Type {sortOrder["paymentType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {orders.map(order => {
                  // let paymentType =
                  //   order.paymentType && order.paymentType !== "" ? `- ${_.startCase(order.paymentType)}` : "";
                  // let btnClass = "btn btn-for-account";
                  // if (paymentType === "") {
                  //   paymentType = "ACCOUNT";
                  // } else {
                  //   paymentType = `COD ${paymentType}`;
                  // }
                  // if (paymentType === "COD - Credit Card") {
                  //   btnClass = "btn btn-for-cc";
                  // } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
                  //   btnClass = "btn btn-for-cash";
                  // } else if (paymentType === "COD - Terminal") {
                  //   btnClass = "btn btn-for-terminal";
                  // }
                  return (
                    <tr className="cursor-pointer" key={order._id} onClick={() => this.navigate(order.ticketNumber, order?.orderCreationType === "createCharge")}>
                      <td>{order.ticketNumber}</td>
                      <td>{order?.truck?.plateNumber ? order?.truck?.plateNumber : '-'}</td>
                      <td>{`${moment(order.createdAt)?.tz(tz)?.format("MM/DD/YY")} ${moment(order.createdAt)?.tz(tz)?.format(
                        "hh:mm a"
                      )}`}</td>
                      <td>{order.customer.displayName}</td>
                      <td><div>{this.truncateText(order.customerRefrence, 20) || '-'}</div></td> 
                      <td>
                        { order.netWeight > 0 ? <>
                       <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} />  lbs </> : "-" }
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.tonnage} />
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={Number(order.yardage).toFixed(2)} />
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.totalAmount}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}
