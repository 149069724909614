import React, { PureComponent } from "react";
import "./styles.scss";
import ReactModal from "react-modal";
import {  CloseIcon } from "../../components/icons";
import { Formik } from "formik";
import { driverSchema } from "../../utils/validations";
import CreateDriver from "../CreateOrder/partials/create-driver";
import NewDriver from "../CreateOrder/partials/new-driver";
export default class AddDriverComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      driverInitialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        state: "New York",
        licenseNumber: "",
        customerId: "",
      },
      licenseImageUrl: "",
      rotation: 0,
      isLicenseCamera: true,
      isForEdit : false,
      drivers: [],
      user: {},
      createDriverSidebar: true,
    };
  }
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.fetchMe();
    if(this.props.isForEdit){
      const {editDriverData} = this.props
      const {isForEdit } = this.props
      this.setInitialState({state:editDriverData})
      this.setState(
        {
          driverInitialValues:{
            ...this.state.driverInitialValues,...editDriverData,
          },
          licenseImageUrl:editDriverData.licenseImageUrl ? editDriverData.licenseImageUrl : "",
        }) 
        this.setState({isForEdit : isForEdit})
    }
    this.setState({})
  };
  async fetchMe() {
    const {
      value: { user },
    } = await this.props.fetchMe();
    this.setState({ user });
  }

  async setInitialState({
    state: { firstName, lastName, licenseNumber, formdata, state },
  }) {
    const { driverInitialValues } = this.state;
    if (formdata !== "" && formdata !== undefined) {
      const licenseImageUrl = await this.convertBlobToBase64(formdata);
      this.setState({ licenseImageUrl, isLicenseCamera: false });
    }
    this.setState({
      createDriverForm: true,
      createDriverSidebar: false,
      driverInitialValues: {
        ...driverInitialValues,
        firstName,
        lastName,
        licenseNumber,
        state: state ? state : "New York",
      },
      licenseImageUrl: this.state.licenseImageUrl,
    });
  }
  convertBlobToBase64(blob) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        var base64data = reader.result;
        return resolve(base64data);
      };
    });
  }

  submitDriverForm = async (values, { setErrors, setSubmitting }) => {
    try {
      const { licenseImageUrl, rotation } = this.state;
      const {
        customer: { _id },
      } = this.props;
      let url = "";
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
      if (licenseImageUrl && !urlPattern.test(licenseImageUrl)) {
        const uploadImage = await this.props.uploadImage(
          this.submitImages(await this.compressImage(licenseImageUrl, 0.7 , rotation))
        );
        url = uploadImage?.value?.url?.length > 0 ? uploadImage?.value?.url: licenseImageUrl ;
        if(url===licenseImageUrl && rotation!== 0){
          url = await this.compressImage(licenseImageUrl, 0.7 , rotation)     
        }
      }else{
        url = licenseImageUrl
      }
      const payload = {
        ...values,
        customerId: _id,
        licenseImageUrl: url,
        rotation,
      };
      if (this.props.isForEdit === true) {
        const {
          value: { status, message },
        } = await this.props.updateDriver(payload);
        if (status) {
          this.props.closeModal();
          this.setState({
            createDriverSidebar: false,
            isDriver: true,
            createDriverForm: false,
            licenseImageUrl: "",
            rotation: 0,
          });
          // const {
          //   value: {
          //     data: {drivers}
          //   }
          // } = await this.props.fetchCustomer(customerId);
        } else {
          setErrors({ state: message });
        }
      }else{
        const {
          value: { status, message },
        } = await this.props.createDriver(payload);
        if (status) {
          this.props.closeModal();
          this.setState({
            createDriverSidebar: false,
            isDriver: true,
            createDriverForm: false,
            licenseImageUrl: "",
            rotation: 0,
          });
          // const {
          //   value: {
          //     data: {drivers}
          //   }
          // } = await this.props.fetchCustomer(customerId);
        } else {
          setErrors({ state: message });
        }
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }

  compressImage = async (imageUrl, quality, rotation) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; 
      img.src = imageUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        if (rotation !== 0) {
          canvas.width = img.width;
                canvas.height = img.height;
                ctx.fillStyle = 'white'; 
                ctx.fillRect(0, 0, canvas.width, canvas.height); 
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((rotation * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
  
        const compressedImageUrl = canvas.toDataURL("image/jpeg", quality);
        resolve(compressedImageUrl);
      };
  
      img.onerror = (error) => {
        reject(error);
      };
    });
  };
  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };
  onTakePhoto = async (dataUri, field, field2) => {
    this.setState({ [field]: dataUri, [field2]: false });
  };

  changeLicenseCameraState = async()=>{
    this.setState({isLicenseCamera : true , isForEdit : false})

  }
  async onCropImage(blob) {
    const licenseImageUrl = await this.convertBlobToBase64(blob);
    this.setState({ licenseImageUrl });
  }

  render() {
    const { driverInitialValues, rotation, user,createDriverForm,createDriverSidebar, isForEdit} = this.state;
    if(isForEdit){
      this.setState({isLicenseCamera : false})
    }
    return (
      <>
        <ReactModal isOpen={true} contentLabel="Please Confirm">
          <div className="react-modal-dialog react-modal-dialog-centered invoice__modal">
            <div className="react-modal-header">
            <div className="btn-back-header justify-content-start">
                <button
                type="button"
                className="btn react-modal-close top-8-custom"
                onClick={() => { 
                  this.setState({ isModelOpen: false }, () => {
                    this.props.closeModal();
                  });
                }}
              >
                <CloseIcon />
              </button>
                <h5 className="react-modal-title">{this.props.isForEdit ? "Edit Driver":"Add New Driver"}</h5>
              </div>
            </div>
            <div className="react-modal-body">
              {createDriverForm && (
                <Formik
                  enableReinitialize
                  initialValues={driverInitialValues}
                  validationSchema={driverSchema}
                  onSubmit={(values, formikProps) =>
                    this.submitDriverForm(values, formikProps)
                  }
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      style={{ display: false ? "none" : "" }}
                    >
                      <CreateDriver
                        {...this.props}
                        isSubmitting={isSubmitting}
                        values={values}
                        handleChange={handleChange}
                        onTakePhoto={(dataUri) =>
                          this.onTakePhoto(
                            dataUri,
                            "licenseImageUrl",
                            "isLicenseCamera"
                          )
                        }
                        licenseImageUrl={this.state.licenseImageUrl}
                        isLicenseCamera={this.state.isLicenseCamera}
                        onClose={() => {
                          this.props.closeModal();
                        }}
                        rotation={rotation}
                        setRotation={() =>
                          this.setState({ rotation: rotation + 45 })
                        }
                        onCrop={(img) => this.onCropImage(img)}
                        changeLicenseCameraState = {this.changeLicenseCameraState}
                        isForEdit={this.state.isForEdit}
                        fromPopUp={true}
                      />
                    </form>
                  )}
                </Formik>
              )}
              {createDriverSidebar && isForEdit === false && (
                <NewDriver
                  onClose={() =>
                    {this.setState({
                      isCreateDrawerOpen: true,
                      isTruck: false,
                      isCompany: false,
                      isDriver: true,
                      createDriverSidebar: false,
                      rotation: 0,
                    })
                    }
                  }
                  setInitialState={({ state }) =>
                    this.setInitialState({ state })
                  }
                  user={user}
                  driverDetails={this.props.driverDetails}
                  fromPopUp={true}
                />
              )}
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}
