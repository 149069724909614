import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;


export const fetchContainers = () => {
  return fetch(`${HOSTNAME}/containers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
