import React, { PureComponent } from 'react';
// import InputMask from "react-input-mask";
import { CloseIcon } from '../../../components/icons';
import ReactModal from 'react-modal';
import '../styles.scss';
import 'react-toastify/dist/ReactToastify.css';
export default class DeleteScaleComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = { 
      isDisable:false,
      PleaseWait:false,
    };
  }
  
  deleteScale = async () => {
    this.setState({isDisable:true,PleaseWait:true})
    let payload={
      id:this.props.ScaleId
    }
    console.log(payload)
    const {
      value: { status },
    } = await this.props.DeleteScale(payload);
    if (status) {
       setTimeout(()=>{
        this.setState({isDisable:false})
         this.props.onCloseClick();
       }, 500);
      
    } 
  };

  render() {
    return (
      <ReactModal ariaHideApp={false}  onRequestClose={this.props.onCloseClick}  isOpen={this.props.isDeleteScaleModelOpen}>
        <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Delete {this.props.scaleName}</h5>
            <button type="button" className="btn react-modal-close" onClick={this.props.onCloseClick}>
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="row">
              <div className="col-md-12">
                <p>Are you sure you want to delete this? This can not be undone.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button disabled={this.state.isDisable} onClick={()=>this.deleteScale()} className="btn btn-red btn-sm delete-btn">
                {this.state.PleaseWait ? "Please wait..." : "Yes, Delete"} 
                </button>
              </div>
              
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
