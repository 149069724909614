import React, { PureComponent } from "react";
import { CloseIcon } from "../../../components/icons";
import _ from "lodash";
import NumberFormat from "react-number-format";
import { isRoundOff, numberFormat, numberFormatLbs, roundOffDifference,customStartCase } from "../../../constants/common";

class DeleteUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dumpRateType: "",
      dumpRate: 0,
      showYardageValue: false,
      updateProgress: false,
      DumpRatesData: {},
      capoutPrice:0,
      isCapoutPrice: false,
      paymentType: "",
      yardage : "",
      isError: false,
      errorMessage: "",
      fuelSurchargeTotal:0
    };
  }

  componentDidMount() {
    const { order } = this.props;
    this.fetchPricingTier(order?.customer?.pricingTier)
    this.setState({
      dumpRateType: order.dumpRateType,
      dumpRate: order.dumpRate,
      paymentType: order.paymentType,
      yardage : order.yardage,
      showYardageValue: order.dumpRateType.indexOf("Yard") !== -1 ? true : false
    });
  }

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  fetchPricingTier = async (pricingTier) => {
    let additionalItems = []
    let DumpRatesData = {}
    const {
      value: { data, status },
    } = await this.props.getPricingTierById(pricingTier);
    if (status) {
      const dumpRatesObject = data?.pricing?.dumpRates
      const dumratesArray = Object.keys(data?.pricing?.dumpRates)
      dumratesArray.forEach((item) => {
        if (dumpRatesObject[item] > 0) {
          DumpRatesData[`${item}`] = dumpRatesObject[item]
        }
      });
      let Add = data?.pricing?.additionalItems
      Add.forEach((item) => {
        if (item?.value > 0) {
          additionalItems.push(item)
        }
      })
      if (Number(data?.pricing?.capoutPrice) > 0) {
        this.setState({ isCapoutPrice: true })
      }
      this.setState({DumpRatesData: this.sortPricingTier(dumpRatesObject),pricingTierData:data,capoutPrice:Number(data?.pricing?.capoutPrice)})
    }
  }

  sortPricingTier = (data) => {
    // Convert the object into an array of [key, value] pairs
    const filteredDataPerTon = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerTon") && value !== null);
    const sortedDataPerTon = Object.fromEntries(filteredDataPerTon)
    const filteredDataPerYard = Object.entries(data).filter(([key, value]) => key.startsWith("pricePerYard") && value !== null);
    const sortedDataPerYard = Object.fromEntries(filteredDataPerYard)
    const filteredData = Object.entries(data).filter(([key, value]) => !key.includes('pricePer') && value !== null);
    const sortedDataWithoutPer = Object.fromEntries(filteredData)
    const sortedDataTonYard = Object.assign({ ...sortedDataPerTon, ...sortedDataPerYard })
    const sortedData = Object.assign({ ...sortedDataTonYard, ...sortedDataWithoutPer })
    return sortedData;
  }

  onChangeDumpRate = e => {
    const {
      order: {
        dumpRateType,
        ...order
      }
    } = this.props;
    const DumpRatesData=this.state.DumpRatesData
    const value = e.target.value;
    let dumpRate = DumpRatesData[value];
    if (value === dumpRateType) {
      dumpRate = order.dumpRate;
    }
    this.setState({ dumpRateType: value, dumpRate }, () => {
      if (value.indexOf("Yard") !== -1) {
        this.setState({ showYardageValue: true });
      }
      if (value.indexOf("Yard") === -1) {
        this.setState({ showYardageValue: false });
      }
    });
  };

calculateTotalAmount() {
  const {
    order: { netWeight, tonnage, totalAmount, dumpRateType,additionalItems,isEnvironmentalLoadFee, ...order }
  } = this.props;
  const { showYardageValue} = this.state;
  const yardage = showYardageValue ? (this.state.yardage) : ""
  const dumpRate = this.state.dumpRate ? parseFloat(this.state.dumpRate) : 0;
  let total = showYardageValue ? dumpRate * yardage : dumpRate * tonnage;
  const capoutPrice = this.round2decimal(
    this.round2decimal(yardage) *
      this.round2decimal(this.state.capoutPrice)
  );
  // const capoutPerYardPrice = capoutPrice / parseFloat(yardage);
  const tonnageCap = capoutPrice / dumpRate;
  const tonnageDifference = tonnageCap - tonnage;
  const isCapped = tonnageDifference < 0 ? "Yes" : "No";
  // const isCapoutPrice = capoutPerYardPrice ? true : false;
  total = isCapped === "Yes" && this.state.capoutPrice  ? capoutPrice : total;
  // let isMinimumPrice= order?.pricingTierObj?.minimumPrice > total
  const minimumPrice = isNaN(Number(order?.pricingTierObj?.minimumPrice)) ? 0 : Number(order?.pricingTierObj?.minimumPrice)
  let isMinimumPrice= total > 0 ? Number(order?.pricingTierObj?.minimumPrice) > total : false
  const totalActualAmount = total;
  total = minimumPrice !== 0  && total > 0 ? (minimumPrice < total ? total : minimumPrice) : total;
  total = parseFloat(total);
  additionalItems.forEach(({ value, quantity }) => {
    total = total + ( parseFloat(value) * parseFloat(quantity));
  });
  if (order?.company?.isEnvironmentalLoadFee) {
  total += parseFloat(order.environmentalLoadFeeAmount);
  }
  if(order.isFuelSurchardeFee) {
  const fuelSurchargeTotal = this.round2decimal(
  order?.company?.fuelSurchardeFeeAmount / 100 *
  this.round2decimal(total));
  total += parseFloat(fuelSurchargeTotal)
  this.setState({fuelSurchargeTotal:fuelSurchargeTotal})
} 
  if(order?.company?.chargeStripeCardFees){
  total+= order.stripeCardFees
  }
  total = isNaN(total) ? 0 : this.round2decimal(total);
  total = isNaN(total) ? 0 : isRoundOff(total)(order?.isRoundOffAmount);
  const roundedDifferenceAmount = isNaN(total) ? 0 : roundOffDifference(total)(order?.isRoundOffAmount);
  
  return {
    netWeight,
    tonnage,
    isMinimumPrice,
    dumpRate,
    total: isNaN(total) ? 0 : total,
    capoutPrice: isNaN(capoutPrice) ? 0 : capoutPrice,
    tonnageCap: isNaN(tonnageCap) ? 0 : tonnageCap,
    tonnageDifference: isNaN(tonnageDifference) ? 0 : tonnageDifference,
    isCapped,
    totalActualAmount: isNaN(totalActualAmount) ? 0 : totalActualAmount,
    roundedDifferenceAmount
  };
}

  renderTotalAmount({ total, totalActualAmount, isRoundOffAmount }) {
    if (totalActualAmount) {
      return (
        <>
          <hr />
          <li className="total__order-amount align-items-start">
            <span>Total Order Amount </span>
            <div>
            {totalActualAmount < total ? (
              <>
                <div className="text-center">
                  ${numberFormat(total)}
                </div>
              </>
            ) : (
              <div className="text-center">${numberFormat(total)}</div>
            )}
            <div className="rounded-text">
            {isRoundOffAmount && <small><pre>(Rounded)</pre></small>}
            </div>
            </div>
          </li>
        </>
      );
    } else {
      return (
        <>
          <hr />
          <li className="total__order-amount align-items-start">
            <span>Total Order Amount </span>
            <div>
            {totalActualAmount < total ? (
              <>
                <div className="text-center">${numberFormat(total)}</div>
              </>
            ) : (
              <div className="text-center">${numberFormat(totalActualAmount)}</div>
            )}
            <div className="rounded-text">
            {isRoundOffAmount && <small><pre>(Rounded)</pre></small>}
            </div>
            </div>
          </li>
        </>
      );
    }
  }

  handleSubmit = async () => {
    if (this.state.showYardageValue && (this.state.yardage <= 0 || isNaN(this.state.yardage) || this.state.yardage === "")) {
      this.setState({
        isError: true,
        errorMessage: "Enter a valid yardage value.",
        updateProgress: false,
      });
      return;
    } else {
      this.setState({
        isError: false,
        errorMessage: "",
      });
    }
    this.setState({ updateProgress: true });
    const { updateTotalAmount, order } = this.props;
    const { dumpRateType , fuelSurchargeTotal ,  yardage} = this.state;
    let newYardage = parseFloat(yardage);
    if (isNaN(newYardage) || newYardage < 0 || newYardage === "") {
      newYardage = 0;
    }
    const { total, dumpRate, tonnageCap, tonnageDifference, capoutPrice , isMinimumPrice , roundedDifferenceAmount} = this.calculateTotalAmount();
    const payload = {
      totalAmount: total,
      roundedDifferenceAmount,
      dumpRate,
      dumpRateType,
      tonnageCap,
      yardage : newYardage,
      fuelSurchargeTotal,
      isMinimumPrice,
      tonnageDifference,
      capoutPrice,
      id: order._id
    };
    await updateTotalAmount(payload);
    this.props.fetchOrder();
    this.props.closeModal();
  };

  handleYardageChange =async (yardage) => {
    if (this.state.isError && this.state.showYardageValue && yardage > 0) {
      this.setState({ isError: false, errorMessage: '' });
    }
   this.setState ({yardage : yardage})
  }

  render() {
    const { order } = this.props;
    const { dumpRateType, dumpRate, updateProgress , fuelSurchargeTotal , isError , errorMessage } = this.state;
    const {
      total,
      capoutPrice,
      tonnageCap,
      tonnageDifference,
      isCapped,
      totalActualAmount
    } = this.calculateTotalAmount();
    return (
      <div className="react-modal-dialog react-modal-dialog-centered payment__breakdown-modal">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Payment Breakdown</h5>
          <button type="button" className="btn react-modal-close" onClick={this.props.closeModal}>
            <CloseIcon />
          </button>
        </div>
        <div className="react-modal-body">
          <div className="payment__breakdown">
            <div className="row">
              <div className="col-md-12">
                <ul className="card__scale-list">
                  <li>
                    <span>Inbound Weight</span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.inboundWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Outbound Weight </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.outboundWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Net Weight</span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} /> lbs
                    </span>
                  </li>
                  <li>
                    <span>Tonnage </span>
                    <span>
                      <NumberFormat thousandSeparator={true} displayType={"text"} value={order.tonnage} /> tons
                    </span>
                  </li>
                  <li>
                  <span>Yardage{this.state.showYardageValue}</span>
                  <span>
                  <NumberFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.state.yardage}
                  name="yardage"
                  onValueChange={(values) => this.handleYardageChange(values.value)}
                  className="form-control form-control-sm material-textfield-input"
                  style={{maxWidth:"120px"}}
                  />
                  {this.state.showYardageValue && isError && (
                    <div className="invalid-feedback d-block">
                      {errorMessage}
                    </div>
                  )}
                  </span>
                  </li>
                  <li>
                    {/*<span>Dump Rate </span>*/}
                    <div className="form-group material-textfield">
                      <select
                        value={dumpRateType}
                        name="dumpRate"
                        className="form-control custom-select w-150 material-textfield-input pr-22"
                        onChange={this.onChangeDumpRate}
                      >
                        {Object.keys(this.state.DumpRatesData).map(key => {
                          if (key !== "defaultOption" && key !=="capoutPrice" && key!=="minimumPrice" && (this.state?.DumpRatesData[key] ?? '').toString().length > 0) {
                            return (
                              <option key={key} value={key}>
                                {customStartCase(key)}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </select>
                      <label className="material-textfield-label">
                        Dump Rate<span>*</span>
                      </label>
                    </div>
                    <span>
                      <NumberFormat
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        value={dumpRate}
                      />
                    </span>
                  </li>
                  {_.get(order, "additionalItems", []).length !== 0 && (
                        <>
                          {_.get(order, "additionalItems", []).map(({ label, value, quantity }) => {
                            return (
                              <li key={label}>
                                <span>
                                  {label} ({quantity}) (${value}/unit)
                                </span>
                                <span>${numberFormat(value * quantity)}</span>
                              </li>
                            );
                          })}
                        </>
                      )}
                      {_.get(order, "isEnvironmentalLoadFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <span>Environmental Load Fee</span>
                            <span>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                value={order.environmentalLoadFeeAmount}
                              />
                            </span>
                          </li>
                        </React.Fragment>
                      )}
                      {_.get(order, "isFuelSurchardeFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <span>Fuel Surcharge</span>
                            <span>
                              <NumberFormat
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                // value={order.fuelSurchargeTotal}
                                value={fuelSurchargeTotal}
                              />
                            </span>
                          </li>
                        </React.Fragment>
                      )}
                  {this.state.capoutPrice !== 0 && (
                    <>
                      <hr />
                      <li>
                        <span>Capped </span>
                        <span>{isCapped} </span>
                      </li>
                      <li>
                        <span>Per Ton Price </span>
                        <span>{numberFormat(dumpRate)} </span>
                      </li>
                      <li>
                        <span>Tonnage Cap</span>
                        <span>{numberFormatLbs(tonnageCap)} tons </span>
                      </li>
                      <li>
                        <span>Capout Price</span>
                        <span>${numberFormat(capoutPrice)} </span>
                      </li>
                      <li>
                        <span>Tonnage Difference</span>
                        <span>{numberFormatLbs(tonnageDifference)} tons </span>
                      </li>
                    </>
                  )}
                   {_.get(order, "chargeStripeCardFees", false) && (order.paymentType === "credit-card" || order?.paymentType === "terminal") && (
                        <>
                          <hr />
                          <li className="">
                            <span>Handling Fee</span>
                            {numberFormat(order.stripeCardFees)}
                          </li>
                          <li className="">
                            <span>Order Amount </span>
                            {  order?.totalActualAmount ? numberFormat(order?.totalActualAmount) : numberFormat(order.totalAmount - order.stripeCardFees)}
                          </li>
                        </>
                      )}
                  {this.renderTotalAmount({ total, totalActualAmount, isRoundOffAmount: order?.isRoundOffAmount })}
                </ul>
              </div>
              <button
                disabled={updateProgress}
                className="btn btn-dark btn-lg w-100 btn-addlead"
                onClick={this.handleSubmit}
              >
                {updateProgress ? "Please wait..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteUser;
