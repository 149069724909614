import { connect } from 'react-redux'
import ReceivePaymentComponent from './component'
import {getRecivingPaymentInvoices,fetchCustomerOpenBalance,updateReceievePayment,getCustomercredits} from '../../store/Billing/duck'
import { uploadImage,fetchMe} from '../../store/user/duck'
import { 
  fetchCustomer,
  addCard,
  updateCustomer,
  chargeCustomer,
} from '../../store/customer/duck'
import { getPaybliIntegration ,
   getPayabliCustomerData,
  // makeTransaction
} from '../../store/paybli_integrations/duck';


const ReceivePaymentContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getRecivingPaymentInvoices,
    uploadImage,
    updateReceievePayment,
    getCustomercredits,
    fetchCustomerOpenBalance,
    addCard,
    fetchCustomer,
    getPaybliIntegration,
    getPayabliCustomerData,
    updateCustomer,
    chargeCustomer,
    fetchMe
  }
)(ReceivePaymentComponent);

export default ReceivePaymentContainer
