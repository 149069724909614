import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const createDriver = data => {
  return fetch(`${HOSTNAME}/driver`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const driverDetails = data => {
  return fetch(`${HOSTNAME}/textract`, {
    method: "POST",
    body: data
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const numberPlateDetails = data => {
  return fetch(`${HOSTNAME}/numberPlate`, {
    method: "POST",
    body: data
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
