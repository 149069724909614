
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { CloseIcon } from '../components/icons';

class EmailAlert extends PureComponent {

  render() {
    return (
      <ReactModal
          isOpen={this.props?.isClose}
          onRequestClose={this.props?.onCloseAlert}

        >
        <div className="react-modal-dialog react-modal-dialog-centered">
          <div className="react-modal-header">
            <div>
              <h5 className="react-modal-title">Email alert</h5>
            </div>
            <button className='btn react-modal-close'onClick={this.props?.onCloseAlert}> <CloseIcon /></button>
          </div>
          <div className="react-modal-body">
          <p>This customer does not have an email address stored on their account.</p>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default EmailAlert;
