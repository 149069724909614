import React, { PureComponent } from "react";
import "../styles.scss";
import ReactModal from "react-modal";
import { CloseIcon } from "../../../components/icons";
import moment from "moment-timezone";
import { message } from "antd";
import { PrintPaymentRecepit, bottomContent } from "../../../utils/printInvoiceReceipt";
import { format_address,paymentMethodPosted, sort_by_descending } from "../../../constants/common";
import _ from "lodash";

// import { Spin } from "antd";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// import { LoadingOutlined } from "@ant-design/icons";
export default class PaymentReceiptDetailsComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess();
    super(props);
    this.state = {
      toggleMenu: false,
      // activeUrl: "",
      modelData: {},
      reRender: false,
      isPrintRecipt: false,
      invoiceEmailPleaseWait: false,
      isModelOpen: false,
      pleaseWait: false,
      reArrangOrders: [],
      isTicketInvoiceLoader: false,
      ticketLoadingStates: [],
      PO: "",
      API_URL: process.env.REACT_APP_API_HOSTNAME,
      manifest: "",
      isSisterCompany: false,
      sisterCompanyData: {},
      showModal: false,
      tz,
    };
  }
  closeModalAlert = () => {
    this.setState({ showModal: false });
  };
  componentDidMount = async () => {
    this.filteredPRsForInvoice();
    const company = this.props.PaymentmodelData.company[0] ?? this.props.PaymentmodelData.company;
    if (company?.sisterCompany) {
      const {
        value: { status, data },
      } = await this.props.fetchSetting(
        company?.sisterCompany
      );
      if (status) {
        this.setState({ sisterCompanyData: data, isSisterCompany: true });
      }
    }
    this.setState({
      modelData: this.props.PaymentmodelData,
      PO: this.props.PaymentmodelData?.PO,
      manifest: this.props.PaymentmodelData?.manifest,
    });
    this.setState({
      invoiceEmailPleaseWait: this.props.PrintDropDown,
      isModelOpen: this.props.isModelOpen,
    });
    this.setState({ isPrintRecipt: this.props.printByDropDown });
  };

  filteredPRsForInvoice = async () => {
    const payload = {
      customerId: this.props.PaymentmodelData?.customerId,
    };
    const {
      value: {
        data: { paymentInvoices },
      },
    } = await this.props.getReceivePaymentForCustomers(payload);
    const filteredPR = paymentInvoices?.filter((items) =>
      items?.isRecivePaymentInvoicesId?.includes(
        this.props.PaymentmodelData?.invoiceId
      )
    );
    this.props.PaymentmodelData.invoicePRs = filteredPR;
    this.setState({ modalData: this.props.PaymentmodelData });
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };
  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  onChangeFile = async (e) => {
    // const file = e.target.files[0];
    const modelData = this.state.modelData;
    const imageShow = URL.createObjectURL(e.target.files[0]);
    this.getBase64(e.target.files[0], (result) => {
      modelData.customAttachments.push(result);
      modelData.showAttachments.push(imageShow);
      this.setState({ modelData: modelData }, () => {
        this.setState({ reRender: !this.state.reRender });
      });
    });
    this.setState({ reRender: !this.state.reRender });
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }

  numberWithCommas = (x) => {
    x = parseFloat(x);
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  sendInvoice2 = async () => {
    const finalizedInvoiceData = this.props.AllData;
    const {customer} = this.props.PaymentmodelData;
    const payloadforFetchData = {
      invoiceId: this.props.PaymentmodelData?.paymentReceiptId
    }
    const { value: { data } } = await this.props.getReceivePaymentForPdf(payloadforFetchData);
     const newdata = data.paymentInvoices[0]
    const companyData = this.props.PaymentmodelData.company[0] ?? this.props.PaymentmodelData.company;
    const billingEmailAddress = this.props.PaymentmodelData?.customer?.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress?.trim() === "") {
      this.setState({ showModal: true });
    }
    else{
    const billingEmail = finalizedInvoiceData?.customer?.secondaryBillingContacts ? 
    finalizedInvoiceData?.customer?.secondaryBillingContacts?.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: finalizedInvoiceData?.customer?.customerName,
        customerId: finalizedInvoiceData?.customer?._id,
      },
      customer: {
        address: customer?.address,
        phone: customer?.phone,
        city: customer?.city,
        zip: customer?.zip,
        state: customer?.state,
        email: customer?.email,
        billingEmailAddress:
          [customer?.billingEmailAddress,...billingEmail],
        companyName: customer?.displayName,
      },
      invoice: {
        invoiceId: finalizedInvoiceData?.invoiceId,
        dueDate: finalizedInvoiceData?.dueDate,
        invoiceData: finalizedInvoiceData,
        total: finalizedInvoiceData?.total,
        balance:
          Number(finalizedInvoiceData?.total) -
          Number(finalizedInvoiceData?.amountPaid),
        amountPaid: finalizedInvoiceData?.amountPaid,
      },
      Data: { ...newdata, paymentMethodType:paymentMethodPosted({customerReceivePayments:{...newdata}}) },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail2(payload);
    if (status) {
      const finalizedInvoiceData = this.state.finalizedInvoiceData;
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        reRender: !this.state.reRender,
      });
      message.success(showMessage);
    }}
  };

  closePrintRecipt = () => {
    if (this.props.printByDropDown) {
      this.setState({ isPrintRecipt: false });
      this.props.closeModel();
    } else {
      this.setState({ isPrintRecipt: false });
    }
  };

  updateInvoiceDetail = async () => {
    const { modelData } = this.state;
    modelData.PO = this.state.PO;
    modelData.manifest = this.state.manifest;
    // this.props.updateinvoiceDetailData(modelData)
    this.setState({ pleaseWait: true });
    const { uploadImage } = this.props;
    let attachments = [];
    let finalAttachments = [];
    if (
      modelData?.customAttachments &&
      modelData?.customAttachments.length > 0
    ) {
      modelData.customAttachments.forEach((item) => {
        attachments.push(uploadImage(this.submitImages(item)));
      });
    }
    const attachImage = await Promise.all(attachments);
    const attachImages = attachImage.map((item) => {
      return item.value.url;
    });
    finalAttachments = modelData?.attachments.concat(attachImages);
    const payload = {
      _id: modelData._id,
      notes: modelData.notes,
      attachments: finalAttachments,
      PO: this.state.PO,
      manifest: this.state.manifest,
    };

    const {
      value: { status },
    } = await this.props.updateInvoicDetail(payload);
    if (status) {
      this.setState({ pleaseWait: false }, () => {
        this.props.closeModel();
      });
    }
  };

  cancleAttachments = (attachmentsIndex, type) => {
    const modelData = this.state.modelData;
    if (type === "FromDB") {
      modelData.attachments.splice(attachmentsIndex, 1);
    } else {
      modelData.showAttachments.splice(attachmentsIndex, 1);
      modelData.customAttachments.splice(attachmentsIndex, 1);
    }
    this.setState({ modelData: modelData, reRender: !this.state.reRender });
  };

  openBillingRecieptForPR = async (index, isView=false) => {
    let printWindow = "";
    let postedOrderData = this.state.modelData;
    postedOrderData.index = index;
    postedOrderData.company =  this.props.PaymentmodelData.company[0] ?? this.props.PaymentmodelData.company;
    const payload = {
      invoiceId: this.props.PaymentmodelData?.paymentReceiptId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    const company = data?.company ? data?.company : postedOrderData?.company?.[0] ? postedOrderData?.company?.[0] : this.props.PaymentmodelData.company[0];  
    Object.assign(company, { sisterCompanydetails: this.state.isSisterCompany ? this.state.sisterCompanyData :undefined });
    const invoices = newdata && newdata.invoices;
    // const customer = postedOrderData && postedOrderData.customer;
    // const creditAmount =
    //   invoices &&
    //   invoices?.map((item) => (item.payment !== null ? item.creditAmount : 0));
    // const result =
    //   creditAmount &&
    //   creditAmount.reduce(
    //     (accumulator, currentValue) => accumulator + currentValue,
    //     0
    //   );
    let body1 = ""
        body1 += bottomContent();
    const printPromises = [postedOrderData].map((items, i) => {
      return new Promise((resolve, reject) => {
        body1 += PrintPaymentRecepit(items,newdata,company,invoices,true,isView)
        printWindow += body1;
        resolve()
      });
    });

    if (!isView) {
      const {value} = await this.props.printPaymentReciept({html:printWindow,ticketNumber:postedOrderData.invoiceId})
  if (value) {
    const url = URL.createObjectURL(value);
    const printWindow = window.open(url, '_blank');
      printWindow.onload = function() {
        printWindow.print();
      };
    }
    } else {
      Promise.all(printPromises).then(() => {}).finally(()=>{
        let printInvoice = window.open(`invoice`, "_blank");
        if(printInvoice){
        printInvoice.document.write(printWindow);
        printInvoice.document.close();
        printInvoice.onload = function () {
          if(!isView){
            printInvoice.print();
          }
        };
      }
    }) 
    }
  };

  getOrderInvoicePdf = async (index, ticketnumber) => {
    this.setState((prevState) => {
      const updatedLoadingStates = [...prevState.ticketLoadingStates];
      updatedLoadingStates[index] = true;
      return { ticketLoadingStates: updatedLoadingStates };
    });
    const { value } = await this.props.getOrderInvoicePdf(ticketnumber);
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, "_blank");
      printWindow.onload = function () {
        printWindow.print();
      };
    }
    this.setState((prevState) => {
      const updatedLoadingStates = [...prevState.ticketLoadingStates];
      updatedLoadingStates[index] = false;
      return { ticketLoadingStates: updatedLoadingStates };
    });
  };

  printPR = async (index, isView = false) => {
    let printWindow = "";
    let postedOrderData = this.state.modelData;
    const payload = {
      invoiceId: postedOrderData.invoicePRs[index]?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    const company = postedOrderData.company[0];
    Object.assign(company, { sisterCompanydetails: this.state.isSisterCompany ? this.state.sisterCompanyData :undefined });
    const invoices = newdata && newdata.invoices;
    const printPromises = [postedOrderData].map((items, i) => {
      return new Promise((resolve, reject) => {
        const body1 = PrintPaymentRecepit(
          items,
          newdata,
          company,
          invoices,
          true
        );
        printWindow += body1;
      });
    });

    Promise.all(printPromises).then(() => {});
    let printInvoice = window.open(`invoice`, "_blank");
    printInvoice.document.write(printWindow);
    if (!isView) {
      printInvoice.print();
    }
  };

  render() {
    const {  customer } = this.props.PaymentmodelData;
    const { invoices } = this.props.PaymentmodelData;
    return (
      <>
        <>
          <ReactModal
            isOpen={this.state.isModelOpen}
            onRequestClose={() => {
              this.setState(() => {
                this.props.closeModels();
              });
            }}
            contentLabel="Please Confirm"
          >
            <div className="react-modal-dialog react-modal-dialog-centered invoice__modal">
              {!this.state.isPrintRecipt && (
                <>
                  <div className="react-modal-header">
                    <div className="btn-back-header">
                      <h5 className="react-modal-title">Payment {this.props.PaymentmodelData?.paymentReceiptId}</h5>
                      <div className="page-header-btn-grp d-flex align-items-center">
                        <button
                          type="button"
                          onClick={() => {
                            this.sendInvoice2();
                          }}
                          className="btn btn-dark mr-2"
                        >
                          {this.state.invoiceEmailPleaseWait
                            ? "Please wait ..."
                            : "Send Receipt"}{" "}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            this.openBillingRecieptForPR(this.props.PaymentmodelData);
                            // this.props.navigate(`/billing-receipt/${this.props.modelData.invoiceId}`)
                            // this.setState({ isPrintRecipt: true });
                          }}
                          className="btn btn-dark"
                        >
                          Print Receipt{" "}
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        this.setState(() => {
                          this.props.closeModels();
                        });
                      }}
                      type="button"
                      className="btn react-modal-close"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="react-modal-body">
                    <div className="invoice__address">
                      <ul className="list address__list">
                        <li className="items">
                        <h3 className="address__title">{customer?.displayName}</h3>
                        <h3 className="address__title">{customer?.billingContactName}</h3>
                          <p className="address">
                            {customer?.address ? <>{customer?.address} <br /></> : ""}
                            {format_address(customer) ? <>{format_address(customer)} <br /></> : ""}
                            {customer?.phone ? <>{this.phoneFormate(customer?.phone)} <br /></> : ""}
                            {customer?.billingEmailAddress ? <>{customer?.billingEmailAddress} <br /></> : ""}
                          </p>
                      </li>
                        <li className="items">
                          <p className="address">
                            Date: {moment(this.props.PaymentmodelData?.createdAt)
                                      ?.tz(this.state?.tz)
                                      ?.format("MM/DD/YY h:mm A")}<br />
                            Payment Method: {_.startCase(paymentMethodPosted({customerReceivePayments:{...this.props.PaymentmodelData}}))}
                            <br />
                            Payment Status: {_.startCase(this.props.AllData?.paymentStatus)}
                            <br />
                          </p>
                        </li>
                        <li className="items">
                          <div className="balance__due">
                            <h4>Payment Amount</h4>
                            <h1 className="balance">
                              $
                              {this.numberWithCommas(
                                Number(
                                  this.props.PaymentmodelData?.stripeCardFees > 0 
                                  && this.props.PaymentmodelData?.isFromReceivePayment
                                  ? this.props.PaymentmodelData?.amountReceive 
                                  : this.props.PaymentmodelData?.amountToApply
                                )
                              )}
                            </h1>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="table-responsive">
                      <table className="table amount-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Invoice #</th>
                            <th>Original Amount</th>
                            <th>Open Balance</th>
                            <th>Payment Applied </th>
                          </tr>
                        </thead>
                        {invoices.length > 0 &&
                          sort_by_descending(
                            invoices,
                            "createdAt"
                          )?.map((item) => (
                            <>
                              <tbody className="dumping__services-table">
                                <tr>
                                  <td>
                                    {moment(item.createdAt)
                                      ?.tz(this.state?.tz)
                                      ?.format("l")}
                                  </td>
                                  <td>{item?.invoiceId}</td>
                                  <td>${this.numberWithCommas(Number(item?.total))}</td>
                                  <td>
                                    ${this.numberWithCommas(Number(item?.balacnce))}
                                  </td>
                                  <td>
                                    $
                                    {this.numberWithCommas(
                                      Number(item?.payment)
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                      </table>
                    </div>
                    <section className="payement_receiptSection">
                      <div className="invoice__not-inner">
                        <div className="nots">
                        </div>
                        <div className="invoice__total">
                          <ul className="list">

                            <li className="items">
                              <h3>Amount Applied to Invoices</h3>{" "}
                              <span>
                                $
                                {this.numberWithCommas(
                                  this.round2decimal(
                                    Number(this.props.PaymentmodelData?.amountToApply)
                                  )
                                )}
                              </span>
                            </li>
                            {this.props.PaymentmodelData?.stripeCardFees > 0 && this.props.PaymentmodelData?.isFromReceivePayment &&
                              <li className="items">
                                <h3>Handling Fees</h3>{" "}
                                <span>
                                  $
                                  {this.numberWithCommas(
                                    this.round2decimal(
                                      Number(this.props.PaymentmodelData?.stripeCardFees)
                                    )
                                  )}
                                </span>
                              </li>
                            }
                            <li className="items">
                              <h3>Amount Applied to Credit Memo</h3>
                              <span>
                                $
                                {this.numberWithCommas(
                                  this.round2decimal(
                                    Number(this.props.PaymentmodelData?.totalCreditAmount)
                                  )
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </div>
          </ReactModal>
        </>
      </>
    );
  }
}
