import { connect } from 'react-redux'
import { getMe, fetchCompanyUsers, createUser, updateUserById, deleteUser, getUserToken,updateUserToken,fetchMe,getUserPermissions } from "../../store/user/duck";
import UserManagmentComponent from './component'
import { verifyQBIntegration } from './../../store/dashboard/duck';

const UserManagmentContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    createUser,
    updateUserById,
    fetchMe,
    fetchCompanyUsers,
    updateUserToken,
    deleteUser,
    getUserToken,
    verifyQBIntegration,
    getUserPermissions
  }
)(UserManagmentComponent);

export default UserManagmentContainer
