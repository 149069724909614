import React, { PureComponent } from "react";
import "./styles.scss";
import ordersIcon from "./../../images/orders_icon.svg";
import ordersArrow from "./../../images/orders_arrow.svg";
import ordersCart from "./../../images/orders_cart.svg";
import ordersDoller from "./../../images/orders_doller.svg";
import deleteIcons from "./../../images/delete-icon.svg";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";

export default class ReportsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
      permissions: {},
    };
  }
  componentDidMount = async () => {
    const {
      value: { user },
    } = await this.props.fetchMe();
    this.setState({ permissions: user?.permissions });
  };

  render() {
    let permissions = {};
    if (this.props.user?.permissions) {
      permissions = this.props.user.permissions;
    } else {
      permissions = this.state.permissions;
    }
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />

        <main className="dashboard-layout-content reports__dashboard-wrapper">
          <div className="page-header">
            <div className="page-header-inner">
              <h2 className="heading-title">Reports</h2>
            </div>
          </div>
          <section className="report_section">
            <div className="report_section-inner">
              <div className="row">
                {permissions && permissions?.reports?.orders && (
                  <div className="col-lg-6">
                    <div
                      onClick={() => this.props.history.push("/reports/orders")}
                      className="card d-card cursor-pointer"
                    >
                      <img className="card_icon" src={ordersIcon} alt="" />
                      <h3 className="title">Orders</h3>
                      <p>
                        View a report of all your completed orders. You can
                        search and filter by date range.
                      </p>
                    </div>
                  </div>
                )}
                {permissions && permissions?.reports?.inboundVsOutbound && (
                  <div
                    onClick={() => this.props.history.push("/material-report")}
                    className="col-lg-6"
                  >
                    <div className="card d-card cursor-pointer">
                      <img className="card_icon" src={ordersArrow} alt="" />
                      <h3 className="title">Inbound vs Outbound</h3>
                      <p>
                        View a report of all your inbound and outbound
                        transactions for a given date range{" "}
                      </p>
                    </div>
                  </div>
                )}
                {permissions && permissions?.reports?.clintAndJob && (
                  <div className="col-lg-6">
                    <div
                      onClick={() => this.props.history.push("/client-report")}
                      className="card d-card cursor-pointer"
                    >
                      <img className="card_icon" src={ordersCart} alt="" />
                      <h3 className="title">Client & Job Report</h3>
                      <p>
                        View a report of the clients and jobs associated with
                        those clients.
                      </p>
                    </div>
                  </div>
                )}
                {permissions && permissions?.reports?.cashDiscrepancy && (
                  <div className="col-lg-6">
                    <div
                      onClick={() =>
                        this.props.history.push("/cash-descrepancy-report")
                      }
                      className="card d-card cursor-pointer"
                    >
                      <img className="card_icon" src={ordersDoller} alt="" />
                      <h3 className="title">
                        Cash Discrepancy Form/Log Report
                      </h3>
                      <p>
                        View log entries of when cash in the cash on hand was
                        different than the systems calculations{" "}
                      </p>
                    </div>
                  </div>
                )}
                {permissions && permissions?.reports?.projectOrders && (
                  <div className="col-lg-6">
                    <div
                      onClick={() =>
                        this.props.history.push("/reports/orders/projects")
                      }
                      className="card d-card cursor-pointer"
                    >
                      <img className="card_icon" src={ordersIcon} alt="" />
                      <h3 className="title">
                        Projects
                      </h3>
                      <p>
                        View a report of all your Projects. You can
                        search and filter by date range.
                        </p>
                    </div>
                  </div>
                )}
                {permissions && permissions?.reports?.deletedTickets && (
                  <div className="col-lg-6">
                    <div
                      onClick={() =>
                        this.props.history.push("/deleted-tickets-report")
                      }
                      className="card d-card cursor-pointer"
                    >
                      <img className="card_icon" src={deleteIcons} alt="" />
                      <h3 className="title">
                      Voided Report
                      </h3>
                      <p>
                      View a report of all your Deleted orders. You can search and filter by date range.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
