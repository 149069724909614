// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
// import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase'


// import { fromPromise } from 'rxjs/observable/fromPromise'
// import { of } from 'rxjs'
// import { mergeMap, flatMap, catchError } from 'rxjs/operators'
import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const FETCH_DASHBOARD_DATA = 'empire/dashboard/FETCH_DASHBOARD_DATA'
export const FETCH_ALL_ITEMS = "empire/dashboard/FETCH_ALL_ITEMS";
export const REFRESH_TOKEN = "empire/dashboard/REFRESH_TOKEN";
export const UPDATE_QUICKBOOKS = "empire/dashboard/UPDATE_QUICKBOOKS";
export const VERIFY_QB_INTEGRATION = "empire/dashboard/VERIFY_QB_INTEGRATION";
export const DISABLE_QUICKBOOKS = "empire/dashboard/DISABLE_QUICKBOOKS";
export const DASHBOARD_PAYMENT= "empire/dashboard/DASHBOARD_PAYMENT";
export const TOP_CUSTOMER= "empire/dashboard/TOP_CUSTOMER";
export const TODAY_DUES = "empire/dashboard/TODAY_DUES";
export const TOP_DUMP_RATES_SALECTION="empire/dashboard/TOP_DUMP_RATES_SALECTION";
export const  TOP_MATERIALS_BY_TONS="empire/dashboard/TOP_MATERIALS_BY_TONS"
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const fetchDashboardData = data => {
  return {
    type: FETCH_DASHBOARD_DATA,
    payload: api.getDashboardData(data)
  }
}

export const fetchAllItems = (data, searchStartsWith) => {
  return {
    type: FETCH_ALL_ITEMS,
    payload: api.fetchAllItems(data, searchStartsWith)
  };
}

export const refreshToken = data => {
  return {
    type: REFRESH_TOKEN,
    payload: api.refreshToken(data)
  };
}

export const updateQuickBooks = data => {
  return {
    type: UPDATE_QUICKBOOKS,
    payload: api.updateQuickBooks(data)
  };
}

export const verifyQBIntegration = data => {
  return {
    type: VERIFY_QB_INTEGRATION,
    payload: api.verifyQBIntegration(data)
  };
}

export const disableQuickbooks = data => {
  return {
    type: DISABLE_QUICKBOOKS,
    payload: api.disableQuickbooks(data)
  };
}

export const getPayments = data => {
  return {
    type: DASHBOARD_PAYMENT,
    payload: api.getPayments(data)
  };
}

export const getTopCustomer = data => {
  return {
    type: TOP_CUSTOMER,
    payload: api.getTopCustomer(data)
  };
}

export const getTopOutStandingCustomer = data => {
  return {
    type: TOP_CUSTOMER,
    payload: api.getTopOutSTandingCustomer(data)
  };
}

export const getTodayDueInvoices = data => {
  return {
    type: TODAY_DUES,
    payload: api.getTodayDueInvoices(data)
  };
}


export const getTopMaterialsByTons = data => {
  return {
    type: TOP_MATERIALS_BY_TONS,
    payload: api.getTopMaterialsByTons(data)
  };
}

export const getTopDumpRateSelections = data => {
  return {
    type: TOP_DUMP_RATES_SALECTION,
    payload: api.getTopDumpRateSelections(data)
  };
}
/***********************************
 * Epics
 ***********************************/


export const dashboardEpic = combineEpics()
