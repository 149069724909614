import React, { PureComponent } from "react";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import ReactModal from "react-modal";
import { CloseIcon, MoreIcon,DateIcon } from "../../components/icons";
import { Formik, ErrorMessage, FieldArray } from "formik";
import { activeOutboundActivitySchema } from "../../utils/validations";
import Datetime from "react-datetime";
import { Pagination, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import "react-datetime/css/react-datetime.css";
import "./styles.scss";
import CompleteOutBoundActivity from "./partials/complete-outboundactivity";
import EditOutBoundActivity from "./partials/edit-outboundactivity";
import ShowOutBoundActivity from "./partials/outbound-activity";
import NotFound from "../NoRecords/component";
import NumberFormat from "react-number-format";
import {  numberFormat, outboundTruckTypes } from "../../constants/common";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import DeleteOrderModal from "../PendingOrderDetails/partials/delete-order";
import Dropzone from 'react-dropzone';
import _ from "lodash";
import { DownarrowIcon, StateUpArrowIcon } from "../../components/icons";
import {
  CloseOutlined 
} from '@ant-design/icons';
import ReactExport from "react-data-export";
import {
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  startOfDay,
} from "date-fns";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class OutboundActivityComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    const activeTab = props.location.pathname === "/outbound-activity-active" ? "active" : "completed";
    this.state = {
      modalIsOpen: false,
      toggleMenu: false,
      initialValues: {
        date: new Date(),
        manifestNumber: "",
        manifestNumberOther: "",
        facilityId: "",
        haulerId: "",
        truckType: "",
        actualGross: 0,
        sortOrder: { date : -1 },
        loadNumber: 0,
        tare: 0,
        adjustment: 0,
        materials: [
          {
            material: "",
            grossWeight: "",
            yardage: ""
          }
        ],
        manifest: [
          {
            manifestNumber: "",
            manifestNumberOther: "",
            manifestAttachments: "",
            manifestAttachmentsName: ""
          }
        ]
      },
      companyId: "",
      activities: [],
      facilities: [],
      outboundMaterials: [],
      inHouseHaulers: [],
      thirdPartyHaulers: [],
      activeTab,
      completeModal: false,
      editModal: false,
      showModal: false,
      activity: {},
      outboundFetchInProgress: false,
      searchText: "",
      typingTimeout: 0,
      dateType: "today",
      selectedDate: "",
      datePickerModal: false,
      startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
      endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
      spinning: false,
      isDeleteModal: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      progressBar: false,
      preview: '',
      tz,
      exportData: [],
      isExporting: false,
      DateRangePickerDroDown: false,
      date: {
        startDate: moment()?.tz(tz)?.startOf("month")?.toDate(),
        endDate: moment()?.tz(tz)?.endOf('month')?.toDate(),
        key: 'selection'
      },
      sortOrder: { date : -1 },
    };
    
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    if (this.props.location.pathname === "/outbound-activity-active") {
      this.setPageTitle("active");
    } else {
      this.setPageTitle("completed");
    }
    const {
      value: { user }
    } = await this.props.fetchMe();
    const outboundFilter = JSON.parse(localStorage.getItem("outboundFilter"));
    if (outboundFilter) {
      const { startDate, endDate } = this.state
      await this.setAsyncState({
        startDate: moment(startDate).isValid()
          ? moment(startDate)?.clone()?.tz(this.state?.tz)?.startOf('day')?.toDate()
          : moment()?.clone()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
        endDate: moment(endDate).isValid() ? moment(endDate)?.clone()?.tz(this.state?.tz)?.endOf('day')?.toDate() : moment()?.tz(this.state?.tz)?.endOf('day')?.toDate(),
        sortOrder: _.get(outboundFilter, "sortOrder", { date: -1 }),
        dateType:  _.get(this.state, "dateType", "today"),
        selectedDate: _.get(this.state, "selectedDate", ""),
      })
    }
    const billingData = JSON.parse(localStorage.getItem("OrderDate"))
    const startDate = startOfDay(new Date(JSON.parse(localStorage.getItem("OrderDate"))?.startDate ?? new Date()))
    const endDate = endOfDay(new Date(JSON.parse(localStorage.getItem("OrderDate"))?.endDate ?? new Date()))
    const date = { startDate: startDate, endDate: endDate, key: 'selection' }
    billingData && this.setState({ startDate: startDate, endDate: endDate, date: date })
    window.addEventListener('click', this.handleClickOutside);
    this.fetchOutboundActivities();
    this.fetchFacilities("facility");
    this.fetchThirdPartyHauler("thirdPartyHauler");
    this.fetchInhouseHauler("inHouseHauler");
    this.setState({ companyId: user.companyId });
    this.fetchMaterials();
    this.setState({exportData: []})
  };
  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  }

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchOutboundActivities();
    });
  };

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchOutboundActivities();
      }, 1000)
    });
  };

  fetchOutboundActivities = async () => {
    const { activeTab, searchText, startDate, endDate, skip, limit, dateType , sortOrder , selectedDate} = this.state;
    await this.setAsyncState({ outboundFetchInProgress: true });
    const {
      value: {
        data: { outboundactivities, outboundactivitiesCount }
      }
    } = await this.props.fetchOutboundActivities({
      skip,
      limit,
      type: activeTab,
      searchText,
      startDate: moment(startDate)
      ?.tz(this.state?.tz)
      ?.startOf("day")
      ?.toISOString(),
      sortOrder,
      endDate: moment(endDate)
      ?.tz(this.state?.tz)
      ?.endOf("day")
      ?.toISOString(),
      dateType
    });
    this.setState({ activities: outboundactivities, totalItems: outboundactivitiesCount , outboundFetchInProgress: false});
    localStorage.setItem(
      "outboundFilter",
      JSON.stringify({
        startDate,
        endDate,
        sortOrder,
        dateType,
      selectedDate
      })
    )
  };

  fetchFacilities = async resourceType => {
    const {
      value: { data }
    } = await this.props.fetchResourcesList({ resourceType, searchText: "" });
    this.setState({ facilities: data });
  };

  fetchMaterials = async() =>{
    const { value : {data} } = await this.props.getOutboundMaterials();
    this.setState({ outboundMaterials: data });
  }

  fetchThirdPartyHauler = async resourceType => {
    const {
      value: { data }
    } = await this.props.fetchResourcesList({ resourceType, searchText: "" });
    this.setState({ thirdPartyHaulers: data });
  };

  fetchInhouseHauler = async resourceType => {
    const {
      value: { data }
    } = await this.props.fetchResourcesList({ resourceType, searchText: "" });
    this.setState({ inHouseHaulers: data });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  async setOrder(field) {
    const { sortOrder } = this.state;
    const sort = { [field]: sortOrder[field] === 1 ? -1 : 1 };
    await this.setAsyncState({ sortOrder: sort });
      this.fetchOutboundActivities(); 
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      editModal: false,
      completeModal: false,
      showModal: false,
      transportationCost:"",
      datePickerModal: false
    });
  }

  deleteOutboundActivity = async () => {
    const { deleteOutboundActivity,deleteCompletedOutboundActivity } = this.props;
    const { _id,activeTab } = this.state;
    if (activeTab === "completed") {
      const {
        value: { status }
      } = await deleteCompletedOutboundActivity(_id);
      if (status) {
        this.setState({ isDeleteModal: false});
        this.fetchOutboundActivities();
      }
    }else{
      const {
        value: { status }
      } = await deleteOutboundActivity(_id);
      if (status) {
        this.setState({ isDeleteModal: false });
        this.fetchOutboundActivities();
      }
    }
    
  };

  setDate(e) {
    const self = this;
    const val = e.target.value;
    if (val === "today") {
      self.setState(
        {
          dateType: "today",
          selectedDate: "",
          startDate: moment()?.tz(this.state?.tz)?.startOf('day')?.toDate(),
          endDate:  moment()?.tz(this.state?.tz)?.endOf('day')?.toDate(),
          currentPage: 1,
          skip: 0
        },
        () => {
          this.fetchOutboundActivities();
        }
      );
    } else if (val === "this week") {
      self.setState(
        {
          dateType: "this week",
          selectedDate: "",
          startDate:  moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.startOf("isoWeek")
          ?.toDate(),
          endDate:  moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.endOf("isoWeek")
          ?.toDate(),
          currentPage: 1,
          skip: 0
        },
        () => {
          this.fetchOutboundActivities();
        }
      );
    } else if (val === "this month") {
      self.setState(
        {
          dateType: "this month",
          selectedDate: "",
          startDate:  moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.startOf("month")
          ?.toDate(),
          endDate:  moment()
          ?.clone()
          ?.tz(this.state?.tz)
          ?.endOf("month")
          ?.toDate(),
          currentPage: 1,
          skip: 0
        },
        () => {
          this.fetchOutboundActivities();
        }
      );
    } else if (val === "custom") {
      self.setState({ datePickerModal: true });
    } else {
      this.setState({ dateType: "" }, () => {
        this.fetchOutboundActivities();
      });
    }
  }

  openDatePicker = e => {
    const val = e.target.value;
    if (val === "custom") {
      this.setState({ datePickerModal: true });
    }
  };

  setPageTitle = tab => {
    if (tab === "active") {
      document.title = "Active Outbound Activity | Weighworks";
    } else {
      document.title = "Completed Outbound Activity | Weighworks";
    }
  };

  onClickTab(activeTab, url) {
    const { history } = this.props;
    this.setState({ activeTab, currentPage: 1, skip: 0 }, () => {
      this.setPageTitle(activeTab);
      this.fetchOutboundActivities();
      history.push(url);
    });
  }

  onSelect = ({ start, end }) => {
    this.setState(
      {
        dateType: "custom",
        selectedDate: `${moment(start)?.clone()?.tz(this.state?.tz)?.toDate()} - ${moment(end)?.clone()?.tz(this.state?.tz)?.toDate()}`,
        startDate: moment(start)?.clone()?.tz(this.state?.tz)?.toDate(),
        endDate: moment(end)?.clone()?.tz(this.state?.tz)?.toDate(),
        datePickerModal: false
      },
      () => {
        this.fetchOutboundActivities();
      }
    );
  };

  handleSubmit = async ({ grossWeight, ...values }, { setSubmitting, setErrors }) => {
    const { companyId } = this.state;
    setSubmitting(true);
    const payload = {
      ...values,
      status: 1,
      companyId,
      loadNumber: values.loadNumber ? Number(values.loadNumber):0,
      materials:values.materials.map((item)=>({...item,yardage:item?.yardage && item?.yardage !== "" ? item?.yardage : 0})),
    };
    const {
      value: { status, message }
    } = await this.props.createOutboundActivity(payload);
    if (status) {
      this.fetchOutboundActivities();
      this.closeModal();
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setErrors({ email: message });
    }
  };

  valid = current => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  deleteUserModal({ _id }) {
    this.setState({ _id, isDeleteModal: true });
  }

  menu(activity) {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => this.setState({ activity, editModal: true })}>
          Edit
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.deleteUserModal(activity)}>
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
 
  handlePdf = (preview) => {
    window.open(preview, "_blank");
    }

    dataURItoBlob = dataURI => {
      var binary = atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    };
  
    submitImages(data,fileType) {
      const blob = this.dataURItoBlob(data);
      const formdata = new FormData();
      formdata.append("photos", blob);
      formdata.append("fileName", `${new Date().getTime()}.${fileType === "pdf" ? "pdf" : "png"}`);
      formdata.append("folderName", "load");
      formdata.append("fileType", fileType);
      return formdata;
    }

   handleExportData = async () => {
    this.setState({isExporting: true, exportData: []})
    const { activeTab, searchText, startDate, endDate, dateType , sortOrder} = this.state;
    const {
      value: {
        data: { outboundactivities }
      }
    } = await this.props.fetchOutboundActivities({
      skip: 0,
      limit: 0,
      type: activeTab,
      searchText,
      startDate: moment(startDate)
      ?.tz(this.state?.tz)
      ?.startOf("day")
      ?.toISOString(),
      sortOrder,
      endDate: moment(endDate)
      ?.tz(this.state?.tz)
      ?.endOf("day")
      ?.toISOString(),
      dateType
    });
  const data = outboundactivities?.map(item => {
     const actualGross = item.actualGross !== "" ? parseFloat(String(item.actualGross).replace(/[^-?\d.]/g, "")) : 0;
     const tare = item.tare !== "" ? parseFloat(String(item.tare).replace(/[^-?\d.]/g, "")) : 0;
     let adjustment = item?.adjustment !== "" ? parseFloat(String(item?.adjustment).replace(/[^-?\d.]/g, "")) : 0;
     const totalYardage = item.materials.reduce((sum, material) => {
      return sum + (material.yardage !== "" ? parseFloat(material.yardage) : 0);
    }, 0);
    adjustment = isNaN(adjustment) ? 0 : adjustment 
    const net = actualGross + adjustment - tare;
     const tonnage = parseFloat(net) / 2000;
     const disposalRate = isNaN(item?.transportationCost) ? 0 : (parseFloat(tonnage.toFixed(2)) * parseFloat(item?.transportationCost)) + (parseFloat(item?.disposalRateYard) * parseFloat(totalYardage));
     const total = isNaN(disposalRate) ? 0 : parseFloat(disposalRate) + parseFloat(item.disposalRate);
      return {
        date: moment(item.date).tz(this.state?.tz).format("MM/DD/YY"),
        time: moment(item.date).tz(this.state?.tz).format("hh:mm a"),
        manifest: item?.manifestNumber ? item?.manifestNumber : 
        item?.manifestNumberOther ? item?.manifestNumberOther :
        item?.manifest?.map(item => item?.manifestNumber === '' ? item?.manifestNumberOther : item?.manifestNumber).join(', '),
        hauler: item.hauler.name,
        facility: item.facility.name,
        yardage : item?.materials?.reduce((sum, item) => {
          const yardageValue = parseFloat(item?.yardage) || 0; 
          return sum + yardageValue;
        }, 0),
        tonnage: tonnage.toFixed(2),
        total: total,
        disposalCost: isNaN(disposalRate) ? 0 : disposalRate,
        disposalRateTon: isNaN(item.transportationCost) ? 0 : item.transportationCost,
        disposalRateYard: isNaN(item.disposalRateYard) ? 0 : item.disposalRateYard,
        transportationCost: isNaN(item.disposalRate) ? 0 : item.disposalRate,
      }
  })
  this.setState({exportData: data},()=>{
    this.setState({ exportData: [], isExporting: false })
  })
  }
  handleSelect = (ranges) => {
    ranges = {
      ...ranges,
      selection: {
        ...ranges?.selection,
        startDate: moment(ranges.selection.startDate)?.startOf("day").toDate(),
        endDate: moment(ranges.selection.endDate)?.endOf("day")?.toDate()
      }
    }
    this.setState({
      date: ranges.selection,
      // isPaymentsDateRangePickerOpen: false
    });
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate },()=> this.fetchOutboundActivities(),
    ()=> this.fetchFacilities("facility"),
    ()=> this.fetchThirdPartyHauler("thirdPartyHauler"),
    ()=> this.fetchInhouseHauler("inHouseHauler"))
  }
  handleClickOutside = (event) => {
    if (this.dateRangePickerRef && !this.dateRangePickerRef.contains(event.target)) {
      this.setState({ DateRangePickerDroDown: false });
    }
  };
  render() {
    const {
      location: { pathname }, order
    } = this.props;
    const {
      initialValues,
      facilities,
      inHouseHaulers,
      thirdPartyHaulers,
      activities,
      activeTab,
      searchText,
      sortOrder,
      progressBar,
      outboundMaterials,
      isExporting
    } = this.state;
    const isYardageRequired = this.props.user?.company?.isYardageRequired;
    return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          searchText={searchText}
          handleSearchChange={this.handleSearch}
          onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />
   
        <main className="dashboard-layout-content outboundactivity__wrapper">
          <div className="page-header"  ref={ref => this.dateRangePickerRef = ref}>
          <div>
              <h2 className="heading-title">Outbound Activity </h2>
              {pathname === "/outbound-activity-active" && (
                <button className="btn btn-link btn-sm underline p-0" onClick={()=> this.props.history.push('/outbound-materials')}>
                Manage Outbound Materials
              </button>
              )}
            </div>
            <div className="outboundactivity__page-header-inner">
            {pathname === "/outbound-activity-active" && (
              <button className="btn btn-dark btn-sm outbound__activity-btn" onClick={this.openModal}>
                Record Outbound Activity
              </button>
            )}
            {pathname === "/outbound-activity-completed" && (
              <button disabled={isExporting} className="btn btn-dark btn-sm py-0 px-2 mr-2" onClick={this.handleExportData}>
                {isExporting ? "Please wait..." :"Export Completed Outbound Activity"}
              </button>
            )}
            {<button
              className="btn btn-dark btn-sm btn-exportorders" onClick={() => { this.setState({ DateRangePickerDroDown: !this.state.DateRangePickerDroDown }) }}>
                <DateIcon />
              {this.state.date.startDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })} - {this.state.date.endDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
            </button>}
            {this.state.DateRangePickerDroDown &&
              <DateRangePicker
              className="datepicker-orders"
                //onChange={(e)=>{this.handleSelect(e.dateRange) ;this.deshboardPayments(e.target.value); this.paymentDatePicker()}}
                onChange={this.handleSelect}
                ranges={[this.state.date]}
                editableDateInputs={true}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                calendarFocus="forwards"
                staticRanges={[
                  ...defaultStaticRanges,
                  {
                    label: "Last Year",
                    range: () => ({
                      startDate: startOfYear(addYears(new Date(), -1)),
                      endDate: endOfYear(addYears(new Date(), -1))
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  },
                  {
                    label: "This Year",
                    range: () => ({
                      startDate: startOfYear(new Date()),
                      endDate: endOfDay(new Date())
                    }),
                    isSelected(range) {
                      const definedRange = this.range();
                      return (
                        isSameDay(range.startDate, definedRange.startDate) &&
                        isSameDay(range.endDate, definedRange.endDate)
                      );
                    }
                  }
                ]}

              />}
          </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="custome__tabs">
                <ul className="custome__tabs-list">
                  <li>
                    <span
                      className={pathname === "/outbound-activity-active" ? "active" : ""}
                      onClick={() => this.onClickTab("active", "/outbound-activity-active")}
                    >
                      Active
                    </span>
                  </li>
                  <li>
                    <span
                      className={pathname === "/outbound-activity-completed" ? "active" : ""}
                      onClick={() => this.onClickTab("completed", "/outbound-activity-completed")}
                    >
                      Completed
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {activities.length !== 0 ? (
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th onClick={() => this.setOrder("date")}>Date/Time 
                        {sortOrder["date"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        <th onClick={() => this.setOrder("manifest.manifestNumber")}>Manifest #
                        {sortOrder["manifest.manifestNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        <th onClick={() => this.setOrder("materials.grossWeight")}> Weight 
                        {sortOrder["materials.grossWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        <th onClick={() => this.setOrder("facility.name")}>Facility
                        {sortOrder["facility.name"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        <th onClick={() => this.setOrder("hauler.name")}>Hauler
                        {sortOrder["hauler.name"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        <th onClick={() => this.setOrder("truckType")}>Truck
                        {sortOrder["truckType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                        {activeTab === "active" && <th>&nbsp;</th>}
                        {activeTab === "completed" && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {activities.map(activity => {
                        let grossWeight = 0;
                        activity.materials.forEach(weight => {
                          grossWeight+= weight.grossWeight
                        })
                        return (
                          <tr
                            className="cursor-pointer"
                            key={activity._id}
                            onClick={() =>
                              activeTab === "active"
                                ? this.setState({ activity, completeModal: true })
                                : this.setState({ activity, showModal: true })
                            }
                          >
                            <td>{moment(activity.date)?.tz(this.state?.tz)?.format("MM/DD/YY h:mm a")}</td>
                            <td>{(activity.manifest && activity.manifest.length > 0) ? activity.manifest[0].manifestNumber : activity.manifestNumber}</td>
                            <td>
                              <NumberFormat
                                thousandSeparator={true}
                                displayType={"text"}
                                value={grossWeight}
                              />{" "}
                              lbs
                            </td>
                            <td>{activity.facility.name}</td>
                            <td>{activity.hauler.name}</td>
                            <td>{activity.truckType}</td>
                            {activeTab === "active" && (
                              <>
                                <td className="cursor-pointer underline text-bold">Complete Activity</td>
                                <td className="table__last-child" onClick={e => e.stopPropagation()}>
                                  <Dropdown
                                    overlay={() => this.menu(activity)}
                                    trigger={["click"]}
                                    overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                                  >
                                    <button className="btn ant-dropdown-link">
                                      <MoreIcon />
                                    </button>
                                  </Dropdown>
                                </td>
                              </>
                            )}
                            {activeTab === "completed" && (
                              <>
                               <td onClick={e => e.stopPropagation()}><button className="delete-btn-table pl-0" onClick={() => this.deleteUserModal(activity)}>Void</button></td> 
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <NotFound
              title={activeTab === "active" ? "No Active Outbound Activity" : "No Completed Outbound Activity"}
            />
          )}
        </main>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered edit__outbound__modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Record Outbound Activity</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={activeOutboundActivitySchema(isYardageRequired)}
              onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
            >
              {({ values, handleChange, handleSubmit, setValues, setFieldValue, isSubmitting,setSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Datetime
                            inputProps={{
                              name: "date",
                              required: true,
                              autoComplete: "off",
                              className: "form-control material-textfield-input"
                            }}
                            onChange={date => setValues({ ...values, date })}
                            // isValidDate={this.valid}
                            value={values.date}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                            // Placeholder=""
                          />
                          <ErrorMessage component="span" name="date" className="invalid-feedback d-block" />
                          <label className="material-textfield-label label__for-date">
                            Date<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="loadNumber"
                            type="number"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            // isValidDate={this.valid}
                            value={values && parseInt(values?.loadNumber, 10)}
                            required
                            
                          />
                          <ErrorMessage component="span" name="loadNumber" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                          Load Number
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                      <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="facilityId"
                        onChange={handleChange}
                        value={values && values.facilityId}
                        required
                      >
                        <option>Select Facility</option>
                        {facilities?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(facility => {
                          return (
                            <option value={facility._id} key={facility._id}>
                              {facility.companyName}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage component="span" name="facilityId" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Facility<span>*</span>
                      </label>
                    </div>
                      </div>
                      <div className="col-md-4">
                      <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="haulerId"
                        onChange={handleChange}
                        value={values && values.haulerId}
                        required
                      >
                        <option>Select Hauler</option>
                        {inHouseHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(inHouseHauler => {
                          return (
                            <option value={inHouseHauler._id} key={inHouseHauler._id}>
                              {inHouseHauler.companyName} (In House)
                            </option>
                          );
                        })}
                        {thirdPartyHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(thirdPartyHauler => {
                          return (
                            <option value={thirdPartyHauler._id} key={thirdPartyHauler._id}>
                              {thirdPartyHauler.companyName} (Third Party)
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage component="span" name="haulerId" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Hauler<span>*</span>
                      </label>
                    </div>
                      </div>
                      <div className="col-md-4">
                      <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="truckType"
                        onChange={handleChange}
                        value={values && values.truckType}
                        required
                      >
                         <option>Select Truck Type</option>
                        {
                          outboundTruckTypes?.slice()?.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )?.map((item)=>{
                          return(
                            <option value={item.value}>{item.name}</option>
                          )
                          })
                        }
                      </select>
                      <ErrorMessage component="span" name="truckType" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Type of Truck<span>*</span>
                      </label>
                    </div>
                      </div>
                    </div>
                    <FieldArray
                      name="materials"
                      render={arrayHelpers => (
                        <div>
                          {values.materials.map((material, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Material ${index + 1}`}</h3>
                                    {values.materials.length !== 1 && (
                                      <button
                                        className="material__label-remove"
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name={`materials[${index}].material`}
                                      value={material.material}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option>Select Material</option>
                                      {outboundMaterials
                                        ?.filter(material => material?.status) 
                                        ?.slice()
                                        ?.sort((a, b) => a?.materials?.localeCompare(b?.materials))
                                        ?.map(material => (
                                          <option value={material?.materials} key={material?.materials}>
                                            {material?.materials}
                                          </option>
                                        ))}
                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].material`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Material<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`materials[${index}].grossWeight`}
                                      value={material.grossWeight}
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`materials[${index}].grossWeight`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                      Gross Weight at Facility (lbs)
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`materials[${index}].tareWeight`}
                                      value={material.tareWeight}
                                      thousandSeparator={true}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`materials[${index}].tareWeight`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].tareWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Tare Weight
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="number"
                                      name={`materials[${index}].yardage`}
                                      value={material.yardage}
                                      className="form-control material-textfield-input"
                                      onChange={handleChange}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].yardage`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Yardage{isYardageRequired ? <span>*</span>:""}
                                    </label>
                                  </div>
                                </div>
                                {values.materials.length - 1 === index && (
                                  <div className="col-md-12">
                                    <div className="material__label-section add__another">
                                      <h3
                                        className="material__label-title underline"
                                        onClick={() =>
                                          arrayHelpers.push({ material: "", grossWeight: "", yardage: "" })
                                        }
                                      >
                                        Add Another Material
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <FieldArray
                      name="manifest"
                      render={newArrayHelpers => (
                        <div>
                          {values.manifest.map((manifest, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Manifest ${index + 1}`}</h3>
                                    {values.manifest.length !== 1 && (
                                      <button
                                        className="material__label-remove"
                                        type="button"
                                        onClick={() => newArrayHelpers.remove(index)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                  <NumberFormat
                                      name={`manifest[${index}].manifestNumber`}
                                      value={manifest.manifestNumber}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumber`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`manifest[${index}].manifest`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                    Manifest #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`manifest[${index}].manifestNumberOther`}
                                      value={manifest.manifestNumberOther}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumberOther`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                      required
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Other #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                  
                                  {manifest.manifestAttachments === "" ?
                                  <Dropzone
                                  onDrop={acceptedFiles => {
                                    setSubmitting(true)
                                    if (acceptedFiles.length > 0) {
                                      let fileType = "";
                                      if (!acceptedFiles[0]) return;
                                      if (acceptedFiles[0].type === "application/pdf") {
                                        fileType = "pdf";
                                      }else {
                                        fileType = "image";
                                      }
                                      this.getBase64(acceptedFiles[0],async (result) => {
                                        const {
                                          value: { url }
                                        } = await this.props.uploadImage(this.submitImages(result,fileType));
                                        if (url) {
                                          setSubmitting(false)
                                          setFieldValue(`manifest[${index}].manifestAttachments`, url);
                                          setFieldValue(`manifest[${index}].manifestAttachmentsName`,acceptedFiles[0].name);
                                        }
                                      });
                                    }
                                  }
                                  }
                                  accept="image/png,image/jpeg,image/gif,image/jpg,application/pdf"
                                >
                                  {({ getRootProps, getInputProps }) => (

                                    <div className="items-inner" {...getRootProps()}>
                                      <div className="circle">
                                        <input {...getInputProps()} />
                                        <h6
                                        className="material__label-title underline pt-3"
                                      >
                                        Upload Manifest #
                                      </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>:
                                   manifest?.manifestAttachments.endsWith('png') ? (
                                    <>
                                    <div className='imagebox float-left p-0'>
                                    {manifest.manifestAttachments && (
                                      <span
                                        className='CloseBtn cursor-pointer'
                                        onClick={() => {
                                          setFieldValue(`manifest[${index}].manifestAttachments`, "");
                                        setFieldValue(`manifest[${index}].manifestAttachmentsName`,"");
                                        }}>
                                        <CloseOutlined className="text-danger cursor-pointer" />
                                      </span>
                                    )}
                                   <img src={manifest.manifestAttachments} alt="Manifest Attachment" width={200} height={100} className="pb-3" />
                                  </div>
                                   </>) : (<div className="d-flex d-inline">
                                    <h6 className="material__label-title underline pt-3" onClick={()=>this.handlePdf(manifest.manifestAttachments)}>{manifest?.manifestAttachmentsName}</h6>
                                    {manifest.manifestAttachments && (
                                      <span
                                        className='CloseBtn cursor-pointer'
                                        onClick={() => {
                                        setFieldValue(`manifest[${index}].manifestAttachments`, "");
                                        setFieldValue(`manifest[${index}].manifestAttachmentsName`,"");
                                        }}>
                                        <CloseOutlined className="text-danger cursor-pointer" />
                                      </span>
                                    )}
                                   </div>)
                                
                                }
                                  </div>
                                </div>
                                {values.manifest.length - 1 === index && (
                                  <div className="col-md-12">
                                    <div className="material__label-section add__another">
                                      <h3
                                        className="material__label-title underline"
                                        onClick={() => {
                                          const { manifest } = values;
                                          const lastManifest = manifest[manifest.length - 1];
                                          if (
                                            lastManifest.manifestNumber !== "" ||
                                            lastManifest.manifestNumberOther !== "" ||
                                            lastManifest.manifestAttachments !== ""
                                          ) {
                                            newArrayHelpers.push({
                                              manifestNumber: "",
                                              manifestNumberOther: "",
                                              manifestAttachments: ""
                                            });
                                          }
                                        }}
                                      >
                                        Add Another Manifest #
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                   
                    <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-lg w-100">
                      {isSubmitting ? "Please wait..." : "Save Outbound Record"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
        <CompleteOutBoundActivity
          activity={this.state.activity}
          editModal={this.state.completeModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          outboundMaterials={this.state.outboundMaterials}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={true}
          uploadImage={this.props.uploadImage}
          user={this.props.user}
        />
        <EditOutBoundActivity
          activity={this.state.activity}
          editModal={this.state.editModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={true}
          uploadImage={this.props.uploadImage}
          user={this.props.user}
        />
        <ShowOutBoundActivity
          activity={this.state.activity}
          showModal={this.state.showModal}
          facilities={facilities}
          inHouseHaulers={inHouseHaulers}
          thirdPartyHaulers={thirdPartyHaulers}
          outboundMaterials={this.state.outboundMaterials}
          onCloseClick={this.closeModal}
          updateOutboundActivity={this.props.updateOutboundActivity}
          fetchOutboundActivities={this.fetchOutboundActivities}
          isEditable={false}
          uploadImage={this.props.uploadImage}
          user={this.props.user}
        />
        {/* <DateRangePicker
          datePickerModal={this.state.datePickerModal}
          closeModal={this.closeModal}
          setDate={() => {
            this.setState(
              {
                dateType: "custom",
                selectedDate: `${moment(this.state.startDate).format("MMM DD, YYYY")} - ${moment(
                  this.state.endDate
                ).format("MMM DD, YYYY")}`,
                currentPage: 1,
                skip: 0
              },
              () => {
                this.closeModal();
                this.fetchOutboundActivities();
              }
            );
          }}
          handleFromChange={date => this.setState({ startDate: moment(date)?.clone()?.tz(this.state?.tz)?.toDate() })}
          handleToChange={date => this.setState({ endDate: moment(date)?.clone()?.tz(this.state?.tz)?.toDate() })}
          from={this.state.startDate}
          to={this.state.endDate}
        /> */}
        <Pagination
          className="text-center"
          onChange={this.onChangePage}
          hideOnSinglePage
          current={this.state.currentPage}
          total={this.state.totalItems}
          pageSize={this.state.limit}
          showSizeChanger={false}
        />
        {this.state.isDeleteModal && (
          <DeleteOrderModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => this.setState({ isDeleteModal: false,softDelete:false })}
          deleteOrder={this.deleteOutboundActivity}
          progressBar={progressBar}
          is_paid_order_message={order?.completed}
          softDelete={this.state.activeTab === "active" ? false : true}
        />
        )}
        {
          this.state.exportData.length > 0 && (  
          <ExcelFile hideElement filename={`Completed Outbound Activity ${moment().format("MM/DD/YYYY hh:mm:ss a")}`}>
          <ExcelSheet data={this.state.exportData} name="Completed Outbound Activity">
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Time" value="time" />
            <ExcelColumn label="Manifest" value="manifest" />
            <ExcelColumn label="Hauler"   value='hauler' />
            <ExcelColumn label="Facility" value="facility" />
            <ExcelColumn label="Tonnage" value='tonnage' />
            <ExcelColumn label="Yardage" value='yardage'  />
            <ExcelColumn label="Disposal Rate/Ton" value={col => `$${numberFormat(col.disposalRateTon)}`} />
            <ExcelColumn label="Disposal Rate/Yard" value={col => `$${numberFormat(col.disposalRateYard)}`} />
            <ExcelColumn label="Disposal Cost" value={col => `$${numberFormat(col.disposalCost)}`} />
            <ExcelColumn label="Trucking Cost" value={col => `$${numberFormat(col.transportationCost)}`} />
            <ExcelColumn label="Total Cost" value={col => `$${numberFormat(col.total)}`} />
          </ExcelSheet>
        </ExcelFile>
        )
        }
      </div>
    );
  }
}
