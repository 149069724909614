import React from "react";
import ReactModal from 'react-modal';
import { CloseIcon } from "../../../components/icons";
import { message } from "antd";
class EditEmailPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.customEmail,
      isWaiting:false
    }
  }

  updateEmail = async () => {
    this.setState({ isWaiting: true });
    const {
      value: { status, data },
    } = await this.props.updateCustomEmail({
      companyId: this.props.companyId,
      fromEmail: this.state.email,
    });
    if (status) {
      message.success("Custom email updated successfully");
      this.props.closeModal();
      this.setState({ isWaiting: false });
      this.props.emailUpdate(data?.fromEmail)
    }
  };

  render() {
    const { closeModal, is_custom_email_edit } = this.props;
    const {isWaiting, email} = this.state;
    return <>
        <ReactModal isOpen={is_custom_email_edit}>
          <div className="react-modal-dialog react-modal-dialog-centered payabli__integration-modal m-auto">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Edit Email</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="form-group material-textfield">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  className="form-control material-textfield-input"
                  name="email"
                  required
                />
                <label className="material-textfield-label">
                  Custom Email<span>*</span>
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <button onClick={closeModal} className="btn btn-dark btn-sm delete-btn mx-2">
                  Cancel
                </button>
                <button onClick={this.updateEmail} className="btn btn-yellow">
                  {isWaiting ? "Wait" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </ReactModal></>
      }
}

export default EditEmailPopup;