import "rxjs";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import app from "./app";
import user, { userEpic } from "./user/duck";
import company, { companyEpic } from "./company/duck";
import customer, { customerEpic } from "./customer/duck";
import resource, { resourceEpic } from "./resource/duck";
import outboundActivity, { outboundActivityEpic } from "./outboundactivity/duck";
import order, { orderEpic } from "./order/duck";
import dashboard, { dashboardEpic } from "./dashboard/duck";
import transaction, { transactionEpic } from "./transaction/duck";
import driver, { driverEpic } from "./driver/duck";
import report, { reportEpic } from "./report/duck";
import notifications, { notificationEpic } from "./notifications/duck";
import signature_pad, { signaturepadEpic } from "./signature_pad/duck";
import billing, { billingEpic } from "./Billing/duck";


// Bundling Epics
const rootEpic = combineEpics(
  userEpic,
  companyEpic,
  customerEpic,
  resourceEpic,
  outboundActivityEpic,
  orderEpic,
  dashboardEpic,
  transactionEpic,
  driverEpic,
  reportEpic,
  notificationEpic,
  signaturepadEpic,
  billingEpic
);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware();

// Define Middleware
const middleware = [thunk, promise, epicMiddleware];

// Define Reducers
const reducers = combineReducers({
  company,
  customer,
  resource,
  outboundActivity,
  user,
  order,
  transaction,
  dashboard,
  driver,
  report,
  notifications,
  signature_pad,
  app,
  form: formReducer,
  billing
});

// Create Store
const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(...middleware)
);
epicMiddleware.run(rootEpic);
export default store;
