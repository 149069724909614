// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const FETCH_COMPANY_LOGO = 'empire/user/FETCH_COMPANY_LOGO'
export const CREATE_COMPANY = 'empire/user/CREATE_COMPANY'
export const FETCH_COMPANIES = 'empire/user/FETCH_COMPANIES'
export const FETCH_COMPANY_USERS = 'empire/user/FETCH_COMPANY_USERS'
export const FETCH_SETTING = 'empire/user/FETCH_SETTING'
export const UPDATE_COMPANY = 'empire/user/UPDATE_COMPANY'
export const FETCH_COMPANY_DRIVERS = 'empire/user/FETCH_COMPANY_DRIVERS'
export const UPDATE_STRIPE_INFO = 'empire/user/UPDATE_STRIPE_INFO'
export const FETCH_COMPANIES_BY_ID = 'empire/user/FETCH_COMPANIES_BY_ID'
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const createCompany = payload => {
  return {
    type: CREATE_COMPANY,
    payload: api.createCompany(payload)
  }
}

export const fetchCompanyUsers = payload => {
  return {
    type: FETCH_COMPANY_USERS,
    payload: api.fetchCompanyUsers(payload)
  }
}

export const fetchCompanies = payload => {
  return {
    type: FETCH_COMPANIES,
    payload: api.fetchCompanies(payload)
  }
}
export const fetchCompaniesByID = payload => {
  return {
    type: FETCH_COMPANIES_BY_ID,
    payload: api.fetchCompaniesByID(payload)
  }
}
export const fetchSetting = payload => {
  return {
    type: FETCH_SETTING,
    payload: api.fetchSetting(payload)
  }
}

export const updateCompany = payload => {
  return {
    type: UPDATE_COMPANY,
    payload: api.updateCompany(payload)
  }
}

export const updateCustomEmail = payload => {
  return {
    type: UPDATE_COMPANY,
    payload: api.updateCustomEmail(payload)
  }
}

export const fetchCompanyDrivers = payload => {
  return {
    type: FETCH_COMPANY_DRIVERS,
    payload: api.fetchCompanyDrivers(payload)
  }
}

export const updateStripeInfo = payload => {
  return {
    type: UPDATE_STRIPE_INFO,
    payload: api.updateStripeInfo(payload)
  }
}

export const fetchCompanyLogo = () => {
  return {
    type: FETCH_COMPANY_LOGO,
    payload: api.fetchCompanyLogo()
  }
}

/***********************************
 * Epics
 ***********************************/

export const companyEpic = combineEpics()
