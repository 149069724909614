import React from 'react'
// import { Link } from 'react-router-dom'
// import { Tooltip } from 'antd'
// import { QuestionIcon, LockIcon, CloseIcon } from '../../components/icons'
// import { isEmpty } from 'lodash'
// import { Formik } from 'formik'
// import InputMask from "react-text-mask"
// import Avatar from '../../images/avatar.svg'
// import PropTypes from 'prop-types'

import './styles.scss'

export default class SignupComponent extends React.Component {

  constructor(props){
    super(props)
    this.state = {
     
    }
  }
 

  render() {
    // const { percent, isEmailExists, isStoreExists, avatar, isAgent, plan, selectedMembershipPlan } = this.state
    return (
      <div className="agent-signup">

       
    </div>
   )
  }
}