import React, { Component } from "react";
import ReactModal from "react-modal";
import moment from "moment-timezone";
import { CloseIcon } from "../../../components/icons";
import { Formik, ErrorMessage, FieldArray } from "formik";
import { editActiveOutboundActivitySchema } from "../../../utils/validations";
import Datetime from "react-datetime";
import {  numberFormat, numberFormatLbs, outboundTruckTypes } from "../../../constants/common";
import NumberFormat from "react-number-format";
import Dropzone from "react-dropzone";
import {
  CloseOutlined 
} from '@ant-design/icons';

export default class EditOutboundActivity extends Component {
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    this.state = {
      initialValues: {
        date: "",
        manifestNumber: "",
        manifestNumberOther: "",
        materials: [
          {
            material: "",
            grossWeight: "",
            yardage: "",
            tareWeight:""
          }
        ],
        manifest: [
          {
            manifestNumber: "",
            manifestNumberOther: "",
            manifestAttachments: "",
            manifestAttachmentsName: ""
          }
        ],
        facilityId: "",
        haulerId: "",
        truckType: "",
        actualGross: "",
        tare: "",
        adjustment: 0,
        companyId: "",
        loadNumber: 0,
      },
      activities: [],
      facilities: [],
      outboundMaterials: [],
      inHouseHaulers: [],
      thirdPartyHaulers: [],
      disposalRate: 0,
      disposalRateYard: 0,
      costPerLoad: 0,
      costPerTon: 0,
      costPerYard: 0,
      transportationCost: 0, 
      costPerTonHauler : 0,
      editDisposalRate: false,
      editDisposalRateYard: false,
      editTransporationCost: false,
      tz
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { activity, facilities, inHouseHaulers, thirdPartyHaulers, outboundMaterials } = this.props;
    if (activity !== prevProps.activity) {
      const { initialValues } = this.state;
      const keys = Object.keys(activity);
      if(!keys?.includes('loadNumber')){
        initialValues['loadNumber'] = 0;
      }
      if(!keys?.includes("manifest")){
        initialValues["manifest"] = [
          {
            manifestNumber: "",
            manifestNumberOther: "",
            manifestAttachments: "",
            manifestAttachmentsName : ""
          }
        ]
      }
      let mNumber = ''
      for (let k in initialValues) {
        if (activity[k] || activity[k] === "" || activity[k] === 0) {
          if (k === "date") {
            initialValues[k] = moment(activity[k]).tz(this.state.tz);
          } else if (k === "manifestNumber" && activity[k] !== "") {
            mNumber = activity[k]
          }else if(k === "materials"){
            initialValues[k] = activity[k].map((item)=>({
              ...item,
              yardage: item.yardage 
            }))
          } else {
            initialValues[k] = activity[k] === 0 ? "" : activity[k] ;
          }
        }
      }
      if(mNumber !== "") {
        initialValues["manifest"][0]["manifestNumber"] = mNumber
      }

      this.setState({
        initialValues,
        facilities,
        inHouseHaulers,
        outboundMaterials,
        thirdPartyHaulers,
        costPerLoad: activity.hauler.cost_per_load,
        costPerTonHauler: activity.hauler.cost_per_ton,
        costPerTon: activity.facility.cost_per_ton,
        costPerYard: activity.facility.cost_per_yard,
      });
    }
  }

  findHaulerById = haulerId => {
    const { inHouseHaulers, thirdPartyHaulers } = this.state;
    const allHaulers = [...inHouseHaulers, ...thirdPartyHaulers];
    return allHaulers.find(hauler => hauler._id === haulerId);
  };

  calculatedData(values) {
    const selectedFacilityId = values.facilityId;
    const selectedHaulerId = values.haulerId;
  
    const selectedFacility = this.state.facilities.find(facility => facility._id === selectedFacilityId);
    const selectedHauler = this.findHaulerById(selectedHaulerId)
  
const matchingDetail = selectedHauler?.facilityDetails?.find(detail => detail.facility === selectedFacility?.companyName);
    const { costPerTon,  editDisposalRate, editTransporationCost , costPerYard , editDisposalRateYard , transportationCost , costPerLoad , costPerTonHauler } = this.state;
    const actualGross = values.actualGross !== "" ? parseFloat(String(values.actualGross).replace(/[^-?\d.]/g, "")) : 0;
    const tare = values.tare !== "" ? parseFloat(String(values.tare).replace(/[^-?\d.]/g, "")) : 0;
    const adjustment = values.adjustment !== "" ? parseFloat(String(values.adjustment).replace(/[^-?\d.]/g, "")) : 0;
    const totalYardage = values.materials.reduce((sum, material) => {
      return sum + (material.yardage !== "" ? parseFloat(material.yardage) : 0);
    }, 0);
    const net = actualGross + adjustment - tare;
    const tonnage = parseFloat(net) / 2000;
    const disposalRate = (parseFloat(tonnage.toFixed(2)) * parseFloat(costPerTon)) + (parseFloat(costPerYard)* parseFloat(totalYardage));
    let transportation;
    if (matchingDetail) {
      transportation = (Number(tonnage.toFixed(2)) * Number(matchingDetail.cost_per_ton_for_facility)) + Number(matchingDetail.cost_per_load_for_facility);
    } else {
      transportation = (Number(tonnage.toFixed(2)) * Number(costPerTonHauler)) + Number(costPerLoad);
    }
    // const transportation = (parseFloat(tonnage) * parseFloat(costPerTonHauler)) + parseFloat(costPerLoad);
    const total = parseFloat(disposalRate) + parseFloat(transportation);
    if (this.state.transportationCost !== transportation) {
      if(transportation && transportation !== 'NaN' && !isNaN(transportation)){
        this.setState({ transportationCost: transportation },        
          this.forceUpdate()
        ); 
      }
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <ul className="complete__outbound-list">
            <li>
              <span>Net (lbs)</span>
              <span>{numberFormatLbs(net)} (lbs)</span>
            </li>
            <li>
              <span>Tonnage </span>
              <span>{tonnage.toFixed(2)}</span>
            </li>
            <ul>
           {costPerTon !== 0 && (
            <li>
              <span>Disposal Rate</span>
              {!editDisposalRate ? (
                <span onClick={() => this.setState({ editDisposalRate: true })}>
                  ${numberFormat(costPerTon)} per/ton
                </span>
              ) : (
                <NumberFormat
                  allowEmptyFormatting={true}
                  autoFocus
                  onBlur={() => this.setState({ editDisposalRate: false })}
                  prefix={"$"}
                  thousandSeparator={true}
                  displayType={"input"}
                  type="text"
                  className="form-control material-textfield-input width-50"
                  onValueChange={({ floatValue }) => {
                    const costPerTon = floatValue === undefined ? '' : floatValue;
                    this.setState({ costPerTon });
                  }}
                  value={costPerTon}
                  name="dumpRates.pricePerTonLight"
                  required
                />
              )}
            </li>
            )}
             {costPerYard !== 0 && (
            <li>
              {costPerTon === 0 ? <span> Disposal Rate</span> : <span>&nbsp;</span>}
              {!editDisposalRateYard ? (
                <span onClick={() => this.setState({ editDisposalRateYard: true })}>
                  ${numberFormat(costPerYard)} per/yard
                </span>
              ) : (
                <NumberFormat
                  allowEmptyFormatting={true}
                  autoFocus
                  onBlur={() => this.setState({ editDisposalRateYard: false })}
                  prefix={"$"}
                  thousandSeparator={true}
                  displayType={"input"}
                  type="text"
                  className="form-control material-textfield-input width-50"
                  onValueChange={({floatValue }) => {
                    const costPerYard = floatValue === undefined ? '' : floatValue;
                    this.setState({ costPerYard});
                  }}
                  value={costPerYard}
                  name="dumpRates.pricePerYardLight"
                  required
                />
              )}
            </li>
             )}
             </ul>
            <li>
              <span>Disposal Cost</span>
              <span>${numberFormat(disposalRate)}</span>
            </li>
            <li>
              <span>Trucking Cost</span>
              {!editTransporationCost ? (
                <span onClick={() => this.setState({ editTransporationCost: true })}>${numberFormat(transportationCost)}</span>
              ) : (
                <NumberFormat
                  allowEmptyFormatting={true}
                  autoFocus
                  onBlur={() => this.setState({ editTransporationCost: false })}
                  prefix={"$"}
                  thousandSeparator={true}
                  displayType={"input"}
                  type="text"
                  className="form-control material-textfield-input width-50"
                  onValueChange={({ formattedValue }) => {
                    const transportationCost =
                      formattedValue === "$" ? 0 : parseFloat(String(formattedValue).replace(/[^\d]/g, ""));
                    this.setState({ transportationCost });
                  }}
                  value={transportationCost}
                  name="dumpRates.pricePerTonLight"
                  required
                />
              )}
            </li>
            <li>
              <span>Total Cost </span>
              <span>${numberFormat(total)}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  handleSubmit = async ({ adjustment ,actualGross, tare, ...values }, { setSubmitting, setErrors }) => {
    const {
      activity: { _id },
      fetchOutboundActivities,
      onCloseClick
    } = this.props;
    const { costPerTon, transportationCost , costPerYard } = this.state;
    if(adjustment === ""){
    adjustment = 0;}
    setSubmitting(true);
    const payload = {
      ...values,
      actualGross: String(actualGross).replace(/[^-?\d.]/g, ""),
      tare: String(tare).replace(/[^-?\d.]/g, ""),
      adjustment: String(adjustment).replace(/[^-?\d.]/g, ""),
      transportationCost: String(costPerTon).replace(/[^-?\d.]/g, ""),
      disposalRate: transportationCost,
      disposalRateYard: String(costPerYard).replace(/[^\d.]/g, ""),
      status: 1,
      _id,
      completed: true,
      loadNumber: Number(values.loadNumber),
      materials:values.materials.map((item)=>({...item,yardage:item?.yardage && item?.yardage !== "" ? item?.yardage : 0})),
    };
    const {
      value: { status, message }
    } = await this.props.updateOutboundActivity(payload);
    if (status) {
      fetchOutboundActivities();
      onCloseClick();
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setErrors({ tare: message });
    }
  };

  valid = current => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  handleFacilityChange = async (event, handleChange) => {
    const { value } = event.target;
    const {facilities} = this.state
    const selectedFacility = facilities.find(facility => facility?._id === value);

    if (selectedFacility) {
      this.setState({
        costPerTon: selectedFacility.cost_per_ton,
        costPerYard: selectedFacility.cost_per_yard,
      });
    }
    handleChange(event);
  };


  handleHaulerChange = (event, handleChange) => {
    const { value } = event.target;
    const { inHouseHaulers, thirdPartyHaulers } = this.state;

   const isHaulerInHouse = inHouseHaulers.some(hauler => hauler._id === value);
   const isHaulerThirdParty = thirdPartyHaulers.some(hauler => hauler._id === value);

   if (isHaulerInHouse) {
    const selectedHauler= inHouseHaulers.find(hauler => hauler?._id === value);
    this.setState({
      costPerTonHauler: selectedHauler.cost_per_ton,
      costPerLoad: selectedHauler.cost_per_load,
    });
  } else if (isHaulerThirdParty) {
    const selectedHauler= thirdPartyHaulers.find(hauler => hauler?._id === value);
    this.setState({
      costPerTonHauler: selectedHauler.cost_per_ton,
      costPerLoad: selectedHauler.cost_per_load,
    });
  }
    handleChange(event);
  }
  
  

 
  handlePdf = (preview) => {
    window.open(preview, "_blank");
  }
    dataURItoBlob = dataURI => {
      var binary = atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    };
  
    submitImages(data,fileType) {
      const blob = this.dataURItoBlob(data);
      const formdata = new FormData();
      formdata.append("photos", blob);
      formdata.append("fileName", `${new Date().getTime()}.${fileType === "pdf" ? "pdf" : "png"}`);
      formdata.append("folderName", "load");
      formdata.append("fileType", fileType);
      return formdata;
    }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  render() {
    const { initialValues, facilities, inHouseHaulers, thirdPartyHaulers , outboundMaterials} = this.state;
    const { editModal, onCloseClick } = this.props;
    const isYardageRequired = this.props.user?.company?.isYardageRequired;
    return (
      <div>
        <ReactModal
          isOpen={editModal}
          onRequestClose={this.props.onCloseClick}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered edit__outbound__modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Complete Outbound Activity</h5>
              <button type="button" className="btn react-modal-close" onClick={onCloseClick}>
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={editActiveOutboundActivitySchema(isYardageRequired)}
              onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
            >
              {({ values, handleChange, handleSubmit, setValues, isSubmitting, setFieldValue,setSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Datetime
                            inputProps={{
                              name: "date",
                              required: true,
                              autoComplete: "off",
                              className: "form-control material-textfield-input"
                            }}
                            onChange={date => setValues({ ...values, date })}
                            isValidDate={this.valid}
                            value={values.date}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                          />
                          <ErrorMessage component="span" name="date" className="invalid-feedback d-block" />
                          <label className="material-textfield-label label__for-date">
                            Date<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="loadNumber"
                            type="number"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            // isValidDate={this.valid}
                            value={values && parseInt(values?.loadNumber, 10)}
                             Placeholder="Load Number"
                          />
                          <ErrorMessage component="span" name="loadNumber" className="invalid-feedback d-block" />
                          <label className="material-textfield-label label__for-date">
                            Load Number
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="facilityId"
                        // onChange={handleChange}
                        onChange={(event) => this.handleFacilityChange(event, handleChange)}
                        value={values && values.facilityId}
                        required
                      >
                        <option value="">Select Facility</option>
                        {facilities?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(facility => {
                          return (
                            <option value={facility._id} key={facility._id}>
                              {facility.companyName}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage component="span" name="facilityId" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Facility<span>*</span>
                      </label>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="haulerId"
                        onChange={(event) => this.handleHaulerChange(event, handleChange)}
                        value={values && values.haulerId}
                        required
                      >
                        <option value="">Select Hauler</option>
                        {inHouseHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(inHouseHauler => {
                          return (
                            <option value={inHouseHauler._id} key={inHouseHauler._id}>
                              {inHouseHauler.companyName} (In House)
                            </option>
                          );
                        })}
                        {thirdPartyHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(thirdPartyHauler => {
                          return (
                            <option value={thirdPartyHauler._id} key={thirdPartyHauler._id}>
                              {thirdPartyHauler.companyName} (Third Party)
                            </option>
                          );
                        })}
                      </select>
                      <ErrorMessage component="span" name="haulerId" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Hauler<span>*</span>
                      </label>
                    </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group material-textfield">
                      <select
                        className="form-control custom-select material-textfield-input"
                        name="truckType"
                        onChange={handleChange}
                        value={values && values.truckType}
                        required
                      >
                        <option value="">Select Truck Type</option>
                        {
                          outboundTruckTypes?.slice()?.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )?.map((item)=>{
                          return(
                            <option value={item.value}>{item.name}</option>
                          )
                          })
                        }
                      </select>
                      <ErrorMessage component="span" name="truckType" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Type of Truck<span>*</span>
                      </label>
                    </div>
                    </div>
                     
                    </div>
                    <FieldArray
                      name="materials"
                      render={arrayHelpers => (
                        <div>
                          {values.materials.map((material, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Material ${index + 1}`}</h3>
                                    {values.materials.length !== 1 && (
                                      <button
                                        className="material__label-remove"
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                  <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name={`materials[${index}].material`}
                                      value={(material?.material || '').toLowerCase()}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select Material</option>
                                      {outboundMaterials
                                        ?.filter((materiall) => materiall?.status || materiall?.materials.toLowerCase() === (material?.material || '').toLowerCase())
                                        ?.slice()
                                        ?.sort((a, b) => a?.materials?.localeCompare(b?.materials))
                                        ?.map((filteredMaterial) => {
                                          const filteredMaterialLowerCase = filteredMaterial?.materials.toLowerCase();
                                          return (
                                            <option
                                              value={filteredMaterialLowerCase}
                                              key={filteredMaterialLowerCase}
                                            >
                                              {filteredMaterial?.materials}
                                            </option>
                                          );
                                        })}

                                    </select>
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].material`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Material<span>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`materials[${index}].grossWeight`}
                                      thousandSeparator={true}
                                      value={material.grossWeight}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`materials[${index}].grossWeight`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />
                                    {/* <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                      Gross Weight at Facility (lbs)
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`materials[${index}].tareWeight`}
                                      thousandSeparator={true}
                                      value={material.tareWeight}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`materials[${index}].tareWeight`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />
                                    {/* <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].tareWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Tare Weight
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group material-textfield">
                                    <input
                                      type="number"
                                      name={`materials[${index}].yardage`}
                                      value={material.yardage}
                                      className="form-control material-textfield-input"
                                      onChange={handleChange}
                                      required
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].yardage`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                      Yardage{isYardageRequired ? <span>*</span>:""}
                                    </label>
                                  </div>
                                </div>
                                {values.materials.length - 1 === index && (
                                  <div className="col-md-12">
                                    <div className="material__label-section add__another">
                                      <h3
                                        className="material__label-title underline"
                                        onClick={() =>
                                          arrayHelpers.push({ material: "", grossWeight: "", yardage: "" })
                                        }
                                      >
                                        Add Another Material
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <FieldArray
                      name="manifest"
                      render={newArrayHelpers => (
                        <div>
                          {values.manifest.map((manifest, index) => {
                            return (
                              <div className="row" key={index}>
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Manifest ${index + 1}`}</h3>
                                    {values.manifest.length !== 1 && (
                                      <button
                                        className="material__label-remove"
                                        type="button"
                                        onClick={() => newArrayHelpers.remove(index)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                  <NumberFormat
                                      name={`manifest[${index}].manifestNumber`}
                                      value={manifest.manifestNumber}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumber`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`manifest[${index}].manifest`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                    Manifest #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`manifest[${index}].manifestNumberOther`}
                                      value={manifest.manifestNumberOther}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumberOther`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Other #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    {manifest.manifestAttachments === "" ?
                                      <Dropzone
                                      onDrop={acceptedFiles => {
                                        setSubmitting(true)
                                        if (acceptedFiles.length > 0) {
                                          let fileType = "";
                                          if (!acceptedFiles[0]) return;
                                          if (acceptedFiles[0].type === "application/pdf") {
                                            fileType = "pdf";
                                          }else {
                                            fileType = "image";
                                          }
                                          this.getBase64(acceptedFiles[0],async (result) => {
                                            const {
                                              value: { url }
                                            } = await this.props.uploadImage(this.submitImages(result,fileType));
                                            if (url) {
                                              setSubmitting(false)
                                              setFieldValue(`manifest[${index}].manifestAttachments`, url);
                                              setFieldValue(`manifest[${index}].manifestAttachmentsName`,acceptedFiles[0].name);
                                            }
                                          });
                                        }
                                      }
                                      }
                                        accept="image/png,image/jpeg,image/gif,image/jpg,application/pdf"
                                      >
                                        {({ getRootProps, getInputProps }) => (

                                          <div className="items-inner" {...getRootProps()}>
                                            <div className="circle">
                                              <input {...getInputProps()} />
                                              <h6
                                                className="material__label-title underline pt-3"
                                              >
                                                Upload Manifest #
                                              </h6>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone> :
                                    manifest?.manifestAttachments.endsWith('png') ? (
                                      <>
                                      <div className='imagebox float-left p-0'>
                                      {manifest.manifestAttachments && (
                                        <span
                                          className='CloseBtn cursor-pointer'
                                          onClick={() => {
                                            setFieldValue(`manifest[${index}].manifestAttachments`, "");
                                          setFieldValue(`manifest[${index}].manifestAttachmentsName`,"");
                                          }}>
                                          <CloseOutlined className="text-danger cursor-pointer" />
                                        </span>
                                      )}
                                    <img src={manifest.manifestAttachments} alt="Manifest Attachment" width={200} height={100} className="pb-3" />
                                    </div>
                                    </>) : (<div className="d-flex d-inline">
                                      <h6 className="material__label-title underline pt-3" onClick={()=>this.handlePdf(manifest.manifestAttachments)}>{manifest?.manifestAttachmentsName}</h6>
                                      {manifest.manifestAttachments && (
                                        <span
                                          className='CloseBtn cursor-pointer'
                                          onClick={() => {
                                          setFieldValue(`manifest[${index}].manifestAttachments`, "");
                                          setFieldValue(`manifest[${index}].manifestAttachmentsName`,"");
                                          }}>
                                          <CloseOutlined className="text-danger cursor-pointer" />
                                        </span>
                                      )}
                                    </div>)
                                    }
                                   
                                  </div>
                                </div>
                                {values.manifest.length - 1 === index && (
                                  <div className="col-md-12">
                                    <div className="material__label-section add__another">
                                      <h3
                                        className="material__label-title underline"
                                        onClick={() => {
                                          const { manifest } = values;
                                          const lastManifest = manifest[manifest.length - 1];
                                          if (
                                            lastManifest.manifestNumber !== "" ||
                                            lastManifest.manifestNumberOther !== "" ||
                                            lastManifest.manifestAttachments !== ""
                                          ) {
                                            newArrayHelpers.push({
                                              manifestNumber: "",
                                              manifestNumberOther: "",
                                              manifestAttachments: ""
                                            });
                                          }
                                        }}
                                      >
                                        Add Another Manifest #
                                      </h3>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const actualGross = formattedValue;
                              setValues({
                                ...values,
                                actualGross
                              });
                            }}
                            value={values.actualGross}
                            required
                          />
                          <ErrorMessage component="span" name="actualGross" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Actual Gross<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                             className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const tare = formattedValue;
                              setValues({
                                ...values,
                                tare
                              });
                            }}
                            value={values.tare}
                            required
                          />
                          <ErrorMessage component="span" name="tare" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Tare (lbs)<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const adjustment = formattedValue;
                              setValues({
                                ...values,
                                adjustment
                              });
                            }}
                            value={values.adjustment}
                          />
                          <label className="material-textfield-label">
                            Adjustment
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.calculatedData(values)}
                    <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-lg w-100">
                      {isSubmitting ? "Please wait..." : "Complete Entry"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
      </div>
    );
  }
}
