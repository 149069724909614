import React, { Component } from "react";
import ReactModal from "react-modal";
import moment from "moment-timezone";
import { CloseIcon } from "../../../components/icons";
import { Formik, ErrorMessage, FieldArray } from "formik";
import { editActiveOutboundActivitySchema } from "../../../utils/validations";
import Datetime from "react-datetime";
import {  numberFormat, numberFormatLbs, outboundTruckTypes } from "../../../constants/common";
import NumberFormat from "react-number-format";

export default class ShowOutboundActivity extends Component {
  constructor(props) {
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    super(props);
    this.state = {
      initialValues: {
        date: "",
        manifestNumber: "",
        manifestNumberOther: "",
        materials: [
          {
            material: "",
            grossWeight: "",
            yardage: ""
          }
        ],
        manifest: [
          {
            manifestNumber: "",
            manifestNumberOther: "",
            manifestAttachments: "",
            manifestAttachmentsName : ""
          }
        ],
        facilityId: "",
        haulerId: "",
        truckType: "",
        actualGross: "",
        tare: "",
        adjustment: "",
        companyId: "",
        loadNumber: 0,
      },
      activities: [],
      facilities: [],
      inHouseHaulers: [],
      outboundMaterials: [],
      thirdPartyHaulers: [],
      disposalRate: 0,
      costPerLoad: 0,
      costPerTon: 0,
      costPerYard: 0,
      transportationCost: 0,
      tz
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { activity, facilities, inHouseHaulers, thirdPartyHaulers , outboundMaterials } = this.props;
    if (activity !== prevProps.activity) {
      const { initialValues } = this.state;
      const keys = Object.keys(activity);
      if(!keys?.includes('loadNumber')){
        initialValues['loadNumber'] = 0;
      }
      if(!keys?.includes("manifest")){
        initialValues["manifest"] = [
          {
            manifestNumber: "",
            manifestNumberOther: "",
            manifestAttachments: "",
            manifestAttachmentsName : ""
          }
        ]
      }
      for (let k in initialValues) {
        if (activity[k] || activity[k] === 0) {
          if (k === "date") {
            initialValues[k] = moment(activity[k]).tz(this.state.tz);
          } else {
            initialValues[k] = activity[k];
          }
        }
      }
      if (activity?.adjustment === "" || activity?.adjustment === undefined) {
        this.setState({ adjustment: "0" });
      } else {
        this.setState({ adjustment: activity.adjustment });
      }
      this.setState({
        initialValues,
        facilities,
        inHouseHaulers,
        outboundMaterials,
        thirdPartyHaulers,
        costPerTon: activity.transportationCost,
        costPerYard: activity.disposalRateYard,
        transportationCost: activity.disposalRate
      });
    }
  }

  calculatedData(values) {
    const { costPerTon , costPerYard , transportationCost} = this.state;
    const actualGross = values.actualGross !== "" ? parseFloat(String(values.actualGross).replace(/[^-?\d.]/g, "")) : 0;
    const tare = values.tare !== "" ? parseFloat(String(values.tare).replace(/[^-?\d.]/g, "")) : 0;
    const adjustment = values.adjustment !== "" ? parseFloat(String(values.adjustment).replace(/[^-?\d.]/g, "")) : 0;
    const totalYardage = values.materials.reduce((sum, material) => {
      return sum + (material.yardage !== "" ? parseFloat(material.yardage) : 0);
    }, 0);
    const net = actualGross + adjustment - tare;
    const tonnage = parseFloat(net) / 2000;
    if(costPerYard === undefined){
      this.setState({costPerYard : 0})
    }
    let disposalRate = (parseFloat(tonnage.toFixed(2)) * parseFloat(costPerTon)) + (parseFloat(costPerYard)* parseFloat(totalYardage));
    disposalRate = isNaN(disposalRate) ? 0 : disposalRate
    let total = parseFloat(disposalRate) + parseFloat(transportationCost);
    total = isNaN(total)? 0 : total
    let costPerYardd = this.state.costPerYard;
    costPerYardd = isNaN(costPerYardd) ? 0 : costPerYardd
    return (
      <div className="row">
        <div className="col-md-12">
          <ul className="complete__outbound-list">
            <li>
              <span>Net (lbs)</span>
              <span>{numberFormatLbs(net)} (lbs)</span>
            </li>
            <li>
              <span>Tonnage </span>
              <span>{tonnage.toFixed(2)}</span>
            </li>
            {costPerTon !== 0 && (
            <li>
              <span>Disposal Rate</span>
              <span>${numberFormat(costPerTon)} per/ton</span>
            </li>
            )}
             {costPerYardd !== 0 && (
            <li>
                {costPerTon === 0 ? <span> Disposal Rate</span> : <span>&nbsp;</span>}
              <span>${numberFormat(costPerYardd)} per/yard</span>
            </li>
             )}
            <li>
              <span>Disposal Cost</span>
              <span>${numberFormat(disposalRate)}</span>
            </li>
            <li>
              <span>Trucking Cost </span>
              <span>${numberFormat(transportationCost)}</span>
            </li>
            <li>
              <span>Total Cost </span>
              <span>${numberFormat(total)}</span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  handleSubmit = async ({ adjustment , grossWeight, actualGross, tare, ...values }, { setSubmitting, setErrors }) => {
    const {
      activity: { _id },
      fetchOutboundActivities,
      onCloseClick
    } = this.props;
    setSubmitting(true);
    const payload = {
      ...values,
      grossWeight: String(grossWeight).replace(/[^-?\d.]/g, ""),
      actualGross: String(actualGross).replace(/[^-?\d.]/g, ""),
      adjustment: String(adjustment).replace(/[^-?\d.]/g, ""),
      tare: String(tare).replace(/[^\d]/g, ""),
      materials:values.materials.map((item)=>({...item,yardage:item?.yardage ?? 0})),
      status: 1,
      _id,
      completed: true
    };
    const {
      value: { status, message }
    } = await this.props.updateOutboundActivity(payload);
    if (status) {
      fetchOutboundActivities();
      onCloseClick();
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setErrors({ tare: message });
    }
  };
  handlePdf = (manifestAttachments) => {
    window.open(manifestAttachments, "_blank");
    }

    handleCloseClick = () => {
      this.setState(prevState => ({
        initialValues: {
          ...prevState.initialValues,
        }
      }), () => {
        this.props.onCloseClick();
      });
    };

  render() {
    const { initialValues, facilities, inHouseHaulers, thirdPartyHaulers, adjustment, outboundMaterials} = this.state;
    const { showModal } = this.props;
    const isYardageRequired = this.props.user?.company?.isYardageRequired;
    return (
      <div>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.props.onCloseClick}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered edit__outbound__modal completed__outboundactivity-modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Completed Outbound Activity</h5>
              <button type="button" className="btn react-modal-close" onClick={this.handleCloseClick}>
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={editActiveOutboundActivitySchema(isYardageRequired)}
              onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
            >
              {({ values, handleChange, handleSubmit, setValues, setFieldValue }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <Datetime
                            inputProps={{
                              name: "date",
                              required: true,
                              autoComplete: "off",
                              className: "form-control material-textfield-input"
                            }}
                            onChange={date => setValues({ ...values, date })}
                            value={values.date}
                            dateFormat="MM/DD/YYYY"
                            closeOnSelect={true}
                          />
                          <ErrorMessage component="span" name="date" className="invalid-feedback d-block" />
                          <label className="material-textfield-label datetime__label">
                            Date<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="loadNumber"
                            type="number"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            // isValidDate={this.valid}
                            value={values && parseInt(values?.loadNumber, 10)}
                          />
                          <ErrorMessage component="span" name="loadNumber" className="invalid-feedback d-block" />
                          <label className="material-textfield-label label__for-date">
                            Load Number
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control custom-select material-textfield-input"
                            name="facilityId"
                            onChange={handleChange}
                            value={values && values.facilityId}
                            required
                          >
                            <option>Select Facility</option>
                            {facilities?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(facility => {
                              return (
                                <option value={facility._id} key={facility._id}>
                                  {facility.companyName}
                                </option>
                              );
                            })}
                          </select>
                          <ErrorMessage component="span" name="facilityId" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Facility<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control custom-select material-textfield-input"
                            name="haulerId"
                            onChange={handleChange}
                            value={values && values.haulerId}
                            required
                          >
                            <option>Select Hauler</option>
                            {inHouseHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(inHouseHauler => {
                              return (
                                <option value={inHouseHauler._id} key={inHouseHauler._id}>
                                  {inHouseHauler.companyName} (In House)
                                </option>
                              );
                            })}
                            {thirdPartyHaulers?.slice()?.sort((a, b) =>
                             a.companyName.localeCompare(b.companyName)
                           )?.map(thirdPartyHauler => {
                              return (
                                <option value={thirdPartyHauler._id} key={thirdPartyHauler._id}>
                                  {thirdPartyHauler.companyName} (Third Party)
                                </option>
                              );
                            })}
                          </select>
                          <ErrorMessage component="span" name="haulerId" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Hauler<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group material-textfield">
                          <select
                            className="form-control custom-select material-textfield-input"
                            name="truckType"
                            onChange={handleChange}
                            value={values && values.truckType}
                            required
                          >
                            <option>Select Truck Type</option>
                            {
                             outboundTruckTypes?.slice()?.sort((a, b) =>
                             a.name.localeCompare(b.name)
                           )?.map((item)=>{
                             return(
                               <option value={item.value}>{item.name}</option>
                             )
                            })
                            }
                          </select>
                          <ErrorMessage component="span" name="truckType" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Type of Truck<span>*</span>
                          </label>
                        </div>
                      </div>
                      
                    </div>
                    <FieldArray
                      name="materials"
                      render={arrayHelpers => (
                        <div>
                          {values.materials.map((material, index) => {
                            return (
                              <React.Fragment key={index}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="material__label-section">
                                      <h3 className="material__label-title">{`Material ${index + 1}`}</h3>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group material-textfield">
                                      <select
                                      className="form-control custom-select w-150 material-textfield-input pr-22"
                                      name={`materials[${index}].material`}
                                      value={(material?.material || '').toLowerCase()}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option value="">Select Material</option>
                                      {outboundMaterials
                                         ?.filter((materiall) => materiall?.status || materiall?.materials.toLowerCase() === (material?.material || '').toLowerCase())
                                        ?.slice() 
                                        ?.sort((a, b) => a?.materials?.localeCompare(b?.materials)) 
                                        ?.map((filteredMaterial) => {
                                          const filteredMaterialLowerCase = filteredMaterial?.materials.toLowerCase();
                                          const materialName = filteredMaterial?.materials;
                                          const displayText = materialName?.length > 22 ? `${materialName?.substring(0, 22)}...` : materialName;
                                          return (
                                            <option
                                              value={filteredMaterialLowerCase}
                                              key={filteredMaterialLowerCase}
                                            >
                                               {displayText} 
                                            </option>
                                          );
                                        })}
                                    </select>
                                      <ErrorMessage
                                        component="span"
                                        name={`materials[${index}].material`}
                                        className="invalid-feedback d-block"
                                      />
                                      <label className="material-textfield-label">
                                        Material<span>*</span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group material-textfield">
                                      <NumberFormat
                                        name={`materials[${index}].grossWeight`}
                                        thousandSeparator={true}
                                        value={material.grossWeight}
                                        displayType={"input"}
                                        onValueChange={values => {
                                          const { value } = values;
                                          setFieldValue(`materials[${index}].grossWeight`, value);
                                        }}
                                        type="text"
                                        className="form-control material-textfield-input"
                                      />
                                      {/* <ErrorMessage
                                        component="span"
                                        name={`materials[${index}].grossWeight`}
                                        className="invalid-feedback d-block"
                                      /> */}
                                      <label className="material-textfield-label">
                                        Gross Weight at Facility (lbs)
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group material-textfield">
                                      <NumberFormat
                                        name={`materials[${index}].tareWeight`}
                                        thousandSeparator={true}
                                        value={material.tareWeight}
                                        displayType={"input"}
                                        onValueChange={values => {
                                          const { value } = values;
                                          setFieldValue(`materials[${index}].tareWeight`, value);
                                        }}
                                        type="text"
                                        className="form-control material-textfield-input"
                                      />
                                      {/* <ErrorMessage
                                        component="span"
                                        name={`materials[${index}].tareWeight`}
                                        className="invalid-feedback d-block"
                                      /> */}
                                      <label className="material-textfield-label">
                                      Tare Weight
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group material-textfield">
                                      <input
                                        type="number"
                                        name={`materials[${index}].yardage`}
                                        value={material.yardage}
                                        className="form-control material-textfield-input"
                                        onChange={handleChange}
                                        required
                                      />
                                      <ErrorMessage
                                        component="span"
                                        name={`materials[${index}].yardage`}
                                        className="invalid-feedback d-block"
                                      />
                                      <label className="material-textfield-label">
                                        Yardage{isYardageRequired ? <span>*</span>:""}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    />
                    <br/>
                    <FieldArray
                      name="manifest"
                      render={newArrayHelpers => (
                        <div>
                          {values.manifest.map((manifest, index) => {
                            return (
                              <React.Fragment key={index}>
                              <div className="row" >
                                <div className="col-md-12">
                                  <div className="material__label-section">
                                    <h3 className="material__label-title">{`Manifest ${index + 1}`}</h3>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                  <NumberFormat
                                      name={`manifest[${index}].manifestNumber`}
                                      value={manifest.manifestNumber}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumber`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />
                                    <ErrorMessage
                                      component="span"
                                      name={`manifest[${index}].manifest`}
                                      className="invalid-feedback d-block"
                                    />
                                    <label className="material-textfield-label">
                                    Manifest #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield">
                                    <NumberFormat
                                      name={`manifest[${index}].manifestNumberOther`}
                                      value={manifest.manifestNumberOther}
                                      displayType={"input"}
                                      onValueChange={values => {
                                        const { value } = values;
                                        setFieldValue(`manifest[${index}].manifestNumberOther`, value);
                                      }}
                                      type="text"
                                      className="form-control material-textfield-input"
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Other #
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group material-textfield h-25">
                                  <input
                                      name={`manifest[${index}].manifestAttachments`}
                                      type="text"
                                      className="form-control material-textfield-input p-0 "
                                      style={{display:'none'}}
                                    />{/* 
                                    <ErrorMessage
                                      component="span"
                                      name={`materials[${index}].grossWeight`}
                                      className="invalid-feedback d-block"
                                    /> */}
                                    <label className="material-textfield-label">
                                    Manifest Attachment
                                    </label>
                                  {manifest.manifestAttachments === "" ?
                                      null:
                                    manifest?.manifestAttachments.endsWith('png') ? (
                                      <>
                                       <br />
                                    <br />
                                      <div className='imagebox float-left p-0'>
                                    <img src={manifest.manifestAttachments} alt="Manifest Attachment" width={200} height={100} className="pb-3" />
                                    </div>
                                    </>) : (<><br /><div className="d-flex d-inline">
                                    
                                      <h6 className="material__label-title underline pt-3" onClick={()=>this.handlePdf(manifest.manifestAttachments)}>{manifest?.manifestAttachmentsName}</h6>
                                    </div></>)
                                    }
                                  </div>
                                </div>
                              </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )}
                    /> <hr />
                    <div className="row faclity__container">
                      <div className="w-45">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            type="text"
                            decimalScale={2}
                            fixedDecimalScale={true}
                            className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const actualGross = formattedValue;
                              setValues({
                                ...values,
                                actualGross
                              });
                            }}
                            value={values.actualGross}
                            required
                          />
                          <ErrorMessage component="span" name="actualGross" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Actual Gross<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="w-45">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const tare = formattedValue;
                              setValues({
                                ...values,
                                tare
                              });
                            }}
                            value={values.tare}
                            required
                          />
                          <ErrorMessage component="span" name="tare" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Tare (lbs)<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="w-45">
                        <div className="form-group material-textfield">
                          <NumberFormat
                            thousandSeparator={true}
                            displayType={"input"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="text"
                            className="form-control material-textfield-input"
                            onValueChange={_values => {
                              const { formattedValue } = _values;
                              const adjustment = formattedValue;
                              setValues({
                                ...values,
                                adjustment
                              });
                            }}
                            value={adjustment}
                          />
                          <label className="material-textfield-label">
                            Adjustment
                          </label>
                        </div>
                      </div>
                    </div>
                    {this.calculatedData(values)}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
      </div>
    );
  }
}
