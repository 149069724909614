import { connect } from 'react-redux'
import { getMe ,updateUserToken,fetchMe} from '../../store/user/duck'
import { createResource, fetchResources, deleteResource, updateResource } from '../../store/resource/duck'
import ResourcesComponent from './component'

const ResourcesContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
    fetchMe,
  	createResource,
    updateUserToken,
  	fetchResources,
    deleteResource,
    updateResource
  }
)(ResourcesComponent)

export default ResourcesContainer
