import { connect } from 'react-redux'
import { getMe, fetchMe ,updateUserToken} from '../../store/user/duck'
import { fetchTransactions } from "../../store/transaction/duck";
import CreditCardTransactionsComponent from './component'

const CreditCardTransactionsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    fetchTransactions,
    updateUserToken
  }
)(CreditCardTransactionsComponent);

export default CreditCardTransactionsContainer
