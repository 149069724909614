import React, { PureComponent } from "react";
import { ErrorMessage } from "formik";
import NumberFormat from "react-number-format";
import "./styles.scss";


export default class DumpRatesComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isCapoutPrice: false,
      onSelectFocus: false,
      isModelOpen: false
    };
  }

  setDefaultOption(e) {
    this.props.setDefaultOption(e);
  }

  render() {
    const { onSelectFocus } = this.state;
    const { values, isSubmitting } = this.props;
    return (
      <div>
        <div className="dumprates__wrapper">
          <div>
            <div className="card">
              <div className="card-header d-flex flex-column align-items-start">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="card-title">Dump Rates</h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group material-textfield">
                      <select
                        id="dumpRates"
                        name="dumpRates.defaultOption"
                        className="form-control material-textfield-input custom-select w-150 pr-22"
                        value={values.dumpRates.defaultOption}
                        onChange={e => this.setDefaultOption(e)}
                        required
                        onFocus={() => this.setState({ onSelectFocus: true })}
                        onBlur={() => this.setState({ onSelectFocus: false })}
                      >
                        <option value="">{!onSelectFocus ? "" : "Select Dump Rate"}</option>
                        <option value="pricePerTonLight">Price per ton (light)</option>
                        <option value="pricePerTonHeavy">Price per ton (heavy)</option>
                        <option value="pricePerTonMixedCD">Price per ton Mixed C&D</option>
                        <option value="pricePerTonWood">Price per ton Wood</option>
                        <option value="pricePerTonMetal">Price per ton Metal</option>
                        <option value="pricePerTonRoofing">Price per ton Roofing</option>
                        <option value="pricePerTonMasonry">Price per ton Masonry</option>
                        <option value="pricePerTonBrick">Price per ton Brick</option>
                        <option value="pricePerTonDirt">Price per ton Dirt</option>
                        <option value="pricePerTonConcrete">Price per ton Concrete</option>
                        <option value="pricePerTonCD">Price per ton C&D</option>
                        <option value="pricePerTonLowRecovery">Price per ton Low Recovery</option>
                        <option value="pricePerTonOpenContainer">Price per ton Open Container</option>
                        <option value="pricePerTonClosedContainer">Price per ton Closed Container</option>
                        <option value="pricePerTonTrailer">Price per ton Trailer</option>
                        <option value="pricePerTonAsbestos">Price per ton Asbestos</option>
                        <option value="pricePerYardLight">Price per yard (light)</option>
                        <option value="pricePerYardHeavy">Price per yard (heavy)</option>
                        <option value="pricePerYardMixedCD">Price per yard Mixed C&D</option>
                        <option value="pricePerYardWood">Price per yard Wood</option>
                        <option value="pricePerYardMetal">Price per yard Metal</option>
                        <option value="pricePerYardRoofing">Price per yard Roofing</option>
                        <option value="pricePerYardMasonry">Price per yard Masonry</option>
                        <option value="pricePerYardBrick">Price per yard Brick</option>
                        <option value="pricePerYardDirt">Price per yard Dirt</option>
                        <option value="pricePerYardConcrete">Price per yard Concrete</option>
                        <option value="pricePerYardCD">Price per yard C&D</option>
                        <option value="pricePerYardLowRecovery">Price per yard Low Recovery</option>
                        <option value="pricePerYardOpenContainer">Price per yard Open Container</option>
                        <option value="pricePerYardClosedContainer">Price per yard Closed Container</option>
                        <option value="pricePerYardTrailer">Price per yard Trailer</option>
                        <option value="pricePerYardAsbestos">Price per yard Asbestos</option>
                      </select>
                      <label className="material-textfield-label">Set default price</label>
                      <ErrorMessage
                        component="span"
                        name="dumpRates.defaultOption"
                        className="invalid-feedback d-block"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonLight = formattedValue;
                          this.props.setDefaultValues("pricePerTonLight", pricePerTonLight);
                        }}
                        value={values.dumpRates.pricePerTonLight}
                        name="dumpRates.pricePerTonLight"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonLight"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Price per ton (light)</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonHeavy = formattedValue;
                          this.props.setDefaultValues("pricePerTonHeavy", pricePerTonHeavy);
                        }}
                        value={values.dumpRates.pricePerTonHeavy}
                        name="dumpRates.pricePerTonHeavy"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonHeavy"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Price per ton (heavy)</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardLight = formattedValue;
                          this.props.setDefaultValues("pricePerYardLight", pricePerYardLight);
                        }}
                        value={values.dumpRates.pricePerYardLight}
                        name="dumpRates.pricePerYardLight"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardLight"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Price per yard (light)</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardHeavy = formattedValue;
                          this.props.setDefaultValues("pricePerYardHeavy", pricePerYardHeavy);
                        }}
                        value={values.dumpRates.pricePerYardHeavy}
                        name="dumpRates.pricePerYardHeavy"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardHeavy"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Price per yard (heavy)</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="dump__rates-title">Price per ton by material</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonMixedCD = formattedValue;
                          this.props.setDefaultValues("pricePerTonMixedCD", pricePerTonMixedCD);
                        }}
                        value={values.dumpRates.pricePerTonMixedCD}
                        name="dumpRates.pricePerTonMixedCD"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonMixedCD"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Mixed C&D</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonWood = formattedValue;
                          this.props.setDefaultValues("pricePerTonWood", pricePerTonWood);
                        }}
                        value={values.dumpRates.pricePerTonWood}
                        name="dumpRates.pricePerTonWood"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonWood"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Wood</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonMetal = formattedValue;
                          this.props.setDefaultValues("pricePerTonMetal", pricePerTonMetal);
                        }}
                        value={values.dumpRates.pricePerTonMetal}
                        name="dumpRates.pricePerTonMetal"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonMetal"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Metal</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonRoofing = formattedValue;
                          this.props.setDefaultValues("pricePerTonRoofing", pricePerTonRoofing);
                        }}
                        value={values.dumpRates.pricePerTonRoofing}
                        name="dumpRates.pricePerTonRoofing"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonRoofing"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Roofing</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonMasonry = formattedValue;
                          this.props.setDefaultValues("pricePerTonMasonry", pricePerTonMasonry);
                        }}
                        value={values.dumpRates.pricePerTonMasonry}
                        name="dumpRates.pricePerTonMasonry"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonMasonry"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Masonry</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonBrick = formattedValue;
                          this.props.setDefaultValues("pricePerTonBrick", pricePerTonBrick);
                        }}
                        value={values.dumpRates.pricePerTonBrick}
                        name="dumpRates.pricePerTonBrick"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonBrick"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Brick</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonDirt = formattedValue;
                          this.props.setDefaultValues("pricePerTonDirt", pricePerTonDirt);
                        }}
                        value={values.dumpRates.pricePerTonDirt}
                        name="dumpRates.pricePerTonDirt"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonDirt"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Dirt</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonConcrete = formattedValue;
                          this.props.setDefaultValues("pricePerTonConcrete", pricePerTonConcrete);
                        }}
                        value={values.dumpRates.pricePerTonConcrete}
                        name="dumpRates.pricePerTonConcrete"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonConcrete"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Concrete</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonCD = formattedValue;
                          this.props.setDefaultValues("pricePerTonCD", pricePerTonCD);
                        }}
                        value={values.dumpRates.pricePerTonCD}
                        name="dumpRates.pricePerTonCD"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonCD"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">{`C&D`}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonLowRecovery = formattedValue;
                          this.props.setDefaultValues("pricePerTonLowRecovery", pricePerTonLowRecovery);
                        }}
                        value={values.dumpRates.pricePerTonLowRecovery}
                        name="dumpRates.pricePerTonLowRecovery"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonLowRecovery"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Low Recovery</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonOpenContainer = formattedValue;
                          this.props.setDefaultValues("pricePerTonOpenContainer", pricePerTonOpenContainer);
                        }}
                        value={values.dumpRates.pricePerTonOpenContainer}
                        name="dumpRates.pricePerTonOpenContainer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonOpenContainer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Open Container</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonClosedContainer = formattedValue;
                          this.props.setDefaultValues("pricePerTonClosedContainer", pricePerTonClosedContainer);
                        }}
                        value={values.dumpRates.pricePerTonClosedContainer}
                        name="dumpRates.pricePerTonClosedContainer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonClosedContainer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Closed Container</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonTrailer = formattedValue;
                          this.props.setDefaultValues("pricePerTonTrailer", pricePerTonTrailer);
                        }}
                        value={values.dumpRates.pricePerTonTrailer}
                        name="dumpRates.pricePerTonTrailer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonTrailer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Trailer</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerTonAsbestos = formattedValue;
                          this.props.setDefaultValues("pricePerTonAsbestos", pricePerTonAsbestos);
                        }}
                        value={values.dumpRates.pricePerTonAsbestos}
                        name="dumpRates.pricePerTonAsbestos"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerTonAsbestos"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Asbestos</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="dump__rates-title">Price per yard by material</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardMixedCD = formattedValue;
                          this.props.setDefaultValues("pricePerYardMixedCD", pricePerYardMixedCD);
                        }}
                        value={values.dumpRates.pricePerYardMixedCD}
                        name="dumpRates.pricePerYardMixedCD"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardMixedCD"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Mixed C&D</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardWood = formattedValue;
                          this.props.setDefaultValues("pricePerYardWood", pricePerYardWood);
                        }}
                        value={values.dumpRates.pricePerYardWood}
                        name="dumpRates.pricePerYardWood"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardWood"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Wood</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardMetal = formattedValue;
                          this.props.setDefaultValues("pricePerYardMetal", pricePerYardMetal);
                        }}
                        value={values.dumpRates.pricePerYardMetal}
                        name="dumpRates.pricePerYardMetal"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardMetal"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Metal</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardRoofing = formattedValue;
                          this.props.setDefaultValues("pricePerYardRoofing", pricePerYardRoofing);
                        }}
                        value={values.dumpRates.pricePerYardRoofing}
                        name="dumpRates.pricePerYardRoofing"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardRoofing"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Roofing</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardMasonry = formattedValue;
                          this.props.setDefaultValues("pricePerYardMasonry", pricePerYardMasonry);
                        }}
                        value={values.dumpRates.pricePerYardMasonry}
                        name="dumpRates.pricePerYardMasonry"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardMasonry"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Masonry</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardBrick = formattedValue;
                          this.props.setDefaultValues("pricePerYardBrick", pricePerYardBrick);
                        }}
                        value={values.dumpRates.pricePerYardBrick}
                        name="dumpRates.pricePerYardBrick"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardBrick"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Brick</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardDirt = formattedValue;
                          this.props.setDefaultValues("pricePerYardDirt", pricePerYardDirt);
                        }}
                        value={values.dumpRates.pricePerYardDirt}
                        name="dumpRates.pricePerYardDirt"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardDirt"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Dirt</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardConcrete = formattedValue;
                          this.props.setDefaultValues("pricePerYardConcrete", pricePerYardConcrete);
                        }}
                        value={values.dumpRates.pricePerYardConcrete}
                        name="dumpRates.pricePerYardConcrete"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardConcrete"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Concrete</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardCD = formattedValue;
                          this.props.setDefaultValues("pricePerYardCD", pricePerYardCD);
                        }}
                        value={values.dumpRates.pricePerYardCD}
                        name="dumpRates.pricePerYardCD"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardCD"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">{`C&D`}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardLowRecovery = formattedValue;
                          this.props.setDefaultValues("pricePerYardLowRecovery", pricePerYardLowRecovery);
                        }}
                        value={values.dumpRates.pricePerYardLowRecovery}
                        name="dumpRates.pricePerYardLowRecovery"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardLowRecovery"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Low Recovery</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardOpenContainer = formattedValue;
                          this.props.setDefaultValues("pricePerYardOpenContainer", pricePerYardOpenContainer);
                        }}
                        value={values.dumpRates.pricePerYardOpenContainer}
                        name="dumpRates.pricePerYardOpenContainer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardOpenContainer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Open Container</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardClosedContainer = formattedValue;
                          this.props.setDefaultValues("pricePerYardClosedContainer", pricePerYardClosedContainer);
                        }}
                        value={values.dumpRates.pricePerYardClosedContainer}
                        name="dumpRates.pricePerYardClosedContainer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardClosedContainer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Closed Container</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardTrailer = formattedValue;
                          this.props.setDefaultValues("pricePerYardTrailer", pricePerYardTrailer);
                        }}
                        value={values.dumpRates.pricePerYardTrailer}
                        name="dumpRates.pricePerYardTrailer"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardTrailer"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Trailer</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const pricePerYardAsbestos = formattedValue;
                          this.props.setDefaultValues("pricePerYardAsbestos", pricePerYardAsbestos);
                        }}
                        value={values.dumpRates.pricePerYardAsbestos}
                        name="dumpRates.pricePerYardAsbestos"
                        required
                      />
                      <ErrorMessage
                        component="span"
                        name="dumpRates.pricePerYardAsbestos"
                        className="invalid-feedback d-block"
                      />
                      <label className="material-textfield-label">Asbestos</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="dump__rates-title">Fixed Items</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        onChange={e => this.props.setInitialValues(e)}
                        className="form-control material-textfield-input"
                        value={values.mattress}
                        decimalScale={2}
                        name="mattress"
                        required
                      />
                      <label className="material-textfield-label">Mattress</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.tire}
                        name="tire"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Tire</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.sofa}
                        name="sofa"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Sofa</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.tv}
                        name="tv"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">TV</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.refrigerator}
                        name="refrigerator"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Refrigerator</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.appliances}
                        name="appliances"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Appliances</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.hardToHandle}
                        name="hardToHandle"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Hard to Handle</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.overage}
                        name="overage"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Overage</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.waitTime}
                        name="waitTime"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Wait Time</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={e => this.props.setInitialValues(e)}
                        value={values.travelFee}
                        name="travelFee"
                        decimalScale={2}
                        required
                      />
                      <label className="material-textfield-label">Travel Fee</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        name="capoutPrice"
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const capoutPrice = formattedValue;
                          this.props.setInitialValue("capoutPrice", capoutPrice);
                        }}
                        value={values.capoutPrice}
                        required
                      />
                      <ErrorMessage component="span" name="capoutPrice" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">Capout Price Per Yard</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group material-textfield">
                      <NumberFormat
                        name="minimumPrice"
                        allowEmptyFormatting={true}
                        prefix={"$"}
                        thousandSeparator={true}
                        displayType={"input"}
                        type="text"
                        className="form-control material-textfield-input"
                        onValueChange={_values => {
                          const { formattedValue } = _values;
                          const minimumPrice = formattedValue;
                          this.props.setInitialValue("minimumPrice", minimumPrice);
                        }}
                        value={values.minimumPrice}
                        required
                      />
                      <ErrorMessage component="span" name="minimumPrice" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">Minimum Price</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <button disabled={isSubmitting} className="btn btn-dark btn-lg w-100" type="submit">
                      {isSubmitting ? "Please wait..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    );
  }
}
