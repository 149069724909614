import React from "react";
import CurrencyInput from "react-currency-input-field";

const MoneyInput = ({value,onChange,inputRef}) => {
  return (
    <CurrencyInput
    allowDecimals
      id="input-example"
      name="input-name"
      className="form-control material-textfield-input"
      defaultValue={value}
      decimalsLimit={2}
      value={isNaN(value) ? "" : value}
      onValueChange={onChange}
      ref={inputRef}
      intlConfig={{ locale: 'en-US', currency: 'USD' }}
    />
  );
};

export default MoneyInput;
