import { connect } from 'react-redux'
import { getMe, logout ,updateUserToken,fetchMe} from "../../store/user/duck";
import { fetchAllItems } from "../../store/dashboard/duck";
import { saveAmount, fetchClosingBalance } from "../../store/transaction/duck";
import { getNotifications, getUnreadNotificationCount, readNotifications, clearNotifications } from "../../store/notifications/duck";
import { setSearchText } from '../../store/Billing/duck'
import TopNavigationComponent from './component'

const TopNavigationContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateUserToken,
    fetchMe,
    fetchAllItems,
    saveAmount,
    fetchClosingBalance,
    getNotifications,
    getUnreadNotificationCount,
    readNotifications,
    clearNotifications,
    setSearchText,
    logout
  }
)(TopNavigationComponent);

export default TopNavigationContainer
