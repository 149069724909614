import React, { PureComponent } from "react";
import _ from "lodash";
import { Switch } from "antd";
import InputMask from "react-input-mask";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { CloseIcon } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { settingSchema } from "../../utils/validations";
import PlacesAutocomplete from "../../components/forms/PlaceSearchBox";
import CitiesAutocomplete from "../../components/forms/CitiesSearchBox";
import CloseGray from "../../images/close_gray.svg";
import NumberFormat from "react-number-format";
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

export default class SettingsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        businessName: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        fax: "",
        dsnyTransferStation: "",
        nycdecPermit: "",
        scaleip: "",
        scaleport: "",
        autoLogoutDuration: 30,
        timezone: "America/New_York",
        domain: "",
        orderPrefix: "",
        invoicePrefix:"",
        billingEmail:""
      },
      logo: "",
      address: "",
      user: null,
      isValidisFuelSurchardeFee:true,
      microblinkEnabled: true,
      chargeStripeCardFees: true,
      chargeStripeCardFeesAmount: "3",
      errchargeStripeCardFeesAmount: "",
      isEnvironmentalLoadFee: false,
      is_open_pdf_when_an_order_complete: false,
      serviceAddress: false,
      environmentalLoadFeeAmount: "",
      isFuelSurchardeFee: false,
      fuelSurchardeFeeAmount: "",
      signatureRequired: false,
      isSignaturePadIntegration: false,
      cities: [],
      city: "",
      errEnvironmentalLoadFeeAmount: "",
      errFuelSurchardeFeeAmount: "",
      PleaseWait:false,
      payabliActive:false,
      isYardageRequired:false,
      salesTaxFeeAmount: "",
      isSalesTax : false,
      errisSalesTax: "",
      isRoundOffAmount: false,
      emailForPaymentsOnOrders: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getMe();
    document.title = "Business Settings | Weighworks";
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.user && this.state.user !== prevState.user) {
      this.fetchSetting();
      this.checkForPayabli();
    }
  }
  checkForPayabli = async () => {
    const { value } = await this.props.checkPabliIntegration({ companyId:this.state.user.companyId })
    this.setState({payabliActive:value?.status});
  }

  fetchSetting = async () => {
    try {
      const { fetchSetting } = this.props;
      const {
        value: {
          data: {
            businessInfo,
            logo,
            scaleip,
            scaleport,
            autoLogoutDuration,
            microblinkEnabled,
            chargeStripeCardFees,
            chargeStripeCardFeesAmount,
            signatureRequired,
            timezone,
            domain,
            cities,
            isEnvironmentalLoadFee,
            environmentalLoadFeeAmount,
            is_open_pdf_when_an_order_complete,
            serviceAddress,
            isFuelSurchardeFee,
            fuelSurchardeFeeAmount,
            orderPrefix,
            invoicePrefix,
            billingEmail,
            isYardageRequired,
            isSalesTax,
            salesTaxFeeAmount,
            isRoundOffAmount,
            emailForPaymentsOnOrders,
            isSignaturePadIntegration
          },
        }
      } = await fetchSetting(this.state.user.companyId);
      const initialValues = this.state.initialValues;
      if (businessInfo) {
        this.setState({
          initialValues: { ...businessInfo, scaleip, scaleport, autoLogoutDuration, timezone, domain, orderPrefix,invoicePrefix, billingEmail},
          address: businessInfo.address,
          logo,
          scaleip,
          scaleport,
          microblinkEnabled,
          chargeStripeCardFees,
          chargeStripeCardFeesAmount,
          signatureRequired,
          cities: cities ? cities : [],
          isEnvironmentalLoadFee,
          environmentalLoadFeeAmount,
          is_open_pdf_when_an_order_complete,
          serviceAddress,
          isFuelSurchardeFee,
          fuelSurchardeFeeAmount,
          isYardageRequired,
          isSalesTax,
          salesTaxFeeAmount,
          isRoundOffAmount,
          emailForPaymentsOnOrders,
          isSignaturePadIntegration
        });
      } else {
        this.setState({
          initialValues: {
            ...initialValues,
            scaleip,
            scaleport,
            autoLogoutDuration,
            timezone,
            domain,
            cities: cities ? cities : [],
            isEnvironmentalLoadFee,
            environmentalLoadFeeAmount,
            is_open_pdf_when_an_order_complete,
            serviceAddress,
            isFuelSurchardeFee,
            fuelSurchardeFeeAmount,
            salesTaxFeeAmount,
            isSalesTax,
            orderPrefix,
            invoicePrefix
          }
        });
      }
    } catch (err) {
      // console.log({ err });
    }
  };

  handleImage = (e, setSubmitting) => {
    setSubmitting(true);
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function(r) {
        const formdata = new FormData();
        formdata.append("photos", input.files[0]);
        formdata.append("fileName", input.files[0].name);
        formdata.append("folderName", "company");
        const {
          value: { url }
        } = await this.props.uploadImage(formdata);
        this.setState({ logo: url }, () => {
          setSubmitting(false);
        });
      }.bind(this);
      reader.readAsDataURL(input.files[0]);
    }
  };

  removeCity(city) {
    const cities = [...this.state.cities];
    const index = cities.indexOf(city);
    if (index !== -1) {
      cities.splice(index, 1);
      this.setState({ cities });
    }
  }

  isEnvironmentalLoadFee(environmentalLoadFeeAmount,setSubmitting){
    let amount=0;
    if (this.state.isEnvironmentalLoadFee) {
      amount = String(environmentalLoadFeeAmount).replace(/[^\d.]/g, "");
      if (amount === "" || amount === "0") {
        this.setState({ errEnvironmentalLoadFeeAmount: "Please enter the Environmental Load Fee Amount" });
        this.setState({isValidisFuelSurchardeFee:false})
        setSubmitting(true)
      }else{
        this.setState({isValidisFuelSurchardeFee:true})
        this.setState({ errEnvironmentalLoadFeeAmount: "" });
        setSubmitting(false)
      }
    }
  }

  isSalesTax(salesTaxFeeAmount,setSubmitting){
    const {isSalesTax}=this.state
    if (isSalesTax) {
      if(salesTaxFeeAmount > 100){
        this.setState({ errisSalesTax: "SalesTax cannot be more than 100%." });
        this.setState({isValidisFuelSurchardeFee:false})
        setSubmitting(true)
      }
        if(salesTaxFeeAmount <= 100){
        this.setState({ errisSalesTax: "" });
        this.setState({isValidisFuelSurchardeFee:true})
        setSubmitting(false)
      }
      }
  }


  isFuelSurchardeFee(fuelSurchardeFeeAmount,setSubmitting){
    let fuelAmount = 0;
    const {isFuelSurchardeFee}=this.state
    if (isFuelSurchardeFee) {
      if(fuelSurchardeFeeAmount > 100){
        this.setState({ errFuelSurchardeFeeAmount: "Fuel Surcharge cannot be more than 100% of the subtotal amount." });
        this.setState({isValidisFuelSurchardeFee:false})
        setSubmitting(true)
      }
      fuelAmount = String(fuelSurchardeFeeAmount).replace(/[^\d.]/g, "");
      if (fuelAmount === "" || fuelAmount === "0") {
        this.setState({ errFuelSurchardeFeeAmount: "Please enter the fuel Surcharge Fee" });
        this.setState({isValidisFuelSurchardeFee:false})
        setSubmitting(true)
      }
      if(fuelSurchardeFeeAmount < 100 && !(fuelAmount === "" || fuelAmount === "0")){
        this.setState({ errFuelSurchardeFeeAmount: "" });
        this.setState({isValidisFuelSurchardeFee:true})
        setSubmitting(false)
      }
      }
    }
    isHandlingFee(chargeStripeCardFeesAmount,setSubmitting){
      let handlingFee = 0;
      const {chargeStripeCardFees}=this.state
      if (chargeStripeCardFees) {
        if(chargeStripeCardFeesAmount > 100){
          this.setState({ errchargeStripeCardFeesAmount: "Handling Fee cannot be more than 100%." });
          this.setState({isValidisFuelSurchardeFee:false})
          setSubmitting(true)
        }
        handlingFee = String(chargeStripeCardFeesAmount).replace(/[^\d.]/g, "");
        if (handlingFee === "") {
          this.setState({ errchargeStripeCardFeesAmount: "Please enter the Handling Fee" });
          this.setState({isValidisFuelSurchardeFee:false})
          setSubmitting(true)
        }
        if(chargeStripeCardFeesAmount < 100 && !(handlingFee === "" )){
          this.setState({ errchargeStripeCardFeesAmount: "" });
          this.setState({isValidisFuelSurchardeFee:true})
          setSubmitting(false)
        }
        }
      }
    handleSubmit = async (values, { setErrors, setValues }) => {
      if (this.state.address === '') {
        setValues({
          ...values,
          address:""
  
        })
        setErrors({ address: "Address is required" });
      } else {
    this.setState({PleaseWait:true})
    const { updateCompany } = this.props;
    let amount = 0;
    let fuelAmount=0;
    let handlingFee=0;
    let salesTaxAmount = "";
    this.setState({ errEnvironmentalLoadFeeAmount: "", errFuelSurchardeFeeAmount: "" , errisSalesTax: "" , errchargeStripeCardFeesAmount: "" });
    const {
      user: { companyId },
      logo,
      microblinkEnabled,
      chargeStripeCardFees,
      chargeStripeCardFeesAmount,
      signatureRequired,
      cities,
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      is_open_pdf_when_an_order_complete,
      serviceAddress,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      isYardageRequired,
      isSalesTax,
      salesTaxFeeAmount,
      isRoundOffAmount,
      emailForPaymentsOnOrders
    } = this.state;
    const { scaleip, scaleport, autoLogoutDuration, timezone, domain, orderPrefix,invoicePrefix, billingEmail } = values;
    delete values.scaleip;
    delete values.scaleport;
    delete values.autoLogoutDuration;
    delete values.timezone;
    delete values.domain;
    delete values.billingEmail;
   amount=isEnvironmentalLoadFee ? String(environmentalLoadFeeAmount).replace(/[^\d.]/g, ""):0
    fuelAmount=fuelSurchardeFeeAmount ? String(fuelSurchardeFeeAmount).replace(/[^\d.]/g, "") : 0 
    handlingFee= chargeStripeCardFeesAmount ? String(chargeStripeCardFeesAmount).replace(/[^\d.]/g, "") : 3 
    salesTaxAmount=salesTaxFeeAmount ? String(salesTaxFeeAmount).replace(/[^\d.]/g, "") : null
    localStorage.setItem("timezone", timezone)
    if (this.state.isValidisFuelSurchardeFee) {
      const {
        value: { status }
      } = await updateCompany({
        businessInfo: values,
        companyId,
        logo,
        scaleip,
        scaleport,
        autoLogoutDuration,
        microblinkEnabled,
        chargeStripeCardFees,
        chargeStripeCardFeesAmount: parseFloat(handlingFee),
        signatureRequired,
        timezone,
        domain,
        cities: _.sortBy(cities),
        isEnvironmentalLoadFee,
        environmentalLoadFeeAmount: parseFloat(amount),
        is_open_pdf_when_an_order_complete,
        serviceAddress,
        isFuelSurchardeFee,
        fuelSurchardeFeeAmount: parseFloat(fuelAmount),
        salesTaxFeeAmount: parseFloat(salesTaxAmount),
        orderPrefix,
        invoicePrefix,
        billingEmail,
        isYardageRequired,
        isSalesTax,
        isRoundOffAmount,
        emailForPaymentsOnOrders
      });
      if (status) {
        setTimeout(()=>{
          this.props.onCloseClick()
        }, 500);
        
      }
    }
  }
  };

  render() {
    const {
      user,
      initialValues,
      microblinkEnabled,
      signatureRequired,
      chargeStripeCardFees,
      chargeStripeCardFeesAmount,
      errchargeStripeCardFeesAmount,
      isEnvironmentalLoadFee,
      environmentalLoadFeeAmount,
      is_open_pdf_when_an_order_complete,
      isFuelSurchardeFee,
      fuelSurchardeFeeAmount,
      city,
      serviceAddress,
      cities,
      errEnvironmentalLoadFeeAmount,
      errFuelSurchardeFeeAmount,
      isYardageRequired,
      isSalesTax,
      errisSalesTax,
      salesTaxFeeAmount,
      isRoundOffAmount,
      emailForPaymentsOnOrders,
      isSignaturePadIntegration
    } = this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.isBusinessSettingModelOpen}
          // isOpen={this.state.modalIsOpen}
          onRequestClose={this.props.onCloseClick}
          // contentLabel="Please Confirm"
          // ariaHideApp={false}
          // className="common__confirmtion-popu business__settings-modal"  
        >
          <div className='react-modal-dialog react-modal-dialog-centered HeightAutoModal CstmModalWidth'>
          <div className='react-modal-header'>
              <h5 className='react-modal-title'>Business Settings</h5>
              <button
                type='button'
                className='btn react-modal-close'
                onClick={
                  this.props.onCloseClick
                }
                >
                <CloseIcon />
              </button>
            </div>

            <div className='react-modal-body' >
        <div className="settings__container">
          {/* <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Business Settings</div>
            <div className="head-section-cta">
              <span
                className="btn btn-link btn-back"
                onClick={
                  this.props.onCloseClick
                }
              >
                <CloseIcon />
              </span>
            </div>
          </div> */}

              <div className="card-body">
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={settingSchema}
                  onSubmit={(values, formikProps) =>{ this.handleSubmit(values, formikProps)}}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting, setValues, setSubmitting, errors }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              name="businessName"
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.businessName}
                              required
                            />
                            <ErrorMessage component="span" name="businessName" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Business Name<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="invoicePrefix"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.invoicePrefix}
                              required
                            />
                            <ErrorMessage component="span" name="invoicePrefix" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Invoice Prefix<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <PlacesAutocomplete
                              type="text"
                              name="address"
                              isRequired={true}
                              value={values.address}
                              onChange={handleChange}
                              className="form-control material-textfield-input"
                              handleAddressChange={({ address, city, state, zip }) =>{
                                setValues({
                                  ...values,
                                  address,
                                  city,
                                  state,
                                  zip
                                })
                                setSubmitting(false)
                              }
                              }
                              setValue={address =>{this.setState({address:address})}}
                              address={this.state.address}
                            />
                            <ErrorMessage component="span" name="address" className="invalid-feedback d-block" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              name="city"
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.city}
                              required
                            />
                            <ErrorMessage component="span" name="city" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              City<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              name="state"
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.state}
                              required
                            />
                            <ErrorMessage component="span" name="state" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              State<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group material-textfield">
                            <input
                              name="zip"
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.zip}
                              required
                            />
                            <ErrorMessage component="span" name="zip" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Zip<span>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {_.get(user, "role") === "admin" && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group material-textfield">
                              <CitiesAutocomplete
                                type="text"
                                name="cities"
                                value={city}
                                // isRequired={true}
                                onChange={handleChange}
                                className="form-control material-textfield-input"
                                handleAddressChange={({ city, state }) => {
                                  if (city) {
                                    const cities = [...this.state.cities];
                                    if (cities.indexOf(`${city}, ${state}`) === -1) {
                                      this.setState({ cities: [...this.state.cities, `${city}, ${state}`] });
                                    }
                                    this.setState({ city: "" });
                                  }
                                }}
                                setValue={city => this.setState({ city })}
                                address={this.state.city}
                              />
                              <ErrorMessage component="span" name="cities" className="invalid-feedback d-block" />
                            </div>
                            <ul className="city__list">
                              {cities.map(city => {
                                return (
                                  <li key={city}>
                                    {city}{" "}
                                    <button className="close__btn" type="button" onClick={() => this.removeCity(city)}>
                                      <img className="img-fluid" src={CloseGray} alt="" />
                                    </button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <InputMask
                              mask="(999) 999 - 9999"
                              name="phone"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.phone}
                              required
                              maskChar=""
                            />
                            <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Phone Number<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group material-textfield">
                            <InputMask
                              mask="(999) 999 - 9999"
                              name="fax"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.fax}
                              required
                              maskChar=""
                            />
                            <ErrorMessage component="span" name="fax" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">Fax Number</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              name="billingEmail"
                              value={values.billingEmail}
                              required
                            />
                            <ErrorMessage
                              component="span"
                              name="billingEmail"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                                Billing Email
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              name="dsnyTransferStation"
                              value={values.dsnyTransferStation}
                              required
                            />
                            <ErrorMessage
                              component="span"
                              name="dsnyTransferStation"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                              DSNY Transfer Station<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="nycdecPermit"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.nycdecPermit}
                              required
                            />
                            <ErrorMessage component="span" name="nycdecPermit" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              NYCDEC Permit<span>*</span>
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="scaleip"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.scaleip}
                              required
                            />
                            <ErrorMessage component="span" name="scaleip" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Scale IP<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="scaleport"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.scaleport}
                              required
                            />
                            <ErrorMessage component="span" name="scaleport" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Scale Port<span>*</span>
                            </label>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <InputMask
                              mask="9999"
                              name="autoLogoutDuration"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.autoLogoutDuration}
                              required
                              maskChar=""
                            />
                            <ErrorMessage
                              component="span"
                              name="autoLogoutDuration"
                              className="invalid-feedback d-block"
                            />
                            <label className="material-textfield-label">
                              Auto Logout Duration<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <input
                              type="text"
                              name="orderPrefix"
                              className="form-control material-textfield-input"
                              onChange={handleChange}
                              value={values.orderPrefix}
                              required
                            />
                            <ErrorMessage component="span" name="orderPrefix" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Order Prefix<span>*</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group material-textfield custom-select-50 timezone__picker">
                            <TimezonePicker
                              absolute={false}
                              value={values.timezone}
                              placeholder="Select timezone..."
                              required
                              onChange={timezone =>
                                setValues({
                                  ...values,
                                  timezone
                                })
                              }
                            />
                            <label className="material-textfield-label">Timezone</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="display-team-status">
                            <strong className="material-textfield-label">Enable Microblink</strong>
                            <Switch
                              name="microblinkEnabled"
                              checked={microblinkEnabled}
                              onChange={() => this.setState({ microblinkEnabled: !microblinkEnabled })}
                            />
                            <p className={microblinkEnabled ? "on-of-text text-green" : "on-of-text text-red"}>
                              {microblinkEnabled ? "Yes" : "No"}
                            </p>
                          </div>
                        </div>
                        {/* {_.get(user, "role") === "admin" && (
                          <> */}
                            { isSignaturePadIntegration &&
                              <div className="col-md-12">
                                <div className="display-team-status">
                                  <strong className="material-textfield-label">Signature Required</strong>
                                  <Switch
                                    name="signatureRequired"
                                    checked={signatureRequired}
                                    onChange={() => this.setState({ signatureRequired: !signatureRequired })}
                                  />
                                  <p className={signatureRequired ? "on-of-text text-green" : "on-of-text text-red"}>
                                    {signatureRequired ? "Yes" : "No"}
                                  </p>
                                </div>
                              </div>
                            }
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Charge Handling Fee</strong>
                                <Switch
                                  name="chargeStripeCardFees"
                                  checked={chargeStripeCardFees}
                                  onChange={() => this.setState({ chargeStripeCardFees: !chargeStripeCardFees })}
                                />
                                <p className={chargeStripeCardFees ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {chargeStripeCardFees ? "Yes" : "No"}
                                </p>
                              </div>
                              {chargeStripeCardFees && (                              
                              <div className="form-group material-textfield">
                                <NumberFormat
                                  allowEmptyFormatting={true}
                                  thousandSeparator={false}
                                  decimalScale={2}
                                  allowNegative={false}
                                  displayType={"input"}
                                  type="text"
                                  className="form-control material-textfield-input"
                                  onValueChange={_values => {
                                    const { formattedValue } = _values;
                                    const chargeStripeCardFeesAmount = formattedValue;
                                    this.setState({ chargeStripeCardFeesAmount },()=>this.isHandlingFee(chargeStripeCardFeesAmount,setSubmitting));
                                    
                                  }}
                                  value={chargeStripeCardFeesAmount}
                                  name="chargeStripeCardFeesAmount"
                                  required
                                />
                                <div className="invalid-feedback text-left">{errchargeStripeCardFeesAmount}</div>
                                <label className="material-textfield-label">Credit Card Handling Fee %<span>*</span></label>
                              </div>                             
                            )}
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Open PDF when an order completes</strong>
                                <Switch
                                  name="is_open_pdf_when_an_order_complete"
                                  checked={is_open_pdf_when_an_order_complete}
                                  onChange={() => this.setState({ is_open_pdf_when_an_order_complete: !is_open_pdf_when_an_order_complete })}
                                />
                                <p className={is_open_pdf_when_an_order_complete ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {is_open_pdf_when_an_order_complete ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Display service address on orders</strong>
                                <Switch
                                  name="serviceAddress"
                                  checked={serviceAddress}
                                  onChange={() => this.setState({ serviceAddress: !serviceAddress })}
                                />
                                <p className={serviceAddress ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {serviceAddress ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Required yardage for Outbound Activities</strong>
                                <Switch
                                  name="isYardageRequired"
                                  checked={isYardageRequired}
                                  onChange={() => this.setState({ isYardageRequired: !isYardageRequired })}
                                />
                                <p className={isYardageRequired ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {isYardageRequired ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Round up order totals for cash transactions</strong>
                                <Switch
                                  name="isRoundOffAmount"
                                  checked={isRoundOffAmount}
                                  onChange={() => this.setState({ isRoundOffAmount: !isRoundOffAmount })}
                                />
                                <p className={isRoundOffAmount ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {isRoundOffAmount ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Receive email notifications for payments on orders</strong>
                                <Switch
                                  name="emailForPaymentsOnOrders"
                                  checked={emailForPaymentsOnOrders}
                                  onChange={() => this.setState({ emailForPaymentsOnOrders: !emailForPaymentsOnOrders })}
                                />
                                <p className={emailForPaymentsOnOrders ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {emailForPaymentsOnOrders ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Sales Tax</strong>
                                <Switch
                                  name="isSalesTax"
                                  checked={isSalesTax}
                                  onChange={() => this.setState({ isSalesTax: !isSalesTax })}
                                />
                                <p className={isSalesTax ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {isSalesTax ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            {isSalesTax && (
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    thousandSeparator={false}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType={"input"}
                                    type="text"
                                    className="form-control material-textfield-input"
                                    onValueChange={_values => {
                                      const { formattedValue } = _values;
                                      const salesTaxFeeAmount = formattedValue;
                                      this.setState({ salesTaxFeeAmount },()=>{this.isSalesTax(salesTaxFeeAmount,setSubmitting)});
                                    }}
                                    value={salesTaxFeeAmount}
                                    name="salesTaxFeeAmount"
                                  />
                                  <div className="invalid-feedback text-left">{errisSalesTax}</div>
                                  <label className="material-textfield-label">% of Sales Tax</label>
                                </div>
                              </div>
                            )}
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label">Environmental Load Fee</strong>
                                <Switch
                                  name="isEnvironmentalLoadFee"
                                  checked={isEnvironmentalLoadFee}
                                  onChange={() => this.setState({ isEnvironmentalLoadFee: !isEnvironmentalLoadFee })}
                                />
                                <p className={isEnvironmentalLoadFee ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {isEnvironmentalLoadFee ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            {isEnvironmentalLoadFee && (
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    displayType={"input"}
                                    type="text"
                                    className="form-control material-textfield-input"
                                    onValueChange={_values => {
                                      const { formattedValue } = _values;
                                      const environmentalLoadFeeAmount = formattedValue;
                                      this.setState({ environmentalLoadFeeAmount },()=>{this.isEnvironmentalLoadFee(environmentalLoadFeeAmount,setSubmitting)});
                                    }}
                                    value={environmentalLoadFeeAmount}
                                    name="environmentalLoadFeeAmount"
                                    required
                                  />
                                  <div className="invalid-feedback text-left">{errEnvironmentalLoadFeeAmount}</div>
                                  <label className="material-textfield-label">Environmental Load Fee Amount<span>*</span></label>
                                </div>
                              </div>
                            )}
                            <div className="col-md-12">
                              <div className="display-team-status">
                                <strong className="material-textfield-label"> Add Fuel Surcharge?</strong>
                                <Switch
                                  name="isEnvironmentalLoadFee"
                                  checked={isFuelSurchardeFee}
                                  onChange={() => this.setState({ isFuelSurchardeFee: !isFuelSurchardeFee })}
                                />
                                <p className={isFuelSurchardeFee ? "on-of-text text-green" : "on-of-text text-red"}>
                                  {isFuelSurchardeFee ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                            {isFuelSurchardeFee && (
                              <div className="col-md-12">
                                <div className="form-group material-textfield">
                                  <NumberFormat
                                    allowEmptyFormatting={true}
                                    thousandSeparator={false}
                                    decimalScale={2}
                                    allowNegative={false}
                                    displayType={"input"}
                                    type="text"
                                    className="form-control material-textfield-input"
                                    onValueChange={_values => {
                                      const { formattedValue } = _values;
                                      const fuelSurchardeFeeAmount = formattedValue;
                                      this.setState({ fuelSurchardeFeeAmount },()=>this.isFuelSurchardeFee(fuelSurchardeFeeAmount,setSubmitting));
                                      
                                    }}
                                    value={fuelSurchardeFeeAmount}
                                    name="fuelSurchardeFeeAmount"
                                    required
                                  />
                                  <div className="invalid-feedback text-left">{errFuelSurchardeFeeAmount}</div>
                                  <label className="material-textfield-label">% of subtotal<span>*</span></label>
                                </div>
                              </div>
                            )}
                          {/* </>
                        )} */}
                      </div>
                      <div className='row'>
                              {this.state.logo === '' && (
                                <div className='col-md-12'>
                                  <div className='form-group upload__image'>
                                    <div className='upload__image-inner'>
                                      <div className='upload__image-body'>
                                        <p>Upload Logo</p>
                                        <p>or</p>
                                        <button className='btn btn-dark btn-sm'>
                                          Drag & Drop
                                        </button>
                                      </div>
                                    </div>
                                    {/* <label className="material-textfield-label">Logo<span>*</span></label> */}
                                    <input
                                      type='file'
                                      name='logo'
                                      autoFocus
                                      defaultValue={''}
                                      // onClick={evt => this.handleImage(evt, setSubmitting)}
                                      onChange={(evt) =>
                                        this.handleImage(evt, setSubmitting)
                                      }
                                      className='form-control material-textfield-input'
                                    />
                                  </div>
                                </div>
                              )}
                              <div className='col-md-12'>
                                <div className='text-center business-setting-logo'>
                                  <div className='imagebox'>
                                    {this.state.logo && (
                                      <span
                                        className='btn btn-link btn-back CloseBtn'
                                        onClick={() => {
                                          this.setState({ logo: '' });
                                        }}>
                                        <CloseIcon />
                                      </span>
                                    )}
                                    <img src={this.state.logo} alt='' />
                                  </div>
                                </div>
                              </div>
                            </div>
                      <button type="submit" className="btn btn-dark btn-lg w-100" disabled={isSubmitting}>
                       {this.state.PleaseWait? "Please wait..." : "Save"} 
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
        </div>
        </div>
        </div>
        </ReactModal>
      </div>
    );
  }
}
