import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const getInvoiceDeliveryData = data => {
  return fetch(`${HOSTNAME}/get_invoice_delivery`, {
    method: "GET"
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getInvoiceDeliveryByIdData = id => {
  console.log(id)
  return fetch(`${HOSTNAME}/get_invoice_delivery/${id}`, {
    method: "GET"
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


