import React, { PureComponent } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import {
  BackArrowIcon
} from "../../components/icons";

export default class CreditCardPayoutDetailsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toggleMenu: false,
      activeUrl: "",
      ddmmYYYY: "",
      payabliDate: "",
      payabliPayouts: {
        Records: []
      },
      totalItems: 0,
      limit: 20,
      skip: 0,
      totalAmt:0
    };
  }
  componentDidMount = async () => {
    const date = new Date(this.props?.location?.state?.date);
    const formattedDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' });
    const parts = formattedDate.split('/');
    this.setState({ ddmmYYYY: parts[1] + '/' + parts[0] + '/' + parts[2] })
    this.setState({ payabliDate: parts[0] + '-' + parts[1] + '-' + parts[2] })
    this.fetchPayabliPayouts(parts[2] + '-' + parts[1] + '-' + parts[0])
  };

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  fetchPayabliPayouts = async (date) => {
    const payload = { limit: this.state.limit, skip: this.state.skip, date: date }
    const {
      value: { data }
    } = await this.props.getPayabliTransactions(payload)
    this.setState({ payabliPayouts: data, totalItems: data?.Summary.totalRecords })
    const sum = data?.Records.map(obj => obj.TotalAmount)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
      this.setState({totalAmt:sum})
  }

  render() {
    const numberWithCommas = (x) => {
      var parts = x.toFixed(2).split(".");
      var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
      return num;
    };
    return (
      <div className="credit__card-payout-details-wrapper">
        <div className="custome__container">
          <div className="btn-back-header">
            <button className="btn btn-link btn-back">
              <Link to={"/"}>
                <BackArrowIcon /> Back
              </Link>
            </button>
          </div>
          <div className="card">
            <div className="card-header d-flex flex-column align-items-start">
              <div className="row">
                <div className="col-md-12">
                  <p className="card-title-sm">
                    Total Payout Amount for {this.state.ddmmYYYY}
                  </p>
                  <h5 className="card-title">${this.state.totalAmt && numberWithCommas(Number(this.state.totalAmt)
                    )}</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Invoice Number</th>
                      <th>Ticket Number</th>
                      <th>Amount</th>
                      <th>Paybli Fees</th>
                      <th>Net</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.payabliPayouts?.Records.length > 0 && this.state.payabliPayouts?.Records.map(function (data) {
                         let orderIdsData=data.OrderId.split("|")
                         let invoiceId=""
                         let orderId=''
                      if(data.OrderId.includes("|")){
                       invoiceId=orderIdsData[0]
                        orderId=orderIdsData[1]
                      }
                      else{
                        invoiceId=''
                        orderId=orderIdsData[0]
                      }
                      return (
                        <tr>
                          <td>{data?.TransactionTime}</td>
                          <td>{invoiceId}</td>
                          <td>{orderId}</td>
                          <td>${data?.NetAmount && numberWithCommas(Number(data?.NetAmount)
                    )}</td>
                          <td>${data?.FeeAmount}</td>
                          <td>${data?.TotalAmount && numberWithCommas(Number(data?.TotalAmount)
                    )}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
