import React, { PureComponent } from "react";
import _ from "lodash";
import moment from "moment";
import Logo from "../../images/logo.png";
import "./styles.scss";
import { numberFormat, numberFormatLbs, customStartCase } from "../../constants/common";

export default class ReceiptComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      user: null,
      orderCreationType:""
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.fetchOrderDetail();
  };

  fetchOrderDetail = async () => {
    const {
      match: {
        params: { id }
      },
      fetchOrder,
      fetchMe
    } = this.props;
    const [
      {
        value: { data, orderLogs }
      },
      {
        value: { user }
      }
    ] = await Promise.all([fetchOrder(id), fetchMe()]);
    this.setState({ order: data,orderCreationType: data?.orderCreationType, user, orderLogs }, () => {
      setTimeout(() => {
        window.print();
        this.props.history.goBack();
      }, 1000);
    });
  };

  render() {
    const { order, user } = this.state;
    let paymentType = order.paymentType && order.paymentType !== "" ? `- ${_.startCase(order.paymentType)}` : "";
    if (paymentType === "") {
      paymentType = "ACCOUNT";
    } else {
      paymentType = `COD ${paymentType}`;
    }
    return (
      <div>
        <main className="dashboard-layout-content receipt__layout">
          <div className="receipt__layout-inner">
            <div className="receipt__header">
              <div className="receipt__header-left">
                <div className="receipt__company-logo">
                  <img
                    className="img-fluid"
                    src={user && user.company && user.company.logo ? user.company.logo : Logo}
                    alt=""
                  />
                </div>
                <h2 className="company__name">Receipt from {_.get(user, "company.name", "")} </h2>
              </div>
              <div className="receipt__header-right">
                <div className="company__address">
                  <h2 className="company__name">{_.get(order, "customer.displayName", "")}</h2>
                  <p className="sub__title">
                    {_.get(order, "customer.address", "") && order.customer.address}
                    <br />
                    {_.get(order, "customer.city", "") && order.customer.city},{" "}
                    {_.get(order, "customer.state", "") && order.customer.state}{" "}
                    {_.get(order, "customer.zip", "") && order.customer.zip}
                  </p>
                </div>
              </div>
            </div>
            <div className="company__details">
              <ul>
                <li>
                  <span>Company Name:</span> {_.get(order, "customer.displayName", "")}
                </li>
                <li>
                  <span>Ticket #:</span> {_.get(order, "ticketNumber", "")}
                </li>
                <li>
                  <span>Date:</span> {moment(_.get(order, "createdAt", moment())).format("MMMM DD, YYYY")}
                </li>
                <li>
                  <span>Payment Terms: </span> {_.toUpper(_.get(order, "customer.paymentTerms", ""))}
                </li>
              </ul>
            </div>
            <div className="summary__section">
              <h2 className="title">Summary</h2>
              <div className="summary__inner">
                <ul className="summary__list">
                  <li>
                    <div className="summary__list-item">
                      Inbound Weight
                      <span className="for__desktop">
                        | {moment(_.get(order, "inboundWeightDate", moment())).format("MM/DD/YY")} at{" "}
                        {moment(_.get(order, "inboundWeightDate", moment())).format("h:mma")}
                      </span>
                      <span className="for__mobile">
                        {moment(_.get(order, "inboundWeightDate", moment())).format("MM/DD/YY")} at{" "}
                        {moment(_.get(order, "inboundWeightDate", moment())).format("h:mma")}
                      </span>
                    </div>
                    <div>{numberFormat(_.get(order, "inboundWeight"))} Ibs</div>
                  </li>
                  <li>
                    <div className="summary__list-item">
                      Outbound Weight
                      <span className="for__desktop">
                        | {moment(_.get(order, "outboundWeightDate", moment())).format("MM/DD/YY")} at{" "}
                        {moment(_.get(order, "outboundWeightDate", moment())).format("hh:mma")}
                      </span>
                      <span className="for__mobile">
                        {moment(_.get(order, "outboundWeightDate", moment())).format("MM/DD/YY")} at{" "}
                        {moment(_.get(order, "outboundWeightDate", moment())).format("hh:mma")}
                      </span>
                    </div>
                    <div>{numberFormat(_.get(order, "outboundWeight"))} Ibs</div>
                  </li>
                  <li>
                    <div>Net Weight</div>
                    <div>{numberFormat(_.get(order, "netWeight"))} Ibs</div>
                  </li>
                  <li>
                    <div>Tonnage </div>
                    <div>{numberFormat(_.get(order, "tonnage"))} tons </div>
                  </li>
                  <li>
                    <div>Yardage </div>
                    <div>{numberFormatLbs(order.yardage)}</div>
                  </li>
                  <li>
                    <div>Dump Rate Type</div>
                    <div>{customStartCase(_.get(order, "dumpRateType", 0))}</div>
                  </li>
                  {!_.get(order, "customer.hidePrice", false) && (
                    <>
                      <li>
                        <div>Dump Rate</div>
                        <div>${numberFormat(_.get(order, "dumpRate", 0))}</div>
                      </li>
                      { order?.orderCreationType==='createCharge' &&_.get(order, "orderBreakDownItems.fixedItem", []).length !== 0 && (
                        <>
                          <hr />
                          {_.get(order, "orderBreakDownItems.fixedItem", []).map((item,i) => {
                            return (
                              
                              <li key={i}>
                                <>
                                <span>
                                  {item?.fixedItem} ({item?.units}) (${item?.unitPrice}/unit)
                                </span>
                                <span>${numberFormat(item?.unitPrice * item?.units)}</span>
                                </>
                              </li>
                            );
                          })}
                        </>
                      )}
                       { order?.orderCreationType === 'createCharge' &&_.get(order, "orderBreakDownItems.chargeByWeight", []).length !== 0 && (
                        <>
                          <hr />
                          {_.get(order, "orderBreakDownItems.chargeByWeight", []).map((item,i) => {
                            return (
                              
                              <li key={i}>
                                <>
                                <span>
                                  {item?.fixedItem} ({item?.units}) (${item?.unitPrice}/unit)
                                </span>
                                <span>${numberFormat(item?.unitPrice * item?.units)}</span>
                                </>
                              </li>
                            );
                          })}
                        </>
                      )}
                      { order.orderCreationType !=='createCharge' && _.get(order, "additionalItems", []).length !== 0 && (
                        <>
                          <hr />
                          {_.get(order, "additionalItems", []).map((item,i) => {
                            return (
                              
                              <li key={i}>
                                {this.state.orderCreationType !== "createCharge" ?
                                <>
                                <span>
                                  {item?.label} ({item?.quantity}) (${item?.value}/unit)
                                </span>
                                <span>${numberFormat(item?.value * item?.quantity)}</span>
                                </>:
                                <>
                                <span>
                                  {item?.fixedItem} ({item?.units}) (${item?.unitPrice}/unit)
                                </span>
                                <span>${numberFormat(item?.unitPrice * item?.units)}</span>
                                </>}
                              </li>
                            );
                          })}
                        </>
                      )}
                      {_.get(order, "isEnvironmentalLoadFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <div>Environmental Load Fee</div>
                            <div>${numberFormat(_.get(order, "environmentalLoadFeeAmount", 0))}</div>
                          </li>
                        </React.Fragment>
                      )}
                      {_.get(order, "isFuelSurchardeFee", false) && (
                        <React.Fragment>
                          <hr />
                          <li>
                            <div>Fuel Surcharge</div>
                            <div>${numberFormat(_.get(order, "fuelSurchargeTotal", 0))}</div>
                          </li>
                        </React.Fragment>
                      )}
                      {_.get(order, "chargeStripeCardFees", false) && (order?.paymentType === "credit-card" || order?.paymentType === "terminal") && (
                        <>
                          <hr />
                          <li className="">
                            <span>Handling Fee</span>
                            {numberFormat(order.stripeCardFees)}
                          </li>
                          <li className="">
                            <span>Order Amount </span>
                            {numberFormat(order.totalAmount - order.stripeCardFees)}
                          </li>
                        </>
                      )}
                    </>
                  )}
                  {_.get(order, "isEnvironmentalLoadFee", false) && _.get(order, "customer.hidePrice", false) && (
                    <React.Fragment>
                      <hr />
                      <li>
                        <div>Environmental Load Fee</div>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
                {!_.get(order, "customer.hidePrice", false) && (
                  <div className="summary__footer">
                    <ul>
                      <li>
                        <span>Total Order Amount</span>
                        <span>${numberFormat(_.get(order, "totalAmount"))}</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="payment__method">
              <h2 className="title">Payment Method </h2>
              <div className="payment__method-inner">{paymentType}</div>
              {order.signatureUrl !== "" &&
                <img src={`${order.signatureUrl}`} alt="signature"></img>
              }
            </div>
            {order.notes && (
              <div className="payment__method">
                <h2 className="title">Notes </h2>
                <div className="notes">{order.notes}</div>
              </div>
            )}
            <div className="sustainability__information">
              {_.get(order, "sustainabilityInfo", []).map((sustainabilityInfo, i) => {
                return (
                  <div className="card" key={i}>
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12 d-flex flex-unset align-items-center justify-content-between">
                          <div>
                            <h5 className="card-title">Sustainability Information</h5>
                            <p className="card-title-sm">{sustainabilityInfo.address}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          {sustainabilityInfo.selectedFields.indexOf("totalpercentage") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Total % (yards)</h6>
                                  <p className="sub__title">{Math.round(sustainabilityInfo.totalpercentage)}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Total % (tons)</h6>
                                  <p className="sub__title">{Math.round(sustainabilityInfo.weight_total)}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.weight}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("waste") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Waste % (Yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.waste}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Waste % (Tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[0].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Waste Weight (Tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[0].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("brick") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Brick % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.brick}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Brick % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[1].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Brick Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[1].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("dirt") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Dirt % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.dirt}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Dirt % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[2].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Dirt Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[2].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("concrete") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Concrete % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.concrete}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Concrete % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[3].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Concrete Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[3].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("cleanwood") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Wood % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.cleanwood}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Wood % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[4].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Wood Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[4].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("metal") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Metal % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.metal}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Metal % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[5].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Metal Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[5].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("paper_cardboard") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Paper/Cardboard % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.paper_cardboard}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Paper/Cardboard % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[6].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Paper/Cardboard Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[6].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("plastic") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Plastic % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.plastic}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Plastic % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[7].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Plastic Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[7].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("drywall") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Drywall % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.drywall}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Drywall % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[8].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Drywall Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[8].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("glass") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Glass % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.glass}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Glass % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[9].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Glass Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[9].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          {sustainabilityInfo.selectedFields.indexOf("asphalt") !== -1 && (
                            <ul className="card__list">
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Asphalt % (yards)</h6>
                                  <p className="sub__title">{sustainabilityInfo.asphalt}</p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Asphalt % (tons)</h6>
                                  <p className="sub__title">
                                    {Math.round(sustainabilityInfo.tonnagepercentage[10].value)}
                                  </p>
                                </div>
                              </li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Asphalt Weight (tons)</h6>
                                  <p className="sub__title">{sustainabilityInfo.tonnageweight[10].value}</p>
                                </div>
                              </li>
                            </ul>
                          )}
                          
                          <div className="recycling__title">
                            <span>Recycling %</span> {Math.round(sustainabilityInfo?.residualpercentage?sustainabilityInfo?.recyclingpercentage :0)}%
                          </div>
                          <div className="risidual__waste">
                            <span>Waste %</span> { Math.round(sustainabilityInfo?.residualpercentage ?sustainabilityInfo?.residualpercentage:0)}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="divider__line"></div>
            <div className="driver__information">
              <h2 className="title">Driver Information</h2>
              <p className="driver__name">
                <span>Name:</span>
                {_.startCase(`${_.get(order, "driver.firstName", "")} ${_.get(order, "driver.lastName", "")}`)}
              </p>
              <p className="signature">
                <span>Signature:</span>
                <span className="driver__signature">
                  <img src={_.get(order, "driverSignature", "")} alt="" />
                </span>
              </p>
            </div>
            <div className="drivers__license-Plate">
              <div className="license-Plate">
                <h3>Licence Plate</h3>
                <img className="img-fluid" src={_.get(order, "truck.plateImageUrl", "")} alt="" />
              </div>
              {/* <div className="license-license">
                <h3>Drivers License</h3>
                <img className="img-fluid" src={_.get(order, "driver.licenseImageUrl", "")} alt="" />
              </div> */}
              {_.get(order, "loadImageUrl", "") && (
                <div className="license-license">
                  <h3>Inbound Truck Photo</h3>
                  <img className="img-fluid" src={_.get(order, "loadImageUrl", "")} alt="" />
                </div>
              )}
            </div>
            <div className="divider__line"></div>
            <div className="invoic__text">
              <div className="powered__by-text-logo">
                <h5 className="powered__by-text">Powered by</h5>
                <div className="powered__by-logo">
                  <img className="img-fluid powered-imge" src={Logo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
