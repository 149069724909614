import { connect } from 'react-redux'
import { getMe, fetchMe, updateUserToken,uploadImage } from '../../store/user/duck'
import { fetchCustomer ,addNewTruck, updateTruck} from '../../store/customer/duck'
import {fetchContainers} from "../../store/container/duck"
import AddTruckComponent from './component'

const AddTruckContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    updateUserToken,
    fetchCustomer,
    fetchContainers,
    uploadImage,
    addNewTruck,
    updateTruck
  }
)(AddTruckComponent)

export default AddTruckContainer
