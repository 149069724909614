// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from 'immutable'
import { assign } from 'lodash'
// import Cookies from 'universal-cookie'
import { INIT } from '../../constants/phase'

import { combineEpics } from 'redux-observable'

import * as api from './api'

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const CREATE_CUSTOMER = 'empire/customer/CREATE_CUSTOMER'
export const CREATE_PROJECT = 'empire/customer/CREATE_PROJECT'
export const UPDATE_CUSTOMER = 'empire/customer/UPDATE_CUSTOMER'
export const FETCH_CUSTOMERS = 'empire/customer/FETCH_CUSTOMERS'
export const FETCH_PROJECTS= 'empire/customer/FETCH_PROJECTS'
export const UPDATE_PROJECT= 'empire/customer/UPDATE_PROJECT'
export const FETCH_CUSTOMER = 'empire/customer/FETCH_CUSTOMER'
export const FETCH_DRIVERS = "empire/customer/FETCH_DRIVERS"
export const ADD_CARD = "empire/customer/ADD_CARD";
export const FETCH_DRIVERS_BY_CUSTOMER_ID = 'empire/customer/FETCH_DRIVERS_BY_CUSTOMER_ID'
export const FETCH_TRUCKS_BY_CUSTOMER_ID = 'empire/customer/FETCH_TRUCKS_BY_CUSTOMER_ID'
export const FETCH_TRUCKS_BY_LICENSE_PLATE = 'empire/customer/FETCH_TRUCKS_BY_LICENSE_PLATE'
export const FETCH_TRUCKS_BY_COMPANY_ID = 'empire/customer/FETCH_TRUCKS_BY_COMPANY_ID'
export const CHARGE_CUSTOMER = "empire/customer/CHARGE_CUSTOMER";
export const UPDATE_DRIVER = 'empire/customer/UPDATE_DRIVER'
export const UPDATE_TRUCK = 'empire/customer/UPDATE_TRUCK'
export const UPDATE_TRUCK_INDEX = 'empire/customer/UPDATE_TRUCK_INDEX'
export const ADD_NEW_TRUCK = 'empire/customer/ADD_NEW_TRUCK'
export const FETCH_QB_CUSTOMERS = "empire/customer/FETCH_QB_CUSTOMERS";
export const FETCH_LOCATION_LIST = 'empire/customer/FETCH_LOCATION_LIST'
export const GET_QB_USER = "empire/customer/GET_QB_USER"
export const DELETE_PAYMENT_METHOD = "empire/customer/DELETE_PAYMENT_METHOD"
export const FETCH_QB_CUSTOMERS_BY_SEARCHTEXT = "empire/customer/FETCH_QB_CUSTOMERS_BY_SEARCHTEXT"
export const SYNC_CUSTOMERS = "empire/customer/SYNC_CUSTOMERS"
export const FETCH_LOGS = "empire/customer/FETCH_LOGS"
export const FETCH_PAYMENT_METHOD_CARDS = "empire/customer/FETCH_PAYMENT_METHOD_CARDS"
export const FETCH_CUSTOMER_PRICING = 'empire/customer/FETCH_CUSTOMER_PRICING'
export const FETCH_CUSTOMER_TRUCKS = 'empire/customer/FETCH_CUSTOMER_TRUCKS'
export const FETCH_CUSTOMER_PAST_WEIGHINS = 'empire/customer/FETCH_CUSTOMER_PAST_WEIGHINS'

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {}
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const createCustomer = payload => {
  return {
    type: CREATE_CUSTOMER,
    payload: api.createCustomer(payload)
  }
}

export const fetchLogs = payload => {
  return {
    type: FETCH_LOGS,
    payload: api.fetchLogs(payload)
  }
}


export const createProject = payload => {
  return {
    type: CREATE_PROJECT,
    payload: api.createProject(payload)
  }
}

export const updateProject = payload => {
  return {
    type: UPDATE_PROJECT,
    payload: api.updateProject(payload)
  }
}

export const fetchCustomers = payload => {
  return {
    type: FETCH_CUSTOMERS,
    payload: api.fetchCustomers(payload)
  }
}

export const fetchProjects = payload => {
  return {
    type: FETCH_PROJECTS,
    payload: api.fetchProject(payload)
  }
}

export const fetchPaymentMethodCards = payload => {
  return {
    type: FETCH_PAYMENT_METHOD_CARDS,
    payload: api.fetchPaymentMethodCards(payload)
  }
}

export const fetchBillingCustomers = payload => {
  return {
    type: FETCH_CUSTOMERS,
    payload: api.fetchBillingCustomers(payload)
  }
}

export const fetchCustomer = (payload,sortData,inActivetrucks,skip,limit) => {
  return {
    type: FETCH_CUSTOMER,
    payload: api.fetchCustomer(payload,sortData,inActivetrucks,skip,limit)
  }
}

export const updateCustomer = payload => {
  return {
    type: UPDATE_CUSTOMER,
    payload: api.updateCustomer(payload)
  }
}

export const fetchDrivers = payload => {
  return {
    type: FETCH_DRIVERS,
    payload: api.fetchDrivers(payload)
  };
};

export const fetchDriversByCustomerId = payload => {
  return {
    type: FETCH_DRIVERS_BY_CUSTOMER_ID,
    payload: api.fetchDriversByCustomerId(payload)
  }
}

export const chargeCustomer = payload => {
  return {
    type: CHARGE_CUSTOMER,
    payload: api.chargeCustomer(payload)
  };
};

export const fetchTrucksByCustomerId = payload => {
  return {
    type: FETCH_TRUCKS_BY_CUSTOMER_ID,
    payload: api.fetchTrucksByCustomerId(payload)
  }
}
export const fetchTrucksByCompanyId = payload => {
  return {
    type: FETCH_TRUCKS_BY_COMPANY_ID,
    payload: api.fetchTrucksByCompanyId(payload)
  }
}

export const addCard = payload => {
  return {
    type: ADD_CARD,
    payload: api.addCard(payload)
  };
};

export const updateDriver = payload => {
  return {
    type: UPDATE_DRIVER,
    payload: api.updateDriver(payload)
  }
}
export const addNewTruck = payload => {
  return {
    type: ADD_NEW_TRUCK,
    payload: api.addNewTruck(payload)
  };
};

export const updateTruck = payload => {
  return {
    type: UPDATE_TRUCK,
    payload: api.updateTruck(payload)
  }
}
export const updateTruckWithIndex = payload => {
  return {
    type: UPDATE_TRUCK_INDEX,
    payload: api.updateTruckWithIndex(payload)
  }
}
export const fetchLocationsList = payload => {
  return {
    type: FETCH_LOCATION_LIST,
    payload: api.fetchLocationsList(payload)
  };
}

export const fetchQBCustomers = payload => {
  return {
    type: FETCH_QB_CUSTOMERS,
    payload: api.fetchQBCustomers(payload)
  };
}

export const getQuickBooksUser = payload => {
  return {
    type: GET_QB_USER,
    payload: api.getQuickBooksUser(payload)
  };
}

export const deletePaymentMethod = payload => {
  return {
    type: DELETE_PAYMENT_METHOD,
    payload: api.deletePaymentMethod(payload)
  };
}

export const fetchQBCustomersBySearchText = payload => {
  return {
    type: FETCH_QB_CUSTOMERS_BY_SEARCHTEXT,
    payload: api.fetchQBCustomersBySearchText(payload)
  };
}

export const syncCustomers = payload => {
  return {
    type: SYNC_CUSTOMERS,
    payload: api.syncCustomers(payload)
  };
}

export const fetchCustomerPricing = payload => {
  return {
    type: FETCH_CUSTOMER_PRICING,
    payload: api.fetchPricingByCustomerId(payload)
  }
}

export const fetchCustomerTrucks = payload => {
  return {
    type: FETCH_CUSTOMER_TRUCKS,
    payload: api.fetchCustomerTrucks(payload)
  }
}

export const fetchCustomerPastWeignIns = payload => {
  return {
    type: FETCH_CUSTOMER_PAST_WEIGHINS,
    payload: api.fetchCustomerPastWeignIns(payload)
  }
}
/***********************************
 * Epics
 ***********************************/

export const customerEpic = combineEpics()
