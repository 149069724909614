import React, { PureComponent } from 'react';
import '../styles.scss';
import NotFound from '../../NoRecords/component';
import ReactModal from 'react-modal';
import { CloseIcon } from '../../../../src/components/icons';

export default class CustomerPricing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customer: [],
      pricingTierName:""
    };
  }

  componentDidMount = async () => {
    let PricingTirerArray = window.location.pathname.split('/');
    this.getCustomer(PricingTirerArray[2]);
    this.getPricingTier(PricingTirerArray[2]);
  };

  getCustomer = async (pricingTierId) => {
    const payload = {
      id: pricingTierId,
    };
    const {
      value: {
        data: { Customers },
      },
    } = await this.props.getCustomersPricing(payload);
    this.setState({ customer: Customers });
  };

  getPricingTier = async (pricingTierId) => {
    const {
      value: {
        data: { pricing },
      },
    } = await this.props.getPricingTierById(pricingTierId);
    this.setState({ pricingTierName: pricing.pricingTier });
  };

  render() {
    const { customer } = this.state;
    return (
      <div>
        <ReactModal
          isOpen={this.props.isCustomerPricingModelOpen}
          onRequestClose={this.props.onCloseClick}
        // className="common__confirmtion-popu business__settings-modal"
        >
          <div className='react-modal-dialog react-modal-dialog-centered HeightAutoModal CstmModalWidth'>
            <div className='react-modal-header'>
              <h5 className='react-modal-title'>
                {' '}
                Customers in {this.state.pricingTierName}
              </h5>
              <button
                type='button'
                className='btn react-modal-close'
                onClick={this.props.onCloseClick}>
                <CloseIcon />
              </button>
            </div>
            <div className='react-modal-body'>
              <div className='settings__container'>
                <div className='card-body'>
                  <div className='customer__ricing-wrapper'>
                    <div className='custome__container'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive'>
                            {customer?.length > 0 ? (
                              <table className='table custom-table drivers__trucks-table'>
                                <thead>
                                  <th>Customer Name</th>
                                  <th>Address</th>
                                  <th>Phone Number</th>
                                  <th>Email</th>
                                  <th>Contact Name</th>
                                </thead>
                                <tbody>
                                  {customer.map((item) => (
                                    <tr key={item?.companyName}>
                                      <td>{item?.companyName}</td>
                                      <td>{item?.address}</td>
                                      <td>{item?.phone}</td>
                                      <td>{item?.email}</td>
                                      <td>{item?.displayName}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <NotFound
                                title={`No Customers`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
