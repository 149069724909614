import { connect } from 'react-redux'
import { getMe, updateProfile,updateUserToken,fetchMe } from '../../store/user/duck'
import IntegrationsComponent from './component'
import { verifyQBIntegration } from "../../store/dashboard/duck";
import { fetchSetting } from '../../store/company/duck'

const IntegrationsContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
    fetchMe,
    updateUserToken,
    updateProfile,
    verifyQBIntegration,
    fetchSetting
  }
)(IntegrationsComponent)

export default IntegrationsContainer
