import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;


export const getNotifications = (payload) => {
  return fetch(`${HOSTNAME}/notifications`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const getUnreadNotificationCount = (payload) => {
  return fetch(`${HOSTNAME}/notifications/unreadcount`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const readNotifications = (payload) => {
  return fetch(`${HOSTNAME}/notifications/read`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const clearNotifications = (payload) => {
  return fetch(`${HOSTNAME}/notifications/clear`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
