import React, { PureComponent } from "react";


export default class searchCheckboxComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      // searchStartsWith: true
    };
  }

  render() {
    const { searchStartsWith } = this.props
    return (

      <div className="search-check-list">
        <div className="search-check-list-items">
          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
            <input
              type="checkbox"
              checked={searchStartsWith}
              onChange={() => !searchStartsWith && this.props.handleSearchStartsWith(true)}
              className="custom-checkbox--input"
            />
            <span className="checkmark"></span>
            Starts With
          </label>
        </div>
        <div className="search-check-list-items">
          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
            <input
              type="checkbox"
              checked={!searchStartsWith}
              className="custom-checkbox--input"
              onChange={() => searchStartsWith && this.props.handleSearchStartsWith(false)}
            />
            <span className="checkmark"></span>
            Contains
          </label>
        </div>
      </div>
    );
  }
}
