import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { CloseIcon } from "../../../components/icons";

class DeleteUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reason:""
    };
  }


  render() {
    const { isDeleteModal, progressBar, deleteOrder, softDelete, hasPaymentReceipt } = this.props;
    const {reason} = this.state
    const is_paid_order_message = this.props?.is_paid_order_message
    return (
      <ReactModal ariaHideApp={false} onRequestClose={this.props.closeModal} isOpen={isDeleteModal}>
        <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">{softDelete ? "Void" : "Delete"}</h5>
            <button type="button" className="btn react-modal-close" onClick={this.props.closeModal}>
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="row">
              <div className="col-md-12">
                {
                  !is_paid_order_message ?
                    <p>{`Are you sure you want to ${softDelete ? "void" :" delete"} this? This can not be undone.`}</p>
                    : <p><strong>{`Are you sure you want to ${softDelete ? "void":"delete"} this order?`}</strong> {hasPaymentReceipt ? `If you ${softDelete ? "void" : "delete"} this order the invoice for this order will be deleted but not the payment receipt.`:""}</p>
                }
              </div>
            </div>
            <div>
              <div className="form-group material-textfield">
                <input
                  type="text"
                  value={reason}
                  onChange={(e) =>
                    this.setState({ reason: e.target.value })
                  }
                  className="form-control material-textfield-input"
                  name="reason"
                  required
                  autoFocus
                />
                {/* <ErrorMessage
                  component="span"
                  name="companyName"
                  className="invalid-feedback d-block"
                /> */}
                <label className="material-textfield-label">
                Cancellation Reason<span>*</span>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button disabled={progressBar || reason === ""} onClick={()=> deleteOrder(reason)} className="btn btn-red btn-sm delete-btn">
                  {`Yes, ${softDelete ? "Void" :"Delete"}`}
                </button>
                <button onClick={this.props.closeModal} className="btn btn-yellow btn-sm delete-btn mx-2">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteUser;
