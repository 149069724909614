import React, { PureComponent } from "react";
import { CloseIcon } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { stripeInfoSchema } from "../../utils/validations";
/* eslint-disable import/first */
const base64 = require("base-64");
import "./styles.scss";
export default class StripeSettingsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      initalValues: {
        stripePublicKey: "",
        stripeSecretKey: "",
      },
      user: null,
      err: {}
    };
  }

  componentDidMount() {
    document.title = "Stripe Integration | Weighworks";
    this.props.getMe();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.user && this.state.user !== prevState.user) {
      this.fetchSetting();
    }
  }

  fetchSetting = async () => {
    try {
      const { fetchSetting } = this.props;
      const {
        value: {
          data: { stripePublicKey, stripeSecretKey }
        }
      } = await fetchSetting(this.state.user.companyId);
      const values = {
        stripePublicKey: (stripePublicKey ? base64.decode(stripePublicKey) : ""),
        stripeSecretKey: (stripeSecretKey ? base64.decode(stripeSecretKey) : ""),
      }
      this.setState({
        initalValues: values
      });
    } catch (err) {
      // console.log({ err });
    }
  };

  handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const { updateStripeInfo, history } = this.props;
    const {
      user: { companyId }
    } = this.state;
    const err = {};
    if(values.stripePublicKey.indexOf('pk_') === -1) {
      err.stripePublicKey = "Invalid public key.";
      setErrors({ stripePublicKey: "Invalid public key." });
    } else if(values.stripeSecretKey.indexOf('sk_') === -1) {
      err.stripeSecretKey = "Invalid secret key.";
      setErrors({ stripeSecretKey: "Invalid secret key." });
    }
    // console.log('values.stripePublicKey', values.stripePublicKey)
    // console.log('base64.encode(values.stripePublicKey)', base64.encode(values.stripePublicKey))
    if (!Object.keys(err).length) {
      setSubmitting(true);
      const {
        value: { status }
      } = await updateStripeInfo({ 
        companyId,
        stripePublicKey: base64.encode(values.stripePublicKey),
        stripeSecretKey: base64.encode(values.stripeSecretKey)
      });
      if (status) {
        history.push("/dashboard");
      }
    }
  };

  render() {
    const { initalValues } = this.state;
    return (
      <div>
        <div className="stripe__container">
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Stripe Settings</div>
            <div className="head-section-cta">
              <span
                className="btn btn-link btn-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
          <div className="stripe__container-wrapper">
            <div className="card">
              <div className="card-header d-flex align-items-center">
                <h5 className="card-title">Stripe Information</h5>
              </div>
              <div className="card-body">
                <Formik
                  enableReinitialize
                  initialValues={initalValues}
                  validationSchema={stripeInfoSchema}
                  onSubmit={(values, formikProps) =>
                    this.handleSubmit(values, formikProps)
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setValues,
                    setSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="stripePublicKey"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values.stripePublicKey}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="stripePublicKey"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Public Key<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="stripeSecretKey"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            value={values.stripeSecretKey}
                            required
                          />
                          <ErrorMessage
                            component="span"
                            name="stripeSecretKey"
                            className="invalid-feedback d-block"
                          />
                          <label className="material-textfield-label">
                            Secret Key<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-dark btn-lg w-100"
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>      
    );
  }
}
