import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
         email: Yup.string()
           .email("Invalid email")
           .required("Email is required"),
         company: Yup.string()
           .min(5, "Too Short!")
           .max(50, "Too Long!")
           .required("Company name is required")
           .matches(/^[A-Za-z ]*$/, "Please enter valid company name"),
         domain: Yup.string()
           .required("Domain name is required")
           .matches(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/, "Please enter valid domain"),
         scaleip: Yup.string().required("Scale IP is required"),
         scaleport: Yup.string().required("Scale Port is required")
         //  code: Yup.string().when('email', {
         //    is: email => email !== '',
         //    then: Yup.string()
         //      .required('Verification code is required')
         //      .length(4),
         //    otherwise: Yup.string().notRequired(),
         //  }),
       });

export const editCompanySchema = Yup.object().shape({
  domain: Yup.string()
    .required("Domain name is required")
    .matches(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/, "Please enter valid domain")
});
export const companySchema = Yup.object().shape({
  // companyName: Yup.string().required("Company email is required"),
  // email: Yup.string().email('Invalid email').required('Company email is required'),
  // phone: Yup.string().required('Phone number is required'),
  // fax: Yup.string().required('Fax is required'),
  // address: Yup.string().required('Address is required'),
  // city: Yup.string().required('City is required'),
  // state: Yup.string().required('State is required'),
  // zip: Yup.string().required('Zip is required'),
  paymentTerms: Yup.string().required("Payment terms is required"),
  displayName: Yup.string().required("Display name is required")
});

export const resourceSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required"),
  // lastName: Yup.string().required('Last Name is required'),
  // phone: Yup.string().required('Phone number is required'),
  // address: Yup.string().required('Address is required'),
  // email: Yup.string().email('Invalid email').required('Email is required'),
  // cost_per_ton: Yup.number().typeError('Must be a number').required('Cost Per Ton is required'),
  // cost_per_yard: Yup.number().typeError('Must be a number').required('Cost Per Yard is required'),
  // cost_per_load: Yup.number().typeError('Must be a number').required('Cost Per Load is required')
});

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phone: Yup.string().required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  status: Yup.string().required("Status is required"),
  role: Yup.string().required("Role is required")
});

export const settingSchema = Yup.object().shape({
  businessName: Yup.string().required("Business Name is required"),
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("Zip is required"),
  phone: Yup.string().required("Phone number is required"),
  dsnyTransferStation: Yup.string().required("DSNY transfer station is required"),
  nycdecPermit: Yup.string().required("NYCDEC permit is required"),
  invoicePrefix:Yup.string().trim().max(2, 'Too Long!').required('Invoice Prefix is required'),
  // scaleip: Yup.string().required("Scale IP is required"),
  // scaleport: Yup.string().required("Scale Port is required"),
  orderPrefix: Yup.string().trim().max(2, 'Too Long!').required('Order Prefix is required'),
  autoLogoutDuration: Yup.number()
    .typeError("Must be a number")
    .required("Duration is required"),
  domain: Yup.string()
          .required("Domain name is required")
          .matches(/^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{3,6}$/, "Please enter valid domain"),
  billingEmail:Yup.string().email("Invalid email").optional(""),
});

// export const dumpRateSchema1 = Yup.object().shape({
//   pricePerTonLight: Yup.number().typeError('Must be a number'),
//   pricePerTonHeavy: Yup.number().typeError('Must be a number'),
//   pricePerTonMixedCD: Yup.number().typeError('Must be a number'),
//   pricePerTonWood: Yup.number().typeError('Must be a number'),
//   pricePerTonMetal: Yup.number().typeError('Must be a number'),
//   pricePerTonRoofing: Yup.number().typeError('Must be a number'),
//   pricePerTonMasonry: Yup.number().typeError('Must be a number'),
//   pricePerTonBrick: Yup.number().typeError('Must be a number'),
//   pricePerTonDirt: Yup.number().typeError('Must be a number'),
//   pricePerTonConcrete: Yup.number().typeError('Must be a number'),
//   pricePerYardLight: Yup.number().typeError('Must be a number'),
//   pricePerYardHeavy: Yup.number().typeError('Must be a number'),
//   pricePerYardMixedCD: Yup.number().typeError('Must be a number'),
//   pricePerYardWood: Yup.number().typeError('Must be a number'),
//   pricePerYardMetal: Yup.number().typeError('Must be a number'),
//   pricePerYardRoofing: Yup.number().typeError('Must be a number'),
//   pricePerYardMasonry: Yup.number().typeError('Must be a number'),
//   pricePerYardBrick: Yup.number().typeError('Must be a number'),
//   pricePerYardDirt: Yup.number().typeError('Must be a number'),
//   pricePerYardConcrete: Yup.number().typeError('Must be a number')
// });

export const dumpRateSchema = ({ dumpRates: { defaultOption, ...object } }) => {
  const obj = {};
  for (let k in object) {
    if (defaultOption === k) {
      obj[k] = Yup.string()
        .required(`This field is required`)
        .nullable();
    } else {
      // obj[k] = Yup.number().typeError("Must be a number");
    }
  }
  return Yup.object().shape({
    // companyName: Yup.string().required("Company email is required"),
    paymentTerms: Yup.string().required("Payment terms is required"),
    displayName: Yup.string().required("Display name is required"),
    pricingTier:Yup.string().required("pricing tier is required"),
    email: Yup.string().email("Invalid email"),
    dumpRates: Yup.object().shape({
      ...obj,
      defaultOption: Yup.string().required(`Select default option`)
    })
  });
};
export const createCustomer=Yup.object().shape({
  // companyName: Yup.string().required("Company email is required"),
  paymentTerms: Yup.string().required("Payment terms is required"),
  displayName: Yup.string().required("Display name is required"),
  billingContactName:Yup.string().required("Billing Constact name is required"),
  billingEmailAddress: Yup.string().when('paymentTerms', {is: (paymentTerms) => paymentTerms !== 'cod',
    then: Yup.string().email("Invalid email").required("Billing Email Address name is required"),
    otherwise: Yup.string().email("Invalid email")
}),
  email: Yup.string().email("Invalid email"),
  pricingTier:Yup.string().required("Pricing Tier is required"),
  invoiceFrequency: Yup.string().required("Payment Terms is required"),
  invoiceDelivery:Yup.string().required(" Invoice Delivery is required"),
  secondaryBillingContacts: Yup.array().of(
    Yup.object().shape({
      billingContactName: Yup.string().required("Billing Constact name is required"),
      billingEmailAddress: Yup.string().email("Invalid email").required("Billing Email Address name is required"),
    })
  ),
 })

export const activeOutboundActivitySchema = (isYardageRequired)=>{
  return Yup.object().shape({
    date: Yup.string().required("Date is required"),
    manifestNumber: Yup.number().typeError("Must be a number"),
    manifestNumberOther: Yup.number().typeError("Must be a number"),
    // material: Yup.string().required("Material is required"),
    // grossWeight: Yup.string().required("Gross Weight is required"),
    // yardage: Yup.number()
    //   .required("Yardage is required")
    //   .typeError("Must be a number"),
    materials: Yup.array()
    .of(
      Yup.object().shape({
        material: Yup.string().required("Material is required"),
        //grossWeight: Yup.string().required("Gross Weight is required"),
        yardage:isYardageRequired ? Yup.number()
          .required("Yardage is required")
          .typeError("Must be a number"):
          Yup.number()
          .optional("")
          .typeError("Must be a number"),
      })
    ),
    facilityId: Yup.string().required("Facility is required"),
    haulerId: Yup.string().required("HaulerId is required"),
    truckType: Yup.string().required("TruckType is required")
  })
}

export const editActiveOutboundActivitySchema = (isYardageRequired)=>{
  return Yup.object().shape({
    date: Yup.string().required("Date is required"),
    manifestNumber: Yup.number().typeError("Must be a number"),
    manifestNumberOther: Yup.number().typeError("Must be a number"),
    materials: Yup.array()
    .of(
      Yup.object().shape({
        material: Yup.string().required("Material is required"),
        //grossWeight: Yup.string().required("Gross Weight is required"),
        yardage:isYardageRequired ? Yup.number()
          .required("Yardage is required")
          .typeError("Must be a number")
          : Yup.number()
          .optional("")
          .typeError("Must be a number"),
      })
    ),
    facilityId: Yup.string().required("Facility is required"),
    haulerId: Yup.string().required("Hauler is required"),
    truckType: Yup.string().required("TruckType is required"),
    actualGross: Yup.string().required("Actual gross is required"),
    tare: Yup.string().required("Tare is required")
  });
}

export const assignInboundSchemaTab2 = (checkbox)=>{
 return Yup.object().shape({
    customer: Yup.string().required("Please select customer"),
    //driver: Yup.string().required("Please select driver"),
    plateNumber: Yup.string().required("Enter plate number"),
    number: Yup.string().optional(),
    defaultOutboundWeight:checkbox && Yup.string().required("Please select default outbound weight"),
    containers: checkbox ? Yup.object().test("containers-exist", "Please Select Container", function (value) {
      if (!value || Object.keys(value).length === 0) {
        return this.createError({ message: "Please Select Container", path: "containers" });
      }
      return true;
    }):Yup.object().optional(),
  });
}

export const truckAddSchema = Yup.object().shape({
  //driver: Yup.string().required("Please select driver"),
  plateNumber: Yup.string().required("Enter plate number"),
  number: Yup.string().optional(),
  type: Yup.string().required("Please select truck type"),
});

export const assignInboundSchemaTab1 = Yup.object().shape({
  customer: Yup.string().required("Please select customer"),
  //driver: Yup.string().required("Please select driver")
  // truck: Yup.string().required("Please select truck")
});

export const cardSchema = Yup.object().shape({
  number: Yup.string().required("Please enter card number"),
  expDate: Yup.string().required("Expiration date"),
  nameOnCard: Yup.string().required("Please enter name on card"),
  securityCode: Yup.string().required("Security code")
});

export const pendingOrderSchema1 = (additionalItems,showYardageValue,newTruck) => {
  const obj = {};
    for (let i = 0; i < additionalItems.length; i++) {
      if (additionalItems[i].quantity === '') {
      obj[additionalItems[i].name] = Yup.string().required("Please enter a value");
      }
    }
  
  return Yup.object().shape({
    dumpRate: Yup.string().required("Please select dump rate"),
    yardage:showYardageValue && Yup.string().nullable().test('is_valid_yardage', 'Enter a valid yardage value', yardage => {
     const num_yardage_value = +yardage;
  if (yardage === null || yardage === ''  || Number.isNaN(num_yardage_value) || num_yardage_value === 0) 
  return false;
  return true;
}).required("Enter yardage value"),
    plateNumber:newTruck ? Yup.string().required("Enter truck number") : Yup.string().optional(),
    truckType:newTruck ? Yup.string().required("Enter truck number") : Yup.string().optional(),
    ...obj
  });
};

export const pendingOrderSchema2 = Yup.object().shape({
  dumpRate: Yup.string().required("Please select dump rate")
  // paymentType: Yup.string().required("Please select payment type")
});

export const profileSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required")
});

export const stripeInfoSchema = Yup.object().shape({
  stripePublicKey: Yup.string().required("Public Key is required."),
  stripeSecretKey: Yup.string().required("Secret Key is required.")
});

export const withDrawlSchema = Yup.object().shape({
  amount: Yup.string().required("Enter amount"),
  withDrawlReason: Yup.string().required("Enter reason")
});

export const depositSchema = Yup.object().shape({
  amount: Yup.string().required("Enter amount")
});

export const projectSchema = Yup.object().shape({
  projectName: Yup.string().required("Project name is required"),
});
export const driverSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  // phone: Yup.string().required("Phone number is required"),
  state: Yup.string().required("State is required"),
  //licenseNumber: Yup.string().required("License number is required")
});
export const scaleSchema = Yup.object().shape({
  scaleName: Yup.string().required("Scale name is required"),
  // scaleIp: Yup.string().ipv4().required("Scale ip address is required"),
  scaleIp: Yup.string().required("Scale Ip Address is required"),
  scalePort: Yup.number()
    .typeError("Must be a Number")
    .required("Scale Port is required"),
  // scaleLocation: Yup.string(),
});
export const PricingTierCreateEdit = ({ dumpRates: { defaultOption, ...object } }) => {
  const obj = {};
  for (let k in object) {
    if (defaultOption === k) {
      obj[k] = Yup.string().required(`This field is required`).nullable();
    } else {
      // obj[k] = Yup.number().typeError("Must be a number");
    }
  }
  return Yup.object().shape({
    pricingTier: Yup.string().required("Pricing tier name is required"),
    // paymentTerms: Yup.string().required("Payment terms is required"),
    // displayName: Yup.string().required("Display name is required"),
    // email: Yup.string().email("Invalid email"),
    dumpRates: Yup.object().shape({
      // ...obj,
      defaultOption: Yup.string().required(`Select Dump Rates`),
    }),
  });
};
