import { connect } from 'react-redux'
import { getMe, fetchMe,updateUserToken } from '../../store/user/duck'
import { fetchOrders, fetchOrderList } from "../../store/order/duck";
import { getScale } from '../../store/scale/duck';
import {fetchContainers} from "../../store/container/duck"
import ScaleComponent from './component'

const ScaleContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    getScale,
    updateUserToken,
    fetchMe,
    fetchOrders,
    fetchContainers,
    fetchOrderList
  }
)(ScaleComponent);

export default ScaleContainer
