
import React, { PureComponent } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { ErrorMessage } from "formik";
import "react-image-crop/lib/ReactCrop.scss";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactModal from 'react-modal';
import { CloseIcon } from "../../../components/icons";
import { projectSchema } from "../../../utils/validations";
import { Switch } from "antd";

export default class CreateProject extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      InitialValues: {
        projectName: "",
        customerId: "",
        startDate: new Date(),
        endDate: new Date(),
        projectStatus: "active",
        isLEEDsRequired: false,
      },
    };
  }

  componentDidMount() {
    console.log(this.props.customerData)
    if(this.props.isCreateAndEditProject === "edit"){
      const forEdit={
        projectName:this.props.projectData?.projectName,
        customerId:this.props?.customerData?._id,
        startDate:new Date(this.props.projectData?.startDate),
        endDate:new Date(this.props.projectData?.endDate),
        projectStatus:this.props.projectData?.projectStatus,
        isLEEDsRequired: this.props.projectData?.isLEEDsRequired || false,
      }
      this.setState({InitialValues:forEdit})
    }else{
      let InitialValues = this.state.InitialValues
      this.setState({ ...InitialValues,customerId: this.props.customerData?._id})
    }
  }

  closeModel=()=>{
    this.props.closeModal()
  }

  async CreateNewProject(values) {
    const payload = {
      companyId:this.props.customerData?.companyId,
      projectName: values.projectName,
      customerId: this.props.customerData?._id,
      startDate: values.startDate,
      endDate: values.endDate,
      projectStatus: values.projectStatus,
      isLEEDsRequired: values.isLEEDsRequired
    }
    if(this.props.isCreateAndEditProject==="edit"){
      payload._id=this.props.projectData._id
      const {
        value: { status }
      } = await this.props.updateProject(payload);
      if (status) {
        this.closeModel()
      }
    }else{
      const {
        value: { status }
      } = await this.props.createProject(payload);
      if (status) {
        this.closeModel()
      }
    }
  }

  render() {
    return (
      <ReactModal
      isOpen={this.props.isProjectModel}
      onRequestClose={() => {this.closeModel()}}
    >
      <div className="react-modal-dialog react-modal-dialog-centered">
          <div className="react-modal-header">
            <h5 className="react-modal-title">{this.props.isCreateAndEditProject ==="edit"? "Edit Project" :"Create Project"}</h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={() => { document.body.classList.remove("ReactModal__Body--open");this.closeModel()  }}
            >
              <CloseIcon />
            </button>
          </div>
        <div className="react-modal-body">
        <Formik
         enableReinitialize
         initialValues={this.state.InitialValues}
         validationSchema={projectSchema}
         onSubmit={(values, formikProps) =>
          this.CreateNewProject(values, formikProps)
          }
        >
        {({ values, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <form
             onSubmit={handleSubmit}
             noValidate
             style={{ display: false ? "none" : "" }}>
             <div className="row">
               <div className="col-lg-12">
                <div className='form-group material-textfield'>
                  <input
                    type='text'
                    value={values.projectName}
                    onChange={handleChange}
                    className='form-control material-textfield-input'
                    name='projectName'
                    required
                  />
                  <ErrorMessage
                    component='span'
                    name='projectName'
                    className='invalid-feedback d-block'
                  />
                  <label className='material-textfield-label'>
                    Project Name<span>*</span>
                  </label>
                </div>
                <div className='form-group material-textfield'>
                  <select
                    value={values.projectStatus}
                    className='form-control custom-select'
                    name='projectStatus'
                    onChange={handleChange}
                    required>
                    <option value="active">Active</option>
                    <option value='inActive'>InActive</option>
                  </select>
                  <ErrorMessage
                    component='span'
                    className='invalid-feedback d-block'
                    name='projectStatus'
                  />
                  {/* <label className="material-textfield-label">
                      State<span>*</span>
                      <    /label> */}
                </div>
                <div className="calendar__form">
                  <div className="input-from">
                    <div className="form-group">
                      <DatePicker
                        name="startDate"
                        selected={moment(values.startDate, "MMM DD, YYYY").toDate()}
                        onChange={(e) => {
                          setValues({ ...values, startDate: moment(e, "MMM DD, YYYY").toDate() })
                        }}
                        className="form-control material-textfield-input"
                        placeholder="10/01/20"
                        required
                        autoComplete="off"
                      />
                      <label className="material-textfield-label label__for-date">Start Date</label>
                    </div>
                  </div>
                  <div className="input-to">
                    <div className="form-group">
                      <DatePicker
                        name="endDate"
                        selected={moment(values.endDate, "MMM DD, YYYY").toDate()}
                        onChange={(e) => {
                          setValues({ ...values, endDate: moment(e, "MMM DD, YYYY").toDate() })
                        }}
                        className="form-control material-textfield-input"
                        placeholder="10/01/20"
                        required
                        autoComplete="off"
                      />
                      <label className="material-textfield-label label__for-date">End Date</label>
                    </div>
                  </div>
                </div>
                      <div className="col-md-6 p-0">
                        <div className="display-team-status">
                          <span className="display-team-text">
                            LEEDs Required
                          </span>
                          <Switch
                            name="isLEEDsRequired"
                            checked={values.isLEEDsRequired}
                            onChange={() => {
                              const updatedIsLEEDsRequired = !values.isLEEDsRequired;
                              this.setState({ isLEEDsRequired: updatedIsLEEDsRequired }, () => {
                                setValues({ ...values, isLEEDsRequired: updatedIsLEEDsRequired });
                              });
                            }}
                          />
                          <p
                            className={
                              values.isLEEDsRequired
                                ? "on-of-text text-green"
                                : "on-of-text text-red"
                            }
                          >
                            {values.isLEEDsRequired ? "Yes" : "No"}
                          </p>
                        </div>
                      </div>
                <button
                  className='btn btn-dark btn-lg btn-block w-100 btn-done'
                  type='submit'
                  disabled={isSubmitting}>
                  {isSubmitting ? "Please wait..." : this.props.isCreateAndEditProject ==="edit" ? "Update" :"Done"}
                </button>                
              </div>
             </div>
          </form>
        )}
      </Formik>
        </div>
      </div>
    </ReactModal>
    
    );
  }
}
