import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const createCustomer = data => {
  return fetch(`${HOSTNAME}/customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createProject = data => {
  return fetch(`${HOSTNAME}/new-project`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const updateProject = data => {
  return fetch(`${HOSTNAME}/updateProjects`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchCustomers = ({ searchText, status, sortOrder, skip, limit, inActiceCustomers, isDisableCount, searchStartsWith }) => {
  return fetch(
    `${HOSTNAME}/customers?searchText=${escape(searchText)}&status=${status}&sortOrder=${JSON.stringify(
      sortOrder
    )}&skip=${skip || 0}&limit=${limit || 20}&inActiceCustomers=${inActiceCustomers ? inActiceCustomers : false}${isDisableCount ? `&isDisableCount=${isDisableCount}` : ""}&searchStartsWith=${searchStartsWith ? searchStartsWith: false}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchBillingCustomers = ({ searchText, status, sortOrder, skip, limit, searchStartsWith }) => {
  return fetch(
    `${HOSTNAME}/billingCustomers?searchText=${escape(searchText)}&status=${status}&sortOrder=${JSON.stringify(
      sortOrder
    )}&skip=${skip || 0}&limit=${limit || 20}&searchStartsWith=${searchStartsWith ? searchStartsWith: false}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchCustomer = (customerId,sortData,inActivetrucks,skip,limit) => {
  return fetch(`${HOSTNAME}/customer/${customerId}?sortData=${sortData !== undefined ?JSON.stringify(
    sortData
  ):""}&inActivetrucks=${inActivetrucks ? true :false}&skip=${skip || 0}&limit=${limit || 10}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchProject = payload => {
  return fetch(`${HOSTNAME}/projects/${payload.id}?searchText=${escape(payload.searchText)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchPaymentMethodCards = (customerId) => {
  return fetch(`${HOSTNAME}/get-payment-methods/${customerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateCustomer = payload => {
  return fetch(`${HOSTNAME}/customer`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchDrivers = customerId => {
  return fetch(`${HOSTNAME}/drivers/${customerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchLogs = customerId => {
  console.log(customerId)
  return fetch(`${HOSTNAME}/logs/${customerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchDriversByCustomerId = payload => {
  return fetch(`${HOSTNAME}/driver/${payload.customerId}?searchText=${escape(payload.searchText)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchTrucksByCustomerId = payload => {
  return fetch(`${HOSTNAME}/truck/${payload.customerId}?searchText=${escape(payload.searchText)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const fetchTrucksByCompanyId = payload => {
  return fetch(`${HOSTNAME}/truck/company/${payload.companyId}?searchText=${escape(payload.searchText)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const chargeCustomer = payload => {
  return fetch(`${HOSTNAME}/charge-customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { status: false, errMessage: error?.message}
      // throw error;
    });
};

export const addCard = payload => {
  return fetch(`${HOSTNAME}/add-card`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateDriver = payload => {
  return fetch(`${HOSTNAME}/driver`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const addNewTruck = data => {
  return fetch(`${HOSTNAME}/truck`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const updateTruck = payload => {
  return fetch(`${HOSTNAME}/truck`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const updateTruckWithIndex = payload => {
  return fetch(`${HOSTNAME}/truck-index`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchLocationsList = ({ searchText }) => {
  return fetch(`${HOSTNAME}/locations?searchText=${escape(searchText)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchQBCustomers = ({ skip, limit, search }) => {
  return fetch(`${HOSTNAME}/quickbooks-customers?skip=${skip}&limit=${limit}&search=${search}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getQuickBooksUser = () => {
  return fetch(`${HOSTNAME}/quickbooks-user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchQBCustomersBySearchText = ({ searchText }) => {
  return fetch(`${HOSTNAME}/quickbooks-customers-by-text?searchText=${searchText}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deletePaymentMethod = (id) => {
  return fetch(`${HOSTNAME}/payment-method/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const syncCustomers = () => {
  return fetch(`${HOSTNAME}/sync-customers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchPricingByCustomerId = payload => {
  return fetch(`${HOSTNAME}/customer_pricing_tiers/${payload.customerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchCustomerTrucks = payload => {
  return fetch(`${HOSTNAME}/customer-trucks?customerId=${payload.customerId}&skip=${payload.skip}&limit=${payload.limit}&inActivetrucks=${payload.inActivetrucks}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then(res => {
    return res.json();
  })
  .then(payload => {
    return payload;
  })
  .catch(error => {
    throw error;
  });
};

export const fetchCustomerPastWeignIns = payload => {
  return fetch(`${HOSTNAME}/customer-past-weighins/${payload.customerId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then(res => {
    return res.json();
  })
  .then(payload => {
    return payload;
  })
  .catch(error => {
    throw error;
  });
};
