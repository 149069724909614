import { connect } from 'react-redux'
import { getMe, getUserDetail,fetchMe,updateUserToken,updateUserById } from '../../store/user/duck'
import UserDetailsComponent from './component'
import { verifyQBIntegration } from './../../store/dashboard/duck';

const UserDetailsContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
    updateUserToken,
    fetchMe,
  	getUserDetail,
    verifyQBIntegration,
    updateUserById
  }
)(UserDetailsComponent)

export default UserDetailsContainer
