import { connect } from 'react-redux'
import { getMe ,updateUserToken,fetchMe,uploadImage} from '../../store/user/duck'
import { fetchDashboardData } from "../../store/dashboard/duck";
import {fetchCompaniesByID} from '../../store/company/duck'
import {getPricingTier,getPricingTierById} from "../../store/Pricing_tier/duck"
import ChargeByYardComponent from './component';
import { driverDetails ,createDriver, numberPlateDetails} from '../../store/driver/duck';
import { 
  fetchCustomers, 
  fetchCustomer,
  updateCustomer,
  fetchDriversByCustomerId, 
  fetchTrucksByCustomerId ,
  addCard,chargeCustomer,
  createProject,
  fetchProjects
} from '../../store/customer/duck'
import { fetchSetting, updateCompany } from "../../store/company/duck";
import { createOrder,createCharge } from '../../store/outboundactivity/duck';
import { getPaybliIntegration , getPayabliCustomerData,makeTransaction,getPaybliTerminalDevices,makeTerminalTransaction} from '../../store/paybli_integrations/duck';
import { chargeOnDevice,fetchDevices, getOrderInvoicePdf, fetchOrder, fetchDeviceStatus } from '../../store/order/duck';
import {customerInvoicesByCustomerId} from "../../store/Billing/duck"
import { fetchTrucksByCompanyId } from '../../store/customer/duck'
import { getCustomercredits } from '../../store/Billing/duck'
const ChargeByYardContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  // Map actions to dispatch and props
  {
    getMe,
    fetchMe,
    fetchCustomer,
    fetchDashboardData,
    fetchCompaniesByID,
    updateUserToken,
    fetchDriversByCustomerId, 
    fetchTrucksByCustomerId ,
    fetchCustomers,
    getPricingTier,
    fetchSetting,
    driverDetails,
    createDriver,
    numberPlateDetails,
    customerInvoicesByCustomerId,
    uploadImage,
    createOrder,
    fetchOrder,
    createCharge,
    getPricingTierById,
    getPaybliIntegration,
    getPayabliCustomerData,
    makeTransaction,
    updateCustomer,
    addCard,
    chargeCustomer,
    fetchDevices,
    getPaybliTerminalDevices,
    makeTerminalTransaction,
    chargeOnDevice,
    fetchTrucksByCompanyId,
    createProject,
    fetchProjects,
    getOrderInvoicePdf,
    getCustomercredits,
    updateCompany,
    fetchDeviceStatus
  }
)(ChargeByYardComponent);

export default ChargeByYardContainer
