import { connect } from 'react-redux'
import { getMe, uploadImage } from '../../store/user/duck'
import { fetchSetting, updateCompany } from '../../store/company/duck'
import SettingsComponent from './component'
import { checkPabliIntegration } from '../../store/Billing/duck'

const SettingsContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchSetting,
    updateCompany,
    uploadImage,
    checkPabliIntegration
  }
)(SettingsComponent)

export default SettingsContainer
