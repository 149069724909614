export const responseCode = (code) => {
    code = Number(code);
    switch (code) {
        // case 100:
        //     return { message: "Transaction Approved", colour: 'green' };
        case 200:
            return { message: "Transaction declined (200): Transaction was declined by the bank. Customer must contact bank for details.", colour: "red" };
        case 201:
            return { message: "Transaction declined (201): Transaction was declined by the bank. Customer must contact bank for details.", colour: "red" };
        case 202:
            return { message: "Insufficient funds (202): Oops! It appears there are insufficient funds to cover this payment. Please try a different payment method.", colour: "red" };
        case 203:
            return { message: "Over limit (203): Oops! It appears this transactions exceeds the account’s transaction limit. Please try a different payment method.", colour: "red" };
        case 204:
            return { message: "Transaction declined (204): Transaction was declined by the bank. Customer must contact bank for details.", colour: "red" };
        case 220:
            return { message: "Restricted payment (220): Sorry! It appears this is a restricted payment. Please contact support with the error code.", colour: "red" };
        case 221:
            return { message: "Issue with bank (221): Oops! It appears there is an issue with the bank. Customer must contact bank for details.", colour: "red" };
        case 222:
            return { message: "Issue with card (222): Oops! It appears there is an issue with the card. Customer must contact bank for details.", colour: "red" };
        case 223:
            return { message: "Expired card (223): Oops! It appears this card is expired.", colour: "red" };
        case 224:
            return { message: "Invalid expiration date (224): Please try again. If issue persists, please have the customer contact the bank.", colour: "red" };
        case 225:
            return { message: "Invalid security code (225): Please try again. If the issue persists, please have the customer contact the bank.", colour: "red" };
        case 226:
            return { message: "Invalid PIN (226): PIN is invalid or missing. Please try again. If the issue persists, please have the customer contact the bank.", colour: "red" };
        case 240:
            return { message: "Transaction declined (240): Transaction was declined by the bank. Customer must contact bank for details.", colour: "red" };
        case 250:
            return { message: "Pick up card (250): This card has been reported lost or stolen, and the bank has requested you keep the card and call the number on the back to report it.", colour: "red" };
        case 251:
            return { message: "Lost card (251): This card has been reported as lost.", colour: "red" };
        case 252:
            return { message: "Stolen card (252): This card has been reported as stolen.", colour: "red" };
        case 253:
            return { message: "Suspected fraud (253): The card-issuer suspects fraud.", colour: "red" };
        case 300:
            return { message: "Transaction rejected (300): Transaction was rejected by the gateway. Please contact support with the error code.", colour: "red" };
        case 400:
            return { message: "Transaction error (400): Sorry! It appears there is an issue with the payment processor. Please contact support with the error code.", colour: "red" };
        case 410:
            return { message: "Invalid merchant configuration (410): Oops! It appears there is an issue with your merchant with the payment processor. Please contact support with the error code.", colour: "red" };
        case 420:
            return { message: "Communication issue (420): Sorry! It appears there was an issue communicating the transaction. Please contact support with the error code.", colour: "red" };
        case 421:
            return { message: "Communication issue (421): Sorry! It appears there was an issue communicating the transaction. Please contact support with the error code.", colour: "red" };
        case 440:
            return { message: "Processor format error (440): Oops! It appears there was an issue with the provided transaction information. Please try again. If the issue persists, please contact support with the error code.", colour: "red" };
        case 441:
            return { message: "Invalid transaction data (441): Oops! It appears there was an issue with the provided transaction information. Please try again. If the issue persists, please contact support with the error code.", colour: "red" };
        case 460:
            return { message: "Processor feature not available (460): Oops! It appears the requested functionality is not supported or enabled by the payment processor. If you believe this to be an error, please contact support with the error code.", colour: "red" };
        case 500:
            return { message: "Error (500): Sorry! It seems there was an issue processing the payment. Please contact support with the error code.", colour: "red" };
        case 600:
            return { message: "Configuration Error (600): Sorry! It seems there was an issue processing the payment. Please contact support with the error code.", colour: "red" };
        default:
            return { message: "Card could not be charged, please try again.", colour: "red" };
    }
};