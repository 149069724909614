import { connect } from 'react-redux'
import { getMe } from '../../store/user/duck'
import NoRecordsComponent from './component'

const NoRecordsContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe
  }
)(NoRecordsComponent)

export default NoRecordsContainer
