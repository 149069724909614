import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { BackArrowIcon } from "../../components/icons";
import "./styles.scss";

export default class CompanyUserListComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      searchText: "",
      users: [],
      company: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCompanyUsers();
  }

  async fetchCompanyUsers() {
    const {
      fetchUsersByCompanyId,
      location: {
        state: { id }
      }
    } = this.props;
    const {
      value: { data, company }
    } = await fetchUsersByCompanyId(id);
    this.setState({ users: data, company });
  }

  async impersonateUser({ _id }) {
    const {
      value: { data }
    } = await this.props.getUserToken({ _id });
    const auth = localStorage.getItem("Authorization");
    localStorage.setItem("i_Authorization", auth);
    localStorage.setItem("Authorization", data);
    localStorage.setItem("user", "superadmin");
    this.props.history.push("/dashboard");
  }

  render() {
    const { users, company } = this.state;
    return (
      <div className="admin__userlist-wrapper">
        <div className="custome__container">
          <div className="btn-back-header">
            <button className="btn btn-link btn-back">
              <Link to={"/companylisting"}>
                <BackArrowIcon />
                Back
              </Link>
              <span>{company ? company.name : ""} User List </span>
            </button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table custom-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(({ _id, firstName, lastName, email, phone, role, status }) => {
                      return (
                        <tr key={_id}>
                          <td>{_.startCase(`${firstName} ${lastName}`)}</td>
                          <td>{email}</td>
                          <td>{phone}</td>
                          <td>{role}</td>
                          <td>{status === 1 ? "Active" : "Inactive"}</td>
                          <td className="underline text-bold" onClick={() => this.impersonateUser({ _id })}>
                            Impersonate
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
