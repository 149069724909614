import { connect } from 'react-redux'
import { getMe, updateProfile,updateUserToken,fetchMe } from '../../store/user/duck'
import CardknoxInfoComponent from './component'

const CardknoxInfoContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
  	getMe,
    updateUserToken,
  	updateProfile,
    fetchMe
  }
)(CardknoxInfoComponent)

export default CardknoxInfoContainer
