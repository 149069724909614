import { connect } from 'react-redux'
import { getMe } from '../../store/user/duck'
import { verifyQBIntegration } from "../../store/dashboard/duck";
import QuickBooksComponent from "./component";

const QuickBooksContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user
  }),
  {
    getMe,
    verifyQBIntegration
  }
)(QuickBooksComponent);

export default QuickBooksContainer;
