import { connect } from "react-redux";
import CMComponent from "./component";
import { refreshToken, updateQuickBooks } from "../../store/dashboard/duck";
import { fetchMe,updateUserToken, getMe } from "../../store/user/duck";
import { updateCustomer, fetchCustomers, fetchQBCustomers, fetchQBCustomersBySearchText, syncCustomers, fetchCustomer, updateDriver, updateTruck, deletePaymentMethod, updateTruckWithIndex, fetchProjects, createProject, updateProject } from "../../store/customer/duck";
import {getRecivingPaymentInvoices,getInvoiceDetalByInvoiceId,updateInvoicDetail,getCustomercreditsForCustomers,getReceivePaymentForCustomers, syncPostedOrder,customerInvoicesByCustomerId, getReceivePaymentForPdf, getQbDetails, sendInvoiceEmail} from '../../store/Billing/duck'
import {getInvoiceDeliveryByIdData} from "../../store/invoiceDelivery/duck"
import { getPayabliCustomerData } from "../../store/paybli_integrations/duck";
import { getOrderInvoicePdf, fetchProjectOrderList } from "../../store/order/duck";
import {fetchSetting} from '../../store/company/duck'

const CMContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user
  }),
  {
    fetchMe,
    updateUserToken,
    refreshToken,
    updateQuickBooks,
    updateCustomer,
    fetchCustomers,
    fetchQBCustomers,
    fetchQBCustomersBySearchText,
    syncCustomers,
    syncPostedOrder,
    getPayabliCustomerData,
    updateTruckWithIndex,
    getReceivePaymentForPdf,
    getOrderInvoicePdf,
    customerInvoicesByCustomerId,
    getReceivePaymentForCustomers,
    getCustomercreditsForCustomers,
    updateInvoicDetail,
    getInvoiceDetalByInvoiceId,
    getRecivingPaymentInvoices,
    getInvoiceDeliveryByIdData,
    updateTruck,
    updateDriver,
    deletePaymentMethod,
    fetchCustomer,
    getMe,
    fetchProjects,
    getQbDetails,
    createProject,
    fetchProjectOrderList,
    updateProject,
    fetchSetting,
    sendInvoiceEmail
  }
)(CMComponent);

export default CMContainer;
