import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import InputMask from "react-input-mask";
import { Switch } from "antd";
import { CloseIcon } from "../../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { resourceSchema } from "../../../utils/validations";
import PlacesAutocomplete from "../../../components/forms/PlaceSearchBox";
import NumberFormat from "react-number-format";

export default class ResourcesComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        companyName: "",
        phone: "",
        email: "",
        address: ""
      },
      status: 1,
      facilityDetails: [
        {
          facility: "",
          cost_per_ton_for_facility: "",
          cost_per_load_for_facility: "",
        },
      ],
      showFacilityDetails: false,
      selectedFacilities: [],
      resource: null,
      address: "",
      cost_per_yard: "",
      cost_per_ton: "",
      cost_per_load: ""
    };
  }


  static getDerivedStateFromProps({ resource, facilityData }, prevState,) {
    if (resource && resource !== prevState.resource) {
      const { status, companyName, phone, email, cost_per_ton, cost_per_load, cost_per_yard, address, facilityDetails } = resource;
      const initialValues = {
        companyName,
        phone,
        email,
        address,
        facilityDetails
      };
      return { status, initialValues, resource, address, cost_per_ton, cost_per_load, cost_per_yard, facilityDetails, facilityData };
    }
    return null;
  }
  handleCloseModal = () => {
    this.setState({
      errorMessage: "",
      emailerrorMessage: "",
      facilityerrorMessage: ""
    });
    this.props.closeModal();
  };

  handleAddFacility = (values, setValues) => {
    const { facilityDetails } = values;
    const newFacility = {
      facility: "",
      cost_per_ton_for_facility: "",
      cost_per_load_for_facility: "",
    };
    setValues({ ...values, facilityDetails: [...facilityDetails, newFacility] });
  };

  handleRemoveFacility = (index, values, setValues) => {
    const { facilityDetails } = values;
    const updatedFacilityDetails = [...facilityDetails];
    updatedFacilityDetails.splice(index, 1);
    setValues({ ...values, facilityDetails: updatedFacilityDetails });
  };
    
  isFacilityAdded = (facilityOption, currentIndex) => {
    const { facilityDetails } = this.state;
    return facilityDetails.some((facility, index) => index !== currentIndex && facility.facility === facilityOption.companyName);
  };

  
  handleSubmit = async ({ email, phone, facilityDetails, ...values }, { setSubmitting, setErrors }) => {
    const {
      cost_per_ton,
      cost_per_load,
      cost_per_yard,
      resource: { _id, companyId, type }
    } = this.state;
    this.setState({ errorMessage: "" });
    setSubmitting(true);

    const formattedFacilityDetails = facilityDetails.map(facility => ({
      facility: facility.facility,
      cost_per_ton_for_facility: (facility.cost_per_ton_for_facility ? String(facility.cost_per_ton_for_facility).replace(/,/g, "") : ""),
      cost_per_load_for_facility: (facility.cost_per_load_for_facility ? String(facility.cost_per_load_for_facility).replace(/,/g, "") : ""),
    }));

    const isFacilityDetailsValid = facilityDetails.every(
      (facility) => facility.cost_per_ton_for_facility || facility.cost_per_load_for_facility
    );
  
    if (!isFacilityDetailsValid) {
      setSubmitting(false);
        this.setState({ facilityerrorMessage: "Please input either Cost per Ton or Cost Per Load to save" });
      return;
    }

    const invalidFacilityIndex = facilityDetails.findIndex(facility => facility.facility === '' || facility.facility === 'Select Facility');
    if (invalidFacilityIndex !== -1) {
      setSubmitting(false);
      this.setState({ facilityerror: "Please select a facility", invalidFacilityIndex });
      return;
    }

    const payload = {
      ...values,
      facilityDetails: formattedFacilityDetails,
      cost_per_ton: (cost_per_ton ? String(cost_per_ton).replace(/,/g, "") : ""),
      cost_per_load: (cost_per_load ? String(cost_per_load).replace(/,/g, "") : ""),
      cost_per_yard: (cost_per_yard ? String(cost_per_yard).replace(/,/g, "") : ""),
      phone: phone.replace(/[^\d]/g, ""),
      status: this.state.status,
      companyId,
      type,
      _id
    };
    if (email !== "") {
      payload.email = email;
    }
    const {
      value: { status, message }
    } = await this.props.updateResource(payload);
    if (status) {
      this.props.fetchResources();
      this.handleCloseModal();
      setSubmitting(false);
    } else {
      setSubmitting(false);
      if (message==="Email already exists."){
        this.setState({ emailerrorMessage: message });
      }
      else{
      this.setState({ errorMessage: message });
    }
    }
  };

  render() {
    const { editModal, tabName, facilityData } = this.props;
    const {
      initialValues,
      status,
      errorMessage,
      cost_per_ton,
      facilityerrorMessage,
      invalidFacilityIndex,
      emailerrorMessage,
      cost_per_load,
      cost_per_yard
    } = this.state;
    return (
      <ReactModal
        isOpen={editModal}
        onRequestClose={this.handleCloseModal}
        contentLabel="Please Confirm"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        <div className="react-modal-dialog react-modal-dialog-centered edit-third-party-modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Edit {tabName}</h5>
            <button type="button" className="btn react-modal-close" onClick={this.handleCloseModal}>
              <CloseIcon />
            </button>
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={resourceSchema}
            onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setValues }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="react-modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          value={values.companyName}
                          name="companyName"
                          required
                        />
                        <ErrorMessage component="span" name="companyName" className="invalid-feedback d-block" />
                        <span className="invalid-feedback d-block m-10">{errorMessage}</span>
                        <label className="material-textfield-label">
                          Company Name<span>*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <InputMask
                          mask="(999) 999-9999"
                          className="form-control material-textfield-input"
                          value={values.phone}
                          maskChar=""
                          name="phone"
                          onChange={handleChange}
                          required
                        />
                        <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                        <label className="material-textfield-label">
                          Phone Number
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group material-textfield">
                        <input
                          type="text"
                          className="form-control material-textfield-input"
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          required
                        />
                        <ErrorMessage component="span" name="email" className="invalid-feedback d-block" />
                        <span className="invalid-feedback d-block m-10">{emailerrorMessage}</span>
                        <label className="material-textfield-label">
                          Email
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group material-textfield">
                        <PlacesAutocomplete
                          type="text"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          className="form-control material-textfield-input"
                          handleAddressChange={({ address }) => setValues({ ...values, address })}
                          setValue={address => this.setState({ address })}
                          address={this.state.address}
                          isSetToState={true}
                          isRequired={false}
                        />
                        <ErrorMessage component="span" name="address" className="invalid-feedback d-block" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {tabName === "Third Party Hauler" || tabName === "In House Hauler" ? (
                      <div className="material__label-section add__another">
                        <h3
                          className={`material__label-title underline`}
                          onClick={() => {
                            if (facilityData.length !== values.facilityDetails.length) {
                              this.handleAddFacility(values, setValues);
                            }
                          }}
                        >
                          + Add Facility
                        </h3>
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
    {values?.facilityDetails && values?.facilityDetails.map((facility, index) => (
      <React.Fragment key={index}>
        <div className="col-md-4">
          <div className="form-group material-textfield">
            <select
                         className="form-control custom-select w-150 material-textfield-input pr-22"
              value={facility.facility}
              onChange={(e) => {
                const updatedFacilityDetails = [...values.facilityDetails];
                updatedFacilityDetails[index].facility = e.target.value;
                setValues({ ...values, facilityDetails: updatedFacilityDetails });
                this.setState({ facilityDetails: updatedFacilityDetails, facilityerror: null, invalidFacilityIndex: null });
              }}
              required
            >
              <option>Select Facility</option>
              {facilityData
                .filter(facilityOption => !this.isFacilityAdded(facilityOption, index))
                .sort((a, b) => a.companyName.localeCompare(b.companyName))
                .map((facilityOption) => (
                  <option key={facilityOption?.index} value={facilityOption?.companyName}>
                    {facilityOption?.companyName}
                  </option>
                ))}
            </select>

            <label className="material-textfield-label">
              Facility<span>*</span>
            </label>
            <div className=""><span className="invalid-feedback d-block m-10">{invalidFacilityIndex === index ? 'Please select a facility' : ''}</span>
            </div>
          </div>
        </div>
      <div className="col-md-3">
        <div className="form-group material-textfield">
          <NumberFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"input"}
            type="text"
            className="form-control material-textfield-input"
            onValueChange={(e) => {
              const { formattedValue } = e;
              const updatedFacilityDetails = [...values.facilityDetails];
              updatedFacilityDetails[index].cost_per_ton_for_facility = String(formattedValue);
              setValues({ ...values, facilityDetails: updatedFacilityDetails });
            }}
            value={facility.cost_per_ton_for_facility}
          />
          <label className="material-textfield-label">Cost Per Ton</label>
        </div>
      </div>
      <div className="col-md-3">
        <div className="form-group material-textfield">
          <NumberFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"input"}
            type="text"
            className="form-control material-textfield-input"
            onValueChange={(e) => {
              const { formattedValue } = e;
              const updatedFacilityDetails = [...values.facilityDetails];
              updatedFacilityDetails[index].cost_per_load_for_facility = formattedValue;
              setValues({ ...values, facilityDetails: updatedFacilityDetails });
            }}
            value={facility.cost_per_load_for_facility}
          />
          <label className="material-textfield-label">Cost Per Load</label>
        </div>
      </div>
      <div className="col-md-2 d-flex align-items-center">
            <div className="form-group">
            <button
            className="delete-btn"
            type="button"
            onClick={() => this.handleRemoveFacility(index, values, setValues)}
          >
            Remove
          </button>
            </div>
        </div>
    </React.Fragment>
  ))}
</div>

                  {tabName === "Facility" ? (
                    <React.Fragment>
                      <div className="form-group material-textfield">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"input"}
                          type="text"
                          className="form-control material-textfield-input"
                          onValueChange={(values) => {
                            const { formattedValue } = values;
                            this.setState({ cost_per_ton: formattedValue })
                          }}
                          value={cost_per_ton}
                          required
                        />
                        <label className="material-textfield-label">
                          Cost Per Ton
                        </label>
                      </div>
                      <div className="form-group material-textfield">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"input"}
                          type="text"
                          className="form-control material-textfield-input"
                          onValueChange={(values) => {
                            const { formattedValue } = values;
                            this.setState({ cost_per_yard: formattedValue })
                          }}
                          value={cost_per_yard}
                          required
                        />
                        <label className="material-textfield-label">
                          Cost Per Yard
                        </label>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="form-group material-textfield">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"input"}
                          type="text"
                          className="form-control material-textfield-input"
                          onValueChange={(values) => {
                            const { formattedValue } = values;
                            this.setState({ cost_per_ton: formattedValue })
                          }}
                          value={cost_per_ton}
                          required
                        />
                        <label className="material-textfield-label">
                          Cost Per Ton
                        </label>
                      </div>
                      <div className="form-group material-textfield">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"input"}
                          type="text"
                          className="form-control material-textfield-input"
                          onValueChange={(values) => {
                            const { formattedValue } = values;
                            this.setState({ cost_per_load: formattedValue })
                          }}
                          value={cost_per_load}
                          required
                        />
                        <label className="material-textfield-label">
                          Cost Per Load
                        </label>
                      </div>
                      <span className="invalid-feedback d-block m-10">{facilityerrorMessage}</span>
                    </React.Fragment>
                  )}
                  <div className="display-team-status">
                    <span className="display-team-text">Status</span>
                    <Switch
                      name="status"
                      checked={status === 3 ? false : true}
                      onChange={() => this.setState({ status: status === 3 ? 1 : 3 })}
                    />
                    <p className={status === 1 ? "on-of-text text-green" : "on-of-text text-red"}>
                      {status === 3 ? "Not Active" : "Active"}
                    </p>
                  </div>
                  <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-lg w-100">
                    {isSubmitting ? "Please wait..." : "Save"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </ReactModal>
    );
  }
}
