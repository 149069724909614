import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { CloseIcon } from "../../../components/icons";

class DeleteUser extends PureComponent {
  deleteUser = async () => {
    const { userId, deleteUser, fetchCompanyUsers, closeModal } = this.props;
    const {
      value: { status }
    } = await deleteUser(userId);
    if (status) {
      closeModal();
      fetchCompanyUsers();
    }
  };

  render() {
    const { isDeleteModal } = this.props;
    return (
      <ReactModal ariaHideApp={false} onRequestClose={this.props.closeModal} isOpen={isDeleteModal}>
        <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Delete</h5>
            <button type="button" className="btn react-modal-close" onClick={this.props.closeModal}>
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="row">
              <div className="col-md-12">
                <p>Are you sure you want to delete this? This can not be undone.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button onClick={this.deleteUser} className="btn btn-red btn-sm delete-btn">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteUser;
