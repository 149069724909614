import React, { PureComponent } from "react";
import _ from "lodash";
import queryString from "query-string";
import { CloseIcon } from "../../components/icons";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import CustomerDetailComponent from "../CustomerDetail/component";
import { Spin, Select, Pagination } from "antd";
import "./styles.scss";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class CustomerMapping extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSizeChanger = this.handleSizeChanger.bind(this);
    this.state = {
      loading: false,
      customers: [],
      qbCustomers: [],
      spinning: false,
      skip: 0,
      limit: 10,
      currentPage: 1,
      totalItems: 0,
      sortOrder: { companyName: 1 },
      typingTimeout: 0,
      initialCustomer: [],
      isSyncedCustomer:true,
      customerModalOpen:false,
      customerId:""
    };
    this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount = async () => {
    document.title = "QuickBooks | Weighworks";
    const user_data = await this.props.fetchMe()
    const { code, realmId } = queryString.parse(this.props.location.search);
    const redirectUri = process.env.REACT_APP_MAIN_URL;
    const initialCustomer = _.get(await this.props.fetchQBCustomersBySearchText({ searchText: "a" }), "value.data.QueryResponse.Customer", []).map(({ DisplayName, Id, SyncToken }) => ({ label: DisplayName, value: Id, synctoken: SyncToken }));
    this.setState({ spinning: true, initialCustomer,isSyncedCustomer: user_data?.value?.user?.company?.isSyncedCustomer});
    if (code) {
      this.setState({ loading: true });
      await this.props.refreshToken({ code, realmId, redirectUri });
    }
    this.fetchCustomers();
  };

  onChangePage (page) {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchCustomers();
    });
  }

  handleSizeChanger(current, size){
    this.setState({ currentPage: current, skip: (current - 1) * size, limit: size }, () => {
      this.fetchCustomers();
    });
  }

  onSearch = async (searchText, i) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    if(searchText !== ""){
      const customers = [...this.state.customers];
      customers[i].searchText = searchText
      this.setState({
        customers,
        typingTimeout: setTimeout(() => {
          this.fetchQBCustomers(i);
        }, 1000)
      });
    } else {
      const customers = [...this.state.customers];
      const qbCustomers = await this.props.fetchQBCustomersBySearchText({ searchText:"a" })
      const syncCustomer = _.get(qbCustomers, "value.data.QueryResponse.Customer", []).map(({ DisplayName, Id, SyncToken }) => ({ label: DisplayName, value: Id, synctoken: SyncToken }))
      customers[i].customers = []
      this.setState({initialCustomer: syncCustomer, customers})
    }
  };

  fetchQBCustomers = async(i) => {
    const customers = [...this.state.customers];
    const searchText = customers[i].searchText;
    const qbCustomers = await this.props.fetchQBCustomersBySearchText({ searchText })
    customers[i].customers = _.get(qbCustomers, "value.data.QueryResponse.Customer", []).map(({ DisplayName, Id, SyncToken }) => ({ label: DisplayName, value: Id, synctoken: SyncToken }))
    if(customers[i].customers.length < 1){
      this.setState({initialCustomer: []})
    }
    this.setState({ customers })
  }

  async fetchCustomers () {
    const { skip, limit } = this.state;
    this.setState({ spinning: true });
    const { value: { data: { customers, totalCustomersCount } }} = await this.props.fetchQBCustomers({ skip, limit, search: "" });
    this.setState({
      customers: customers,
      totalItems: totalCustomersCount,
      spinning: false,
      loading: false
    });
  }

  setMappingFields = (value, { synctoken }, i) => {
    const customers = [...this.state.customers];
    customers[i].qbCustomerId = value;
    customers[i].qbSyncToken = synctoken;
    this.setState({ customers });
  };

  syncCustomers = async () => {
    const { value: { status }} = await this.props.syncCustomers()
    if (status) {
      this.props.history.push("/dashboard")
    }
  }

  updateQuickBooks = async () => {
    const { customers, qbCustomers } = this.state;
    this.setState({ loading: true });
    await this.props.updateQuickBooks({ customers, qbCustomers });
    this.setState({ loading: false });
  };
  customerSearch = async(e)=>{
    this.setState({ spinning: true });
    const { value: { data: { customers ,totalCustomersCount} }} = await this.props.fetchQBCustomers({ skip:0, limit:50 , search: e.target.value});
    setTimeout(()=>{
      this.setState({
        customers: customers,
        totalItems: totalCustomersCount,
        loading: false,
        currentPage: 1,
        spinning: false 
      });
    },1000)
  }

  openCustomer = async (customerId) => {
    this.setState({customerModalOpen:true,customerId})
  }
  
  closeModel = () => {
    this.setState({customerModalOpen:false})
  }

  assignUrl = ()=>{

  }

  render() {
    const { spinning, loading } = this.state;
    return (
      <div className="quickbooks__quickbooks invoice__mapping">
        <Spin indicator={antIcon} spinning={spinning}>
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Quickbooks Setup</div>
            <div className="head-section-cta">
              <Link to={"/business-admin/integrations"} className="btn btn-link btn-back">
                <CloseIcon />
              </Link>
            </div>
          </div>
          <div className="custome__container">
            <div className="row">
              <div className="col-md-12">
                <div className="card quickBooks__right-left mdwidth">
                  <div className="card-header d-flex justify-content-between">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="card-title">Customer Mapping</h5>
                        <h6 className="subheading">To finish the setup map your quickbook customers</h6>
                      </div>
                    </div>
                    </div>
                    <div className="row m-3">
                    <div className="col-md-12">
                      <input
                        type="text"
                        onChange={(e)=>this.customerSearch(e)}
                        className="form-control search-form"
                        placeholder="Search by customer name..."
                      />
                      </div>
                    </div>
                  <ul className="quickbooks__list minuspad-tb">
                    <li className="quickbooks__list-item">
                      <p>Weighworks Customers</p>
                    </li>
                    <li className="quickbooks__list-item listedit">
                      <p>QuickBooks Customers</p>
                    </li>
                  </ul>
                  <div className="card-body card-body-padnone">
                    {this.state.customers.map((d, i) => {
                      return (
                        <div key={d._id}>
                          <ul className="quickbooks__list quickbooks__select-list">
                            <li className="quickbooks__list-item list-item-left">
                              <p onClick={()=> this.openCustomer(d.customerId)} className="card-title-sm cursor-pointer">
                                {_.get(d, "companyName", "")}
                                {d.displayName && d.companyName && " | "}
                                {_.get(d, "displayName", "")} ({`${_.get(d, "orders", "0") } ${Number(_.get(d, "orders", "0")) <= 1 ? "Order" :"Orders"}`})
                              </p>
                            </li>
                            <li className="quickbooks__list-item list-item-right">
                              <div className="form-group material-textfield">
                                <Select
                                  // className="form-control custom-select w-150 material-textfield-input pr-22"
                                  style={{ width: 200 }}
                                  onSelect={(value, option) => this.setMappingFields(value, option, i)}
                                  onSearch={(searchText) => this.onSearch(searchText, i)}
                                  defaultValue={d.qbCustomerId}
                                  showSearch
                                  filterOption={false}
                                >
                                  {
                                  d.customers.length < 1 ? 
                                  this.state.initialCustomer?.map((c) => (
                                    <Option key={c.value} value={c.value}>
                                      {c.label}
                                    </Option>
                                  )) :
                                  d.customers.map((c) => (
                                    <Option key={c.value} value={c.value}>
                                      {c.label}
                                    </Option>
                                  ))
                                }
                                </Select>
                                <label className="material-textfield-label">Quickbooks Field</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                    <Pagination
                      current={this.state.currentPage}
                      defaultCurrent={1}
                      total={this.state.totalItems}
                      onChange={this.onChangePage}
                      pageSize={this.state.limit}
                      hideOnSinglePage
                      showSizeChanger
                      onShowSizeChange={this.handleSizeChanger}
                    />
                    <button
                      disabled={loading}
                      onClick={this.updateQuickBooks}
                      className="btn btn-dark btn-lg save__btn m-33"
                    >
                      {loading ? "Please wait" : "Save"}
                    </button>
                    { !this.state.isSyncedCustomer &&
                    <button
                      disabled={loading}
                      onClick={this.syncCustomers}
                      className="btn btn-dark btn-lg save__btn m-33"
                    >
                      {loading ? "Please wait" : "Sync Customers"}
                    </button>
                     }
                    <button className="btnskip" onClick={() => this.props.history.push("/disable-quickbooks")}>
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.customerModalOpen ?  <CustomerDetailComponent
            syncPostedOrder={this.props.syncPostedOrder}
            customerId={this.state.customerId}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            changeUrl={this.assignUrl}
            closeModel={this.closeModel}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            getPayabliCustomerData={this.props.getPayabliCustomerData}
            defaultUrl={this.state.defaultUrl}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            withoutUrl
            {...this.props}
            
          />:""}
        </Spin>
      </div>
    );
  }
}
