import { connect } from 'react-redux'
import { fetchMe } from "../store/user/duck";
import { updateCompany } from "../store/company/duck";
import { saveAmount } from "../store/transaction/duck";
import AppComponent from './component'

const AppContainer = connect(
  // Map state to props
  state => ({}),
  // Map actions to dispatch and props
  {
    fetchMe,
    saveAmount,
    updateCompany
  }
)(AppComponent);
export default AppContainer
