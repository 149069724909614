import { connect } from "react-redux";
import QBComponent from "./component";
import { fetchCustomers, fetchQBCustomers, getQuickBooksUser } from "../../store/customer/duck";
import { updateCompany } from "../../store/company/duck";
import { fetchMe,updateUserToken } from "../../store/user/duck";
import { updateQuickBooks, disableQuickbooks } from "../../store/dashboard/duck";

const QBContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user
  }),
  {
    fetchCustomers,
    fetchQBCustomers,
    updateUserToken,
    getQuickBooksUser,
    updateCompany,
    fetchMe,
    updateQuickBooks,
    disableQuickbooks
  }
)(QBComponent);

export default QBContainer;
