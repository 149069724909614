import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { BackArrowIcon } from "../../components/icons";
import "./styles.scss";
import { numberFormat } from "../../constants/common";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class CashLogDetailsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      transaction: null,
      inFlowDetails: null,
      outFlowDetails: null,
      in_flow_details_data: [],
      closingBalance: 0,
      closeBalanceAtEnd : null,
      date: this.props?.match?.params?.date ? moment(this.props?.match?.params?.date).format("MMM DD, YYYY") : moment().format("MMM DD, YYYY"),
      closeBalanceDetails: null,
      openBalanceDetails: null,
      spinning: true
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const {
      match: {
        params: { date }
      }
    } = this.props;
    const {
      location: {
        search
      }
    } = this.props;
    const userId = search.split("=")[1];
    const startDate = moment(date)
      .startOf("day")
      .toISOString();
    const endDate = moment(date)
      .endOf("day")
      .toISOString();
    const {
      value: {
        data: { inFlowDetails, outFlowDetails, closingBalance, closeBalanceDetails, openBalanceDetails, in_flow_details_data , closeBalanceAtEnd}
      }
    } = await this.props.fetchTransaction({ startDate, endDate, type: "cash", timezone: moment().format("Z"),userId });
    this.setState({
      inFlowDetails: _.get(inFlowDetails, "[0]", null),
      outFlowDetails: _.get(outFlowDetails, "[0]", null),
      in_flow_details_data: _.get(in_flow_details_data, "[0]", []),
      closingBalance,
      closeBalanceAtEnd,
      date: moment(date).format("MMM DD, YYYY"),
      closeBalanceDetails,
      openBalanceDetails,
      spinning: false
    });
  };

  orderDeposit(transaction) {
    if(transaction?.receivePayments?.invoices?.length > 0){
      return(
        <li key={transaction._id}>
          <div>
            <h5 className="transactions__title">{_.get(transaction, "order.customer.displayName", "N/A")}</h5>
            <p className="transactions__sub-title">
             {transaction?.receivePayments?.invoices?.map(item => item.invoiceId)?.join(', ')} | Processed by{" "}
             {_.startCase(`${transaction.user.firstName} ${transaction.user.lastName}`)}
            </p>
          </div>
          <div className="invoice__right">
            <div className="text-amount text-black">+${numberFormat(transaction?.receivePayments?.invoices?.reduce((acc, item) => acc + (item.payment || 0), 0))}</div>
          </div>
        </li>
        )
      }
    return (
      <li key={transaction._id}>
        <div>
          <h5 className="transactions__title">{_.get(transaction, "order.customer.displayName", "N/A")}</h5>
          <p className="transactions__sub-title">
            {transaction.order.ticketNumber} | Processed by{" "}
            {_.startCase(`${transaction.user.firstName} ${transaction.user.lastName}`)}
          </p>
        </div>
        <div className="invoice__right">
          <div className="text-amount text-black">+${numberFormat(transaction.amount)}</div>
        </div>
      </li>
    );
  }

  adminDeposit(transaction) {
    return (
      <li key={transaction._id}>
        <div>
          <h5 className="transactions__title">
            {_.startCase(`${transaction.user.firstName} ${transaction.user.lastName}`)}
          </h5>
          <p className="transactions__sub-title">{_.startCase(`${transaction.user.role}`)} Deposit </p>
        </div>
        <div className="invoice__right">
          <div className="text-amount text-black">+${numberFormat(transaction.amount)}</div>
        </div>
      </li>
    );
  }

  render() {
    const { inFlowDetails, outFlowDetails, closingBalance, date, closeBalanceDetails, openBalanceDetails, in_flow_details_data , closeBalanceAtEnd} = this.state;
    return (
      <div className="cashlog__details-wrapper invoce__table">
        <div className="custome__container">
          <div className="btn-back-header">
            <button className="btn btn-link btn-back">
              <Link to={"/billing/cash-transactions"}>
                <BackArrowIcon />
                Back
              </Link>
              <span>{date} Cash Orders </span>
            </button>
          </div>
          <Spin indicator={antIcon} spinning={this.state.spinning}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="cashlog__details-invoice">
                    <div className="invoice__heading">Opening Balance</div>
                    <ul className="transactions__list">
                      <li>
                        <div>
                          <h5 className="transactions__title">
                            {moment(
                              _.get(closeBalanceDetails, "createdAt", _.get(openBalanceDetails, "createdAt", moment()))
                            ).format("MMM DD, YYYY")}{" "}
                            Closing Balance
                          </h5>
                          <p className="transactions__sub-title">
                            {_.startCase(
                              _.get(closeBalanceDetails, "user.role", _.get(openBalanceDetails, "user.role", ""))
                            )}{" "}
                            |{" "}
                            {_.startCase(
                              `${_.get(
                                closeBalanceDetails,
                                "user.firstName",
                                _.get(openBalanceDetails, "user.firstName", "")
                              )} ${_.get(
                                closeBalanceDetails,
                                "user.lastName",
                                _.get(openBalanceDetails, "user.lastName", "")
                              )}`
                            )}{" "}
                            at{" "}
                            {moment(
                              _.get(closeBalanceDetails, "createdAt", _.get(openBalanceDetails, "createdAt", moment()))
                            ).format("hh:mm a")}
                          </p>
                        </div>
                        <div className="invoice__right">
                          <div className="text-center">
                            <h5 className="invoice__title">(System)</h5>
                            <p className="invoice__sub-title">
                              ${numberFormat(_.get(closeBalanceDetails, "amount", 0))}{" "}
                            </p>
                          </div>
                          <div className="text-center">
                            <h5 className="invoice__title">
                              (
                              {_.startCase(
                                `${_.get(openBalanceDetails, "user.firstName", "")} ${_.get(
                                  openBalanceDetails,
                                  "user.lastName",
                                  ""
                                )}`
                              )}
                              )
                            </h5>
                            <p className="invoice__sub-title">
                              ${numberFormat(_.get(openBalanceDetails, "amount", 0))}
                            </p>
                          </div>
                          <div className="text-amount text-black">
                            +${numberFormat(_.get(openBalanceDetails, "amount", 0))}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="invoice__heading">Cash Debit (inflow)</div>
                    <ul className="transactions__list">
                      {_.get(in_flow_details_data, "data", []).map(transaction => {
                        return (
                          <li key={transaction?._id}>
                            <div>
                              <h5 className="transactions__title">
                                {_.startCase(`${transaction?.user?.firstName} ${transaction?.user?.lastName}`)}
                              </h5>
                              <p className="transactions__sub-title">
                                {_.startCase(`${transaction?.user?.role}`)} Deposit
                                {transaction?.note?.length > 0 &&  ` | ${transaction?.note?.charAt(0).toUpperCase() + transaction?.note?.slice(1)}`}
                              </p>
                            </div>
                            <div className="invoice__right">
                              <div className="text-amount text-black">+${numberFormat(transaction?.amount)}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <ul className="transactions__list">
                      {_.get(inFlowDetails, "data", []).map(transaction => {
                        if (Object.keys(transaction?.order)?.length > 0) {
                          return this.orderDeposit(transaction);
                        } else {
                          return this.adminDeposit(transaction);
                        }
                      })}
                    </ul>
                    <div className="total__credit">
                      Total Credit{" "}
                      <div className="text-amount text-green">
                        +${numberFormat(_.get(inFlowDetails, "totalAmount", 0) + _.get(in_flow_details_data, "totalAmount", 0))}
                      </div>
                    </div>
                    <div className="invoice__heading">Cash Credit (outflow)</div>
                    <ul className="transactions__list">
                      {_.get(outFlowDetails, "data", []).map(transaction => {
                        return (
                          <li key={transaction._id}>
                            <div>
                              <h5 className="transactions__title">
                                {_.startCase(`${transaction.user.firstName} ${transaction.user.lastName}`)}
                              </h5>
                              <p className="transactions__sub-title">
                                {_.startCase(`${transaction.user.role}`)} Withdrawal | {_.startCase(transaction?.withDrawlReason ?? "Bank Deposit")} 
                                {transaction?.note?.length > 0 && ` | ${transaction?.note?.charAt(0).toUpperCase() + transaction?.note?.slice(1)}`}
                              </p>
                            </div>
                            <div className="invoice__right">
                              <div className="text-amount text-black">-${numberFormat(transaction.amount)}</div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="total__credit">
                      Total Credit
                      <div className="text-amount text-red">
                        -${numberFormat(_.get(outFlowDetails, "totalAmount", 0))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-between">
              <h5 className="transactions__title">Closing Balance</h5>
              <div className="invoice__right">
                <div className="text-center">
                  <h5 className="invoice__title">(System) </h5>
                  <p className="invoice__sub-title">${numberFormat(closingBalance)} </p>
                </div>
                <div className="text-center">
                  <h5 className="invoice__title">
                    (
                    {_.startCase(
                      `${_.get(openBalanceDetails, "user.firstName", "")} ${_.get(
                        openBalanceDetails,
                        "user.lastName",
                        ""
                      )}`
                    )}
                    )
                  </h5>
                  <p className="invoice__sub-title">  ${numberFormat(_.get(closeBalanceAtEnd, "amount", closingBalance))}</p>
                </div>
                <div className="text-amount text-black">${numberFormat(closingBalance)}</div>
              </div>
            </div>
          </div>
          </Spin>
        </div>
      </div>
    );
  }
}
