import React from "react";
import ReactModal from 'react-modal';
import { CloseIcon } from "../../../components/icons";
import { message } from "antd";

class SignaturePad extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isWaiting: false
    }
  }

  updateSignaturePadSetting = async () => {
    this.setState({ isWaiting: true });
    const {
      value: { status }
    } = await this.props.updateSignaturePadSetting({
      companyId: this.props.companyId,
      isSignaturePadIntegration: !this.props.isSignaturePadIntegration
    });

    if (status) {
      if(this.props.isSignaturePadIntegration) {
        message.success("Signature pad disabled successfully!");
      } else {
        message.success("Signature pad integrated successfully!");
      }
      this.setState({ isWaiting: false });
      this.props.fetchSetting(this.props.companyId);
      this.props.closeModal();
    }
  };

  render() {
    const { closeModal, isOpen, isSignaturePadIntegration } = this.props;
    const { isWaiting } = this.state;
    return <>
        <ReactModal  onRequestClose={closeModal} isOpen={isOpen}>
          <div className="react-modal-dialog react-modal-dialog-centered payabli__integration-modal m-auto">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Signature Pad - Topaz (T-LBK460-HSX-R)</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={closeModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              { isSignaturePadIntegration ? <p>Are you sure? Do you want to disable this?</p> : 
               <p>Signature pad must be connected to the computer before integration is enabled. Is there signature pad already connected?</p>
              }
            </div>
            <div className="react-modal-footer">
              <button onClick={closeModal} className="btn btn-dark btn-sm delete-btn mx-2">
                Cancel
              </button>
              <button
                onClick={this.updateSignaturePadSetting}
                className="btn btn-yellow"
                disabled={isWaiting}
              >
                {isWaiting ? "Please wait.." : "Yes"}
              </button>
            </div>
          </div>
        </ReactModal></>
      }
}

export default SignaturePad;