import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import './styles.scss'

export default class NoRecordsComponent extends PureComponent {

  static propTypes = {
    // PropTypes go here
  }

  render() {
    return (
      <>
      {/* <tr>
        <td colSpan={10}>
          <div className="empty__section">          
            <div className="empty__section-inner">
              <p className="empty__text">{this.props.title}</p>
            </div>           
          </div>
        </td>
      </tr> */}
      
          <div className="empty__section">          
            <div className="empty__section-inner">
              <p className="empty__text">{this.props.title}</p>
            </div>           
          </div>
       
      </>
    )
  }
}
