import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken,uploadImage } from '../../store/user/duck'
import { fetchCustomer, updateDriver, updateTruck, deletePaymentMethod } from '../../store/customer/duck'
import {getInvoiceDeliveryByIdData} from "../../store/invoiceDelivery/duck"
import {sendInvoiceEmail,updateInvoiceOrder,updateInvoicDetail,statementEmail,generateStatementPdf,getCompanyById,getInvoiceDetalByInvoiceId,getRecivingPaymentInvoices,getReceivePaymentForCustomers,syncPostedOrder,customerInvoicesByCustomerId, getQbDetails,customerOpenBalanceInvoicesByCustomerId, getReceivePaymentForPdf , getCustomercreditsForCustomers } from '../../store/Billing/duck'
import { getPayabliCustomerData } from '../../store/paybli_integrations/duck';
import { getOrderInvoicePdf, fetchOrder } from "../../store/order/duck";
import CreateStatementComponent from './component'

const CreateStatementContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
    getQbDetails,
    fetchOrder,
    syncPostedOrder,
    getMe,
    fetchMe,
    updateUserToken,
    fetchCustomer,
    updateDriver,
    updateTruck,
    deletePaymentMethod,
    getPayabliCustomerData,
    getInvoiceDeliveryByIdData,
    getRecivingPaymentInvoices,
    sendInvoiceEmail,
    uploadImage,
    updateInvoiceOrder,
    updateInvoicDetail,
    getCustomercreditsForCustomers,
    getInvoiceDetalByInvoiceId,
    getReceivePaymentForCustomers,
    customerInvoicesByCustomerId,
    getOrderInvoicePdf,
    customerOpenBalanceInvoicesByCustomerId,
    getReceivePaymentForPdf,
    getCompanyById,
    generateStatementPdf,
    statementEmail
  }
)(CreateStatementComponent)

export default CreateStatementContainer
