import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { DownarrowIcon, StateUpArrowIcon } from "../../../components/icons";
import _ from "lodash";
import moment from "moment-timezone";
import NotFound from "../../NoRecords/component";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class ProjectOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders:[],
      totalItems:0,
      sortOrder: { ticketNumber: -1 },
      projectId:"",
      spinning:false,
    };
  }

  
componentDidMount=async()=>{
this.fetchOrders()
}

  fetchOrders = async () => {
    const { fetchProjectOrderList,projectdetails } = this.props;
    this.setState({spinning:true})
    const {
      sortOrder,
    } = this.state;
    const {
      value: {
        data: { orders, ordersCount }
      }
    } = await fetchProjectOrderList({
      sortOrder,projectId:projectdetails?._id
    });
    this.setState({ orders, totalItems: ordersCount,spinning:false });
  
  };

  async setOrder(field) {
    const { sortOrder } = this.state;
    const sort = { [field]: sortOrder[field] === 1 ? -1 : 1 };
    this.setState({ sortOrder: sort });
    this.fetchOrders();
  }

  render() {
    const { orders, sortOrder} = this.state;
    const {customer} =this.props
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    return (
      <>
        
          <Spin indicator={antIcon} spinning={this.state.spinning}>
          {orders.length === 0 ? (
          <NotFound title="No Orders Found" />
        ) : (
          <div className="table-responsive">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th onClick={() =>this.setOrder("ticketNumber")}>
                    Ticket # {sortOrder["ticketNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("createdAt")}>
                    {`Date & Time`} {sortOrder["createdAt"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("customer.companyName")}>
                    Company Name {sortOrder["customer.companyName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("netWeight")}>
                    Net Weight(lbs) {sortOrder["netWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("tonnage")}>
                    Tonnage {sortOrder["tonnage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("yardage")}>
                    Yardage {sortOrder["yardage"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() =>this.setOrder("totalAmount")}>
                    Total Order Amount {sortOrder["totalAmount"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                  <th onClick={() => this.setOrder("paymentType")}>
                    Payment Type {sortOrder["paymentType"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => {
                  let paymentType 
                  if(order?.completed){
                    paymentType = order.paymentType && order.paymentType !== "" ? `- ${_.startCase(order.paymentType)}` :  "" ;
                  }else{
                    paymentType = customer.paymentTerms === "cod" ? paymentType === "" ?`- ${_.startCase("cash")}` :`- ${_.startCase(customer.paymentType)}`  : ""
                  }
                  let btnClass = "btn btn-for-account";
                  if (paymentType === "") {
                    paymentType = "ACCOUNT";
                  } else {
                    paymentType = `COD ${paymentType}`;
                  }
                  if (paymentType === "COD - Credit Card") {
                    btnClass = "btn btn-for-cc";
                  } else if (paymentType === "COD - Cash" || paymentType === "COD - Check") {
                    btnClass = "btn btn-for-cash";
                  } else if (paymentType === "COD - Terminal") {
                    btnClass = "btn btn-for-terminal";
                  }
                  return (
                    <tr className="cursor-pointer" key={order._id}>
                      <td>{order.ticketNumber}</td>
                      <td>{`${moment(order.createdAt)?.tz(tz)?.format("MM/DD/YY")} ${moment(order.createdAt)?.tz(tz)?.format(
                        "hh:mm a"
                      )}`}</td>
                      <td className="number-of-project-cell">{order.customer.companyName}</td>
                      <td>
                        { order.netWeight > 0 ? <>
                       <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} />  lbs </> : "-" }
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.tonnage} />
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.yardage} />
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.totalAmount}
                        />
                      </td>
                      <td>
                        <button className={btnClass}>{paymentType}</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
           )}
          </Spin>
       
      </>
    );
  }
}
