import React, { PureComponent } from "react";
import _ from "lodash";
import { CloseIcon } from "../../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { editCompanySchema } from "../../../utils/validations";

export default class CompanyListingComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initalValues: {
        domain: _.get(props, "company.domain", "")
      }
    };
  }

  handleSubmit = async ({ domain }, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const { company } = this.props;
    const {
      value: { status, message }
    } = await this.props.updateCompany({ companyId: company._id, domain });
    if (status) {
      this.props.closeModal();
      this.props.fetchCompanies();
    } else {
      setErrors({ domain: message });
    }
  };

  render() {
    const { initalValues } = this.state;
    return (
      <div className="react-modal-dialog react-modal-dialog-centered">
        <div className="react-modal-header">
          <h5 className="react-modal-title">Edit Domain</h5>
          <button type="button" className="btn react-modal-close" onClick={this.props.closeModal}>
            <CloseIcon />
          </button>
        </div>
        <Formik
          initialValues={initalValues}
          validationSchema={editCompanySchema}
          onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className="react-modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group material-textfield">
                      <input
                        type="text"
                        name="domain"
                        value={values.domain}
                        onChange={handleChange}
                        className="form-control material-textfield-input"
                        required
                      />
                      <span>Example: demo.weighworks.com</span>
                      <ErrorMessage component="span" name="domain" className="invalid-feedback d-block" />
                      <label className="material-textfield-label">
                        Domain<span>*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-dark btn-lg w-100" disabled={isSubmitting}>
                  Update
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}
