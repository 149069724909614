import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const getDashboardData = (data) => {
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/dashboard/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const fetchAllItems = (searchText, searchStartsWith) => {
  return fetch(`${HOSTNAME}/items?searchText=${escape(searchText)}&searchStartsWith=${searchStartsWith ? searchStartsWith: false}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const refreshToken = ({ code, realmId, redirectUri }) => {
  return fetch(
    `${HOSTNAME}/quickbooks-token?code=${code}&realmId=${realmId}&redirectUri=${redirectUri}`,
    {
      method: "GET",
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQuickBooks = (data) => {
  return fetch(`${HOSTNAME}/put-qb-customers`, {
    method: "PUT",
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyQBIntegration = (data) => {
  return fetch(`${HOSTNAME}/verify-integration`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const disableQuickbooks = (data) => {
  return fetch(`${HOSTNAME}/disable-quickbooks`, {
    method: "DELETE",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPayments = (data) => {
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/dashboard_payments/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopCustomer = (data) => {
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/get_top_customers/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopOutSTandingCustomer = (data) => {
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/get_top_due_customers/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTodayDueInvoices = (data) => {
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/due_Invoices/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      console.log("Today", payload);
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopMaterialsByTons = (data) => {
  console.log(data);
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/top_materials_by_tons/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopDumpRateSelections = (data) => {
  console.log(data);
  data = JSON.stringify(data);
  return fetch(`${HOSTNAME}/top_dumpRates_salections/${data}`, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
