import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { DownCaretIcon } from "../../components/icons";
import ToggleMenu from "./../../images/togglemenu.png";
import SearchIcon from "./../../images/search_icon.svg";
import { CustomersIcon } from "../../components/icons";
import ReactModal from "react-modal";
import { Formik, ErrorMessage } from "formik";
import { depositSchema } from "../../utils/validations";
import { CloseIcon, BackArrowIcon } from "../../components/icons";
import _ from "lodash";
import moment from "moment";
import { numberFormat } from "../../constants/common";
import CloseBalance from "./partials/close-balance";
import NumberFormat from "react-number-format";
import Notifications from "./Notifications";
import SearchCheckboxComponent from "../Common/SearchCheckbox";
import "./styles.scss";

export default class TopNavigationComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);

    this.state = {
      searchStartsWith: true,
      user: null,
      searchText: "",
      showSearchText: "",
      customers: [],
      orders: [],
      typingTimeout: 0,
      openBalanceModal: false,
      closeBalanceModal: false,
      depositInitialValues: {
        amount: ""
      },
      openingBalanceText: {
        oBScreen: true,
        header: `Approve Opening Balance`,
        para: `Please make sure the number below represents the number that is in the cash register when starting your shift.`,
        btnText: `Yes, this is Correct! Start my shift`
      },
      closingBalanceText: {
        oBScreen: true,
        header: `Approve Closing Balance`,
        para: `Please make sure the number below represents the number that is in the cash register when ending your shift.`,
        btnText: `Yes, this is Correct! End my shift`
      },
      iAuthorization: localStorage.getItem("i_Authorization")
    };
    this.logout = this.logout.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  async componentDidMount() {
    const path = this.props.history?.location?.pathname;
    const pathnames = [
      '/billing',
      '/needReview',
      '/finalized',
      '/posted'
    ]
    if (!pathnames.some(pathname => path.startsWith(pathname))) {
      this.props.setSearchText("")
    }
    this.props.getMe();
    const { user } = this.state;
    if (!user?._id) {
      const {
        value: { user },
      } = await this.props.fetchMe();
      this.setState({ user });
    }
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      const depositInitialValues = {
        amount: _.get(nextProps, "user.lastCloseBalance", "")
      };
      return {
        user: nextProps.user,
        depositInitialValues
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.closeBalanceModal !== prevProps.closeBalanceModal) {
      this.setState({ closeBalanceModal: this.props.closeBalanceModal });
    }
  }

  logout() {
    this.props.logout();
    localStorage.clear();
    this.props.history.push("/login");
  }

  menu = () => (
    <Menu>
      <Menu.Item key="0">
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <Link to={"/integrations"}>Integration</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="3">
        <Link to={"/business-settings"}>Settings</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="4">
        <Link to={"/stripe-settings"}>Stripe Info</Link>
      </Menu.Item> */}
      <Menu.Item key="5" onClick={this.logout}>
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  showOpenAmountModal = e => {
    e.preventDefault();
    const openingBalanceText = {
      oBScreen: false,
      header: `Enter Opening Balance`,
      para: `Since the number does not match what is in the system please manually enter in the amount that is in the cast register`,
      btnText: `Submit`
    };
    this.setState({ openingBalanceText });
  };

  showCloseAmountModal = e => {
    e.preventDefault();
    const closingBalanceText = {
      oBScreen: false,
      header: `Enter Closing Balance`,
      para: `Since the number does not match what is in the system please manually enter in the amount that is in the cast register`,
      btnText: `Submit`
    };
    this.setState({ closingBalanceText });
  };

  showOpenApproveModal = () => {
    const {
      openingBalanceText: { oBScreen }
    } = this.state;
    if (!oBScreen) {
      const openingBalanceText = {
        oBScreen: true,
        header: `Approve Opening Balance`,
        para: `Please make sure the number below represents the number that is in the cash register when starting your shift.`,
        btnText: `Yes, this is Correct! Start my shift`
      };
      this.setState({ openingBalanceText });
    } else {
      this.setState({ openBalanceModal: false });
    }
  };

  showCloseApproveModal = () => {
    const {
      closingBalanceText: { oBScreen }
    } = this.state;
    if (!oBScreen) {
      const closingBalanceText = {
        oBScreen: true,
        header: `Approve Closing Balance`,
        para: `Please make sure the number below represents the number that is in the cash register when ending your shift.`,
        btnText: `Yes, this is Correct! End my shift`
      };
      this.setState({ closingBalanceText });
    } else {
      if (this.props.closeBalanceModalFunc) this.props.closeBalanceModalFunc();
      this.setState({ closeBalanceModal: false });
    }
  };

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value.trimStart(),
      typingTimeout: event.target.value.trim().length > 0 ? setTimeout(() => {
        this.fetchItems();
      }, 500) : this.handleClickOutside(event)
    });
  };

  fetchItems = async () => {
    const {
      value: {
        data: { customers, orders }
      }
    } = await this.props.fetchAllItems(this.state.searchText, this.state.searchStartsWith);
    this.setState({ customers, orders });
  };

  removeImpersonate = () => {
    const token = localStorage.getItem("i_Authorization");
    localStorage.setItem("Authorization", token);
    localStorage.removeItem("i_Authorization");
    localStorage.removeItem("reportFilter");
    localStorage.removeItem("orderFilter");
    const user = localStorage.getItem("user");
    if (user === "superadmin") {
      this.props.history.push("/companylisting");
    } else {
      this.props.history.push("/user-management");
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ customers: [], orders: [] });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  async handleSubmit({ amount }, type, { setSubmitting }) {
    const { saveAmount } = this.props;
    amount = parseFloat(String(amount).replace(/,/g, ""));
    const {
      value: { status }
    } = await saveAmount({ amount, type });
    if (status) {
      setSubmitting(false);
      this.componentDidMount();
      this.setState({ openBalanceModal: false, closeBalanceModal: false });
    }
  }

  render() {
    const { user, openingBalanceText, iAuthorization, showSearchText } = this.state;
    const { searchText, customers, orders } = this.state;
    const length = [...customers, ...orders].length;
    return (
      <>
        {iAuthorization && (
          <div className="impersonate-header bg-yellow">
            <span>
              You are impersonating {`${_.get(user, "firstName", "")} ${_.get(user, "lastName", "")}`}
              <span className="underline" onClick={this.removeImpersonate}>
                {" "}
                (Click here to go back to your account)
              </span>
            </span>
          </div>
        )}
        {(_.get(user, "role", false) === "supervisor" || _.get(user, "role", false) === "admin") &&
          !_.get(user, "isSetOpenBalance", true) &&
          moment().isBetween(
            moment().startOf("day"),
            moment()
              .startOf("day")
              .add(990, "minutes")
          ) && (
            <div className="impersonate-header">
              <span>
                You need to enter your opening balance.
                <span className="underline" onClick={() => this.setState({ openBalanceModal: true })}>
                  {" "}
                  Click here to enter your opening balance
                </span>
              </span>
            </div>
          )}
        {(_.get(user, "role", false) === "supervisor" || _.get(user, "role", false) === "admin") &&
          moment().isBetween(
            moment()
              .startOf("day")
              .add(991, "minutes"),
            moment()
              .startOf("day")
              .add(1439, "minutes")
          ) &&
          !_.get(user, "isSetClosingBalance", true) && (
            <div className="impersonate-header">
              <span>
                You need to enter your closing balance.{" "}
                <span className="underline" onClick={() => this.setState({ closeBalanceModal: true })}>
                  Click here to enter your closing balance
                </span>
              </span>
            </div>
          )}

        <header className="header topnavigation-layout-header d-flex align-items-baseline">
          <div className="mobile__toggle-button">
            <img alt="" onClick={this.props.onClickToggleMenu} src={ToggleMenu} />
          </div>
          <div className="search_icon">
            <img alt="" src={SearchIcon} onClick={() => this.setState({ showSearchText: true })} />
          </div>
          <div className={showSearchText ? `search-small search-small-open` : `search-small`}>
            <input
              type="search"
              className="search-input"
              placeholder="Search by ticket # or customer name..."
              value={searchText}
              onChange={this.handleSearch}
              // ref={input => input && input.focus()}
              // onBlur={() => this.setState({ showSearchText: false, customers: [], orders: [] })}
            />
            <span onClick={() => this.setState({ showSearchText: false, customers: [], orders: [] })}>
              <CloseIcon />
            </span>
          </div>
          <div className={`searchingbox ${length === 0 && "searchingbox-hide"}`}>
            {customers.length !== 0 && (
              <div className="customers">
                <h4>Customers</h4>
                <ul className="SearchInputSelector">
                  {customers.map(({ _id, email, customerId, displayName, companyName }) => {
                    return (
                      <li
                        key={_id}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/customer-detail/${customerId}/general`
                          })
                        }
                      >
                        <div className="profile-img">
                          <CustomersIcon />
                        </div>
                        <div className="profile-details">  
                          <h2>{companyName ? `${displayName} | ${companyName}` : displayName}</h2>
                          <h4>{email}</h4>
                          {/* <h3>
                            <NumberFormat format="###-###-####" displayType={"text"} value={phone} thousandSeparator />
                          </h3> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {orders.length !== 0 && (
              <div className="orders">
                <h4>Orders</h4>
                <ul className="SearchInputSelector">
                  {orders.map(({ _id, ticketNumber, customer: { displayName , companyName} }) => {
                    return (
                      <li
                        key={_id}
                        onClick={() => this.props.history.push({ pathname: `/order-details/${ticketNumber}` })}
                      >
                        <div className="profile-img">
                          <CustomersIcon />
                        </div>
                        <div className="profile-details">
                          <h2>{ticketNumber}</h2>  
                          <h3>{companyName ? `${displayName} | ${companyName}` : displayName}</h3>
                          {/* <h3>666-656-5464</h3> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>

          <div className="topnavigation-layout-search">
            <div className="topnavigation-layout-wrapper">
              <input
                className="form-control"
                placeholder="Search by ticket # or customer name..."
                value={searchText}
                onChange={this.handleSearch}
                onFocus={() => {
                  document.addEventListener("mousedown", this.handleClickOutside);
                }}
                onBlur={() => {
                  document.removeEventListener("mousedown", this.handleClickOutside);
                }}
              />
              <div className="clearfix"></div>
              <div ref={this.setWrapperRef} className={`searchingbox ${length === 0 && "searchingbox-hide"}`}>
                {customers.length !== 0 && (
                  <div className="customers">
                    <h4>Customers</h4>
                    <ul className="SearchInputSelector">
                      {customers.map(({ _id, email, customerId, displayName , companyName }) => {
                        return (
                          <li
                            key={_id}
                            onClick={() => {
                              this.props.history.push({
                                pathname: `/customer-detail/${customerId}/general`
                              })
                              this.props.isCustomerModelOpen && this.props.isCustomerModelOpen()
                            }
                            }
                          >
                            <div className="profile-img">
                              <CustomersIcon />
                            </div>
                            <div className="profile-details">
                            <h2>{companyName ? `${displayName} | ${companyName}` : displayName}</h2>
                              <h3>{email}</h3>
                              {/* <h3>
                            <NumberFormat format="###-###-####" displayType={"text"} value={phone} thousandSeparator />
                          </h3> */}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {orders.length !== 0 && (
                  <div className="orders">
                    <h4>Orders</h4>
                    <ul className="SearchInputSelector">
                      {orders.map(({ _id, ticketNumber, customer: { displayName , companyName} }) => {
                        return (
                          <li
                            key={_id}
                            onClick={() => this.props.history.push({ pathname: `/order-details/${ticketNumber}` })}
                          >
                            <div className="profile-img">
                              <CustomersIcon />
                            </div>
                            <div className="profile-details">
                              <h2>{ticketNumber}</h2>                          
                              <h3>{companyName ? `${displayName} | ${companyName}` : displayName}</h3>
                              {/* <h3>666-656-5464</h3> */}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <SearchCheckboxComponent
              searchStartsWith={this.state.searchStartsWith}
              handleSearchStartsWith={(value) => this.setState({ searchStartsWith: value }, () => {
                if(searchText && searchText!=="") {
                  this.fetchItems()
                }
                document.addEventListener("mousedown", this.handleClickOutside);
              })}
            />
          </div>
	        
          <div className="topnavigation-layout__profile ml-auto d-flex align-items-center">
          { user && user?._id && <Notifications {...this.props} /> }
            <Dropdown overlay={this.menu} trigger={["click"]} overlayClassName="profile--dropdown--overlay">
              <a className="ant-dropdown-link d-flex align-items-center" href="/">
                <div className="avatar">
                  <p className="name_text">
                    {user && user.firstName.substr(0, 1)}
                    {user && user.lastName.substr(0, 1)}
                  </p>
                </div>
                <div className="avatar-info">
                  <div className="avatar-name">
                    {user ? user.firstName : ""} {user ? user.lastName : ""}
                  </div>
                  <div className="avatar-email">{user ? user.email : ""}</div>
                </div>
                <DownCaretIcon />
              </a>
            </Dropdown>
          </div>
        </header>
        <ReactModal
          isOpen={this.state.openBalanceModal}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered common__confirmtion-popu">
            <div className="react-modal-header">
              {!openingBalanceText.oBScreen && (
                <div className="btn-back-header">
                  <button className="btn btn-link btn-back">
                    <span onClick={this.showOpenApproveModal}>
                      <BackArrowIcon />
                      Back
                    </span>
                  </button>
                </div>
              )}
              <button type="button" className="btn react-modal-close" onClick={this.showOpenApproveModal}>
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <h5 className="react-modal-title">{openingBalanceText.header}</h5>
              <p>{openingBalanceText.para}</p>
              <Formik
                enableReinitialize
                initialValues={this.state.depositInitialValues}
                validationSchema={depositSchema}
                onSubmit={(values, formikProps) => {
                  this.handleSubmit(values, "openBalance", formikProps);
                }}
              >
                {({ values, handleChange, handleSubmit, setValues, isSubmitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    {!openingBalanceText.oBScreen ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group material-textfield">
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType={"input"}
                              type="text"
                              className="form-control material-textfield-input"
                              onValueChange={_values => {
                                const { formattedValue } = _values;
                                const amount = formattedValue;
                                setValues({
                                  ...values,
                                  amount
                                });
                              }}
                              value={values.amount}
                              required
                            />
                            <ErrorMessage component="span" name="amount" className="invalid-feedback d-block" />
                            <label className="material-textfield-label">
                              Enter Amount<span>*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <h3 className="amout__title">${numberFormat(_.get(user, "lastCloseBalance", 0))}</h3>
                    )}
                    <button type="submit" disabled={isSubmitting} className="btn btn-dark btn-lg w-100">
                      {openingBalanceText.btnText}
                    </button>
                    {openingBalanceText.oBScreen && (
                      <button
                        onClick={this.showOpenAmountModal}
                        disabled={isSubmitting}
                        className="btn btn-dark btn-lg w-100 bg-red"
                      >
                        No, this is NOT Correct
                      </button>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </ReactModal>
        {this.state.closeBalanceModal && (
          <CloseBalance
            showCloseApproveModal={this.showCloseApproveModal}
            closeBalanceModal={this.state.closeBalanceModal}
            closingBalanceText={this.state.closingBalanceText}
            showCloseAmountModal={this.showCloseAmountModal}
            handleSubmit={(values, closeBalance, formikProps) => this.handleSubmit(values, closeBalance, formikProps)}
            fetchClosingBalance={this.props.fetchClosingBalance}
          />
        )}
      </>
    );
  }
}
