import React, { PureComponent } from "react";
import "./styles.scss";
import ReactModal from "react-modal";
import { CloseIcon } from "../../components/icons";
import moment from "moment-timezone";
import { message } from "antd";
import deleteIcon from "../../images/delete-icon-red.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Logo from "../../images/logo.png";
import { format_address } from "../../constants/common";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class CreateStatementComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleMenu: false,
      // activeUrl: "",
      modelData: {},
      customerCredit : 0,
      creditlimit: 11,
      creditskip: 0,
      reRender: false,
      isPrintRecipt: false,
      invoiceEmailPleaseWait: false,
      isModelOpen: false,
      pleaseWait: false,
      reArrangOrders: [],
      isTicketInvoiceLoader: false,
      PO: "",
      recipients: [
        {
          email:"",
          name: "",
        },
      ],
      isWaitSendEmail: false,
      isWaitDownload: false,
      isWaitPrint: false,
      isLoading:true
    };
  }

  componentDidMount = async () => {
    const {customer} = this.props
    if(customer){
      const payload = {
        customerId: customer?._id,
        limit: this.state.creditlimit,
        skip: this.state.creditskip
    }
      const data = await this.props.customerOpenBalanceInvoicesByCustomerId({customerId: customer?._id})
      const {
        value: {
            data: {totalCreditAmount }
        }
    } = await this.props.getCustomercreditsForCustomers(payload);
      const secondaryEmails = customer?.secondaryBillingContacts ? customer?.secondaryBillingContacts?.map((v)=>{return {email: v.billingEmailAddress, name: v.billingContactName}}):[]
      this.setState({modelData: data.value.data ,recipients: [{email: data.value.data?.customerInvoice[0]?.customer?.billingEmailAddress, name: data.value.data?.customerInvoice[0]?.customer?.billingContactName},...secondaryEmails],isLoading:false , customerCredit : Number(totalCreditAmount[0]?.totalCreditAmount)})
    }
    // this.setState({
    //   modelData: this.props.modelData,
    //   PO: this.props.modelData?.PO,
    // });
    // this.setState({
    //   invoiceEmailPleaseWait: this.props.PrintDropDown,
    //   isModelOpen: this.props.isModelOpen,
    // });
    // this.setState({ isPrintRecipt: this.props.printByDropDown });
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };

  numberWithCommas = (x) => {
    x = parseFloat(x);
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  sendInvoice = async () => {
    const { modelData } = this.state;
    const { company, customer } = this.state.modelData;
    this.setState({ invoiceEmailPleaseWait: true });
    const invoice = this.state.modelData;
    const billingEmail = customer?.secondaryBillingContacts ? 
      customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
      :[]
    const payload = {
      company: {
        logo: company[0].logo,
        businessInfo: company[0]?.businessInfo,
        email: company[0]?.email,
        domain: company[0]?.domain,
        companyId: company[0]?._id,
        companyName: company[0]?.displayName,
        customerName: customer?.customerName,
        customerId: customer?._id,
      },
      customer: {
        email: customer.email,
        billingEmailAddress: [customer?.billingEmailAddress,...billingEmail],
        companyName: customer?.displayName,
        customerId: customer?._id,
      },
      invoice: {
        invoiceData: invoice,
        invoiceId: invoice?.invoiceId,
        dueDate: invoice?.dueDate,
        total: invoice?.total,
        balacnce: Number(invoice?.total) - Number(invoice.amountPaid),
        amountPaid: invoice?.amountPaid,
      },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail(payload);
    if (status) {
      modelData.deliveryStatus = true;
      this.setState(
        {
          invoiceEmailPleaseWait: false,
          modelData: modelData,
          reRender: !this.state.reRender,
        },
        () => {
          message.success(showMessage);
        }
      );
    }
  };

  closePrintRecipt = () => {
    if (this.props.printByDropDown) {
      this.setState({ isPrintRecipt: false });
      this.props.history.goBack();
    } else {
      this.setState({ isPrintRecipt: false });
    }
  };

  statementTemplate = (data, totalBalance, companyData,customerCredit , print) => {
    const Company = companyData;
    let { customer } = data[0];
    const template = `<html lang="en">
  <head>
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<title>Print Invoice</title>
<style>

    @media print {
      html {
        size: A3;
        page-break-after: avoid;
      }
      body {
        zoom: 80%;
        margin-top: 20px;
      }
      @page {
        page-break-after: avoid;
      }
      .invoice__each-transaction {
        position: relative;
        page-break-after: auto;
      }
      .invoice__each-transaction {
        visibility: visible;
        position: relative;
        left: 0;
        top: 0;
      }
      .print-table-block {
        page-break-after: auto;
        // height: 100vh;
        position: relative;
        margin-bottom: 70px;
      }
      .print-block {
        display: block;
        page-break-before:always;
        padding-bottom: 70px;
    }
  }
  .powered-by {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-size: 10px;
    color: #808080;
    padding: 10px 0;
    display:${print? "flex" :"none"};
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 999999;
    margin-bottom: 12px;
  }

  .powered-by span {
    margin-right: 10px;
  }

</style>
<style>
.bill-description-table th,.bill-description-table td{
  font-family: 'Arial';
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #000000;
  text-align: left;
  border-bottom: 1px solid #000000;
  padding: 4px 17px;
  word-wrap: break-word;
  min-width: 72px;
  vertical-align: text-top;
}
.bill-description-table th{
  font-weight: 700;
}

.bill-description-table tr td:first-child,.bill-description-table tr th:first-child{
  padding-left: 0px;
}
.bill-description-table tr td:last-child,.bill-description-table tr th:last-child{
  padding-right: 0px;
  text-align: right;
  white-space: nowrap;
}
.amount-number{
    font-family: 'Arial';
    font-weight: 400;
    font-size: 35px;
    line-height: 53px;
    color: #000000;
    margin: 0px;
    white-space: nowrap;
  }
  .bill-description-footer-table tr td{
    border: 0px;
  }

  .invoice-number-date-table td:first-child{
    max-width: 127px;
    min-width: 127px;
    vertical-align: text-top;
  }
  .ticket-col-table{
    width: 100%;
  }
  .ticket-col-table td{
    border-bottom: 0px;
    padding: 0px;
  }
  .ticket-col-table td:first-child{
    min-width: 60px !important;
  }
  .ticket-col-table td:last-child{
    min-width: 120px;
  }
  .desc-list-items {

  }
  .desc-list-items p {
   display: block;
   white-space: nowrap;
   font-size: 14px;
   font-weight: 600;
  }
  .desc-list-items p span {
    white-space: nowrap;
    font-weight: 400;
   }
</style>
</head>
<body>
<table width="100%" style="max-width: 1094px; margin: 0px auto;padding: 0px 15px 15px 15px;">
<thead>
<tr>
  <th colspan="3">
    <img  style="margin: 0px auto; max-height: 50px; max-width: 250px; display: block;" src=${ Company?.logo?.length > 0 ? Company?.logo : Logo}>
  </th>
</tr>
<tr>
  <td style="height: 20px;"></td>
</tr>
<!-- Gap Tr Start -->
<tr>
  <th colspan="3">
    <p
    style="font-family: 'Arial';
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-bottom: 32px;
    "
    >${Company?.businessInfo?.address}
    <br />
    ${
      Company?.businessInfo?.city +
      " " +
      Company?.businessInfo?.state +
      `${ Company?.businessInfo?.state?.length > 0 || Company?.businessInfo?.city?.length > 0 ? ", " : ""}` +
      Company?.businessInfo?.zip
    }
    <br />
    ${Company.billingEmail?.length > 0 ? Company.billingEmail : ""}
    </p>
  </th>
</tr>
</thead>
<tbody>
<tr>
<td style="vertical-align: top; padding-right: 30px; max-width: 200px;">
 ${customer?.displayName?.length > 0 ? `<div
    style="
    font-family: Arial;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    color: #000000;
    margin: 0px;
    "
    >${customer?.displayName} </div>`: ""}
  ${customer?.address?.length > 0 ? `<div
    style="
    font-family: Arial;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #000000;
    margin: 0px;
    "
    >${customer?.address}</div>`: ""}
    <div
    style="
      font-family: Arial;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #000000;
      word-break: break-all;
      margin: 0px;
      "
    >${format_address(customer)?.length > 0 ? `${format_address(customer)} <br />`: ""}
    ${ customer?.phone?.length > 0 ? `${this.phoneFormate(customer?.phone)} <br />`: "" }
    ${ customer?.email?.length > 0 ? customer?.email : customer?.billingEmailAddress }
  </td>
  <td style="vertical-align: top; padding-right: 30px;">
    <table class="invoice-number-date-table">
      <tbody>
        <tr>
          <td>
          <h3
            style="
            font-family: Arial;
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            min-width: 127px;
            margin: 0px;
            vertical-align: top;
            "
          >Statement Date: </h3>
          </td>
          <td>
          <p
            style="
            font-family: Arial;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            color: #000000;
            vertical-align: top;
            min-width: 127px;
            margin: 0px;
            "
          > ${moment()?.tz(Company?.timezone)?.format("MM/DD/YYYY h:mm A")}
          </p>
          </td>
        </tr>
      </tbody>
    </table>
  </td>
  <td
    style="vertical-align: top;"
  >
    <table cellspacing="1" cellpadding="1">
      <tbody>
        <tr>
          <td>
            <h3
              style="
              font-family: Arial;
              font-weight: 700;
              font-size: 12px;
              line-height: 20px;
              color: #000000;
              display: inline-block;
              min-width: 130px;
              margin: 0px;
              white-space: nowrap;"
            >Open Balance Amount
            </h3>
              <p style="
              font-family: Arial;
              font-weight: 400;
              font-size: 28px;
              line-height: normal;
              " class="amount-number"
                > $${this.numberWithCommas(this.round2decimal(Number(totalBalance)))}</p>
            </td>
          </tr> 
      </tbody>
    </table>
    <table style="border-spacing: 0px;    table-layout: fixed;" cellspacing="1" cellpadding="1">
      <tbody>
          <tr>
            <td 
            style="font-family: Arial;
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              vertical-align: top;
              white-space: nowrap;
            ">Credit Balance :</td>
            <td 
            style="
              font-family: Arial;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #000000;
              vertical-align: top;
              max-width: 180px;
              margin: 0px;
              word-wrap: break-word;
              overflow-wrap: break-word;
            ">$${this.numberWithCommas(this.round2decimal(Number(customerCredit)))}
          </td>
        </tr>
      </tbody>
    </table

  </td>
</tr>
<!-- Tr End  -->
<!-- Gap Tr Start -->
<tr>
  <td style="height: 20px;"></td>
</tr>
<!-- Gap Tr Start -->
<tr>
  <td style="height: 20px;"></td>
</tr>
<!-- Gap Tr End -->
<tr>
  <td colspan="3">
  <table class="bill-description-table" width="100%" style="border-collapse: collapse;">
      <thead>
        <tr>
          <th>
            Date
          </th>
          <th style="width: 40%">
           Invoice #
          </th>
          <th>
          Original Amount
          </th>
          <th>
          Open Balance
          </th>
        </tr>
      </thead>
      <tbody>
      ${data?.length > 0 ? data?.map((item) => {
        return `<tr>
<td>
 ${moment(item?.invoiceDate)?.tz(Company?.timezone)?.format("l")}
</td>
<td style="width: 40%">
${item.invoiceId}
</td>
<td style="text-align: left">
$${this.numberWithCommas(this.round2decimal(Number(item?.total)))}
</td>
<td style="text-align: last;">
$${this.numberWithCommas(this.round2decimal(Number(item?.balacnce)))}
</td>
</tr>`;
      })?.join(" ") : ""}
<tfoot>
<tr>
<td style="border: 0px;"> <div class="powered-by">
<span >Powered by</span> <img src=${Logo} alt="WeighWorks" style=" max-width: 120px;max-height:30px;"  />
</div></td>
</tr>
</tfoot>
      </tbody>
      </table>
    </td>
  </tr>

</tbody>
</table>

</body>
</html>`;

    return template;
  };

  printStatement = async (data, totalBalance , customerCredit) => {
    let printWindow = "";
    this.setState({isWaitPrint: true})
    const {value:{data:companyData}} = await this.props.getCompanyById({
      companyId: data[0]?.companyId,
    });
    const prm = new Promise(async (resolve, reject) => {
      let body1 = this.statementTemplate(data, totalBalance, companyData, customerCredit , true);
      printWindow = body1;
      resolve()
    })
    prm.then(async () => {
      this.setState({isWaitPrint: false})
      let printInvoice = window.open(`invoice`, "_blank");
      await printInvoice.document.write(printWindow);
      printInvoice.print();
    })
  };

  downloadStatement = async (invoiceData, totalBalance , customerCredit) => {
    const {value:{data:companyData}} = await this.props.getCompanyById({
      companyId: invoiceData[0]?.companyId,
    });
    const html = this.statementTemplate(invoiceData, totalBalance, companyData , customerCredit);
    this.setState({isWaitDownload: true})
    const {
      value: { data },
    } = await this.props.generateStatementPdf({
      html,
    });
    if(data){
      this.setState({isWaitDownload: false})
      window.open(data);
    }
  };

  sendEmail = async (invoiceData, totalBalance , customerCredit) => {
    this.setState({isWaitSendEmail: true})
    const {value:{data:companyData}} = await this.props.getCompanyById({
      companyId: invoiceData[0]?.companyId,
    });
    const html = this.statementTemplate(invoiceData, totalBalance, companyData , customerCredit);
    const {
      value: { data },
    } = await this.props.generateStatementPdf({
      html,
    });
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.displayName,
        customerName: invoiceData[0]?.customer?.customerName,
        customerId: invoiceData[0]?.customer?._id,
      },
      customer: {
        email: invoiceData[0]?.customer.email,
        billingEmailAddress: invoiceData[0]?.customer?.billingEmailAddress,
        companyName: invoiceData[0]?.customer?.displayName,
      },
      pdfUrl: data,
      totalBalance: totalBalance,
      customerCredit : customerCredit,
      recipients: this.state.recipients.map((item) => item.email),
      html: this.statementTemplate(invoiceData, totalBalance, companyData , customerCredit)
    };
    if(this.state.recipients[0].email && this.state.recipients[0].name){
      const {
        value: { showMessage },
      } = await this.props.statementEmail(payload);
    if(showMessage){
      this.setState({isWaitSendEmail: false})
      message.success(showMessage);
    }
    }
  };

  deleteRecipient = async (index) => {
    const data = [...this.state.recipients];
    data.splice(index, 1);
    this.setState({ recipients: data });
  };
  render() {
    const { totalBalance, customerInvoice } = this.state.modelData;
    const{customerCredit} = this.state
    return (
      <>
        <ReactModal
          isOpen={true}
          onRequestClose={this.props.onClose}
          contentLabel="Please Confirm"
        >
           {  
          <div className="react-modal-dialog react-modal-dialog-centered create-statement-invoice-modal invoice__modal-">
            <div className="react-modal-header">
              <div className="btn-back-header">
                <h5 className="react-modal-title">Create a statement for {customerInvoice && customerInvoice[0]?.customer?.displayName}</h5>
              </div>
              <button
                onClick={this.props.onClose}
                type="button"
                className="btn btn-sm react-modal-close"
              >
                <CloseIcon />
              </button>
            </div>
            
              <Spin spinning={this.state.isLoading} indicator={antIcon} size='large'>
            <div className="react-modal-body">
              <div className="invoice__address">
                <ul className="list address__list">
                  <li className="items open-blance-items">
                    <div className="balance__due">
                      <h4>Open Balance</h4>
                      <h1 className="balance">
                        ${this.numberWithCommas(Number(totalBalance ?? 0))}
                      </h1>
                    </div>
                  </li>
                  <li className="items credit-items">
                    <div className="balance__due">
                      <h4>Credit</h4>
                      <h1 className="balance">
                        ${ customerCredit ? this.numberWithCommas(Number(customerCredit)): "0"}
                      </h1>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="table-heading-container">
                <h3>Recipients</h3>
              </div>
              <div className="table-responsive">
                <table className="table statement-table">
                  <thead>
                    <tr>
                      <th>Recipient Name</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.recipients.map((e, i) => {
                      return (
                        <tr>
                          <td>
                            <input
                              className="form-control statement-table-po-input"
                              value={e.name}
                              onChange={(e) => {
                                const recipients = [...this.state.recipients];
                                recipients[i] = {
                                  ...recipients[i],
                                  name: e.target.value,
                                };
                                this.setState({ recipients: recipients });
                              }}
                            />
                          </td>
                          <td>
                            <div className="d-flex">
                              <input
                                className="form-control statement-table-po-input"
                                value={e.email}
                                onChange={(e) => {
                                  const recipients = [...this.state.recipients];
                                  recipients[i] = {
                                    ...recipients[i],
                                    email: e.target.value,
                                  };
                                  this.setState({ recipients: recipients });
                                }}
                              />
                              {i !== 0 && (
                                <img
                                  onClick={() => this.deleteRecipient(i)}
                                  className="recipient-delete-icon"
                                  src={deleteIcon}
                                  alt=""
                                />
                              )}
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="addNewRecipient">
                  <span
                    onClick={() => {
                      this.setState({
                        recipients: [
                          ...this.state.recipients,
                          {
                            email: "",
                            name: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add new recipients +
                  </span>
                </div>
              </div>
              <div className="row buttonSection">
                <div className="col-lg-6">
                  <button
                    disabled={this.state.isWaitPrint}
                    className="btn btn-outline-dark w-40"
                    onClick={() =>
                      this.printStatement(customerInvoice, totalBalance , customerCredit)
                    }
                  >
                  {
                    this.state.isWaitPrint ? <Spin indicator={antIcon}/> : "Print"
                  }
                  </button>
                  <button
                    disabled={this.state.isWaitDownload}
                    className="btn btn-outline-dark w-40 download-btn"
                    onClick={() =>
                      this.downloadStatement(customerInvoice, totalBalance , customerCredit)
                    }
                  >
                  {
                    this.state.isWaitDownload ? <Spin indicator={antIcon}/> : "Download"
                  }
                  </button>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <button
                    disabled={this.state.isWaitSendEmail}
                    className="btn btn-dark w-40 text-light send-email-btn"
                    onClick={() =>
                      this.sendEmail(customerInvoice, totalBalance , customerCredit)
                    }
                  >
                  {
                    this.state.isWaitSendEmail ? <Spin indicator={antIcon}/> : "Send Email"
                  }
                  </button>
                </div>
              </div>
            </div>
              </Spin>
          </div>             
  }
        </ReactModal>
      </>
    );
  }
}
