import React, { PureComponent } from "react";
// import PropTypes from 'prop-types'
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import ReactModal from "react-modal";
import InputMask from "react-input-mask";
import { Dropdown, Menu, Pagination } from "antd";
import _ from "lodash";
import { Formik, ErrorMessage } from "formik";
import { userSchema } from "../../utils/validations";
import { CloseIcon, MoreIcon } from "../../components/icons";
import NumberFormat from "react-number-format";
import "./styles.scss";
import EditUserModal from "./partials/edit-user";
import DeleteUserModal from "./partials/delete-user";
import PermissionDialog from "../../utils/permissionDialog";

export default class UserManagmentComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      users: [],
      typingTimeout: 0,
      user: {},
      _id: "",
      editUser: null,
      toggleMenu: false,
      editUserModal: false,
      searchText: "",
      isDeleteModal: false,
      initialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        role: "admin",
        status: "1"
      },
      spinning: false,
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      bussinessAdminCheck: true,
      generalCheck: true,
      scaleCheck: true,
      createChargeCheck: true,
      ordersVoidCheck: true,
      billingCheck: true,
      pricingSetupAdminCheck: true,
      scaleSetupAdminCheck: true,
      intergrationsCheck: true,
      userManagementCheck: true,
      reportsCheck: true,
      ordersCheck: true,
      inboundVsOutboundCheck: true,
      clientAndJobReportCheck: true,
      cashDiscrepancyCheck: true,
      deletedTicketsCheck: true,
      projectOrdersCheck: true,
      viewUserManagementTab: true,
      bussinessSetupCheck: true,
      completeOrder: true,
      exportOrdersAndReports: true,
      manuallyEnterScaleWeight: true,
      deleteOrder: true,
      createManualCharge: true,
      voidOrders: true,
      voidInvoices: true,
      viewBillingPage: true,
      syncToQuickbooks: true,
      voidPayments: true,
      editUserPermissions: true,
      editBusinessSettings: true,
      editSyncedOrders: true,
      dialogOpen: false,
      qbIntegrationStatus: false,
      pricingTier: true,
      createCustomer: true,
      currentUser:{}

    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    document.title = "User Management | Weighworks";
    // this.props.getMe();
    const { fetchMe,verifyQBIntegration } = this.props;
    const {
      value: { user }
    } = await fetchMe();
    try {
      const {value:{status}} = await verifyQBIntegration();
      if (status) {
        this.setState({ qbIntegrationStatus: true });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ currentUser:user })
    this.fetchCompanyUsers();
  };

  componentDidUpdate({ user }, prevState) {
    if (user && user !== prevState.user) {
      this.setState({ user }, () => {
        // this.fetchCompanyUsers();
      });
    }
  }

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchCompanyUsers();
    });
  };

  fetchCompanyUsers = async () => {
    const { fetchCompanyUsers } = this.props;
    const { searchText, limit, skip } = this.state;
    const {
      value: { data: { users, usersCount } }
    } = await fetchCompanyUsers({ searchText, limit, skip, allUsers: false });
    this.setState({ users, totalItems: usersCount });
  };

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchCompanyUsers();
      }, 1000)
    });
  };

  openModal() {
    this.setState({ 
      modalIsOpen: true,
      pricingTier: true,
      createCustomer: true,
      bussinessAdminCheck: true,
      generalCheck: true,
      scaleCheck: true,
      createChargeCheck: true,
      ordersVoidCheck: true,
      billingCheck: true,
      pricingSetupAdminCheck: true,
      scaleSetupAdminCheck: true,
      intergrationsCheck: true,
      userManagementCheck: true,
      reportsCheck: true,
      ordersCheck: true,
      inboundVsOutboundCheck: true,
      clientAndJobReportCheck: true,
      cashDiscrepancyCheck: true,
      deletedTicketsCheck: true,
      projectOrdersCheck: true,
      viewUserManagementTab: true,
      bussinessSetupCheck: true,
      completeOrder: true,
      exportOrdersAndReports: true,
      manuallyEnterScaleWeight: true,
      deleteOrder: true,
      createManualCharge: true,
      voidOrders: true,
      voidInvoices: true,
      viewBillingPage: true,
      syncToQuickbooks: true,
      voidPayments: true,
      editUserPermissions: true,
      editBusinessSettings: true,
      editSyncedOrders: true,
     });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleSubmit = async ({ phone, ...rest }, { setSubmitting, setErrors }) => {
    const {
      user: { companyId }
    } = this.state;
    setSubmitting(true);
    const payload = {
      ...rest,
      companyId,
      phone: phone.replace(/[^\d]/g, ""),
      permissionsSettings:{
        businessAdmin:{
          businessSetup:this.state.bussinessSetupCheck,
          pricingSetup:this.state.pricingSetupAdminCheck,
          scaleSetup:this.state.scaleSetupAdminCheck,
          intergrations:this.state.intergrationsCheck,
        },
        userManagement:this.state.viewUserManagementTab,
        reports:{
          orders:this.state.ordersCheck,
          inboundVsOutbound:this.state.inboundVsOutboundCheck,
          clintAndJob:this.state.clientAndJobReportCheck,
          cashDiscrepancy:this.state.cashDiscrepancyCheck,
          deletedTickets:this.state.deletedTicketsCheck,
          projectOrders:this.state.projectOrdersCheck,
        },
        permissionOptions:{
          completeOrder: this.state.completeOrder,
          exportOrdersAndReports:   this.state.exportOrdersAndReports,
          manuallyEnterScaleWeight:   this.state.manuallyEnterScaleWeight,
          deleteOrder:  this.state.deleteOrder,  
          createManualCharge:   this.state.createManualCharge,
          voidOrders:   this.state.voidOrders,
          voidInvoices:   this.state.voidInvoices,
          viewBillingPage:   this.state.viewBillingPage,
          syncToQuickbooks:   this.state.syncToQuickbooks,
          voidPayments:   this.state.voidPayments,
          editUserPermissions:  this.state.editUserPermissions,
          editBusinessSettings:   this.state.editBusinessSettings,
          editSyncedOrders:   this.state.editSyncedOrders,
          pricingTier:   this.state.pricingTier,
          createCustomer:   this.state.createCustomer,
        }
      }
    };
    const {
      value: { status, message }
    } = await this.props.createUser(payload);
    if (status) {
      this.setState({ modalIsOpen: false });
      this.fetchCompanyUsers();
    } else {
      setErrors({ email: message });
    }
  };

  deleteUserModal({ _id }) {
    this.setState({ _id, isDeleteModal: true });
  }

  editUser =async (user)=> {
    const {getUserPermissions}=this.props
    const {
      value: { data: { permission } }
    } = await getUserPermissions(user._id);
    if(permission){
    user["permission"]=permission
    }
    else{
      user["permission"]={}
    }
    this.setState({ editUserModal: true, editUser: user });
  }

  async impersonateUser({ token, _id }) {
    const { value: { data } } = await this.props.getUserToken({ _id })
    const auth = localStorage.getItem("Authorization");
    localStorage.setItem("i_Authorization", auth);
    localStorage.setItem("Authorization", data);
    this.props.history.push("/dashboard");
  }

  bussinessAdminCheckboxTree = () => {
    const { bussinessAdminCheck, bussinessSetupCheck, scaleSetupAdminCheck, pricingSetupAdminCheck, intergrationsCheck,editBusinessSettings,pricingTier } = this.state
    if ((pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier) && bussinessAdminCheck) {
      this.setState({
        pricingSetupAdminCheck: false, scaleSetupAdminCheck: false,
        editBusinessSettings:false,
        pricingTier:false,
        intergrationsCheck: false, bussinessSetupCheck: false, bussinessAdminCheck: false
      })
    }
    else {
      this.setState({
        pricingSetupAdminCheck: true, scaleSetupAdminCheck: true,
        editBusinessSettings:true,
        pricingTier:true,
        intergrationsCheck: true, bussinessSetupCheck: true, bussinessAdminCheck: true
      });
    }
  }
  generalCheckboxTree = () => {
    const { completeOrder, exportOrdersAndReports,generalCheck ,createCustomer } = this.state
    if ((completeOrder && exportOrdersAndReports && createCustomer ) && generalCheck) {
      this.setState({
        completeOrder: false, exportOrdersAndReports: false,
        createCustomer: false,
        generalCheck: false
      })
    }
    else {
      this.setState({
        completeOrder: true, exportOrdersAndReports: true,
        createCustomer: true,
        generalCheck: true
      });
    }
  }
  scaleCheckboxTree = (role) => {
    const { manuallyEnterScaleWeight, deleteOrder,scaleCheck } = this.state
    if (role === "user") {
      if ((deleteOrder ) && scaleCheck) {
        this.setState({
          deleteOrder: false,
          scaleCheck: false
        })
      }
      else {
        this.setState({
          deleteOrder: true,
          scaleCheck: true
        });
      }
    }else{
      if ((manuallyEnterScaleWeight && deleteOrder ) && scaleCheck) {
        this.setState({
          manuallyEnterScaleWeight: false, deleteOrder: false,
          scaleCheck: false
        })
      }
      else {
        this.setState({
          manuallyEnterScaleWeight: true, deleteOrder: true,
          scaleCheck: true
        });
      }
    }
  }
  createChargeCheckboxTree = () => {
    const { createManualCharge,createChargeCheck } = this.state
    if ((createManualCharge ) && createChargeCheck) {
      this.setState({
        createManualCharge: false,
        createChargeCheck: false
      })
    }
    else {
      this.setState({
        createManualCharge: true,
        createChargeCheck: true
      });
    }
  }
  ordersCheckboxTree = () => {
    const { voidOrders,ordersVoidCheck } = this.state
    if ((voidOrders ) && ordersVoidCheck) {
      this.setState({
        voidOrders: false,
        ordersVoidCheck: false
      })
    }
    else {
      this.setState({
        voidOrders: true,
        ordersVoidCheck: true
      });
    }
  }
  billingCheckboxTree = () => {
    const { voidPayments,voidInvoices,syncToQuickbooks,billingCheck,qbIntegrationStatus,viewBillingPage } = this.state

    if (qbIntegrationStatus) {
      if ((voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage ) && billingCheck) {
        this.setState({
          voidPayments: false,
          voidInvoices: false,
          syncToQuickbooks: false,
          billingCheck: false,
          viewBillingPage:false
        })
      }
      else {
        this.setState({
          voidPayments: true,
          voidInvoices: true,
          syncToQuickbooks: true,
          billingCheck: true,
          viewBillingPage:true
        });
      }
    }else{
      if ((voidPayments && voidInvoices && viewBillingPage ) && billingCheck) {
        this.setState({
          voidPayments: false,
          voidInvoices: false,
          syncToQuickbooks: false,
          billingCheck: false,
          viewBillingPage:false
        })
      }
      else {
        this.setState({
          voidPayments: true,
          voidInvoices: true,
          syncToQuickbooks: false,
          billingCheck: true,
          viewBillingPage:true
        });
      }
    }
  }
  userManagementCheckboxTree = (e) => {
    const {checked} = e.target;
    const { editUserPermissions,viewUserManagementTab,editSyncedOrders,userManagementCheck,qbIntegrationStatus } = this.state
    if (qbIntegrationStatus) {
      if (checked) {
        if ((editUserPermissions && viewUserManagementTab && editSyncedOrders ) && userManagementCheck) {
          this.setState({
            editUserPermissions: false,
            viewUserManagementTab: false,
            userManagementCheck: false,
            editSyncedOrders:false
          })
        }
        else {
          this.setState({
            editUserPermissions: true,
            viewUserManagementTab: true,
            editSyncedOrders:true,
            userManagementCheck: true
          });
        }
        this.setState({ dialogOpen: true,editSyncedOrders:true });
      }else{
        this.setState({ dialogOpen: false,
          editUserPermissions: false,
          viewUserManagementTab: false,
          userManagementCheck: false,
          editSyncedOrders:false 
        });
      }
    }else{
      if ((editUserPermissions && viewUserManagementTab  ) && userManagementCheck) {
        this.setState({
          editUserPermissions: false,
          viewUserManagementTab: false,
          userManagementCheck: false,
          editSyncedOrders:false 
        })
      }
      else {
        this.setState({
          editUserPermissions: true,
          viewUserManagementTab: true,
          userManagementCheck: true,
          editSyncedOrders:false 
        });
      }
    }
    
  }

  reportsCheckboxTree = () => {
    const { reportsCheck, ordersCheck, inboundVsOutboundCheck, clientAndJobReportCheck, cashDiscrepancyCheck,deletedTicketsCheck ,projectOrdersCheck} = this.state
    if ((ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck && projectOrdersCheck) && reportsCheck) {
      this.setState({
        ordersCheck: false, inboundVsOutboundCheck: false,
        clientAndJobReportCheck: false, cashDiscrepancyCheck: false, reportsCheck: false,deletedTicketsCheck:false ,projectOrdersCheck:false
      })
    }
    else {
      this.setState({
        ordersCheck: true, inboundVsOutboundCheck: true,
        clientAndJobReportCheck: true, cashDiscrepancyCheck: true, reportsCheck: true,deletedTicketsCheck:true ,projectOrdersCheck:true
      })
    }
  }
  
reportCheckbox =()=>{
  const {  ordersCheck, inboundVsOutboundCheck, clientAndJobReportCheck, cashDiscrepancyCheck,deletedTicketsCheck ,projectOrdersCheck} = this.state
    if(!(ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck && projectOrdersCheck)){
      this.setState({reportsCheck:false})
    }
    if((ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck && projectOrdersCheck)){
      this.setState({reportsCheck:true})
    }
  }

  bussinessAdminCheckbox =()=>{
    const {  bussinessSetupCheck, scaleSetupAdminCheck, pricingSetupAdminCheck, intergrationsCheck,editBusinessSettings,pricingTier } = this.state
    if(!(pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier)){
      this.setState({bussinessAdminCheck:false})
    }
    if((pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier)){
      this.setState({bussinessAdminCheck:true})
    }
  }
  generalCheckbox =()=>{
    const {  completeOrder, exportOrdersAndReports,createCustomer } = this.state
    if(!(completeOrder && exportOrdersAndReports && createCustomer)){
      this.setState({generalCheck:false})
    }
    if((completeOrder && exportOrdersAndReports && createCustomer)){
      this.setState({generalCheck:true})
    }
  }
  scaleCheckbox =(role)=>{
    const {  deleteOrder, manuallyEnterScaleWeight } = this.state
    if (role === "user") {
      if(!(deleteOrder )){
        this.setState({scaleCheck:false})
      }
      if((deleteOrder )){
        this.setState({scaleCheck:true})
      }
    }else{

      if(!(deleteOrder && manuallyEnterScaleWeight )){
        this.setState({scaleCheck:false})
      }
      if((deleteOrder && manuallyEnterScaleWeight )){
        this.setState({scaleCheck:true})
      }
    }
  }
  createChargeCheckbox =()=>{
    const {  createManualCharge } = this.state
    if(!(createManualCharge )){
      this.setState({createChargeCheck:false})
    }
    if((createManualCharge)){
      this.setState({createChargeCheck:true})
    }
  }
  ordersCheckbox =()=>{
    const {  voidOrders } = this.state
    if(!(voidOrders )){
      this.setState({ordersVoidCheck:false})
    }
    if((voidOrders)){
      this.setState({ordersVoidCheck:true})
    }
  }
  billingCheckbox =()=>{
    const {  voidPayments,voidInvoices,syncToQuickbooks,qbIntegrationStatus,viewBillingPage } = this.state
    if (qbIntegrationStatus) {
      if(!(voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage)){
        this.setState({billingCheck:false})
      }
      if((voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage)){
        this.setState({billingCheck:true})
      }
    }else{
      if(!(voidPayments && voidInvoices && viewBillingPage  )){
        this.setState({billingCheck:false})
      }
      if((voidPayments && voidInvoices && viewBillingPage )){
        this.setState({billingCheck:true})
      }
    }
  }
  userManagementCheckbox =()=>{
    const {  viewUserManagementTab,editUserPermissions,editSyncedOrders,qbIntegrationStatus } = this.state
    if (qbIntegrationStatus) {
      if(!(viewUserManagementTab && editUserPermissions && editSyncedOrders )){
        this.setState({userManagementCheck:false})
      }
      if((viewUserManagementTab && editUserPermissions && editSyncedOrders)){
        this.setState({userManagementCheck:true})
      }
    }else{
      if(!(viewUserManagementTab && editUserPermissions )){
        this.setState({userManagementCheck:false})
      }
      if((viewUserManagementTab && editUserPermissions)){
        this.setState({userManagementCheck:true})
      }
    }
  }

  menu(user) {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => this.editUser(user)}>
          Edit
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.deleteUserModal(user)}>
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  dialogOpen = (e) => {
    const {checked} = e.target;
    if (checked) {
      this.setState({ dialogOpen: true,editSyncedOrders:true });
    }else{
      this.setState({ dialogOpen: false,editSyncedOrders:false,userManagementCheck: false });
    }
  }
  render() {
        const { users, searchText, initialValues,currentUser,qbIntegrationStatus} = this.state;
    return (
      <div>
        <div className="layout-has-sidebar">        
          <TopNavigation
            {...this.props}
            searchText={searchText}
            handleSearchChange={this.handleSearch}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
          <main className="dashboard-layout-content user__managment--wrapper">
            <div className="page-header">
              <h2 className="heading-title">User Management</h2>
              <div className="page-header-btn-grp">
                <button className="btn btn-dark btn-sm" onClick={this.openModal}>
                  Add New User
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table custom-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => {
                        return (
                          <tr className="cursor-pointer" key={user._id}>
                            <td
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/user-detail`,
                                  state: {
                                    id: _.get(user, "_id", "")
                                  }
                                })
                              }
                            >
                              {_.startCase(_.toLower(`${user.firstName} ${user.lastName}`))}
                            </td>
                            <td
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/user-detail`,
                                  state: {
                                    id: _.get(user, "_id", "")
                                  }
                                })
                              }
                            >
                              {user.email}
                            </td>
                            <td
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/user-detail`,
                                  state: {
                                    id: _.get(user, "_id", "")
                                  }
                                })
                              }
                            >
                              <NumberFormat
                                format="(###) ###-####"
                                allowEmptyFormatting={false}
                                mask=""
                                displayType={"text"}
                                value={user.phone}
                              />
                            </td>
                            <td
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/user-detail`,
                                  state: {
                                    id: _.get(user, "_id", "")
                                  }
                                })
                              }
                            >
                              {user.role}
                            </td>
                            <td
                              className={user.status === 1 ? "text-green" : "text-red"}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/user-detail`,
                                  state: {
                                    id: _.get(user, "_id", "")
                                  }
                                })
                              }
                            >
                              {user.status === 1 ? "Active" : "Not Active"}
                            </td>
                            <td
                              className="cursor-pointer underline text-bold"
                              onClick={() => {
                                this.impersonateUser(user);
                                  var element = document.getElementsByClassName("application-container");
                                         element[0].classList.add("impersonate__one")
                              
                              }}
                            >
                              Impersonate
                            </td>
                            {(currentUser?.permissions?.permissionOptions?.editUserPermissions === true) &&
                            <td className="table__last-child">
                              <Dropdown
                                overlay={() => this.menu(user)}
                                trigger={["click"]}
                                overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                              >
                                <button className="btn ant-dropdown-link">
                                  <MoreIcon />
                                </button>
                              </Dropdown>
                            </td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <div className="react-modal-dialog react-modal-dialog-centered create__user-modal">
                <div className="react-modal-header">
                  <h5 className="react-modal-title">Create User</h5>
                  <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                    <CloseIcon />
                  </button>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="firstName"
                            value={values.firstName}
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            required
                          />
                          <ErrorMessage component="span" name="firstName" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            First Name<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="lastName"
                            value={values.lastName}
                            type="text"
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            required
                          />
                          <ErrorMessage component="span" name="lastName" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Last Name<span>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <InputMask
                            value={values.phone}
                            mask="(999) 999-9999"
                            name="phone"
                            maskChar=""
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            required
                          />
                          <ErrorMessage component="span" name="phone" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Phone Number<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group material-textfield">
                          <input
                            name="email"
                            type="text"
                            value={values.email}
                            className="form-control material-textfield-input"
                            onChange={handleChange}
                            required
                          />
                          <ErrorMessage component="span" name="email" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Email<span>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        value={values.role}
                        className="form-control custom-select material-textfield-input"
                        name="role"
                        onChange={handleChange}
                        required
                      >
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                        <option value="supervisor">Supervisor</option>
                      </select>
                      <ErrorMessage component="span" className="invalid-feedback d-block" name="role" />
                      <label className="material-textfield-label">
                        Role<span>*</span>
                      </label>
                    </div>
                    <div className="form-group material-textfield">
                      <select
                        value={values.status}
                        className="form-control custom-select material-textfield-input"
                        name="status"
                        onChange={handleChange}
                        required
                      >
                        <option value="1">Active</option>
                        <option value="3">Inactive</option>
                      </select>
                      <ErrorMessage component="span" className="invalid-feedback d-block" name="status" />
                      <label className="material-textfield-label">
                        Status<span>*</span>
                      </label>
                    </div>
                    <div className="checkboxtree__section">
                      <div className="checkboxtree__header">
                        <h3 className="title">Permissions </h3>
                        <p>Select the permissions you want to grant this user,
                          if it’s selected they can see that feature.</p>
                      </div>
                      <div className="checkboxtree__body">
                        <div className="checkbox__tree">
                          <ul className="checkbox__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                General 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.generalCheck}

                                  onChange={(e) => {this.generalCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Completing Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.completeOrder}
                                      onChange={() => { this.setState({ completeOrder: !this.state.completeOrder },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Export Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.exportOrdersAndReports}
                                      onChange={() => { this.setState({ exportOrdersAndReports: !this.state.exportOrdersAndReports },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Create customer
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.createCustomer}
                                      onChange={() => { this.setState({ createCustomer: !this.state.createCustomer },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Scale 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.scaleCheck}

                                  onChange={(e) => {this.scaleCheckboxTree(values.role)}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Delete Order
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.deleteOrder}
                                      onChange={() => { this.setState({ deleteOrder: !this.state.deleteOrder },()=>{this.scaleCheckbox(values.role)}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {values.role==="user"?null:(<li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Manually Enter Scale Weight
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.manuallyEnterScaleWeight}
                                      onChange={() => { this.setState({ manuallyEnterScaleWeight: !this.state.manuallyEnterScaleWeight },()=>{this.scaleCheckbox(values.role)}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>)}
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Create Charge 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.createChargeCheck}

                                  onChange={(e) => {this.createChargeCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Create Manual Charge
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.createManualCharge}
                                      onChange={() => { this.setState({ createManualCharge: !this.state.createManualCharge },()=>{this.createChargeCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Orders
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.ordersVoidCheck}

                                  onChange={(e) => {this.ordersCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Void Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidOrders}
                                      onChange={() => { this.setState({ voidOrders: !this.state.voidOrders },()=>{this.ordersCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                              Billing
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.billingCheck}

                                  onChange={(e) => {this.billingCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                              <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  View Billing page
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.viewBillingPage}
                                      onChange={() => { this.setState({ viewBillingPage: !this.state.viewBillingPage },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {qbIntegrationStatus && <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Sync with Quickbooks
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.syncToQuickbooks}
                                      onChange={() => { this.setState({ syncToQuickbooks: !this.state.syncToQuickbooks },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>}
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Void Invoices
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidInvoices}
                                      onChange={() => { this.setState({ voidInvoices: !this.state.voidInvoices },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {/* <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Void Payments
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidPayments}
                                      onChange={() => { this.setState({ voidPayments: !this.state.voidPayments },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li> */}
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Business Admin
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.bussinessAdminCheck}

                                  onChange={(e) => {this.bussinessAdminCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Business Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.bussinessSetupCheck}
                                      onChange={() => {
                                        this.setState({ bussinessSetupCheck: !this.state.bussinessSetupCheck },()=>{this.bussinessAdminCheckbox()});
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Pricing Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.pricingSetupAdminCheck}
                                      onChange={() => { this.setState({ pricingSetupAdminCheck: !this.state.pricingSetupAdminCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Scale Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.scaleSetupAdminCheck}
                                      onChange={() => { this.setState({ scaleSetupAdminCheck: !this.state.scaleSetupAdminCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Intergrations
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.intergrationsCheck}
                                      onChange={() => { this.setState({ intergrationsCheck: !this.state.intergrationsCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                   Edit Business Settings
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editBusinessSettings}
                                      onChange={() => { this.setState({ editBusinessSettings: !this.state.editBusinessSettings },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Add/Edit Pricing Tier
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.pricingTier}
                                      onChange={() => { this.setState({ pricingTier: !this.state.pricingTier },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                User Management
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.userManagementCheck}
                                  onChange={(e) => {this.userManagementCheckboxTree(e)}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    View User Management tab
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.viewUserManagementTab}
                                      onChange={() => { this.setState({ viewUserManagementTab: !this.state.viewUserManagementTab },()=>{this.userManagementCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Edit User Permissions
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editUserPermissions}
                                      onChange={() => { this.setState({ editUserPermissions: !this.state.editUserPermissions },()=>{this.userManagementCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {qbIntegrationStatus && <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Edit Synced Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editSyncedOrders}
                                      onChange={(e) => this.dialogOpen(e)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>}
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Reports
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.reportsCheck}
                                  onChange={()=>{this.reportsCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.ordersCheck}
                                      onChange={()=>{this.setState({ordersCheck:!this.state.ordersCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Inbound vs Outbound
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.inboundVsOutboundCheck}
                                      onChange={()=>{this.setState({inboundVsOutboundCheck:!this.state.inboundVsOutboundCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Client & Job Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.clientAndJobReportCheck}
                                      onChange={()=>{this.setState({clientAndJobReportCheck:!this.state.clientAndJobReportCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Cash Discrepancy Form/Log Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.cashDiscrepancyCheck}
                                      onChange={()=>{this.setState({cashDiscrepancyCheck:!this.state.cashDiscrepancyCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Voided Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.deletedTicketsCheck}
                                      onChange={()=>{this.setState({deletedTicketsCheck:!this.state.deletedTicketsCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Projects
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.projectOrdersCheck}
                                      onChange={()=>{this.setState({projectOrdersCheck:!this.state.projectOrdersCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                               
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <button disabled={isSubmitting} className="btn btn-dark btn-lg w-100" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            )}  
          </Formik>
        </ReactModal>
        <PermissionDialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false,editSyncedOrders: false,userManagementCheck : false})}
          onOkChange={() => { this.setState({ editSyncedOrders: true,dialogOpen: false }, () => { this.userManagementCheckbox() }) }}
        />
        <EditUserModal
          user={this.state.editUser}
          editUserModal={this.state.editUserModal}
          closeModal={() => this.setState({ editUserModal: false })}
          fetchCompanyUsers={this.fetchCompanyUsers}
          updateUserById={this.props.updateUserById}
          
        />
        <DeleteUserModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => this.setState({ isDeleteModal: false })}
          deleteUser={this.props.deleteUser}
          fetchCompanyUsers={this.fetchCompanyUsers}
          userId={this.state._id}
        />
        <Pagination
          className="text-center"
          onChange={this.onChangePage}
          hideOnSinglePage
          current={this.state.currentPage}
          total={this.state.totalItems}
          pageSize={this.state.limit}
          showSizeChanger={false}
        />
      </div>
    );
  }
}
