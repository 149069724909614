import React, { PureComponent } from "react";
import "../styles.scss";
import { DownarrowIcon, MoreIcon, StateUpArrowIcon } from "../../../components/icons";
import { Dropdown, Pagination, Menu, Spin } from "antd";
import _ from "lodash";
import flagRed from "../../../images/flag_red.svg";
import CustomerDetailComponent from "../../CustomerDetail/component";
import { LoadingOutlined } from "@ant-design/icons";
import SearchCheckboxComponent from "../../Common/SearchCheckbox";
import CreateStatementComponent from "../../CreateStatement/component";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class CustomersComponent extends PureComponent {
  tableRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      searchStartsWith: true,
      toggleMenu: false,
      user: {},
      customers: [],
      customer: {},
      searchText: "",
      typingTimeout: 0,
      sortOrder: { displayName: 1},
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      spinning: false,
      mainSearchText: "",
      isCustomerModelOpen: false,
      createStatementPopup : false,
      createStatementCustomerId : "",
      customerId: "",
      defaultUrl: "billing",
      isLoading:true
      // activeUrl: "",
    };
  }
  componentDidMount = async () => {
    document.title = "Customers| Weighworks";
    this.fetchCustomers();
    const path = window.location.pathname;
    const pathArray = path.split("/");
    if (pathArray.length > 5) {
      this.setState({ customerId: pathArray[4] });
      this.setState({ isCustomerModelOpen: true });
    }
    const urlarray = window.location.pathname.split("/");
    if (urlarray[4] === "general") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
    // this.assignUrl(path)
  };
  async fetchCustomers(sortOrderParam) {
    const { fetchBillingCustomers } = this.props;
    const { searchText, sortOrder, skip, limit, mainSearchText, searchStartsWith } = this.state;
    this.setState({ spinning: true });
    const {
      value: {
        data: { customers, totalCustomers },
      },
    } = await fetchBillingCustomers({
      searchText: searchText ? searchText : mainSearchText,
      sortOrder:sortOrderParam ? sortOrderParam : sortOrder,
      skip,
      limit,
      searchStartsWith
    });
    if (this.tableRef.current) {
      this.tableRef.current.scrollTop = 0;
    }
    this.setState({ customers, totalItems: totalCustomers, spinning: false });
  }
  onChangePage = (page) => {
    this.setState(
      { currentPage: page, skip: (page - 1) * this.state.limit },
      () => {
        this.fetchCustomers();
      }
    );
  };

  assignUrl = (url) => {
    let customerId = "";
    const path = url.split("/");
    if (path[1] !== "order-details" && this.state.customerId === "") {
      customerId = path[2];
    } else {
      customerId = this.state.customerId;
    }

    if (path[1] === "order-details" || path[1] === "edit-customer") {
      if (path[1] === "order-details") {
        this.props.navigateFunction(url);
        this.setState({ isCustomerModelOpen: false });
      }
      if (path[1] === "edit-customer") {
        this.props.navigateFunction({
          pathname: "/edit-customer",
          state: {
            id: customerId,
          },
        });
      }
    } else {
      if (path[3] === "general") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/general`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "past-weigh-ins") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/past-weigh-ins`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "invoice") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/billing/invoice`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "payment-receipt") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/billing/payment-receipt`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "credit-memo") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/billing/credit-memo`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "rates") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/rates`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "drivers") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/drivers`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "trucks") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/trucks`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "payment-methods") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/payment-methods`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "history") {
        this.props.navigateFunction(
          `/billing/customer/customer-detail/${customerId}/history`
        );
        this.setState({ isCustomerModelOpen: true });
      }
    }
  };
  closeCustomerDetatilModel = () => {
    this.setState({ isCustomerModelOpen: false });
    this.props.navigateFunction("/billing/customers");
  };
  setCustomerTotalBalance = async (index,customerId)=>{
    const data = await this.props.customerOpenBalanceInvoicesByCustomerId({customerId: customerId ? customerId:this.state.customers[index]._id})
    this.setState({ statementModalData: data.value.data });
  }
  menu = (index) => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => this.setState({
              createStatementCustomerId: this.state.customers[index]?._id,
              createStatementPopup: true,
              customer : this.state.customers[index]
            })}
          >
            Create Statement
          </Menu.Item>
        </Menu>
      );
  };
  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };

  changeSort = async(sortOrder)=>{
   await this.setState({sortOrder})
    this.fetchCustomers(sortOrder)
  }
  render() {
    return (
      <div className="billing__customers-wrapper">
        <section className="table__filter-section">
          <div className="table__filter-inner">
            <div className="filter-form">
              <input
                type="text"
                onChange={(e) => {
                  this.setState({ searchText: e.target.value.trimStart(), skip: 0, currentPage: 1 }, () => {
                    this.state.searchText!=="" && this.fetchCustomers();
                  });
                }}
                value={this.state.searchText}
                className="form-control search-form"
                placeholder="Search by company name, contact name, phone, or email..."
              />
            </div>
            <p className="invoices__text">
              <img className="icon" src={flagRed} alt="" /> Has Outstanding
              Invoices
            </p>
          </div>
        </section>
        <SearchCheckboxComponent
          searchStartsWith={this.state.searchStartsWith}
          handleSearchStartsWith={(value) => this.setState({ searchStartsWith: value, skip: 0, currentPage: 1 }, () => this.fetchCustomers())}
        />
        <section className="table__section">
          <div className="card">
            <div ref={this.tableRef} className="table-responsive">
            <Spin spinning={this.state.spinning} indicator={null}>
              <table className="table custom-table fixTableHead">
                <thead>
                  <tr>
                    <th className="first-child">Customer Name</th>
                    <th>Phone Number</th>
                    <th>Billing Email</th>
                    <th>Billing Contact Name</th>
                    <th  onClick={()=> this.changeSort({balance: this.state.sortOrder["balance"] === 1 ? -1 : 1 })}><span className="mx-2">Open Balance</span>  {this.state.sortOrder["balance"] ? this.state.sortOrder["balance"] === 1 ? <DownarrowIcon /> : <StateUpArrowIcon /> : <StateUpArrowIcon />}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.spinning ? this.state.customers.length > 0 &&
                    this.state.customers.map((customer, index) => {
                      return (
                        <tr key={customer._id}>
                          {customer.balance > 0 &&
                          new Date(customer.dueDate) < new Date() ? (
                            <td
                              className="first-child underline "
                              style={{ color: "red" }}
                              onClick={() => {
                                // this.setState({ isCustomerModelOpen: true, customerId: customer.customerId })
                                this.props.navigateFunction(
                                  `/billing/customer/customer-detail/${customer.customerId}/general`
                                );
                              }}
                            >
                              {_.get(customer, "displayName", "")}{" "}
                              <img className="icon" src={flagRed} alt="" />
                            </td>
                          ) : (
                            <td
                              className="first-child underline "
                              onClick={() => {
                                // this.setState({ isCustomerModelOpen: true, customerId: customer.customerId },()=>{this.assignUrl(`/billing/customer/customer-detail/${customer.customerId}/general`)})
                                this.props.navigateFunction(
                                  `/billing/customer/customer-detail/${customer.customerId}/general`
                                );
                              }}
                            >
                              {_.get(customer, "displayName", "")}
                            </td>
                          )}
                          <td>{customer?.phone}</td>
                          <td>{customer?.billingEmailAddress}</td>
                          <td>{customer?.billingContactName}</td>
                          <td>{this.numberWithCommas(customer?.balance)}</td>
                          <td className="last-child">
                            {
                              customer.balance > 0 && 
                             <Dropdown
                              overlay={() => this.menu(index)}
                              trigger={["click"]}
                              overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                            >
                              <button onClick={() => this.setCustomerTotalBalance(index)} className="btn ant-dropdown-link">
                                <MoreIcon />
                              </button>
                             </Dropdown>
                            }
                            
                          </td>
                        </tr>
                      );
                    }):(<tr><td colSpan={5} className="w-100 h-100"><div className="empty__section"><Spin spinning indicator={antIcon} /></div></td></tr>)}
                  {/* <tr>
                    <td className="first-child underline">
                      Worley Construction
                    </td>
                    <td>(860)388-1687</td>
                    <td>hello@worley.com</td>
                    <td>Jessica Worley</td>
                    <td>$400.00</td>
                    <td className="last-child">
                      <Dropdown
                        overlay={() => this.menu()}
                        trigger={["click"]}
                        overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                      >
                        <button className="btn ant-dropdown-link">
                          <MoreIcon />
                        </button>
                      </Dropdown>
                    </td>
                  </tr> */}
                </tbody>
              </table>
              </Spin>
              <Pagination
                className="text-center"
                onChange={this.onChangePage}
                hideOnSinglePage
                current={this.state.currentPage}
                total={this.state.totalItems}
                pageSize={this.state.limit}
                showSizeChanger={false}
              />
              {this.state.isCustomerModelOpen && (
                <CustomerDetailComponent
                  syncPostedOrder={this.props.syncPostedOrder}
                  customerId={this.state.customerId}
                  getMe={this.props.getMe}
                  fetchCustomer={this.props.fetchCustomer}
                  changeUrl={this.assignUrl}
                  closeModel={this.closeCustomerDetatilModel}
                  updateDriver={this.props.updateDriver}
                  updateTruck={this.props.updateTruck}
                  deletePaymentMethod={this.props.deletePaymentMethod}
                  defaultUrl={this.state.defaultUrl}
                  getInvoiceDeliveryByIdData={
                    this.props.getInvoiceDeliveryByIdData
                  }
                  getRecivingPaymentInvoices={
                    this.props.getRecivingPaymentInvoices
                  }
                  sendInvoiceEmail={this.props.sendInvoiceEmail}
                  printByDropDown={this.state.isPrintRecipt}
                  uploadImage={this.props.uploadImage}
                  updateInvoiceOrder={this.props.updateInvoiceOrder}
                  updateInvoicDetail={this.props.updateInvoicDetail}
                  getInvoiceDetalByInvoiceId={
                    this.props.getInvoiceDetalByInvoiceId
                  }
                  getCustomercreditsForCustomers={
                    this.props.getCustomercreditsForCustomers
                  }
                  getReceivePaymentForCustomers={
                    this.props.getReceivePaymentForCustomers
                  }
                  customerInvoicesByCustomerId={
                    this.props.customerInvoicesByCustomerId
                  }
                  getOrderInvoicePdf={this.props.getOrderInvoicePdf}
                  updateTruckWithIndex={this.props.updateTruckWithIndex}
                  {...this.props}
                />
              )}
              {this.state.createStatementPopup &&
                <CreateStatementComponent
                  customerId={this.state.createStatementCustomerId}
                  customer={this.state.customer}
                  onClose={() => {
                    this.setState({ createStatementPopup: false })
                  }}
                  customerOpenBalanceInvoicesByCustomerId={this.props.customerOpenBalanceInvoicesByCustomerId}
                  getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
                  sendInvoiceEmail={this.props.sendInvoiceEmail}
                  printByDropDown={this.state.isPrintRecipt}
                  getCompanyById={this.props.getCompanyById}
                  generateStatementPdf={this.props.generateStatementPdf}
                  statementEmail={this.props.statementEmail}
                />

              }
            </div>
          </div>
        </section>
      </div>
    );
  }
}
