import { connect } from 'react-redux'
import PrivacyPolicyComponent from './component'

const PrivacyPolicyContainer = connect(
  // Map state to props
  state => ({
  }),
  {
  }
)(PrivacyPolicyComponent)

export default PrivacyPolicyContainer
