import { connect } from 'react-redux'
import { getMe, uploadImage, fetchMe ,updateUserToken} from "../../store/user/duck";
import { createOrder } from "../../store/outboundactivity/duck";
import {customerInvoicesByCustomerId} from "../../store/Billing/duck"
import { createDriver, driverDetails } from "../../store/driver/duck";
import { fetchContainers } from '../../store/container/duck';
import {getPricingTierById} from "../../store/Pricing_tier/duck"
import { 
  fetchCustomers, 
  fetchDriversByCustomerId, 
  fetchTrucksByCustomerId,
  fetchTrucksByCompanyId,
  createProject,
  fetchProjects
} from '../../store/customer/duck'
import CreateOrderComponent from './component'

const CreateOrderContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateUserToken,
    getPricingTierById,
    uploadImage,
    fetchCustomers,
    fetchDriversByCustomerId,
    fetchTrucksByCustomerId,
    fetchTrucksByCompanyId,
    createOrder,
    createDriver,
    fetchMe,
    driverDetails,
    fetchContainers,
    customerInvoicesByCustomerId,
    createProject,
    fetchProjects
  }
)(CreateOrderComponent);

export default CreateOrderContainer
