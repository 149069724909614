import React, { Component } from "react";
import Client from "../../utils/client";
import Logger from "../../utils/logger";
// import { Link } from "react-router-dom";
// import { CloseIcon } from "../../components/icons";
import { Spin } from "antd";
import Cart from "./partials/cart";
import "./styles.scss";

const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

class TerminalConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discoveredReaders: [],
      discoverReaders: false,
      showCart: false,
      workFlowInProgress: null,
      chargeAmount: props.totalAmount,
      itemDescription: "Red t-shirt",
      taxAmount: 0,
      currency: "usd",
      isPaymentSuccessfull: false,
      paymentProgress: false
    };
  }

  componentDidMount() {
    this.initializeBackendClientAndTerminal(HOSTNAME);
    this.discoverReaders();
  }

  isWorkflowDisabled = () => this.state.cancelablePayment || this.state.workFlowInProgress;

  updateChargeAmount = amount => this.setState({ chargeAmount: parseInt(amount, 10) });

  updateItemDescription = description => this.setState({ itemDescription: description });

  updateTaxAmount = amount => this.setState({ taxAmount: parseInt(amount, 10) });

  updateCurrency = currency => this.setState({ currency: currency });

  initializeBackendClientAndTerminal(url) {
    // 1a. Initialize Client class, which communicates with the example terminal backend
    this.client = new Client(url);
    // 1b. Initialize the StripeTerminal object
    this.terminal = window.StripeTerminal.create({
      // 1c. Create a callback that retrieves a new ConnectionToken from the example backend
      onFetchConnectionToken: async () => {
        let connectionTokenResult = await this.client.createConnectionToken();
        return connectionTokenResult.secret;
      },
      // 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
      // You can use this callback to alert your user that the reader is no longer connected and will need to be reconnected.
      onUnexpectedReaderDisconnect: Logger.tracedFn(
        "onUnexpectedReaderDisconnect",
        "https://stripe.com/docs/terminal/js-api-reference#stripeterminal-create",
        () => {
          alert("Unexpected disconnect from the reader");
          this.setState({
            connectionStatus: "not_connected",
            reader: null
          });
        }
      ),
      // 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
      // You can use this callback to update your UI with the reader's connection status.
      onConnectionStatusChange: Logger.tracedFn(
        "onConnectionStatusChange",
        "https://stripe.com/docs/terminal/js-api-reference#stripeterminal-create",
        ev => {
          this.setState({ connectionStatus: ev.status, reader: null });
        }
      )
    });
  }
  // 2. Discover and connect to a reader.
  discoverReaders = async () => {
    this.setState({
      discoveryWasCancelled: false,
      discoverReaders: true
    });
    // 2a. Discover registered readers to connect to.
    const discoverResult = await this.terminal.discoverReaders();
    let simulatedResults = [];
    if (discoverResult.discoveredReaders.length === 0) {
      simulatedResults = await this.connectToSimulator();
    }
    if (discoverResult.error) {
      // console.log("Failed to discover: ", discoverResult.error);
      return discoverResult.error;
    } else {
      const discoveredReaders = [...discoverResult.discoveredReaders, ...simulatedResults];
      await this.connectToReader(discoveredReaders[0]);
      if (this.state.discoveryWasCancelled) return;
      this.setState({
        discoveredReaders
      });
      return discoverResult.discoveredReaders;
    }
  };

  runWorkflow = async (workflowName, workflowFn) => {
    this.setState({
      workFlowInProgress: workflowName
    });
    try {
      await workflowFn();
    } finally {
      this.setState({
        workFlowInProgress: null
      });
    }
  };

  updateLineItems = async () => {
    // 3a. Update the reader display to show cart contents to the customer
    await this.terminal.setReaderDisplay({
      type: "cart",
      cart: {
        line_items: [
          {
            description: this.state.itemDescription,
            amount: this.state.chargeAmount,
            quantity: 1
          }
        ],
        tax: this.state.taxAmount,
        total: this.state.chargeAmount + this.state.taxAmount,
        currency: this.state.currency
      }
    });
    // console.log("Reader Display Updated!");
    return;
  };
  // 3b. Collect a card present payment
  collectCardPayment = async () => {
    const { customerId, orderId, updateOrder, fetchOrder, setSubmitting, closeTerminal } = this.props;
    this.setState({ paymentProgress: true });
    // We want to reuse the same PaymentIntent object in the case of declined charges, so we
    // store the pending PaymentIntent's secret until the payment is complete.
    if (!this.pendingPaymentIntentSecret) {
      try {
        let createIntentResponse = await this.client.createPaymentIntent({
          amount: this.state.chargeAmount + this.state.taxAmount,
          currency: this.state.currency,
          description: "Test Charge",
          customerId
        });
        this.pendingPaymentIntentSecret = createIntentResponse.client_secret;
      } catch (e) {
        // Suppress backend errors since they will be shown in logs
        return;
      }
    }
    // Read a card from the customer
    const paymentMethodPromise = this.terminal.collectPaymentMethod(this.pendingPaymentIntentSecret);
    this.setState({ cancelablePayment: true });
    const result = await paymentMethodPromise;
    if (result.error) {
      // console.log("Collect payment method failed:", result.error.message);
    } else {
      const confirmResult = await this.terminal.processPayment(result.paymentIntent);
      // At this stage, the payment can no longer be canceled because we've sent the request to the network.
      this.setState({ cancelablePayment: false });
      if (confirmResult.error) {
        // console.log("payment error", confirmResult);
        // alert(`Confirm failed: ${confirmResult.error.message}`);
      } else if (confirmResult.paymentIntent) {
        try {
          // Capture the PaymentIntent from your backend client and mark the payment as complete
          let captureResult = await this.client.capturePaymentIntent({
            paymentIntentId: confirmResult.paymentIntent.id
          });
          this.pendingPaymentIntentSecret = null;
          // console.log("Payment Successful!");
          await updateOrder({ id: orderId, isPaymentDone: true });
          // this.setState({ isPaymentSuccessfull: true, paymentProgress: false }, () => {
            fetchOrder();
            setSubmitting(false)
            closeTerminal();
          // });
          return captureResult;
        } catch (e) {
          // Suppress backend errors since they will be shown in logs
          return;
        }
      }
    }
  };

  connectToSimulator = async () => {
    this.setState({ discoverReaders: true });
    const simulatedResult = await this.terminal.discoverReaders({
      simulated: true
    });
    return simulatedResult.discoveredReaders;
  };

  connectToReader = async selectedReader => {
    // console.log('selectedReader', selectedReader)
    // 2b. Connect to a discovered reader.
    const connectResult = await this.terminal.connectReader(selectedReader);
    if (connectResult.error) {
      // console.log("Failed to connect:", connectResult.error);
    } else {
      this.collectCardPayment()
      // this.setState({
      //   status: "workflows",
      //   discoverReaders: false,
      //   showCart: true
      //   // discoveredReaders: [connectResult.reader]
      // });
      // return connectResult;
    }
  };

  render() {
    const { discoveredReaders, discoverReaders, showCart, isPaymentSuccessfull, paymentProgress } = this.state;
    return (
      <div>
        <div className="update-profile-container">
          {/* <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Terminal Information</div>
            <div className="head-section-cta">
              <Link to={"/dashboard"} className="btn btn-link btn-back">
                <CloseIcon />
              </Link>
            </div>
          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="terminal__body">
                  {isPaymentSuccessfull && <p className="text-center text-success">Payment successfull!!!</p>}
                  {/* <ul className="select_driver-list">
                    {discoveredReaders.map((red) => {
                      return (
                        <li
                          className="active"
                          key={red.id}
                          onClick={e => {
                            e.preventDefault();
                            this.connectToReader(red)
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <p className="driver__name">
                              Serial # {red.id}: {red.label}
                            </p>
                          </div>
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            <input type="checkbox" className="custom-checkbox--input" />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul> */}
                  {discoveredReaders.length === 0 &&
                    (discoverReaders ? (
                      <Spin />
                    ) : (
                      <>
                        <p className="process_text">
                          Click the button below to start the process to connect your terminal
                        </p>
                        {/* <div className="row"> */}
                        <div className="col-md-12">
                          <button onClick={this.discoverReaders} className="btn btn-dark btn-lg w-100">
                            Discover Terminal
                          </button>
                        </div>
                        {/* <div className="col-md-6">
                            <button onClick={this.connectToSimulator} className="btn btn-dark btn-lg w-100">
                              Use Simulator
                            </button>
                          </div> */}
                        {/* </div> */}
                      </>
                    ))}
                  {showCart && (
                    <Cart
                      workFlowDisabled={this.isWorkflowDisabled()}
                      paymentProgress={paymentProgress}
                      itemDescription={this.state.itemDescription}
                      chargeAmount={this.state.chargeAmount}
                      taxAmount={this.state.taxAmount}
                      currency={this.state.currency}
                      onClickUpdateLineItems={() => this.runWorkflow("updateLineItems", this.updateLineItems)}
                      onClickCollectCardPayments={() => this.runWorkflow("collectPayment", this.collectCardPayment)}
                      onChangeCurrency={currency => this.updateCurrency(currency)}
                      onChangeChargeAmount={amount => this.updateChargeAmount(amount)}
                      onChangeTaxAmount={amount => this.updateTaxAmount(amount)}
                      onChangeItemDescription={description => this.updateItemDescription(description)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TerminalConfiguration;
