// import Rx from 'rxjs/Rx'
// import {Observable} from 'rxjs'
import { Record } from "immutable";
import { assign } from "lodash";
// import Cookies from 'universal-cookie'
import { INIT } from "../../constants/phase";

import { combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

export const GET_PRICING_TIER = "empire/pricing/GET_SCALE_BY_PRICING_TIER";
export const CREATE_PRICING_TIER = "empire/pricing/CREATE_PRICING_TIER";
export const EDIT_PRICING_TIER = "empire/pricing/EDIT_PRICING_TIER";
export const EDIT_PRICING_TIER_BY_ID = "empire/pricing/EDIT_PRICING_TIER_BY_ID";
export const GET_CUSTOMER_BY_PRICING = "empire/pricing/GET_CUSTOMER_BY_PRICING";
export const GET_PRICING_TIERDETAILS = "empire/pricing/GET_PRICING_TIERDETAILS";
export const GET_PRICING_TIER_BY_ID = "empire/pricing/GET_PRICING_TIER_BY_ID";
export const ADD_CUSTOM_FIXED_ITEMS = "empire/pricing/ADD_CUSTOM_FIXED_ITEMS";
export const GET_FIXED_ITEMS = "empire/pricing/GET_FIXED_ITEMS";
const InitialStateInterface = {
  data: {},
  token: null,
  phase: INIT,
  userPhase: INIT,
  user: null,
  error: null,
  message: null,
  loginPhase: INIT,
  sendVerificationCodePhase: INIT,
  sendVerificationCodeData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getPricingTier = (payload) => {
  return {
    type: GET_PRICING_TIER,
    payload: api.getPricingTier(payload),
  };
};

export const getPricingTierById = (payload) => {
  return {
    type: GET_PRICING_TIER_BY_ID,
    payload: api.getPricingTierById(payload),
  };
};

export const createPricingTier = (payload) => {
  return {
    type: CREATE_PRICING_TIER,
    payload: api.createPricingTier(payload),
  };
};

export const editPricingTier = (payload) => {
  return {
    type: EDIT_PRICING_TIER,
    payload: api.editPricingTier(payload),
  };
};

export const getCustomerByPricing = (payload) => {
  return {
    type: GET_CUSTOMER_BY_PRICING,
    payload: api.getCustomerByPricing(payload),
  };
};

export const getPricingTierDetails = (payload) => {
  return {
    type: GET_PRICING_TIERDETAILS,
    payload: api.getPricingTierDetails(payload),
  };
};

export const addFixedItems = (payload) => {
  return {
    type: ADD_CUSTOM_FIXED_ITEMS,
    payload: api.addFixedItems(payload),
  };
};
export const getFixedItems = (payload) => {
  return {
    type: GET_FIXED_ITEMS,
    payload: api.getFixedItems(payload),
  };
};






/***********************************
 * Epics
 ***********************************/

export const pricingtierEpic = combineEpics();
