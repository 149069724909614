import React, { PureComponent } from "react";
import ReactModal from "react-modal";
import { CloseIcon } from "../../../components/icons";


const customStyles = {
  content: {
    alignItems: 'center'
  },
};

class DeleteUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deletingCard: false
    }
  }

  deleteUser = async () => {
    const { paymentMethodId, deletePaymentMethod, closeModal, closeModalAfterDelete } = this.props;
    this.setState({ deletingCard: true })
    const {
      value: { status }
    } = await deletePaymentMethod(paymentMethodId);
    this.setState({ deletingCard: false })
    if (status) {
      closeModalAfterDelete();
    } else {
      closeModal();
    }
  };
 
  render() {
    const { isDeleteModal } = this.props;
    return (
      <ReactModal ariaHideApp={false} isOpen={isDeleteModal}
      style={customStyles}
      >
        <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Confirmation</h5>
            <button type="button" className="btn react-modal-close" onClick={this.props.closeModal}>
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <div className="row">
              <div className="col-md-12">
                <p><strong>Are you sure you want to delete this card?</strong></p>
              </div>
              <div className="col-md-12 mb-3">
              Deleting this card will remove it from the customer's account, and it cannot be undone. Please confirm your action by clicking the "Delete" button below.
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button disabled={this.state.deletingCard} onClick={this.deleteUser} className="btn btn-red btn-sm delete-btn">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default DeleteUser;
