import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const createOutboundActivity = data => {
  return fetch(`${HOSTNAME}/outbound-activity`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchOutboundActivities = ({ type, searchText, startDate, endDate, skip, limit, dateType , sortOrder}) => {
  return fetch(
    `${HOSTNAME}/outbound-activities?type=${type}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&skip=${skip}&limit=${limit}&dateType=${dateType}&sortOrder=${JSON.stringify(sortOrder)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteOutboundActivity = id => {
  return fetch(`${HOSTNAME}/outbound-activity/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteCompletedOutboundActivity = id => {
  return fetch(`${HOSTNAME}/completed-outbound-activity/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getOutboundMaterials = ()  => {
  return fetch(`${HOSTNAME}/get-outbound-activities-materials`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const postOutboundMaterials = payload => {
  return fetch(`${HOSTNAME}/post-outbound-activity-material`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const updateOutboundActivity = ({ _id, ...rest }) => {
  return fetch(`${HOSTNAME}/outbound-activity/${_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(rest)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createOrder = payload => {
  return fetch(`${HOSTNAME}/order`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createCharge = payload => {
  return fetch(`${HOSTNAME}/create-charge`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
