import { connect } from 'react-redux'
import { getMe, fetchCompanyUsers ,updateUserToken,fetchMe} from '../../store/user/duck'
import { fetchCustomers } from '../../store/customer/duck'
import { fetchCompanyDrivers } from '../../store/company/duck'
import { getInboundOutboundMaterials } from "../../store/report/duck";
import { fetchReports, exportOrder, generatePdf, generateMultiplePdf } from "../../store/order/duck";
import ReportsComponent from './component'

const ReportsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchReports,
    fetchCustomers,
    fetchMe,
    fetchCompanyUsers,
    fetchCompanyDrivers,
    updateUserToken,
    exportOrder,
    generatePdf,
    generateMultiplePdf,
    getInboundOutboundMaterials
  }
)(ReportsComponent);

export default ReportsContainer
