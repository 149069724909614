/** @format */

import React, { PureComponent } from "react";
import TopNavigation from "../TopNavigation/container";
import SidebarNavigation from "../SidebarNavigation/component";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { CloseIcon } from "../../components/icons";
import moment from "moment-timezone";
import _ from "lodash";
import "./styles.scss";
import { Drawer, message, Spin, Switch } from "antd";
import OrderBreakDown from "./partials/oderBreakdown";
import PaymentOptions from "./partials/paymentOptions";
import SustainabilityInformationComponent from "./partials/SustainabilityInformation";
import NewDriver from "../CreateOrder/partials/new-driver";
import CreateDriver from "../CreateOrder/partials/create-driver";
import { driverSchema } from "../../utils/validations";
import { Formik } from "formik";
import AddCardComponent from "../payabli/addNewCard";
import CreateCustomer from "../CreateCustomer/container";
import NumberFormat from "react-number-format";
import { LoadingOutlined } from '@ant-design/icons';
import { DebounceInput } from 'react-debounce-input';
import CreateProject from "../CreateOrder/partials/create-projects";
import {truckTypes,round2decimal } from "../../constants/common";
import { numberFormat } from "../../constants/common";
import { responseCode } from "../../utils/responseCode";

const limit = 100;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class ChargeByYardComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isDriver: false,
      companySearchText: "",
      sortOrder: { companyName: 1 },
      order: {},
      customers: [],
      customer: null,
      selectedCustomer: null,
      selectedDriver: null,
      driverSearchText: "",
      typingTimeout: 0,
      drivers: [],
      driver: null,
      datetime: "",
      dueBalance : 0,
      isTruckAvaliable: true,
      isTruck: false,
      trucks: [],
      project:{},
      responseMessage: "",
      trck: null,
      selectedTruck: null,
      truckSearchText: "",
      pricingTierList: [],
      // pricingTier: {},
      selectedchargeByYard: 0,
      selectedchargeByYardUnit: 0,
      currentDate: new Date(),
      cityOfOrigin: "",
      selectedChargeByWeightInTone: 0,
      selectedChargeByWeightInToneUnit: 0,
      isCameraOn: false,
      isTruckNotAvaliableCameraOn: false,
      Notes: "",
      saveNote: "",
      isEditNotes: false,
      isSplitPaymentOption: false,
      isTerminalTabActive: false,
      createDriverSidebar: false,
      isLicenseCamera: false,
      totalAmountDue: 0,
      isSustainabilityInformationModelOpen: false,
      completeOrderButtonDisable : false,
      sustainabilityInfo: {
        selectedFields: [],
        displayName: "",
        address: "",
        containersize: "",
      },

      signatureData: "",
      pictureOfTruck: "",
      pictureOfLoad: "",
      checkNumber: "",
      driverInitialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        state: "New York",
        licenseNumber: "",
        customerId: "",
      },
      createDriverForm: false,
      rotation: 0,
      licensePlate: "",
      addSelectedFixedItems: [],
      addSelectedChargeByWeight: [],
      paymentMethod: "COD",
      isAddNewCard: false,
      pleaseWait: false,
      pleaseWaitPaymentLater: false,
      activeTab: "cash",
      isDisable: true,
      forntCheckImage: "",
      backCheckImage: "",
      checkDetails: {
        checkNumber: "",
        frontCheckImage: "",
        backCheckImage: "",
      },
      pricingTier: "",
      yardadge: 0,
      tonnage: 0,
      orderDetails: {},
      pricingTierName: "",
      customerData: {},
      paybliEnable: false,
      payabliCustomerMethod: {},
      createCustomerModelOpen: false,
      isCardModalOpen: false,
      reRender: false,
      truckNumber: "",
      truckType: "Box Truck",
      payMethod: "",
      isCharged: false,
      pricingTierId: "",
      paybliDetails: {},
      totalWithFee:{ 
        amount:0,
        fee:0,
        total:0
      },
      customerPayabliData:{},
      selectedTruckSearch:null,
      selectedCustomerSearch:null,
      overAllData:[],
      data_type: 'customer',
      SearchText:"",
      showSearch:false,
      isLoading:false,
      terminalPaymentStatus: false,
      terminalStatus: false,
      paymentData: {},
      signatureUrl: "",
      isEnvironmentalLoadFee:false,
      is_open_pdf_when_an_order_complete: false,
      isSalesTax: false,
      saleTaxFeeAmount: "",
      errisSalesTax: "",
      isFuelSurchardeFee:false,
      fuelSurchardeFeeAmount:0,
      environmentalLoadFeeAmount:0,
      totalOrderAmmWithFee:{ 
        amount:0,
        fee:0,
        total:0
      },
      isProjectDrawerOpen:false,
      isCreateNewProject:false,
      projectsData:[],
      selectedProjectData:{},
      projectSearchText:"",
      projectSpinning:false,
      // create_payment_later_order: is_payment_later => this.props?.createOrder(is_payment_later),
      orderId: this?.props?.match?.params?.orderId ?? null,
      errorMessage:"",
      // paymentLaterError:"",
      clickedCompleteButton: false,
      scaleItemsErrors:[],
      fixedItemsErrors:[],
      showErrors:false,
      order_id:"",
      isPayabliIntegration: false,
     paidAmount: 0,
      selectedSplitPaymentMethods: [],
      alreadyExistSplitPayment:[],
      alreadyExistselectedSplitPaymentMethods:[],
      splitPayments: [
        {
          paymentMethod: "credit-card",
          paymentAmount: null,
          isSelectedPayment: false,
          uniqueId:"",
          handlingFee: null
        },
        {
          paymentMethod: "cash",
          paymentAmount: null,
          isSelectedPayment: false
        },
        {
          paymentMethod: "check",
          paymentAmount: null,
          isSelectedPayment: false
        },
        {
          paymentMethod: "terminal",
          paymentAmount: null,
          uniqueId:"",
          isSelectedPayment: false,
          remaingSplitAmount: null,
          paybliOutputDetails: {},
          ticket_number: ""
        },
        {
          paymentMethod: "credit-memo",
          paymentAmount: null,
          isSelectedPayment: false,
          creditMemoIds: []
        },
      ],
      terminalPaymentInformation:{},
      customerCredits: [],
      disablePaymentLater: false,
      inProgressCreditCardPayment:false,
      inProgressTerminalPayment:false,
      isCreditCardPaymentDone:"",
      isCalculteOrder:false,
      isRoundOffAmount:false,
      totalHandlingFeeAmount:0,
      handlingFeeForSplit:0,
      splitpaymentCalculationAmount:0,
      selectedCreditMemos:{},
      isActualRoundOffAmount:false,
      ticket_number:"",
      isFailedTransaction: false,
      withOutFeeAndRoundOffTotal:0,
      handleCompleteOrderButtonForTerminal:false
    };
    this.payabliRef = React.createRef();
  }

  componentDidMount = () => {
    // let data = moment.tz.guess();
    // let datetime = new Date().toLocaleString("en-US", { timeZone: data });
    window.scrollTo(0, 0);
    this.props.getMe();
    this.fetchCustomers();
    this.state?.orderId && this.fetchOrder();
    (this.props?.user?.company ? this.props.user?.company?.isPayabliIntegration : true) && this.getPaybliIntegration();
    document.title = "Charge By yard | Weighworks";
    const date = new Date();

    const currentdate = date.toLocaleString("en-US", {
      timeZone: "America/New_York",
    });
    this.setState({ currentDate: currentdate });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.user !== this.props.user) {
      this.setState({ 
        // isEnvironmentalLoadFee:this.props.user?.company?.isEnvironmentalLoadFee,
        is_open_pdf_when_an_order_complete: this.props.user?.company?.is_open_pdf_when_an_order_complete ?? false,
        // isFuelSurchardeFee:this.props.user?.company?.isFuelSurchardeFee,
        environmentalLoadFeeAmount:this.props.user?.company?.environmentalLoadFeeAmount,
        fuelSurchardeFeeAmount:this.props.user?.company?.fuelSurchardeFeeAmount,
        saleTaxFeeAmount:this.state.saleTaxFeeAmount!== "" ? this.state.saleTaxFeeAmount : this.props.user?.company?.salesTaxFeeAmount,
        isRoundOffAmount:this.props?.user?.company?.isRoundOffAmount
      })
    }
  }

  getCustomerCredits = async(customerId) => {
    const {value: { data }} = await this.props.getCustomercredits({ customerId, skip: 0, limit: 0 })
    this.setState({ customerCredits: data.customercredits })
  }
  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };

  calcFee(amount, currency) {
    const _fee = this.props.user?.company?.chargeStripeCardFeesAmount ? this.props.user?.company?.chargeStripeCardFeesAmount : "3"
    let fee = 0
    amount = this.round2decimal(amount);
    fee =
      amount * (this.round2decimal(_fee) / 100) 
    fee = this.round2decimal(fee);
    const total = this.round2decimal(amount + fee);
  
      return {
      amount: this.round2decimal(amount),
      fee: this.round2decimal(fee),
      total: this.round2decimal(total),
    };
  }

  fetchProject=async()=>{
    this.setState({projectSpinning:true})
     const payload={
      id: this.state.customer?._id,
      searchText:this.state.projectSearchText,
     }
    const {
      value: {
        data
      }
    } = await this.props.fetchProjects(payload);
    this.setState({ projectsData:data});
    this.setState({projectSpinning:false})
  }

  getPaybliIntegration = async () => {
    const {
      value: { status, data },
    } = await this.props.getPaybliIntegration();
    if (status) {
      this.setState({ paybliEnable: true,
        paybliDetails: data});
    }
  };

  fetchCustomerDetail = async () => {
    const {
      value: {
        data: { customer },
      },
    } = await this.props.fetchCustomer(this.state.customer?.customerId);
    this.setState({ customerData: {...customer} },()=>{
      let splitPayment=this.state.splitPayments
      let selectedSplitPaymentMethods=[...this.state.selectedSplitPaymentMethods]
      this.state.splitPayments.map((payment,index)=>{
        if(payment.paymentMethod === customer.paymentType){
          selectedSplitPaymentMethods.push(payment.paymentMethod)
          splitPayment[index].isSelectedPayment = true
        }
        return null
      })
      this.setState({splitPayments:splitPayment,selectedSplitPaymentMethods:selectedSplitPaymentMethods})
      this.forceUpdate()
    });
  };

  fetchCustomers = async () => {
    const { fetchCustomers } = this.props;
    const { companySearchText, sortOrder } = this.state;
    const {
      value: {
        data: { customers },
      },
    } = await fetchCustomers({
      searchText: companySearchText,
      status: 1,
      sortOrder,
      limit,
      isDisableCount: true
    });
    this.setState({ customers ,isLoading:false });
  };

  fetchOrder = async () => {
    const { value: { data } } = await this.props?.fetchOrder(this.state?.orderId)
    let selectedSplitPaymentMethods = []
    let availableAmount = null
    let paidAmount = 0
    let alreadyExistselectedSplitPaymentMethods=[]
    let splitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
    if (data?.splitPayment && data?.splitPayment.length > 0) {
      data.splitPayment.map((payment) => {
        paidAmount += payment.amount
        alreadyExistselectedSplitPaymentMethods.push(payment.paymentType)
        splitPayment.map((loaclPayment, index) => {
          if (loaclPayment.paymentMethod === payment.paymentType) {
            if (payment.paymentType === "credit-card" || payment.paymentType === "terminal") {
              splitPayment[index] = { paymentMethod: payment.paymentType, paymentAmount: Number((Number(payment.amount) - Number(payment.handlingFee)).toFixed(2)), isSelectedPayment: true }
              selectedSplitPaymentMethods.push(payment.paymentType)
            } else {
              splitPayment[index] = { paymentMethod: payment.paymentType, paymentAmount: payment.amount, isSelectedPayment: true }
              selectedSplitPaymentMethods.push(payment.paymentType)
            }
          }
          return null
        })
        availableAmount = availableAmount + payment.amount
        return null
      })
    }
    this.setState({
      order: data,
      paidAmount: paidAmount,
      customer: {...data?.customer},
      selectedCustomer: {...data?.customer, company: data?.company},
      project: data?.projects,
      driver: data?.driver,
      truck: data?.truck,
      order_id:data._id,
      // pricingTier: value?.data?.pricingTierObj,
      saveNote: data?.notes,
      Notes: data?.notes,
      cityOfOrigin: data?.cityOfOrigin,
      pricingTierName: data?.pricingTier,
      pricingTier: data?.pricingTierId,
      pricingTierId: data?.pricingTierId,
      saleTaxFeeAmount: data?.salesTaxPercentageForOrder,
      chargeByweightItemsArray: data?.orderBreakDownItems?.chargeByWeight,
      addSelectedFixedItems: data?.additionalItems,
      paymentMethod:"",
      totalAmountDue:this.converTwoDecimalValue(data?.orderBreakDownItems?.totalAmountDue - data?.stripeCardFees),
      splitpaymentCalculationAmount:this.converTwoDecimalValue(data?.orderBreakDownItems?.totalAmountDue - data?.stripeCardFees),
      orderBreakDown:{
        ...data?.orderBreakDownItems,
        totalAmountDue:this.converTwoDecimalValue(data?.orderBreakDownItems?.totalAmountDue),
        totalOrderAmout: this.converTwoDecimalValue(data?.orderBreakDownItems?.totalOrderAmout - data?.stripeCardFees),
        originalAmount: data?.orderBreakDownItems?.originalAmount ,
      },
      sustainabilityInfo: data?.sustainabilityInfo?.[0],
      pictureOfLoad: data?.loadImageUrl,
      signatureUrl: data?.driverSignature,
      yardadge: data?.yardage,
      tonnage: data?.tonnage,
      addSelectedChargeByWeight: data?.orderBreakDownItems?.chargeByWeight,
      isEnvironmentalLoadFee: data?.isEnvironmentalLoadFee,
      isFuelSurchardeFee: data?.isFuelSurchardeFee,
      isSalesTax: data?.isSalesTax,
      alreadyExistSplitPayment:data?.splitPayment,
      alreadyExistselectedSplitPaymentMethods:alreadyExistselectedSplitPaymentMethods,
      totalHandlingFeeAmount: this.converTwoDecimalValue(this.state.totalHandlingFeeAmount + data?.stripeCardFees),
      isSplitPaymentOption : data?.isSplitPayment,
      isCalculteOrder:true
    })
    this.assignOrderDetail({
      ...data?.orderBreakDownItems,
      totalAmountDue: data?.orderBreakDownItems?.totalAmountDue - data?.stripeCardFees,
      totalOrderAmout: data?.orderBreakDownItems?.totalOrderAmout - data?.stripeCardFees,
    })
    this.getCustomerPayment(data?.customer?.payabliCustomer?.customerId)
    this.fetchDrivers()
    this.fetchProject()
    this.fetchTrucks()
    this.isDelinquentAccount()
    this.fetchCustomerDetail()
    this.getCustomerCredits( data && data.customer && data.customer._id);
  }

  throughError = (error,isPaymentLater,showErrors) => {
    const inputs = document.querySelectorAll('input[required], textarea[required], select[required]');
    let emptyField = null;
    for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].value === "" && inputs[i].name !== "overAllSearch") {
            emptyField = inputs[i];
            break;
        }
    }

    if (emptyField) {
        emptyField.focus();
    }
    if(isPaymentLater){
      this.setState({errorMessage:error,pleaseWaitPaymentLater:false,showErrors})
    }else{
      this.setState({errorMessage:error,pleaseWait:false,showErrors})
    }
  }

  onTakePhoto = (dataURI, image, image2) => {
    if (image === "truckIsAvaliable") {
      this.setState({ isCameraOn: false, pictureOfLoad: dataURI });
    } else if (image === "plateImageUrl") {
      this.setState({ pictureOfTruck: dataURI });
      this.setState({ isTruckNotAvaliableCameraOn: false });
      this.extractText(dataURI);
    } else {
      this.setState({ licenseImageUrl: dataURI });
      this.setState({ isLicenseCamera: false });
    }
  }

  closeModel=()=>{
    this.setState({createCustomerModelOpen:false})
  }

  isDelinquentAccount = async () => {
    const payload = {
      customerId:this.state.customer._id,
      billingStatus: '',
    }
    const { value: { data: { allData } } } = await this.props.customerInvoicesByCustomerId(payload);
    this.setState({dueBalance : allData?.delinquentBalance})
  };
  
  dataURItoBlob = dataURI => {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  };

  extractText = async (dataURI) => {
    const blob = this.dataURItoBlob(dataURI);
    var formdata = new FormData();
    formdata.append("upload", blob);
    const {
      value: { status, data },
    } = await this.props.numberPlateDetails(formdata);
    if (status) {
      this.setState({ licensePlate: data?.results[0].plate });
    }
  };

 converTwoDecimalValue=(val)=>{
  return Number((Number(val)).toFixed(2))
 }
  assignOrderDetail = (payload) => {
    this.setState({ orderDetails: payload });
    const { originalAmount, isminimumPrice, totalAmountDue } = payload
    let totalWithFee = { amount: null, fee: null, total: null }
    // let totalOrderAmmWithFee = { amount: null, fee: null, total: null }
    // let totalWithFeeforCCAndTM = {}
    // let totalOrderAmmWithFeeforCCAndTM = {}
      // this.state.splitPayments.map((payment) => {
      //   if ((payment.isSelectedPayment && (payment.paymentType === "credit-card" || payment.paymentType === "terminal"))) {
      //     if (this.props.user?.company?.chargeStripeCardFees) {
      //      totalWithFeeforCCAndTM = this.calcFee(isminimumPrice ? totalAmountDue : originalAmount, "USD");
      //      totalOrderAmmWithFeeforCCAndTM = this.calcFee(totalOrderAmout, "USD");
      //     }else{
      //       totalWithFeeforCCAndTM =  { amount: isminimumPrice ? totalAmountDue : originalAmount, fee: 0, total: isminimumPrice ? totalAmountDue : originalAmount };
      //       totalOrderAmmWithFeeforCCAndTM = { amount: totalOrderAmout, fee: 0, total: totalOrderAmout }
      //     }
      //     totalWithFee.fee = totalWithFee.fee + totalWithFeeforCCAndTM.fee
      //     totalOrderAmmWithFee.fee = totalOrderAmmWithFee.fee + totalOrderAmmWithFeeforCCAndTM.fee
      //   } else {
      //     totalWithFee = { amount: isminimumPrice ? totalAmountDue : originalAmount, fee: null, total: isminimumPrice ? totalAmountDue : originalAmount }
      //     totalOrderAmmWithFee = { amount: totalOrderAmout, fee: null, total: totalOrderAmout }
      //   }
      //   return null
      // })
      totalWithFee.amount = isminimumPrice ? totalAmountDue : originalAmount
      totalWithFee.total = totalWithFee.amount + totalWithFee.fee
      // totalOrderAmmWithFee.amount = isminimumPrice ? totalAmountDue : originalAmount
      // totalOrderAmmWithFee.total = totalOrderAmmWithFee.amount + totalWithFee.fee
      // let totalWithFee =  this.calcFee(isminimumPrice ? totalAmountDue : originalAmount, "USD");
      // const totalOrderAmmWithFee = this.calcFee(totalOrderAmout, "USD");
      this.setState({
        totalWithFee: { amount: totalWithFee.amount, fee: totalWithFee.fee, total: totalWithFee.total},
        // totalOrderAmmWithFee
      })
  };

  assigncheckData = (data, type) => {
    let checkDetails = this.state.checkDetails;
  
    if (type === "checkNumber") {
      const truncatedData = data.slice(0, 12);
      this.setState({ checkDetails: { ...checkDetails, checkNumber: truncatedData } });
    }
    if (type === "frontCheckImage") {
      this.setState({
        checkDetails: { ...checkDetails, frontCheckImage: data },
      });
    }
    if (type === "backCheckImage") {
      this.setState({
        checkDetails: { ...checkDetails, backCheckImage: data },
      });
    }
  }
  
  assignSelectedCreditmemos = (payalod) =>{
    this.setState({selectedCreditMemos:payalod})
  }

  assignNewCard = (isCard) => {
    this.setState({ isAddNewCard: isCard });
  };
  assignYardage = (assignYardage) => {
    this.setState({ yardage: assignYardage });
  };
  assignTonnage = (asssigntonnage) => {
    this.setState({ tonnage: asssigntonnage });
  };
  activeTab = (activeTab, assignCustomerPaymentType) => {
    console.log(activeTab, 'activeTab')
    activeTab = activeTab === "creditCard" ? "credit-card" : activeTab
    if(activeTab !== "terminal"){
     this.setState({ activeTab: activeTab});
    }
    let splitPayment= _.clone(this.state.splitPayments)
    let selectedSplitPaymentMethods = _.clone(this.state.selectedSplitPaymentMethods)
    if(activeTab === "credit-card") {
      const { customerData } = this.state;
      if(assignCustomerPaymentType && customerData.paymentType === "terminal") {
        selectedSplitPaymentMethods = [customerData.paymentType]
      } else {
        selectedSplitPaymentMethods = [activeTab]
      } 
    } else {
      selectedSplitPaymentMethods = [activeTab]
    }
    splitPayment.map((payment,index) => {
      if(payment.paymentMethod === activeTab) {
          splitPayment[index].isSelectedPayment = true
          splitPayment[index].paymentAmount = this.state.withOutFeeAndRoundOffTotal
      } else {
       splitPayment[index].isSelectedPayment=false
       splitPayment[index].paymentAmount=null
      }
      return null 
    })
    
    this.setState({splitPayment:splitPayment,selectedSplitPaymentMethods:selectedSplitPaymentMethods,isCalculteOrder:true})
    // if (activeTab === "check") {
    //   this.setState({ isDisable: true });
    // }
  };

  handleCompanySearch = (event) => {
    this.setState({
      companySearchText: event.target.value.trimStart(),
      isLoading:true,
    }, () => this.fetchCustomers())
  };

  handleNote = async () => {
    this.setState({ saveNote: this.state.Notes, isEditNotes: false });
  };

  onCloseClick = async () => {
    this.setState({
      isSustainabilityInformationModelOpen: false,
      isAddNewCard: false,
    });
  };

  handleDriverSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      driverSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchDrivers();
      }, 200),
    });
  };

  handleProjectSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      projectSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchProject();
      }, 200),
    });
  };

  handleTruckSearch = (event) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      truckSearchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchTrucks();
      }, 200),
    });
  };

  assignNewCustomer = async (selectedCustomer,projects) =>{
    this.setState({
      selectedCustomer,
      isEnvironmentalLoadFee:  this.props.user?.company?.isEnvironmentalLoadFee && selectedCustomer?.isEnvironmentalLoadFee,
      isFuelSurchardeFee: this.props.user?.company?.isFuelSurchardeFee && selectedCustomer?.isFuelSurchardeFee
    })
    await new Promise(resolve => this.setState({}, resolve));
    this.assignCustomer("new")
    this.setState({ projectsData:projects,projectSpinning:false});
  }

  assignCustomer = async (status) => {
    const { selectedCustomer } = this.state;
    this.setState({
      isVisible: false,
      driver: null,
      isCompany: false,
      customer: null, 
      pricingTier: null, 
      truck:null,
      addSelectedFixedItems: [],
      addSelectedChargeByWeight: [],
      isSplitPaymentOption: false,
      selectedProjectData :{},
      project:{},
      isEnvironmentalLoadFee: this.props.user?.company?.isEnvironmentalLoadFee && selectedCustomer?.isEnvironmentalLoadFee,
      isFuelSurchardeFee: this.props.user?.company?.isFuelSurchardeFee && selectedCustomer?.isFuelSurchardeFee,
      drivers: [],
    });
    if(selectedCustomer?.paymentTerms ==="account"){
      this.setState({isRoundOffAmount: false})
    }
    else {
      this.setState({isRoundOffAmount: this.props.user?.company?.isRoundOffAmount && selectedCustomer?.paymentTerms !== "account" && this.state.activeTab === "cash"})
    }
    await new Promise(resolve => this.setState({}, resolve));
    this.setState(
      {
        isVisible: false,
        driver: null,
        isCompany: false,
        customer: selectedCustomer,
        pricingTier: selectedCustomer.pricingTier,
      },
      () => {
        if (selectedCustomer) {
          if(status !== "new"){
            this.fetchDrivers();
            this.fetchTrucks();
            this.isDelinquentAccount();
            this.fetchCustomerDetail();
            this.getCustomerPayment(
              this.state.customer?.payabliCustomer?.customerId
              );
              this.fetchProject()
            this.getCustomerCredits(this.state.customer?._id);
            }
        }
      }
    );
  };

  handleTerminalStatus = (status, data) => {
    if (status === "Success") {
      this.setState({ terminalPaymentStatus: true, terminalStatus: true, payabliInfo: data,handleCompleteOrderButtonForTerminal:true }, () => {
        this.forceUpdate()
      })
    } else {
      this.setState({ terminalPaymentStatus: false, terminalStatus: true, payabliInfo: data,handleCompleteOrderButtonForTerminal:false }, () => {
        this.forceUpdate()
      })
    }
  }

  handleCreditCardStatus = (status, data) => {
    if (status === "Success") {
      this.setState({ payabliInfo: data }, () => {
        this.forceUpdate()
      })
    } else {
      this.setState({  payabliInfo: data }, () => {
        this.forceUpdate()
      })
    }
  }


  
  assignTotalAmountDue = async (totalAmountDue,splitpaymentCalculationAmount) => {
    this.setState({ totalAmountDue: totalAmountDue,splitpaymentCalculationAmount:splitpaymentCalculationAmount});
  };
  
  assignSustainabilityInfo = async (info) => {
    this.setState({ sustainabilityInfo: info , completeOrderButtonDisable : false , errorMessage : "" });
  };

  assignSignature = async (signature) => {
    this.setState({ signatureData: signature });
    this.setState({ errorMessage: "" })
  };
  assignCityOfOrigin = async (cityOrigin) => {
    this.setState({ cityOfOrigin: cityOrigin });
  };
  assignFixedItem = async (additionalItem, chargeWeights) => {
    this.setState({
      addSelectedFixedItems: additionalItem,
      addSelectedChargeByWeight: chargeWeights,
    });
    if(!((additionalItem.length < 1 && chargeWeights.length < 1)
    || additionalItem.some(item => item.units === "" || item.fixedItem === "") 
  || chargeWeights.some(item => item.units === "" || item.fixedItem === ""))){
      this.setState({ errorMessage:"" })
  }
  };
  assignPaymentMethod = async (paymentTerm) => {
    this.setState({ paymentMethod: paymentTerm });
  };
  assignCard = async (cardId, paymethod) => {
    this.setState({ cardId: cardId, payMethod: paymethod });
  };

  fetchTrucks = async () => {
    const { fetchTrucksByCustomerId } = this.props;
    const { truckSearchText } = this.state;
    const {
      value: { data },
    } = await fetchTrucksByCustomerId({
      customerId: this.state.customer._id,
      searchText: truckSearchText,
    });
    this.setState({ trucks: data });
  };

  assignTruck = () => {
    const { selectedTruck } = this.state;
    this.setState({
      truck: selectedTruck,
      licensePlate: selectedTruck.plateNumber,
      isCompany: false,
      isVisible: false,
      isDriver: false,
      isTruck: false,
    });
  };

  assignDriver = () => {
    const { selectedDriver } = this.state;
    this.setState({
      driver: selectedDriver,
      truck: this.state?.truck?._id ? this.state?.truck: null,
      isCompany: false,
      isDriver: false,
      isVisible: false,
    });
  };

  assignProject = () => {
    const { selectedProjectData,selectedDriver } = this.state;
    
    this.setState({
      driver: selectedDriver,
      project:selectedProjectData,
      isProjectDrawerOpen:false,
      isCreateNewProject:false,
      truck: this.state?.truck?._id ? this.state?.truck : null,
      isCompany: false,
      isDriver: false,
      isVisible: false,
    });
  };

  assignPricingTierName=(pricing)=>{
  this.setState({pricingTierName:pricing?.pricingTier ,pricingTierId:pricing?._id})
  }

  fetchDrivers = async () => {
    const { fetchDriversByCustomerId } = this.props;
    const { driverSearchText, customer } = this.state;
    const {
      value: { data },
    } = await fetchDriversByCustomerId({
      customerId: customer._id,
      searchText: driverSearchText,
    });
    this.setState({ drivers: data });
  };

  chargeCustomer = async (is_payment_later) => {
    if (this.SplitPaymentValidation("credit-card", is_payment_later, this.state.splitPayments)) {
      let payAmount = 0
      const SplitPayment = this.state.splitPayments.map(payment => ({ ...payment }));
      this.state.selectedSplitPaymentMethods.includes("credit-card") && SplitPayment.length > 0 &&
        SplitPayment.map((payment) => {
          if (payment.paymentMethod === "credit-card") {
            if (_.get(this.props.user, "company.chargeStripeCardFees", false)) {
              const striptFeeOnCreditCardAmount = this.calcFee(payment.paymentAmount, "USD");
              payAmount = this.state.isSplitPaymentOption ?round2decimal(striptFeeOnCreditCardAmount.total) :round2decimal(this.state.totalAmountDue)
            } else {
              payAmount =this.state.isSplitPaymentOption ? round2decimal(payment.paymentAmount) : round2decimal(this.state.totalAmountDue)
            }
          }
          return null
        })
    this.setState({ pleaseWait: true,inProgressCreditCardPayment:true });
    if (this.state.payMethod === "payengine") {
      try {
        let { cardId } = this.state;
         await this.createOrder(true, true);
        const {
          value: { status, data,message,paymentHistoryData , Code},
        } = await this.props.chargeCustomer({
          amount: payAmount,
          cardId,
          customerId: this.state.customerData._id,
          orderId:this.state.order_id,
          createCharge: true,
        });
        if (status) {
          let splitPayment = this.state.splitPayments
          splitPayment.map((payment,index)=>{
            if (payment.paymentMethod === "credit-card") {
               splitPayment[index].uniqueId=paymentHistoryData._id
            }
            return null
          })
            this.handleCreditCardStatus("Success",data)
            this.setState({ isCharged: true,isCreditCardPaymentDone:true, errorMessage: message, paymentData: data, pleaseWait: false,splitPayments:splitPayment },()=>{
              if(this.state.isSplitPaymentOption){
               this.createOrder(true, true);
              }
            })
            setTimeout(() => {
              this.setState({ isCreditCardPaymentDone:false})
            },3000)
          return true;
        }else{
            this.handleCreditCardStatus("Decline",data)
            const matchingCode = responseCode(Code)
            if(matchingCode){
              this.setState({errorMessage : matchingCode.message})
            }
            else{
              this.setState({errorMessage : "Card could not be charged, please try again." })
            }
            this.setState({ isCharged: false,isCreditCardPaymentDone:false, paymentData: data, pleaseWait: false,inProgressCreditCardPayment:false  , isFailedTransaction : true})
            return false;
         }
      } catch (err) {
        if (err.message.indexOf("No such source") !== -1) {
          message.error("Payment Unsuccessfull! \nInvalid Card. Please try again.")
          // alert("Payment Unsuccessfull! \nInvalid Card. Please try again.");
          this.setState({ pleaseWait: false , isCharged:false  , isFailedTransaction : true});
          return false;
        } else {
          message.error(err.message);
          // alert(err.message);
          this.setState({ pleaseWait: false,isCharged:false, errorMessage: message , isFailedTransaction : true });
          return false;
          }
        }
    } else {
        if (this.state.selectedSplitPaymentMethods.includes("credit-card") && this.state.payMethod === "payabli") {
          let decryptedData = ""
         await this.createOrder(true, true);
          if (!_.isEmpty(this.state.paybliDetails)) {
            // let bytes = CryptoJS.AES.decrypt(this.state.paybliDetails.entry_point, 'weigh123!!');
            decryptedData = this.state.paybliDetails.entry_point
          }
          const {
            value: { data, status,paymentHistoryData , message },
          } = await this.props.makeTransaction({
            // make payabli transaction
            cardData: {
              storedMethodId: this.state.cardId, //this.state.payabliCustomerMethod?.StoredMethods?.length > 0 && this.state.payabliCustomerMethod?.StoredMethods[0]?.IdPmethod,
              payabliInfo: this.state.terminalStatus === true ? this.state.payabliInfo : !this.state.customerPayabliData ? this.state.paymentData : this.state.customerPayabliData?.StoredMethods?.find((card) => { return card.IdPmethod === this.state.cardId })
            },
            paymentDetails: { totalAmount: round2decimal(payAmount) },
            customerData: {
              customerId: this.state.customerPayabliData?.customerId,
            },
            entryPoint: decryptedData,
            signatureUrl: this.state.signatureUrl,
            orderId: this.state.ticket_number,
            companyId: this.state.customerData.companyId,

          });
          if (status) {
            let splitPayment = this.state.splitPayments
            splitPayment.map((payment,index)=>{
              if (payment.paymentMethod === "credit-card") {
                splitPayment[index].uniqueId=paymentHistoryData._id
              }
              return null
            })
           this.handleCreditCardStatus("Success",data)
            this.setState({ isCharged: true,isCreditCardPaymentDone:true, paymentData: data, paybliOutputDetails: data,splitPayments:splitPayment},()=>{ if(this.state.isSplitPaymentOption) {this.createOrder(true, true);}})
            setTimeout(() => {
              this.setState({ isCreditCardPaymentDone:false})
            }, 5000)
            return true;
          }else{
            this.setState({ isCharged: false,isCreditCardPaymentDone:false, paymentData: data, pleaseWait: false,inProgressCreditCardPayment:false , isFailedTransaction : true})
            const match = message?.match(/\b\d+\b/);
            if (match) {
              const extractedNumber = match[0];
              const matchingCode = responseCode(extractedNumber)
              if (matchingCode) {
                this.setState({errorMessage : matchingCode.message})
              }
            }
            else if(match === null){
              this.setState({errorMessage : message}) 
            }
            else{
              this.setState({errorMessage: "Card could not be charged, please try again.",})
            }
            this.handleCreditCardStatus("Decline",data)
          }
        }
      }
    }
  }

  getOrderInvoicePdf = async(ticketnumber) => {
    const { value } = await this.props.getOrderInvoicePdf(ticketnumber)
    const url = URL.createObjectURL(value);
    const printWindow = window.open(url, '_blank');
    if(printWindow){
    printWindow.onload = function() {
      printWindow.print();
    };
  }
  }

  validateOrder =async (is_payment_later,forTerminalUse)=>{
    this.setState({clickedCompleteButton : true})
    const updatedFixedItems = [...this.state.addSelectedFixedItems];
    updatedFixedItems.forEach(item => {
        item.clickAfterComplete = false;
    });

    const updatedChargeByWeight = [...this.state.addSelectedChargeByWeight];
    updatedChargeByWeight.forEach(item => {
        item.clickAfterComplete = false;
    });

    this.setState({
        addSelectedFixedItems: updatedFixedItems,
        addSelectedChargeByWeight: updatedChargeByWeight
    });
    
    if(this.state.project?._id === undefined && this.state.customer.isProjectRequired){
      this.throughError("Project Required",is_payment_later,true)
      return false;
    }
    if(this.state.addSelectedFixedItems.length < 1 && this.state.addSelectedChargeByWeight.length < 1){
      this.throughError("Please select atleast one item",is_payment_later,false)
      return false;
    }
    if((this.state.addSelectedFixedItems?.some(item => item.units === "" || isNaN(item.units) || item.fixedItem === "" || item.fixedItem === undefined || item.unitPrice === "" || isNaN(item.unitPrice))) || (this.state.addSelectedChargeByWeight?.some(item => item.units === "" || isNaN(item.units) || item.fixedItem === "" || item.fixedItem === undefined || item.unitPrice === "" || isNaN(item.unitPrice)))){
      this.throughError("",is_payment_later,true)
      return false
    }
    if (
      (!is_payment_later && (
          this.state.selectedCustomer?.isLEEDsRequired || 
          this.state.project?.isLEEDsRequired
      )) &&
      this.state.sustainabilityInfo?.selectedFields?.length === 0
  ) {
      this.setState({completeOrderButtonDisable : true})
      this.throughError("LEEDs/Sustainability information required to complete this order.", is_payment_later, true)
      return false
  }
    if (!is_payment_later && this.props.user?.company?.signatureRequired && this.props.user?.company?.isSignaturePadIntegration && this.state.signatureData === "") {
      this.throughError("Driver signature required", is_payment_later, true)
      return false;
    }
    if(this.state.isSplitPaymentOption){
      let validations
      if(this.state.selectedSplitPaymentMethods.length !== 0){
        for (let paymentType of this.state.selectedSplitPaymentMethods) {
          if (paymentType === "cash" || paymentType === "check" || paymentType === "credit-memo") {
            paymentType = "cash-check-credit-memo"
          }
          validations = this.SplitPaymentValidation(paymentType, is_payment_later, this.state.splitPayments);
          if (!validations) {
            break;
          }
        }
      }else{
        if(this.state?.customer?.paymentTerms === "account" || is_payment_later ){
          validations=true  
        }else{
          if((Number(this.state.splitpaymentCalculationAmount) === Number(this.state.paidAmount))){
            validations=true
          }else{
            validations = this.SplitPaymentValidation("", is_payment_later, this.state.splitPayments);
          }
        }
      } 
      if (validations) {
        if (forTerminalUse){
          return true;
        }
        await this.createOrder(is_payment_later, forTerminalUse);
        return true;
      }else{
        return false
      }
    }else{
      if (forTerminalUse){
        return true;
      }
      await this.createOrder(is_payment_later, forTerminalUse);
        return true;
    }
  }
  
  updateSelectedSplitpayments=(selectedSplitPaymentMethods)=>{
   this.setState({selectedSplitPaymentMethods:selectedSplitPaymentMethods})
  }

  updateSplitPaymnet = (splitMethodPayments, remaningAmount) => {
    this.setState({ splitPayments: splitMethodPayments, remaingSplitAmount: remaningAmount, })
  }
 updateInProgressTerminalPayment=(payload)=>{
this.setState({inProgressTerminalPayment:payload})
 }

  SplitPaymentValidation = (type, is_payment_later, splitPayments) => {
    if (this.state.isSplitPaymentOption) {
      const splitCreditCardPayment = splitPayments;
      // For Credit card Validation 
      let amount = 0;
      if(!is_payment_later){
        splitCreditCardPayment.map((payment, index) => {
          if (type === "credit-card" && payment.paymentMethod === "credit-card") {
            amount = splitCreditCardPayment[index].paymentAmount;
          }
          return null
        });
        if (type === "credit-card") {
          if (amount <= 0 && this.state.cardId === "") {
            this.setState({ isError: true, errorMessage: "Please choose a credit card, ensuring that the credit card amount is greater than zero." });
            return false;
          } else {
            if (amount <= 0) {
              this.setState({ isError: true, errorMessage: "Enter an amount that is more than zero" });
              return false;
            } else {
              if (this.state.cardId === "") {
                this.setState({ isError: true, errorMessage: "Please Select Credit Card" });
                return false;
              }
            }
          }
        }
       // For Terminal Validation
        if (type === "terminal") {
          let amount = 0;
          splitCreditCardPayment.map((payment, index) => {
            if (payment.paymentMethod === "terminal") {
              amount = splitCreditCardPayment[index].paymentAmount;
            }
            return null
          });
          if (amount <= 0) {
            this.setState({ isError: true, errorMessage: "Enter an amount that is more than zero" });
            return false;
          }
          if (!is_payment_later && this.state.selectedSplitPaymentMethods.includes("terminal") && (!this.state.terminalPaymentStatus)) {
            this.setState({ isError: true, errorMessage: "Please Charge Terminal" });
            return false;
          }
          if (!is_payment_later) {
            if (this.state.remaingSplitAmount === 0) {
              return true;
            } else {
              this.setState({ isError: true, errorMessage: "Please enter full Order Amount" });
              return false;
            }
          } else {
            return true
          }
        }
       // For Cash and check validation
        if ((type === "cash-check-credit-memo")) {
          const selectedPaymentTypes = ["cash", "check", "credit-memo"];
          const selectType = [];
          if (this.state.selectedSplitPaymentMethods.includes("check")) {
            if (this.state.checkDetails.checkNumber === "") {
              this.setState({ isError: true, errorMessage: `Please add check number.` });
              return false;
            }
            // if (this.state.checkDetails.backCheckImage === "") {
            //   this.setState({ isError: true, errorMessage: `Please add back of check image.` });
            //   return false;
            // }
            // if (this.state.checkDetails.frontCheckImage === "") {
            //   this.setState({ isError: true, errorMessage: `Please add front of check image.` });
            //   return false;
            // }
          }
          for (const payment of splitCreditCardPayment) {
            if (selectedPaymentTypes.includes(payment.paymentMethod) && payment.isSelectedPayment) {
              selectType.push(payment.paymentMethod);
            }
          }
          for (const paymentType of selectType) {
            const payment = splitCreditCardPayment.find(payment => payment.paymentMethod === paymentType);
            if (payment && payment.paymentMethod === "credit-memo" && !this.state.selectedCreditMemos.length > 0) {
              this.setState({ isError: true, errorMessage: 'Please select credit memo.' });
              return false;
            }
            if (payment && payment.paymentAmount <= 0) {
              this.setState({ isError: true, errorMessage: `Enter an amount that is more than zero.` });
              return false;
            }
          }
          if (!is_payment_later && this.state.remaingSplitAmount !== 0) {
            this.setState({ isError: true, errorMessage: "Please enter full Order Amount" });
            return false;
          }
        }
        // Gloable validation
        // if (type === "cash-check" || type === "") {
        // let availableAmount = availableSplitAmount;
        if (!((this.state.selectedSplitPaymentMethods.length > 0))) {
            if(this.state.remaingSplitAmount !== 0 ){
             this.setState({ isError: true, errorMessage: "This order can't be completed due to an outstanding amount. Please select a payment method from the options above" });
            return false;
            }else{
              return true;
            }
        }
        if (!is_payment_later && this.state.selectedSplitPaymentMethods.includes("credit-card") && (!this.state.isCharged)) {
          this.setState({ isError: true, errorMessage: "Please Charge Card" });
          return false;
        }
        if (!is_payment_later) {
          if (this.state.remaingSplitAmount === 0) {
            return true;
          } else {
            this.setState({ isError: true, errorMessage: "Please enter full Order Amount" });
            return false;
          }
        } else {
          return true
        }
      }else{
        return true
      }
    } else {
      return true
    }
    // if (!is_payment_later && Number(availableAmount).toFixed(2) < Number((this.state.totalWithFee.total).toFixed(2))) {
    //   this.setState({ isError: true, errorMessage: "Please enter Valid Amount." });
    //   return false;
    // } else {
    //   return true;
    // }
    // }
  }

  assignTerminalData =(payalod)=>{
   this.setState({terminalPaymentInformation:payalod})
  }

  isCalculteOrder=(isCalculate)=>{
    this.setState({isCalculteOrder:isCalculate})
  }

  createOrder = async (is_payment_later, forTerminalUse) => {
    const { user } = this.props
    let SplitPayment = []
    let handlingFee = this.state.totalHandlingFeeAmount
    let selectedSplitPaymentMethods = [...this.state.selectedSplitPaymentMethods]
    if(!this.state.isSplitPaymentOption && this.state.selectedSplitPaymentMethods.includes("credit-card") && !is_payment_later ){
     const isCreditCardCharged =  await this.chargeCustomer(false)
     if(!isCreditCardCharged){
      return false;
     }
    }
    this.state.splitPayments.map((payment,index) => {
      if (selectedSplitPaymentMethods.includes(payment.paymentMethod)) {
        if ((payment.paymentMethod === "credit-card" && this.state.isCharged) || (payment.paymentMethod === "terminal" && this.state.terminalPaymentStatus)) {
          selectedSplitPaymentMethods = this.state.selectedSplitPaymentMethods.filter((paymentType)=> paymentType !== payment.paymentMethod)
          if (_.get(user, "company.chargeStripeCardFees", false)) {
            const totalWithFee = this.calcFee( this.state.isSplitPaymentOption ? payment.paymentAmount : this.state.withOutFeeAndRoundOffTotal, "USD") 
            handlingFee += round2decimal(Number(totalWithFee.fee))
            SplitPayment.push ({
              paymentType:payment.paymentMethod,
              uniqueId:payment.uniqueId,
              amount:this.state.isSplitPaymentOption ? round2decimal(totalWithFee.total) : round2decimal(totalWithFee.total), handlingFee: round2decimal(totalWithFee.fee)
            })
            const emptySplitpayment={
              paymentMethod:payment.paymentMethod,
              paymentAmount: null,
              isSelectedPayment: false,
              uniqueId:"",
              handlingFee: null
            }
            let updateSplitPaymnet=this.state.splitPayments
            updateSplitPaymnet[index]=emptySplitpayment
            this.setState({paidAmount:round2decimal(Number(this.state.paidAmount + totalWithFee.total)),splitPayment:updateSplitPaymnet,isCharged:false,terminalPaymentStatus:this.state.isSplitPaymentOption ? false : true,selectedSplitPaymentMethods,totalHandlingFeeAmount:handlingFee,inProgressCreditCardPayment:false,inProgressTerminalPayment:false})
          } else {
            SplitPayment.push({
              paymentType: payment.paymentMethod,
              uniqueId:payment.uniqueId,
              amount:this.state.isSplitPaymentOption ? round2decimal(payment.paymentAmount) : round2decimal(this.state.totalAmountDue), handlingFee: 0
            })
            const emptySplitpayment={
              paymentMethod:payment.paymentMethod,
              paymentAmount: null,
              isSelectedPayment: false,
              uniqueId:"",
              handlingFee: null
            }
            let updateSplitPaymnet=this.state.splitPayments
            updateSplitPaymnet[index]=emptySplitpayment
            this.setState({paidAmount:round2decimal(Number(this.state.paidAmount + this.state.isSplitPaymentOption ? round2decimal(payment.paymentAmount) : round2decimal(this.state.totalAmountDue))),splitPayment:updateSplitPaymnet,isCharged:false,terminalPaymentStatus: this.state.isSplitPaymentOption ? false : true,selectedSplitPaymentMethods,inProgressCreditCardPayment:false,inProgressTerminalPayment:false})
          }
        }else {
          if(!is_payment_later) {
            if (payment.paymentMethod === "credit-memo") {
              SplitPayment.push({
                paymentType: payment.paymentMethod,
                amount:round2decimal(payment.paymentAmount),
                creditMemoIds: payment.creditMemoIds
              })
            } else {
              if(payment.paymentMethod ==="cash" && this.state.isRoundOffAmount){
                SplitPayment.push({
                  paymentType: payment.paymentMethod,
                  amount:this.state.isSplitPaymentOption ? Math.ceil(round2decimal(payment.paymentAmount)) : Math.ceil(round2decimal(this.state.totalAmountDue) )
                })
              }else{
              SplitPayment.push({
                paymentType: payment.paymentMethod,
                amount: this.state.isSplitPaymentOption ? round2decimal(payment.paymentAmount) : round2decimal(this.state.totalAmountDue)
              })
            }
            }
          }
        }
      }
      return null
    })
    let decryptedData = ""
    if(!_.isEmpty(this.state.paybliDetails)){
      // let bytes = CryptoJS.AES.decrypt(this.state.paybliDetails.entry_point, 'weigh123!!');
      decryptedData = this.state.paybliDetails.entry_point 
    }
    // this.setState({isCharged:true})
    // if (this.state.paymentMethod === "creditcard" && !is_payment_later) {
    //   const chargeUstomerResponse = await this.chargeCustomer();
    //   if(!chargeUstomerResponse && !this.state.isTerminalTabActive) {
    //     this.throughError("Card could not be charged, please try again.", is_payment_later, true)
    //     this.setState({ isCharged: false })
    //   }
    // }
   
     const { uploadImage, createCharge } = this.props;
     const {
      addSelectedFixedItems,
      customer,
      truck,
      driver,
      pictureOfLoad,
      pictureOfTruck,
      signatureData,
      cityOfOrigin,
      sustainabilityInfo,
      saveNote,
      orderDetails,
      truckNumber,
    } = this.state;
    let { totalAmountDue } = this.state
    this.setState({ pleaseWait: !is_payment_later, pleaseWaitPaymentLater: is_payment_later });
    let loadImage = "";
    let plateImage = "";
    if (truck && pictureOfLoad && !/^(https?:\/\/)/i.test(pictureOfLoad)) {
      const img1 = await uploadImage(this.submitImages(pictureOfLoad));
      loadImage = img1.value.url;
    }
    else {
      loadImage = pictureOfLoad
    }
    if (!truck) {
      if (pictureOfLoad && !/^(https?:\/\/)/i.test(pictureOfLoad)) {
        const img1 = await uploadImage(this.submitImages(pictureOfLoad));
        loadImage = img1.value.url;
      }
      else {
        loadImage = pictureOfLoad
      }
      if (pictureOfTruck && !/^(https?:\/\/)/i.test(pictureOfTruck)) {
        const img1 = await uploadImage(this.submitImages(pictureOfTruck));
        plateImage = img1.value.url;
       }
        else{
        loadImage = pictureOfTruck
       }
    }
    if (this.state.activeTab === "check") {
      if (this.state.checkDetails?.frontCheckImage) {
        const forntCheckImage = await uploadImage(
          this.submitImages(this.state.checkDetails.frontCheckImage)
        );
        this.setState({ forntCheckImage: forntCheckImage });
      }
      if (this.state.checkDetails?.backCheckImage) {
        const backCheckImage = await uploadImage(
          this.submitImages(this.state.checkDetails.backCheckImage)
        );
        this.setState({ backCheckImage: backCheckImage });
       }
    }
      const totalAmountWithCardFee = this.props.user?.company?.chargeStripeCardFees && (selectedSplitPaymentMethods.includes("credit-card") || selectedSplitPaymentMethods.includes("terminal")) && orderDetails.isminimumPrice ? Number(this.state.totalWithFee.total) < Number(orderDetails.minimumPrice) ? orderDetails.minimumPrice : this.state.totalWithFee.total : totalAmountDue
      const orderBreakDownForCreditCard = this.props.user?.company?.chargeStripeCardFees ? { ...orderDetails, totalOrderAmout: this.state.totalOrderAmmWithFee.total } : orderDetails
      const payload = {
      customerId: customer._id,
      driverId: driver?driver._id:null,
      truckId: truck ? truck._id : "",
      plateNumber: this.state.licensePlate,
      plateImageUrl: plateImage,
      projectId:this.state.project?._id,
      loadImageUrl: loadImage,
      number: truckNumber,
      orderType: "manual",
      driverSignature: signatureData,
      cityOfOrigin,
      sustainabilityInfo,
      chargeStripeCardFees : handlingFee > 0 ? true : false,
      stripeCardFees : round2decimal(handlingFee),
      totalAmount: round2decimal(totalAmountWithCardFee),
      amountPaid:round2decimal(totalAmountDue) ,
      additionalItems: addSelectedFixedItems,
      completed: true,
      paymentType:"",
      checkNumber: this.state.checkDetails.checkNumber,
       // this.state.customer?.paymentTerms === "account"
      //    ? ""
       //   : this.state.activeTab === "creditCard" ? this.state.terminalStatus ? 'terminal' : "credit-card"   :  this.state.activeTab ,
      paymentTerms: this.state.customer?.paymentTerms,
      notes: saveNote,
      yardage: this.state.yardage,
      tonnage: this.state.tonnage,
      orderCreationType: "createCharge",
       orderBreakDownItems:selectedSplitPaymentMethods.includes("credit-card") ? orderBreakDownForCreditCard : orderDetails,
      completedDate: new Date(),
      pricingTier: this.state.pricingTierName,
      isPaymentDone: this.state.is_payment_later ? false : true,
      pricingTierId:this.state.pricingTierId,
      makePaymentDetails: {
        cardData: {
          storedMethodId:
            this.state.payabliCustomerMethod?.StoredMethods?.length > 0 && this.state.payabliCustomerMethod?.StoredMethods[0]?.IdPmethod,
            payabliInfo: this.state.terminalStatus === true ? this.state.payabliInfo : !this.state.customerPayabliData ? this.state.paymentData   :  this.state.customerPayabliData?.StoredMethods?.find((card)=>{return card.IdPmethod === this.state.cardId})
        },
        paymentDetails: { totalAmount:round2decimal(totalAmountWithCardFee) },
        customerData: {
          customerId: this.state.payabliCustomerMethod?.customerId,
        },
        entryPoint: decryptedData,
        signatureUrl: this.state.signatureUrl,
        orderunderscoreID: this.state?.order_id ?? null,
      },
      isEnvironmentalLoadFee: this.state.isEnvironmentalLoadFee,
      isFuelSurchardeFee: this.state.isFuelSurchardeFee,
      isSalesTax: this.state.isSalesTax,
      environmentalLoadFeeAmount: this.state.environmentalLoadFeeAmount,
      fuelSurchardeFeeAmount: orderDetails.fuelSurchargeAmmount,
      salesTaxFeeAmount:orderDetails.salesTaxFeeAmount,
      salesTaxPercentageForOrder: this.state.saleTaxFeeAmount,
      nonRoundedTotalAmount:totalAmountWithCardFee,
      orderId: this.state?.order_id ?? null,
      needSignature: this.props.user.company.signatureRequired  && signatureData === "",
      splitPayment: SplitPayment,
      isFailedPayment : false,
      selectedSplitPaymentMethods: [...this.state.selectedSplitPaymentMethods],
      creditCardPaymentInformation: (this.state.payMethod === "payengine") ? this.state.paymentData : this.state.paybliOutputDetails,
      terminalPaymentInformation:this.state.terminalPaymentInformation,
      isSplitPayment:this.state.isSplitPaymentOption
    };
    if(!truck) {
      payload['type'] = this.state?.truckType
    }
    if(is_payment_later) {
      payload['isPaymentDone'] = false
      payload['completed'] = false
      payload['isPaymentLaterFlow'] = true
      payload['isPaymentLater'] = true
      if (this.props.user.company.signatureRequired !== true) {
        payload['signatureRequired'] = false
      }
    } else {
      payload['isPaymentLater'] = false
      payload['isPaymentLaterFlow'] = this.state?.orderId?.length > 0
    }
    const { value } = await createCharge(payload);
    if (value?.status) {
      if(forTerminalUse){
       this.setState({order_id:value.order._id,ticket_number:value.ticket_number, pleaseWait: false, pleaseWaitPaymentLater: false})
     }
      else{
        this.setState({order_id:value.order._id})
      }
      if(!forTerminalUse){
        if(this.state?.is_open_pdf_when_an_order_complete && !is_payment_later){
          this.getOrderInvoicePdf(value?.ticket_number).then(() => {
                        this.props.history.push(is_payment_later ? "/pending-payments" : this.props.user.company.signatureRequired && payload.driverSignature === undefined ? "/queued-for-drivers-signature" : "/completed-orders");
            this.setState({ pleaseWait: is_payment_later, pleaseWaitPaymentLater: !is_payment_later  });
          })
        }
        else{
          this.props.history.push(is_payment_later ? "/pending-payments" : this.props.user.company.signatureRequired && payload.driverSignature  === undefined  ? "/queued-for-drivers-signature" : "/completed-orders");
          this.setState({ pleaseWait: is_payment_later, pleaseWaitPaymentLater: !is_payment_later  });
        }
      }
    } else {
      message.error("Card could not be charged, please try again.")
     this.setState({ pleaseWait: is_payment_later, pleaseWaitPaymentLater: !is_payment_later  });
    }
  };

  // customer payabli method
  assignPaybaliCustomerMethod = (data) => {
    this.setState({ payabliCustomerMethod: data });
  };

  async setInitialState({
    state: { firstName, lastName, licenseNumber, formdata, state },
  }) {
    const { driverInitialValues } = this.state;
    if (formdata !== "") {
      const licenseImageUrl = await this.convertBlobToBase64(formdata);
      this.setState({ licenseImageUrl, isLicenseCamera: false });
    }
    this.setState({
      createDriverForm: true,
      createDriverSidebar: false,
      driverInitialValues: {
        ...driverInitialValues,
        firstName,
        lastName,
        licenseNumber,
        state,
      },
    });
  }

  convertBlobToBase64(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        return resolve(base64data);
      };
    });
  }

  isSalesTax(saleTaxFeeAmount){
    const {isSalesTax}=this.state
    if (isSalesTax) {
      if(saleTaxFeeAmount > 100){
        this.setState({ errisSalesTax: "Sales Tax cannot be more than 100%." });
      }
      if(saleTaxFeeAmount <= 100 ){
        this.setState({ errisSalesTax: "" });
      }
      }
    }
  submitDriverForm = async (values, { setErrors }) => {
    try {
      const {
        customer: { _id },
        licenseImageUrl,
        rotation,
      } = this.state;
      let url = "";
      if (licenseImageUrl) {
        const data =   this.submitImages(licenseImageUrl)
        const uploadImage = await this.props.uploadImage(
          data
        );
        url = uploadImage?.value?.url?.length > 0 ? uploadImage?.value?.url: licenseImageUrl ;
      }
      const payload = {
        ...values,
        customerId: _id,
        licenseImageUrl: url,
        rotation,
      };
      const {
        value: { status, message },
      } = await this.props.createDriver(payload);
      if (status) {
        this.setState({
          createDriverSidebar: false,
          isDriver: true,
          createDriverForm: false,
          licenseImageUrl: "",
          rotation: 0,
        });
        this.fetchDrivers();
      } else {
        setErrors({ state: message });
      }
    } catch (err) {
      setErrors({ state: "Upload driver license image" });
    }
  };

  submitImages(data) {
    const blob = this.dataURItoBlob(data);
    const formdata = new FormData();
    formdata.append("photos", blob);
    formdata.append("fileName", `${new Date().getTime()}.png`);
    formdata.append("folderName", "load");
    return formdata;
  }
  // assign card payabli
  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isAddNewCard: false });
    const payload = {
      _id: this.state.customerData._id,
      payabliCustomer: cardData,
    };
    await this.props.updateCustomer(payload);
    this.setState({ cardAdded: false });
    this.getCustomerPayment(cardData?.customerId);
  };
  
  assignhandlingFee=(fee,withOutFeeAndRoundOffTotal)=>{
    this.setState({handlingFeeForSplit:fee,withOutFeeAndRoundOffTotal:withOutFeeAndRoundOffTotal})
  }

  handleSplitPaymentChange = () => {
    this.setState(() => ({
      isSplitPaymentOption: !this.state.isSplitPaymentOption
    }));
   let splitPayments = _.clone(this.state.splitPayments)
      let selectedSplitPaymentMethods = [this.state.customer?.paymentType]
        this.setState({activeTab: this.state.customer.paymentType })
      splitPayments.map((payment,index)=>{
        if(payment.paymentMethod === this.state.customer.paymentType){
          if((this.state.customer.paymentType === "credit-card" || this.state.customer.paymentType === "terminal")){
            splitPayments[index].isSelectedPayment = true
             splitPayments[index].paymentAmount = Number(Number(this.state.withOutFeeAndRoundOffTotal).toFixed(2))
             splitPayments[index].handlingFee=this.state.handlingFeeForSplit
          }else{
            if(payment.paymentMethod === "cash"){
              splitPayments[index].isSelectedPayment = true
              splitPayments[index].paymentAmount= Math.ceil(this.state.withOutFeeAndRoundOffTotal)
            }else{
              splitPayments[index].isSelectedPayment = true
              splitPayments[index].paymentAmount= this.state.withOutFeeAndRoundOffTotal
            }
          }
        }else{
          if((this.state.customer.paymentType === "credit-card" || this.state.customer.paymentType === "terminal")){
            splitPayments[index].isSelectedPayment = false
             splitPayments[index].paymentAmount= null
             splitPayments[index].handlingFee=null
          }else{
            splitPayments[index].isSelectedPayment = false
            splitPayments[index].paymentAmount= null
          } 
        }
        return null
      })
      this.setState({splitPayments:splitPayments,selectedSplitPaymentMethods:selectedSplitPaymentMethods,remaingSplitAmount:0},()=>{
        this.setState({isCalculteOrder :true})
      })
  };

 
  //get payabli data
  getCustomerPayment = async (value) => {
    const payload = {
      customerId: value,
    };
    const {
      value: { data },
    } = await this.props.getPayabliCustomerData(payload);
    this.setState({ customerPayabliData: data, payabliCustomerMethod: data });
    this.setState({ reRender: !this.state.reRender });
  };


  handleOverAllSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    const searchText = event.target.value;
    const showSearch = searchText.length >= 3;
    this.setState({
      SearchText: event.target.value.trimStart(),
      showSearch:showSearch,
      selectedCustomerSearch: null,
      selectedTruckSearch: null,
      isLoading: true,
      pricingTier: null,
      typingTimeout: setTimeout(() => {
        this.fetchOverAll();
      }, 200)
    });
  };

  fetchOverAll = async () => {
    const { fetchTrucksByCompanyId } = this.props;
    const { SearchText } = this.state;
    const {
      value: { data, data_type }
    } = await fetchTrucksByCompanyId({
      companyId: this.props.user?.companyId && this.props.user?.companyId,
      searchText:SearchText.trimStart()
    });
    
    this.setState({ overAllData: data,isLoading:false, data_type });
  };

  selectCustomerSearch = customer => {
  this.getCustomerCredits(customer?._id);
    this.setState({
      selectedCustomerSearch: customer,
      showSearch: false,
      SearchText:'',
      isEnvironmentalLoadFee: this.props.user?.company?.isEnvironmentalLoadFee && customer?.isEnvironmentalLoadFee,
      isFuelSurchardeFee: this.props.user?.company?.isFuelSurchardeFee && customer?.isFuelSurchardeFee
    }, () => {
      if (!this.state.showSearch) {
        this.assignCustomerSearch();
      }
    });
  };
  assignCustomerSearch = async () => {
    const { selectedCustomerSearch } = this.state;
    this.setState({
      isVisible: false,
      driver: null,
      isCompany: false,
      customer: null, 
      pricingTier: null, 
      addSelectedFixedItems: [],
      addSelectedChargeByWeight: [],
      isSplitPaymentOption: false,
      truck:null,
      plateNumber:"",
      licensePlate:"",
    });
    if(selectedCustomerSearch?.paymentTerms ==="account"){
      this.setState({isRoundOffAmount: false})
    } 
    else {
      this.setState({isRoundOffAmount: this.props.user?.company?.isRoundOffAmount && selectedCustomerSearch?.paymentTerms !== "account" && this.state.activeTab === "cash"})
    }
    await new Promise(resolve => this.setState({}, resolve));
    this.setState(
      {
        isVisible: false,
        truck: null,
        driver: null,
        isCompany: false,
        customer: selectedCustomerSearch,
        pricingTier: selectedCustomerSearch.pricingTier,
        selectedProjectData :{},
      project:{}
      },
      () => {
        if (selectedCustomerSearch) {
          this.fetchDrivers();
          this.fetchTrucks();
          this.isDelinquentAccount();
          this.fetchCustomerDetail();
          this.fetchProject();
          this.getCustomerPayment(
            this.state.customer?.payabliCustomer?.customerId
          );
        }
      }
    );
  };
  selectedDriverSearch = (customer,driver,truck) => {
    this.setState({ selectedCustomerSearch: customer,selectedDriverSearch: driver, selectedTruckSearch : truck, truck, showSearch: false,SearchText:'',
    isEnvironmentalLoadFee: this.props.user?.company?.isEnvironmentalLoadFee && customer?.isEnvironmentalLoadFee,
    isFuelSurchardeFee: this.props.user?.company?.isFuelSurchardeFee && customer?.isFuelSurchardeFee
  }, () => {
      if (!this.state.showSearch) {
        this.assignDriverSearch();
      }
    });
  };
  assignDriverSearch = async () => {
    const {  selectedDriverSearch,selectedCustomerSearch,selectedTruckSearch} = this.state;
    this.setState({
      isVisible: false,
      driver: null,
      isCompany: false,
      customer: null, 
      pricingTier: null, 
      truck: this.state?.truck?._id ? this.state?.truck?._id : null,
      licensePlate: "",
      isDriver: false,
      isTruck: false,
      plateNumber:""
    });
    await new Promise(resolve => this.setState({}, resolve));
    this.setState({
      customer: selectedCustomerSearch,
      pricingTier: selectedCustomerSearch.pricingTier,
      driver: selectedDriverSearch,
      truck: selectedTruckSearch,
      licensePlate: selectedTruckSearch.plateNumber,
      isCompany: false,
      isVisible: false,
      isDriver: false,
      isTruck: false,
    },() => {
        if (selectedCustomerSearch) {
          this.fetchDrivers();
          this.fetchTrucks();
          this.fetchCustomerDetail();
          this.isDelinquentAccount();
          this.fetchProject();
          this.getCustomerPayment(
            this.state.customer?.payabliCustomer?.customerId
          );
        }
      });
  };

  updateErrors = ({fixedItemsErrors,scaleItemsErrors}) => {
    this.setState({
      fixedItemsErrors:fixedItemsErrors ?? this.state.fixedItemsErrors,
      scaleItemsErrors:scaleItemsErrors ?? this.state.scaleItemsErrors
    })
  }


  render() {
    const { user } = this.props;
    const {
      isCompany,
      isDriver,
      customers,
      selectedCustomer,
      customer,
      drivers,
      selectedDriver,
      driver,
      isTruckAvaliable,
      isTruck,
      selectedTruck,
      trucks,
      truck,
      dueBalance,
      selectedTruckSearch,
      selectedCustomerSearch,
      overAllData,
      data_type,
      showSearch,
      orderId,
      isEnvironmentalLoadFee,
      isSalesTax,
      isFuelSurchardeFee
    } = this.state;
      return (
      <div className="layout-has-sidebar">
        <TopNavigation
          {...this.props}
          onClickToggleMenu={() =>
            this.setState({ toggleMenu: !this.state.toggleMenu })
          }
        />
        <SidebarNavigation
          {...this.props}
          toggleMenu={this.state.toggleMenu}
          user={user}
          onClickToggleMenu={() => this.setState({ toggleMenu: false })}
        />

        <main className="dashboard-layout-content chargebyyard__wrapper border-box-shadow">
          <div className="page-header">
            <h2 className="heading-title">Create Charge</h2>
            <h3 className="sub__heading-title">
              Date - {moment(this.state.currentDate).format("LL")} at{" "}
              {moment(this.state.currentDate).format("LT")} EST{" "}
            </h3>
        
            {customer && dueBalance > 0 && customer.paymentTerms === "account" ? (
              <div className="ticket-void-order-text mt-0 mb-3">
                {customer?.displayName} has an overdue balance of ${numberFormat(dueBalance)}
              </div>
            ) : null}

          {(user?.permissions?.permissionOptions?.createCustomer === true) &&
            <p className="new__customer-text">
              New Customer?{" "}
              <span
                className="button-item"
                onClick={() => {
                  this.setState({ createCustomerModelOpen: true });
                }}
              >
                Click Here to Create a New Company
              </span>
            </p>
          }
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="reading__select-list"
              >
                <p className="new__customer-text">
                  <span >Search by Company</span>
                </p>
                <div className="form-group material-textfield">

                <DebounceInput
                  type="text"
                  className="form-control material-textfield-input"
                  onChange={this.handleOverAllSearch}
                  value={this.state.SearchText}
                  required
                  minLength={2}
                  debounceTimeout={1000}
                  name="overAllSearch"
                  />
                  <label className="material-textfield-label">Start searching...</label>
                </div>
                <ul className={showSearch === false ?"select_driver-list select__truck-list box-shadow":"select_driver-list select__truck-list box-shadow mb-3"}>
                  {showSearch === true ? overAllData && overAllData.map((t, index) => {
                    return (
                      <>
                      <Spin indicator={antIcon} spinning={this.state.isLoading}>
                        {
                          data_type === "customer" &&
                           <li
                           onClick={this.selectCustomerSearch.bind(this, t)}
                           className={String(selectedCustomerSearch && selectedCustomerSearch._id) === String(t._id) ? "active" : ""}
                             style={{cursor:'pointer'}}
                             key={index}
                             >
                             <div className="d-flex flex-column">
                               <div className="driver__name">
                                 <span>{t.displayName}</span>
                               </div>
                             </div>
                             <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                               <input
                                 type="checkbox"
                                 className="custom-checkbox--input"
                                 onChange={this.selectCustomerSearch.bind(this, t)}
                                 checked={String(selectedCustomerSearch && selectedCustomerSearch._id) === String(t._id) ? true : false}
                                 />
                               <span className="checkmark"></span>
                             </label>
                           </li>
                         }
                        {t.drivers.length > 0 ? (
                          t.drivers.map((driver, index) => {
                            const driverName = `${driver.firstName} ${driver.lastName}`;
                            const truckItems = t?.trucks
                              .filter((truck) => truck?.customerId === driver?.customerId && (truck?.plateNumber || truck?.number !== ""))
                              .map((truck, truckIndex) => (
                                <li
                                onClick={this.selectedDriverSearch.bind(this, t, driver, truck)}
                                  className={
                                    String(selectedTruckSearch && selectedTruckSearch._id) ===
                                      String(truck._id)
                                      ? "active"
                                      : ""
                                  }
                                  style={{cursor:'pointer'}}
                                  key={`${index}-${truckIndex}`}
                                >
                                  <div className="d-flex flex-column">
                                    <div className="driver__name">
                                      <span>{t?.displayName}</span>
                                      <br />
                                      <small>{driverName} | {truck?.plateNumber}</small>
                                    </div>
                                  </div>
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      onChange={this.selectedDriverSearch.bind(this, t, driver, truck)}
                                      checked={
                                        String(selectedTruckSearch && selectedTruckSearch._id) ===
                                          String(truck._id)
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              ));

                            return truckItems;
                          })
                        ) : null}
                        </Spin>
                      </>
                    )
                  }
                  ) : null}

                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="reading__select-list">
                <div
                  onClick={() => {
                    this.setState({
                      isVisible: true,
                      isDriver: false,
                      isProjectDrawerOpen:false,
                      isCompany: true,
                      isTruck: false,
                      createDriverSidebar: false,
                      isCreateDrawerOpen: false,
                      createDriverForm: false,
                      selectedSplitPaymentMethods:[]
                    });
                  }}
                  className="reading__select-item"
                >
                  <h6 className="reading__select-title">
                    Select Company <span>*</span>
                  </h6>
                  <p className="reading__select-sub-title">
                    <input
                      name="customer"
                      readOnly
                      placeholder="Select"
                      className="reading__select-input"
                      value={customer?.displayName}
                      type="text"
                    />{" "}
                  </p>
                </div>
              </div>
              {customer && (
                <>
                  <ul className="list chargeby__list">
                    <li className="items">
                      <h2 className="title">Company ID</h2>
                      <h3 className="sub__title">{customer?.customerId}</h3>
                    </li>
                    <li className="items">
                      <h2 className="title">Company Address</h2>
                      <h3 className="sub__title">
                        {" "}
                        {customer?.address +
                          " " +
                          customer?.state +
                          " " +
                          customer?.zip}
                      </h3>
                    </li>
                    <li className="items">
                      <h2 className="title">Customer Payment Terms</h2>
                      <h3 className="sub__title">{customer?.paymentTerms}</h3>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="col-lg-4">
              <div className="reading__select-list">
                <div
                  onClick={() => {
                    this.setState({
                      isVisible: true,
                      isDriver: false,
                      isProjectDrawerOpen:true,
                      isTruck: false,
                      isCompany: false,
                      createDriverSidebar: false,
                      isCreateDrawerOpen: false,
                      createDriverForm: false,
                    });
                  }}
                  className="reading__select-item"
                >
                  <h6 className="reading__select-title">
                    Select Project 
                    {customer?.isProjectRequired ? <span>*</span> : ""}
                  </h6>
                  <p className="reading__select-sub-title">
                    {this.state.project?.projectName 
                      ? this.state.project?.projectName 
                      : "Select"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="reading__select-list">
                <div
                  onClick={() => {
                    this.setState({
                      isVisible: true,
                      isDriver: true,
                      isTruck: false,
                      isProjectDrawerOpen:false,
                      isCompany: false,
                      createDriverSidebar: false,
                      isCreateDrawerOpen: false,
                      createDriverForm: false,
                    });
                  }}
                  className="reading__select-item"
                >
                  <h6 className="reading__select-title">
                    Select Driver 
                  </h6>
                  <p className="reading__select-sub-title">
                    {driver?.firstName || driver?.lastName
                      ? driver?.firstName + " " + driver?.lastName
                      : "Select"}
                  </p>
                </div>
              </div>
              {driver && (
                <>
                  <ul className="list chargeby__list">
                    <li className="items">
                      <h2 className="title">Drivers License</h2>
                      <img
                        className="license__img"
                        src={driver?.licenseImageUrl}
                        alt=""
                      />
                    </li>
                  </ul>
                </>
              )}
            </div>
         
          </div>
          
          <br />
          {customer && (
            <>
              <div>
                <div className="row"></div>
                  <div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="custome__tabs">
                          <ul className="custome__tabs-list">
                            <li>
                              <span
                                className={isTruckAvaliable && "active"}
                                onClick={() =>
                                  this.setState({
                                    isTruckAvaliable: true,
                                    createDriverForm: false,
                                  })
                                }
                              >
                                Truck has been here
                              </span>
                            </li>
                            <li>
                              <span
                                className={!isTruckAvaliable && "active"}
                                onClick={() =>
                                  this.setState({
                                    isTruckAvaliable: false,
                                    createDriverForm: false,
                                    licensePlate: "",
                                  })
                                }
                              >
                                Truck has never been here{" "}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {isTruckAvaliable && (
                      <div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="reading__select-list">
                              <div
                                onClick={() =>
                                  this.setState({
                                    isVisible: true,
                                    isTruck: true,
                                    isCompany: false,
                                    isDriver: false,
                                    isProjectDrawerOpen:false
                                  })
                                }
                                className="reading__select-item"
                              >
                                <h6 className="reading__select-title">
                                  Select Truck from{" "}
                                  {customer && customer?.displayName}
                                </h6>
                                <p className="reading__select-sub-title">
                                  {truck?.number ?? "Select"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <ul className="list licenseplate__number">
                              <li className="items">
                                <h2 className="title">License Plate Number</h2>
                                <h3 className="sub__title">
                                  {truck?.plateNumber ?? "--"}
                                </h3>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <ul className="list picture__list">
                              <li className="items">
                                {truck?.plateImageUrl && (
                                  <>
                                    <h2 className="title">Picture of Truck</h2>
                                    <img
                                      src={truck?.plateImageUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </>
                                )}
                                {/* <button onClick={() => this.refs.fileUploader.click()} className="btn btn-yellow w-100">Add photo of truck</button> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {!isTruckAvaliable && (
                      <div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group material-textfield">
                              <input
                                name="plateNumber"
                                type="text"
                                className="form-control material-textfield-input"
                                onChange={(e) =>
                                  this.setState({
                                    licensePlate: e.target.value,
                                  })
                                }
                                value={this.state.licensePlate}
                                required
                              />
                              <label className="material-textfield-label">
                                Truck License Plate #<span>*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group material-textfield">
                              <select
                                className="form-control custom-select w-150 material-textfield-input pr-22"
                                required
                                value={this.state.truckType}
                                onChange={(e) => {
                                  this.setState({ truckType: e.target.value });
                                }}
                                name="type"
                              >
                            {
                             truckTypes?.map((item)=>{
                             return(
                               <option value={item.value}>{item.name}</option>
                             )
                             })
                            }
                              </select>
                              <label className="material-textfield-label">
                                Truck Type<span>*</span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group material-textfield">
                              <input
                                name="number"
                                type="text"
                                value={this.state?.truckNumber}
                                onChange={(e) => {
                                  this.setState({
                                    truckNumber: e.target.value,
                                  });
                                }}
                                className="form-control material-textfield-input"
                                required
                              />
                              <label className="material-textfield-label">
                                Truck Number<span>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <section className="picture__of-load-section take__picture-truck-section">
                          <p className="take__picture-nottext">
                            Note: Taking a picture of your truck will auto
                            detect your licenses plate #
                          </p>
                          <ul className="list picture__list">
                            <li className="items">
                              {this.state.isTruckNotAvaliableCameraOn && (
                                <>
                                  <Camera
                                    onTakePhoto={(dataUri) =>
                                      this.onTakePhoto(
                                        dataUri,
                                        "plateImageUrl",
                                        "isCameraOn"
                                      )
                                    }
                                    ref="fileUploader"
                                    imageType={IMAGE_TYPES.PNG}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    isMaxResolution={true}
                                    isImageMirror={false}
                                    isSilentMode={false}
                                    isDisplayStartCameraError={true}
                                    isFullscreen={false}
                                  />
                                </>
                              )}
                              {!this.state.isTruckNotAvaliableCameraOn &&
                                this.state.pictureOfTruck !== "" && (
                                  <img
                                    className="pictureofload w-100"
                                    src={this.state.pictureOfTruck}
                                    alt=""
                                  />
                                )}
                              <div>
                                {this.state.isTruckNotAvaliableCameraOn ? (
                                  <button
                                    onClick={() =>
                                      this.setState({
                                        isTruckNotAvaliableCameraOn: false,
                                      })
                                    }
                                    className="btn btn-yellow btn-sm m-auto retake__load-btn w-auto"
                                  >
                                    Close Camera
                                  </button>
                                ) : (
                                  <>
                                    {this.state.pictureOfTruck !== "" ? (
                                      <button
                                        onClick={() =>
                                          this.setState({
                                            isTruckNotAvaliableCameraOn: true,
                                          })
                                        }
                                        className="btn btn-yellow btn-sm m-auto retake__truck-btn"
                                      >
                                        Retake picture of the truck
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          this.setState({
                                            isTruckNotAvaliableCameraOn: true,
                                          })
                                        }
                                        className="btn btn-yellow btn-sm m-auto retake__load-btn"
                                      >
                                        Take picture of the truck
                                      </button>
                                    )}{" "}
                                  </>
                                )}
                              </div>
                            </li>
                          </ul>
                        </section>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="picture__of-load-section">
                          <ul className="list picture__list">
                            <li className="items">
                              <h2 className="title">Picture of Load</h2>
                              {this.state.isCameraOn && (
                                <Camera
                                  onTakePhoto={(dataUri) =>
                                    this.onTakePhoto(
                                      dataUri,
                                      "truckIsAvaliable",
                                      "isCameraOn"
                                    )
                                  }
                                  ref="fileUploader"
                                  imageType={IMAGE_TYPES.PNG}
                                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                                  isMaxResolution={true}
                                  isImageMirror={false}
                                  isSilentMode={false}
                                  isDisplayStartCameraError={true}
                                  isFullscreen={false}
                                />
                              )}
                              {!this.state.isCameraOn &&
                                this.state.pictureOfLoad !== "" && (
                                  <img
                                    className="pictureofload"
                                    src={this.state.pictureOfLoad}
                                    alt=""
                                  />
                                )}
                              {this.state.isCameraOn ? (
                                <button
                                  onClick={() => {
                                    this.setState({ isCameraOn: false });
                                  }}
                                  className="btn btn-yellow btn-sm m-auto retake__load-btn w-auto"
                                >
                                  Close Camera
                                </button>
                              ) : (
                                <>
                                  {this.state.pictureOfLoad !== "" ? (
                                    <button
                                      onClick={() => {
                                        this.setState({
                                          isCameraOn: true,
                                          pictureOfLoad: "",
                                        });
                                      }}
                                      className="btn btn-yellow btn-sm m-auto retake__load-btn w-auto"
                                    >
                                      Retake load photo
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        this.setState({ isCameraOn: true });
                                      }}
                                      className="btn btn-yellow btn-sm"
                                    >
                                      Take a photo of today’s load
                                    </button>
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                <OrderBreakDown
                {...this.props}
                  getPricingTierById={this.props.getPricingTierById}
                  fetchSetting={this.props.fetchSetting}
                  customer={this.state.selectedCustomer ?? this.state.selectedCustomerSearch}
                  totalAmountDue={this.assignTotalAmountDue}
                  cityOfOrigin={this.assignCityOfOrigin}
                  cityOfOriginName={this.state.cityOfOrigin}
                  assignFixedItem={this.assignFixedItem}
                  pricingTier={this.state.pricingTier}
                  yardage={this.assignYardage}
                  tonnage={this.assignTonnage}
                  orderDetails={this.assignOrderDetail}
                  getPricingTier={this.assignPricingTierName}
                  activeTab={this.state.activeTab}
                  totalWithFee={this.state.totalWithFee}
                  totalOrderAmmWithFee={this.state.totalOrderAmmWithFee}
                  user={this.props.user}
                  fixedItemsArray={this.state?.fixedItemsArray}
                  selectedFixedItemsArray={this.state?.addSelectedFixedItems}
                  selectedChargeByWeightArray={this.state?.chargeByweightItemsArray}
                  orderBreakDown= {this.state?.orderBreakDown}
                  clickedCompleteButton={this.state.clickedCompleteButton}
                  fixedItemsErrors={this.state?.fixedItemsErrors}
                  scaleItemsErrors={this.state?.scaleItemsErrors}
                  updateErrors={this.updateErrors}
                  showErrors={this.state.showErrors}
                  isEnvironmentalLoadFee={this.state.isEnvironmentalLoadFee}
                  isFuelSurchardeFee={this.state.isFuelSurchardeFee}
                  isSalesTax={this.state.isSalesTax}
                  saleTaxFeeAmount={this.state.saleTaxFeeAmount}
                  splitPayment={this.state.splitPayments}
                  // checkNumber={this.assigncheckNumber()}
                  selectedSplitPaymentMethods={[...this.state.selectedSplitPaymentMethods]}
                  alreadyExistselectedSplitPaymentMethods={this.state.alreadyExistselectedSplitPaymentMethods}
                  order={this.state.order}
                  isCalculteOrder={this.isCalculteOrder}
                  isCalculate={this.state.isCalculteOrder}
                  assignhandlingfee={this.assignhandlingFee}
                  parentComponentState={this.state}
                />
                <section className="note__section">
                  {  _.get(user, "company.isFuelSurchardeFee", false ) &&
                    <div className="row">
                      <div className="col-lg-12">
                        <hr />
                        <div className="display-team-status">
                          <span className="display-team-text">
                            Add a Fuel Surcharge
                          </span>
                          <Switch
                            name="isFuelSurchardeFee"
                            checked={isFuelSurchardeFee}
                            // disabled={this.state.disabledFuelSwitch}
                            onChange={() =>
                              this.setState({ isFuelSurchardeFee: !isFuelSurchardeFee })
                            }
                          />
                          <p
                            className={
                              isFuelSurchardeFee
                                ? "on-of-text text-green"
                                : "on-of-text text-red"
                            }
                          >
                            {isFuelSurchardeFee ? "Yes" : "No"}
                          </p>
                        </div>     
                      </div>
                    </div>
                  }
                  {  _.get(user, "company.isEnvironmentalLoadFee", false ) &&
                    <div className="row">
                      <div className="col-lg-12">
                        <hr />
                        <div className="display-team-status">
                          <span className="display-team-text">
                            Add Environmental Load Fee
                          </span>
                          <Switch
                            name="isEnvironmentalLoadFee"
                            checked={isEnvironmentalLoadFee}
                            // disabled={this.state.disabledFuelSwitch}
                            onChange={() =>
                              this.setState({ isEnvironmentalLoadFee: !isEnvironmentalLoadFee })
                            }
                          />
                          <p
                            className={
                              isEnvironmentalLoadFee
                                ? "on-of-text text-green"
                                : "on-of-text text-red"
                            }
                          >
                            {isEnvironmentalLoadFee ? "Yes" : "No"}
                          </p>
                        </div>     
                      </div>
                    </div>
                  }
                    {  _.get(user, "company.isSalesTax", false ) &&
                    <div className="row">
                      <div className="col-lg-12">
                        <hr />
                        <div className="display-team-status">
                          <span className="display-team-text">
                            Add a Sales Tax
                          </span>
                          <Switch
                            name="isSalesTax"
                            checked={isSalesTax}
                            onChange={() =>
                              this.setState({ isSalesTax: !isSalesTax,isCalculteOrder:true })
                            }
                          />
                          <p
                            className={
                              isSalesTax
                                ? "on-of-text text-green"
                                : "on-of-text text-red"
                            }
                          >
                            {isSalesTax ? "Yes" : "No"}
                          </p>
                        </div>  
                      </div>
                      {this.state.isSalesTax &&
                          <div className="col-md-6">
                            <div className="form-group material-textfield">
                              <NumberFormat
                                allowEmptyFormatting={true}
                                thousandSeparator={false}
                                decimalScale={2}
                                allowNegative={false}
                                displayType={"input"}
                                type="text"
                                className="form-control material-textfield-input"
                                onValueChange={_values => {
                                  const { formattedValue, floatValue, value } = _values;
                                  if (value === "") {
                                    this.setState({ saleTaxFeeAmount: value, errisSalesTax: "" , isCalculteOrder :true});
                                  } else if (floatValue <= 100) {
                                    this.setState({ saleTaxFeeAmount: formattedValue, errisSalesTax: "" , isCalculteOrder :true}, () => {
                                      this.isSalesTax(floatValue);
                                    });
                                  } else {
                                    this.setState({ errisSalesTax: "Sales Tax cannot be more than 100%." });
                                  }
                                }}
                                value={this.state.saleTaxFeeAmount}
                                name="salesTaxFeeAmount"
                                required
                              />
                              <div className="invalid-feedback text-left">{this.state.errisSalesTax}</div>
                              <label className="material-textfield-label">% of Sales Tax</label>
                            </div>
                          </div>
                        }    
                    </div>
                  }
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="note__header">
                        <h2 className="title">Notes</h2>
                        <button
                          onClick={() => this.setState({ isEditNotes: true })}
                          className="btn btn-yellow btn-sm edit-note"
                        >
                          {this.state.Notes === "" ? "Add Note" : "Edit Note"}
                        </button>
                      </div>
                      {this.state.isEditNotes ? (
                        <div className="notes__default">
                          <div className="notes__default-inner">
                            <textarea
                              name="notes"
                              className="notes__default-input textaria"
                              onChange={(e) =>
                                this.setState({ Notes: e.target.value })
                              }
                              value={this.state.Notes}
                            ></textarea>
                          </div>
                          <div className="not__buttons d-flex">
                            <button
                              type="button"
                              className="btn btn-yellow btn-sm"
                              onClick={() => this.handleNote()}
                            >
                              Save Note
                            </button>
                            <button
                              type="button"
                              className="btn btn-dark btn-sm"
                              onClick={() =>
                                this.setState({ isEditNotes: false })
                              }
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        this.state.saveNote !== "" && (
                          <div className="note__body">
                            <p>{this.state.saveNote} </p>
                          </div>
                        )
                      )}
                      <div className="row">
                            <div className="note__header note__header-leed-diversion w-100">
                              <div className="col-md-7 col-sm-7 col-xs-7 col-xl-7 ">
                            <h2 className="title ">LEED/Diversion %</h2>
                            <h3 className="sub__title">
                              {this.state.sustainabilityInfo?.address}
                            </h3>
                          </div>
                          <div className="col-md-5 col-sm-5 col-xs-5 col-xl-5">
                              <button
                              onClick={() =>
                                this.setState({
                                  isSustainabilityInformationModelOpen: true,
                                })
                              }
                              className="btn btn-yellow btn-sm edit-note float-right"
                            >
                              {this.state.sustainabilityInfo?.selectedFields
                                ?.length > 0 ||
                              this.state.sustainabilityInfo?.displayName !== "" ||
                              this.state.sustainabilityInfo?.address !== "" ||
                              this.state.sustainabilityInfo?.containersize !== ""
                                ? "Edit Sustainability Information"
                                : "Add Sustainability Information"}
                            </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.sustainabilityInfo?.selectedFields?.length >
                    0 && (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="delivery__address-data">
                          {/* <p className='address'>
                            
                          </p> */}
                          <table className="delivery__address-table">
                            {this.state.sustainabilityInfo?.tonnagepercentage
                              ?.length > 0 &&
                              this.state.sustainabilityInfo?.tonnagepercentage.map(
                                (item, index) => (
                                  <tr className="delivery__data-list'">
                                    {item.value > 0 && (
                                      <>
                                        <td className="items">
                                          <h3>{item.key} % (yards)</h3>
                                          <p>
                                            {
                                              this.state.sustainabilityInfo[
                                                `${item.key}`
                                              ]
                                            }
                                          </p>
                                        </td>
                                        <td className="items">
                                          <h3>{item.key} % (tons)</h3>
                                          <p>{Math.trunc(item.value)}</p>
                                        </td>
                                        <td className="items">
                                          <h3>{item.key} Weight (tons)</h3>
                                          <p>
                                            {
                                              this.state.sustainabilityInfo
                                                ?.tonnageweight[index].value
                                            }
                                          </p>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                )
                              )}
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </section>
                {this.state.sustainabilityInfo?.selectedFields?.length > 0 && (
                  <section className="recycling__waste-section">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="recycling__waste-inner">
                          <ul className="list">
                            <li className="items">
                              <span className="title">Recycling %</span>
                              <span className="percentage">
                                {Math.round(
                                  this.state.sustainabilityInfo
                                    ?.recyclingpercentage
                                )
                                  ? Math.round(
                                      this.state.sustainabilityInfo
                                        ?.recyclingpercentage
                                    )
                                  : 0}
                                %
                              </span>
                            </li>
                            <li className="items">
                              <span className="title">Waste %</span>
                              <span className="percentage">
                                {Math.round(
                                  this.state.sustainabilityInfo
                                    ?.residualpercentage
                                )
                                  ? Math.round(
                                      this.state.sustainabilityInfo
                                        ?.residualpercentage
                                    )
                                  : 0}
                                %
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
              <div>
                {this.state.isSustainabilityInformationModelOpen && (
                  <SustainabilityInformationComponent
                    sustainabilityInformationComponent={
                      this.state.sustainabilityInfo
                        ? this.state.sustainabilityInfo
                        : {}
                    }
                    isSustainabilityInformationModelOpen={
                      this.state.isSustainabilityInformationModelOpen
                    }
                    onCloseClick={this.onCloseClick}
                    assignSustainabilityInfo={this.assignSustainabilityInfo}
                  />
                )}
                {this.state.isAddNewCard && (
                  <AddCardComponent
                    customerData={this.state.customerData}
                    assignCardData={this.assignCardData}
                  />
                )}
                <PaymentOptions
                  totalAmountDue={this.state.totalAmountDue}
                  totalWithFee={this.state.totalWithFee}
                  customerPaymentTerms={this.state.customer?.paymentTerms}
                  signatureData={this.assignSignature}
                  paymentMethod={this.assignPaymentMethod}
                  checkNumber={this.assigncheckData}
                  handleisSplitPaymentOption={this.handleSplitPaymentChange}
                  validateOrder={this.validateOrder}
                  isTerminalTabActive={(isTerminalTabActive) => this.setState({ isTerminalTabActive })}
                  updateFailedPaymentStatus={(isFailedPayment) => this.setState({isFailedTransaction : isFailedPayment})}
                  addNewCard={this.assignNewCard}
                  activeTab={this.activeTab}
                  activeTabState={this.state?.activeTab}
                  signatureUrl={this.state?.signatureUrl}
                  assignCard={this.assignCard}
                  updateCustomer={this.props.updateCustomer}
                  getPayabliCustomerData={this.props.getPayabliCustomerData}
                  assignPaybaliCustomerMethod={this.assignPaybaliCustomerMethod}
                  customerData={this.state.customerData}
                  paybliEnable={this.state.paybliEnable}
                  paybliDetails={this.state.paybliDetails}
                  addCard={this.props.addCard}
                  customerPayabliData={this.state.customerPayabliData}
                  chargeCustomer={this.props.chargeCustomer}
                  getPaybliTerminalDevices={this.props.getPaybliTerminalDevices}
                  chargeOnDevice={this.props.chargeOnDevice}
                  fetchDevices={this.props.fetchDevices}
                  makeTerminalTransaction={this.state.makeTerminalTransaction}
                  fetchCustomerDetail={this.fetchCustomerDetail}
                  getCustomerPayment={this.getCustomerPayment}
                  driver={driver}
                  disablePaymentLater={(status)=>{this.setState({ disablePaymentLater: status })}}
                  terminalPaymentStatus={(paymentStatus)=>{ this.setState({terminalPaymentStatus: paymentStatus, terminalStatus: paymentStatus})}}
                  handleTerminalStatus={this.handleTerminalStatus}
                  paymentType={this.state.customer.paymentType}
                  handlePaymentType={this.handlePaymentType}
                  createOrderForTerminal={this.createOrder}
                  order_id={this.state.order_id}
                  splitPayments={this.updateSplitPaymnet}
                  UpdateSelectedSplitPaymentMethods={this.updateSelectedSplitpayments}
                  chargeCreditCard={this.chargeCustomer}
                  SplitPaymentValidation={this.SplitPaymentValidation}
                  errorMessage={this.state.errorMessage}
                  setErrorMessage={(e) => { this.setState({ errorMessage: e }) }}
                  paidAmount={this.state.paidAmount}
                  order={this.state.order}
                  assignTerminalData={this.assignTerminalData()}
                  customerCredits={this.state.customerCredits}
                  parentComponentState={this.state}
                  isCalculteOrder={this.isCalculteOrder}
                  isCalculate={this.state.isCalculteOrder}
                  updateInProgressTerminalPayment={this.updateInProgressTerminalPayment}
                  assignSelectedCreditmemos={this.assignSelectedCreditmemos}
                  handleCompleteOrderButtonforTerminal={(payload)=>{this.setState({handleCompleteOrderButtonForTerminal:payload})}}
                  {...this.props}
                />
              </div>
              {/* <div className="pb-5" style={{textAlign: "center"}}>
                { this.state.errorMessage !== "" ? <p className="invalid-feedback d-block big-font"> { this.state.errorMessage } </p>:"" }
              </div> */}
              {this.state.isSplitPaymentOption ? <>
                {!orderId &&
                  <button
                    type="button"
                    onClick={() => this.validateOrder(true, false)}
                    disabled={
                      this.state.pleaseWait
                      || this.state?.pleaseWaitPaymentLater || this.state.disablePaymentLater || this.state.inProgressCreditCardPayment || this.state.inProgressTerminalPayment
                    }
                    className="btn btn-dark btn-lg w-100 create__order-btn mb-3"
                  >
                    {this.state?.pleaseWaitPaymentLater
                      ? "Processing Transaction…"
                      : "Collect Payment Later"}
                  </button>
                }
                <button
                  type="button"
                  onClick={() => this.validateOrder(false, false)}
                  disabled={
                    !(
                      (
                        this.state.totalAmountDue > 0 &&
                        ((!isTruckAvaliable &&
                          this.state.licensePlate !== "" &&
                          this.state.truckNumber !== "" &&
                          this.state.truckType !== "") ||
                          isTruckAvaliable))
                    ) ||
                    this.state.pleaseWait || this.state?.pleaseWaitPaymentLater
                    //  || this.state.completeOrderButtonDisable
                  }
                  className="btn btn-dark btn-lg w-100 create__order-btn"
                >
                  {this.state.pleaseWait
                    ? "Processing Transaction…"
                    : "Complete Order"}
                </button>
              </> : 
              <> 
                <div className="pb-5" style={{textAlign: "center"}}>
                  { this.state.errorMessage !== "" ? <p className={this.state.errorMessage === "Customer charged successfully" ? "invalid-feedback d-block big-font text-green": "invalid-feedback d-block big-font"}> { this.state.errorMessage } </p>:"" }
                </div> 
                {!orderId &&
                  // && this.state.customer?.paymentTerms !== "account"
                  <button
                    type="button"
                    onClick={() => this.validateOrder(true, false)}
                    disabled={
                      this.state.pleaseWait
                      //  || this.state.customer?.paymentTerms === "account" 
                      || this.state?.pleaseWaitPaymentLater || this.state.disablePaymentLater || this.state.inProgressCreditCardPayment || this.state.inProgressTerminalPayment
                    }
                    className="btn btn-dark btn-lg w-100 create__order-btn mb-3"
                  >
                    {this.state?.pleaseWaitPaymentLater
                      ? "Processing Transaction…"
                      : "Collect Payment Later"}
                  </button>
                }
                {(user?.permissions?.permissionOptions && user?.permissions?.permissionOptions?.completeOrder === true)? (<button
                  type="button"
                    onClick={() => this.validateOrder(false, false)}
                    disabled={
                      !(
                        (this.state?.customer?.paymentTerms === "account" &&
                          this.state.totalAmountDue > 0 &&
                          ((!isTruckAvaliable &&
                            this.state.licensePlate !== "" &&
                            this.state.truckNumber !== "" &&
                            this.state.truckType !== "") ||
                            isTruckAvaliable)) ||
                        (this.state.selectedSplitPaymentMethods.includes("check") &&
                        this.state.checkDetails.checkNumber !== "" &&
                          ((!isTruckAvaliable &&
                            this.state.licensePlate !== "" &&
                            this.state.truckNumber !== "" &&
                            this.state.truckType !== "") ||
                            isTruckAvaliable)) ||
                          (this.state.selectedSplitPaymentMethods.includes("cash") &&
                          ((!isTruckAvaliable &&
                            this.state.licensePlate !== "" &&
                            this.state.truckNumber !== "" &&
                            this.state.truckType !== "") ||
                            isTruckAvaliable))  
                            || (
                              this.state.selectedSplitPaymentMethods.includes("credit-card") &&
                              this.state.cardId !== "" && this.state.cardId !== undefined &&  
                              ((!isTruckAvaliable &&
                                this.state.licensePlate !== "" &&
                                this.state.truckNumber !== "" &&
                                this.state.truckType !== "") ||
                                isTruckAvaliable)
                            )
                            || (
                              this.state.handleCompleteOrderButtonForTerminal
                            )
                      )  || 
                      this.state.pleaseWait || this.state?.pleaseWaitPaymentLater || this.state.inProgressTerminalPayment 
                      // || this.state.completeOrderButtonDisable
                    }
                    className="btn btn-dark btn-lg w-100 create__order-btn"
                  >
                    {this.state.pleaseWait
                      ? "Processing Transaction…"
                    : "Complete Order"}
                </button>):null}
                <div className="pb-5"></div>
            </> }
            </>
          )}

          <Drawer
            title="Basic Drawer"
            placement="right"
            closable={false}
            onClose={() => this.setState({ isVisible: false })}
            visible={this.state.isVisible}
            className="select__driver-drawer creat__order-drawer"
          >
            {isCompany ? (
              <div className="creat__order-drawer-inner">
                <div className="head-section-header d-flex align-items-center justify-content-between">
                  <div className="head-section-title">Select Company</div>
                  <div className="head-section-cta">
                    <div
                      onClick={() => this.setState({ isVisible: false })}
                      className="btn btn-link btn-close pr-0 btn-back- mobpad-"
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <div className="form-group material-textfield">
                  <DebounceInput
                    type="text"
                    className="form-control"
                    onChange={this.handleCompanySearch}
                    value={this.state.companySearchText}
                    debounceTimeout={1000}
                    required
                  />
                  <label className="material-textfield-label">
                    Search Company
                  </label>
                </div>
                <ul className="select_driver-list box-shadow">
                {this.state.isLoading &&   
                    <Spin  className="select_driver-list-spiner" indicator={antIcon} spinning={this.state.isLoading}></Spin>
                    }
                  {customers.map((customer) => {
                    return (
                      <li
                        className={
                          String(selectedCustomer && selectedCustomer._id) ===
                          String(customer._id)
                            ? "active"
                            : ""
                        }
                        key={customer._id}
                      >
                        <div className=" filter_list-inner d-flex align-items-center">
                          <p className="driver__name">
                            {_.get(customer, "displayName", "")}
                          </p>
                        </div>
                        <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                          <input
                            type="checkbox"
                            className="custom-checkbox--input"
                            onChange={() =>
                              this.setState({ selectedCustomer: customer , errorMessage : "" , completeOrderButtonDisable : false})
                            }
                            checked={
                              String(
                                selectedCustomer && selectedCustomer._id
                              ) === String(customer._id)
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-20 mb-20">
                  {customers.length > 0 ? (
                    <button
                      className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                      onClick={() => this.assignCustomer()}
                    >
                      Assign Company
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.state.isProjectDrawerOpen && 
              <div className="creat__order-drawer-inner">
              { !this.state.isCreateNewProject ?
                <>
                  <div className="head-section-header d-flex align-items-center justify-content-between">
                    <div className="head-section-title">Select Project</div>

                    <div className="head-section-cta">
                      {customer && (
                        <button
                          className="btn btn-dark btn-sm mr-3 smbtn"
                          onClick={() =>
                            this.setState({
                              isCreateNewProject:true
                            })
                          }
                        >
                          Create Project
                        </button>
                      )}
                      <div
                        onClick={() => this.setState({ isVisible: false })}
                        className="btn btn-link btn-close pr-0"
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form-group material-textfield">
                    <input
                      type="text"
                      className="form-control material-textfield-input"
                      onChange={this.handleProjectSearch}
                      value={this.state.projectSearchText}
                      required
                    />
                    <label className="material-textfield-label">
                      Search Project
                    </label>
                  </div>
                  <ul className="select_driver-list box-shadow">
                  
                  { <Spin indicator={antIcon} spinning={this.state.projectSpinning}>
                    {this.state.projectsData.length >0 && this.state.projectsData.map((d) => {
                      return (
                          <>
                        {d?.projectStatus === "active" &&
                        <li
                          className={
                            String(this.state.selectedProjectData && this.state.selectedProjectData._id) ===
                            String(d._id)
                              ? "active"
                              : ""
                          }
                          key={d._id}
                        >
                          <div className="filter_list-inner d-flex align-items-center">
                            <p className="driver__name">
                              {d.projectName} 
                            </p>
                          </div>
                          <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                            <input
                              type="checkbox"
                              className="custom-checkbox--input"
                              onChange={() => {
                                this.setState({ selectedProjectData: d , errorMessage: "", completeOrderButtonDisable : false});
                              }}
                              checked={
                                String(
                                  this.state.selectedProjectData && this.state.selectedProjectData._id
                                ) === String(d._id)
                                  ? true
                                  : false
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                         }
                        </>
                        );
                      })}
                      </Spin>
                   }
              
                  </ul>
                  <div className="mt-20 mb-20">
                    {this.state.projectsData.length > 0 ? (
                      <button
                        className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                        onClick={this.assignProject}
                      >
                        Assign Project
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </>
                :
                <>
                <CreateProject 
                 customerData={this.state.customer}
                 onClose={() =>
                  this.setState({
                    isCreateDrawerOpen: false,
                    isTruck: false,
                    isCompany: false,
                    isDriver: false,
                    isProjectDrawerOpen:true,
                    isCreateNewProject:false,
                    createDriverSidebar: false,
                    rotation: 0,
                  },()=>{
                   this.fetchProject()
                  })
                }
                  {...this.props}
                />
                </>
              }
            </div>
            }
            {isDriver && (
              <div className="creat__order-drawer-inner">
                {
                  <>
                    <div className="head-section-header d-flex align-items-center justify-content-between">
                      <div className="head-section-title">Select Driver</div>
                      <div className="head-section-cta">
                        {customer && (
                          <button
                            className="btn btn-dark btn-sm mr-3 smbtn"
                            onClick={() =>
                              this.setState({
                                isLicenseCamera: true,
                                createDriverSidebar: true,
                                isDriver: false,
                              })
                            }
                          >
                            Create Driver
                          </button>
                        )}
                        <div
                          onClick={() => this.setState({ isVisible: false })}
                          className="btn btn-link btn-close pr-0"
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form-group material-textfield">
                      <input
                        type="text"
                        className="form-control material-textfield-input"
                        onChange={this.handleDriverSearch}
                        value={this.state.driverSearchText}
                        required
                      />
                      <label className="material-textfield-label">
                        Search Driver
                      </label>
                    </div>
                    <ul className="select_driver-list box-shadow">
                      {drivers.map((d) => {
                        return (
                          <li
                            className={
                              String(selectedDriver && selectedDriver._id) ===
                              String(d._id)
                                ? "active"
                                : ""
                            }
                            key={d._id}
                          >
                            <div className="filter_list-inner d-flex align-items-center">
                              <div className="thumb-preview">
                                <img
                                  style={{
                                    transform: `rotate(${d.rotation}deg)`,
                                  }}
                                  src={d.licenseImageUrl}
                                  alt=""
                                />
                              </div>
                              <p className="driver__name">
                                {d.firstName} {d.lastName}
                              </p>
                            </div>
                            <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                              <input
                                type="checkbox"
                                className="custom-checkbox--input"
                                onChange={() => {
                                  this.setState({ selectedDriver: d });
                                }}
                                checked={
                                  String(
                                    selectedDriver && selectedDriver._id
                                  ) === String(d._id)
                                    ? true
                                    : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="mt-20 mb-20">
                      {drivers.length > 0 ? (
                        <button
                          className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn"
                          onClick={this.assignDriver}
                        >
                          Assign Driver
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                }
              </div>
            )}
            {this.state.createDriverForm && (
              <Formik
                enableReinitialize
                initialValues={this.state.driverInitialValues}
                validationSchema={driverSchema}
                onSubmit={(values, formikProps) =>
                  this.submitDriverForm(values, formikProps)
                }
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    style={{ display: false ? "none" : "" }}
                  >
                    <CreateDriver
                      {...this.props}
                      isSubmitting={isSubmitting}
                      values={values}
                      handleChange={handleChange}
                      onTakePhoto={(dataUri) =>
                        this.onTakePhoto(
                          dataUri,
                          "licenseImageUrl",
                          "isLicenseCamera"
                        )
                      }
                      licenseImageUrl={this.state.licenseImageUrl}
                      isLicenseCamera={this.state.isLicenseCamera}
                      onClose={() =>
                        this.setState({
                          isCreateDrawerOpen: true,
                          isTruck: false,
                          isCompany: false,
                          isDriver: false,
                          createDriverSidebar: true,
                          createDriverForm: false,
                          rotation: 0,
                          licenseImageUrl: "",
                          isLicenseCamera: true,
                        })
                      }
                      rotation={this.state.rotation}
                      setRotation={() =>
                        this.setState({ rotation: this.state.rotation + 45 })
                      }
                      onCrop={(img) => this.onCropImage(img)}
                    />
                  </form>
                )}
              </Formik>
            )}
            {this.state.createDriverSidebar && (
              <NewDriver
                onClose={() =>
                  this.setState({
                    isCreateDrawerOpen: true,
                    isTruck: false,
                    isCompany: false,
                    isDriver: true,
                    createDriverSidebar: false,
                    rotation: 0,
                  })
                }
                setInitialState={({ state }) => this.setInitialState({ state })}
                user={user}
                driverDetails={this.props.driverDetails}
              />
            )}
            {isTruck ? (
              <div className="creat__order-drawer-inner">
                <div className="head-section-header d-flex align-items-center justify-content-between">
                  <div className="head-section-title">
                    Select Truck for {customer && customer.displayName}
                  </div>
                  <div className="head-section-cta">
                    <div
                      onClick={() => this.setState({ isVisible: false })}
                      className="btn btn-link btn-back"
                    >
                      <CloseIcon />
                    </div>
                  </div>
                </div>
                <div className="form-group material-textfield">
                  <input
                    type="text"
                    className="form-control material-textfield-input"
                    onChange={this.handleTruckSearch}
                    value={this.state.truckSearchText}
                    required
                  />
                  <label className="material-textfield-label">
                    Select Truck
                  </label>
                </div>
                <ul className="select_driver-list select__truck-list box-shadow">
                  {trucks?.map((t) => {
                    return (
                      <li
                        className={
                          String(selectedTruck && selectedTruck._id) ===
                          String(t._id)
                            ? "active"
                            : ""
                        }
                        key={t._id}
                      >
                        <div className="d-flex flex-column">
                          <div className="driver__name">
                            <span>License plate number:</span>
                            {t?.plateNumber}
                          </div>
                          <div className="driver__name">
                            <span>Truck Number:</span>
                            {t?.number}
                          </div>
                        </div>
                        <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                          <input
                            type="checkbox"
                            className="custom-checkbox--input"
                            onChange={() => this.setState({ selectedTruck: t })}
                            checked={
                              String(selectedTruck && selectedTruck._id) ===
                              String(t._id)
                                ? true
                                : false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-20 mb-20">
                  {trucks.length > 0 ? (
                    <button
                      className="btn btn-dark btn-lg w-100 assign__driver-btn select__drawer-btm-btn "
                      onClick={this.assignTruck}
                    >
                      Assign Truck
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <br />
              </div>
            ) : (
              ""
            )}
          </Drawer>
          {this.state.createCustomerModelOpen && (
            <CreateCustomer
              createCustomerModelOpen={this.state.createCustomerModelOpen}
              closeModel={this.closeModel}
              assignNewCustomer={this.assignNewCustomer}
            />
          )}
        </main>
      </div>
    );
  }
}
