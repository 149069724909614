import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken } from '../../store/user/duck'
import { fetchCustomer, updateCustomer } from "../../store/customer/duck";
import DumpRatesComponent from './component'

const DumpRatesContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    updateUserToken,
    fetchCustomer,
    updateCustomer
  }
)(DumpRatesComponent);

export default DumpRatesContainer
