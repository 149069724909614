import React, { Component } from "react";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import NotFound from "../../NoRecords/component";
export default class FailedPayments extends Component {
  navigate = (orderId, is_create_charge_order) => {
    this.props.history.push({ pathname: is_create_charge_order ? `/create-charge/${orderId}` : `/pending-order-details/${orderId}` });
  };
  truncateText(text, maxLength) {
    if (!text || text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  }
  render() {
    const { orders, 
   setOrder } = this.props;
    const tz = localStorage.getItem("timezone") || moment?.tz?.guess()
    return (
      <>
        {orders.length === 0 ? (
          <NotFound title="No Orders Found" />
        ) : (
          <div className="table-responsive">
            <table className="table custom-table">
              <thead>
                <tr>
                  <th 
                  >
                    Ticket # 
                  </th>
                  <th
                  >
                   License Plate Number # 
                  </th>
                  <th 
                  >
                    {`Date & Time`} 
                  </th>
                  <th 
                  >
                    Company Name 
                  </th>
                  <th 
                  >
                    Cust Ref #
                  </th>
                  <th 
                  >
                    Net Weight(lbs) 
                  </th>
                  <th onClick={() => setOrder("tonnage")}>
                    Tonnage 
                  </th>
                  <th 
                  >
                    Yardage 
                  </th>
                  <th 
                  >
                    Total Order Amount 
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => {
                  return (
                    <tr className="cursor-pointer" key={order._id} onClick={() => this.navigate(order.ticketNumber, order?.orderCreationType === "createCharge")}>
                      <td>{order.ticketNumber}</td>
                      <td>{order?.truck?.plateNumber ? order?.truck?.plateNumber : '-'}</td>
                      <td>{`${moment(order.createdAt)?.tz(tz)?.format("MM/DD/YY")} ${moment(order.createdAt)?.tz(tz)?.format(
                        "hh:mm a"
                      )}`}</td>
                      <td>{order.customer.displayName}</td>
                      <td><div>{this.truncateText(order.customerRefrence, 20) || '-'}</div></td> 
                      <td>
                        { order.netWeight > 0 ? <>
                       <NumberFormat thousandSeparator={true} displayType={"text"} value={order.netWeight} />  lbs </> : "-" }
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={order.tonnage} />
                      </td>
                      <td>
                        <NumberFormat thousandSeparator={true} displayType={"text"} value={Number(order.yardage).toFixed(2)} />
                      </td>
                      <td>
                        <NumberFormat
                          thousandSeparator={true}
                          prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          value={order.totalAmount}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}