import React, { PureComponent } from "react";
import { Dropdown, Menu } from "antd";
import { MoreIcon } from "../../../components/icons";
import ReactModal from "react-modal";
import { CloseIcon } from "../../../components/icons";
import NumberFormat from 'react-number-format';
import _ from "lodash"

export default class ResourcesComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      _id: "",
      isDeleteModal: false
    };
    this.deleteResource = this.deleteResource.bind(this);
  }

  async deleteResource() {
    const { _id } = this.state;
    const { deleteResource, fetchResources } = this.props;
    const {
      value: { status }
    } = await deleteResource(_id);
    if (status) {
      this.setState({ isDeleteModal: false }, () => {
        fetchResources();
      });
    }
  }

  deleteResourceModal({ _id }) {
    this.setState({ _id, isDeleteModal: true });
  }
  editResource(resource) {
    this.props.editResourceModal(resource);
  }

  menu(resource) {
    return (
      <Menu>
        <Menu.Item key="0" onClick={() => this.editResource(resource)}>
          Edit
        </Menu.Item>
        <Menu.Item key="1" onClick={() => this.deleteResourceModal(resource)}>
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const { resources } = this.props;
    const { isDeleteModal } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table custom-table">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Address</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {resources.map(resource => {
                    const address = resource.address.split(",");
                    return (
                      <tr key={resource._id}>
                        <td onClick={() => this.editResource(resource)} className="rowclick">
                          {_.startCase(resource.companyName)}
                        </td>
                        <td onClick={() => this.editResource(resource)} className="rowclick">
                          <div className="city__addresss">{address[0]}</div>
                          <div className="state__addresss">
                            {address[1]}, {address[2]} {address[3]}
                          </div>
                        </td>
                        <td onClick={() => this.editResource(resource)} className="rowclick">
                          {resource.phone !== "" ?
                          <NumberFormat
                            format="(###) ###-####"
                              allowEmptyFormatting={false}
                            mask=""
                            displayType={"text"}
                            value={resource.phone}
                          />
                            :
                            ""
                          }
                        </td>
                        <td onClick={() => this.editResource(resource)} className="rowclick">{resource.email}</td>
                        <td className={resource.status === 1 ? "text-green rowclick" : "text-red rowclick"} onClick={() => this.editResource(resource)}>
                          {resource.status === 1 ? "Active" : "Not Active"}
                        </td>
                        <td className="table__last-child">
                          <Dropdown
                            overlay={() => this.menu(resource)}
                            trigger={["click"]}
                            overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                          >
                            <button className="btn ant-dropdown-link">
                              <MoreIcon />
                            </button>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ReactModal ariaHideApp={false} isOpen={isDeleteModal}>
          <div className="react-modal-dialog react-modal-dialog-centered delete_modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Delete</h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => this.setState({ isDeleteModal: false })}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <div className="row">
                <div className="col-md-12">
                  <p>Are you sure you want to delete this? This cannot be undone.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-right">
                  <button onClick={this.deleteResource} className="btn btn-red btn-sm delete-btn">
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </React.Fragment>
    );
  }
}
