import { connect } from "react-redux";
// import { getMe, fetchMe } from "../../store/user/duck";
import ReceiptComponent from "./component";
import { payInvoice, getInvoiceDataForEmail, checkPabliIntegration, updatePaymentInfo } from "../../store/Billing/duck";
import { getPayabliCustomerData, makeTransaction} from '../../store/paybli_integrations/duck';
import { updateCustomer } from '../../store/customer/duck';

const ReceiptContainer = connect(
  // Map state to props
  state => ({
    // user: state.user.user,
    // userPhase: state.user.userPhase,
  }),
  {
    // getMe,
    // fetchOrder,
    //fetchMe,
    updatePaymentInfo,
    updateCustomer,
    payInvoice,
    makeTransaction,
    getPayabliCustomerData,
    getInvoiceDataForEmail,
    checkPabliIntegration
  }
)(ReceiptComponent);

export default ReceiptContainer;
