import { connect } from 'react-redux'
import { getMe, fetchMe , updateUserToken,getUserPermissions} from '../../store/user/duck'
import { deleteScale } from '../../store/scale/duck';
import {getPricingTier,getCustomerByPricing, getPricingTierById, editPricingTier} from '../../store/Pricing_tier/duck'
import { getScale } from '../../store/scale/duck';
import {
  createOutboundActivity,
  fetchOutboundActivities,
  updateOutboundActivity,
  deleteOutboundActivity
} from "../../store/outboundactivity/duck";
import { fetchResourcesList } from '../../store/resource/duck'
import OutboundActivityComponent from './component'
import { fetchSetting , updateCustomEmail} from '../../store/company/duck'
import { getPaybliIntegration } from '../../store/paybli_integrations/duck';
import { verifyQBIntegration } from "../../store/dashboard/duck";
import { getCompanyById } from "../../store/Billing/duck";
import { updateSignaturePadSetting } from "../../store/signature_pad/duck";

const OutboundActivityContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    getScale,
    fetchMe,
    createOutboundActivity,
    updateUserToken,
    fetchOutboundActivities,
    fetchResourcesList,
    updateOutboundActivity,
    deleteOutboundActivity,
    fetchSetting,
    deleteScale,
    getPricingTier,
    getCustomerByPricing,
    getPricingTierById,
    getUserPermissions,
    getPaybliIntegration,
    verifyQBIntegration,
    getCompanyById,
    updateCustomEmail,
    editPricingTier,
    updateSignaturePadSetting
  }
)(OutboundActivityComponent);

export default OutboundActivityContainer
