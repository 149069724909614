import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import TickIcon from "../../../images/tick.svg";
import "react-image-crop/dist/ReactCrop.css";

export default function App({ licenseImageUrl, setIsCrop }) {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", x: 20, y: 20, height: 50, width: 50 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const getResizedCanvas = (canvas, newWidth, newHeight) => {
    const tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    const ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);

    return tmpCanvas;
  };

  const generateDownload = (previewCanvas, crop) => {
    if (!crop || !previewCanvas) {
      return;
    }

    const dpr = window.devicePixelRatio || 1;
    const canvas = dpr !== 1 ? getResizedCanvas(previewCanvas, crop.width, crop.height) : previewCanvas;

    canvas.toBlob(
      blob => {
        setIsCrop(blob);
      },
      "image/png",
      1
    );
  };
  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const dpr = window.devicePixelRatio || 1;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * dpr;
    canvas.height = crop.height * dpr;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * dpr,
      crop.height * dpr
    );
  }, [completedCrop]);

  return (
    <div className="App">
      <ReactCrop
        src={licenseImageUrl}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={c => setCrop(c)}
        onComplete={c => setCompletedCrop(c)}
      />
      <div className="crop__rotate-icon">
        <span
          className="crop_right-icon"
          onClick={() => {
            generateDownload(previewCanvasRef.current, completedCrop);
          }}
          title="Done"
        >
          <img className="img-fluid" src={TickIcon} alt="" />
        </span>
      </div>
      <div>
        <canvas ref={previewCanvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
}
