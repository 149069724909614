import { connect } from "react-redux";
import { uploadImage, fetchMe, updateUserToken } from "../../store/user/duck";
import {
  fetchOrder,
  updateOrder,
  sendEmailReceipt,
  deleteOrder,
  updateTotalAmount,
  getOrderInvoicePdf,
} from "../../store/order/duck";
import { getPricingTierById } from "../../store/Pricing_tier/duck";
import PrintOrderDetailsComponent from "./component";

const PrintOrderDetailsContainer = connect(
  // Map state to props
  (state) => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
  }),
  {
    getOrderInvoicePdf,
    fetchOrder,
    updateUserToken,
    updateOrder,
    fetchMe,
    sendEmailReceipt,
    uploadImage,
    deleteOrder,
    updateTotalAmount,
    getPricingTierById,
  }
)(PrintOrderDetailsComponent);

export default PrintOrderDetailsContainer;
