import React, { PureComponent } from 'react'
import { Switch } from "antd";
import { CloseIcon } from '../../components/icons'
import ArrowRight from "./../../images/arrow_right.svg";
import './styles.scss'

export default class IntegrationsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  }

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        firstName: "",
        lastName: "",
        phone: "",
        email: ""
      },
      user: null,
      isQuickbooksConnected: false,
      isStripeConnected: false
    }
  }

  componentDidMount() {
    document.title = "Integration | Weigh Works";
    this.props.getMe();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.user && this.state.user !== prevState.user) {
      this.fetchIntegrations();
    }
  }

  async fetchIntegrations() {
    const [
      { value: { status }},
      { value: { data: { stripePublicKey, stripeSecretKey } } }
    ] = await Promise.all([
      this.props.verifyQBIntegration(),
      this.props.fetchSetting(this.state.user.companyId)
    ]);
    this.setState({
      isQuickbooksConnected: status,
      isStripeConnected: (stripePublicKey && stripeSecretKey) ? true : false
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  render() {
    const { isQuickbooksConnected } = this.state;
    function onChange(checked) {
      console.log(`switch to ${checked}`);
    }
    return (
      <div>
        <div className="integrations__container">
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Integrations</div>
            <div className="head-section-cta">
              <span
                className="btn btn-link btn-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
          <div className="integrations__wrapper">
            <div className="card card__pd drivers__trucks-section">            
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table custom-table drivers__trucks-table">
                        <tbody>
                          {/* <tr className="cursor-pointer" onClick={() => this.props.history.push('/stripe-settings')}>
                            <td>
                              Stripe
                            </td>
                            <td className="last__child"> 
                              <div className="d-flex align-items-center">
                                <div className="display-team-status">
                                  <Switch checked={isStripeConnected} onChange={onChange} />
                                </div>
                                <img className="img-fluid right-arrow" src={ArrowRight} alt="" />
                              </div> 
                            </td> 
                          </tr> */}
                          <tr className="cursor-pointer" onClick={() => this.props.history.push('/quickbooks')}>
                            <td>
                              Quickbooks                              
                            </td>
                            <td className="last__child"> 
                              <div className="d-flex align-items-center">
                                <div className="display-team-status">
                                  <Switch checked={isQuickbooksConnected} onChange={onChange} />
                                </div>
                                <img className="img-fluid right-arrow" src={ArrowRight} alt="" />
                              </div> 
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              Cardknox                           
                            </td>
                            <td className="last__child"> 
                              <div className="d-flex align-items-center">
                                <div className="display-team-status">
                                  <Switch defaultChecked onChange={onChange} />
                                </div>
                                <img className="img-fluid right-arrow" src={ArrowRight} alt="" />
                              </div> 
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
