import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { BackArrowIcon } from "../../components/icons";
import "./styles.scss";
import NumberFormat from "react-number-format";
import moment from "moment";
import NoRecords from "../NoRecords/component";
import { Spin, notification } from "antd";
import PermissionDialog from "../../utils/permissionDialog";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class UserDetailsComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      activities: [],
      permissionOptions:{},
      loader: false,
      bussinessAdminCheck: false,
      generalCheck: false,
      scaleCheck: false,
      createChargeCheck: false,
      ordersVoidCheck: false,
      billingCheck: false,
      pricingSetupAdminCheck: false,
      scaleSetupAdminCheck: false,
      intergrationsCheck: false,
      userManagementCheck: false,
      reportsCheck: false,
      ordersCheck: false,
      inboundVsOutboundCheck: false,
      clientAndJobReportCheck: false,
      cashDiscrepancyCheck: false,
      deletedTicketsCheck: false,
      projectOrdersCheck: false,
      viewUserManagementTab: false,
      bussinessSetupCheck: false,
      completeOrder: false,
      exportOrdersAndReports: false,
      manuallyEnterScaleWeight: false,
      deleteOrder: false,
      createManualCharge: false,
      voidOrders: false,
      voidInvoices: false,
      syncToQuickbooks: false,
      voidPayments: false,
      editUserPermissions: false,
      editBusinessSettings: false,
      editSyncedOrders: false,
      dialogOpen: false,
      qbIntegrationStatus: false,
      pricingTier: false,
      createCustomer: false,
      viewBillingPage: false,
      currentUser:{}
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    document.title = "User Detail | Weighworks";
    const { id } = this.props.location.state;
    const { getUserDetail,fetchMe,verifyQBIntegration } = this.props;
    this.setState({ loader: true });
    const {
      value: {
        data: { user,permissions, activities }
      }
    } = await getUserDetail(id);
    const {value} = await fetchMe();
    try {
      const {value:{status}} = await verifyQBIntegration();
      if (status) {
        this.setState({ qbIntegrationStatus: true,
          userManagementCheck: permissions?.permissionOptions?.editUserPermissions && permissions?.userManagement &&  permissions?.permissionOptions?.editSyncedOrders ? true : false,
          billingCheck: permissions?.permissionOptions?.voidPayments && permissions?.permissionOptions?.voidInvoices && permissions?.permissionOptions?.viewBillingPage ? true : false && permissions?.permissionOptions?.syncToQuickbooks ? true : false,
        });
      }
    } catch (error) {
      this.setState({ qbIntegrationStatus: false,
        userManagementCheck: permissions?.permissionOptions?.editUserPermissions && permissions?.userManagement ? true : false,
        billingCheck: permissions?.permissionOptions?.voidPayments && permissions?.permissionOptions?.voidInvoices && permissions?.permissionOptions?.viewBillingPage ? true : false,
      });
      console.log(error);
    }
    this.setState({ 
      user,
      permissionOptions:permissions?.permissionOptions ? permissions?.permissionOptions: {},
      activities,
      ordersCheck:permissions?.reports?.orders,
      inboundVsOutboundCheck: permissions?.reports?.inboundVsOutbound,
      clientAndJobReportCheck: permissions?.reports?.clintAndJob,
      cashDiscrepancyCheck: permissions?.reports?.cashDiscrepancy,
      projectOrdersCheck: permissions?.reports?.projectOrders,
      deletedTicketsCheck: permissions?.reports?.deletedTickets,
      bussinessSetupCheck: permissions?.businessAdmin?.businessSetup,
      pricingSetupAdminCheck: permissions?.businessAdmin?.pricingSetup,
      scaleSetupAdminCheck:  permissions?.businessAdmin?.scaleSetup,
      intergrationsCheck:  permissions?.businessAdmin?.intergrations,
      bussinessAdminCheck: permissions?.businessAdmin?.businessSetup && permissions?.businessAdmin?.pricingSetup && permissions?.businessAdmin?.scaleSetup && permissions?.businessAdmin?.intergrations && permissions?.permissionOptions?.editBusinessSettings && permissions?.permissionOptions?.pricingTier
                           ? true :false,
      reportsCheck : permissions?.reports?.orders && permissions?.reports?.inboundVsOutbound && permissions?.reports?.clintAndJob && permissions?.reports?.cashDiscrepancy && permissions?.reports?.deletedTickets
                       ? true : false,
      completeOrder: permissions?.permissionOptions?.completeOrder,
      exportOrdersAndReports:   permissions?.permissionOptions?.exportOrdersAndReports,
      manuallyEnterScaleWeight: user?.role === "user" ? false :permissions?.permissionOptions?.manuallyEnterScaleWeight,
      deleteOrder:  permissions?.permissionOptions?.deleteOrder,  
      createManualCharge:   permissions?.permissionOptions?.createManualCharge,
      voidOrders:   permissions?.permissionOptions?.voidOrders,
      voidInvoices:   permissions?.permissionOptions?.voidInvoices,
      viewBillingPage:   permissions?.permissionOptions?.viewBillingPage,
      syncToQuickbooks:   permissions?.permissionOptions?.syncToQuickbooks,
      voidPayments:   permissions?.permissionOptions?.voidPayments,
      editUserPermissions:  permissions?.permissionOptions?.editUserPermissions,
      editBusinessSettings:   permissions?.permissionOptions?.editBusinessSettings,
      generalCheck: permissions?.permissionOptions?.completeOrder && permissions?.permissionOptions?.exportOrdersAndReports && permissions?.permissionOptions?.createCustomer ? true : false,
      scaleCheck: (user?.role === "user" && permissions?.permissionOptions?.deleteOrder) || (permissions?.permissionOptions?.manuallyEnterScaleWeight && permissions?.permissionOptions?.deleteOrder)? true : false,
      createChargeCheck: permissions?.permissionOptions?.createManualCharge ? true : false,
      ordersVoidCheck: permissions?.permissionOptions?.voidOrders ? true : false,
      viewUserManagementTab: permissions?.userManagement,
      editSyncedOrders: permissions?.permissionOptions?.editSyncedOrders,
      pricingTier: permissions?.permissionOptions?.pricingTier,
      createCustomer: permissions?.permissionOptions?.createCustomer,
      currentUser:value.user,
      loader: false
     });
  };
  /*  onChange = async (checked,name) => {
    const { permissionOptions,user } = this.state;
    permissionOptions[name] = checked;
    const payload ={id:user._id,permissions:{permissionOptions:permissionOptions}}
    const { updateUserById } = this.props;
    const {
      value:{
        permissions
      }
    } = await updateUserById(payload);
    this.setState({ permissionOptions:permissions?.permissionOptions ? permissions?.permissionOptions: {} });
  }; */
  bussinessAdminCheckboxTree = () => {
    const { bussinessAdminCheck, bussinessSetupCheck, scaleSetupAdminCheck, pricingSetupAdminCheck, intergrationsCheck,editBusinessSettings,pricingTier } = this.state
    if ((pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier) && bussinessAdminCheck) {
      this.setState({
        pricingSetupAdminCheck: false, scaleSetupAdminCheck: false,
        editBusinessSettings:false,
        pricingTier:false,
        intergrationsCheck: false, bussinessSetupCheck: false, bussinessAdminCheck: false
      })
    }
    else {
      this.setState({
        pricingSetupAdminCheck: true, scaleSetupAdminCheck: true,
        editBusinessSettings:true,
        pricingTier:true,
        intergrationsCheck: true, bussinessSetupCheck: true, bussinessAdminCheck: true
      });
    }
  }
  generalCheckboxTree = () => {
    const { completeOrder, exportOrdersAndReports,generalCheck ,createCustomer } = this.state
    if ((completeOrder && exportOrdersAndReports && createCustomer ) && generalCheck) {
      this.setState({
        completeOrder: false, exportOrdersAndReports: false,
        createCustomer: false,
        generalCheck: false
      })
    }
    else {
      this.setState({
        completeOrder: true, exportOrdersAndReports: true,
        createCustomer: true,
        generalCheck: true
      });
    }
  }
  scaleCheckboxTree = () => {
    const { manuallyEnterScaleWeight, deleteOrder,scaleCheck,user } = this.state
    if (user?.role === "user") {
      if ((deleteOrder ) && scaleCheck) {
        this.setState({
          deleteOrder: false,
          scaleCheck: false
        })
      }
      else {
        this.setState({
          deleteOrder: true,
          scaleCheck: true
        });
      }
    }else{
      if ((manuallyEnterScaleWeight && deleteOrder ) && scaleCheck) {
        this.setState({
          manuallyEnterScaleWeight: false, deleteOrder: false,
          scaleCheck: false
        })
      }
      else {
        this.setState({
          manuallyEnterScaleWeight: true, deleteOrder: true,
          scaleCheck: true
        });
      }
    }
  }
  createChargeCheckboxTree = () => {
    const { createManualCharge,createChargeCheck } = this.state
    if ((createManualCharge ) && createChargeCheck) {
      this.setState({
        createManualCharge: false,
        createChargeCheck: false
      })
    }
    else {
      this.setState({
        createManualCharge: true,
        createChargeCheck: true
      });
    }
  }
  ordersCheckboxTree = () => {
    const { voidOrders,ordersVoidCheck } = this.state
    if ((voidOrders ) && ordersVoidCheck) {
      this.setState({
        voidOrders: false,
        ordersVoidCheck: false
      })
    }
    else {
      this.setState({
        voidOrders: true,
        ordersVoidCheck: true
      });
    }
  }
  billingCheckboxTree = () => {
    const { voidPayments,voidInvoices,syncToQuickbooks,billingCheck,qbIntegrationStatus,viewBillingPage } = this.state

    if (qbIntegrationStatus) {
      if ((voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage ) && billingCheck) {
        this.setState({
          voidPayments: false,
          voidInvoices: false,
          syncToQuickbooks: false,
          billingCheck: false,
          viewBillingPage:false
        })
      }
      else {
        this.setState({
          voidPayments: true,
          voidInvoices: true,
          syncToQuickbooks: true,
          billingCheck: true,
          viewBillingPage:true
        });
      }
    }else{
      if ((voidPayments && voidInvoices  && viewBillingPage ) && billingCheck) {
        this.setState({
          voidPayments: false,
          voidInvoices: false,
          syncToQuickbooks: false,
          billingCheck: false,
          viewBillingPage:false
        })
      }
      else {
        this.setState({
          voidPayments: true,
          voidInvoices: true,
          syncToQuickbooks: false,
          billingCheck: true,
          viewBillingPage:true
        });
      }
    }
  }
  userManagementCheckboxTree = (e) => {
    const {checked} = e.target;
    const { editUserPermissions,viewUserManagementTab,editSyncedOrders,userManagementCheck,qbIntegrationStatus } = this.state
    if (qbIntegrationStatus) {
      if (checked) {
        if ((editUserPermissions && viewUserManagementTab && editSyncedOrders ) && userManagementCheck) {
          this.setState({
            editUserPermissions: false,
            viewUserManagementTab: false,
            userManagementCheck: false,
            editSyncedOrders:false
          })
        }
        else {
          this.setState({
            editUserPermissions: true,
            viewUserManagementTab: true,
            editSyncedOrders:true,
            userManagementCheck: true
          });
        }
        this.setState({ dialogOpen: true,editSyncedOrders:true });
      }else{
        this.setState({ dialogOpen: false,
          editUserPermissions: false,
          viewUserManagementTab: false,
          userManagementCheck: false,
          editSyncedOrders:false 
        });
      }
    }else{
      if ((editUserPermissions && viewUserManagementTab  ) && userManagementCheck) {
        this.setState({
          editUserPermissions: false,
          viewUserManagementTab: false,
          userManagementCheck: false,
          editSyncedOrders:false 
        })
      }
      else {
        this.setState({
          editUserPermissions: true,
          viewUserManagementTab: true,
          userManagementCheck: true,
          editSyncedOrders:false 
        });
      }
    }
    
  }

  reportsCheckboxTree = () => {
    const { reportsCheck, ordersCheck, inboundVsOutboundCheck, clientAndJobReportCheck, cashDiscrepancyCheck,deletedTicketsCheck ,projectOrdersCheck} = this.state
    if ((ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck && projectOrdersCheck) && reportsCheck) {
      this.setState({
        ordersCheck: false, inboundVsOutboundCheck: false,
        clientAndJobReportCheck: false, cashDiscrepancyCheck: false, reportsCheck: false,deletedTicketsCheck:false,projectOrdersCheck:false,
      })
    }
    else {
      this.setState({
        ordersCheck: true, inboundVsOutboundCheck: true,
        clientAndJobReportCheck: true, cashDiscrepancyCheck: true, reportsCheck: true,deletedTicketsCheck:true ,projectOrdersCheck:true,
      })
    }
  }
  
reportCheckbox =()=>{
  const {  ordersCheck, inboundVsOutboundCheck, clientAndJobReportCheck, cashDiscrepancyCheck,deletedTicketsCheck ,projectOrdersCheck} = this.state
    if(!(ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck ,projectOrdersCheck)){
      this.setState({reportsCheck:false})
    }
    if((ordersCheck && inboundVsOutboundCheck && clientAndJobReportCheck && cashDiscrepancyCheck && deletedTicketsCheck ,projectOrdersCheck)){
      this.setState({reportsCheck:true})
    }
  }

  bussinessAdminCheckbox =()=>{
    const {  bussinessSetupCheck, scaleSetupAdminCheck, pricingSetupAdminCheck, intergrationsCheck,editBusinessSettings,pricingTier } = this.state
    if(!(pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier)){
      this.setState({bussinessAdminCheck:false})
    }
    if((pricingSetupAdminCheck && scaleSetupAdminCheck && intergrationsCheck && bussinessSetupCheck && editBusinessSettings && pricingTier)){
      this.setState({bussinessAdminCheck:true})
    }
  }
  generalCheckbox =()=>{
    const {  completeOrder, exportOrdersAndReports,createCustomer } = this.state
    if(!(completeOrder && exportOrdersAndReports && createCustomer)){
      this.setState({generalCheck:false})
    }
    if((completeOrder && exportOrdersAndReports && createCustomer)){
      this.setState({generalCheck:true})
    }
  }
  scaleCheckbox =()=>{
    const {  deleteOrder, manuallyEnterScaleWeight,user } = this.state
    if (user?.role === "user") {
      if(!(deleteOrder )){
        this.setState({scaleCheck:false})
      }
      if((deleteOrder )){
        this.setState({scaleCheck:true})
      }
    }else{

      if(!(deleteOrder && manuallyEnterScaleWeight )){
        this.setState({scaleCheck:false})
      }
      if((deleteOrder && manuallyEnterScaleWeight )){
        this.setState({scaleCheck:true})
      }
    }
  }
  createChargeCheckbox =()=>{
    const {  createManualCharge } = this.state
    if(!(createManualCharge )){
      this.setState({createChargeCheck:false})
    }
    if((createManualCharge)){
      this.setState({createChargeCheck:true})
    }
  }
  ordersCheckbox =()=>{
    const {  voidOrders } = this.state
    if(!(voidOrders )){
      this.setState({ordersVoidCheck:false})
    }
    if((voidOrders)){
      this.setState({ordersVoidCheck:true})
    }
  }
  billingCheckbox =()=>{
    const {  voidPayments,voidInvoices,syncToQuickbooks,qbIntegrationStatus,viewBillingPage } = this.state
    if (qbIntegrationStatus) {
      if(!(voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage)){
        this.setState({billingCheck:false})
      }
      if((voidPayments && voidInvoices && syncToQuickbooks && viewBillingPage)){
        this.setState({billingCheck:true})
      }
    }else{
      if(!(voidPayments && voidInvoices && viewBillingPage  )){
        this.setState({billingCheck:false})
      }
      if((voidPayments && voidInvoices && viewBillingPage)){
        this.setState({billingCheck:true})
      }
    }
  }
  userManagementCheckbox =()=>{
    const {  viewUserManagementTab,editUserPermissions,editSyncedOrders,qbIntegrationStatus } = this.state
    if (qbIntegrationStatus) {
      if(!(viewUserManagementTab && editUserPermissions && editSyncedOrders )){
        this.setState({userManagementCheck:false})
      }
      if((viewUserManagementTab && editUserPermissions && editSyncedOrders)){
        this.setState({userManagementCheck:true})
      }
    }else{
      if(!(viewUserManagementTab && editUserPermissions )){
        this.setState({userManagementCheck:false})
      }
      if((viewUserManagementTab && editUserPermissions)){
        this.setState({userManagementCheck:true})
      }
    }
  }
  handleFinish = async () => {
    this.setState({ loader: true });
    notification.destroy();
    const { user,qbIntegrationStatus } = this.state;
    const payload = {
      id: user._id,
      permissions:{
        businessAdmin:{
          businessSetup:this.state.bussinessSetupCheck,
          pricingSetup:this.state.pricingSetupAdminCheck,
          scaleSetup:this.state.scaleSetupAdminCheck,
          intergrations:this.state.intergrationsCheck,
        },
        userManagement:this.state.viewUserManagementTab,
        reports:{
          orders:this.state.ordersCheck,
          inboundVsOutbound:this.state.inboundVsOutboundCheck,
          clintAndJob:this.state.clientAndJobReportCheck,
          cashDiscrepancy:this.state.cashDiscrepancyCheck,
          deletedTickets:this.state.deletedTicketsCheck,
          projectOrders:this.state.projectOrdersCheck,
        },
        permissionOptions:{
          completeOrder: this.state.completeOrder,
          exportOrdersAndReports:   this.state.exportOrdersAndReports,
          manuallyEnterScaleWeight:  user?.role === "user" ? false : this.state.manuallyEnterScaleWeight,
          deleteOrder:  this.state.deleteOrder,  
          createManualCharge:   this.state.createManualCharge,
          voidOrders:   this.state.voidOrders,
          voidInvoices:   this.state.voidInvoices,
          viewBillingPage:   this.state.viewBillingPage,
          syncToQuickbooks:   this.state.syncToQuickbooks,
          voidPayments:   this.state.voidPayments,
          editUserPermissions:  this.state.editUserPermissions,
          editBusinessSettings:   this.state.editBusinessSettings,
          editSyncedOrders:  this.state.editSyncedOrders,
          pricingTier : this.state.pricingTier,
          createCustomer : this.state.createCustomer,
        }
      }
    };
    const { updateUserById } = this.props;
    const {
      value: {
        permissions,
        status,
        message
      }
    } = await updateUserById(payload);
    if (status) {
      if (qbIntegrationStatus) {
        this.setState({
        billingCheck: permissions?.permissionOptions?.voidPayments && permissions?.permissionOptions?.voidInvoices && permissions?.permissionOptions?.viewBillingPage &&  permissions?.permissionOptions?.syncToQuickbooks ? true : false,
        userManagementCheck: permissions?.permissionOptions?.editUserPermissions && permissions?.userManagement && permissions?.permissionOptions?.editSyncedOrders ? true : false,
        })
      }else{
        this.setState({
          billingCheck: permissions?.permissionOptions?.voidPayments && permissions?.permissionOptions?.voidInvoices && permissions?.permissionOptions?.viewBillingPage ? true : false,
          userManagementCheck: permissions?.permissionOptions?.editUserPermissions && permissions?.userManagement ? true : false,
        })
      }
      this.setState({
        ordersCheck:permissions?.reports?.orders,
        inboundVsOutboundCheck: permissions?.reports?.inboundVsOutbound,
        clientAndJobReportCheck: permissions?.reports?.clintAndJob,
        cashDiscrepancyCheck: permissions?.reports?.cashDiscrepancy,
        projectOrdersCheck: permissions?.reports?.projectOrders,
        deletedTicketsCheck: permissions?.reports?.deletedTickets,
        bussinessSetupCheck: permissions?.businessAdmin?.businessSetup,
        pricingSetupAdminCheck: permissions?.businessAdmin?.pricingSetup,
        scaleSetupAdminCheck:  permissions?.businessAdmin?.scaleSetup,
        intergrationsCheck:  permissions?.businessAdmin?.intergrations,
        bussinessAdminCheck: permissions?.businessAdmin?.businessSetup && permissions?.businessAdmin?.pricingSetup && permissions?.businessAdmin?.scaleSetup && permissions?.businessAdmin?.intergrations && permissions?.permissionOptions?.editBusinessSettings && permissions?.permissionOptions?.pricingTier
                             ? true :false,
        reportsCheck : permissions?.reports?.orders && permissions?.reports?.inboundVsOutbound && permissions?.reports?.clintAndJob && permissions?.reports?.cashDiscrepancy && permissions?.reports?.deletedTickets
                         ? true : false,
        completeOrder: permissions?.permissionOptions?.completeOrder,
        exportOrdersAndReports:   permissions?.permissionOptions?.exportOrdersAndReports,
        manuallyEnterScaleWeight: user?.role === "user" ? false :  permissions?.permissionOptions?.manuallyEnterScaleWeight,
        deleteOrder:  permissions?.permissionOptions?.deleteOrder,  
        createManualCharge:   permissions?.permissionOptions?.createManualCharge,
        voidOrders:   permissions?.permissionOptions?.voidOrders,
        voidInvoices:   permissions?.permissionOptions?.voidInvoices,
        viewBillingPage:   permissions?.permissionOptions?.viewBillingPage,
        syncToQuickbooks:   permissions?.permissionOptions?.syncToQuickbooks,
        voidPayments:   permissions?.permissionOptions?.voidPayments,
        editUserPermissions:  permissions?.permissionOptions?.editUserPermissions,
        editBusinessSettings:   permissions?.permissionOptions?.editBusinessSettings,
        generalCheck: permissions?.permissionOptions?.completeOrder && permissions?.permissionOptions?.exportOrdersAndReports && permissions?.permissionOptions?.createCustomer ? true : false,
        scaleCheck: (user?.role === "user" && permissions?.permissionOptions?.deleteOrder) || (permissions?.permissionOptions?.manuallyEnterScaleWeight && permissions?.permissionOptions?.deleteOrder)? true : false,
        createChargeCheck: permissions?.permissionOptions?.createManualCharge ? true : false,
        ordersVoidCheck: permissions?.permissionOptions?.voidOrders ? true : false,
        editSyncedOrders: permissions?.permissionOptions?.editSyncedOrders,
        pricingTier : permissions?.permissionOptions?.pricingTier ,
        createCustomer : permissions?.permissionOptions?.createCustomer,
        loader: false
       });
       notification.success({message:"User Permissions updated successfully"})
    }else{
      notification.error({message:message})
    }
   
  };
  dialogOpen = (e) => {
    const {checked} = e.target;
    if (checked) {
      this.setState({ dialogOpen: true,editSyncedOrders:true });
    }else{
      this.setState({ dialogOpen: false,editSyncedOrders:false,userManagementCheck: false});
    }
  }
  render() {
    const { user, activities,currentUser,qbIntegrationStatus } = this.state;
    return (
      <div className="customer__details-wrapper">
        <div className="custome__container">
          <div className="col-md-12">
            <div className="btn-back-header">
              <button className="btn btn-link btn-back">
                <Link to={"/user-management"}>
                  <BackArrowIcon />
                  Back
                </Link>
              </button>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <h5 className="card-title">
                        {user && _.startCase(`${user?.firstName}`)} {user && _.startCase(`${user?.lastName}`)}
                      </h5>
                      {/*<button className="btn btn-dark btn-sm">Edit {user && _.startCase(`${user.firstName}`)}'s Information</button>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card__body-inner">
                      <div className="card__left">
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Email</h6>
                              <p className="sub__title underline">{user && user.email}</p>
                            </div>
                          </li>
                        </ul>
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Phone Number</h6>
                              <p className="sub__title underline">
                                <NumberFormat
                                  format="(###) ###-####"
                                  allowEmptyFormatting
                                  mask=""
                                  displayType={"text"}
                                  value={user && user.phone}
                                />
                              </p>
                            </div>
                          </li>
                        </ul>
                            </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card__body-inner">
                      <div className="card__left">
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Role</h6>
                              <p className="sub__title">{user && _.startCase(`${user?.role}`)}</p>
                            </div>
                          </li>
                        </ul>
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Status</h6>
                              <p className="sub__title">{user && user.status === 1 ? "Active" : "Not Active"}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12 col-xs-12 col-xl-6">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="card-title">Permissions</h5>
                </div>
              </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 col-xl-6">
                    <div className="text-right text-nowrap">
                    <button disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true} className="btn btn-dark btn-md w-25 mb-2" onClick={this.handleFinish}>
                      Save
                    </button>
                    <br />
                    {currentUser?.permissions?.permissionOptions?.editUserPermissions === true ?null:this.state.loader === true ? null:<small className="text-danger">You don't have permission to edit user permissions</small>}
                </div>
              </div>
                </div>
              </div>{" "}
              <Spin spinning={this.state.loader} indicator={antIcon} ><div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                  <div className="checkboxtree__section">
                      <div className="checkboxtree__body">
                        <div className="checkbox__tree">
                          <ul className="checkbox__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                General 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.generalCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.generalCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Complete Order
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.completeOrder}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ completeOrder: !this.state.completeOrder },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Export Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.exportOrdersAndReports}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ exportOrdersAndReports: !this.state.exportOrdersAndReports },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Create customer
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.createCustomer}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ createCustomer: !this.state.createCustomer },()=>{this.generalCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Scale 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.scaleCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.scaleCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Delete Order
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.deleteOrder}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ deleteOrder: !this.state.deleteOrder },()=>{this.scaleCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {user?.role === "user" ?null :(<li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Manually Enter Scale Weight
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.manuallyEnterScaleWeight}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ manuallyEnterScaleWeight: !this.state.manuallyEnterScaleWeight },()=>{this.scaleCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>)}
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Create Charge 
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.createChargeCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.createChargeCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Create Manual Charge
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.createManualCharge}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ createManualCharge: !this.state.createManualCharge },()=>{this.createChargeCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Orders
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.ordersVoidCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.ordersCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Void Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidOrders}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ voidOrders: !this.state.voidOrders },()=>{this.ordersCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                              Billing
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.billingCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.billingCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                              <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  View Billing page
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.viewBillingPage}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ viewBillingPage: !this.state.viewBillingPage },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {qbIntegrationStatus && <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Sync with Quickbooks
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.syncToQuickbooks}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ syncToQuickbooks: !this.state.syncToQuickbooks },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>}
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Void Invoices
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidInvoices}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ voidInvoices: !this.state.voidInvoices },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {/* <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Void Payments
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.voidPayments}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ voidPayments: !this.state.voidPayments },()=>{this.billingCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li> */}
                              </ul>
                            </li>
                          </ul>
                          
                        </div>
                      </div>
                    </div>
                    {/* <ul className="card__scale-list">
                      {permissionOptions?.map(permission => {
                        return (
                          <li key={permission.value}>
                            <span>{permission?.label}</span>
                            
                              <span><Switch checked={this.state?.permissionOptions ? this.state?.permissionOptions[permission?.value]:false} onChange={(checked)=>this.onChange(checked,permission.value)} /></span>
                          </li>
                        );
                      })}
                      </ul> */}
                    </div>
                  <div className="col-md-6">
                  <div className="checkboxtree__section">
                      <div className="checkboxtree__body">
                        <div className="checkbox__tree">
                        <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Business Admin
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.bussinessAdminCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.bussinessAdminCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Business Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.bussinessSetupCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => {
                                        this.setState({ bussinessSetupCheck: !this.state.bussinessSetupCheck },()=>{this.bussinessAdminCheckbox()});
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Pricing Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.pricingSetupAdminCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ pricingSetupAdminCheck: !this.state.pricingSetupAdminCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Scale Setup
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.scaleSetupAdminCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ scaleSetupAdminCheck: !this.state.scaleSetupAdminCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Intergrations
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.intergrationsCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ intergrationsCheck: !this.state.intergrationsCheck },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                   Edit Business Settings
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editBusinessSettings}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ editBusinessSettings: !this.state.editBusinessSettings },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Add/Edit Pricing Tier
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.pricingTier}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ pricingTier: !this.state.pricingTier },()=>{this.bussinessAdminCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list secound__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                User Management
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.userManagementCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={(e) => {this.userManagementCheckboxTree(e)}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    View User Management tab
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.viewUserManagementTab}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ viewUserManagementTab: !this.state.viewUserManagementTab },()=>{this.userManagementCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Edit User Permissions
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editUserPermissions}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={() => { this.setState({ editUserPermissions: !this.state.editUserPermissions },()=>{this.userManagementCheckbox()}) }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                {qbIntegrationStatus && <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                  Edit Synced Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.editSyncedOrders}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={(e) => this.dialogOpen(e)}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>}
                              </ul>
                            </li>
                          </ul>
                          <ul className="checkbox__list">
                            <li className="items">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                Reports
                                <input
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                  checked={this.state.reportsCheck}
                                  disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                  onChange={()=>{this.reportsCheckboxTree()}}
                                />
                                <span className="checkmark"></span>
                              </label>
                              <ul className="tree__inner-list">
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Orders
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.ordersCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({ordersCheck:!this.state.ordersCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Inbound vs Outbound
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.inboundVsOutboundCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({inboundVsOutboundCheck:!this.state.inboundVsOutboundCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Client & Job Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.clientAndJobReportCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({clientAndJobReportCheck:!this.state.clientAndJobReportCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Cash Discrepancy Form/Log Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.cashDiscrepancyCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({cashDiscrepancyCheck:!this.state.cashDiscrepancyCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Voided Report
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.deletedTicketsCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({deletedTicketsCheck:!this.state.deletedTicketsCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                                <li className="items">
                                  <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                                    Projects
                                    <input
                                      type="checkbox"
                                      className="custom-checkbox--input"
                                      checked={this.state.projectOrdersCheck}
                                      disabled={currentUser?.permissions?.permissionOptions?.editUserPermissions !== true}
                                      onChange={()=>{this.setState({projectOrdersCheck:!this.state.projectOrdersCheck},()=>{this.reportCheckbox()})}}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <PermissionDialog
                      open={this.state.dialogOpen}
                      onClose={() => this.setState({ dialogOpen: false,editSyncedOrders: false,userManagementCheck : false})}
                      onOkChange={() => { this.setState({ editSyncedOrders: true,dialogOpen: false }, () => { this.userManagementCheckbox() }) }}
                    />
                    {/* <ul className="card__scale-list">
                      {permissionOptions?.map(permission => {
                        return (
                          <li key={permission.value}>
                            <span>{permission?.label}</span>
                            
                              <span><Switch checked={this.state?.permissionOptions ? this.state?.permissionOptions[permission?.value]:false} onChange={(checked)=>this.onChange(checked,permission.value)} /></span>
                          </li>
                        );
                      })}
                      </ul> */}
                    </div>
                  </div>
              </div></Spin>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="card-title">Activity Log</h5>
                </div>
              </div>
                </div>
              </div>{" "}
              {activities?.length === 0 ? (
                <NoRecords title="No Activity" />
              ) : (
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="card__scale-list">
                      {activities?.map(activity => {
                        return (
                          <li key={activity._id}>
                            <span>{activity.log}</span>
                              <span>{`${moment(activity.createdAt).format("MM/DD/YY")} ${moment(
                                activity.createdAt
                              ).format("hh:mm a")}`}</span>
                          </li>
                        );
                      })}
                      </ul>
                    </div>
                  </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
