import React from "react";
import ReactModal from 'react-modal';
import { sortBy } from "lodash";
import { CloseIcon } from "../../../components/icons";
import CitiesAutocomplete from "../../../components/forms/CitiesSearchBox";

// import { message } from "antd";

class CityOfOrigin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isWaiting: false,
      city: "",
      cities: this.props.cities,
      tempcity : "",
      tempState : ""
    }
  }

  async save(cityOfOrigin) {
    if (this.state.tempcity) {
      const {tempState ,tempcity} = this.state
      const c = this.state.cities;
      if (c.indexOf(`${tempcity}, ${tempState}`) === -1) {
        c.push(`${tempcity}, ${tempState}`)
        this.setState({ cities: c });
      }
      this.setState({ city: `${tempcity}, ${tempState}` });
      this.props.assignCityOfOrigin(`${tempcity}, ${tempState}`)
    }
    this.setState({ isWaiting: true })
    const { companyId, updateCompany } = this.props;
    const {
      value: { status }
    } = await updateCompany({
      companyId,
      cities: sortBy(this.state.cities)
    });
    this.setState({ isWaiting: false })
    if (status) {
      this.props.closeModal(cityOfOrigin)
    }
  }

  render() {
    const { closeModal, isOpen } = this.props;
    const { isWaiting } = this.state;
    return <>
      <ReactModal onRequestClose={closeModal} isOpen={isOpen}>
        <div className="react-modal-dialog react-modal-dialog-centered payabli__integration-modal m-auto">
          <div className="react-modal-header">
            <h5 className="react-modal-title">Add new city of origin</h5>
            <button
              type="button"
              className="btn react-modal-close"
              onClick={closeModal}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="react-modal-body">
            <CitiesAutocomplete
              type="text"
              name="cities"
              value={this.state.city}
              isRequired={true}
              className="form-control material-textfield-input"
              handleAddressChange={({ city, state }) => {
                if(city){
                  this.setState({
                    tempcity : city,
                    tempState:state,
                    city: `${city}, ${state}`
                  })
                }
              
              }}
              hideLabel={true}
              placeholder="City of Origin"
              setValue={city => this.setState({ city })}
              address={this.state.city}
            />
          </div>
          <div className="react-modal-footer">
            <button onClick={closeModal} className="btn btn-dark btn-sm delete-btn mx-2">
              Cancel
            </button>
            <button
              onClick={() => this.save(this.state.city)}
              className="btn btn-yellow"
              disabled={isWaiting || this.state.tempcity.length === 0}
            >
              {isWaiting ? "Please wait.." : "Save"}
            </button>
          </div>
        </div>
      </ReactModal></>
  }
}

export default CityOfOrigin;
