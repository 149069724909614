
import React, { PureComponent } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { BackArrowIcon } from "../../../components/icons";
import { ErrorMessage } from "formik";
import "react-image-crop/lib/ReactCrop.scss";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Switch } from "antd";

import { projectSchema } from "../../../utils/validations";

export default class CreateProject extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      InitialValues: {
        projectName: "",
        customerId: "",
        startDate: new Date(),
        endDate: new Date(),
        projectStatus: "active",
        isLEEDsRequired: false,
      },
    };
  }

  componentDidMount() {
    let InitialValues = this.state.InitialValues
    this.setState({ ...InitialValues, customerId: this.props?.customer?._Id })
  }

  async CreateNewProject(values) {
    const payload = {
      projectName: values.projectName,
      companyId: this.props.customerData?.companyId,
      customerId: this.props.customerData?._id,
      startDate: values.startDate,
      endDate: values.endDate,
      projectStatus: values.projectStatus,
      isLEEDsRequired: values.isLEEDsRequired
    }
    const {
      value: { status }
    } = await this.props.createProject(payload);
    if (status) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={this.state.InitialValues}
        validationSchema={projectSchema}
        onSubmit={(values, formikProps) =>
          this.CreateNewProject(values, formikProps)
        }
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            style={{ display: false ? "none" : "" }}>
            <main className='create__driver-wrapper'>
              <div className='header__container m-10'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='back__button'>
                        <button
                          className='btn btn-link btn-back'
                          onClick={this.props.onClose}
                        >
                          <BackArrowIcon /> Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='page_container'>
                <div className='create__driver-inner-scroll'>
                  <div className='form-group material-textfield'>
                    <input
                      type='text'
                      value={values.projectName}
                      onChange={handleChange}
                      className='form-control material-textfield-input'
                      name='projectName'
                      required
                    />
                    <ErrorMessage
                      component='span'
                      name='projectName'
                      className='invalid-feedback d-block'
                    />
                    <label className='material-textfield-label'>
                      Project Name<span>*</span>
                    </label>
                  </div>
                  <div className='form-group material-textfield'>
                    <select
                      value={values.projectStatus}
                      className='form-control custom-select'
                      name='projectStatus'
                      onChange={handleChange}
                      required>
                        <option value="active">Active</option>
                    <option value='inActive'>InActive</option>
                    </select>
                    <ErrorMessage
                      component='span'
                      className='invalid-feedback d-block'
                      name='projectStatus'
                    />
                    {/* <label className="material-textfield-label">
                       State<span>*</span>
                        <    /label> */}
                  </div>
                  <div className="calendar__form">
                    <div className="input-from">
                      <div className="form-group">
                        <DatePicker
                          name="startDate"
                          selected={moment(values.startDate, "MMM DD, YYYY").toDate()}
                          onChange={(e) => {
                            setValues({ ...values, startDate: moment(e, "MMM DD, YYYY").toDate() })
                          }}
                          className="form-control material-textfield-input"
                          placeholder="10/01/20"
                          required
                          autoComplete="off"
                        />
                        <label className="material-textfield-label label__for-date">Start Date</label>
                      </div>
                    </div>
                    <div className="input-to">
                      <div className="form-group">
                        <DatePicker
                          name="endDate"
                          selected={moment(values.endDate, "MMM DD, YYYY").toDate()}
                          onChange={(e) => {
                            setValues({ ...values, endDate: moment(e, "MMM DD, YYYY").toDate() })
                          }}
                          className="form-control material-textfield-input"
                          placeholder="10/01/20"
                          required
                          autoComplete="off"
                        />
                        <label className="material-textfield-label label__for-date">End Date</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-0">
                    <div className="display-team-status">
                      <span className="display-team-text">
                        LEEDs Required
                      </span>
                      <Switch
                        name="isLEEDsRequired"
                        checked={values.isLEEDsRequired}
                        onChange={() => {
                          const updatedIsLEEDsRequired = !values.isLEEDsRequired;
                          this.setState({ isLEEDsRequired: updatedIsLEEDsRequired }, () => {
                            setValues({ ...values, isLEEDsRequired: updatedIsLEEDsRequired });
                          });
                        }}
                      />
                      <p
                        className={
                          values.isLEEDsRequired
                            ? "on-of-text text-green"
                            : "on-of-text text-red"
                        }
                      >
                        {values.isLEEDsRequired ? "Yes" : "No"}
                      </p>
                    </div>
                    </div>
                  <button
                    className='btn btn-dark btn-lg btn-block w-100 btn-done'
                    type='submit'
                    disabled={isSubmitting}>
                    {isSubmitting ? "Please wait..." : "Done"}
                  </button>
                  <br />
                </div>
              </div>
            </main>
          </form>
        )}
      </Formik>
    );
  }
}
