import { connect } from "react-redux";
import { getInboundOutboundMaterials, getReport } from "../../store/report/duck";
import { fetchCustomers, fetchLocationsList } from "../../store/customer/duck";
import { fetchMe,updateUserToken } from '../../store/user/duck'
import WorkReportComponent from "./component";

const WorkReportContainer = connect(
  // Map state to props
  state => ({ user: state.user.user, userPhase: state.user.userPhase }),
  // Map actions to dispatch and props
  { 
    getReport, 
    fetchCustomers, 
    fetchLocationsList,
    fetchMe,
    updateUserToken,
    getInboundOutboundMaterials
  }
)(WorkReportComponent);

export default WorkReportContainer;
