import { connect } from 'react-redux'
import { getMe, fetchUsersByCompanyId, getUserToken,fetchMe,updateUserToken } from "../../store/user/duck";
import CompanyUserListComponent from './component'

const CompanyUserListContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchUsersByCompanyId,
    getUserToken,
    updateUserToken,
    fetchMe
  }
)(CompanyUserListComponent);

export default CompanyUserListContainer
