import React, { PureComponent } from 'react'
import { message } from "antd";
import './styles.scss'

export default class SignPadComponent extends PureComponent {

  constructor(props){
    super(props)
    this.state = {
      showCaptureButton: false,
      loading: false,
      base64String: '',
      signatureUrl: ''
    }
    this.textareaRef = React.createRef()
  }
  addScript(src) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }
  componentDidMount () {
    this.addScript(`/SignWebTablet.js`);
    this.addScript(`/SignWeb.js`);
  }

  componentWillUnmount() {
    // window.onbeforeunload();
  }

  async initialize() {
    const res = window.onSign();

    if(res?.success === false && res.message) {
      message.info(res?.message)
    } else {
      this.setState({ showCaptureButton: window.IsSigWebInstalled() })
    }
  }

  clear() {
    this.setState({ signatureUrl: '', loading: false })
    window.onClear();
    window.onSign();
  }
  save() {
    this.setState({ loading: true })
    window.onDone();
    setTimeout(async() => {
       // Access the current value of the textarea using the ref
      const base64String = this.textareaRef.current.value;
      const base64regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/;
      if (base64String !== "" && base64regex.test(base64String)) {
        this.setState({ base64String });
        const payload = {
          orderId: this.props.orderId,
          base64String,
          fileName: this.props.orderId
        }
        const {
          value: { errorMessage, signatureUrl }
        } = await this.props.uploadSignature(payload);
        this.setState({ signatureUrl, loading: false });
        this.props.parentCallback({ signatureUrl, base64String })
        if(errorMessage) {
          message.error(errorMessage);
        }
      } else {
        this.setState({ loading: false })
        message.error('Please check connection with signature pad or signature.')
      }
    }, 1000);
  };
  

  render() {
    const { showCaptureButton, signatureUrl, loading } = this.state;
    return(
      <div className='row'>
        <div className='col-lg-12'>
          <div className="driver__signature-pad">
            <div className="row">
              <div className="col-lg-12">
                <p className="signature__text">
                  Driver Signature
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="driver__signature-inner">
                  <div className="signature">
                    <canvas id="cnv" name="cnv" width="500" height="100"></canvas>
                    <form action="#" name="FORM1">
                      <input type="hidden" name="bioSigData" />
                      <input type="hidden" name="sigImgData" />
                      <textarea name="sigStringData" rows="20" cols="50" style={{ display: 'none' }} />
                      <textarea
                        name="sigImageData"
                        rows="20"
                        cols="50"
                        ref={this.textareaRef}
                        style={{ display: 'none' }}
                      />
                    </form>
                  </div>
                  <div className="signature__right-section">
                    {
                      !showCaptureButton &&
                      <button
                        type='button'
                        onClick={() => this.initialize()}
                        className="btn btn-dark save-btn"
                      >
                        Connect with Signature Pad
                      </button>
                    }
                    {
                      showCaptureButton &&
                      <button
                        type='button'
                        onClick={() => this.clear()}
                        className="btn clear-btn"
                      >
                        Clear
                      </button>
                    }
                    {
                      showCaptureButton &&
                      <button
                        type='button'
                        onClick={() => this.save()}
                        className="btn btn-dark save-btn"
                        disabled={loading || signatureUrl!==''}
                      >
                        {loading ? 'Please wait...' : ( signatureUrl!=='' ? 'Signature Saved' : 'Save Signature' )}
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )

  }
}