import { connect } from 'react-redux'
import PrintBillingReceipt from './component'
import { getInvoiceDetailsById } from '../../store/Billing/duck';

const PrintBillingReceiptContainer = connect(
  // Map state to props
  state => ({
  }),
  {
    getInvoiceDetailsById,
  }
)(PrintBillingReceipt)

export default PrintBillingReceiptContainer
