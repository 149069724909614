import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Menu, Dropdown } from "antd";
import { ArrowIcon, CloseIcon } from "../../components/icons";
import { DownCaretIcon } from "../../components/icons";
import { Formik, ErrorMessage } from "formik";
import { loginSchema } from "../../utils/validations";
import EditModal from "./partials/edit-company";
import "./styles.scss";

export default class CompanyListingComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      user: null,
      initalValues: {
        email: "",
        company: "",
        domain: "",
        scaleip: "",
        scaleport: ""
      },
      companies: [],
      searchText: "",
      logo: "",
      typingTimeout: 0,
      editModalOpen: false,
      editCompanyObject: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.getMe();
    this.fetchCompanies();
  };

  fetchCompanies = async () => {
    const {
      value: { data }
    } = await this.props.fetchCompanies(this.state.searchText);
    this.setState({ companies: data });
  };

  handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchCompanies();
      }, 1000)
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return {
        user: nextProps.user
      };
    } else {
      return null;
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleImage = (e, setSubmitting) => {
    setSubmitting(true);
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function(r) {
        this.setState({
          previewStatus: "ViewPatientOnboarding_previewShowing"
        });
        const formdata = new FormData();
        formdata.append("photos", input.files[0]);
        formdata.append("fileName", input.files[0].name);
        formdata.append("folderName", "company");
        const {
          value: { url }
        } = await this.props.uploadImage(formdata);
        this.setState({ logo: url }, () => {
          setSubmitting(false);
        });
      }.bind(this);
      reader.readAsDataURL(input.files[0]);
    }
  };

  handleSubmit = async ({ company, email, domain, scaleip, scaleport }, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const { logo } = this.state;
    const {
      value: { status, message }
    } = await this.props.createCompany({ logo, name: company, email, domain, scaleip, scaleport });
    if (status) {
      this.fetchCompanies();
      this.closeModal();
    } else {
      setErrors({ email: message });
    }
  };

  logout() {
    localStorage.clear();
    this.props.history.push("/login");
  }

  menu = () => (
    <Menu>
      <Menu.Item key="0">
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={"/business-settings"}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={this.logout}>
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  render() {
    const { user } = this.props;
    const { initalValues, companies, editCompanyObject } = this.state;
    return (
      <div>
        <div className="superadmin__wrapper">
          <header className="header topnavigation-layout-header d-flex align-items-center">
            {/*<div className="topnavigation-layout-search">
              <div className="topnavigation-layout-wrapper">
                <input
                  className="form-control"
                  placeholder="Search by Ticket #, Name, Company, or License plate #..."
                />
                <div className="search-wrapper-open-container">
                  <ul className="search-wrapper-options-menu">
                    <div className="options-menu-typeahead-header"><img className="options-menu-typeahead-icon" alt=""/> Buildings</div>
                      <li className="search-wrapper-options-menu-item">
                        <Link to={`/`} target="_blank">
                          <p className="options-menu-item-title">Title</p>
                          <p className="options-menu-item-subtitle">subtitle</p>
                        </Link>
                      </li>
                  </ul>
                </div>
              </div>
            </div>*/}
            <div className="topnavigation-layout__profile ml-auto">
              <Dropdown overlay={this.menu} trigger={["click"]} overlayClassName="profile--dropdown--overlay">
                <a className="ant-dropdown-link d-flex align-items-center" href="/">
                  <div className="avatar">
                    <p className="name_text">
                      {user && user.firstName.substr(0, 1)}
                      {user && user.lastName.substr(0, 1)}
                    </p>
                  </div>
                  <div className="avatar-info">
                    <div className="avatar-name">
                      {user ? user.firstName : ""} {user ? user.lastName : ""}
                    </div>
                    <div className="avatar-email">{user ? user.email : ""}</div>
                  </div>
                  <DownCaretIcon />
                </a>
              </Dropdown>
            </div>
          </header>
          <div className="custome__container">
            <div className="card">
              <div className="card-header">
                <div className="row align-items-center">
                  <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12">
                    <div className="d-flex align-items-center justify-content-between flex-lg-row flex-column">
                      <h5 className="card-title">Admin</h5>
                      <button className="btn btn-dark btn-sm" onClick={this.openModal}>
                        Create New Company
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="serach__box">
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.searchText}
                        onChange={this.handleSearch}
                        placeholder="Search company..."
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <tbody>
                          {companies.map(company => {
                            return (
                              <tr key={company._id}>
                                <td>{company.name}</td>
                                <td>{company.email}</td>
                                <td className="underline text-bold cursor-pointer ">
                                  <div className="d-flex justify-content-end">
                                    <div
                                      onClick={() => this.setState({ editModalOpen: true, editCompanyObject: company })}
                                      className="edite__text"
                                      >
                                        Edit
                                      </div>
                                      <div className="underline text-bold"
                                        onClick={() =>
                                          this.props.history.push({
                                            pathname: "/user-list",
                                            state: { id: company._id }
                                          })
                                        }
                                      >
                                        View Users <ArrowIcon />
                                      </div>
                                  </div>
                                </td>                                
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Create New Company</h5>
              <button type="button" className="btn react-modal-close" onClick={this.closeModal}>
                <CloseIcon />
              </button>
            </div>
            <Formik
              initialValues={initalValues}
              validationSchema={loginSchema}
              onSubmit={(values, formikProps) => this.handleSubmit(values, formikProps)}
            >
              {({ values, handleChange, setValues, handleSubmit, isSubmitting, setSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="react-modal-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="company"
                            value={values.company}
                            onChange={e =>
                              setValues({
                                ...values,
                                company: e.target.value,
                                domain: `${e.target.value.toLowerCase().replace(/\s/g, "")}.weighworks.com`
                              })
                            }
                            className="form-control material-textfield-input"
                            required
                          />
                          <ErrorMessage component="span" name="company" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Company Name<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="form-control material-textfield-input"
                            required
                          />
                          <ErrorMessage component="span" name="email" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Email<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="domain"
                            value={values.domain}
                            onChange={handleChange}
                            className="form-control 
                            material-textfield-input"
                            required
                          />
                          <span>Example: demo.weighworks.com</span>
                          <ErrorMessage component="span" name="domain" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Domain<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="scaleip"
                            value={values.scaleip}
                            onChange={handleChange}
                            className="form-control 
                            material-textfield-input"
                            required
                          />
                          <ErrorMessage component="span" name="scaleip" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Scale IP<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="text"
                            name="scaleport"
                            value={values.scaleport}
                            onChange={handleChange}
                            className="form-control 
                            material-textfield-input"
                            required
                          />
                          <ErrorMessage component="span" name="scaleport" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Scale Port<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group material-textfield">
                          <input
                            type="file"
                            name="logo"
                            onClick={evt => this.handleImage(evt, setSubmitting)}
                            onChange={evt => this.handleImage(evt, setSubmitting)}
                            className="form-control material-textfield-input"
                          />
                          <ErrorMessage component="span" name="logo" className="invalid-feedback d-block" />
                          <label className="material-textfield-label">
                            Logo<span>*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-dark btn-lg w-100" disabled={isSubmitting}>
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={this.state.editModalOpen}
          onRequestClose={() => this.setState({ editModalOpen: false })}
          contentLabel="Please Confirm"
          ariaHideApp={false}
        >
          <EditModal
            company={editCompanyObject}
            updateCompany={this.props.updateCompany}
            closeModal={() => this.setState({ editModalOpen: false })}
            fetchCompanies={this.fetchCompanies}
          />
        </ReactModal>
      </div>
    );
  }
}
