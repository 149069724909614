import { connect } from 'react-redux'
import { getMe, fetchMe,updateUserToken,uploadImage } from '../../store/user/duck'
import {
  postOutboundMaterials,
  getOutboundMaterials
} from "../../store/outboundactivity/duck";
import OutboundActivityMaterialsComponent from './component';

const OutboundActivityMaterialsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    postOutboundMaterials,
    getOutboundMaterials,
    updateUserToken,
    uploadImage
  }
)(OutboundActivityMaterialsComponent);

export default OutboundActivityMaterialsContainer