/** @format */

import React, { PureComponent } from "react";
import PayabliComponent from "../payabli/component";

// import "./styles.scss";

export default class AddCardComponent extends PureComponent {
    static propTypes = {
        // PropTypes go here
    };

    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                number: "",
                expDate: "",
                nameOnCard: "",
                securityCode: ""
            },
            isOpen: false
        };

    }

    componentDidMount = async () => {
        const script = document.createElement("script");
        script.src = "https://embedded.payabli.com/1.9.0/component.js";
        //script.src = "https://embedded-component-sandbox.payabli.com/component.js"
        script.async = true;
        script.onload = () => {console.log('script loaded##########')};

        document.body.appendChild(script);
        this._initPayabli()
        if(this.props.payabliRef?.current){
            this.props.payabliRef.current = {
                _initPayabli:this._initPayabli
            }
        }
    };


    _initPayabli = () => {
        // let entryBytes = CryptoJS.AES.decrypt(this.props.paybliDetails.entry_point,'weigh123!!');
        // const decryptedEntryPoint = entryBytes.toString(CryptoJS.enc.Utf8)
        // let paybliTokenBytes = CryptoJS.AES.decrypt(this.props.paybliDetails.mixed_token,'weigh123!!');
        // const decryptedToken = paybliTokenBytes.toString(CryptoJS.enc.Utf8)
        var self = this;
        // var payablitoken = decryptedToken
        // var entryPoint = decryptedEntryPoint
        var payabliConfig0 = {
            type: "methodLightbox",
            rootContainer: "pay-component-1",
            buttonLabelInModal: 'Save Payment Method',
            defaultOpen: 'card',
            hideComponent: true,
            temporaryToken: false,
            token: this.props.paybliDetails?.public_token,
            entryPoint: this.props.paybliDetails?.entry_point,
            card: {
                enabled: true,
                amex: true,
                discover: true,
                visa: true,
                mastercard: true
            },
            ach: {
                enabled: true,
                checking: true,
                savings: false
            },
            customerData: {
                customerNumber: this.props?.customerData?.phone,
                firstName: this.props?.customerData?.customerName,
                lastName: this.props?.customerData?.displayName,
                billingEmail: this.props?.customerData?.billingEmailAddress,
                company: this.props?.customerData?.companyName
            },
            functionCallBackSuccess: (response) => {
                if (response.isSuccess) {
                    self.props.assignCardData(response.responseData)
                }
                // alert(response.responseText);
            },
            functionCallBackError: function (errors) {
                // alert('Error!');
                console.log(errors);
            }
        };

        var paycomponent0 = new PayabliComponent(payabliConfig0, this.props.closeCardModal)
        paycomponent0.showModal();
    }

    submitForm = async ({ number, nameOnCard, expDate, securityCode }, { setErrors }) => {
        console.log(number, expDate, securityCode, nameOnCard)
    }

    render() {
        return (
            <div className='react-modal-dialog react-modal-dialog-centered sustainability__information-modal'>
                <div id="pay-component-1">
                </div>
            </div>

        );
    }
}
