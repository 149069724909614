import React, { PureComponent } from "react";
import "../styles.scss";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Logo from '../../../images/logo.png'

// const timezone = moment().format("Z");
class InvoicePDF extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      printReciptData: {},
      printReciptdatanew: {},
      reRender: false,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState(
        { printReciptData: this.props.printRecipt,printReciptdatanew:this.props.printReciptdatanew },
        () => {
          this.printRecipt();
        },
        20
      );
    });
  };
  printRecipt = () => {
    var element = document.getElementById("report");
    var opt = {
      margin: -0.1,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.3 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf()
      .from(element)
      .set(opt)
      .output("blob")
      .then(function (pdfBlob) {
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
        URL.revokeObjectURL(url);
      });
    this.props.closePrintRecipt();
  };

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num =
      parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  };
  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };
  render() {
/*     const { order } = this.state.printReciptData;
 */   
const Company =  this.state.printReciptData.company && this.state.printReciptData.company.length > 0?this.state.printReciptData.company[0]:this.state.printReciptData.company ;
console.log(Company);
const customer =this.state.printReciptData && this.state.printReciptData.customer 
console.log(this.state.printReciptdatanew)
const invoices = this.state.printReciptdatanew && this.state.printReciptdatanew.invoices
let totalAmountToApply =0
if(invoices?.length>0){
  invoices.map((item)=>{
if(Number(item.payment)>0){
  totalAmountToApply += Number(item.payment)
}
return null
  })
}

// const creditAmount =invoices &&  invoices?.map((item)=>item.payment !== null ? item.creditAmount : 0)
// const result =creditAmount && creditAmount?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return (
      <>
        <div className="print-block-two" id="report">
          <div className="invoice__each-transaction">
            <div className="invoice__each-inner">
              <section className="invoice__header">
                <tr>
                  <th colspan="3">
                    <img alt="logo" style={{"margin": "0px auto"," height": "75px",objectFit:"contain"}} src={Company?.logo?.length > 0 ? Company?.logo : Logo}/>
                  </th>
                </tr>
                <h1 className="invoice-title">
                  {Company?.businessInfo?.businessName}. Payment Receipt
                </h1>
                <p>
                  {Company?.businessInfo?.address}
                  <br />
                  {Company?.businessInfo?.state +
                    " " +
                    Company?.businessInfo?.city +
                    "," +
                    Company?.businessInfo?.zip}
                </p>
              </section>
              <table className="print-table">
                <tbody>
                  <tr>
                    <td>
                      <h3 className="address__title">
                        {customer?.address}
                      </h3>
                      <p className="address">
                        {customer?.state +
                          " " +
                          customer?.city  + " " +
                          customer?.zip}
                          {customer?.phone &&  <>
                        <br />
                        {/* LIC NY, 11101<br/> */}{/* 
                        {customer?.phone} */}
                        {customer?.phone?`(${customer?.phone.substr(0, 3)}) ${customer?.phone.substr(3, 3)} - ${customer?.phone.substr(6)}`:""}
                    </>  }
                        <br />
                        {customer?.email !== "" ? customer?.email :customer?.billingEmailAddress}
                      </p>
                    </td>
                    <td>
                      <table className="invoice-number-date-table">
                        <tbody>
                          <tr>
                            <td>
                              <h3 className="address__title">
                                Payment Receipt:
                              </h3>
                            </td>
                            <td>
                              <p className="address">
                                {"\u00A0"}
                                {"\u00A0"}
                                {this.state.printReciptdatanew?.paymentReceiptId}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 className="address__title">Payment Date:</h3>
                            </td>
                            <td>
                              <p className="address">
                                {moment(
                                  this.state.printReciptdatanew?.paymentDate
                                ).format("l")}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <h3 className="address__title">Payment Amount</h3>
                      <h2 className="mt-3" style={{ fontFamily: "Arial" }}>
                        $
                        {this.numberWithCommas(
                          this.round2decimal(
                            Number(
                              this.state?.printReciptdatanew &&
                              this.state?.printReciptdatanew?.stripeCardFees > 0 
                              && this.state?.printReciptdatanew?.isFromReceivePayment
                              ? this?.state?.printReciptdatanew?.amountReceive
                              : this.state?.printReciptdatanew?.amountToApply
                              )
                          )
                        )}
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="billing__address">
                {/* <ul className="list address__list">
                <li className="items">
                  
                </li>
                <li className="items">
                  
                </li>
                <li className="items">
                </li>
              </ul> */}
              </div>
              <section className="scale__ticket-section">
                <table className="billing-ticket-invoice">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice #</th>
                      <th>Original Amount</th>
                      <th>Open Balance</th>
                      <th>Payment Applied </th>
                    </tr>
                  </thead>

                     { invoices?.length > 0 ? invoices?.map((items)=>{
                      if (Number(items.payment) > 0) {
                        return (
                          <>
                          <>
                            <tbody>
                              <>
                                <tr className="mb-5">
                                  <td width="1%">
                                    {moment(
                                  this.state.printReciptdatanew?.paymentDate
                                ).format("l")}
                                  </td>
                                  <td width="25%">
                                    {items?.invoiceId}
                                  </td>
                                  <td>
                                    $
                                    {this.numberWithCommas(
                                      this.round2decimal(
                                        Number(
                                          items?.total
                                        )
                                      )
                                    )}
                                  </td>
                                  <td>
                                    $
                                    {this.numberWithCommas(
                                      this.round2decimal(
                                        Number(
                                          items?.balacnce
                                        )
                                      )
                                    )}
                                  </td>
                                  <td>
                                    $
                                    {this.numberWithCommas(
                                      this.round2decimal(
                                        Number(items?.payment)
                                      )
                                    )}
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </>
                        </>
                        )
                      }else{
                        return ''
                      }
                     
                     }) :null}
                </table>
              </section>
              <section className="total__amount-section">
                <div className="card__details">
                  <ul className="list"></ul>
                </div>
                <div className="total__amount">
                  <ul className="list">
                    <li className="items">
                      <small>Amount Applied to Invoices. </small> $
                      {this.numberWithCommas(
                        this.round2decimal(
                          Number(totalAmountToApply)
                        )
                      )}
                    </li>
                    <li className="items">
                      <small style={{ marginRight: "60px" }}>
                        Amount Applied to Credit Memo.
                      </small>
                      $
                      {this.numberWithCommas(
                        this.round2decimal(
                          Number(this.state.printReciptdatanew?.totalCreditAmount ? this.state.printReciptdatanew?.totalCreditAmount : 0 )
                        )
                      )}
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InvoicePDF;
