import React from "react";
import Webcam from "react-webcam";
import _ from "lodash";

const WebcamCapture = ({ onTakePhoto }) => {
  const webcamRef = React.useRef(null);
  const [deviceId, setDeviceId] = React.useState(null);
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onTakePhoto(imageSrc);
  }, [webcamRef, onTakePhoto]);

  const onChangeCamera = e => {
    setDeviceId(e.target.value);
  };

  return (
    <>
      {devices.length === 0 ? (
        "No camera found"
      ) : (
        <>
          <div className="webcam__div">
            <Webcam
              ref={webcamRef}
              audio={false}
              videoConstraints={{ deviceId: deviceId ? deviceId : _.get(devices, "[0].deviceId", "") }}
            />
            <div className="container-circles">
              <div className="outer-circle">
                <div onClick={capture} className="inner-circle" />
              </div>
            </div>
          </div>
          <div className="capure_photo_btn-select">
            <div className="form-group material-textfield custom-select-50">
              <select
                className="form-control custom-select w-150 material-textfield-input pr-22"
                name="dateType"
                onChange={onChangeCamera}
                required
              >
                {devices.map(({ label, deviceId }, key) => (
                  <option key={key} value={deviceId}>
                    {label}
                  </option>
                ))}
              </select>
              <label className="material-textfield-label">Camera</label>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebcamCapture;
