import React, { PureComponent } from "react";
import { CloseIcon } from "../../components/icons";
import { Link } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import QuickbooksIcon from "./../../images/quickbooks_icon.svg";
import queryString from "query-string";
import "./styles.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class QuickBooks extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };
  constructor(props) {
    super(props);

    this.state = {
      spinning: false
    };
  }

  componentDidMount = async () => {
		document.title = "QuickBooks | Weighworks";
		const qs = queryString.parse(this.props.location.search);
		if (qs.state){
			window.location = `${qs.state}/customer-mapping${window.location.search}`;
		}
  };

  render() {
    const { spinning } = this.state;
    return (
      <div>
        <div className="quickbooks__quickbooks">
          <div className="head-section-header d-flex align-items-center">
            <div className="head-section-title">Quickbooks Setup</div>
            <div className="head-section-cta">
              <Link to={"/dashboard"} className="btn btn-link btn-back">
                <CloseIcon />
              </Link>
            </div>
          </div>
          <Spin indicator={antIcon} spinning={spinning}>
            <div className="quickbooks__body">
              <div>
                <div className="quickbooks__body-oinner">
                  <h2 className="quickbooks__title">Setup Quickbooks Invoicing</h2>
                  <p>Click the button below to start the process to start the Quickbooks integration setup</p>
                </div>
                <a className="button btn btn-dark quickbooks__btn" href="/dashboard">
                  <img src={QuickbooksIcon} alt="" />
                  Connect to Quickbooks
                </a>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
