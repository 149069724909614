import { connect } from 'react-redux'
import { getMe,updateUserToken,fetchMe } from '../../store/user/duck'
import { createCustomer, fetchCustomer, updateCustomer } from '../../store/customer/duck'
import { fetchSetting } from "../../store/company/duck";
import EditCompanyInfoComponent from './component'
import {getPricingTier} from "../../store/Pricing_tier/duck"
import { getPayabliCustomerData, getPaybliIntegration } from '../../store/paybli_integrations/duck';
import {fetchInvoiceDeliveryData} from "../../store/invoiceDelivery/duck"

const EditCompanyInfoContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    updateUserToken,
    fetchMe,
    createCustomer,
    updateCustomer,
    fetchCustomer,
    fetchSetting,
    getPricingTier,
    getPayabliCustomerData,
    fetchInvoiceDeliveryData,
    getPaybliIntegration
  }
)(EditCompanyInfoComponent);

export default EditCompanyInfoContainer
