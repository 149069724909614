import { connect } from 'react-redux'
import { getMe, fetchMe,updateUserToken,uploadImage } from '../../store/user/duck'
import {
  createOutboundActivity,
  fetchOutboundActivities,
  updateOutboundActivity,
  deleteOutboundActivity,
  getOutboundMaterials,
  deleteCompletedOutboundActivity
} from "../../store/outboundactivity/duck";
import { fetchResourcesList } from '../../store/resource/duck'
import OutboundActivityComponent from './component'

const OutboundActivityContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getMe,
    fetchMe,
    createOutboundActivity,
    updateUserToken,
    fetchOutboundActivities,
    fetchResourcesList,
    getOutboundMaterials,
    updateOutboundActivity,
    deleteOutboundActivity,
    deleteCompletedOutboundActivity,
    uploadImage
  }
)(OutboundActivityComponent);

export default OutboundActivityContainer
